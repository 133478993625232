import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Stack,
  stackClassNames,
  Typography,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { AppStoreLinkButton } from 'src/components/common/AppStoreLinkButton';
import { Link } from 'src/components/core/Link';
import { FiveStars } from 'src/components/common/FiveStars';
import R, { EXTERNAL } from 'src/routes';
import { PeopleSayingCarousel } from 'src/components/common/PeopleSayingCarousel';
import { IOSLandingProps } from './IOSLanding.types';
import { UseCases } from 'src/components/landing/UseCases';
import { CollectionCarousel } from 'src/components/common/CollectionCarousel';
import clsx from 'clsx';

const UnstyledIOSLanding: VFC<IOSLandingProps> = ({ className, style }) => {
  return (
    <>
      <Helmet>
        <title>
          Moonsift | Save, Curate, Discover | Re-imagine how you shop online
        </title>
        <meta
          name="description"
          content="The universal wishlist app that lets you save products from any store. Create wishlists for any occasion. Free and easy to use."
        />
      </Helmet>
      <Page className={className} style={style} isLanding showFooter>
        <section className="IOSLanding-headingSection">
          <Typography variant="editorial.r46" component="h1" align="center">
            Re-imagine how you shop online
          </Typography>
          <Typography variant="primary.l20" component="p" align="center">
            Save items from any store in the world to your own shoppable
            collections.
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
          <Typography variant="primary.r16" component="p" align="center">
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
        </section>

        <section className="IOSLanding-brandsBannerContainer">
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/brands-banner.png`}
            />
            <img
              className="IOSLanding-brandsBanner"
              alt="Brands banner"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/brands-banner.2x.png`}
            />
          </picture>
        </section>

        <section className="IOSLanding-section">
          <div className="IOSLanding-sectionLeftAlign">
            <Typography variant="editorial.sb34" component="h2">
              Save products from any store
            </Typography>
            <Typography
              variant="primary.l20"
              component="ol"
              className="IOSLanding-orderedList"
            >
              <li>
                <Link to={EXTERNAL.APP_STORE_APP}>Get the iOS app</Link>
              </li>
              <li>Browse and shop as normal </li>
              <li>Save items directly from any store</li>
            </Typography>
            <picture>
              <source
                media="(max-width: 620px)"
                srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/create-wishlists.png`}
              />
              <img
                className="IOSLanding-picture"
                alt="Create wishlists"
                src={`${process.env.REACT_APP_STATIC_URL}/app-landing/create-wishlists.2x.png`}
              />
            </picture>
          </div>
        </section>

        <section className={clsx('IOSLanding-section', '')}>
          <Typography
            className="IOSLanding-useCasesSectionHeading"
            variant="editorial.r26"
            component="h2"
          >
            Curate on a beautiful, easy to use platform - whatever the occasion
          </Typography>
          <UseCases
            order={[
              'wishlist',
              'wedding',
              'christmas',
              'birthday',
              'baby',
              'stylists',
            ]}
            goToSignUp={['wishlist']}
          />
        </section>

        <section className="IOSLanding-section">
          <Typography variant="editorial.sb34" component="h2">
            Curate and share collections
          </Typography>
          <Typography variant="primary.l20" component="p">
            Moonsift makes it easy to compare, organise and keep track of all
            your favourite products in one place.
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
          <Typography variant="primary.r16" component="p" align="center">
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/online-wishlists.png`}
            />
            <img
              className="IOSLanding-picture"
              alt="Online wishlists"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/online-wishlists.2x.png`}
            />
          </picture>
        </section>

        <section className="IOSLanding-section">
          <Typography variant="editorial.sb34" component="h2">
            Get <span className="IOSLanding-priceDropCopy">price drop</span>{' '}
            alerts
          </Typography>
          <Typography variant="primary.l20" component="p">
            We'll track price drops on items you save to your wishlists.
            <br />
            We track prices on <Link to={R.RETAILERS}>2,000+ stores</Link>.
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/price-drop-alerts.png`}
            />
            <img
              className="IOSLanding-priceDropPicture"
              alt="Price drop alerts"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/price-drop-alerts.2x.png`}
            />
          </picture>
        </section>

        <section className="IOSLanding-carouselSection">
          <Typography variant="editorial.sb34" component="h2">
            Read what people are saying
            <Stack direction="row" alignItems="center">
              <FiveStars size="large" />
            </Stack>
          </Typography>

          <PeopleSayingCarousel className="IOSLanding-carousel" />

          <Typography variant="primary.l16" component="p" align="center">
            Get Moonsift on the App Store - it's free
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
          <Typography variant="primary.r16" component="p" align="center">
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
        </section>
        <section className="IOSLanding-carouselSection">
          <Typography variant="editorial.sb34" component="h2">
            Explore public collections
          </Typography>
          <CollectionCarousel listName={'explore_featured'} />
        </section>
      </Page>
    </>
  );
};

export const IOSLanding = styled(UnstyledIOSLanding)`
  overflow-x: hidden;

  .IOSLanding-priceDropCopy {
    color: var(--foreground-green);
  }

  .IOSLanding-appStoreButton {
    height: 54px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  .IOSLanding-headingSection {
    margin: 0 auto;
    padding: 56px 20px 24px;
    max-width: 360px;

    h1 {
      margin-bottom: 22px;
    }
  }

  .IOSLanding-orderedList {
    margin-left: 22px;
  }

  .IOSLanding-useCasesSectionHeading {
    text-align: center;
  }

  .IOSLanding-brandsBannerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .IOSLanding-brandsBanner {
      height: 202px;
      width: auto;
    }
  }

  .IOSLanding-sectionLeftAlign {
    text-align: left;
  }

  .IOSLanding-section {
    text-align: center;
    margin: 0 auto;
    padding: 48px 30px 16px;
    max-width: 360px;

    h2 {
      margin-bottom: 12px;
    }

    h2 + p {
      margin-bottom: 28px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  picture {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }

    img.IOSLanding-priceDropPicture {
      margin-top: -12px;
      margin-bottom: calc(-12px + 44px);
    }
  }

  .IOSLanding-carouselSection {
    margin: 0 auto;
    padding: 48px 20px 64px;
    max-width: 840px;

    h2 {
      margin-bottom: 24px;

      > .${stackClassNames.root} {
        margin-left: 12px;
        display: inline-flex;
      }
    }

    .IOSLanding-carousel {
      margin-bottom: 68px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .IOSLanding-carouselSection {
      padding: 48px 54px 64px;
    }
  }
`;
