import React from 'react';
import styled from 'styled-components';
import {
  Paper,
  Typography,
  ProTickTwoToneIcon,
  proTickTwoToneIconClassNames,
  Stack,
  StandardButton,
} from '@moonsifttech/design-system';

import { ReferProContent } from 'src/components/common/ReferProContent';
import { ReferProDialogProps } from './ReferProDialog.types';

const UnstyledReferProDialog: React.FC<ReferProDialogProps> = ({
  className,
  style,
  onClose,
}) => {
  return (
    <Paper className={className} style={style}>
      <div className="ReferProDialog-heading">
        <Typography variant="branded.b18" component="h3" color="common.white">
          Moonsift Pro <ProTickTwoToneIcon />
        </Typography>
      </div>
      <Stack className="ReferProDialog-content" justifyContent="space-between">
        <ReferProContent />
        <Stack direction="row" justifyContent="flex-end">
          <StandardButton variant="primary-white" onClick={onClose}>
            Done
          </StandardButton>
        </Stack>
      </Stack>
    </Paper>
  );
};

export const ReferProDialog = styled(UnstyledReferProDialog)`
  position: relative;
  width: 480px;
  overflow: hidden;

  .ReferProDialog-content {
    height: calc(100% - 65px);
    padding: 24px 24px 28px;
  }

  .ReferProDialog-heading {
    padding: 20px 24px 16px;
    background: var(--common-black);

    h3 {
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .${proTickTwoToneIconClassNames.root} {
      color: var(--common-white);
      --secondary-color: var(--common-black);
      vertical-align: text-top;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    height: 528px;

    .ReferProDialog-content {
      padding: 32px 36px;
    }
  }
`;
