// ensures we stop listening!
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  baseDBGetDoc,
  baseDBListenDoc,
  baseDBUnlistenSingle,
} from 'src/store/actions/baseDB';
import { BaseDBKindNames, BaseDBKindsLookup, ParserFunction } from 'src/types';
import { DB } from 'src/services/DB';

/*

This hook is used to get a document from the database by initiating a saga that will place it in redux.

*/
export function useDBRetrieveDoc<N extends BaseDBKindNames>(
  dB: DB,
  dBRefFunction: (...args: any[]) => string, // document string reference
  kind: BaseDBKindsLookup[N]['name'],
  parser: ParserFunction<BaseDBKindsLookup[N]['item']>,
  storeAs: BaseDBKindsLookup[N]['storeAs'], // For maps, the key in the map store (otherwise undefined)
  args: any[], // arguments for the ref function and that also cause the query to be re-run (can overload the query with more arguments to make it rerun on them as well)
  method: 'listen' | 'get',
  unlisten: boolean = true, // when the component the hook is in is unmounted, should the db map (& potential listener) be removed?
  server: true | undefined = undefined,
) {
  const runQuery = args.reduce((a, b) => a && b, true) ? true : false;
  const dispatch = useDispatch();
  useEffect(() => {
    if (runQuery) {
      if (method === 'listen') {
        dispatch(
          baseDBListenDoc<BaseDBKindsLookup[N]>(
            {
              dBRef: dBRefFunction(...args),
              dB,
              parser,
            },
            kind,
            storeAs,
          ),
        );
      } else if (method === 'get') {
        dispatch(
          baseDBGetDoc<BaseDBKindsLookup[N]>(
            {
              dBRef: dBRefFunction(...args),
              dB,
              parser,
              server,
            },
            kind,
            storeAs,
          ),
        );
      }
      return unlisten
        ? () => {
            // clears got data to save memory and clears listeners if listening...
            dispatch(baseDBUnlistenSingle<BaseDBKindsLookup[N]>(kind, storeAs));
          }
        : () => null;
    }
    // Only args will change, hence only args or runQuery will change.
    // Note, as using spread need to disable the linter, cannot just pass args because ARRAY will change every render (non-primitive type).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dB,
    kind,
    dispatch,
    storeAs,
    parser,
    ...args, // eslint-disable-line react-hooks/exhaustive-deps
    runQuery,
    dBRefFunction,
    unlisten,
    method,
  ]);
}
