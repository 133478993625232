/*
Component to copy URLs to clipboard

This should be moved to a components diretory.
*/
import React from 'react';
import { Component } from 'react';
import { ServicesContext } from 'src/ServicesContext';
import styled from 'styled-components';
import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const LinkBox = styled.input`
  border-radius: 2px;
  border: 1px solid ${M.COL.LINE.MID};
  background-color: ${M.COL.BG.WHITE};
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  color: ${(props) => (props.disabled ? M.COL.TEXT.MID : M.COL.TEXT.BLACK)};
`;

const LinkBoxAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 36px;
`;

const CopyMessageContainer = styled.div`
  text-align: right;
`;

const LinkCopiedText = styled(TEXTSTYLE.BODY4)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  padding-right: 4px;
  padding-top: 4px;
`;

const WidthHundoP = styled.div`
  width: 100%;
`;

/**
 * Copy URL Within Popup Component
 */
export default class CopyURL extends Component {
  static contextType = ServicesContext;
  constructor() {
    super();
    // We can just use local state (not redux)
    // since this component is quite self-contained and separate to the rest and always mounted.
    this.state = {
      copySuccess: '',
    };
    this.copyToClipboard = this.copyToClipboard.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  copyToClipboard = () => {
    const { analytics } = this.context;
    analytics.recordEvent('WebApp:LinkCopied', { link: this.props.copyText });
    this.setState(
      {
        copySuccess: 'Link copied',
      },
      () =>
        setTimeout(
          () => this._isMounted && this.setState({ copySuccess: '' }),
          3000,
        ),
    );
  };

  render() {
    return (
      <WidthHundoP>
        <LinkBoxAndButtonContainer>
          <LinkBox
            defaultValue={this.props.copyText}
            disabled={this.props.disabled}
          />
          <CopyToClipboard
            text={this.props.copyText}
            onCopy={this.copyToClipboard}
          >
            <Button
              text="Copy"
              msstyle="small_option_dark"
              enabled={!(this.props.disabled === true)}
            />
          </CopyToClipboard>
        </LinkBoxAndButtonContainer>
        <CopyMessageContainer>
          <LinkCopiedText>{this.state.copySuccess}</LinkCopiedText>
        </CopyMessageContainer>
      </WidthHundoP>
    );
  }
}
