import { VFC, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { object, string, ref } from 'yup';

import { RootState } from 'src/index';
import { CreatorPayoutDetails } from 'src/types/models/creatorPayoutDetails.model';
import {
  updateCreatorPayoutDetails,
  requestCreatorPayout,
} from 'src/store/actions/creatorPayoutDetails';
import {
  PayoutDialogContainerProps,
  PayoutDialogProps,
  PaymentDetailsFormContent,
} from './PayoutDialog.types';
import { PayoutDialog } from './PayoutDialog.component';

const validationSchema = object<PaymentDetailsFormContent>({
  email: string()
    .email('Must be a valid email.')
    .max(254, 'Max 254 characters.')
    .required('Email is required.'),
  confirmEmail: string()
    .equals([ref('email')], "Emails don't match.")
    .required('Email confirmation is required.'),
  fullName: string()
    .max(50, 'Max 50 characters.')
    .required('Name cannot be blank.'),
}).required();

const orderedSteps: PayoutDialogProps['step'][] = [
  'details',
  'request',
  'requested',
];

function capIndex(index: number): number {
  return Math.min(Math.max(index, 0), orderedSteps.length);
}

const PayoutDialogContainer: VFC<PayoutDialogContainerProps> = (props) => {
  const dispatch = useDispatch();

  // Handlers to move back and forth in this three-step modal form.
  const [step, setStep] = useState<PayoutDialogProps['step']>('details');
  const handleBack = useCallback(() => {
    setStep((prevStep) => {
      const newIndex = orderedSteps.indexOf(prevStep) - 1;
      return orderedSteps[capIndex(newIndex)];
    });
  }, []);
  const handleForward = useCallback(() => {
    setStep((prevStep) => {
      const newIndex = orderedSteps.indexOf(prevStep) + 1;
      return orderedSteps[capIndex(newIndex)];
    });
  }, []);

  // Updates data in our store
  const handleInitialSubmit = useCallback<PayoutDialogProps['onComplete']>(
    ({ email, fullName }, actions) => {
      dispatch(updateCreatorPayoutDetails({ email, fullName }));
      handleForward();
      actions.setSubmitting(false);
    },
    [dispatch, handleForward],
  );

  // Actually sends request to server to update details
  const handleRequest = useCallback<PayoutDialogProps['onRequest']>(
    async (_params, actions) => {
      await dispatch(requestCreatorPayout());
      handleForward();
      actions.setSubmitting(false);
    },
    [dispatch, handleForward],
  );

  // DB creator payout details
  const dbCreatorPayoutDetails = useSelector<
    RootState,
    CreatorPayoutDetails | undefined
  >(({ db }) => db.user_verified_settings.data?.creator_payout_details);

  // Form creator payout details
  const formCreatorPayoutDetails = useSelector<RootState, CreatorPayoutDetails>(
    (state) => state.creatorPayoutDetails,
  );

  // Initialise form with data from previous payout requests if existing or use
  // data from the redux attributed destined for the form.
  const initialCreatorPayoutDetails =
    dbCreatorPayoutDetails ?? formCreatorPayoutDetails;

  const initialValues: PaymentDetailsFormContent = {
    ...initialCreatorPayoutDetails,
    confirmEmail: initialCreatorPayoutDetails.email,
  };

  return (
    <PayoutDialog
      step={step}
      initialValues={initialValues}
      validationSchema={validationSchema}
      fullName={formCreatorPayoutDetails.fullName}
      email={formCreatorPayoutDetails.email}
      onComplete={handleInitialSubmit}
      onBack={handleBack}
      onRequest={handleRequest}
      {...props}
    />
  );
};

export { PayoutDialogContainer as PayoutDialog };
