import { PeopleSayingSlideContent } from 'src/components/common/PeopleSayingSlide';

const standardSlides: PeopleSayingSlideContent[] = [
  {
    comment: `
      This revolutionary app gets a 10/10 from me and I recommend it to anyone
      that shops online.
    `,
    avatar: '/static/stylists/non-stylists/hillary.png',
    name: 'Hillary Roberts',
    social: { type: 'instagram', handle: '@hillary_roberts42' },
  },
  {
    comment: `
      I used to buy impulsively, but now I make more considered higher quality
      purchases.
    `,
    avatar: '/static/stylists/reviewers/nicole.png',
    name: 'Nicole Sweet | Stylist',
    social: { type: 'instagram', handle: '@nicolesweetstylist' },
  },
  {
    comment: `
      Love it! Such a useful app for shopping online and keeping everything in
      one place.
    `,
    avatar: '/static/stylists/non-stylists/maria.png',
    name: 'Maria Kovacevic',
    social: { type: 'instagram', handle: '@mariakovacevic1' },
  },
  {
    comment: `
      It's so easy to create boards and share them with friends and followers.
    `,
    avatar: '/static/stylists/reviewers/lynn.png',
    name: 'Lynn M | Brand Manager',
    social: { type: 'instagram', handle: '@lynnmikolajczak' },
  },
  {
    comment: `
      Moonsift is a dream! It's such a useful tool, and it has totally
      transformed the way I shop online.
    `,
    avatar: '/static/stylists/non-stylists/shore.png',
    name: 'Shore Delano',
    social: { type: 'instagram', handle: '@shoreyewande' },
  },
  {
    comment: `
      Your online shopping all saved in one place. It is genius and SO easy to
      use.
    `,
    avatar: '/static/stylists/reviewers/sian.png',
    name: 'Sian Clarke | Stylist',
    social: { type: 'instagram', handle: '@styled_by_sian' },
  },
  {
    comment: `
      Moonsift is the easy way to save items you love to shoppable mood
      boards.
    `,
    avatar: '/static/stylists/non-stylists/tia.png',
    name: 'Tia Hammond',
    social: { type: 'instagram', handle: '@tiabellex' },
  },
  {
    comment: `
      Before Moonsift I used to flick between tabs, or copy and paste links,
      it was such a pain.
    `,
    avatar: '/static/stylists/non-stylists/shope.png',
    name: 'Shopé Delano',
    social: { type: 'instagram', handle: '@shopedelano' },
  },
];

const registrySlides: PeopleSayingSlideContent[] = [
  {
    comment: `
    This revolutionary tool gets a 10/10 from me. It's perfect for making and sharing online gift lists.
    `,
    avatar: '/static/stylists/non-stylists/hillary.png',
    name: 'Hillary Roberts',
    social: { type: 'instagram', handle: '@hillary_roberts42' },
  },
  {
    comment: `
      I used to buy impulsively, but now I make more considered higher quality
      purchases.
    `,
    avatar: '/static/stylists/reviewers/nicole.png',
    name: 'Nicole Sweet | Stylist',
    social: { type: 'instagram', handle: '@nicolesweetstylist' },
  },
  {
    comment: `
    I've tested all the different online registries platforms and this is by far the best. Love it!
    `,
    avatar: '/static/stylists/non-stylists/maria.png',
    name: 'Maria Kovacevic',
    social: { type: 'instagram', handle: '@mariakovacevic1' },
  },
  {
    comment: `
    It's so easy to create shoppable boards and share them with friends and family.
    `,
    avatar: '/static/stylists/reviewers/lynn.png',
    name: 'Lynn M | Brand Manager',
    social: { type: 'instagram', handle: '@lynnmikolajczak' },
  },
  {
    comment: `
    Moonsift is the ultimate registry making platform. It has everything you need and is so easy to use.
    `,
    avatar: '/static/stylists/non-stylists/emily.png',
    name: 'Emily Smith',
    social: { type: 'instagram', handle: '@emilysmith9' },
  },
];

const creatorSlides: PeopleSayingSlideContent[] = [
  {
    comment: `
      Before Moonsift I was tearing my hair out trying to link all the items in my IG and YouTube channel.
    `,
    avatar: '/static/stylists/reviewers/melissa.png',
    name: 'Melissa Murrell',
    social: { type: 'youtube', handle: '@mmpersonalstyling' },
  },
  {
    comment: `
      It's so easy to create boards and share them with followers.
    `,
    avatar: '/static/stylists/reviewers/lynn.png',
    name: 'Lynn M',
    social: { type: 'instagram', handle: '@lynnmikolajczak' },
  },
  {
    comment: `
      It's really useful for having everything in one place. It's like Pinterest you can shop from.
    `,
    avatar: '/static/stylists/reviewers/abbey.png',
    name: 'Abbey Booth | Stylist',
    social: { type: 'instagram', handle: '@storieswithclothes' },
  },
  {
    comment: `
    Moonsift makes it so much easier for my viewers to browse and shop all the products I recommend.
    `,
    avatar: '/static/stylists/reviewers/nisha.png',
    name: 'Nisha Warren',
    social: { type: 'youtube', handle: '@SugarPuffAndFluff' },
  },
  {
    comment: `
    Moonsift is like Pinterest but for shopping! Save all the items you love to shoppable boards.
    `,
    avatar: '/static/stylists/non-stylists/tia.png',
    name: 'Tia Hammond',
    social: { type: 'instagram', handle: '@tiabellex' },
  },
];

export const slides = {
  standard: standardSlides,
  creator: creatorSlides,
  registry: registrySlides,
};
