/*

A place to store states across the app which are "working".  i.e. is an async task running or not?

*/
import { Action, ActionNames } from 'src/types';
import { WorkingState } from 'src/types/models/working.model';

// Note that working can be set as true OR false or can be unset to be undefined.
export function working(
  state: WorkingState = {},
  action: Action,
): WorkingState {
  if (action.type === ActionNames.WORKING_SET) {
    return {
      ...state,
      [action.payload.storeAs]: action.payload.value,
    };
  } else if (action.type === ActionNames.WORKING_UNSET) {
    const newDict = {
      ...state,
    };
    delete newDict[action.payload];
    return newDict;
  }
  return state;
}
