import { FC } from 'react';
import styled from 'styled-components';
import { CloseIcon, Stack } from '@moonsifttech/design-system';

import { GetStartedCloseButtonProps } from './GetStartedCloseButton.types';

const UnstlyedGetStartedCloseButton: FC<GetStartedCloseButtonProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      <CloseIcon />
    </Stack>
  );
};

export const GetStartedCloseButton = styled(UnstlyedGetStartedCloseButton)`
  width: 42px;
  height: 42px;
  cursor: pointer;

  ${({ theme }) => theme.fns.getShapeStyles('round')}
`;
