/*

Destruct the date into constituent parts (UTC time zone)

*/
export const getDateDestructed = (date: Date) => {
  return {
    year: date.getUTCFullYear().toString(),
    month: (date.getUTCMonth() + 1).toString().padStart(2, '0'),
    day: date.getUTCDate().toString().padStart(2, '0'),
    hours: date.getUTCHours().toString().padStart(2, '0'),
    minutes: date.getUTCMinutes().toString().padStart(2, '0'),
    seconds: date.getUTCSeconds().toString().padStart(2, '0'),
  };
};
