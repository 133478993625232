import { VFC, useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  EnableNotificationsPromptContainerProps,
  EnableNotificationsPromptProps,
  PromptQueryFormContent,
} from './EnableNotificationsPrompt.types';
import { EnableNotificationsPrompt } from './EnableNotificationsPrompt.component';
import { object, string } from 'yup';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';

const validationSchema = object({
  priceDrop: string().oneOf(['false', '5', '20']),
});

const EnableNotificationsPromptContainer: VFC<
  EnableNotificationsPromptContainerProps
> = ({ onClose, ...otherProps }) => {
  const { cloud, notifications } = useContext(ServicesContext);

  const handleSubmit = useCallback<EnableNotificationsPromptProps['onSubmit']>(
    async (values, actions) => {
      if (window.ReactNativeWebView) {
        notifications.enableAppNotifications();
      } else {
        notifications.enableBrowserNotifications();
      }
      await cloud.fastAPI({
        api: 'users_update_notifications',
        notifications: {
          price_notifications_on: values.priceDrop !== 'false',
          percentage_threshold:
            values.priceDrop === 'false' ? 20 : parseInt(values.priceDrop),
        },
      });
      actions.setSubmitting(false);
      onClose();
    },
    [cloud, notifications, onClose],
  );

  // TODO: remove typing once notifications is moved into the hooks system:
  const initialValues = useSelector<RootState, PromptQueryFormContent>(
    ({ firestore_notifications }) => {
      const typedNotificationsDoc = firestore_notifications as {
        notification_doc:
          | {
              price_notifications_on: boolean;
              percentage_threshold: number;
            }
          | undefined;
      };
      return {
        priceDrop: typedNotificationsDoc.notification_doc
          ? typedNotificationsDoc.notification_doc.price_notifications_on ===
            true
            ? (typedNotificationsDoc.notification_doc?.percentage_threshold).toString()
            : 'false'
          : '',
      } as { priceDrop: 'false' | '5' | '20' | '' };
    },
  );

  return (
    <EnableNotificationsPrompt
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onClose={onClose}
      {...otherProps}
    />
  );
};

export { EnableNotificationsPromptContainer as EnableNotificationsPrompt };
