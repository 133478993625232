import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledRadio,
  FormControlLabel,
  formControlLabelClassNames,
  ControlledRadioGroup,
  Stack,
  FormHelperText,
  Link,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';

import { CreatorApplicationSixProps } from './CreatorApplicationSix.types';
import R from 'src/routes';

const UnstyledCreatorApplicationSix: VFC<CreatorApplicationSixProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  formRef,
  errorMessage,
  onSubmit,
  onBack,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationSix-content">
        <Typography
          className="CreatorApplicationSix-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Would you like to feature a collection on the Explore page?
        </Typography>
        <Typography
          className="CreatorApplicationSix-heading"
          variant="primary.l20"
          component="p"
        >
          The Explore page helps inspire thousands of shoppers.
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form ref={formRef}>
              <ControlledRadioGroup
                className="CreatorApplicationSix-radios"
                name="feature"
              >
                <FormControlLabel
                  label="Yes"
                  labelPlacement="end"
                  value="Yes"
                  control={<ControlledRadio name="feature" />}
                />
                <FormControlLabel
                  label="No"
                  labelPlacement="end"
                  value="No"
                  control={<ControlledRadio name="feature" />}
                />
              </ControlledRadioGroup>
              <Stack
                className="CreatorApplicationSix-actions"
                direction="row"
                spacing={12}
              >
                <StandardButton variant="primary-white" onClick={onBack}>
                  Back
                </StandardButton>
                <StandardButton
                  type="submit"
                  variant="primary-black"
                  loading={isSubmitting}
                >
                  Done
                </StandardButton>
              </Stack>
              {errorMessage && (
                <FormHelperText error>{errorMessage}</FormHelperText>
              )}
            </Form>
          )}
        </Formik>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const CreatorApplicationSix = styled(UnstyledCreatorApplicationSix)`
  padding: 100px 0 0;

  .CreatorApplicationSix-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationSix-heading {
    margin-bottom: 24px;
  }

  .CreatorApplicationSix-radios {
    display: flex;
    margin-bottom: 24px;

    .${formControlLabelClassNames.root} {
      margin-bottom: 4px;
    }
  }

  .CreatorApplicationSix-actions {
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
