import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import {
  AddCashFundDialogContainerProps,
  AddCashFundDialogProps,
} from './AddCashFundDialog.types';
import { AddCashFundDialog } from './AddCashFundDialog.component';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { CollectionDocument, CashFund } from 'src/types/models/collection.model';

const formValidationSchema = object({
  name: string().required(),
  notes: string(),
  details: string().required(),
}).required();

const emptyCashFund = { name: '', notes: '', details: '' };

const AddCashFundDialogContainer = ({
  collectionUID,
  collectionUserUID,
  onClose,
  isMine,
  isRevealing,
  ...otherProps
}: AddCashFundDialogContainerProps) => {
  const { db } = useContext(ServicesContext);
  const authUserUID = useSelector<RootState, string>(
    (state) => state.auth.id ?? 'no_user',
  );

  const cashFund = useSelector<RootState, CashFund | undefined>(
    (state) => state.db.single_collection.data?.cash_fund,
  );

  const [updateCollectionDocument, isSaving] = useDBUpdateDoc<Partial<CollectionDocument>>(
    db,
    db.collectionDoc(authUserUID, collectionUID),
  );

  const handleSubmit = useCallback<
    AddCashFundDialogProps['onSubmit']
  >(
    async (form) => {
      await updateCollectionDocument({ cash_fund: form });
      onClose();
    },
    [onClose, updateCollectionDocument],
  );
  const handleReset = useCallback<
    AddCashFundDialogProps['onSubmit']
  >(
    async () => {
      await updateCollectionDocument({ cash_fund: emptyCashFund });
      onClose();
    },
    [onClose, updateCollectionDocument],
  );

  return (
    <AddCashFundDialog
      loading={isSaving}
      initialFormValues={cashFund ?? emptyCashFund}
      formValidationSchema={formValidationSchema}
      onClose={onClose}
      onSubmit={handleSubmit}
      onReset={handleReset}
      isMine={isMine}
      isRevealing={isRevealing}
      {...otherProps}
    />
  );
};

export { AddCashFundDialogContainer as AddCashFundDialog };
