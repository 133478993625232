/*
  Shown as a confirmation message that a user has already marked an item as bought if they attempt to use the "Go to item" flow.
  Acts as a warning to not remark the item as bought before leaving the site.
  Provides entry into: marked-as-bought flow, so user can add or edit a message.
  Provides entry into: go-to-item flow, so user can view the item on the retailer site.
*/

import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  CheckCircleIcon,
  LaunchIcon,
  checkCircleIconClassNames,
} from '@moonsifttech/design-system';
import { useItem } from 'src/hooks/useItem';
import { useGoToItem } from 'src/hooks/useGoToItem';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { ModalContent } from 'src/components/core/ModalContent';
import { UnstyledLink } from 'src/components/core/UnstyledLink';

const UnstyledMarkedAsBoughtSlimDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ setModal, closeModal ] = useModal();
  const { url, goToItem } = useGoToItem({
    collectionId,
    collectionUserId,
    itemId,
  });

  const { ownerOnlyPurchase } = useItem(itemId);

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography
        className="MarkedAsBoughtSlimDialog-checkWrapper"
        variant="primary.b26"
        component="h1"
      >
        <CheckCircleIcon size="larger" />
        <span>Marked as bought</span>
      </Typography>

      <StandardButton
        className="MarkedAsBoughtSlimDialog-continueButton"
        variant="primary-white"
        fullWidth
        endIcon={<LaunchIcon />}
        component={UnstyledLink}
        to={url}
        target="_blank"
        onClick={goToItem}
      >
        Continue to item
      </StandardButton>

      <StandardButton
        variant="primary-black"
        fullWidth
        startIcon={<CheckCircleIcon />}
        onClick={() => setModal({
          type: ownerOnlyPurchase
            ? ModalTypes.MarkedAsBoughtSlimOwner
            : ModalTypes.MarkedAsBought,
          collectionId,
          collectionUserId,
          itemId,
        })}
      >
        Bought
      </StandardButton>
    </ModalContent>
  );
};

export const MarkedAsBoughtSlimDialog = styled(UnstyledMarkedAsBoughtSlimDialog)`
  .MarkedAsBoughtSlimDialog-checkWrapper {
    display: flex;
    align-items: center;

    > .${checkCircleIconClassNames.root} {
      min-width: 16px;
      margin-right: 0.5rem;
    }
  }

  .MarkedAsBoughtSlimDialog-continueButton {
    margin-bottom: 1rem;
  }
`;
