/*

For displaying the current product in the left of the product memebrship group + price updates modals.

TODO: typescript


*/
import React from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import LoadCheck from 'src/mvp22/image-components/LoadCheck';
import {
  BothBottomBar
} from 'src/mvp22/modal-components/ModalComponents.js';

const LeftContainerProduct = styled.div`
  height:100%;
  display:flex;
  flex-direction:column;
  max-width: 40%;
`;

const LeftImageProductContainer = styled.div`
  height:100%;
  flex-shrink: 10;
  justify-content: center;
  display:flex;
`;

const LeftImageProduct = styled.img`
  max-width:100%;
  max-height:100%;
  width:auto;
  object-fit: contain;
`;

const ProductDescription = styled(TEXTSTYLE.BODY5)`
  width: 100%;
  overflow: hidden;
  height:18px;
  text-overflow:ellipsis;
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;
`;

const ProductPrice = styled(TEXTSTYLE.OTHER4)`
  width: 100%;
  overflow: hidden;
  height:18px;
  text-overflow:ellipsis;
  flex-shrink: 0;
  white-space: nowrap;
  overflow-x: hidden;
`;

export const ProductLeft = (
  {
    productData,
    productImage
  }
)=>{
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  return (windowWidth>=M.LEFTPRODUCTSWITCH)?
    <LeftContainerProduct>
      <LeftImageProductContainer>
        <LoadCheck src={productImage}>
          <LeftImageProduct/>
        </LoadCheck>
      </LeftImageProductContainer>
      <BothBottomBar>
        <ProductDescription>{productData?productData.description:""}</ProductDescription>
        <ProductPrice>{productData?productData.price:""}</ProductPrice>
      </BothBottomBar>
    </LeftContainerProduct>
  :
    null;
};
