import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  AddIcon,
  MoonsiftLogo,
  ShopfrontIcon,
} from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContent } from 'src/components/core/MenuContent';
import { AddItemsMenuProps } from './AddItemsMenu.types';

const UnstyledAddItemsMenu = React.forwardRef<
  HTMLDivElement,
  AddItemsMenuProps
>(
  (
    {
      className,
      style,
      openAddItemModal,
      isCreator,
      isUnsupportedDevice,
      openAddItemDirectly,
      goToRetailers,
    },
    ref,
  ) => {
    return (
      <MenuContent ref={ref} className={className} style={style}>
        <List>
          {
            <ListItem onClick={openAddItemDirectly}>
              <ListItemIcon>
                <MoonsiftLogo />
              </ListItemIcon>
              <ListItemText>
                Add items directly from any website or app
              </ListItemText>
            </ListItem>
          }
          {(isCreator || isUnsupportedDevice) && (
            <ListItem onClick={openAddItemModal}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText>Add item via link or manually</ListItemText>
            </ListItem>
          )}
          <ListItem onClick={goToRetailers}>
            <ListItemIcon>
              <ShopfrontIcon />
            </ListItemIcon>
            <ListItemText>
              Browse {isCreator ? `our affiliate brands` : `popular retailers`}
            </ListItemText>
          </ListItem>
        </List>
      </MenuContent>
    );
  },
);

export const AddItemsMenu = styled(UnstyledAddItemsMenu)`
  .${modalMenuClassNames.isDesktop} & {
    width: 354px;
  }
`;
