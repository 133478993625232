import { FC } from 'react';
import { Link as RoutingLink } from 'react-router-dom';

import { UnstyledLinkProps } from './UnstyledLink.types';

// This Link component just uses React Router's own Link as base when the
// provided `href` points to an internal page and uses the default HTML anchor
// element otherwise.
export const UnstyledLink: FC<UnstyledLinkProps> = ({
  href,
  ...otherProps
}) => {
  // If no href is provided, consider it's a regular anchor tag.
  const isExternal = href?.includes('//') ?? true;

  if (isExternal) {
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a href={href} {...otherProps} />;
  }

  return <RoutingLink to={href!} {...otherProps} />;
};
