import {
  currency_parser,
  price_parser,
} from 'src/mvp22/core-components/price_functions';
import { Constants } from 'src/constants';

type Input = {
  url: string;
  siteUID: string;
  price: string;
  imageURL: string;
  description: string;
}

const queryBase = {
  form: {
    queryMain: '',
    queryPositive: '',
    queryNegative: '',
    priceRange: {
      min: '0',
      max: '',
    },
    includeMarketPlaces: [],
    imageWeight: '1',
  },
  currencies: ['ALL'],
  searchOnProduct: {},
};

export function buildMoonroseUrl(input: Input): string {
  const currency = currency_parser(input.price) ?? 'USD';
  const currencyExcludeDol = currency === 'DOL' ? 'USD' : currency;

  const query = {
    ...queryBase,
    searchOnProduct: {
      url: input.url,
      best_description: input.description,
      best_image: input.imageURL,
      best_price: input.price,
      site_uid: input.siteUID,
      currency_guess: currencyExcludeDol,
      price_guess: price_parser(input.price) ?? 0,
      is_wishlist_product: true,
    },
  };

  const search = encodeURIComponent(JSON.stringify(query));
  return `${Constants.SEARCH_ADDRESS}?pageDataKey=${search}`;
}
