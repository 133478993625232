/*

TODO: This reducer only stores one variable now which is set when the user data has been loaded!
(See firebase_user_set_up.js)

It used to be used for storing user id and email.

*/
import {
  FIREBASE_SET_AUTH,
  FIRESTORE_REDUX_REMOVE_ALL
} from 'src/mvp22/redux-components/actions';

const DEFAULT_FIREBASE = {
  loaded:false
};


function firebaseState(state = DEFAULT_FIREBASE, action) {
  //console.log(action.type,action.data)
  switch (action.type) {
    case FIREBASE_SET_AUTH:
      return Object.assign({},state,action.data);
    case FIRESTORE_REDUX_REMOVE_ALL:
      return DEFAULT_FIREBASE;
    default:
      return state;
  }
}


export default firebaseState;
