import React from 'react';
import { AddAnItemPasteALinkProps } from './AddAnItemPasteALink.types';
import {
  Alert,
  ControlledTextField,
  Stack,
  StandardButton,
  Typography,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

const UnstyledAddAnItemPasteALink: React.FC<AddAnItemPasteALinkProps> = ({
  className,
  onAddLink,
  style,
  validationSchema,
}) => {
  return (
    <Stack className={className} style={style}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ pastedLink: '' }}
        onSubmit={onAddLink}
        className="AddAnItemPasteALink-form"
      >
        {({ dirty, isSubmitting }) => (
          <Form>
            <Alert severity="info" className="AddAnItemPasteALink-info">
              Pasting a link is a <b>new feature</b>. Please leave feedback via
              the <b>feedback button</b>.
            </Alert>
            <Typography
              variant="primary.r14"
              align="left"
              component="p"
              style={{ margin: '20px 0px 0px' }}
            >
              Paste a link to any product
            </Typography>
            <ControlledTextField
              className="AddAnItemPasteALink-input"
              name="pastedLink"
              placeholder="https://"
              autoComplete="off"
              fullWidth
            />
            <StandardButton
              variant="primary-black"
              loading={isSubmitting}
              type="submit"
              disabled={dirty === false}
            >
              Add item
            </StandardButton>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export const AddAnItemPasteALink = styled(UnstyledAddAnItemPasteALink)`
  .AddAnItemPasteALink-input {
    margin-bottom: 12px;
  }
  .AddAnItemPasteALink-info {
    margin-bottom: 12px;
  }
`;
