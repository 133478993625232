/*

View for the ability to change emails received.

TODO: typescript, camelCase, make functional, non-default exports, design system...
Notification doc and authUserVerifiedSettings should be in redux db already as hooks in the main app component, so won't need to retrieve them here.



*/
import React from 'react';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { ServicesContext } from 'src/ServicesContext';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import EnableNotifications from 'src/mvp22/menu-components/EnableNotifications';
import { M } from 'src/mvp22/constants';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import MEDIA from 'src/mvp22/media';
import get_user_verified_settings from 'src/mvp22/firebase-functions/get_user_verified_settings';
import { requiresAuth } from 'src/mvp22/redux-components/requiresAuth';

export const SUBSCRIPTION_DICT = {
  GENERAL_COMMUNICATIONS: {
    title: 'General communications',
    description: 'Announcements and updates (1 / month max)',
  },
  COMMUNITY_TRENDS: {
    title: 'Collections from the community',
    description: 'Get inspiration from other Moonsifters',
  },
  HANDY_HINTS: {
    title: 'Handy hints',
    description: 'Tips on how to get the most out of Moonsift',
  },
  PROMOTIONS: {
    title: 'Giveaways and rewards',
    description: 'Exclusive giveaways and competitions',
  },
  FEEDBACK_REQUESTS: {
    title: 'Requests for feedback',
    description: 'Help improve the Moonsift experience (few / year max)',
  },
};

const SUBSCRIPTION_ARRAY = Object.keys(SUBSCRIPTION_DICT).map((x) =>
  Object.assign({ key: x }, SUBSCRIPTION_DICT[x]),
);

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  color: ${M.COL.TEXT.BLACK};
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 368px;
  margin-top: 119px;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 10px;
`;

const FormEmailContainer = styled(TEXTSTYLE.BODY4)`
  color: default;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const PreferenceTitle = styled(TEXTSTYLE.BODY4)`
  font-weight: bold;
  text-align: left;
`;

const PreferenceDesc = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
  text-align: left;
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 20px;
  margin-top: 26px;
`;

const TickBox = styled.img`
  height: 16px;
  margin-right: 10px;
  position: relative;
  top: 6px;
  cursor: pointer;
`;

const PreferenceContainer = styled(TEXTSTYLE.LINKA)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
  cursor: pointer;
`;

const PreferenceDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SaveNotificationContainer = styled.div`
  text-align: right;
  margin-right: 28px;
  position: relative;
  bottom: 10px;
  height: 20px;
  top: 20px;
  right: 5px;
`;

const SaveNotificationText = styled(TEXTSTYLE.BODY4)`
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  padding-right: 4px;
  padding-top: 4px;
`;

const SepLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${M.COL.LINE.MID};
  margin: 16px 0px;
`;

const EditTitle = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const EditSubTitle = styled(TEXTSTYLE.HEADING5)`
  color: default;
`;

const BrowserNotificationsContainer = styled.div`
  opacity: ${(props) => (props.enabled === true ? 1.0 : 0.5)};
`;

const EditSubTitleContainer = styled(TEXTSTYLE.LINKA)`
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;

const EditImage = styled.img`
  height: 20px;
  width: 20px;
`;

const EditDescription = styled(TEXTSTYLE.BODY4)`
  display: flex;
  justify-content: flex-start;
  color: ${M.COL.TEXT.MID};
`;

const EditDetails = styled.div`
  height: ${(props) => (props.open ? 'auto' : '0px')};
  overflow: hidden;
`;

const NotificationBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NotificationTickBoxContainer = styled.div`
  bottom: 5px;
  position: relative;
`;

const NotificationTitle = styled(TEXTSTYLE.OTHER2)`
  width: 100%;
  text-align: left;
  margin: 12px 0px;
`;

const AlreadyGotNotifications = styled(TEXTSTYLE.BODY4)`
  padding-top: 8px;
  font-weight: bold;
  text-align: left;
  box-sizing: content-box;
`;

const TickImage = styled.img`
  height: 12px;
  width: 12px;
  display: inline-block;
  padding-right: 8px;
`;

const NotificationTickBlock = styled(TEXTSTYLE.LINKA)`
  display: flex;
  text-align: left;
`;

const ExplanitoryNote = styled(TEXTSTYLE.BODY5)`
  text-align: left;
  margin-bottom: 12px;
  color: ${M.COL.TEXT.MID};
  margin-top: -6px;
`;

class EditEmailPreferences extends Component {
  static contextType = ServicesContext;

  constructor(props) {
    super(props);
    this.state = {
      changed_once_browser: false,
      changed_once_email: false,
      loaded_email: false,
      subscriptions: {},
      notifications_open: {
        browser: false,
        email: false,
      },
      saved_message_browser: '',
      saved_message_email: '',
      submitting_browser: false,
      submitting_email: false,
      notifications_enabled: false,
    };
    this.toggleTickEMail = this.toggleTickEMail.bind(this);
    this.toggleTickBrowser = this.toggleTickBrowser.bind(this);
    this.doSubmitEmail = this.doSubmitEmail.bind(this);
    this.import_settings = this.import_settings.bind(this);
    this.update_settings = this.update_settings.bind(this);
    this.clear_listener = this.clear_listener.bind(this);
    this.expand_notifications = this.expand_notifications.bind(this);
    this.setNotificationsEnabled = this.setNotificationsEnabled.bind(this);
    this.doSubmitBrowser = this.doSubmitBrowser.bind(this);
  }

  toggleTickBrowser(target_name, new_value, event) {
    this.setState((prevState) => {
      if (prevState.notifications_enabled === true) {
        return {
          notification_settings: Object.assign(
            {},
            this.state.notification_settings,
            { [target_name]: new_value },
          ),
          changed_once_browser: true,
        };
      }
    });
    event.preventDefault();
    return false;
  }

  toggleTickEMail(target_name, event) {
    this.setState((prevState) => {
      const new_value = prevState.subscriptions[target_name]
        ? !prevState.subscriptions[target_name]
        : true;
      return {
        subscriptions: Object.assign({}, this.state.subscriptions, {
          [target_name]: new_value,
        }),
        changed_once_email: true,
      };
    });
    event.preventDefault();
    return false;
  }

  update_settings(data) {
    const subscriptionsFromData = data.subscriptions ?? [];
    const subscriptions = {};
    subscriptionsFromData.forEach((x) => (subscriptions[x] = true));
    this.setState({
      subscriptions,
      loaded_email: true,
    });
  }

  clear_listener(prevState = this.state) {
    if (prevState.settings_listener) {
      prevState.settings_listener();
    }
  }

  import_settings() {
    this.setState((prevState, props) => {
      var dict_out = undefined;
      if (prevState.user_uid !== props.authUserUID) {
        this.clear_listener(prevState);
        dict_out = Object.assign({}, dict_out, {
          settings_listener: get_user_verified_settings(
            props.firebase,
            props.authUserUID,
            this.update_settings,
          ),
          user_uid: props.authUserUID,
        });
      }
      if (
        prevState.notification_doc !== props.notification_doc &&
        props.notification_doc
      ) {
        var drop = props.notification_doc.price_notifications_on === true;
        if (drop === true) {
          drop = props.notification_doc.percentage_threshold;
        }
        const comments =
          props.notification_doc.comment_notifications_on === true;
        dict_out = Object.assign({}, dict_out, {
          notification_doc: props.notification_doc,
          notification_settings: {
            drop,
            comments,
          },
        });
      }
      return dict_out;
    });
  }

  expand_notifications(event, type) {
    event.preventDefault();
    this.setState((prevState) => ({
      notifications_open: Object.assign({}, prevState.notifications_open, {
        [type]: !prevState.notifications_open[type],
      }),
    }));
    return false;
  }

  setNotificationsEnabled(value) {
    this.setState({
      notifications_enabled: value,
    });
  }

  componentDidMount() {
    this._isMounted = true;
    this.import_settings();
    const { notifications } = this.context;

    // Get notification status from app if we are in the app
    if (window.ReactNativeWebView) {
      notifications
        .checkAppNotifications()
        .then(({ enabled }) => {
          this.setState({ notifications_enabled: enabled });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ notifications_enabled: false });
        });
    } else {
      const notifications_enabled =
        'Notification' in window && Notification.permission === 'granted';
      this.setState({ notifications_enabled });
    }
  }

  componentWillUnmount() {
    this.clear_listener();
    this._isMounted = false;
  }

  componentDidUpdate() {
    this.import_settings();
  }

  doSubmitEmail() {
    this.setState({
      submitting_email: true,
    });
    const subscriptions = [];
    for (let this_key in this.state.subscriptions) {
      if (this.state.subscriptions[this_key] === true) {
        subscriptions.push(this_key);
      }
    }
    this.props.firebase
      .fast_api()({
        api: 'users_update_subscriptions',
        subscriptions,
      })
      .then((response) => {
        if (this._isMounted) {
          this.setState(
            {
              submitting_email: false,
              saved_message_email: response.data
                ? 'Saved successfully'
                : 'Save failed',
              changed_once_email: response.data ? true : false,
            },
            () =>
              setTimeout(
                () => this.setState({ saved_message_email: '' }),
                3000,
              ),
          );
        }
      })
      .catch((error) => {
        console.error('Error saving settings', error);
        this.setState(
          {
            submitting_email: false,
            saved_message_email: 'Save failed',
          },
          () =>
            setTimeout(() => this.setState({ saved_message_email: '' }), 3000),
        );
      });
  }

  doSubmitBrowser() {
    this.setState({
      submitting_browser: true,
    });
    const notifications = {
      comment_notifications_on:
        this.state.notification_settings.comments === true,
    };
    if (this.state.notification_settings.drop !== false) {
      notifications.percentage_threshold =
        this.state.notification_settings.drop;
      notifications.price_notifications_on = true;
    } else {
      notifications.price_notifications_on = false;
    }
    this.props.firebase
      .fast_api()({
        api: 'users_update_notifications',
        notifications,
      })
      .then((response) => {
        if (this._isMounted) {
          this.setState(
            {
              submitting_browser: false,
              saved_message_browser: response.data
                ? 'Saved successfully'
                : 'Save failed',
              changed_once_browser: response.data ? true : false,
            },
            () =>
              setTimeout(
                () => this.setState({ saved_message_browser: '' }),
                3000,
              ),
          );
        }
      })
      .catch((error) => {
        console.error('Error saving settings', error);
        this.setState(
          {
            submitting_browser: false,
            saved_message_browser: 'Save failed',
          },
          () =>
            setTimeout(
              () => this.setState({ saved_message_browser: '' }),
              3000,
            ),
        );
      });
  }

  render() {
    return (
      <FullBlank className="fullBlank">
        <TopMenu hug="edge" />
        {AddHelmetTitle('Edit Email & Notification Preferences')}
        <Container className="container">
          <FormContainer>
            <FormHeadingContainer>
              <TEXTSTYLE.HEADING2>Email & Notifications</TEXTSTYLE.HEADING2>
            </FormHeadingContainer>
            <EditTitle>
              <EditSubTitleContainer
                href="#"
                onClick={(event) => this.expand_notifications(event, 'browser')}
              >
                <EditSubTitle>Notifications</EditSubTitle>
                <EditImage src={MEDIA.EDIT} />
              </EditSubTitleContainer>
              <EditDescription>
                Recommended for price drops on saved items
              </EditDescription>
            </EditTitle>
            <EditDetails open={this.state.notifications_open['browser']}>
              {this.state.notifications_enabled !== true ? (
                <EnableNotifications
                  setNotificationsEnabled={this.setNotificationsEnabled}
                  show_notification_notification={true}
                />
              ) : (
                <AlreadyGotNotifications>
                  <TickImage src={MEDIA.TICK} />
                  Notifications are enabled on this device
                </AlreadyGotNotifications>
              )}
              {this.state.notification_settings ? (
                <>
                  <BrowserNotificationsContainer
                    enabled={this.state.notifications_enabled}
                  >
                    <NotificationBlock>
                      <NotificationTitle>Price drops</NotificationTitle>
                      <ExplanitoryNote>
                        This affects all collections for which notifications are
                        on
                      </ExplanitoryNote>
                      <NotificationTickBlock
                        href="#"
                        onClick={(event) =>
                          this.toggleTickBrowser('drop', 5, event)
                        }
                      >
                        <NotificationTickBoxContainer>
                          <TickBox
                            src={
                              this.state.notification_settings.drop === 5
                                ? MEDIA.TICKBOXFULL
                                : MEDIA.TICKBOXEMPTY
                            }
                          />
                        </NotificationTickBoxContainer>
                        <TEXTSTYLE.BODY4>Price drops over 5%</TEXTSTYLE.BODY4>
                      </NotificationTickBlock>
                      <NotificationTickBlock
                        href="#"
                        onClick={(event) =>
                          this.toggleTickBrowser('drop', 20, event)
                        }
                      >
                        <NotificationTickBoxContainer>
                          <TickBox
                            src={
                              this.state.notification_settings.drop === 20
                                ? MEDIA.TICKBOXFULL
                                : MEDIA.TICKBOXEMPTY
                            }
                          />
                        </NotificationTickBoxContainer>
                        <TEXTSTYLE.BODY4>Price drops over 20%</TEXTSTYLE.BODY4>
                      </NotificationTickBlock>
                      <NotificationTickBlock
                        href="#"
                        onClick={(event) =>
                          this.toggleTickBrowser('drop', false, event)
                        }
                      >
                        <NotificationTickBoxContainer>
                          <TickBox
                            src={
                              this.state.notification_settings.drop === false
                                ? MEDIA.TICKBOXFULL
                                : MEDIA.TICKBOXEMPTY
                            }
                          />
                        </NotificationTickBoxContainer>
                        <TEXTSTYLE.BODY4>
                          Turn off price notifications
                        </TEXTSTYLE.BODY4>
                      </NotificationTickBlock>
                    </NotificationBlock>
                    <NotificationBlock>
                      <NotificationTitle>
                        Comments on my collections
                      </NotificationTitle>
                      <NotificationTickBlock
                        href="#"
                        onClick={(event) =>
                          this.toggleTickBrowser('comments', true, event)
                        }
                      >
                        <NotificationTickBoxContainer>
                          <TickBox
                            src={
                              this.state.notification_settings.comments === true
                                ? MEDIA.TICKBOXFULL
                                : MEDIA.TICKBOXEMPTY
                            }
                          />
                        </NotificationTickBoxContainer>
                        <TEXTSTYLE.BODY4>
                          New comments / reactions to my saved items
                        </TEXTSTYLE.BODY4>
                      </NotificationTickBlock>
                      <NotificationTickBlock
                        href="#"
                        onClick={(event) =>
                          this.toggleTickBrowser('comments', false, event)
                        }
                      >
                        <NotificationTickBoxContainer>
                          <TickBox
                            src={
                              this.state.notification_settings.comments ===
                              false
                                ? MEDIA.TICKBOXFULL
                                : MEDIA.TICKBOXEMPTY
                            }
                          />
                        </NotificationTickBoxContainer>
                        <TEXTSTYLE.BODY4>
                          Turn off comment notifications
                        </TEXTSTYLE.BODY4>
                      </NotificationTickBlock>
                    </NotificationBlock>
                  </BrowserNotificationsContainer>
                  <SaveNotificationContainer>
                    <SaveNotificationText>
                      {this.state.saved_message_browser}
                    </SaveNotificationText>
                  </SaveNotificationContainer>
                  <CancelAndConfirmButtonsContainer>
                    <Button
                      text={'Save'}
                      working_overlay={this.state.submitting_browser}
                      msstyle="option_positive"
                      onClick={this.doSubmitBrowser}
                      enabled={this.state.changed_once_browser}
                      width="123px"
                      tabIndex={201}
                    />
                  </CancelAndConfirmButtonsContainer>
                </>
              ) : (
                ''
              )}
            </EditDetails>
            <SepLine />
            <EditTitle>
              <EditSubTitleContainer
                href="#"
                onClick={(event) => this.expand_notifications(event, 'email')}
              >
                <EditSubTitle>Email notifications</EditSubTitle>
                <EditImage src={MEDIA.EDIT} />
              </EditSubTitleContainer>
              <EditDescription>Choose which emails you receive</EditDescription>
            </EditTitle>
            <EditDetails open={this.state.notifications_open['email']}>
              {this.state.loaded_email === true ? (
                <>
                  <FormEmailContainer>
                    <TEXTSTYLE.BODY3>
                      {this.props.authUserEmail}
                    </TEXTSTYLE.BODY3>
                  </FormEmailContainer>
                  {SUBSCRIPTION_ARRAY.map((item) => (
                    <PreferenceContainer
                      key={item.key}
                      onClick={(event) => {
                        event.preventDefault();
                        this.toggleTickEMail(item.key, event);
                        return false;
                      }}
                      href="#"
                    >
                      <TickBox
                        src={
                          this.state.subscriptions[item.key] === true
                            ? MEDIA.TICKBOXFULL
                            : MEDIA.TICKBOXEMPTY
                        }
                      />
                      <PreferenceDescContainer>
                        <PreferenceTitle>{item.title}</PreferenceTitle>
                        <PreferenceDesc>{item.description}</PreferenceDesc>
                      </PreferenceDescContainer>
                    </PreferenceContainer>
                  ))}
                  <SaveNotificationContainer>
                    <SaveNotificationText>
                      {this.state.saved_message_email}
                    </SaveNotificationText>
                  </SaveNotificationContainer>
                  <CancelAndConfirmButtonsContainer>
                    <Button
                      text={'Save'}
                      working_overlay={this.state.submitting_email}
                      msstyle="option_positive"
                      onClick={this.doSubmitEmail}
                      enabled={this.state.changed_once_email}
                      width="123px"
                      tabIndex={201}
                    />
                  </CancelAndConfirmButtonsContainer>
                  <Seperator height="24px" />
                </>
              ) : (
                <TEXTSTYLE.HEADING5>Loading...</TEXTSTYLE.HEADING5>
              )}
            </EditDetails>
            <Seperator height="10px" />
          </FormContainer>
        </Container>
      </FullBlank>
    );
  }
}

// Pass the state values into the component:
const mapStateToProps = (state) => {
  return {
    authUserUID: state.auth.id,
    authUserEmail: state.auth.email,
    notification_doc: state.firestore_notifications.notification_doc,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(requiresAuth(withFirebase(EditEmailPreferences))),
);
