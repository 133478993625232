/*

Move to utils

*/

// This function takes new subsection specifications and order specifications and sanitises them to make sure they are consistent
// It should be called whenever making a change to order array / subsection order array / subsection map.
const redraftOrders = (new_subsection_order,new_item_order,new_organising_item_to_subsection_map)=>{
  const outgoing_item_order = [...new_subsection_order,undefined].map(
    (subsection_uid)=>
      new_item_order.filter(
        item_uid=>new_organising_item_to_subsection_map[item_uid]===subsection_uid
      )
  ).flat(); // array of arrays to array
  return outgoing_item_order;
};
export default redraftOrders;
