/*

This hook preloads the product data at the product tile list level, thus
enabling us to know when all data is loaded and ready to show to prevent
products appearing haphazardly.

TODO: once we have a product tile that can render without data (as a
placeholder) we won't need to determine at this level when the data has loaded
and we are ready to show it.

*/
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { PseudoProductElement } from 'src/components/collection/PseudoProductElement';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
export const useLoadedProducts = (
  collectionUID: string,
  collectionUserUID: string,
) => {
  // Fetch all the associated collection doc items in a pseudo-element (so we
  // can don't have to deal with subsections etc.) This allows us to detect when
  // the data is all loaded as opposed to loading this data in the ProductTile
  // component. This will populate redux:
  const singleCollectionItemListData = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );
  const renderPseudoCollectionItems = () =>
    singleCollectionItemListData.map((collectionItem) => (
      <PseudoProductElement
        key={collectionItem.id}
        {...{
          collectionUID,
          collectionUserUID,
          collectionItem,
        }}
      />
    ));
  // Determine if all the products are loaded and we are ready to show the list:
  // Note that this selector is complicated and will run A LOT, but the rerender
  // will not happen as much as a result.
  const loadedProducts = useSelector<RootState, boolean>((state) => {
    const singleCollectionItemListDataLoaded =
      state.db.single_collection_item_list.set;
    const singleCollectionItemListData =
      state.db.single_collection_item_list.data;
    const productData = state.productData;
    if (productData && singleCollectionItemListDataLoaded) {
      return singleCollectionItemListData
        .every((collectionItem) =>
          productData[
            getCollectionItemStoreAs(
              collectionUID,
              collectionUserUID,
              collectionItem.id,
            )
          ] != null
        );
    }
    return false;
  });

  return {
    loadedProducts,
    renderPseudoCollectionItems,
  };
};
