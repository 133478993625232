import { VFC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Stack,
  Typography,
  StandardButton,
  paperClassNames,
} from '@moonsifttech/design-system';

import { EXTERNAL } from 'src/routes';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { GetAppDialogProps } from './GetAppDialog.types';

const UnstyledGetAppDialog: VFC<GetAppDialogProps> = ({
  className,
  style,
  onAppStoreLinkClick,
}) => {
  return (
    <Paper className={className} style={style} variant="outlined">
      <Stack
        className="GetAppDialog-closing"
        direction="column"
        justifyContent="space-between"
      >
        <div>
          <Typography
            className="GetAppDialog-title"
            variant="primary.b18"
            component="h3"
          >
            Get the iOS app to save items to your collections from any store
          </Typography>
          <Typography variant="primary.r16" component="p">
            The app will enable you to save items to Moonsift from any store.
          </Typography>
        </div>

        <StandardButton
          variant="primary-black"
          fullWidth
          component={UnstyledLink}
          to={EXTERNAL.APP_STORE_APP}
          target="_blank"
          onClick={onAppStoreLinkClick}
        >
          Get the app - it's free
        </StandardButton>
      </Stack>
    </Paper>
  );
};

export const GetAppDialog = styled(UnstyledGetAppDialog)`
  position: relative;
  width: 316px;
  height: 220px;
  padding: 20px;

  ${({ theme }) => theme.fns.getShapeStyles('soft')}

  &.${paperClassNames.variantOutlined} {
    border: 2px solid var(--background-purple);
  }

  > div {
    height: 100%;
  }
`;
