import { VFC } from 'react';
import styled from 'styled-components';
import TablePagination from '@mui/material/TablePagination';

import { EarningsTablePaginationProps } from './EarningsTablePagination.types';

const UnstyledEarningsTablePagination: VFC<EarningsTablePaginationProps> = (
  props,
) => {
  return <TablePagination {...props} />;
};

export const EarningsTablePagination = styled(UnstyledEarningsTablePagination)`
  .MuiToolbar-root {
    min-height: 40px;
  }

  .MuiTablePagination-selectLabel,
  .MuiInputBase-root {
    display: none;
  }

  .MuiTablePagination-displayedRows {
    margin-bottom: 0;
    ${({ theme }) => theme.fns.getTypographyStyles('primary.l14')}
  }
`;
