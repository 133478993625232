import { FirebaseApp } from 'firebase/app';
import {
  getStorage,
  ref,
  getDownloadURL,
  connectStorageEmulator,
  FirebaseStorage,
} from 'firebase/storage';

// Firebase Storage service
export class Storage {
  private storage: FirebaseStorage;

  constructor(app: FirebaseApp) {
    const storage = getStorage(app);

    // Use storage emulator if enabled with env var
    if (process.env.REACT_APP_USE_LOCAL_STORAGE === 'TRUE') {
      connectStorageEmulator(storage, 'localhost', 9199);
    }

    this.storage = storage;
  }

  public async downloadJsonFile<JsonData = any>(
    path: string,
  ): Promise<JsonData> {
    const downloadUrl = await getDownloadURL(ref(this.storage, path));
    const response = await fetch(downloadUrl);
    return response.json();
  }
}
