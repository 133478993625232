/*

View for the ability to change name, profile pic, link and bio.

TODO: typescript, camelCase, make functional, non-default exports, design system, redux hooks...

Note that it uploads the profile picture when the value of the upload field changes.

*/
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import { M } from 'src/mvp22/constants';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import TextBox from 'src/mvp22/form-components/TextBox';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import R from 'src/routes';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { requiresAuth } from 'src/mvp22/redux-components/requiresAuth';
import ProfileImage from 'src/mvp22/image-components/ProfileImage';
import prepareFileForUpload from 'src/mvp22/core-components/prepareFileForUpload';

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  color: ${M.COL.TEXT.BLACK};
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 351px;
  margin-top: 125px;
`;

const UploadContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 4px;
`;

const FileUploadButton = styled.input`
  display: none;
`;

const UploadedImage = styled.img`
  height: 50px;
  width: 50px;
  align-content: center;
  object-fit: cover;
  border-radius: 25px;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const InputTitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ProfileImageContainer = styled.div``;

const ButtonSpacer = styled.span`
  display: inline-block;
  width: 16px;
`;

const UnderlineLink = styled(TEXTSTYLE.LINKBOLD)`
  color: default;
`;

const CharCount = styled(TEXTSTYLE.BODY5)`
  text-align: right;
  color: ${(props) =>
    props.charCountExceeded ? M.COL.TEXT.ERROR : M.COL.TEXT.BLACK};
`;

const ErrorMessage = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.ERROR};
`;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      max_chars: 150,
      bio_char_count: 0,
      chars_exceeded_bio: false,
      invalid_name: true,
      bio_text: '',
      name_text: '',
      link_text: '',
      changed_once: false,
      error_message: '',
      profile_picture: false,
      working: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.refresh = this.refresh.bind(this);
    this.uploadButtonReference = React.createRef();
  }

  componentDidMount() {
    // As is protected by requiredAuth, should always be loaded when mount done:
    if (this.props.firestore_user_owner.snapshot) {
      this._isMounted = true;
      this.setState({
        name_text: this.props.firestore_user_owner.snapshot.name,
        bio_text: this.props.firestore_user_owner.snapshot.bio
          ? this.props.firestore_user_owner.snapshot.bio
          : '',
        link_text: this.props.firestore_user_owner.snapshot.link
          ? this.props.firestore_user_owner.snapshot.link
          : '',
        bio_char_count: this.props.firestore_user_owner.snapshot.bio
          ? this.props.firestore_user_owner.snapshot.bio.length
          : 0,
        invalid_name: false,
        changed_once: false,
        invalid_link: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goBack(event) {
    event.preventDefault();
    this.props.history.goBack();
    return false;
  }

  // controlls the form
  handleChange(event) {
    const target = event.target;
    if (target.name === 'bio_text') {
      this.setState({
        bio_char_count: event.currentTarget.value.length,
        chars_exceeded_bio:
          event.currentTarget.value.length > 150 ? true : false,
        bio_text: target.value,
        changed_once: true,
      });
    } else if (target.name === 'link_text') {
      this.setState({
        invalid_link: event.currentTarget.value.length > 100 ? true : false,
        link_text: target.value,
        changed_once: true,
      });
    } else if (target.name === 'upload_picture') {
      prepareFileForUpload(target)
        .then((image_url) => {
          this.setState({
            profile_picture: image_url,
            changed_once: true,
          });
        })
        .catch((error_message) => {
          this.setState({
            error_message,
          });
        });
    } else if (target.name === 'name_text') {
      this.setState({
        name_text: target.value,
        invalid_name:
          event.currentTarget.value.length > 50 ||
          event.currentTarget.value.length <= 0
            ? true
            : false,
        changed_once: true,
      });
    }
  }

  doSubmit(event) {
    event.preventDefault();
    if (
      this.state.working !== true &&
      !this.state.chars_exceeded_bio &&
      !this.state.invalid_name &&
      !this.state.invalid_link
    ) {
      this.setState({
        working: true,
      });
      this.props.firebase
        .fast_api()({
          api: 'users_update_profile',
          data: {
            name: this.state.name_text,
            bio: this.state.bio_text,
            link: this.state.link_text,
            profile_picture: this.state.profile_picture,
          },
        })
        .then(() => {
          this._isMounted &&
            this.props.history.push(
              '/@' + this.props.firestore_user_owner.display_username,
            );
          // Need to refresh to show changes for now, as won't update DB otherwise
          this.refresh();
          // TODO: force DB to remount
          this._isMounted &&
            this.setState({
              working: false,
            });
        })
        .catch(() => {
          this._isMounted &&
            this.setState({
              error_message: 'Failed to save profile.',
              working: false,
            });
        });
    }
  }

  refresh() {
    this.props.history.push(R.HOMEPAGE);
    window.location.reload();
  }

  render() {
    return (
      <FullBlank className="fullBlank">
        <TopMenu hug="edge" />
        {AddHelmetTitle('Edit Profile')}
        <Container className="container">
          <FormContainer>
            <FormHeadingContainer>
              <TEXTSTYLE.HEADING2>Edit Profile</TEXTSTYLE.HEADING2>
            </FormHeadingContainer>
            <Seperator height="24px" />
            <FormHeadingContainer>
              <InputTitle>Profile Image</InputTitle>
            </FormHeadingContainer>
            <UploadContainer>
              <FileUploadButton
                type="file"
                name="upload_picture"
                ref={this.uploadButtonReference}
                onChange={this.handleChange}
                accept="image/*"
              />
              {this.state.profile_picture !== false ? (
                <UploadedImage src={this.state.profile_picture} />
              ) : (
                <ProfileImageContainer>
                  <ProfileImage
                    profile_image_code={
                      this.props.firestore_user_owner.snapshot
                        .profile_image_code
                    }
                    user_uid={this.props.authUserUID}
                    name={this.props.firestore_user_owner.snapshot.name}
                    scale={50}
                  />
                </ProfileImageContainer>
              )}
              <Button
                style={{ marginLeft: '12px', alignSelf: 'flex-end' }}
                onClick={() => this.uploadButtonReference.current.click()}
                type="button"
                text="Edit"
                msstyle="option_negative"
                width="80px"
                tabIndex={4}
              />
            </UploadContainer>
            <Seperator height="24px" />
            {this.props.firestore_user_owner.pro ? (
              <>
                <FormHeadingContainer>
                  <TEXTSTYLE.BODY3>
                    As a pro user you can also:
                    <br />
                    <UnderlineLink to={R.EDIT_USERNAME}>
                      tap here to change your public username
                    </UnderlineLink>
                  </TEXTSTYLE.BODY3>
                </FormHeadingContainer>
                <Seperator height="20px" />
              </>
            ) : (
              ''
            )}
            <FormHeadingContainer>
              <InputTitle>Display Name</InputTitle>
            </FormHeadingContainer>
            <Seperator height="6px" />
            <TextInputFeedback
              focusOnMount={true}
              placeholder="e.g. Diana Ross"
              msstyle="standard"
              onChange={this.handleChange}
              feedbackText={
                this.state.invalid_name
                  ? 'Must be between 0 and 50 characters'
                  : ''
              }
              value={this.state.name_text}
              name="name_text"
              width="100%"
              tabIndex={1}
              onEnter={this.doSubmit}
            />
            <Seperator height="20px" />
            <FormHeadingContainer>
              <InputTitle>Bio</InputTitle>
            </FormHeadingContainer>
            <Seperator height="6px" />
            <TextBox
              placeholder="Write a little bit about yourself here"
              msstyle="standard"
              value={this.state.bio_text}
              onChange={this.handleChange}
              name="bio_text"
              width="100%"
              height="100px"
              tabIndex={2}
            />
            <CharCount charCountExceeded={this.state.chars_exceeded_bio}>
              {this.state.bio_char_count}/150{' '}
            </CharCount>
            {this.props.firestore_user_owner.pro === true ? (
              <>
                <Seperator height="20px" />
                <FormHeadingContainer>
                  <InputTitle>Website Link</InputTitle>
                </FormHeadingContainer>
                <Seperator height="6px" />
                <TextInputFeedback
                  placeholder="e.g. moonsift.com or https://www.moonsift.com"
                  msstyle="standard"
                  value={this.state.link_text}
                  onChange={this.handleChange}
                  feedbackText={
                    this.state.invalid_link
                      ? 'Must be between 0 and 100 characters'
                      : ''
                  }
                  name="link_text"
                  width="100%"
                  tabIndex={3}
                  onEnter={this.doSubmit}
                />
              </>
            ) : (
              ''
            )}
            <Seperator height="24px" />
            {this.state.error_message ? (
              <ErrorMessage>{this.state.error_message}</ErrorMessage>
            ) : null}
            <Seperator height="24px" />
            <CancelAndConfirmButtonsContainer>
              <Button
                onClick={this.goBack}
                type="button"
                text="Back"
                msstyle="option_negative"
                width="123px"
                tabIndex={4}
              />
              <ButtonSpacer />
              <Button
                type="button"
                text="Save"
                msstyle="option_positive"
                onClick={this.doSubmit}
                width="123px"
                working_overlay={this.state.working}
                tabIndex={3}
                enabled={
                  this.state.changed_once &&
                  !this.state.chars_exceeded_bio &&
                  !this.state.invalid_name &&
                  !this.state.invalid_link
                }
              />
            </CancelAndConfirmButtonsContainer>
          </FormContainer>
        </Container>
        <Seperator height="46px" />
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUserUID: state.auth.id,
    firestore_user_owner: state.firestore_user_owner,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(requiresAuth(withFirebase(EditProfile))),
);
