import styled from 'styled-components';

const UnstyledGiftListInfo = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={className}>
      {children}
    </div>
  );
};

export const GiftListInfo = styled(UnstyledGiftListInfo)`
  padding: 12px;
  border: 2px solid ${({ theme }) => theme.colors.background?.purple};
  border-radius: 5px;
`;
