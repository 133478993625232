import { ParserInput } from 'src/services/DB';
import {
  UserPublic,
  UserPublicDocumentWithID,
} from 'src/types/models/userPublic.model';
import { dataAndID } from 'src/store/parsers/dataandid';

export function userPublicParser(doc: ParserInput): UserPublic | null {
  const data = dataAndID<UserPublicDocumentWithID>(doc);
  const user_uid = doc.ref.parent.id;
  let public_out: UserPublic | null = null;
  if (data !== null) {
    public_out = {
      ...data,
      display_username: data.alias_username
        ? data.alias_username
        : data.original_username
        ? data.original_username
        : user_uid,
    };
  }
  return public_out;
}
