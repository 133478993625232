/*

Animated dots that indicate loading on buttons or elsewhere.

These are now in the design system so occurrences can be replaced.


*/
import React from 'react';
import { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';

const keyframes_dict = {
  standard_left:
    keyframes`
      0% {background-color: ${M.COL.BUTTON.YELLOW};}
      33% {background-color: ${M.COL.BUTTON.MID};}
      66% {background-color: ${M.COL.BUTTON.MID};}
      100% {background-color: ${M.COL.BUTTON.YELLOW};}
    `,

  standard_centre :
    keyframes`
      0% {background-color: ${M.COL.BUTTON.MID};}
      33% {background-color: ${M.COL.BUTTON.YELLOW};}
      66% {background-color: ${M.COL.BUTTON.MID};}
      100% {background-color: ${M.COL.BUTTON.MID};}
    `,

  standard_right :
    keyframes`
      0% {background-color: ${M.COL.BUTTON.MID};}
      33% {background-color: ${M.COL.BUTTON.MID};}
      66% {background-color: ${M.COL.BUTTON.YELLOW};}
      100% {background-color: ${M.COL.BUTTON.MID};}
    `,

  dark_left :
    keyframes`
      0% {background-color: ${M.COL.BUTTON.WHITE};}
      33% {background-color: ${M.COL.BUTTON.LIGHT};}
      66% {background-color: ${M.COL.BUTTON.LIGHT};}
      100% {background-color: ${M.COL.BUTTON.WHITE};}
    `,

  dark_centre :
    keyframes`
      0% {background-color: ${M.COL.BUTTON.LIGHT};}
      33% {background-color: ${M.COL.BUTTON.WHITE};}
      66% {background-color: ${M.COL.BUTTON.LIGHT};}
      100% {background-color: ${M.COL.BUTTON.LIGHT};}
    `,

  dark_right :
    keyframes`
      0% {background-color: ${M.COL.BUTTON.LIGHT};}
      33% {background-color: ${M.COL.BUTTON.LIGHT};}
      66% {background-color: ${M.COL.BUTTON.WHITE};}
      100% {background-color: ${M.COL.BUTTON.LIGHT};}
    `
};

const DotBase = styled.div`
  height:5px;
  width:5px;
  border-radius:3px;
  font-size:0px;
  line-height: 0px;
  display:flex;
  flex-direction:row;
  margin:0px 2px;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${props=>keyframes_dict[props.pos_type]};
`;

const Container = styled.div`
  font-size:0px;
  line-height: 0px;
  display:flex;
  flex-direction:row;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

/**
* Base text input class
*/
class LoadingDots extends Component {
  static propTypes = {
    /** Dots style */
    msstyle: PropTypes.string,
  }

  render(){
    switch(this.props.msstyle){
      case "dark":{
        return(
          <Container>
            <DotBase pos_type="dark_left"/>
            <DotBase pos_type="dark_centre"/>
            <DotBase pos_type="dark_right"/>
          </Container>
        );
      }
    default:{
        return(
          <Container>
            <DotBase pos_type="standard_left"/>
            <DotBase pos_type="standard_centre"/>
            <DotBase pos_type="standard_right"/>
          </Container>
        );
      }
    }
  }
}

export default LoadingDots;
