// This file contains the route names for the main pages:
const R = {
  HOMEPAGE: '/',
  HOME_OR_LOGIN: '/mycollections',
  SITEMAP: '/sitemap',
  LANDING: '/landing',
  WISHLIST: '/wishlist',
  WISHLIST_MAKER: '/wishlist-maker',
  CHRISTMAS_LANDING: '/christmas-wishlist',
  LOADING: '/loading',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  SIGNUPFROMEXTENSION: '/signup/extension',
  SIGNIN: '/signin',
  SIGNINFROMEXTENSION: '/signin/extension',
  POSTSIGNUP: '/postsignup',
  RETAILERS_AZ: '/retailers/az',
  RETAILERS_FEATURED: '/retailers/trending',
  RETAILERS_SEARCH: '/retailers/search',
  RETAILERS: '/retailers',
  DESIGNSYSTEM: '/test/designsystem',
  TASTEMAKERLANDING: '/stylists',
  TASTEMAKERSAPPLY: '/stylistsapply',
  PRIVACYPOLICY: '/privacy',
  TERMS: '/terms',
  USERHOMEPAGE: '/home',
  HOME: '/home',
  PRO_LANDING: '/getpro',
  PRO_SHARE: '/givepro',
  PRO_REFER: '/referpro',
  PRO_TRIAL: '/protrial',
  PRO_WELCOME: '/pro-welcome',
  FORGOTPASSWORD: '/forgotpassword',
  COLLECTION: '/collection',
  REPORTPROBLEM: '/reportsite',
  EXTENSION_ISSUE_REPORT: '/extensionissue',
  EXTENSION_UPGRADED: '/extension-upgraded',
  ABOUT_US: '/about',
  USER_PROFILE: '/user/',
  LIKED: '/liked',
  BOOKMARKED: '/bookmarked',
  PURCHASED: '/purchased',
  EXPLORE_FEATURED: '/featured/',
  EDIT_PROFILE: '/profile/edit',
  EDIT_USERNAME: '/profile/username',
  EDIT_EMAIL_PREFERENCES: '/profile/email',
  EDIT_ACCOUNT_PREFERENCES: '/profile/account',
  UNSUBSCRIBE_FROM_LIST: '/profile/unsubscribe-list',
  ADD_PRODUCT: '/addproduct',
  NOTIFICATIONS: '/notifications',
  LIZZIEYOUTUBE: `/${decodeURI('%C3%A2%C2%80%C2%8B')}`, // popular youtube video link included this extra for some reason.
  LIZZIEYOUTUBEURI: `/%C3%A2%C2%80%C2%8B`,
  COLLECTIONS_CREATE: '/collections/create',
  POSTINSTALL_ONE: '/postinstall',
  POSTINSTALL_TWO: '/postinstall/2',
  POSTINSTALL_THREE: '/postinstall/3',
  HOW_TO_SAVE: '/how-to-save',
  CREATOR_APPLICATION_ONE: '/creator/application/1',
  CREATOR_APPLICATION_TWO: '/creator/application/2',
  CREATOR_APPLICATION_THREE: '/creator/application/3',
  CREATOR_APPLICATION_FOUR: '/creator/application/4',
  CREATOR_APPLICATION_FIVE: '/creator/application/5',
  CREATOR_APPLICATION_SIX: '/creator/application/6',
  CREATOR_APPLICATION_DONE: '/creator/application/done',
  CREATOR_DASHBOARD: '/creator/earnings',
  CREATOR_LANDING: '/creators',
  ALL_CREATORS: '/all-creators',
  CREATOR_FAQ: '/creator/faq',
  AFFILIATE_BRANDS: '/affiliate-brands',
  APP_LANDING: '/wishlist-app',
  APP_QR_CODE: '/app-qr',
  STYLIST_APPLICATION_ONE: '/stylist/application/1',
  STYLIST_APPLICATION_TWO: '/stylist/application/2',
  STYLIST_APPLICATION_THREE: '/stylist/application/3',
  STYLIST_APPLICATION_FOUR: '/stylist/application/4',
  REGISTRY_LANDING: '/gift-registry',
  BABY_REGISTRY_LANDING: '/baby-registry',
  BIRTHDAY_LANDING: '/birthday-wishlist',
  PLANS: '/plans',
};

export const EXTERNAL = {
  FACEBOOK: 'https://www.facebook.com/Moonsift/',
  INSTAGRAM: 'https://www.instagram.com/moonsift/',
  MEDIUM: 'https://medium.com/@alex.reed',
  LINKEDIN: 'https://www.linkedin.com/company/moonsift/',
  CHROMESTORE:
    'https://chromewebstore.google.com/detail/moonsift-universal-save-b/hgbbfkafecdbocpcdkoahfkbkoodcaon',
  RETAILER_ASOS: 'http://www.asos.com/',
  RETAILER_SELFRIDGES: 'http://www.selfridges.com/',
  RETAILER_HM: 'http://www.hm.com/',
  RETAILER_STORIES: 'http://www.stories.com/',
  RETAILER_TOPSHOP: 'http://www.topshop.com/',
  RETAILER_TROUVA: 'http://www.trouva.com/',
  ORLY: 'https://en.wikipedia.org/wiki/O_RLY%3F',
  JOBS: 'https://www.notion.so/Join-the-Moonsift-team-9cf518f1c87c41cc85286556d8b2d006',
  MASTERING_MOONSIFT:
    'https://moonsift.notion.site/Mastering-Moonsift-96d36c1e2e8448a48900b13251fcaadb',
  PRO_EXPLANATION_FAQ:
    'https://moonsift.notion.site/moonsift/MOONSIFT-Pro-c9725c7dba0e4f6595a1c66e06ff1660',
  PRO_EXPLANATION:
    'https://moonsift.notion.site/An-upgraded-experience-8e4cdf20c3904ff99bf8ed22372ad9cd',
  ACCOUNTS_AND_GENERAL_QUESTIONS:
    'https://moonsift.notion.site/moonsift/Account-other-general-questions-iOS-a97ee522758f49118acb28e02d6eecf5',
  VIDEO_EXPLANATION:
    'https://moonsift.notion.site/How-to-generate-a-video-from-your-Moonsift-collection-669326e267d34a15917464080b25f7f4',
  SUBMIT_COLLECTION:
    'https://moonsift.notion.site/Featuring-Collections-on-Moonsift-8a1d95e64f8e4360aa3f7f7acc22ca63',
  HOW_TO_ADD:
    'https://moonsift.notion.site/How-to-add-items-to-your-Collection-c7a890258757415a848a88da8b428583',
  HOW_TO_PIN_MOONSIFT:
    'https://moonsift.notion.site/How-to-Pin-Moonsift-on-Chrome-1cd970a3a5a945648fdc269258d26779',
  APP_STORE_APP:
    'https://apps.apple.com/us/app/universal-wishlist-by-moonsift/id1609475671',
  APP_STORE_APP_CREATORS:
    'https://apps.apple.com/us/app/universal-wishlist-by-moonsift/id1609475671?ppid=6543ae4b-0a13-4da7-a9f2-b2d76c73efb9',
  CREATOR_HOW_IT_WORKS:
    'https://moonsift.notion.site/MOONSIFT-CREATORS-e6f930c639504251b6231b19aa3da892',
  GUIDES: 'https://www.moonsift.com/guides',
  AI_LANDING: 'https://www.moonsift.com/ai',
  CHROME_UPGRADE:
    'https://support.google.com/chrome/answer/95414?co=GENIE.Platform%3DDesktop&hl=hl=en-GB',
};

export const GUIDES = {
  baby: {
    'What do I actually need on my baby registry?':
      'https://www.moonsift.com/guides/what-do-i-need-registry',
    'Why do people make baby registries?':
      'https://www.moonsift.com/guides/why-do-people-make-baby-registries',
    'What is a baby registry & how does a baby registry work?':
      'https://www.moonsift.com/guides/what-is-a-baby-registry',
    'Baby registry checklist':
      'https://www.moonsift.com/guides/baby-registry-checklist',
    'Newborn bath essentials':
      'https://www.moonsift.com/guides/newborn-bath-essentials',
    'What Should a Baby Shower Include?':
      'https://www.moonsift.com/guides/baby-shower-planning',
    'How do I financially prepare for a baby?':
      'https://www.moonsift.com/guides/financially-prepare-for-baby',
    'What baby items should you buy first?':
      'https://www.moonsift.com/guides/first-baby-items-to-buy',
    'List of newborn essentials':
      'https://www.moonsift.com/guides/newborn-essentials',
    'What is baby shower gift etiquette?':
      'https://www.moonsift.com/guides/baby-shower-gift-etiquette',
    'When should I start buying baby stuff during pregnancy?':
      'https://www.moonsift.com/guides/when-to-shop-for-my-baby',
    'When to start a baby registry':
      'https://www.moonsift.com/guides/when-to-start-a-baby-registry',
    'Who plans and pays for a baby shower?':
      'https://moonsift.com/guides/baby-shower-plans',
    'How to plan a baby shower': 'https://moonsift.com/guides/plan-baby-shower',
  },
  gift: {
    'Which gift registry is best?':
      'https://www.moonsift.com/guides/best-gift-registry',
    'A Guide to Making a Universal Wish List with Moonsift':
      'https://www.moonsift.com/guides/create-universal-wish-list',
    'How to share a wish list': 'https://moonsift.com/guides/share-a-wish-list',
    'Universal Wishlist App - Become An Expert Shopper':
      'https://moonsift.com/guides/universal-wishlist-app',
    'Best shopping wishlist apps':
      'https://moonsift.com/guides/best-shopping-wishlist-app',
    'Amazon Assistant for Chrome Discountinued. Now what?':
      'https://moonsift.com/guides/amazon-assistant-discontinued-best-alternatives',
    "What are the best alternatives to Amazon's wishlist addon?":
      'https://moonsift.com/guides/what-are-the-best-alternatives-to-amazon-s-wishlist-addon',
    'How to add things to my Amazon wish list from other websites':
      'https://moonsift.com/guides/how-to-add-things-to-my-amazon-wish-list-from-other-websites',
  },
  christmas: {
    'Creating a Christmas Wishlist':
      'https://www.moonsift.com/guides/creating-christmas-wishlist',
    'Secret Santa Wishlist':
      'https://moonsift.com/guides/secret-santa-wishlist',
    'How to make a Christmas list online':
      'https://moonsift.com/guides/how-to-make-a-christmas-wishlist',
    'Handy Christmas wishlist ideas':
      'https://moonsift.com/guides/top-christmas-list-ideas',
    'Aesthetic Christmas wishlist ideas':
      'https://moonsift.com/guides/aesthetic-christmas-ideas',
    'How to make a Christmas wish list':
      'https://moonsift.com/guides/make-christmas-wishlist',
    'How to make an online Christmas list':
      'https://moonsift.com/guides/online-christmas-list',
    'Best wishlist for Christmas':
      'https://www.moonsift.com/guides/best-wishlist-for-christmas',
  },
  birthday: {
    'Birthday Wishlist: How to Create a List of Items You Actually Want':
      'https://www.moonsift.com/guides/birthday-wishlist-how-to-create-a-list-of-items-you-actually-want',
    'How to create the perfect birthday wishlist':
      'https://moonsift.com/guides/perfect-birthday-wishlist',
    'What should I give my boyfriend for his birthday?':
      'https://moonsift.com/guides/boyfriend-birthday-presents',
    'How can I organise my birthday?':
      'https://moonsift.com/guides/organise-my-birthday',
    'How to Make a Birthday Wish List':
      'https://moonsift.com/guides/how-to-make-birthday-list',
    'Best Online Birthday Wishlist Maker':
      'https://moonsift.com/guides/birthday-wishlist-makers',
  },
  wedding: {
    'Your guide to the best wedding registries':
      'https://moonsift.com/guides/best-wedding-registries',
    'Top wedding shower gifts':
      'https://moonsift.com/guides/wedding-shower-gifts',
    'Researching and finding a wedding registry':
      'https://moonsift.com/guides/finding-a-wedding-registry',
  },
  ai: {
    'From Keywords to Context: The Impact of AI on Product Search':
      'https://moonsift.com/guides/from-keywords-to-context-how-has-search-advanced',
    'The rise of semantic search: a comparison of vector databases':
      'https://moonsift.com/guides/vectors-speak-louder-than-words-the-rise-of-semantic-search',
    "NLP's quantum leap: from N-grams to GPT-4 ":
      'https://moonsift.com/guides/from-n-grams-to-gpt-4-nlp-s-quantum-leap',
  },
};

export default R;

// Order as appears in sitemap:
export const categories = {
  HOME: 'HOME',
  WISHLIST: 'WISHLIST',
  CREATORS: 'CREATORS',
  ACCOUNT: 'ACCOUNT',
  ABOUT: 'ABOUT',
  GUIDES_GIFTS: 'GUIDES_gift',
  GUIDES_CHRISTMAS: 'GUIDES_christmas',
  GUIDES_BABY: 'GUIDES_baby',
  GUIDES_BIRTHDAY: 'GUIDES_birthday',
  GUIDES_AI: 'GUIDES_ai',
};
export const categoryStructure = {
  [categories.HOME]: { title: 'Home' },
  [categories.WISHLIST]: { title: 'Universal Wishlist' },
  [categories.CREATORS]: { title: 'Creators and Stylists' },
  [categories.ACCOUNT]: { title: 'My Account' },
  [categories.ABOUT]: { title: 'About Moonsift' },
  [categories.GUIDES_GIFTS]: { title: 'Gift Registry Guides' },
  [categories.GUIDES_CHRISTMAS]: { title: 'Christmas Wishlist Guides' },
  [categories.GUIDES_BABY]: { title: 'Baby Registry Guides' },
  [categories.GUIDES_BIRTHDAY]: { title: 'Birthday Wishlist Guides' },
  [categories.GUIDES_AI]: { title: 'AI Blog' },
};

export const pageTitles = {
  // Home
  [R.HOMEPAGE]: 'Homepage',
  // Guides
  [EXTERNAL.GUIDES]: 'Guide articles homepage',
  // Wishlist
  [R.REGISTRY_LANDING]: 'Gift Registry',
  [R.BIRTHDAY_LANDING]: 'Birthday Wishlist',
  [R.BABY_REGISTRY_LANDING]: 'Baby Registry',
  [R.CHRISTMAS_LANDING]: 'Christmas Gift Wishlist',
  [R.APP_LANDING]: 'iPhone App',
  [R.RETAILERS]: 'Retailers With Moonsift Integrations',
  [R.HOW_TO_SAVE]: 'How to save to Moonsift',
  // Creators
  [R.CREATOR_LANDING]: 'Moonsift for Content Creators',
  [R.EXPLORE_FEATURED]: 'Featured Collections',
  [R.CREATOR_FAQ]: 'Creator FAQ',
  [R.AFFILIATE_BRANDS]: 'Affiliate Brands for Commission',
  // Account:
  [R.SIGNUP]: 'Sign up',
  [R.SIGNIN]: 'Sign in',
  [R.FORGOTPASSWORD]: 'Forgot Password',
  [R.PLANS]: 'Pro Plan Information',
  // About
  [R.SITEMAP]: 'Sitemap',
  [R.ABOUT_US]: 'About Moonsift',
  [EXTERNAL.AI_LANDING]: 'AI Shopping Copilot',
};

const weekly = 'weekly';
const monthly = 'monthly';
const yearly = 'yearly';
export const SEORoutes = [
  [R.HOMEPAGE, monthly, 1.0, categories.HOME],
  [R.REGISTRY_LANDING, monthly, 0.9, categories.WISHLIST],
  [R.CREATOR_LANDING, monthly, 0.9, categories.CREATORS],
  [EXTERNAL.AI_LANDING, monthly, 0.8, categories.ABOUT],
  [R.CHRISTMAS_LANDING, yearly, 0.8, categories.WISHLIST],
  [R.BIRTHDAY_LANDING, yearly, 0.8, categories.WISHLIST],
  [R.BABY_REGISTRY_LANDING, yearly, 0.9, categories.WISHLIST],
  [R.TASTEMAKERLANDING, monthly, 0.8],
  [R.APP_LANDING, monthly, 0.8, categories.WISHLIST],
  [R.EXPLORE_FEATURED, weekly, 0.7, categories.WISHLIST],
  [EXTERNAL.GUIDES, monthly, 0.7, categories.GUIDES],
  [R.SITEMAP, monthly, 0.7, categories.ABOUT],
  [R.ABOUT_US, yearly, 0.5, categories.ABOUT],
  [R.SIGNUP, yearly, 0.3, categories.ACCOUNT],
  [R.SIGNIN, yearly, 0.3, categories.ACCOUNT],
  [R.RETAILERS_AZ, monthly, 0.3],
  [R.RETAILERS_FEATURED, yearly, 0.3],
  [R.RETAILERS_SEARCH, yearly, 0.3],
  [R.RETAILERS, monthly, 0.3, categories.WISHLIST],
  [R.PRO_LANDING, monthly, 0.5, categories.ACCOUNT],
  [R.FORGOTPASSWORD, yearly, 0.3, categories.ACCOUNT],
  [R.HOW_TO_SAVE, monthly, 0.3, categories.WISHLIST],
  [R.CREATOR_FAQ, monthly, 0.3, categories.CREATORS],
  [R.AFFILIATE_BRANDS, monthly, 0.3, categories.CREATORS],
  [R.APP_QR_CODE, monthly, 0.3],
  [R.PLANS, monthly, 0.3, categories.ACCOUNT],
];
