import { DB, DBError, DocumentSnapshot } from 'src/services/DB';
import { put, takeEvery, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { baseDBSetDoc, baseDBSetDocListener } from 'src/store/actions/baseDB';
import {
  ActionNames,
  ParserFunction,
  BaseDBKindsUnion,
  BaseDBActionTypes,
  BaseDBFetchingDict,
} from 'src/types';
import {
  addToFetchingDict,
  checkFetchingDict,
  deleteFromFetchingDict,
} from 'src/utils/fetchingDict';

const fetchingDict: BaseDBFetchingDict = {};

/*

The saga for getting documents from the database and listening for changes.

*/
function* listenItems<K extends BaseDBKindsUnion>(
  kind: K['name'],
  dB: DB,
  dBRef: string,
  parser: ParserFunction<K['item']>,
  storeAs: string | undefined,
) {
  // Code for treating the above listener output:
  // closing channel will terminate entire function and just call finally
  if (!checkFetchingDict(kind, storeAs, fetchingDict)) {
    const docChannel = eventChannel<DocumentSnapshot | 'USE_DB_ERROR'>((emit) =>
      dB.listenDoc(dBRef, emit, (err: DBError) => {
        console.log('useDB error for kind', kind, err);
        emit('USE_DB_ERROR');
      }),
    );
    // Save the channel into redux (like a listener):
    yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_DOC]>(
      baseDBSetDocListener<K>(docChannel, kind, storeAs),
    );
    try {
      addToFetchingDict(kind, storeAs, fetchingDict);
      while (true) {
        // take from the channel...
        const snapshot: DocumentSnapshot | 'USE_DB_ERROR' = yield take(
          docChannel,
        );
        // process the snapshot:...
        const parsedData =
          snapshot === 'USE_DB_ERROR' ? null : parser(snapshot);
        // Put data away:
        yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_DOC]>(
          baseDBSetDoc<K>(parsedData, kind, storeAs),
        );
      }
    } finally {
      // any code to perform after END called or error received?
      docChannel.close(); // can be called multiple times if already closed.
      deleteFromFetchingDict(kind, storeAs, fetchingDict);
    }
    // Anything below here is never called (unless there is an error in yield...?)
  }
}

// worker saga:
function* createListener<K extends BaseDBKindsUnion>(
  action: BaseDBActionTypes<K>['BASEDB_LISTEN_DOC'],
) {
  yield listenItems<K>(
    action.kind,
    action.payload.dB,
    action.payload.dBRef,
    action.payload.parser,
    action.storeAs,
  );
}

// Watching saga:
export function* baseDocListen() {
  yield takeEvery(ActionNames.BASEDB_LISTEN_DOC, createListener);
}
