import { VFC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';
import { CreatorApplicationThreeContainerProps } from './CreatorApplicationThree.types';
import { CreatorApplicationThree } from './CreatorApplicationThree.component';
import { RootState } from 'src';
import { useSelector } from 'react-redux';

const CreatorApplicationThreeContainer: VFC<
  CreatorApplicationThreeContainerProps
> = (props) => {
  const history = useHistory();

  const hasAffiliates = useSelector<RootState, boolean>(
    ({ creatorApplication }) => creatorApplication.affiliates[0] !== 'None yet',
  );

  const handleSubmit = useCallback(async () => {
    history.push(R.CREATOR_APPLICATION_FOUR);
  }, [history]);

  const handleBack = useCallback(() => {
    history.push(R.CREATOR_APPLICATION_TWO);
  }, [history]);

  return (
    <CreatorApplicationThree
      onSubmit={handleSubmit}
      onBack={handleBack}
      hasAffiliates={hasAffiliates}
      {...props}
    />
  );
};

export { CreatorApplicationThreeContainer as CreatorApplicationThree };
