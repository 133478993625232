import { Audience, Channel, Status } from 'src/types/common';
import { ActionNames, ActionType } from 'src/types/actions';

const categoriesMap = {
  'Fashion': true,
  'Home & Interiors': true,
  'Beauty': true,
  'Family': true,
  'Health & Fitness': true,
  'Lifestyle': true,
  'Other': true,
};

export type Category = keyof typeof categoriesMap;

export const categories = Object.keys(categoriesMap) as Category[];

const AffiliatesMap = {
  'LTK': true,
  'Amazon Associates': true,
  'MagicLinks': true,
  'Klarna Affiliate': true,
  'Collective Voice (ShopStyle)': true,
  'ShareASale': true,
  'Other': true,
  'None yet': true,
};

export type Affiliate = keyof typeof AffiliatesMap;

export const affiliates = Object.keys(AffiliatesMap) as Affiliate[];

export interface CreatorApplicationRequest {
  type?: 'content-creator-general';
  audience: Audience;
  categories: Category[];
  channels: Channel[];
  feature: boolean;
  affiliates: Affiliate[];
  referral_code?: string;
}

export interface CreatorApplication extends CreatorApplicationRequest {
  status: Status;
}

export interface CreatorApplicationState
  extends Omit<
    CreatorApplicationRequest,
    'type' | 'referral_code' | 'feature'
  > {
  feature: 'Yes' | 'No' | '';
  errorMessage: string;
}

export type UpdateCreatorApplicationRequestAction = ActionType<
  ActionNames.UPDATE_CREATOR_APPLICATION,
  Partial<CreatorApplicationState>
>;

export type UpdateCreatorApplicationErrorMessageAction = ActionType<
  ActionNames.UPDATE_CREATOR_APPLICATION_ERROR_MESSAGE,
  { errorMessage: string }
>;

export type ResetCreatorApplicationAction = ActionType<
  ActionNames.RESET_CREATOR_APPLICATION,
  undefined
>;

export type CreatorApplicationAction =
  | UpdateCreatorApplicationRequestAction
  | UpdateCreatorApplicationErrorMessageAction
  | ResetCreatorApplicationAction;
