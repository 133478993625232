import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  TextField,
  Stack,
  StandardButton,
  formHelperTextClassNames,
  EarthIcon,
  PersonAddIcon,
  LockClosedOutlinedIcon,
  GiftIcon,
  VideoCamIcon,
  InfoIcon,
  ButtonBase,
} from '@moonsifttech/design-system';
import Overlay from 'react-overlays/Overlay';
import { ModalContent } from 'src/components/core/ModalContent';
import { CollectionSharePanelProps } from './CollectionSharePanel.types';
import { visibilityOptions } from 'src/types/models/collection.model';
export const mapIconNameToIcon: Record<string, React.ReactNode> = {
  'earth': <EarthIcon />,
  'person-add': <PersonAddIcon />,
  'lock-closed-outlined': <LockClosedOutlinedIcon />,
};

const UnstyledCollectionSharePanel: React.FC<CollectionSharePanelProps> = ({
  className,
  style,
  onClose,
  onCopy,
  openEditCollection,
  onOpenGenerateVideo,
  setShowOverlay,
  isPro,
  copied,
  canBeShared,
  currentVisibilityType,
  isRegistry,
  triggerRef,
  showOverlay,
  containerRef,
}) => {
  return (
    <ModalContent className={className} style={style} onClose={onClose}>
      <Typography variant="primary.b26" component="h1">
        Share this collection
      </Typography>
      {canBeShared && (
        <>
          <Typography variant="primary.r14" color="grey.2" component="p">
            Copy and share with friends
          </Typography>
          <form onSubmit={onCopy}>
            <Stack direction="row" justifyContent="flex-end">
              <TextField
                name="url"
                defaultValue={window.location.href.split('?')[0]}
                fullWidth
                className="CollectionSharePanel-textField"
              />
              <StandardButton type="submit" variant="primary-black">
                Copy
              </StandardButton>
            </Stack>
            <Stack direction="row" justifyContent="flex-end">
              <Typography
                variant="primary.r10"
                className="CollectionSharePanel-copiedText"
              >
                {copied ? 'Link copied' : ' '}
              </Typography>
            </Stack>
          </form>
        </>
      )}
      {!canBeShared && (
        <Typography variant="primary.r14" color="grey.2" component="p">
          Collection cannot be shared while set to 'hidden'
        </Typography>
      )}
      {currentVisibilityType && (
        <Stack spacing={8}>
          <Typography variant="primary.b18"></Typography>
          <Stack direction="row" alignItems="flex-start" spacing={8}>
            <div className="CollectionSharePanel-icon">
              {mapIconNameToIcon[visibilityOptions[currentVisibilityType].icon]}
            </div>
            <Typography variant="primary.r12">
              {visibilityOptions[currentVisibilityType].description} (
              <p
                onClick={openEditCollection}
                className="CollectionSharePanel-linktoEdit"
              >
                change
              </p>
              )
            </Typography>
          </Stack>
          {currentVisibilityType !== 'hidden' && (
            <Stack direction="row" alignItems="flex-start" spacing={8}>
              <div className="CollectionSharePanel-icon">
                <GiftIcon
                  color={isRegistry ? 'var(--common-black)' : 'var(--grey-4)'}
                />
              </div>{' '}
              <Typography variant="primary.r12">
                {isRegistry &&
                  `Collection is a gift list. Anyone can mark items as bought`}
                {!isRegistry &&
                  `Not a gift list.  Visitors cannot mark items as bought`}{' '}
                (
                <p
                  onClick={openEditCollection}
                  className="CollectionSharePanel-linktoEdit"
                >
                  change
                </p>
                )
              </Typography>
            </Stack>
          )}
          {isPro && (
            <Stack
              direction="row"
              className="CollectionSharePanel-videoGenerationButtonContainer"
              ref={containerRef}
            >
              <StandardButton
                startIcon={<VideoCamIcon />}
                onClick={onOpenGenerateVideo}
                variant="primary-white"
              >
                Create collection video
              </StandardButton>
              <ButtonBase
                data-testid="info-button"
                className="CollectionSharePanel-videoGenerationButtonInfo"
                ref={triggerRef}
                onClick={(event) => {
                  event.preventDefault();
                  setShowOverlay(true);
                }}
              >
                <InfoIcon />
              </ButtonBase>
              <Overlay
                show={showOverlay}
                offset={[20, 20]}
                onHide={() => setShowOverlay(false)}
                placement="bottom-start"
                container={containerRef}
                target={triggerRef}
                rootClose
                flip
              >
                {({ props }) => (
                  <div
                    {...props}
                    className="CollectionSharePanel-videoGenerationButtonInfoTooltip"
                  >
                    We can automatically generate videos of your collection to
                    share. We recommend sharing these on Instagram Stories and
                    Instagram Reels.
                  </div>
                )}
              </Overlay>
            </Stack>
          )}
          <Stack direction="row" justifyContent="flex-end">
            <StandardButton onClick={onClose} variant="primary-white">
              Done
            </StandardButton>
          </Stack>
        </Stack>
      )}
    </ModalContent>
  );
};

export const CollectionSharePanel = styled(UnstyledCollectionSharePanel)`
  p {
    margin-bottom: 10px;
  }

  .CollectionSharePanel-textField {
  }

  ${formHelperTextClassNames.root} {
    font-weight: 700;
  }
  .CollectionSharePanel-icon {
    display: inline;
  }

  .CollectionSharePanel-videoGenerationButtonContainer {
    margin-top: 46px;
    margin-bottom: 46px;
  }
  .CollectionSharePanel-videoGenerationButtonInfoTooltip {
    background-color: var(--common-white);
    position: absolute;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 100;
  }

  .CollectionSharePanel-videoGenerationButtonInfo {
    background: none;
    color: var(--common-black);
    width: 40px;
    min-width: 0px;
    padding: 0px;
    margin: 0px;
  }

  .CollectionSharePanel-linktoEdit {
    display: inline;
    text-decoration: underline;
    cursor: pointer;
  }
  .CollectionSharePanel-copiedText {
    margin-top: 4px;
    margin-right: 18px;
    height: 14px;
  }
`;
