/*

Dots in the tastemaker sign up page.

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';

const CircleBase = styled.div`
  height: ${props => props.diameter};
  width: ${props => props.diameter};
  border-radius:${props => props.diameter};
`;

const BrightCircle = styled(CircleBase)`
  background-color:${M.COL.BUTTON.YELLOW};
  border-width:0px;
`;

const BlackCircle = styled(CircleBase)`
  background-color:${M.COL.BUTTON.BLACK};
  border-width:0px;
`;

const GreyCircle = styled(CircleBase)`
  background-color:${M.COL.BUTTON.MID};
  border-width:0px;
`;

const VoidCircle = styled(CircleBase)`
  background-color:${M.COL.UC.FG};
  border-width:0px;
`;

const HSeparator = styled.div`
  width: ${props => props.width};
`;

const Container = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
`;

/**
* Base text input class
*/
class Balls22 extends Component {
  static propTypes = {
    /** Radius of the ball */
    diameter: PropTypes.string,
    /** Ball Sep */
    sep: PropTypes.string,
    /** The number of Balls */
    count: PropTypes.number,
    /** The number of Balls along we are */
    value: PropTypes.number,
    /** Style type for balls to use preset Moonsift styles */
    msstyle: PropTypes.string,
  }

  constructor(props){
    super(props);
    this.getCircles = this.getCircles.bind(this);
  }

  getCircles(){
    switch(this.props.msstyle){
      case "standard":{
        return([BrightCircle,GreyCircle]);
      }
      case "dark":{
        return([BlackCircle,GreyCircle]);
      }
      default:{
        return([VoidCircle,VoidCircle]);
      }
    }
  }

  render(){
    const Circles = this.getCircles();
    var Balls = [];
    var CurCircle = Circles[0];
    for (var i=0;i<parseInt(this.props.count,10);i++){
      if (i>=parseInt(this.props.value,10)){
        CurCircle = Circles[1];
      }
      Balls.push(<CurCircle key={i} diameter={this.props.diameter}/>);
      if (i<parseInt(this.props.count,10)-1){
        Balls.push(<HSeparator key={"hs"+i}width={this.props.sep}/>);
      }
    }
    return(
      <Container title={this.props.title}>
        {Balls}
      </Container>
    );
  }

}

export default Balls22;
