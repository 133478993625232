import { Timestamp } from 'src/services/DB';
import { ReducerType, Kind } from './basedb.model';
import { ID } from 'src/types/common';
/*

DB: Collection Documents come from the document stored at:
/databases/user_product_collection/<userUID>/<collectionUID>

They contain summary info for the collection.

*/

type Subsection = {
  name: string;
};

export type SubsectionInfoMap = {
  [x: string]: Subsection;
};

export type CoverImageDict = {
  [x: number]: string;
};

export type ItemToSubsectionMap = {
  [x: string]: number;
};

export type CashFund = {
  name: string;
  notes?: string;
  details: string;
}

export interface CollectionDocument {
  /**
   * Date in which the collection was created.
   */
  created_at: Timestamp;
  /**
   * Date in which the collection was last updated.
   */
  last_updated: Timestamp;
  /**
   * Name of the collection.
   */
  name: string;
  /**
   * Description of the collection.
   */
  description?: string;
  /**
   * Visibility level of the collection.
   */
  public_level: number;
  /**
   * Whether the collection is a registry.
   */
  is_registry?: boolean;
  /**
   * User UID of the owner of the collection.
   */
  user_uid: string;
  /**
   * Number of times the collection has been viewed by users other than the owner.
   */
  views?: number;
  /**
   * Number of likes.
   */
  like_count?: number;
  /**
   * Order of the items.
   */
  order_array?: string[];
  /**
   * Order of the subsections.
   */
  subsection_order?: number[];
  /**
   * Map containing subsection's info.
   */
  subsection_info?: SubsectionInfoMap;
  /**
   * Map of items to collectionCreationEventValidator.
   */
  item_to_subsection_map?: ItemToSubsectionMap;
  /**
   * Dictionary of cover images.
   */
  cover_image_dict?: CoverImageDict;
  /**
   * Cover image URL.
   */
  cover_image_url?: string;
  /**
   * Notification settings for this collection
   */
  notifications_enabled?: boolean;
  /**
   * last time image previews to be updated:
   */
  images_last_update?: Timestamp;
  /**
   * Registry public access level
   */
  registry_public_level?: number;
  /**
   * Delivery information attached to a collection by the owner
   */
  delivery_info?: string;
  /**
   * Cash fund details attached to a collection by the owner
   */
  cash_fund?: CashFund;
}

export type Collection = CollectionDocument & ID;

export interface SingleCollectionKind extends Kind {
  item: Collection;
  reducer: ReducerType.DOC_SINGLE;
}

// Explore, landing page carousels etc.
export interface CollectionListCollectionsKind extends Kind {
  item: Collection;
  reducer: ReducerType.DOC_MAP;
}

export type CollectionType = 'public' | 'private' | 'hidden';

export const visibilityOptions: Record<
  CollectionType,
  { name: string; description: string; icon: string }
> = {
  public: {
    name: 'Public',
    description: 'Public: Accessible to anyone and visible in your profile',
    icon: 'earth',
  },
  private: {
    name: 'Private',
    description: 'Private: Only people with the link can view',
    icon: 'person-add',
  },
  hidden: {
    name: 'Hidden',
    description: 'Hidden: No-one but you can view',
    icon: 'lock-closed-outlined',
  },
};
