/*

Replace with using hook useDBAddDoc

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function add_new_collection(data,firebase,auth_user_uid,callback){
  return new Promise(
    (resolve,reject)=>{
      if (data.name.length > 0 && data.name.length<=100){
        data.public_level = 7;
        // double check a query with firestore frist:
        const created_at = firebase.serverTimestamp();
        const last_updated = created_at;
        const new_data = Object.assign(
          {},
          data,
          {
          created_at,
          last_updated,
          user_uid:auth_user_uid
          }
        );
        firebase.user_collection_list(
          auth_user_uid
        ).add(
          new_data
        ).then((response)=>
          {
            callback(response);
            resolve();
          }
        ).catch(
          (error)=>{
            send_error(error);
            reject();
          }
        );
      }
      else{
        send_error("Name must be between 1 and 100 characters");
        reject();
      }
    }
  );
}
