import {
  FC,
  ChangeEventHandler,
  MouseEventHandler,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import R, { EXTERNAL } from 'src/routes';
import { ServicesContext } from 'src/ServicesContext';
import { OutgoingMessageType } from 'src/services/Mobile';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { Link } from 'src/components/core/Link';
import { GetStartedDialog } from './GetStartedDialog.component';
import {
  GetStartedDialogProps,
  GetStartedDialogContainerProps,
  GetAppInitialChecklist,
  GetStartedAppFlags,
} from './GetStartedDialog.types';

const getInitialChecklist: GetAppInitialChecklist = (
  { onboarded, appInFavourites, hasSavedToCollections, installedExtension },
  onChecklistChange,
  goToOnboarding,
) => {
  return [
    {
      name: 'onboarded',
      title: 'Learn how to save products',
      description: (
        <>
          <Link to={R.HOW_TO_SAVE}>Click here</Link> for a reminder
        </>
      ),
      checked: onboarded,
      onChange: onChecklistChange('onboarded'),
    },
    {
      name: 'appInFavourites',
      title: 'Make saving to Moonsift easier',
      description: (
        <>
          <Link to="" onClick={goToOnboarding}>
            Add Moonsift to your share menu
          </Link>
        </>
      ),
      checked: appInFavourites,
      onChange: onChecklistChange('app_in_favourites'),
    },
    {
      name: 'hasSavedToCollections',
      title: 'Add items to your collections',
      description: (
        <>
          <Link to={R.RETAILERS_FEATURED}>Visit our most popular stores</Link>{' '}
          for ideas
        </>
      ),
      checked: hasSavedToCollections,
      onChange: onChecklistChange('has_saved_to_collections'),
    },
    {
      name: 'installedExtension',
      title: 'Get Moonsift on your desktop',
      description: (
        <>
          <Link to={EXTERNAL.CHROMESTORE}>
            Get Moonsift's browser extension
          </Link>
        </>
      ),
      checked: installedExtension,
      onChange: onChecklistChange('installed_extension'),
    },
  ];
};

const AppGetStartedDialogContainer: FC<GetStartedDialogContainerProps> = ({
  onAllSet,
  ...otherProps
}) => {
  // Services
  const { db, mobile } = useContext(ServicesContext);

  // Flags
  const onboarded = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.onboarded ? true : false,
  );
  const appInFavourites = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.app_in_favourites ? true : false,
  );
  const hasSavedToCollections = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.has_saved_to_collections ? true : false,
  );
  const installedExtension = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.installed_extension ? true : false,
  );

  // DB updater
  const authUserUID = useSelector<RootState, string>((state) => state.auth.id!);
  const [updateUserOwnerDocument] = useDBUpdateDoc<Partial<GetStartedAppFlags>>(
    db,
    db.userOwnerDoc(authUserUID),
  );

  // Toggle dimissal dialog
  const [isDismissing, setIsDismissing] = useState(false);
  const handleDismissalToggle = useCallback(() => {
    setIsDismissing((prevIsDismissing) => !prevIsDismissing);
  }, []);

  // Opens the checklist modal
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const handleChecklistToggle = useCallback(() => {
    setIsChecklistOpen((prevIsChecklistOpen) => !prevIsChecklistOpen);
  }, []);

  // Handle checklist changes
  const handleChecklistChange = useCallback(
    (name: string): ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        updateUserOwnerDocument({ [name]: event.currentTarget.checked });
      },
    [updateUserOwnerDocument],
  );

  // Set flag in DB indicating the user has dimissed this dialog
  const handleAllSet = useCallback(
    (event) => {
      updateUserOwnerDocument({ app_get_started_all_set: true });
      onAllSet?.(event);
    },
    [onAllSet, updateUserOwnerDocument],
  );

  // Opens the share menu when on mobile
  const goToOnboarding = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (event) => {
      event.preventDefault();
      mobile.postMessage(OutgoingMessageType.Navigate, {
        screenName: 'Onboarding',
      });
    },
    [mobile],
  );

  // Initialise checklist with the flags from DB
  const checklist: GetStartedDialogProps['checklist'] = getInitialChecklist(
    {
      onboarded,
      appInFavourites,
      hasSavedToCollections,
      installedExtension,
    },
    handleChecklistChange,
    goToOnboarding,
  );

  // Count how many checkboxes are there and how many are checked
  const total = checklist.length;
  const completed = checklist.filter(({ checked }) => checked).length;

  return (
    <GetStartedDialog
      total={total}
      completed={completed}
      isDismissing={isDismissing}
      isChecklistOpen={isChecklistOpen}
      checklist={checklist}
      onChecklistToggle={handleChecklistToggle}
      onDismissalToggle={handleDismissalToggle}
      onAllSet={handleAllSet}
      {...otherProps}
    />
  );
};

export { AppGetStartedDialogContainer as AppGetStartedDialog };
