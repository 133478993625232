/*

This modal displays information about the price history for a product (got to by clicking on the price on the product).

TODO: typescript, design system.

*/
import React, {useEffect} from 'react';
import {useClickOutside} from 'src/hooks/useClickOutside';
import styled from 'styled-components';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { useSelector } from 'react-redux';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import {
  GeneralOverlayWithinContainer,
  RightContainerProduct,
  RightTitleContainer,
  LogoAndTitle,
  LogoInTitle,
  CollectionSettings,
  BothBottomBar,
  RightFlow,
  Cross,
  Separator
} from 'src/mvp22/modal-components/ModalComponents.js';
import {ProductLeft} from 'src/mvp22/modal-components/ProductLeft';
import Button from 'src/mvp22/form-components/Button';

const FlexRow = styled.div`
  width:100%;
  align-items:left;
  margin:2px 0px;
  display: flex;
`;

const LeftTextBody4 = styled(TEXTSTYLE.BODY4)`
  text-align:left;
  align-items: center;
  align-content: center;
  display: flex;
`;

const LeftTextOther2 = styled(TEXTSTYLE.OTHER2)`
  text-align:left;
  align-items: center;
  align-content: center;
  display: flex;
`;

const UpdatedSymbol = styled.img`
  height:22px;
  margin-right:8px;
`;

const PriceSymbol = styled.img`
  height:12px;
  margin-right:8px;
`;

const InfoText = styled(TEXTSTYLE.BODY4)`
  text-align:left;
`;

export const ProductUpdatesModal = (
  {
    // params
    collectionUID,
    collectionUserUID,
    itemUID,
    productImage,
    // functions
    closeFunction
  }
)=>{
  const productDataStoreAs = getCollectionItemStoreAs(collectionUID,collectionUserUID,itemUID);
  const productData = useSelector((state) => state.productData?state.productData[productDataStoreAs]:undefined);

  const PRICE = productData?productData.price:"";
  const PRICE_CHANGE = productData?productData.priceChange:"";
  const ORIGINAL_PRICE = productData?productData.savedPrice??productData.price:"";
  const UPDATED_TIME =  productData?productData.lastUpdated:undefined;
  const containerRef = useClickOutside(closeFunction);

  // Close if product deleted from collection:
  useEffect(
    ()=>{
      if (productData===undefined){
        closeFunction();
      }
    },
    [productData,closeFunction]
  );

  return(
    <GeneralOverlayWithinContainer ref={containerRef}>
      <ProductLeft
        productData={productData}
        productImage={productImage}
      />
        <RightContainerProduct>
          <RightTitleContainer>
            <LogoAndTitle>
              <LogoInTitle src={MEDIA.LOGO_DEFAULT}/>
              <TEXTSTYLE.HEADING5>Product price history</TEXTSTYLE.HEADING5>
            </LogoAndTitle>
            <TEXTSTYLE.LINKA href="#" tabIndex="13002" onClick={closeFunction}><Cross src={MEDIA.EXIT_CROSS_THIN}/></TEXTSTYLE.LINKA>
          </RightTitleContainer>
        <CollectionSettings>
        {
          (PRICE_CHANGE)?
          <FlexRow>
          <LeftTextBody4 style={{fontWeight:"bold",color:(PRICE_CHANGE.sign==="-")?M.COL.UPDATECOLS.GREEN:M.COL.UPDATECOLS.GREY}}>
            <PriceSymbol src={(PRICE_CHANGE.sign==="-")?MEDIA.PRICE_DOWN:MEDIA.PRICE_UP}/>
            {`${PRICE_CHANGE.percentage_difference}% price ${(PRICE_CHANGE.sign==="-")?"decrease":"increase"}`}
          </LeftTextBody4>
          </FlexRow>
          :""
        }
        <FlexRow>
          <LeftTextBody4>
            Price when first saved to collection:&nbsp;&nbsp;{ORIGINAL_PRICE}
          </LeftTextBody4>
        </FlexRow>
        <FlexRow>
          <LeftTextBody4>
            Most recent price:&nbsp;&nbsp;<b style={(PRICE_CHANGE && PRICE_CHANGE.sign==="-")?{color:M.COL.UPDATECOLS.GREEN}:{}}>{PRICE}</b>
          </LeftTextBody4>
        </FlexRow>
        <Separator height="12px"/>
        {
          (UPDATED_TIME!==undefined)?
          <>
            <FlexRow>
            <LeftTextBody4>Moonsift was last able to update this price</LeftTextBody4>
            </FlexRow>
            <FlexRow>
            <LeftTextOther2 style={{color:M.COL.UPDATECOLS[UPDATED_TIME[1].toUpperCase()]}}>
              <UpdatedSymbol src={MEDIA['RECENCY_'+UPDATED_TIME[1].toUpperCase()]}/>
              {UPDATED_TIME[0]}
            </LeftTextOther2>
            </FlexRow>
            </>
          :""
        }
        <Separator height="8px"></Separator>
        <FlexRow>
          <InfoText>
            <b>Note:</b> Prices update each time a Moonsift extension user visits this product at the respective retailer. Price updates may not be instant and do not guarantee an item is in stock.
          </InfoText>
        </FlexRow>
        </CollectionSettings>
        <BothBottomBar>
          <RightFlow>
            <Button
              text="Close"
              msstyle="square_popup"
              onClick={closeFunction}
              tabIndex={2001}
            />
          </RightFlow>
        </BothBottomBar>
        </RightContainerProduct>
      </GeneralOverlayWithinContainer>
  );
};
