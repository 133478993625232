/*
  Shown when the user clicks the "Mark as bought" button. The main flow for marking an item as bought.
*/

import styled from 'styled-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import {
  Stack,
  Typography,
  StandardButton,
  ControlledTextField,
} from '@moonsifttech/design-system';
import { RootState } from 'src/index';
import { useLoggedIn } from 'src/hooks/useLoggedIn';
import { useMarkAsBought } from 'src/hooks/useMarkAsBought';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { withExhaustedCheck } from 'src/components/collection/withExhaustedCheck';
import { ModalContent } from 'src/components/core/ModalContent';
import { GoToBuyItemCard } from 'src/components/collection/GoToBuyItemCard';
import { GiftListInfo } from 'src/components/collection/GiftListInfo';
import { QuantitySelector } from 'src/components/collection/QuantitySelector';

type Props = {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}

const UnstyledMarkAsBoughtDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: Props) => {
  const { isLoggedIn } = useLoggedIn();

  const [ setModal, closeModal ] = useModal();

  const [ quantity, setQuantity ] = useState(0);

  const { working, mark } = useMarkAsBought({
    collectionUID: collectionId,
    collectionUserUID: collectionUserId,
    collectionItemUID: itemId,
    onSuccess: () => {
      setModal({
        type: ModalTypes.MarkedAsBought,
        collectionId,
        collectionUserId,
        itemId,
      });
    },
    onAlreadyBoughtError: () => {
      setModal({
        type: ModalTypes.AlreadyBought,
        collectionId,
        collectionUserId,
        itemId,
      });
    },
  });

  const ownerName = useSelector<RootState, string>(({ db }) => {
    return db.user_public.map[collectionUserId]?.name ?? 'the owner';
  });
  const ownerNote = useSelector<RootState, string | undefined>(({ db }) => {
    return db.collection_registry_notes.data?.[itemId]?.note;
  });
  const items = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );

  const item = items
    .find((item) => item.id === itemId);

  const requestedQuantity = item?.quantity ?? 1;

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        <span>Confirm that you have bought or will buy this item</span>
      </Typography>

      <Typography variant="primary.r16" component="p">
        The item(s) will become unavailable for others to buy
      </Typography>

      <div className="MarkAsBoughtDialog-infoWrapper">
        {ownerNote && (
          <GiftListInfo>
            <Typography
              variant="primary.l14"
              component="p"
              style={{ marginBottom: '4px' }}
            >
              {`A note from ${ownerName}:`}
            </Typography>
            <Typography variant="primary.r14">
              {ownerNote}
            </Typography>
          </GiftListInfo>
        )}
      </div>

      {requestedQuantity > 1 && (
        <div className="QuantitySelector-wrapper">
          <div className='QuantityDisplay-wrapper'>
            <Typography
              className='QuantityDisplay-header'
              component='h2'
              variant='primary.b14'
              color='grey.1'
            >
              How many are you buying?
            </Typography>
            <Typography
              className='QuantityDisplay-quantity'
              component='span'
              variant='primary.r14'
              color='grey.2'
            >
              Requested: {requestedQuantity}
            </Typography>
            <Typography
              className='QuantityDisplay-needed'
              component='span'
              variant='primary.r14'
              color='background.purple'
            >
              Still needs: {requestedQuantity - (item?.purchased ?? 0)}
            </Typography>
          </div>

          <QuantitySelector
            initialValue={0}
            onChange={setQuantity}
            maximum={requestedQuantity - (item?.purchased ?? 0)}
            minimum={1}
          />
        </div>
      )}

      <GoToBuyItemCard
        className="MarkAsBoughtDialog-productCard"
        collectionUID={collectionId}
        collectionUserUID={collectionUserId}
        collectionItemUID={itemId}
        showLink={true}
      />

      {isLoggedIn ? (
        <Stack direction='row' justifyContent='flex-end'>
          <StandardButton
            className="MarkAsBoughtDialog-noButton"
            variant="primary-white"
            onClick={closeModal}
          >
            Not yet
          </StandardButton>

          <StandardButton
            variant="primary-black"
            loading={working}
            onClick={() => mark(requestedQuantity > 1 ? quantity : 1)}
            disabled={requestedQuantity > 1 && quantity === 0}
          >
            I confirm
          </StandardButton>
        </Stack>
      ) : (
        <Formik
          initialValues={{ name: '' }}
          validationSchema={object({
            name: string().required('A name is required'),
          })}
          onSubmit={({ name }) => mark(requestedQuantity > 1 ? quantity : 1, name)}
        >
          {() => {
            return (
              <Form>
                <ControlledTextField
                  className="MarkAsBoughtDialog-name"
                  id="name"
                  name="name"
                  placeholder="Your name"
                  fullWidth={true}
                />

                <Stack direction='row' justifyContent='flex-end'>
                  <StandardButton
                    className="MarkAsBoughtDialog-noButton"
                    variant="primary-white"
                    onClick={closeModal}
                  >
                    Not yet
                  </StandardButton>

                  <StandardButton
                    variant="primary-black"
                    loading={working}
                    type="submit"
                    disabled={requestedQuantity > 1 && quantity === 0}
                  >
                    I confirm
                  </StandardButton>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </ModalContent>
  );
};

const StyledMarkAsBoughtDialog = styled(UnstyledMarkAsBoughtDialog)`
  .MarkAsBoughtDialog-productCard {
    margin-bottom: 2.5rem;
  }

  .MarkAsBoughtDialog-noButton {
    margin-right: 0.75rem;
  }

  .MarkAsBoughtDialog-infoWrapper {
    div:not(:last-child) {
      margin-bottom: 1rem;
    }
    div:last-child {
      margin-bottom: 1.75rem;
    }
  }

  .MarkAsBoughtDialog-name {
    margin-bottom: 2rem;
  }

  .QuantitySelector-wrapper {
    display: inline-flex;
    align-items: flex-end;
    margin-bottom: 2rem;
  }

  .QuantityDisplay-wrapper {
    margin-right: 10px;
    line-height: 20px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-right: 1.5rem;
    }
  }

  .QuantityDisplay-header {
    margin-bottom: 6px;
  }

  .QuantityDisplay-quantity {
    margin-right: 0.5rem;
  }
`;

export const MarkAsBoughtDialog = withExhaustedCheck<Props>(StyledMarkAsBoughtDialog);
