import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';
import R from 'src/routes';
import { UseCaseCardProps } from './UseCaseCard.types';
import { Link } from 'src/components/core/Link';

const UnstyledUseCaseCard: VFC<UseCaseCardProps> = ({
  className,
  style,
  imagePath,
  heading,
  content,
  hideCTA,
  ctaLink,
}) => {
  return (
    <Link to={ctaLink} className={className} style={style}>
      <Stack justifyContent="center" alignItems="center">
        {' '}
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${process.env.REACT_APP_STATIC_URL}${imagePath}.png`}
          />
          <img
            className="RegistryLanding-brandsBanner"
            alt="Brands banner"
            src={`${process.env.REACT_APP_STATIC_URL}${imagePath}.2x.png`}
          />
        </picture>
        <Typography variant="editorial.r22" align="center" component="h3">
          {heading}
        </Typography>
        <Typography variant="primary.l16" align="center" component="p">
          {content}
        </Typography>
        {hideCTA !== true && (
          <Typography variant="primary.m16" align="center">
            <u>{ctaLink === R.SIGNUP ? 'Get started' : 'Learn more'}</u> - it's
            free
          </Typography>
        )}
      </Stack>
    </Link>
  );
};

export const UseCaseCard = styled(UnstyledUseCaseCard)`
  padding: 20px 16px 24px;
  width: 328px;
  border: ${({ theme }) => theme.fns.getBorder('secondary')};
  margin: 12px;
  text-decoration: none;

  ${({ theme }) => theme.fns.getShapeStyles('soft')}

  picture img {
    display: block;
    width: 110px;
    margin-bottom: 20px;
  }

  h3,
  p {
    margin-bottom: 8px;
  }
`;
