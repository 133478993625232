import 'react-app-polyfill/stable';
// To support .allSettled explicitly since this is in the ECMA2020 spec, which createreactapp's polyfill doesn't detect yet:
import 'core-js/features/promise';

import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { ThemeProvider } from 'styled-components';
import {
  batmanTheme,
  CssBaseline,
  setAppElement,
} from '@moonsifttech/design-system';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import '@fontsource/roboto/700.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/300.css';
import '@fontsource/josefin-sans/700.css';
import '@fontsource/josefin-sans/300.css';
import '@moonsifttech/font-juana';

import reducers from './store/reducers';
import rootSaga from './store/sagas';
import { App } from './components/core/App';
import { createBrowserHistory } from './utils/createBrowserHistory';
import { setupServices } from './utils/setupServices';
import { ServicesContext } from './ServicesContext';

// Load env vars
require('dotenv').config();

// MUI theme
const muiTheme = createTheme();

// This helps keep a11y on modal components that use react-modal
// http://reactcommunity.org/react-modal/accessibility/
setAppElement('#root');

// Setup services
const services = setupServices();

// Set up Redux store:
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  reducers(history),
  composeWithDevTools(
    applyMiddleware(
      routerMiddleware(history),
      thunkMiddleware.withExtraArgument({ services }), // TODO: Remove this when all converted to Sagas.
      sagaMiddleware,
      //    loggerMiddleware // apparently "neat middleware that logs actions"!
    ),
  ),
);
sagaMiddleware.run(rootSaga);
export type RootState = ReturnType<typeof store.getState>;

// Commented out for now - we might add this back in with our own functions shortly
// A and B below are for sending page load calls to segment. they are a necessary part of making the tools downstream from segment work, e.g. mixpanel.
// A. captures cases where a user visits a page after their initial loading of our web app. for example when they land on moonsift.com and then go to moonsift.com/retailers.
history.listen((location) => {
  services.analytics.reportPageView(location.pathname);
});
// B. captures all initial page loads / reloads / when addresses are typed in the url bar
services.analytics.reportPageView(window.location.pathname);

// Render:
ReactDOM.render(
  <React.StrictMode>
    <ServicesContext.Provider value={services}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={batmanTheme}>
            <MuiThemeProvider theme={muiTheme}>
              <CssBaseline />
              <App />
            </MuiThemeProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    </ServicesContext.Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// Message:
console.log(
  "%c\n      MOONSIFT\n%c\n ** WE'RE HIRING ** Help us build Moonsift - join our groovy team! Get in touch: davidw [at] moonsift.com%c\nCurrently recruiting react ninjas, javascript extension gurus, ace data scientists, dev-op divas\nThis is version DEILENAAR-2.4.0, built at: " +
    process.env.REACT_APP_BUILDDATETIME +
    ' %c\nPlease report any bugs to us using the feedback button.',
  "font-family:Arial;font-size:32px;background-repeat:none;background-image:url('https://www.moonsift.com/static/logos/logo.svg');background-size:contain;background-position:0% 50%;background-repeat:no-repeat;background-color:#FEDF91;color:black;",
  'font-size:12px;font-family:Arial;background-color:lightgreen',
  'font-size:12px;font-family:Times New Roman;margin-top:4px',
  'font-size:12px;font-family:Arial;background-color:pink;margin-top:4px',
);
