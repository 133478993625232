/*

This looks up for a userUID given a username which may be a UID, or a user-made displayUsername...:

*/

import { DB } from "src/services/DB";

export const getProfileUserUID = async (usernameInput:string,dB:DB) =>{
  // Case is a UID already:
  if (!usernameInput){
    return "";
  }else{
    if (usernameInput.length>24){
      return usernameInput;
    }else{
      return await (
        dB.getDoc(
          dB.username(
            usernameInput.toLowerCase()
          )
        ).then(
          response=>{
            if (response.exists() && response.data().user_uid){
              return response.data().user_uid;
            }
            return usernameInput;
          }
        ).catch(
          (err)=>{
            console.log(err);
            return usernameInput;
          }
        )
      );
    }
  }
};
