import React from 'react';
import { AddAnItemManuallyProps } from './AddAnItemManually.types';
import {
  // AddCircleIcon,
  // ButtonBase,
  ControlledTextField,
  Stack,
  StandardButton,
  Typography,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';
import styled from 'styled-components';
import { AddAnItemManuallyAddImage } from 'src/components/collection/AddAnItemManuallyAddImage';
import MEDIA from 'src/mvp22/media';

const UnstyledAddAnItemManually: React.FC<AddAnItemManuallyProps> = ({
  className,
  onAddProduct,
  style,
  validationSchema,
  errorState,
  onImageAddition,
  images,
  toggleImageSelection,
  imageDims,
  onImageDimsUpdate,
}) => {
  return (
    <Stack className={className} style={style}>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          link: '',
          description: '',
          price: '',
          images: [] as [string, boolean][],
        }}
        onSubmit={onAddProduct}
        className="AddAnItemManually-form"
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form>
            <ControlledTextField
              className="AddAnItemManually-input"
              name="link"
              placeholder="Enter a link, including 'https://'"
              autoComplete="off"
              fullWidth
            />
            <ControlledTextField
              className="AddAnItemManually-input"
              name="description"
              placeholder="Enter a description"
              autoComplete="off"
              fullWidth
            />
            <ControlledTextField
              className="AddAnItemManually-input"
              name="price"
              placeholder="Enter price including currency e.g. $99.00"
              autoComplete="off"
              fullWidth
            />
            <Stack
              direction="row"
              className="AddAnItemManually-productImagesBox"
              spacing={8}
              wrap="wrap"
            >
              {/* <ButtonBase
                data-testid="AddAnItemManually-addImageButton"
                className="AddAnItemManually-addImageButton"
              >
                <Stack direction="column" alignItems="center">
                  <AddCircleIcon className="AddAnItemManually-addCircleIcon" />
                  <Typography
                    variant="primary.l14"
                    component="p"
                    align="center"
                    className="AddAnItemManually-addImageText"
                  >
                    Add Image
                  </Typography>
                </Stack>
              </ButtonBase> */}
              {images.map((imageURL, index) => (
                <div
                  className="AddAnItemManually-ProductImageContainer"
                  onClick={(event) => toggleImageSelection(index)}
                  key={index}
                >
                  {imageURL[1] === true ? (
                    <img
                      src={MEDIA.TICK}
                      alt="Tick"
                      className="AddAnItemManually-Tick"
                    />
                  ) : (
                    ''
                  )}
                  <img
                    className="AddAnItemManually-ProductImage"
                    src={imageURL[0]}
                    alt="Product"
                    onLoad={(event) => {
                      const target = event.target as HTMLImageElement;
                      onImageDimsUpdate(imageURL[0], {
                        width: target.naturalWidth,
                        height: target.naturalHeight,
                      });
                    }}
                  />
                  <Typography
                    variant="primary.l14"
                    className="AddAnItemManually-ImageDims"
                  >
                    {imageDims[imageURL[0]]
                      ? `${imageDims[imageURL[0]]?.width} x ${
                          imageDims[imageURL[0]]?.height
                        } pixels`
                      : ``}
                  </Typography>
                </div>
              ))}
            </Stack>
            <AddAnItemManuallyAddImage onImageURLAdded={onImageAddition} />
            <Typography
              variant="primary.l16"
              className="AddAnItemManuallyAddImage-errorText"
            >
              {errorState}
            </Typography>
            <Stack className="AddAnItemManuallyAddImage-submit">
              <StandardButton
                variant="primary-black"
                loading={isSubmitting}
                type="submit"
              >
                Add this item
              </StandardButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </Stack>
  );
};

export const AddAnItemManually = styled(UnstyledAddAnItemManually)`
  .AddAnItemManually-input {
    margin-bottom: 12px;
  }
  .AddAnItemManually-info {
    margin-bottom: 12px;
  }
  .AddAnItemManually-addImageButton {
    width: 90px;
    height: 135px;
    border: 1px dashed var(--common-black);
    background-color: var(--common-white);
    color: var(--common-black);
    border-radius: 5px;
  }
  .AddAnItemManually-addCircleIcon {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
  }
  .AddAnItemManuallyAddImage-submit {
    margin-top: 12px;
  }
  .AddAnItemManually-ProductImageContainer {
    margin: 2px;
    position: relative;
    margin-bottom: 8px;
    width: 110px;
    height: 154px;
  }
  .AddAnItemManually-Tick {
    position: absolute;
    top: 0px;
    left: 0px;
  }
  .AddAnItemManually-ProductImage {
    width: 110px;
    height: 134px;
    object-fit: contain;
    background-color: var(--grey-4);
    border-radius: 4px;
    cursor: pointer;
  }
  .AddAnItemManually-addImageText {
    margin-bottom: 0px;
  }
  .AddAnItemManually-productImagesBox {
    margin-bottom: 12px;
    max-height: 150px;
    overflow-y: auto;
  }
  .AddAnItemManuallyAddImage-errorText {
    color: var(--error-main);
  }
`;
