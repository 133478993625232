import { MouseEvent, KeyboardEvent } from 'react';
import { ActionNames } from 'src/types/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { ModalAction, ModalState } from 'src/types';

// Helper functions that set the modal in redux or close it.
export const useModal = (): [
  (payload: ModalState) => void,
  (ev?: MouseEvent | KeyboardEvent) => void,
] => {
  const dispatch = useDispatch();
  const setModal = useCallback(
    (payload: ModalState) => {
      dispatch<ModalAction>({
        type: ActionNames.MODAL_SET,
        payload,
      });
    },
    [dispatch],
  );

  const closeModal = useCallback(
    (ev?: MouseEvent | KeyboardEvent) => {
      ev?.preventDefault();

      dispatch<ModalAction>({
        type: ActionNames.MODAL_CLOSE,
        payload: undefined,
      });
    },
    [dispatch],
  );

  return [setModal, closeModal];
};
