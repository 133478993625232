import { push } from 'connected-react-router';
import Cookies from 'universal-cookie';

import R from 'src/routes';
import { M } from 'src/mvp22/constants';
import { AppThunk, ActionNames } from 'src/types/actions';
import {
  CreatorApplicationState,
  CreatorApplicationRequest,
  UpdateCreatorApplicationRequestAction,
  UpdateCreatorApplicationErrorMessageAction,
  ResetCreatorApplicationAction,
} from 'src/types/models/creatorApplication.model';

const cookies = new Cookies();

export function updateCreatorApplicationRequest(
  payload: Partial<CreatorApplicationState>,
): UpdateCreatorApplicationRequestAction {
  return {
    type: ActionNames.UPDATE_CREATOR_APPLICATION,
    payload,
  };
}

export function updateCreatorApplicationErrorMessage(payload: {
  errorMessage: string;
}): UpdateCreatorApplicationErrorMessageAction {
  return {
    type: ActionNames.UPDATE_CREATOR_APPLICATION_ERROR_MESSAGE,
    payload,
  };
}

export function resetCreatorApplicationRequest(): ResetCreatorApplicationAction {
  return {
    type: ActionNames.RESET_CREATOR_APPLICATION,
    payload: undefined,
  };
}

export const apply = (): AppThunk => {
  return async (dispatch, getState, { services }) => {
    const { cloud } = services;
    const { creatorApplication } = getState();

    // Remove channels that are empty
    const channels = creatorApplication.channels.filter(
      ({ name, url }) => !(name === '' && url === ''),
    );

    const referralCode = cookies.get(M.CREATOR_CODE_COOKIE_KEY);

    const feature = creatorApplication.feature === 'Yes';

    const application: CreatorApplicationRequest = {
      ...creatorApplication,
      channels,
      feature,
    };

    if (referralCode) {
      application.referral_code = referralCode;
    }

    try {
      await cloud.fastAPI2('contentCreator-apply', application);

      dispatch(resetCreatorApplicationRequest());
      // General Creators and stylist paths now merge...
      dispatch(push(R.CREATOR_APPLICATION_DONE));
    } catch (error) {
      const typedError = error as Error;
      dispatch(
        updateCreatorApplicationErrorMessage({
          errorMessage: typedError.message,
        }),
      );
    }
  };
};

export const markAsOnboarded = (): AppThunk => {
  return async (dispatch, getState, { services }) => {
    const { cloud } = services;

    await cloud.call('contentCreator-markAsOnboarded')();
  };
};
