import { VFC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'src';
import R from 'src/routes';
import { Status } from 'src/types/common';
import { CreatorLandingCtaButtonContainerProps } from './CreatorLandingCtaButton.types';
import { CreatorLandingCtaButton } from './CreatorLandingCtaButton.component';
import { ServicesContext } from 'src/ServicesContext';

const CreatorLandingCtaButtonCtaButtonContainer: VFC<
  CreatorLandingCtaButtonContainerProps
> = (props) => {
  // Content Creator application handler
  const history = useHistory();
  const { analytics } = useContext(ServicesContext);
  const handleApply = useCallback(() => {
    analytics.recordEvent('WebApp:CreatorLandingCTAButton:Pressed', {
      name: props.eventName,
    });
    history.push(R.CREATOR_APPLICATION_ONE);
  }, [history, analytics, props.eventName]);

  const creatorStatus = useSelector<RootState, Status | undefined>(
    ({ db }) => db.user_verified_settings.data?.creator?.status,
  );

  const hasApplied = Boolean(creatorStatus) && creatorStatus !== 'denied';

  const handleGoToMyEarnings = useCallback(() => {
    history.push(R.CREATOR_DASHBOARD);
  }, [history]);

  return (
    <CreatorLandingCtaButton
      hasApplied={hasApplied}
      onApply={handleApply}
      onGoToMyEarnings={handleGoToMyEarnings}
      {...props}
    />
  );
};

export { CreatorLandingCtaButtonCtaButtonContainer as CreatorLandingCtaButton };
