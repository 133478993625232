import { useCallback, VFC } from 'react';
import { useHistory } from 'react-router-dom';

import R from 'src/routes';
import { markAsOnboarded } from 'src/store/actions/creatorApplication';
import {
  CreatorDialogContainerProps,
  CreatorDialogProps,
} from './CreatorDialog.types';
import { CreatorDialog } from './CreatorDialog.component';
import { useDispatch } from 'react-redux';

const CreatorDialogContainer: VFC<CreatorDialogContainerProps> = ({
  onClose,
  ...otherProps
}) => {
  const history = useHistory();
  const handleCreatorDashboard = useCallback<
    CreatorDialogProps['onCreatorDashboard']
  >(() => {
    history.push(R.CREATOR_DASHBOARD);
  }, [history]);

  const dispatch = useDispatch();
  const handleClose = useCallback<CreatorDialogProps['onClose']>(
    (event) => {
      onClose(event);
      dispatch(markAsOnboarded());
    },
    [dispatch, onClose],
  );

  return (
    <CreatorDialog
      onCreatorDashboard={handleCreatorDashboard}
      onClose={handleClose}
      {...otherProps}
    />
  );
};

export { CreatorDialogContainer as CreatorDialog };
