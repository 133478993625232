import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  AddCircleIcon,
  RemoveCircleIcon,
  iconBaseClassNames,
} from '@moonsifttech/design-system';

import { Link } from 'src/components/core/Link';
import {
  getMainCells,
  getItemCells,
  mobileOrder,
  desktopOrder,
} from './getCells';
import { CollectionEarningsTableRowProps } from './CollectionEarningsTableRow.types';

const UnstyledCollectionEarningsTableRow: VFC<
  CollectionEarningsTableRowProps
> = ({
  className,
  style,
  isMobile,
  expanded,
  collectionUID,
  collectionName,
  clicks,
  sales,
  totalEarnings,
  items,
  onItemsToggle,
}) => {
  const order = isMobile ? mobileOrder : desktopOrder;
  const mainCells = getMainCells({ clicks, sales, totalEarnings });
  return (
    <>
      <TableRow className={className} style={style}>
        <TableCell
          className="CollectionEarningsTableRow-itemsToggle"
          component="th"
          scope="row"
          onClick={onItemsToggle}
        >
          {expanded ? <RemoveCircleIcon /> : <AddCircleIcon />}
          {collectionName}
        </TableCell>
        {order.map((columnName) => mainCells[columnName])}
      </TableRow>
      {expanded &&
        items.map(({ link, brand, sales, clicks, given, due, pending }) => {
          const itemCells = getItemCells({
            brand,
            clicks,
            sales,
            given,
            due,
            pending,
          });
          return (
            <TableRow
              key={`${collectionUID}_${link}`}
              className={clsx(className, 'CollectionEarningsTableRow-itemRow')}
              style={style}
            >
              <TableCell component="th" scope="row">
                <Link to={link}>{link}</Link>
              </TableCell>
              {order.map((columnName) => itemCells[columnName])}
            </TableRow>
          );
        })}
    </>
  );
};

export const CollectionEarningsTableRow = styled(
  UnstyledCollectionEarningsTableRow,
)`
  white-space: nowrap;

  &:not(.CollectionEarningsTableRow-itemRow, :first-child) .MuiTableCell-root {
    border-top: 1px solid var(--grey-5);
  }

  &.CollectionEarningsTableRow-itemRow th.MuiTableCell-root {
    padding-left: 50px;
  }

  .CollectionEarningsTableRow-itemsToggle {
    cursor: pointer;

    > .${iconBaseClassNames.root} {
      vertical-align: middle;
      margin-right: 10px;
    }
  }
`;
