/*

make into REDUX single Docs...

*/
export default function get_supported_sites(firebase){
  // Ensure that the scratch (+ site uid array) is loaded:
  return new Promise (
    (resolve)=>{
      Promise.allSettled(
        [
          firebase.supported_sites().get(),
          firebase.trending_sites().get(),
          firebase.featured_sites().get()
        ]
      ).then(
        responses=>{
          const out = {resolved:false,data:{trending_retailers:[]}};
          responses.forEach(
            this_response=>{
              if (this_response.status!=="fulfilled"){
                console.error(this_response.reason);
              }
            }
          );
          if (responses[0].status==="fulfilled"
           &&
            responses[0].value.exists() === true
           &&
            responses[0].value.data().names_array
          ){
            out.resolved = true;
            out.data.names_array = responses[0].value.data().names_array;
          }
          if (responses[1].status==="fulfilled"
           &&
            responses[1].value.exists() === true
           &&
            responses[1].value.data().list
          ){
            out.data.trending_retailers = [responses[1].value.data().list];
          }
          if (responses[2].status==="fulfilled"
           &&
            responses[2].value.exists() === true
           &&
            responses[2].value.data().list_of_lists
          ){
            out.data.trending_retailers = [...out.data.trending_retailers,...responses[2].value.data().list_of_lists];
          }
          resolve(out);
        }
      );
    }
  );

}
