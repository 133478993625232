/*

REDUX DocByID MAP
Replaced with:
useDBRetrieveDoc<'user_public'>(dB, dB.userPubicDoc, 'user_public', userPublicParser,collectionUserUID,[collectionUserUID],"get",false);
const singleCollectionUserPublicDocData = useSelector((state: RootState) => state.db.user_public.map[collectionUserUID]);


*/

export default function get_user_profile_public(user_uid,firebase){
  return firebase.user_public_info(
    user_uid
  ).get().then(
    response=>{
      if (response.exists()){
        const data = response.data();
        data.display_username = user_uid;
        if (data.original_username){
          data.display_username = data.original_username;
        }
        if (data.alias_username){
          data.display_username = data.alias_username;
        }
        data.exists = true;
        return data;
      }
      return {
        display_username:user_uid,
        exists:false
      };
    }
  );
}
