import { FC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Typography,
  Stack,
  StandardButton,
  InfoIcon,
  LinearProgress,
  Modal,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { GetStartedCloseButton } from 'src/components/profile/GetStartedCloseButton';
import { GetStartedChecklistDialog } from 'src/components/profile/GetStartedChecklistDialog';
import { GetStartedDialogProps } from './GetStartedDialog.types';

const UnstyledGetStartedDialog: FC<GetStartedDialogProps> = ({
  className,
  style,
  total,
  completed,
  isDismissing,
  isChecklistOpen,
  checklist,
  onDismissalToggle,
  onChecklistToggle,
  onAllSet,
}) => {
  return (
    <Paper className={className} style={style} variant="outlined">
      {isDismissing ? (
        <>
          <Stack
            className="GetStartedDialog-closing"
            direction="column"
            justifyContent="space-between"
          >
            <div>
              <Typography
                className="GetStartedDialog-title"
                variant="primary.b18"
                component="h3"
              >
                Confirm you no longer need these steps to help you get started.
              </Typography>
              <Typography variant="primary.r16" component="p">
                Once confirmed this tile will no longer show in your profile.
              </Typography>
            </div>
            <Stack
              className="GetStartedDialog-closingActions"
              direction="row"
              justifyContent="flex-end"
              spacing={12}
            >
              <StandardButton variant="secondary" onClick={onDismissalToggle}>
                Back
              </StandardButton>
              <StandardButton variant="primary-white" onClick={onAllSet}>
                Confirm
              </StandardButton>
            </Stack>
          </Stack>
        </>
      ) : (
        <>
          <GetStartedCloseButton
            className="GetStartedDialog-closeButton"
            onClick={onDismissalToggle}
          />
          <Typography
            className="GetStartedDialog-title"
            variant="primary.b18"
            component="h3"
          >
            Get started with Moonsift
          </Typography>
          <Typography
            className="GetStartedDialog-subtitle"
            variant="primary.r14"
            component="h4"
            color="grey.2"
          >
            Four simple steps to get you up and running
          </Typography>
          <Stack
            className="GetStartedDialog-progressContainer"
            direction="row"
            alignItems="center"
            spacing={12}
          >
            <Typography variant="primary.b18">
              {completed}/{total}
            </Typography>
            <LinearProgress
              className="GetStartedDialog-progress"
              value={Math.round(100 * (completed / total))}
            />
          </Stack>
          <Stack
            className="GetStartedDialog-actions"
            direction="row"
            justifyContent="flex-end"
          >
            {completed !== 4 ? (
              <StandardButton
                variant="primary-white"
                onClick={onChecklistToggle}
              >
                Show me how
              </StandardButton>
            ) : (
              <StandardButton variant="primary-black" onClick={onAllSet}>
                I'm all set
              </StandardButton>
            )}
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={8}
          >
            <InfoIcon />
            <Link to={R.HOW_TO_SAVE} variant="primary.b14">
              FAQs
            </Link>
          </Stack>
          <Modal isOpen={isChecklistOpen} onRequestClose={onChecklistToggle}>
            <GetStartedChecklistDialog
              checklist={checklist}
              onClose={onChecklistToggle}
              onAllSet={onAllSet}
            />
          </Modal>
        </>
      )}
    </Paper>
  );
};

export const GetStartedDialog = styled(UnstyledGetStartedDialog)`
  position: relative;
  width: 316px;
  height: 220px;
  padding: 20px;
  border-radius: 5px;

  &.${paperClassNames.variantOutlined} {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  .GetStartedDialog-closeButton {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .GetStartedDialog-title {
    margin: 0 0 4px;
  }
  .GetStartedDialog-subtitle {
    margin: 0 0 7px;
  }

  .GetStartedDialog-progressContainer {
    margin-bottom: 10px;
  }

  .GetStartedDialog-progress {
    width: 100%;
  }

  .GetStartedDialog-actions {
    margin-bottom: 24px;
  }

  .GetStartedDialog-closing {
    height: 100%;
  }

  .GetStartedDialog-closingActions {
    width: 100%;
  }
`;
