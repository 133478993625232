import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { RootState } from 'src/index';
import {
  AddAnItemManuallyContainerProps,
  AddAnItemManuallyProps,
} from './AddAnItemManually.types';
import { AddAnItemManually } from './AddAnItemManually.component';
import { ServicesContext } from 'src/ServicesContext';

const validationSchema = object({
  link: string()
    .required('Please enter a link (including https://)')
    .test('startsWithHello', 'The link must start with "https://"', (value) => {
      if (!value) return false;
      return value.startsWith('https://') || value.startsWith('http://');
    }),
  description: string().required('Please enter a description'),
  price: string().required('Please enter a price'),
});

const AddAnItemManuallyContainer: React.FC<AddAnItemManuallyContainerProps> = ({
  onClose,
  ...otherProps
}) => {
  const { analytics, cloud } = useContext(ServicesContext);

  const [errorState, setErrorState] = useState('');
  const [images, setImageState] = useState<[string, boolean][]>([]);
  const [imageDims, setImageDims] = useState<
    Partial<Record<string, { width: number; height: number }>>
  >({});

  const collectionUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.id ?? '',
  );

  const onAddProduct = useCallback<AddAnItemManuallyProps['onAddProduct']>(
    async (values, actions) => {
      setErrorState('');
      const result = (await cloud.fastAPI({
        api: 'add_product_manually',
        description: values.description,
        link: values.link,
        price: values.price,
        image_urls: images.filter((x) => x[1] === true).map((x) => x[0]),
        collections_to_add_to: [collectionUID],
      })) as { data: { success: boolean } };
      if (result.data.success === true) {
        // Also add it to all the collections:
        onClose();
      } else {
        setErrorState(
          'Sorry, adding a new product appears to have failed due to an internal error.  Please check your internet connection and try again or send us feedback.',
        );
      }
      analytics.recordEvent('WebApp:CalledAddAnItemManually');
      actions.setSubmitting(false);
    },
    [analytics, collectionUID, onClose, cloud, images],
  );

  const handleImageAddition = (url: string) => {
    setImageState([...images, [url, true]]);
  };

  const toggleImageSelection = useCallback(
    (index: number) => {
      setImageState(images.map((x, i) => (i === index ? [x[0], !x[1]] : x)));
    },
    [images],
  );

  const handleImageDimsUpdate = useCallback(
    (url: string, dims: { width: number; height: number }) => {
      setImageDims({ ...imageDims, [url]: dims });
    },
    [imageDims],
  );

  return (
    <AddAnItemManually
      onAddProduct={onAddProduct}
      validationSchema={validationSchema}
      errorState={errorState}
      toggleImageSelection={toggleImageSelection}
      onImageAddition={handleImageAddition}
      images={images}
      imageDims={imageDims}
      onImageDimsUpdate={handleImageDimsUpdate}
      {...otherProps}
    />
  );
};

export { AddAnItemManuallyContainer as AddAnItemManually };
