import React from 'react';
import styled from 'styled-components';
import { Paper, listItemTextClassNames } from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContentRef, MenuContentProps } from './MenuContent.types';

const UnstyledMenuContent = React.forwardRef<MenuContentRef, MenuContentProps>(
  ({ className, style, children }, ref) => {
    return (
      <Paper ref={ref} className={className} style={style}>
        {children}
      </Paper>
    );
  },
);

export const MenuContent = styled(UnstyledMenuContent)`
  overflow-x: hidden;
  padding: 16px 0;
  z-index: 10;

  .${modalMenuClassNames.isDesktop} & {
    padding: 0;

    .${listItemTextClassNames.root} > span {
      display: block;
    }
  }
`;
