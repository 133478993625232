/*
  Shown as a confirmation message after the user marks an item as bought.
  Provides entry into additional flows such as adding a message to the owner.
*/

import styled from 'styled-components';
import {
  Stack,
  Typography,
  StandardButton,
  CheckCircleIcon,
  checkCircleIconClassNames,
} from '@moonsifttech/design-system';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { ModalContent } from 'src/components/core/ModalContent';
import { GoToBuyItemCard } from 'src/components/collection/GoToBuyItemCard';

const UnstyledMarkedAsBoughtDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ setModal, closeModal ] = useModal();

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography
        className="MarkedAsBoughtDialog-checkWrapper"
        variant="primary.b26"
        component="h1"
      >
        <CheckCircleIcon size="larger" />
        <span>Marked as bought</span>
      </Typography>

      <Typography
        className="MarkedAsBoughtDialog-checkWrapper"
        variant="primary.r12"
        component="p"
      >
        <CheckCircleIcon size="small" />
        <span>Item no longer available for others to buy</span>
      </Typography>

      <StandardButton
        className="MarkedAsBoughtDialog-unmarkButton"
        variant="secondary"
        onClick={() => setModal({
          type: ModalTypes.Unmark,
          collectionId,
          collectionUserId,
          itemId,
        })}
      >
        Un-mark as bought
      </StandardButton>

      <GoToBuyItemCard
        className="MarkedAsBoughtDialog-productCard"
        collectionUID={collectionId}
        collectionUserUID={collectionUserId}
        collectionItemUID={itemId}
        showLink={true}
      />

      <Stack direction='row' justifyContent='flex-end'>
        <StandardButton
          className="MarkedAsBoughtDialog-doneButton"
          variant="primary-white"
          onClick={closeModal}
        >
          Done
        </StandardButton>
        <StandardButton
          variant="primary-black"
          onClick={() => setModal({
            type: ModalTypes.AddMessage,
            collectionId,
            collectionUserId,
            itemId,
          })}
        >
          Add message
        </StandardButton>
      </Stack>
    </ModalContent>
  );
};

export const MarkedAsBoughtDialog = styled(UnstyledMarkedAsBoughtDialog)`
  .MarkedAsBoughtDialog-checkWrapper {
    display: flex;
    align-items: center;

    > .${checkCircleIconClassNames.root} {
      min-width: 16px;
      margin-right: 0.5rem;
    }
  }

  .MarkedAsBoughtDialog-unmarkButton {
    margin-bottom: 1rem;
  }

  .MarkedAsBoughtDialog-productCard {
    margin-bottom: 2rem;
  }

  .MarkedAsBoughtDialog-doneButton {
    margin-right: 0.75rem;
  }
`;
