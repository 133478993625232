import { useItem } from 'src/hooks/useItem';
import { useModal, ModalTypes } from 'src/hooks/useModal';

interface Props {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
}

export const withExhaustedCheck = <P extends Props>(
  Component: React.ComponentType<P>,
): (props: P) => JSX.Element | null => {
  return (props: P): JSX.Element | null => {
    const {
      collectionId,
      collectionUserId,
      itemId,
    } = props;

    const { exhausted } = useItem(itemId);
    const [ setModal ] = useModal();

    if (exhausted) {
      setModal({
        type: ModalTypes.AlreadyBought,
        collectionId,
        collectionUserId,
        itemId,
      });
      return null;
    }

    return <Component {...props} />;
  };
};
