import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { ActionNames, FooterPopupAction, FooterPopupState } from "src/types";

export const useSetFooterPopup = ()=>{
  const dispatch = useDispatch();
  const setFooterPopup = useCallback(
    (payload: FooterPopupState) =>
      dispatch<FooterPopupAction>({
        type: ActionNames.FOOTER_POPUP_SET,
        payload,
      }),
    [dispatch],
  );
  return setFooterPopup;
};
