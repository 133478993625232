import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { RouterSwitchProps } from './RouterSwitch.types';
import R from 'src/routes';

import Loading from 'src/mvp22/view-components/Loading';
import Landing from 'src/mvp22/view-components/Landing';
import FourOhFour from 'src/mvp22/view-components/FourOhFour';
import Retailers from 'src/mvp22/view-components/Retailers';
import AffiliateBrands from 'src/mvp22/view-components/AffiliateBrands';
import Tastemakers from 'src/mvp22/view-components/Tastemakers';
import TastemakersApply from 'src/mvp22/view-components/TastemakersApply';
import TermsAndConditions from 'src/mvp22/view-components/TermsAndConditions';
import PrivacyPolicy from 'src/mvp22/view-components/PrivacyPolicy';
import Logout from 'src/mvp22/view-components/Logout';
import ForgotPassword from 'src/mvp22/view-components/ForgotPassword';
import AboutUs from 'src/mvp22/view-components/AboutUs';
import Explore from 'src/mvp22/view-components/Explore';
import { Profile } from 'src/views/Profile';
import EditProfile from 'src/mvp22/view-components/EditProfile';
import EditUsername from 'src/mvp22/view-components/EditUsername';
import EditEmailPreferences from 'src/mvp22/view-components/EditEmailPreferences';
import EditEmailUnsubscribeFromList from 'src/mvp22/view-components/EditEmailUnsubscribeFromList';
import ProLanding from 'src/mvp22/view-components/ProLanding';
import ProShare from 'src/mvp22/view-components/ProShare';
import AddProduct from 'src/mvp22/view-components/AddProduct';
import Notifications from 'src/mvp22/view-components/Notifications';
import LandingWishlist from 'src/mvp22/view-components/LandingWishlist';
// Refactored files:
import { Route } from 'src/components/core/Route';
import { Collection } from 'src/views/Collection';
import { CreateCollection } from 'src/views/CreateCollection';
import { PostinstallOne } from 'src/views/PostinstallOne';
import { PostinstallTwo } from 'src/views/PostinstallTwo';
import { PostinstallThree } from 'src/views/PostinstallThree';
import { ReportProblem } from 'src/views/ReportProblem';
import { HowToSave } from 'src/views/HowToSave';
import { ExtensionSignUp } from 'src/views/ExtensionSignUp';
import { SignUp } from 'src/views/SignUp';
import { SignIn } from 'src/views/SignIn';
import { NotProvisioned } from 'src/views/NotProvisioned';
import { CreatorApplicationOne } from 'src/views/CreatorApplicationOne';
import { CreatorApplicationTwo } from 'src/views/CreatorApplicationTwo';
import { CreatorApplicationThree } from 'src/views/CreatorApplicationThree';
import { CreatorApplicationFour } from 'src/views/CreatorApplicationFour';
import { CreatorApplicationFive } from 'src/views/CreatorApplicationFive';
import { CreatorApplicationSix } from 'src/views/CreatorApplicationSix';
import { CreatorApplicationFinal } from 'src/views/CreatorApplicationFinal';
import { StylistApplicationOne } from 'src/views/StylistApplicationOne';
import { StylistApplicationTwo } from 'src/views/StylistApplicationTwo';
import { StylistApplicationThree } from 'src/views/StylistApplicationThree';
import { StylistApplicationFour } from 'src/views/StylistApplicationFour';
import { CreatorDashboard } from 'src/views/CreatorDashboard';
import { CreatorFaq } from 'src/views/CreatorFaq';
import { CreatorLanding } from 'src/views/CreatorLanding';
import { WishlistAppLanding } from 'src/views/WishlistAppLanding';
import { RegistryLanding } from 'src/views/RegistryLanding';
import { ChristmasLanding } from 'src/views/ChristmasLanding';
import { AppQr } from 'src/views/AppQr';
import { AccountSettings } from 'src/views/AccountSettings';
import { PostSignUp } from 'src/views/PostSignUp';
import { AllCreators } from 'src/views/AllCreators';
import { ReferPro } from 'src/views/ReferPro';
import { Plans } from 'src/views/Plans';
import { ProTrial } from 'src/views/ProTrial';
import { ProWelcome } from 'src/views/ProWelcome';
import { Sitemap } from 'src/views/Sitemap';
import { ExtensionUpgraded } from 'src/views/ExtensionUpgraded';
import { ExtensionIssuesReport } from 'src/views/ExtensionIssuesReport';
import { IOSLanding } from 'src/views/IOSLanding';
import { BabyLanding } from 'src/views/BabyLanding';
import { BirthdayLanding } from 'src/views/BirthdayLanding';

export const RouterSwitch: React.FC<RouterSwitchProps> = ({
  authUserUID,
  authUserSet,
  displayUsername,
  showNotProvisioned,
  isApp,
  isIOS,
}) => {
  const renderLanding = () => {
    return isIOS ? <IOSLanding /> : <Landing />;
  };

  const renderProfileRedirect = () => {
    var redirectPath: string | false = false;
    if (displayUsername) {
      redirectPath = '/@' + displayUsername;
    } else if (authUserUID) {
      // TODO: Make this after loading the displayusername!
      redirectPath = '/@' + authUserUID;
    }
    return redirectPath !== false ? (
      <Redirect to={redirectPath} />
    ) : (
      renderLanding()
    );
  };

  const renderProfileRedirectLanding = () => {
    return authUserSet !== true || (isApp && authUserUID === null) ? (
      <Loading />
    ) : authUserUID !== null ? (
      renderProfileRedirect()
    ) : (
      renderLanding()
    );
  };

  /*

  The showNotProvisioned flag will show the NotProvisioned page
  instead of all pages except:
  - Join, since this is the one page where someone can be logged
  in but not marked as provisioned (this is where provisionning
  happens)
  - Logout - required to logout someone if they were not provisioned
  and want to go back.

  */
  return (
    <Switch>
      <Route path={R.SIGNUPFROMEXTENSION} component={ExtensionSignUp} />
      <Route path={R.SIGNUP} component={SignUp} />
      <Route path={R.SIGNIN} component={SignIn} />
      <Route path={R.LOGOUT} component={Logout} />
      {showNotProvisioned ? (
        <NotProvisioned />
      ) : (
        <Switch>
          <Route
            exact={true}
            path={'/'}
            render={renderProfileRedirectLanding}
          />
          <Route path={R.LANDING} component={Landing} />
          <Route path={R.CHRISTMAS_LANDING} component={ChristmasLanding} />
          <Route path={R.LIZZIEYOUTUBE} component={() => <Redirect to="/" />} />
          <Route
            path={R.LIZZIEYOUTUBEURI}
            component={() => <Redirect to="/" />}
          />
          <Route
            path={R.PRO_LANDING + '/:pro_referral_code'}
            component={ProLanding}
          />
          <Route exact={true} path={R.PRO_LANDING} component={Landing} />
          <Route path={R.PRO_SHARE} component={ProShare} />
          <Route isPrivate path={R.PRO_REFER} component={ReferPro} />
          <Route
            path={R.RETAILERS_AZ}
            render={() => <Retailers list_type="az" />}
          />
          <Route
            path={R.RETAILERS_FEATURED}
            render={() => <Retailers list_type="trending" />}
          />
          <Route
            path={R.RETAILERS_SEARCH}
            render={() => <Retailers list_type="search" />}
          />
          <Route
            path={R.RETAILERS}
            render={() => <Retailers list_type="az" />}
          />
          <Route isPrivate path={R.REPORTPROBLEM} component={ReportProblem} />
          <Route
            isPrivate
            path={R.EXTENSION_ISSUE_REPORT}
            component={ExtensionIssuesReport}
          />
          <Route path={R.EXTENSION_UPGRADED} component={ExtensionUpgraded} />
          <Route isPrivate path={R.POSTSIGNUP} component={PostSignUp} />
          <Route
            exact={true}
            path={R.TASTEMAKERLANDING}
            component={Tastemakers}
          />
          <Route path={R.TASTEMAKERSAPPLY} component={TastemakersApply} />
          <Route path={R.LOADING} component={Loading} />
          <Route path={R.TERMS} component={TermsAndConditions} />
          <Route path={R.PRIVACYPOLICY} component={PrivacyPolicy} />
          <Route path={R.HOME} render={renderProfileRedirectLanding} />
          <Route
            exact={true}
            path={R.USER_PROFILE}
            render={renderProfileRedirect}
          />
          <Route path={R.USER_PROFILE} component={Profile} />
          <Route
            path={R.LIKED}
            render={() => <Profile activeTab="bookmarked" />}
          />
          <Route
            path={R.BOOKMARKED}
            render={() => <Profile activeTab="bookmarked" />}
          />
          <Route
            path={R.PURCHASED}
            render={() => <Profile activeTab="purchased" />}
          />
          <Route
            path={`${R.COLLECTION}/:username/:collectionUID`}
            component={Collection}
          />
          <Route path={R.SITEMAP} component={Sitemap} />
          <Route path={R.FORGOTPASSWORD} component={ForgotPassword} />
          <Route path={R.EXPLORE_FEATURED} render={() => <Explore />} />
          <Route path={R.ABOUT_US} component={AboutUs} />
          <Route path={R.HOW_TO_SAVE} exact component={HowToSave} />
          <Route
            isPrivate
            path={R.HOME_OR_LOGIN}
            render={renderProfileRedirectLanding}
          />
          <Route
            isPrivate
            path={R.COLLECTIONS_CREATE}
            exact
            component={CreateCollection}
          />
          <Route
            isPrivate
            path={R.POSTINSTALL_ONE}
            component={PostinstallOne}
            exact
          />
          <Route
            isPrivate
            path={R.POSTINSTALL_TWO}
            component={PostinstallTwo}
            exact
          />
          <Route
            isPrivate
            path={R.POSTINSTALL_THREE}
            component={PostinstallThree}
            exact
          />
          <Route isPrivate path={R.ADD_PRODUCT} component={AddProduct} />
          <Route isPrivate path={R.NOTIFICATIONS} component={Notifications} />
          <Route path={'/@:user_map'} component={Profile} />
          <Route
            isPrivate
            exact={true}
            path={R.EDIT_PROFILE}
            component={EditProfile}
          />
          <Route
            isPrivate
            exact={true}
            path={R.EDIT_USERNAME}
            component={EditUsername}
          />
          <Route
            isPrivate
            exact={true}
            path={R.EDIT_EMAIL_PREFERENCES}
            component={EditEmailPreferences}
          />
          <Route
            isPrivate
            exact={true}
            path={R.EDIT_ACCOUNT_PREFERENCES}
            component={AccountSettings}
          />
          <Route
            isPrivate
            path={R.UNSUBSCRIBE_FROM_LIST}
            component={EditEmailUnsubscribeFromList}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_ONE}
            component={CreatorApplicationOne}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_TWO}
            component={CreatorApplicationTwo}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_THREE}
            component={CreatorApplicationThree}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_FOUR}
            component={CreatorApplicationFour}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_FIVE}
            component={CreatorApplicationFive}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_SIX}
            component={CreatorApplicationSix}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_APPLICATION_DONE}
            component={CreatorApplicationFinal}
          />
          <Route
            isPrivate
            exact
            path={R.STYLIST_APPLICATION_ONE}
            component={StylistApplicationOne}
          />
          <Route
            isPrivate
            exact
            path={R.STYLIST_APPLICATION_TWO}
            component={StylistApplicationTwo}
          />
          <Route
            isPrivate
            exact
            path={R.STYLIST_APPLICATION_THREE}
            component={StylistApplicationThree}
          />
          <Route
            isPrivate
            exact
            path={R.STYLIST_APPLICATION_FOUR}
            component={StylistApplicationFour}
          />
          <Route
            isPrivate
            exact
            path={R.CREATOR_DASHBOARD}
            component={CreatorDashboard}
          />
          <Route exact path={R.CREATOR_FAQ} component={CreatorFaq} />
          <Route
            exact
            path={R.AFFILIATE_BRANDS}
            component={() => <Redirect to={`${R.AFFILIATE_BRANDS}/popular`} />}
          />
          <Route
            exact
            path={`${R.AFFILIATE_BRANDS}/:group`}
            component={AffiliateBrands}
          />
          <Route exact path={R.CREATOR_LANDING} component={CreatorLanding} />
          <Route exact path={R.APP_LANDING} component={WishlistAppLanding} />
          <Route exact path={R.APP_QR_CODE} component={AppQr} />
          <Route exact path={R.ALL_CREATORS} component={AllCreators} />
          <Route exact path={R.REGISTRY_LANDING} component={RegistryLanding} />
          <Route exact path={R.BIRTHDAY_LANDING} component={BirthdayLanding} />
          <Route exact path={R.WISHLIST} component={LandingWishlist} />
          <Route
            exact
            path={R.WISHLIST_MAKER}
            component={isIOS ? WishlistAppLanding : LandingWishlist}
          />
          <Route exact path={R.BABY_REGISTRY_LANDING} component={BabyLanding} />
          <Route exact path={R.PLANS} component={Plans} />
          <Route isPrivate exact path={R.PRO_WELCOME} component={ProWelcome} />
          <Route isPrivate exact path={R.PRO_TRIAL} component={ProTrial} />
          <Route component={FourOhFour} />
        </Switch>
      )}
    </Switch>
  );
};
