/*

Several functions to get price values and display prices appropraitely

Move to utils (maybe folder prices)

*/
const price_re =
  /(£|GBP|€|EUR|\$|NZD|USD|AUD|HKD|SGD|CAD|円|¥|JPY|CNY|CHF|SFr|CHf|Fr|SEK|₹|INR)/;
const price_dict = {
  'GBP': 'GBP',
  '£': 'GBP',
  'EUR': 'EUR',
  '€': 'EUR',
  '$': 'DOL', // COULD ALSO BE CAD,NZD or HKD or SGD or AUD...!
  'USD': 'USD',
  'AUD': 'AUD',
  'NZD': 'NZD',
  'HKD': 'HKD',
  'SGD': 'SGD',
  'CAD': 'CAD',
  '円': 'CNY',
  'CNY': 'CNY',
  '¥': 'JPY',
  'JPY': 'JPY',
  'CHF': 'CHF',
  'SFr': 'CHF',
  'CHf': 'CHF',
  'SEK': 'SEK',
  '₹': 'INR',
  'INR': 'INR',
};

const currency_display = {
  GBP: '£',
  EUR: '€',
  USD: '$US',
  DOL: '$',
  AUD: 'AUD',
  NZD: 'NZ$',
  SGD: 'SGD',
  HKD: 'HK$',
  CAD: 'C$',
  CNY: 'CNY',
  JPY: '¥',
  CHF: 'CHF',
  INR: '₹',
  SEK: 'SEK',
};

export function currency_parser(price_in) {
  const this_match = price_in.match(price_re);
  var this_currency;
  if (this_match) {
    this_currency = price_dict[this_match[0]];
  }
  if (this_currency) {
    return this_currency;
  }
  return 'DEFAULT';
}
function remove_price_spaces(price_in_rep) {
  // remove single spaces:
  const regex_ss = /[^ ] [^ ]/g;
  // MatchAll equivalent for node 10:
  const match_result_ss = price_in_rep.match(regex_ss);
  const matches_ss = match_result_ss ? match_result_ss : [];
  var price_in_rep_out = price_in_rep;
  matches_ss.forEach(
    (m) =>
      (price_in_rep_out = price_in_rep_out.replace(m, m.replace(/ /g, ''))),
  );
  return price_in_rep_out;
}

export function price_parser(price_in) {
  if (typeof price_in !== 'number') {
    // run twice to get all...
    var price_p_rep = price_in;
    for (let i = 0; i < 2; i++) {
      price_p_rep = remove_price_spaces(price_p_rep);
    }
    const regexp = /[0-9,.]+/g;
    // MatchAll equivalent for node 10:
    const matches_result = price_p_rep.match(regexp);
    const matches = matches_result ? matches_result : [];
    if (matches.length === 1) {
      // Deal with commas as decimal points:
      const regexp_commadec = /[0-9]+,[0-9]{0,2}(?![0-9])/g;
      // MatchAll equivalent for node 10:
      const matches_commadec_result = matches[0].match(regexp_commadec);
      const matches_commadec = matches_commadec_result
        ? matches_commadec_result
        : [];
      if (matches_commadec.length === 1) {
        return parseFloat(
          matches[0]
            .replace(
              /[0-9]+,[0-9]{0,2}(?![0-9])/,
              matches_commadec[0].replace(',', '.'),
            )
            .replace(',', ''),
          10,
        );
      } else {
        return parseFloat(matches[0].replace(/,/g, ''), 10);
      }
    } else {
      return false;
    }
  } else {
    return price_in;
  }
}

// scrape dict is original save scrape,
// product dict is processed product dict.
export function get_best_price_rep(old_price_input, product_dict) {
  const currency_code = currency_parser(old_price_input);
  //TODO: Consider ignoring DOLLAR too:
  if (
    currency_code !== 'DEFAULT' &&
    product_dict.best_price[currency_code] &&
    currency_display[currency_code]
  ) {
    var updated_price;
    const old_price_value = price_parser(old_price_input);
    const new_price_value = price_parser(
      product_dict.best_price[currency_code],
    );
    var price_change;
    // TODO: check if this is ok - this used to only do price changes for GBP but now should work for all currencies:
    if (old_price_value !== false && new_price_value !== false) {
      const price_diff = new_price_value - old_price_value;
      const percentage_price_diff = Math.round(
        (price_diff * 100.0) / old_price_value,
      );
      var price_sign;
      if (percentage_price_diff < 0) {
        price_sign = '-';
      } else if (percentage_price_diff > 0) {
        price_sign = '+';
      }
      if (price_sign) {
        price_change = {
          sign: price_sign,
          difference: price_diff,
          percentage_difference: percentage_price_diff,
          currency_code: currency_code,
          new_price_value,
          old_price_input,
        };
      }
      //nothing if no change!.
    }
    if (new_price_value !== false) {
      updated_price = displayPrice(currency_code, new_price_value);
    } else {
      updated_price = product_dict.best_price[currency_code];
    }
    return {
      updated_price,
      price_change,
    };
  } else {
    return {
      updated_price: old_price_input,
    };
  }
}

export function displayPrice(currency_code, price) {
  switch (currency_code) {
    default:
      return `${currency_display[currency_code]}${
        Math.abs(price - Math.floor(price) < 0.00001)
          ? price.toFixed(0)
          : price.toFixed(2)
      }`;
  }
}
