/*

REDUX Query

Note can take a start_after doc for loading more reactions+comments!

Can be a separate redux list as will be separate to the other lists and appended.

*/
export default function listen_for_new_reactions_and_comments(
  product_uid,
  collection_uid,
  collection_user_uid,
  doc,
  firebase,
  state_assign_func
){
  const start_after = (doc!==false)?doc:null;
  return firebase.user_product_collection_reactions_and_comments_list_listen(collection_user_uid, collection_uid, product_uid,start_after)
  .onSnapshot(
    snapshot=>{
      const comments_list = [];
      snapshot.forEach(
        doc=>{
          const data = doc.data();
          // Not entry uid as this was used already...
          data.uid = doc.id;
          comments_list.push(data);
        }
      );
      comments_list.sort(
        (a,b)=>(a.created_at>b.created_at)?-1:1
      );
      state_assign_func({
        comments_list,
      });
    }
  );
}
