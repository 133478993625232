import { useContext, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';

type Input = {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
}

type Output = {
  url: string;
  goToItem: () => void;
}

export function useGoToItem({
  collectionId,
  collectionUserId,
  itemId,
}: Input): Output {
  const { cloud } = useContext(ServicesContext);
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionId,
    collectionUserId,
    itemId,
  );

  // Opens collection item's retailer page in a new tab and increments its click.
  const url = useSelector<RootState, string>(
    ({ productData }) => {
      const rawURL = productData?.[productDataStoreAs].url;
      if (!rawURL) {
        return '';
      }
      try {
        const url = new URL(rawURL);
        if (url.hostname.endsWith('amazon.co.uk')) {
          if (url.searchParams.has('tag')) {
            return rawURL;
          } else {
            url.searchParams.set('tag', 'moonsift03-21');
            return url.href;
          }
        }
      } catch (err) {
        console.log(err);
      }
      try {
        const hostname = new URL(rawURL).hostname;
        if (
          hostname.includes('.amazon.') ||
          hostname.endsWith('a.co') ||
          hostname.startsWith('amazon.')
        ) {
          return rawURL;
        }
      } catch (err) {
        console.log(err);
      }
      if (
        productData?.[productDataStoreAs].commissionType === 'sovrn' &&
        rawURL
      ) {
        return `http://redirect.viglink.com?u=${encodeURIComponent(
          rawURL,
        )}&key=${
          process.env.REACT_APP_SOVRN_API_KEY
        }&utm_content=${collectionUserId}_${collectionId}_${itemId}`;
      }
      return rawURL;
    },
  );

  const goToItem = useCallback<Output['goToItem']>(async () => {
    if (url) {
      try {
        await cloud.call('collections-incrementClicks')({
          user_uid: collectionUserId,
          collection_uid: collectionId,
          url,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [cloud, collectionId, collectionUserId, url]);

  return {
    url,
    goToItem,
  };
}
