import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Stack, Typography } from '@moonsifttech/design-system';
import { CreatorLandingExampleCollectionsProps } from './CreatorLandingExampleCollections';
import { CollectionCarousel } from 'src/components/common/CollectionCarousel';

const UnstyledCreatorLandingExampleCollections: VFC<
  CreatorLandingExampleCollectionsProps
> = ({ className, style }) => {
  return (
    <section className={clsx(className)} style={style}>
      <Stack
        className="CreatorLandingExampleCollections-container"
        alignItems="flex-start"
      >
        <Typography
          className="CreatorLandingExampleCollections-heading"
          variant="editorial.r26"
          component="h2"
          align="left"
        >
          Featured collections
        </Typography>
        <Typography
          className="CreatorLandingExampleCollections-subheading"
          variant="primary.l20"
          component="p"
          align="left"
        >
          Explore collections by Moonsift Creators
        </Typography>
        <div className="CreatorLandingExampleCollections-innerContainer">
          <CollectionCarousel listName={'explore_featured'} />
        </div>
      </Stack>
    </section>
  );
};

export const CreatorLandingExampleCollections = styled(
  UnstyledCreatorLandingExampleCollections,
)`
  padding: 72px 20px 76px 0px;
  overflow: hidden;

  .CreatorLandingExampleCollections-container {
    margin: 0 auto;
    width: min(100vw - 20px, 880px);
  }

  h2 {
    margin-bottom: 24px;
  }

  .CreatorLandingExampleCollections-heading {
    margin-bottom: 12px;
  }

  .CreatorLandingExampleCollections-subheading {
    margin-bottom: 44px;
  }

  .CreatorLandingExampleCollections-innerContainer {
    display: block;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 96px 0px 116px;

    h2 {
      margin-bottom: 32px;
    }

    .CreatorLandingExampleCollections-subheading {
      margin-bottom: 24px;
    }

    .CreatorLandingExampleCollections-innerContainer {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
`;
