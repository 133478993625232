import { useContext, useState, useCallback } from 'react';

import { ServicesContext } from 'src/ServicesContext';

export interface UseUnmarkAsBoughtInput {
  collectionUserUID: string;
  collectionUID: string;
  collectionItemUID: string;
  onSuccess?: () => void;
}

export interface UseUnmarkAsBoughtOutput {
  working: boolean;
  errorMessage: string;
  unmark: (privateIds?: string[]) => void;
}

export function useUnmarkAsBought({
  collectionUserUID,
  collectionUID,
  collectionItemUID,
  onSuccess,
}: UseUnmarkAsBoughtInput): UseUnmarkAsBoughtOutput {
  const { cloud, analytics } = useContext(ServicesContext);

  // Marks a collection item as bought by the logged-in user.
  const [working, setWorking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const unmark = useCallback<
    UseUnmarkAsBoughtOutput['unmark']
  >(async (privateIds) => {
    try {
      setWorking(true);
      await cloud.fastAPI2('unmarkCollectionItemAsBought', {
        collection_user_uid: collectionUserUID,
        collection_uid: collectionUID,
        collection_item_uid: collectionItemUID,
        identity: {
          identifier: analytics.clientIdentifier,
        },
        ...(privateIds && { private_ids: privateIds }),
      });
      setErrorMessage('');
      onSuccess?.();
    } catch (error) {
      const typedError = error as Error;
      setErrorMessage(typedError.message);
    } finally {
      setWorking(false);
    }
  }, [
    cloud,
    collectionUserUID,
    collectionUID,
    collectionItemUID,
    analytics.clientIdentifier,
    onSuccess,
  ]);

  return {
    working,
    errorMessage,
    unmark,
  };
}
