import { FirebaseApp } from 'firebase/app';
import {
  getFunctions,
  Functions,
  connectFunctionsEmulator,
  httpsCallable,
} from 'firebase/functions';

/*

Misc (but important) cloud services, namely functions and uploading for storage.

*/
export class Cloud {
  functions: Functions;

  constructor(app: FirebaseApp) {
    this.functions = getFunctions(app, 'europe-west2');
    // Development Settings:
    if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS === 'TRUE') {
      connectFunctionsEmulator(this.functions, 'localhost', 5001);
    }
  }

  call = (name: string) => httpsCallable(this.functions, name);
  fastAPI = (data: any) => httpsCallable(this.functions, 'fast_api-call')(data);
  fastAPI2 = (endpoint: string, data: any) =>
    httpsCallable(this.functions, `fast_apiv2-call/${endpoint}`)(data);
}
