import React from 'react';

import { VideoGenerationPanelContainerProps } from './VideoGenerationPanel.types';
import { ServicesContext } from 'src/ServicesContext';
import { useSelector } from 'react-redux';
import { VideoGenerationPanel } from './VideoGenerationPanel.component';
import { RootState } from 'src';

const VideoGenerationPanelContainer: React.FC<
  VideoGenerationPanelContainerProps
> = (props) => {
  const { analytics, cloud } = React.useContext(ServicesContext);
  const [videoHasBeenGenerated, setVideoHasBeenGenerated] =
    React.useState(false);
  const [mp4Name, setMP4Name] = React.useState('');
  const [videoHasFailed, setVideoHasFailed] = React.useState(false);
  const [fetchedVideo, setFetchedVideo] = React.useState(false);
  const collectionUserUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.user_uid ?? '',
  );
  const collectionUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.id ?? '',
  );
  const collectionName = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.name ?? '',
  );
  React.useEffect(() => {
    setFetchedVideo(true);
    if (fetchedVideo === false) {
      cloud
        .call('generate_collection_video-call')({
          collection_uid: collectionUID,
          collection_user_uid: collectionUserUID,
        })
        .then((response) => {
          const responseTyped = response as {
            data: { success: boolean; download_filename?: string };
          };
          if (responseTyped.data.success === true) {
            setVideoHasBeenGenerated(true);
            setMP4Name(responseTyped.data.download_filename ?? '');
          } else {
            setVideoHasFailed(true);
          }
        })
        .catch(() => {
          setVideoHasFailed(true);
        });
      analytics.recordEvent('WebApp:GenerateVideoButton:Pressed');
    }
  }, [analytics, cloud, collectionUID, collectionUserUID, fetchedVideo]);

  return (
    <VideoGenerationPanel
      videoHasBeenGenerated={videoHasBeenGenerated}
      mp4Name={mp4Name}
      videoHasFailed={videoHasFailed}
      collectionName={collectionName}
      {...props}
    />
  );
};

export { VideoGenerationPanelContainer as VideoGenerationPanel };
