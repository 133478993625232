import React from 'react';
import { Avatar, Stack, Typography } from '@moonsifttech/design-system';

import { Link } from 'src/components/core/Link';
import { UserProps } from './User.types';

export const User: React.FC<UserProps> = ({
  className,
  style,
  name,
  username,
  imageSource,
  isPro,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      direction="row"
      alignItems="center"
      spacing={6}
    >
      <Avatar alt={name} src={imageSource} isPro={isPro}>
        {name[0]}
      </Avatar>
      <Typography variant="primary.l14">
        By <Link to={`/@${username}`}>{name}</Link>
      </Typography>
    </Stack>
  );
};
