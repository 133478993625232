import { VFC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';
import { InviteCreators } from './InviteCreators.component';
import { InviteCreatorsContainerProps } from './InviteCreators.types';

const InviteCreatorsContainer: VFC<InviteCreatorsContainerProps> = (props) => {
  // Content Creator application handler
  const history = useHistory();
  const handleButtonClick = useCallback(() => {
    history.push(R.PRO_REFER);
  }, [history]);

  return <InviteCreators onButtonClick={handleButtonClick} />;
};

export { InviteCreatorsContainer as InviteCreators };
