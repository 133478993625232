/*

REDUX queryByID

THIS IS PROBABLY SOME OF THE MOST COMPLEX BIT OF CODE LEFT PRE-REFACTOR... PLEASE ASK THE TEAM ABOUT IT BEFORE TOUCHING IT!

This stores product membership data when a pageURL is supplied and a database query returns the result
of a query by that pageURL across the user's collections.
(pageURLs are the unique product references that let us tell if a product is in another collection or not.)

It processes the query by storing a list of all the collections (received from firestore_user_collection_list) and if a product is a member of it or not.
This means the list here has to also be updated when the list of collections changes, so firestore_user_collection_list calls product_membership_collection_list_redux_set.

TODO: Separate the collection list and just store the query results in REDUX, we can process and look them up from the membership window
We can use the DBmethods for the pageURL query, call them in the Modal component or ProductTileList.
We will use DBmethods to get the user's collection list when they log in using the userData hook.

*/
/*

We want to access the product collection data very frequently by looking up page_urls.
But we are going to store user collections in the database as follows:
/user_uid/collection/collection_uid:
products:{product_uid}

We need collections of product_urls.
Most flexible is to store something unique for the extension (which means changing it back AGAIN!)
/user_uid/site_uid/:

Load in all the collections and store in a page_url > collection dict.
Update this as and when a collection is modified.... (which should not be toooo often, at least not as often as loading a new product would be!)

REDUX db

*/
import {FIRESTORE_REDUX_REMOVE_ALL} from 'src/mvp22/redux-components/actions';
const FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP = "FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP";
const FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP_LISTENER = "FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP_LISTENER";
export const FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP = "FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP";
// Future-considerations: other things might go here for tracking....
const DEFAULT_FIRESTORE = {
  query_listener:undefined,
  collection_list_snapshot:[]
};

export default function firestoreState(state = DEFAULT_FIRESTORE, action) {
  switch (action.type) {
    // Update the scratch with the firebase collection:
    case FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP:
      return Object.assign(
        {},
        state,
        action.data
      );
    case FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP_LISTENER:
      if (state.query_listener!==undefined){
        state.query_listener();
      }
      return Object.assign(
        {},
        state,
        action.data
      );
    case FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP:
    case FIRESTORE_REDUX_REMOVE_ALL:
      if (state.query_listener!==undefined){
        state.query_listener();
      }
      return DEFAULT_FIRESTORE;
    default:
      return state;
  }
}

// snapshot refreshes twice so sort happens prematurely, need to wait for a get:
function set_results(dispatch,snapshot,get_not_snapshot){
  const membership_info = {}; // does not seem to be used anymore...?
  const raw_membership_info = []; // raw collection info for saving a query later when adding a product to another collection (see Modal.tsx)
  snapshot.forEach(
    doc=>{
      membership_info[doc.ref.path.split("/")[3]] = true;
      const to_append = doc.data();
      to_append.uid = doc.id;
      raw_membership_info.push(to_append);
    }
  );
  // THIS DISPATCH IS SYNCHRONOUS!
  dispatch(
    {
      type:FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP,
      data:{
        membership_info,
        raw_membership_info
      }
    }
  );
  // This assumes that the component is still active!.... Considering moving to component or checking that it is still active here:
  dispatch(
    product_membership_collection_list_redux_set(
      null,
      get_not_snapshot
    )
  );

}

// Action creator is here now:!
export function product_membership_redux_set(page_url,firebase,auth_user_uid,callback){
  return (dispatch) => {
    return new Promise(
      (resolve)=>{
        // remove all regardless:
        dispatch(
          {
            type:FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP,
          }
        );
        //const current_state = getState().firestore_product_membership;
        // Remove an old listener prior to getting all the new one:
        if (auth_user_uid!==undefined){
          // Get the new listeners if logged in:
          // TODO: Make this a promise array to get all page_url results:
          const this_membership_query = firebase.user_product_page_url(
            auth_user_uid,page_url
          );
          // get ALL the values:
          this_membership_query.get().then(
            (snapshot)=>{
              const anticipated_size = snapshot.size;
              // Set the initial order cos that needs sorting:
              set_results(dispatch,snapshot,true);
              // TYPICALLY THIS CollectionGroup SNAPSHOT LOADS SPORADICALLY which looks weird, so we get all first (above)
              // This might be due to caching...
              // Update as required:
              const query_listener = this_membership_query.onSnapshot(
                (snapshot_sub)=>{
                  set_results(dispatch,snapshot_sub,false);
                  if (anticipated_size === snapshot_sub.size){
                    callback();
                    resolve();
                  }
                }
              );
              dispatch(
                {
                  type:FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP_LISTENER,
                  data:{
                    query_listener
                  }
                }
              );
            }
          );
        }
      }
    );
  };
}

/*

BELOW FUNCTIONS UPDATE THE DATA ON COLLECTION_LIST CHANGES

*/

function keep_and_merge_list_order(new_list,old_list,lookup_dict){
  // If not first display:
  // maintain order!!
  // first note what is new and when it has been added so we can make sure we catch all:
  const new_items = {};
  const used_items = {};
  for (let this_collection of new_list){
    new_items[this_collection.uid] = this_collection; // new items dict to look up from old order!
    used_items[this_collection.uid] = false; // has been added to new list yet?
  }
  var new_display_list = [];
  for (let this_disp_collection of old_list){
    const lookup_new = new_items[this_disp_collection[0].uid];
    // ensure they are still present:
    if (lookup_new!==undefined){
      const membership_bool = (lookup_dict[lookup_new.uid]===true)?true:false;
      new_display_list.push([lookup_new,membership_bool]);
      used_items[lookup_new.uid] = true;
    }
  }
  // add any new ones at the top:
  const top_list = [];
  for (let this_collection of new_list){
    if (used_items[this_collection.uid]===false){
      const lookup_new = new_items[this_collection.uid];
      const membership_bool = (lookup_dict[this_collection.uid]===true)?true:false;
      top_list.push([lookup_new,membership_bool]);
    }
  }
  new_display_list = [...top_list, ...new_display_list];
  return new_display_list;
}


// This function updates a **preserved-order** collection list from the current component state.
export function product_membership_collection_list_redux_set(collection_list_in,do_sort){
  return (dispatch, getState) => {
    const this_state = getState();
    const this_pm_state = this_state.firestore_product_membership;
    const collection_list = (collection_list_in)?collection_list_in:this_state.firestore_user_collection_list.collections_sorted;
    if (this_pm_state.membership_info!==undefined){
      var new_collection_list_snapshot = keep_and_merge_list_order(
        collection_list,
        this_pm_state.collection_list_snapshot,
        this_pm_state.membership_info
      );
      if (do_sort){
        // if this is a new assignment then sort it by added and non-added:
        const collection_display_list_top = [];
        const collection_display_list_bottom = [];
        // If already in the collection list, then let:
        for (let this_collection of new_collection_list_snapshot){
          if (this_collection[1]===true){
            collection_display_list_top.push(this_collection);
          }
          else{
            collection_display_list_bottom.push(this_collection);
          }
        }
        new_collection_list_snapshot = [...collection_display_list_top,...collection_display_list_bottom];
      }
      dispatch(
        {
          type:FIRESTORE_REDUX_ADD_PRODUCT_MEMBERSHIP,
          data:{
            collection_list_snapshot:new_collection_list_snapshot
          }
        }
      );
    }
  };
}
