/*

This is the prompt to get signups if a non-registered user views a non-public shareable collection.

TODO: Typescript, design system

*/
// AKA The "Jeremy" Popup
import { useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Typography, Stack, CtaButton } from '@moonsifttech/design-system';
import { M } from 'src/mvp22/constants';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import R from 'src/routes';
import { authRedirectSearchParam } from 'src/config';
import { Link } from 'src/components/core/Link';
import { ServicesContext } from 'src/ServicesContext';

export const GeneralOverlayWithinContainer = styled.div`
  position: relative;
  width: ${(props) => (props.isDesktop ? '90%' : '100%')};
  height: ${(props) => (props.isDesktop ? 'auto' : '100%')};
  max-width: ${(props) => (props.isDesktop ? '716px' : '100%')};
  max-height: ${(props) => (props.isDesktop ? '576px' : '100%')};
  box-sizing: border-box;
  background-color: ${M.COL.BG.WHITE};
  border-radius: ${(props) => (props.isDesktop ? '10px' : '')};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  color: ${M.COL.TEXT.BLACK};
  overflow: auto;
`;

function makeHistoryPushWithRedirect({ history, location, pathname }) {
  const { pathname: currentPathname, search, hash } = location;
  const redirectSearchParams = new URLSearchParams();
  redirectSearchParams.set(
    authRedirectSearchParam,
    `${currentPathname}${search}${hash}`,
  );

  history.push({
    pathname,
    search: redirectSearchParams.toString(),
  });
}

const UnstyledSignUpToViewModal = ({ className, closeFunction }) => {
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const singleCollectionDocData = useSelector(
    (state) => state.db.single_collection.data,
  );
  useEffect(() => {
    if (!singleCollectionDocData) {
      closeFunction();
    }
  }, [singleCollectionDocData, closeFunction]);
  const collectionUserUID = singleCollectionDocData
    ? singleCollectionDocData.user_uid
    : 'A moonsift user';
  const singleCollectionUserPublicDocData = useSelector(
    (state) => state.db.user_public.map[collectionUserUID],
  );
  const collectionName = singleCollectionDocData
    ? singleCollectionDocData.name
    : '';
  const userName =
    singleCollectionUserPublicDocData && singleCollectionUserPublicDocData.name
      ? singleCollectionUserPublicDocData.name
      : 'A moonsift user';
  const ISDESKTOP = windowWidth >= M.MOBILESWITCH;

  // Send user to the sign in or sign up page.
  const history = useHistory();
  // Do not use history.location as this is outdated.
  const { analytics } = useContext(ServicesContext);
  const location = useLocation();
  const handleSignIn = useCallback(() => {
    analytics.recordEvent(
      'WebApp:PrivateShareableSignUpPromptSignInButton:Pressed',
    );
    makeHistoryPushWithRedirect({ history, location, pathname: R.SIGNIN });
  }, [history, location, analytics]);
  const handleSignUp = useCallback(() => {
    analytics.recordEvent(
      'WebApp:PrivateShareableSignUpPromptSignUpButton:Pressed',
    );
    makeHistoryPushWithRedirect({ history, location, pathname: R.SIGNUP });
  }, [history, location, analytics]);

  return (
    <GeneralOverlayWithinContainer
      isDesktop={ISDESKTOP}
      className={clsx('GeneralOverlayWithinContainer', className)}
    >
      <div className="SignUpToViewModal-innerContainer">
        <LogoAndText className="SignUpToViewModal-logo" heighttype="D" />
        <Typography
          className="SignUpToViewModal-heading"
          variant="primary.b26"
          component="h1"
        >
          {userName} has invited you to view their Private Collection.
        </Typography>
        <Typography
          className="SignUpToViewModal-paragraph"
          variant="primary.l20"
          component="p"
        >
          Log in or sign up to view this private collection -{' '}
          <b>{collectionName}</b> by {userName}.
        </Typography>
        <Stack
          className="SignUpToViewModal-actionButtons"
          direction="row"
          spacing={12}
        >
          <CtaButton variant="primary-white" onClick={handleSignIn}>
            Login
          </CtaButton>
          <CtaButton variant="primary-black" onClick={handleSignUp}>
            Sign up
          </CtaButton>
        </Stack>
        <Typography variant="primary.r14">
          <Link to={R.LANDING}>Why's everyone using Moonsift?</Link>
        </Typography>
      </div>
    </GeneralOverlayWithinContainer>
  );
};

export const SignUpToViewModal = styled(UnstyledSignUpToViewModal)`
  .SignUpToViewModal-innerContainer {
    padding: 40px 20px;
  }

  .SignUpToViewModal-logo {
    justify-content: flex-start;
    margin-bottom: 32px;
  }

  .SignUpToViewModal-heading {
    margin-bottom: 24px;
  }

  .SignUpToViewModal-paragraph {
    margin-bottom: 24px;
  }

  .SignUpToViewModal-actionButtons button {
    min-width: 145px;
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .SignUpToViewModal-innerContainer {
      padding: 56px 40px;
    }

    .SignUpToViewModal-logo {
      margin-bottom: 36px;
    }

    .SignUpToViewModal-heading {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.b34')}
      margin-bottom: 28px;
    }

    .SignUpToViewModal-paragraph {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.l26')}
      margin-bottom: 36px;
    }
  }
`;
