import {
  FC,
  ChangeEventHandler,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import R, { EXTERNAL } from 'src/routes';
import { ServicesContext } from 'src/ServicesContext';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { Link } from 'src/components/core/Link';
import { GetStartedDialog } from './GetStartedDialog.component';
import {
  GetStartedDialogProps,
  GetStartedDialogContainerProps,
  GetDesktopInitialChecklist,
  GetStartedDesktopFlags,
} from './GetStartedDialog.types';

const getInitialChecklist: GetDesktopInitialChecklist = (
  { installedExtension, pinnedExtension, hasSavedToCollections, installedApp },
  onChecklistChange,
) => {
  return [
    {
      name: 'installedExtension',
      title: 'Add the Save Button to your browser',
      description: (
        <>
          <Link to={EXTERNAL.CHROMESTORE}>Click here</Link> to add the universal
          save button
        </>
      ),
      checked: installedExtension,
      onChange: onChecklistChange('installed_extension'),
    },
    {
      name: 'pinnedExtension',
      title: 'Pin Moonsift to your browser',
      description: (
        <>
          So you can save from any store.{' '}
          <Link to={EXTERNAL.HOW_TO_PIN_MOONSIFT}>Click here</Link> to see how.
        </>
      ),
      checked: pinnedExtension,
      onChange: onChecklistChange('pinned_extension'),
    },
    {
      name: 'hasSavedToCollections',
      title: 'Save products to your collection',
      description: (
        <>
          Visit our <Link to={R.RETAILERS_FEATURED}>most popular stores</Link>{' '}
          for ideas
        </>
      ),
      checked: hasSavedToCollections,
      onChange: onChecklistChange('has_saved_to_collections'),
    },
    {
      name: 'installedApp',
      title: 'Get the iOS app',
      description: (
        <>
          <Link to={R.APP_QR_CODE}>Click here</Link> to save to Moonsift on your
          phone
        </>
      ),
      checked: installedApp,
      onChange: onChecklistChange('installed_app'),
    },
  ];
};

const DesktopGetStartedDialogContainer: FC<GetStartedDialogContainerProps> = ({
  onAllSet,
  ...otherProps
}) => {
  // Services
  const { db } = useContext(ServicesContext);

  // Flags
  const installedExtension = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.installed_extension ? true : false,
  );
  const pinnedExtension = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.pinned_extension ? true : false,
  );
  const hasSavedToCollections = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.has_saved_to_collections ? true : false,
  );
  const installedApp = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.snapshot.installed_app ? true : false,
  );

  // DB updater
  const authUserUID = useSelector<RootState, string>((state) => state.auth.id!);
  const [updateUserOwnerDocument] = useDBUpdateDoc<
    Partial<GetStartedDesktopFlags>
  >(db, db.userOwnerDoc(authUserUID));

  // Toggle dismissal dialog
  const [isDismissing, setIsDismissing] = useState(false);
  const handleDismissalToggle = useCallback(() => {
    setIsDismissing((prevIsDismissing) => !prevIsDismissing);
  }, []);

  // Opens the checklist modal
  const [isChecklistOpen, setIsChecklistOpen] = useState(false);
  const handleChecklistToggle = useCallback(() => {
    setIsChecklistOpen((prevIsChecklistOpen) => !prevIsChecklistOpen);
  }, []);

  // Handle checklist changes
  const handleChecklistChange = useCallback(
    (name: string): ChangeEventHandler<HTMLInputElement> =>
      (event) => {
        updateUserOwnerDocument({ [name]: event.currentTarget.checked });
      },
    [updateUserOwnerDocument],
  );

  // Set flag in DB indicating the user has dimissed this dialog
  const handleAllSet = useCallback(
    (event) => {
      updateUserOwnerDocument({ desktop_get_started_all_set: true });
      onAllSet?.(event);
    },
    [onAllSet, updateUserOwnerDocument],
  );

  // Initialise checklist with the flags from DB
  const checklist: GetStartedDialogProps['checklist'] = getInitialChecklist(
    {
      installedExtension,
      pinnedExtension,
      hasSavedToCollections,
      installedApp,
    },
    handleChecklistChange,
  );

  // Count how many checkboxes are there and how many are checked
  const total = checklist.length;
  const completed = checklist.filter(({ checked }) => checked).length;

  return (
    <GetStartedDialog
      total={total}
      completed={completed}
      isDismissing={isDismissing}
      isChecklistOpen={isChecklistOpen}
      checklist={checklist}
      onChecklistToggle={handleChecklistToggle}
      onDismissalToggle={handleDismissalToggle}
      onAllSet={handleAllSet}
      {...otherProps}
    />
  );
};

export { DesktopGetStartedDialogContainer as DesktopGetStartedDialog };
