import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Stack,
  StarIcon,
  EditImageListItem,
  editImageListItemClassNames,
  NoPhotographyOutlinedIcon,
  StandardButton,
  ControlledTextField,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';

import { generateProductImageSource } from 'src/utils/generateProductImageSource';
import { ModalContent } from 'src/components/core/ModalContent';
import { EditProductDetailsProps } from './EditProductDetailsDialog.types';

const UnstyledEditProductDetails: VFC<EditProductDetailsProps> = ({
  className,
  style,
  starredImage,
  imageList,
  deletedImages,
  hasImageError,
  isPristine,
  onStar,
  onDelete,
  onError,
  onSave,
  onClose,
  initialValues,
  validationSchema,
}) => {
  return (
    <ModalContent className={className} style={style} onClose={onClose}>
      <div className="EditProductDetails-innerContainer">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSave}
          className="FeedbackDialog-form"
        >
          {({ dirty, isSubmitting }) => (
            <Form>
              <Typography variant="primary.b26" component="h1">
                Edit product details
              </Typography>
              <Stack
                className="EditProductDetails-explanation"
                direction="row"
                alignItems="center"
                spacing={6}
              >
                <StarIcon size="small" color="common.black" />
                <Typography variant="primary.r14" component="p">
                  Select which image to display in your collection
                </Typography>
              </Stack>
              <Stack
                className="EditProductDetails-pictures"
                direction="row"
                alignItems="center"
                wrap="wrap"
              >
                {imageList.map((image) => {
                  const isStarred = Boolean(
                    starredImage && image.includes(starredImage),
                  );
                  const isDeleted = deletedImages.includes(image);

                  return (
                    <EditImageListItem
                      key={image}
                      data-image={image}
                      starred={isStarred}
                      deleted={isDeleted}
                      src={generateProductImageSource(image)}
                      onStar={onStar}
                      onDelete={!isStarred ? onDelete : undefined}
                      innerProps={{ image: { onError } }}
                    />
                  );
                })}
              </Stack>
              {hasImageError && (
                <Stack
                  className="EditProductDetails-errorMessage"
                  direction="row"
                  alignItems="center"
                  spacing={6}
                >
                  <NoPhotographyOutlinedIcon size="small" />
                  <Typography variant="primary.r12" component="p">
                    The brand is no longer providing these images
                  </Typography>
                </Stack>
              )}
              <Stack
                className="EditProductDetails-descirption-price-link"
                direction="column"
                alignItems="center"
                spacing={12}
              >
                <ControlledTextField
                  className="EditProductDetails-input"
                  id="description"
                  name="description"
                  placeholder="Description"
                  fullWidth={true}
                />
                <ControlledTextField
                  className="EditProductDetails-input"
                  id="price"
                  name="price"
                  placeholder="Price"
                  fullWidth={true}
                />
                <ControlledTextField
                  className="EditProductDetails-input"
                  id="url"
                  name="url"
                  placeholder="Link (including https://)"
                  fullWidth={true}
                />
              </Stack>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={12}
              >
                <StandardButton variant="primary-white" onClick={onClose}>
                  Cancel
                </StandardButton>
                <StandardButton
                  variant="primary-black"
                  disabled={isPristine && !dirty}
                  type="submit"
                  loading={isSubmitting}
                >
                  Save changes
                </StandardButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </ModalContent>
  );
};

export const EditProductDetails = styled(UnstyledEditProductDetails)`
  padding: 20px;

  .EditProductDetails-descirption-price-link {
    margin-bottom: 24px;
  }

  .EditProductDetails-innerContainer {
    max-width: 320px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 4px;
  }

  .EditProductDetails-explanation {
    margin-bottom: 16px;

    p {
      margin-bottom: 0;
    }
  }

  .EditProductDetails-pictures {
    max-height: 256px;
    overflow-y: auto;
    margin-bottom: 24px;

    .${editImageListItemClassNames.root} {
      margin: 0 8px 8px 0;

      &:nth-child(3n + 0) {
        margin: 0 0 8px 0;
      }
    }
  }

  .EditProductDetails-errorMessage {
    margin: -16px 0 24px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    width: 65vw;
    height: 90vh;
    padding: 42px;

    ${({ theme }) => theme.fns.getShapeStyles('soft')}
  }
`;
