import Cookies from 'universal-cookie';
import { push } from 'connected-react-router';

import { M } from 'src/mvp22/constants';
import R from 'src/routes';
import { AppThunk, ActionNames } from 'src/types/actions';
import {
  StylistApplicationState,
  StylistApplicationRequest,
  UpdateStylistApplicationRequestAction,
  UpdateStylistApplicationErrorMessageAction,
  ResetStylistApplicationRequestAction,
} from 'src/types/models/stylistApplication.model';

const cookies = new Cookies();

export function updateStylistApplicationRequest(
  payload: Partial<StylistApplicationState>,
): UpdateStylistApplicationRequestAction {
  return {
    type: ActionNames.UPDATE_STYLIST_APPLICATION_REQUEST,
    payload,
  };
}

export function updateStylistApplicationErrorMessage(payload: {
  errorMessage: string;
}): UpdateStylistApplicationErrorMessageAction {
  return {
    type: ActionNames.UPDATE_STYLIST_APPLICATION_ERROR_MESSAGE,
    payload,
  };
}

export function resetStylistApplicationRequest(): ResetStylistApplicationRequestAction {
  return {
    type: ActionNames.RESET_STYLIST_APPLICATION_REQUEST,
    payload: undefined,
  };
}

export const apply = (): AppThunk => {
  return async (dispatch, getState, { services }) => {
    const { cloud } = services;
    const { stylistApplication } = getState();

    // Remove channels that are empty
    const channels = stylistApplication.channels.filter(
      ({ name, url }) => !(name === '' && url === ''),
    );

    const referralCode = cookies.get(M.CREATOR_CODE_COOKIE_KEY);

    const feature = stylistApplication.feature === 'Yes';

    const application: StylistApplicationRequest = {
      ...stylistApplication,
      channels,
      feature,
    };

    if (referralCode) {
      application.referral_code = referralCode;
    }

    try {
      await cloud.fastAPI2('contentCreator-apply', application);

      dispatch(resetStylistApplicationRequest());
      // General Creators and stylsists now go the same route:
      dispatch(push(R.CREATOR_APPLICATION_DONE));
    } catch (error) {
      const typedError = error as Error;
      dispatch(
        updateStylistApplicationErrorMessage({
          errorMessage: typedError.message,
        }),
      );
    }
  };
};
