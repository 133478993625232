import { VFC, useContext, useState, useCallback, useRef } from 'react';

import { ServicesContext } from 'src/ServicesContext';
import { OutgoingMessageType } from 'src/services/Mobile';
import { HowToSaveContainerProps, HowToSaveProps } from './HowToSave.types';
import { HowToSave } from './HowToSave.component';
import { checkIfIOS } from 'src/utils/checkIfIOS';

const HowToSaveContainer: VFC<HowToSaveContainerProps> = (props) => {
  const { mobile, analytics } = useContext(ServicesContext);

  // Opens the share menu when on mobile
  const handleOnboard = useCallback<HowToSaveProps['onOnboard']>(() => {
    mobile.postMessage(OutgoingMessageType.Navigate, {
      screenName: 'Onboarding',
    });
  }, [mobile]);

  // Play the video only when the accordion is opened and pause it
  // otherwise
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoExpanded, setVideoExpanded] = useState(false);

  const handleVideoChange = useCallback<HowToSaveProps['onVideoChange']>(
    (_event, extended) => {
      setVideoExpanded(extended);

      if (extended) {
        videoRef.current?.play();
      } else {
        videoRef.current?.pause();
      }
    },
    [],
  );

  // Device toggle
  const [device, setDevice] = useState(
    mobile.isApp
      ? 'ios'
      : /(android)/i.test(navigator.userAgent)
      ? 'android'
      : 'desktop',
  );
  const handleDeviceChange = useCallback<HowToSaveProps['onDeviceChange']>(
    (_event, newDevice: string | null) => {
      if (newDevice && newDevice !== device) {
        setDevice(newDevice);

        // Pause video to avoid ending up viewing the desktop info with the
        // video sound in the background
        videoRef.current?.pause();
      }
    },
    [device],
  );

  const handleAppStoreLinkClick = useCallback(() => {
    analytics.recordEvent('WebApp:AppStoreButton:Pressed');
  }, [analytics]);

  // Sends the user either to the App Store if on iOS or to the app QR otherwise
  const isIOS = checkIfIOS();

  return (
    <HowToSave
      videoRef={videoRef}
      device={device}
      videoExpanded={videoExpanded}
      isIOS={isIOS}
      isApp={mobile.isApp}
      onDeviceChange={handleDeviceChange}
      onOnboard={handleOnboard}
      onVideoChange={handleVideoChange}
      onAppStoreLinkClick={handleAppStoreLinkClick}
      {...props}
    />
  );
};

export { HowToSaveContainer as HowToSave };
