import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Stack,
  MoveButton,
  moveButtonClassNames,
  iconBaseClassNames,
} from '@moonsifttech/design-system';

import { NavControlsProps } from './NavControls.types';

const UnstyledNavControls: VFC<NavControlsProps> = ({
  className,
  style,
  hidden,
  onBack,
}) => {
  return (
    <Stack
      className={clsx(className, hidden && 'NavControls-hidden')}
      style={style}
      direction="row"
    >
      <MoveButton variant="outlined" direction="left" onClick={onBack} />
    </Stack>
  );
};

export const NavControls = styled(UnstyledNavControls)`
  padding: 0 16px;

  > .${moveButtonClassNames.variantOutlined} {
    padding: 0;
    transition: transform 0.2s ease-out;

    .${moveButtonClassNames.button} {
      width: 40px;
      height: 40px;

      .${iconBaseClassNames.root} {
        font-size: 20px;
      }
    }
  }

  &.NavControls-hidden {
    > .${moveButtonClassNames.variantOutlined} {
      transform: translate3d(-64px, 0, 0);
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 0 24px;
  }
`;
