import React from 'react';
import { ReviewRequestProps } from './ReviewRequest.types';
import { Modal } from '@moonsifttech/design-system';
import { ModalContent } from 'src/components/core/ModalContent';
import styled from 'styled-components';
import { RateItem } from 'src/components/reviews/RateItem';
import { RateChromeExtension3 } from 'src/components/reviews/RateChromeExtension3';
import { RateChromeExtension2 } from 'src/components/reviews/RateChromeExtension2';
import { FeedbackThankYou } from 'src/components/reviews/FeedbackThankYou';
import { FeedbackRequest } from 'src/components/reviews/FeedbackRequest';
import { InitialRequest } from 'src/components/reviews/InitialRequest';

const UnstyledReviewRequest: React.FC<ReviewRequestProps> = ({
  isOpen,
  onClose,
  className,
  style,
  showModal,
  currentScreen,
  onNoClick,
  onYesClick,
  onTooEarlyClick,
  isMobile,
  setCurrentScreen,
  onRateUsOnChromeStoreClick,
  onOpenChromeStoreClick,
  onRateAppClick,
}) => {
  /*

  RENDERING:

  */
  const getChildren = () => {
    switch (currentScreen) {
      case 'initial':
        return (
          <InitialRequest
            onNoClick={onNoClick}
            onYesClick={onYesClick}
            onTooEarlyClick={onTooEarlyClick}
            isMobile={isMobile}
          />
        );
      case 'feedback':
        return <FeedbackRequest setCurrentScreen={setCurrentScreen} />;
      case 'feedback-thankyou':
        return <FeedbackThankYou onClose={onClose} />;
      case 'rate-chrome-extension-1':
        return (
          <RateItem
            type="extension"
            onRateUsClick={onRateUsOnChromeStoreClick}
          />
        );
      case 'rate-chrome-extension-2':
        return (
          <RateChromeExtension2
            onOpenChromeStoreClick={onOpenChromeStoreClick}
          />
        );
      case 'rate-chrome-extension-3':
        return <RateChromeExtension3 onClose={onClose} />;
      case 'rate-app':
        return <RateItem type="app" onRateUsClick={onRateAppClick} />;
      default:
        return null;
    }
  };

  return showModal ? (
    <Modal
      mode="modal"
      isOpen={isOpen}
      onRequestClose={onClose}
      className={className}
      style={style}
    >
      <ModalContent className="ReviewRequest-modal" onClose={onClose}>
        {getChildren()}
      </ModalContent>
    </Modal>
  ) : null;
};

export const ReviewRequest = styled(UnstyledReviewRequest)`
  overflow-y: auto;
  max-height: 100%;
  .ReviewRequest-modal {
    min-height: ${({ minHeight }) => minHeight?.toString() + 'px' || 'auto'};
  }
`;
