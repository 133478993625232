
export function load() {
  var params = new URLSearchParams(window.location.search);
  var isMobileApp = window.localStorage
    && window.localStorage.getItem('MOONSIFT_IS_MOBILE') === 'YES';

  if (!params.has('sign_in_token') && !isMobileApp) {
    // We are NOT in the mobile app, therefore should load the cookie banner
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://app.enzuzo.com/apps/enzuzo/static/js/__enzuzo-cookiebar.js?uuid=aee3bb28-320c-11ef-9ed1-135e3bd86e38';
    script.async = false;

    var head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  } else {
    // We are in the mobile app, we don't load the cookie banner but grant all permissions
    // NOTE: Calling below with all permissions granted,
    // will also load in Mixpanel and Meta Pixel (load scripts in index.html)
    window.gtag('consent', 'update', {
        'ad_storage': 'granted',
        'ad_user_data': 'granted',
        'ad_personalization': 'granted',
        'analytics_storage': 'granted',
        'personalization_storage': 'granted',
        'functionality_storage': 'granted',
        'security_storage': 'granted',
    });
  }
}
