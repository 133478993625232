import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  CtaButton,
  ctaButtonClassNames,
  Stack,
  Typography,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { Page } from 'src/components/common/Page';
import { PlanFeature } from 'src/components/plans/PlanFeature';
import { PlansProps } from './Plans.types';
import { Helmet } from 'react-helmet';

const UnstyledPlans: VFC<PlansProps> = ({ className, style, isLoggedIn }) => {
  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Helmet>
        <title>Pro Plans - Moonsift</title>
        <meta
          name="description"
          content="Moonsift offers a Pro plan that has additional features as well as plans for stylists and creators."
        />
      </Helmet>
      <div className="Plans-headerContainer">
        <Typography variant="editorial.sb34" align="center" component="h1">
          Choose what's right for you
        </Typography>
        <Typography variant="primary.l16" align="center" component="p">
          Get Moonsift Pro free for both you and a friend by simply{' '}
          <Link to={R.PRO_REFER}>referring them</Link>.
        </Typography>
      </div>

      <Stack direction="row" justifyContent="center" wrap="wrap">
        <div className="Plans-planCard">
          <Stack
            className="Plans-planCardContent"
            direction="column"
            justifyContent="space-between"
          >
            <div>
              <Typography variant="editorial.r26" align="center" component="h2">
                FREE
              </Typography>
              <Stack
                className="Plans-price"
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                spacing={4}
              >
                <Typography variant="primary.b26">$0</Typography>
                <Typography variant="primary.l16">/month</Typography>
              </Stack>
              <div className="Plans-features">
                <PlanFeature highlight>
                  Unlimited Moonsift Collections
                </PlanFeature>
                <PlanFeature>Add items from any store</PlanFeature>
                <PlanFeature>Make Collections Public or Private</PlanFeature>
                <PlanFeature>
                  Make beautiful Registries & Gift lists
                </PlanFeature>
                <PlanFeature>Get price updates</PlanFeature>
                <PlanFeature>Share collections anywhere</PlanFeature>
                <PlanFeature>Make and receive comments</PlanFeature>
                <PlanFeature>
                  Choose which image of the product is displayed
                </PlanFeature>
              </div>
            </div>
            <div>
              <CtaButton
                variant="primary-white"
                fullWidth
                component={UnstyledLink}
                to={isLoggedIn ? R.HOME : R.SIGNUP}
              >
                Choose Free
              </CtaButton>
              <Typography
                className="Plans-referLink"
                variant="primary.r16"
                align="center"
                component="p"
              >
                <Link to={R.PRO_REFER}>
                  Unlock Pro free by referring a friend
                </Link>
              </Typography>
              <Typography variant="primary.l14" align="center" component="p">
                Limited time offer
              </Typography>
            </div>
          </Stack>
        </div>

        <div className={clsx('Plans-planCard', 'Plans-pro')}>
          <Stack
            className="Plans-planCardContent"
            direction="column"
            justifyContent="space-between"
          >
            <div>
              <Typography variant="editorial.r26" align="center" component="h2">
                PRO
              </Typography>
              <Stack
                className="Plans-price"
                direction="row"
                justifyContent="center"
                alignItems="flex-end"
                spacing={4}
              >
                <Typography variant="primary.b26">$6</Typography>
                <Typography variant="primary.l16">/month</Typography>
              </Stack>
              <div className="Plans-features">
                <PlanFeature highlight>Everything in Free</PlanFeature>
                <PlanFeature>Add Sections to Collection</PlanFeature>
                <PlanFeature>Personalise your profile URL</PlanFeature>
                <PlanFeature>
                  Add a link to your own website, blog or social media
                </PlanFeature>
                <PlanFeature>
                  Choose the image that displays on the cover of your collection
                </PlanFeature>
                <PlanFeature>Generate short videos of Collections</PlanFeature>
                <PlanFeature>Support our ad free platform</PlanFeature>
              </div>
            </div>
            <div>
              <CtaButton
                variant="primary-black"
                fullWidth
                component={UnstyledLink}
                to={R.PRO_TRIAL}
              >
                Choose Pro
              </CtaButton>
              <Typography
                className="Plans-referLink"
                variant="primary.r16"
                align="center"
                component="p"
              >
                <Link to={R.PRO_REFER}>
                  Unlock Pro free by referring a friend
                </Link>
              </Typography>
              <Typography variant="primary.l14" align="center" component="p">
                Limited time offer
              </Typography>
            </div>
          </Stack>
        </div>
      </Stack>
    </Page>
  );
};

export const Plans = styled(UnstyledPlans)`
  margin-bottom: 60px;

  .Plans-headerContainer {
    padding: 0 16px;

    > h1 {
      margin-bottom: 12px;
    }

    > p {
      margin-bottom: 32px;
    }
  }

  .Plans-planCard {
    padding: 36px 20px 16px;
    border-radius: 8px;
    border: 1px solid var(--grey-5);
    border-top-width: 10px;
    margin: 0 16px 40px;
    max-width: 328px;
    min-height: 657px;

    .Plans-planCardContent {
      height: 100%;
    }

    h2 {
      margin-bottom: 12px;
    }

    .Plans-price {
      margin-bottom: 40px;

      > span:last-child {
        margin-bottom: 4px;
      }
    }

    .Plans-features {
      margin-bottom: 44px;
    }

    .${ctaButtonClassNames.variantPrimaryWhite},
      .${ctaButtonClassNames.variantPrimaryBlack} {
      margin-bottom: 16px;
    }

    .Plans-referLink {
      margin-bottom: 4px;
    }

    &.Plans-pro {
      border-top-color: var(--common-black);
    }
  }
`;
