import { FirebaseApp } from 'firebase/app';
import {
  getMessaging,
  getToken,
  Messaging,
  isSupported,
} from 'firebase/messaging';

import {
  Mobile,
  IncomingMessageType,
  OutgoingMessageType,
  NotificationsEnabledIncomingMessage,
  NotificationsDisabledIncomingMessage,
} from 'src/services/Mobile';
import { Cloud } from 'src/services/Cloud';

// Notifications provided by firebase notification service and supplementary
// scripts (not in this repo at present)
export class Notifications {
  public messaging: Messaging | null = null;

  // The amount of time the frontend will wait for a response after sending a
  // message to the app
  private readonly TIMEOUT = 15000;

  constructor(app: FirebaseApp, private mobile: Mobile, private cloud: Cloud) {
    this.setUpMessaging(app).then((messaging) => (this.messaging = messaging));
  }

  // Only run if messaging supported in browser:
  private async setUpMessaging(app: FirebaseApp) {
    try {
      const messagingIsSupportedByBrowser = await isSupported();
      if (messagingIsSupportedByBrowser) {
        return getMessaging(app);
      }
    } catch (err) {
      console.log('Messaging not supported, sorry', err);
    }

    return null;
  }

  // Gets a device-specific FCM token
  public getToken() {
    if (this.messaging) {
      return getToken(this.messaging);
    }

    return null;
  }

  public async enableBrowserNotifications() {
    const token = await this.getToken();
    return await this.cloud.fastAPI({
      api: 'update_notification_token',
      token,
    });
  }

  // Asks the app to enable notifications
  public enableAppNotifications(): Promise<void> {
    console.info('[NotificationService] Enabling app notifications...');

    // Start listening to the app
    const messagePromise = this.mobile
      .listenToMessage<
        | NotificationsEnabledIncomingMessage
        | NotificationsDisabledIncomingMessage
      >(
        [
          IncomingMessageType.NotificationsDisabled,
          IncomingMessageType.NotificationsEnabled,
        ],
        this.TIMEOUT,
      )
      .then((message) => {
        console.info('[NotificationService] Notifications have been enabled');
      });

    // Request the enabling of notifications to the app
    this.mobile.postMessage(OutgoingMessageType.EnableNotifications);

    return messagePromise;
  }

  // Checks whether notifications are enabled in the app
  public checkAppNotifications(): Promise<{ enabled: boolean }> {
    console.info('[NotificationService] Checking app notifications status...');

    // Start listening to the app
    const messagePromise = this.mobile
      .listenToMessage<
        | NotificationsEnabledIncomingMessage
        | NotificationsDisabledIncomingMessage
      >(
        [
          IncomingMessageType.NotificationsDisabled,
          IncomingMessageType.NotificationsEnabled,
        ],
        this.TIMEOUT,
      )
      .then(({ type }) => {
        if (type === IncomingMessageType.NotificationsEnabled) {
          console.info('[NotificationService] App notifications are enabled.');
          return { enabled: true };
        }

        console.info('[NotificationService] App notifications are disabled.');
        return { enabled: false };
      });

    // Check if notifications are enabled or not
    this.mobile.postMessage(OutgoingMessageType.CheckNotifications);

    return messagePromise;
  }
}
