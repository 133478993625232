import { VFC, useState, useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Accordion,
  AccordionSummary,
  accordionSummaryClassNames,
  AccordionDetails,
  accordionDetailsClassNames,
  ChevronDownIcon,
  StandardButton,
} from '@moonsifttech/design-system';
import { useHistory } from 'react-router-dom';

import R from 'src/routes';
import { Page } from 'src/components/common/Page';
import { ServicesContext } from 'src/ServicesContext';
import { AccountSettingsProps } from './AccountSettings.types';

const UnstyledDeletionStep = ({
  step,
  deleteAccount,
  openDeletionStepTwo,
  deleteWorking,
}: {
  step: number;
  deleteAccount: () => void;
  openDeletionStepTwo: () => void;
  deleteWorking: boolean;
}) => {
  if (step === 3) {
    return (
      <>
        <Typography variant="primary.r16" component="p">
          Your account has been deleted, you will be logged out. Other devices
          will log out within an hour.
        </Typography>
      </>
    );
  }
  if (step === 2) {
    return (
      <>
        <Typography variant="primary.r16" component="p">
          Are you sure?
        </Typography>
        <StandardButton
          className="deleteaccount-FirstDeleteButton"
          variant="primary-black"
          fullWidth
          onClick={deleteAccount}
          loading={deleteWorking}
        >
          Yes, Delete My Account
        </StandardButton>
      </>
    );
  }
  // Default is step 1:
  return (
    <>
      <Typography variant="primary.r16" component="p">
        Closing your account will remove you from Moonsift mailing lists and
        lead to deletion of all your saved items and collections.
      </Typography>
      <StandardButton
        className="deleteaccount-FirstDeleteButton"
        variant="primary-black"
        fullWidth
        onClick={openDeletionStepTwo}
      >
        Delete Account
      </StandardButton>
    </>
  );
};

const UnstyledAccountSettings: VFC<AccountSettingsProps> = ({
  className,
  style,
}) => {
  // Delete account step to show:
  const [deleteStep, setDeleteStep] = useState(1);
  const [deleteWorking, setDeleteWorking] = useState(false);

  const history = useHistory();
  const { cloud } = useContext(ServicesContext);

  const openDeletionStepTwo = useCallback(() => {
    setDeleteStep(2);
  }, [setDeleteStep]);

  // Actually does the account deletion:
  const deleteAccount = useCallback(async () => {
    // Set working:
    setDeleteWorking(true);
    // Call API to do deletion:
    try {
      await cloud.call('users_deleteAccount-call')();
      setDeleteStep(3);
      // No need to clear the timeout as we want it to happen regardless:
      setTimeout(() => history.push(R.LOGOUT), 4000);
    } catch (err) {
      console.error('Failed to delete account', err);
    }
    setDeleteWorking(false);
  }, [cloud, history]);

  // Go to forgot password page:
  const goToForgotPassword = useCallback(() => {
    history.push(R.FORGOTPASSWORD);
  }, [history]);

  return (
    <Page className={className} style={style}>
      <div className="AccountSettings-innerContainer">
        <Typography
          className="AccountSettings-heading"
          variant="primary.b42"
          component="h1"
        >
          Account Settings
        </Typography>
        <Accordion>
          <AccordionSummary
            id="resetpassword-header"
            aria-controls="resetpassword-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16">Reset Password</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.r16" component="p">
              Please use the 'forgot password' page to request a change to your
              password
            </Typography>
            <StandardButton
              className="resetpassword-go-to-forgot-password"
              variant="primary-black"
              fullWidth
              onClick={goToForgotPassword}
            >
              Go to Forgot Password
            </StandardButton>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="deleteaccount-header"
            aria-controls="deleteaccount-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16">Delete Account</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <UnstyledDeletionStep
              step={deleteStep}
              deleteAccount={deleteAccount}
              openDeletionStepTwo={openDeletionStepTwo}
              deleteWorking={deleteWorking}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </Page>
  );
};

export const AccountSettings = styled(UnstyledAccountSettings)`
  .AccountSettings-innerContainer {
    max-width: 328px;
    margin: 0 auto 40px;
  }

  .AccountSettings-heading {
    margin-bottom: 24px;
  }

  .${accordionSummaryClassNames.root} {
    outline: 0;
  }

  .${accordionDetailsClassNames.root} {
    > :last-child {
      margin-bottom: 24px;
    }
  }
`;
