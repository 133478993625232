/*

useDBUpdate

*/
/*
All we want to do is copy the current entry to another collection if we need to OR delete it!
*/
import send_error from 'src/mvp22/core-components/send_error';
export default function update_product_image(collectionUID,itemUID,imageURL,firebase,authUserUID){
  return new Promise(
    (resolve,reject)=>{
      // For now we just use one page_url:
      if (
        collectionUID !== undefined
        && itemUID !== undefined
        && imageURL !== undefined
      ){
        const new_data = {
          product_image:imageURL
        };
        firebase.user_product_collection_doc(
          authUserUID,
          collectionUID,
          itemUID
        ).update(
          new_data
        ).then(
          ()=>{
            resolve();
          }
        ).catch(
          (err)=>{
            send_error(err);
            reject();
          });
      }
      else{
        send_error("Missing 'image_url' and/or 'collection_uid' and/or 'product' in input!");
        reject();
      }
    }
  );
}
