/*

Just generates a conistent "StoreAs" value for accessing and retrieving documents
in the redux store relating to a single item in a collection.

*/
export const getCollectionItemStoreAs = (
  collectionId: string,
  collectionUserId: string,
  itemId: string,
) => {
  return collectionId + '__' + collectionUserId + '__' + itemId;
};
