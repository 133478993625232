import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import {
  updateCreatorApplicationRequest,
  apply,
} from 'src/store/actions/creatorApplication';
import {
  CreatorApplicationSixContainerProps,
  CreatorApplicationSixProps,
  CreatorApplicationSixFormContent,
} from './CreatorApplicationSix.types';
import { CreatorApplicationSix } from './CreatorApplicationSix.component';

const validationSchema = object<CreatorApplicationSixFormContent>({
  feature: string().oneOf(['Yes', 'No']).required(),
});

const CreatorApplicationSixContainer: VFC<
  CreatorApplicationSixContainerProps
> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = useSelector<
    RootState,
    CreatorApplicationSixFormContent
  >(({ creatorApplication }) => ({
    feature: creatorApplication.feature ?? '',
  }));

  const errorMessage = useSelector<RootState, string>(
    ({ creatorApplication }) => creatorApplication.errorMessage,
  );

  const handleSubmit = useCallback<CreatorApplicationSixProps['onSubmit']>(
    async (values, actions) => {
      dispatch(updateCreatorApplicationRequest(values));
      await dispatch(apply());
      actions.setSubmitting(false);
    },
    [dispatch],
  );

  const formRef: CreatorApplicationSixProps['formRef'] = useRef(null);
  const handleBack = useCallback(() => {
    if (formRef.current) {
      const feature = formRef.current['feature'].value as 'Yes' | 'No';
      dispatch(updateCreatorApplicationRequest({ feature }));
    }
    history.push(R.CREATOR_APPLICATION_FIVE);
  }, [dispatch, history]);

  return (
    <CreatorApplicationSix
      initialValues={initialValues}
      validationSchema={validationSchema}
      formRef={formRef}
      errorMessage={errorMessage}
      onSubmit={handleSubmit}
      onBack={handleBack}
      {...props}
    />
  );
};

export { CreatorApplicationSixContainer as CreatorApplicationSix };
