import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import {
  updateStylistApplicationRequest,
  apply,
} from 'src/store/actions/stylistApplication';
import {
  StylistApplicationFourContainerProps,
  StylistApplicationFourProps,
  StylistApplicationFourFormContent,
} from './StylistApplicationFour.types';
import { StylistApplicationFour } from './StylistApplicationFour.component';

const validationSchema = object<StylistApplicationFourFormContent>({
  feature: string().oneOf(['Yes', 'No']).required(),
});

const StylistApplicationFourContainer: VFC<
  StylistApplicationFourContainerProps
> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = useSelector<
    RootState,
    StylistApplicationFourFormContent
  >(({ stylistApplication }) => ({
    feature: stylistApplication.feature ?? '',
  }));

  const errorMessage = useSelector<RootState, string>(
    ({ stylistApplication }) => stylistApplication.errorMessage,
  );

  const handleSubmit = useCallback<StylistApplicationFourProps['onSubmit']>(
    async (values, actions) => {
      dispatch(updateStylistApplicationRequest(values));
      await dispatch(apply());
      actions.setSubmitting(false);
    },
    [dispatch],
  );

  const formRef: StylistApplicationFourProps['formRef'] = useRef(null);
  const handleBack = useCallback(() => {
    if (formRef.current) {
      const feature = formRef.current['feature'].value as 'Yes' | 'No';
      dispatch(updateStylistApplicationRequest({ feature }));
    }
    history.push(R.STYLIST_APPLICATION_THREE);
  }, [dispatch, history]);

  return (
    <StylistApplicationFour
      initialValues={initialValues}
      validationSchema={validationSchema}
      formRef={formRef}
      errorMessage={errorMessage}
      onSubmit={handleSubmit}
      onBack={handleBack}
      {...props}
    />
  );
};

export { StylistApplicationFourContainer as StylistApplicationFour };
