export const keepParams = (
  currentParams: URLSearchParams,
  nextParams: URLSearchParams,
  keeplist: string[],
): URLSearchParams => {
  keeplist.forEach((param) => {
    if (!nextParams.has(param) && currentParams.has(param)) {
      nextParams.set(param, currentParams.get(param)!);
    }
  });

  return nextParams;
};
