import {
  ActionNames,
  CreatorApplicationAction,
  CreatorApplicationState,
} from 'src/types';

function getInitialState(): CreatorApplicationState {
  return {
    categories: [],
    affiliates: [],
    channels: [
      {
        name: '',
        url: '',
      },
      {
        name: '',
        url: '',
      },
    ],
    audience: {
      countries: [''],
    },
    feature: '',
    errorMessage: '',
  };
}

export const creatorApplication = (
  state = getInitialState(),
  action: CreatorApplicationAction,
): CreatorApplicationState => {
  switch (action.type) {
    case ActionNames.UPDATE_CREATOR_APPLICATION:
    case ActionNames.UPDATE_CREATOR_APPLICATION_ERROR_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionNames.RESET_CREATOR_APPLICATION:
      return getInitialState();
    default:
      return state;
  }
};
