/*
Displays raw numbers in a prettier, abreviated way
Will move to utils
*/
export default function condense_number(number_in){
  if (number_in<=999){
    return number_in;
  }else if (number_in<=999999){
    return Math.round(number_in/1000)+"k";
  }else if (number_in<=999999999){
    return Math.round(number_in/1000000)+"M";
  }else if (number_in>999999999){
    return "999M+";
  }
  else{
    return 0;
  }
}
