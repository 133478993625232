import { useSelector } from 'react-redux';
import { RootState } from 'src/index';

type Output = {
  isMine: boolean;
}

export const useMine = (collectionUserId: string): Output => {
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  return {
    isMine: authUserUID === collectionUserId,
  };
};
