import { ParserInput } from 'src/services/DB';

/**
 * Parses the document by extracting the data.
 */
export function data<T>(doc: ParserInput) {
  if (doc.exists()) {
    return doc.data() as T;
  }

  return null;
}
