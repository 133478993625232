import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { useWorking } from 'src/hooks/useWorking';
import { ActionNames, AutoTriggerReviewRequests } from 'src/types';
import { ReactionTypes } from 'src/types/models/reaction.model';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';

export const useReaction = (
  collectionUserUID: string,
  collectionUID: string,
  itemUID: string,
) => {
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  const { cloud, analytics } = useContext(ServicesContext);
  const dispatch = useDispatch();
  // custom hooks:
  const [setUpvoteWorking, unsetUpvoteWorking, upvoteWorkingValue] = useWorking(
    productDataStoreAs + '__upvote',
  );
  const [setDownvoteWorking, unsetDownvoteWorking, downvoteWorkingValue] =
    useWorking(productDataStoreAs + '__downvote');
  // selectors:
  const reaction = useSelector<RootState, ReactionTypes | undefined>(
    (state) => {
      const reactionQueryResult =
        state.db.single_collection_product_is_liked_or_disliked.map[
          productDataStoreAs
        ];
      return reactionQueryResult?.length === 1
        ? reactionQueryResult[0].type
        : undefined;
    },
  );

  // functions
  const reactProduct = (
    event: MouseEvent,
    currentType: string,
    newType: string,
  ) => {
    event.preventDefault();
    const working =
      newType === 'upvote'
        ? upvoteWorkingValue
        : newType === 'downvote'
        ? downvoteWorkingValue
        : false;
    const setWorking =
      newType === 'upvote'
        ? setUpvoteWorking
        : newType === 'downvote'
        ? setDownvoteWorking
        : false;
    const unsetWorking =
      newType === 'upvote'
        ? unsetUpvoteWorking
        : newType === 'downvote'
        ? unsetDownvoteWorking
        : false;
    if (
      working === undefined &&
      setWorking !== false &&
      unsetWorking !== false
    ) {
      setWorking(true);
      const aPIToCall =
        currentType !== newType ? 'set_reaction' : 'unset_reaction';
      cloud
        .fastAPI({
          api: aPIToCall,
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          product_uid: itemUID,
          reaction: newType,
        })
        .then(() => {
          unsetWorking();
        })
        .catch(() => {
          unsetWorking();
        });
      if (aPIToCall === 'set_reaction' && newType === 'upvote') {
        dispatch<AutoTriggerReviewRequests>({
          type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
          payload: undefined,
        });
      }
    }
    analytics.recordEvent(`WebApp:Rection:${newType}`);
    return false;
  };

  return {
    reaction,
    reactProduct,
    upvoteWorkingValue,
    downvoteWorkingValue,
  };
};
