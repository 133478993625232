import { userPublicParser } from 'src/store/parsers/userPublic';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { useContext } from 'react';
import { ServicesContext } from 'src/ServicesContext';
import { useSelector } from 'react-redux';
import { RootState } from 'src';

export const useUserPublicDoc = (userUID: string) => {
  const { db: dB } = useContext(ServicesContext);
  const authUserUID = useSelector<RootState, string | undefined>(
    ({ auth }) => auth.id ?? undefined,
  );
  const loggedInUserIsRequestedUser =
    authUserUID && userUID && userUID === authUserUID;
  useDBRetrieveDoc<'user_public'>(
    dB,
    dB.userPubicDoc,
    'user_public',
    userPublicParser,
    userUID,
    [userUID],
    loggedInUserIsRequestedUser ? 'listen' : 'get',
    false,
    true, // always get from server for now, might in future have a way to force-refresh it only when required.
  );
};
