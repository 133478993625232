import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, string, tuple } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import { updateStylistApplicationRequest } from 'src/store/actions/stylistApplication';
import {
  StylistApplicationTwoContainerProps,
  StylistApplicationTwoProps,
  StylistApplicationTwoFormContent,
} from './StylistApplicationTwo.types';
import { StylistApplicationTwo } from './StylistApplicationTwo.component';

const validationSchema = object({
  channels: tuple([
    object({
      name: string().required('A channel name must be provided.'),
      url: string().required('A channel url or handle must be provided.'),
    }).required(),
    object({
      name: string(),
      url: string(),
    }),
  ]),
});

const StylistApplicationTwoContainer: VFC<
  StylistApplicationTwoContainerProps
> = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const initialValues = useSelector<
    RootState,
    StylistApplicationTwoFormContent
  >(({ stylistApplication }) => ({
    channels: [...stylistApplication.channels],
  }));

  const formRef: StylistApplicationTwoProps['formRef'] = useRef(null);

  const handleSubmit = useCallback<StylistApplicationTwoProps['onSubmit']>(
    (values, actions) => {
      dispatch(updateStylistApplicationRequest(values));
      actions.setSubmitting(false);
      history.push(R.STYLIST_APPLICATION_THREE);
    },
    [dispatch, history],
  );

  const handleBack = useCallback(() => {
    if (formRef.current) {
      const channels = [
        {
          name: formRef.current['channels[0].name'].value,
          url: formRef.current['channels[0].url'].value,
        },
        {
          name: formRef.current['channels[1].name'].value,
          url: formRef.current['channels[1].url'].value,
        },
      ];

      dispatch(updateStylistApplicationRequest({ channels }));
    }
    history.push(R.STYLIST_APPLICATION_ONE);
  }, [dispatch, history]);

  return (
    <StylistApplicationTwo
      formRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onBack={handleBack}
      {...props}
    />
  );
};

export { StylistApplicationTwoContainer as StylistApplicationTwo };
