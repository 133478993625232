/*

WILL BECOME REDUNDANT


PLEASE READ THE COMMENTS BELOW IF YOU ARE LOOKING AT THIS FILE:




This is now only used for loading data about collections.
- Landing pages,
- Explore pages,
- Profile pages (as CollectionTileList)

This needs to be removed and rewritten in a way similar to how
PseudoProductElement fetches data for the Products and stores it in redux,
though a post processing (e.g. to ProductData) should NOT be required.

Hence do not use this for anything again, rather we should replace the component's methods instead!












This function assigns properties safely (hopefully) to local state in a way that means we DON'T need to use redux.
It stores the state in a dictionary with a key to identify the collection or product etc.
Then there are subproperties that can be updated independently.
It also -- by the way -- handles firebase listeners.

Consider adding option of:
id_must_be_in_new_list=false
which would be useful for garbage collecting...
*/
// We need to call this WITHIN a setState function ideally.  So... just calculate a new state from the old state that comes in:
export default function nested_state_assignment(old_collection_data_dict_input,input_data_dict){
  const old_collection_data_dict = (old_collection_data_dict_input)?old_collection_data_dict_input:{};
  const new_collection_data_dict = {...old_collection_data_dict};
  for (let this_entry_uid in input_data_dict){
    const old_data_entry = old_collection_data_dict[this_entry_uid];
    const new_data_entry = input_data_dict[this_entry_uid];
    new_collection_data_dict[this_entry_uid] = Object.assign({},old_data_entry,new_data_entry);
    // Clear any old listeners:
    for (let this_key in old_data_entry){
      if (
        this_key.includes("listener")
      &&
        new_data_entry[this_key]
      &&
        old_data_entry[this_key]!==new_data_entry[this_key]
      ){
        old_data_entry[this_key]();
      }
    }
  }
  return (new_collection_data_dict);
}
