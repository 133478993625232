/*

Landing page to target Christmas wishlist makers.  Very similar to Christmas Landing page

TODO: typescript, camelCase, make functional, non-default exports, design system...
Extract list of collections useing a dbHook (get,single doc)
Extract collection from db using a dbHook. (get, doc map)
collection tiles should load images in themselves.
*/
import { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { ServicesContext } from 'src/ServicesContext';
import clsx from 'clsx';
import { Stack, Typography } from '@moonsifttech/design-system';
import { connect } from 'react-redux';
import _ from 'lodash';

import { M } from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import windowSize from 'src/mvp22/WindowSize';
import get_collection_images from 'src/mvp22/firebase-functions/get_collection_images';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import get_explore_collections from 'src/mvp22/firebase-functions/get_explore_collections';
import { withFirebase } from 'src/mvp22/Firebase';
import CollectionCarousel from 'src/mvp22/tile-components/CollectionCarousel';
import ReviewsCarousel from 'src/mvp22/tile-components/ReviewsCarousel';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import remove_all_listeners from 'src/mvp22/core-components/remove_all_listeners';
import R, { EXTERNAL } from 'src/routes';
import { Link } from 'src/components/core/Link';
import { UseCases } from 'src/components/landing/UseCases';

const Container = styled.div`
  overflow: hidden;
  background-color: ${M.COL.BG.WHITE};
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.isDesktop === true ? 'flex-start' : 'center'};
  flex-wrap: ${(props) =>
    props.isDesktop === true ? 'no-wrap' : 'wrap-reverse'};
  background-color: ${M.COL.BG.LIGHT};
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SecondRowInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 990px;
`;

const TopImageContainer = styled.div`
  width: ${(props) => (props.isDesktop ? '45%' : '100%')};
  overflow: hidden;
  display: flex;
`;

const BannerImage = styled.div`
  height: 668px;
  width: 1120px;
  background-image: url(${MEDIA.LANDING_IMG_1_MAR_2022});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  resize: both;
`;

const BannerImageSmall = styled.div`
  height: 353px;
  width: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  resize: both;
  background-image: url(${MEDIA.LANDING_IMG_1_AUG_2020_SMALL});
`;

const FirstRowColumn = styled.div`
  display: flex;
  width: ${(props) => (props.isDesktop ? '50%' : '100%')};
  justify-content: center;
  align-items: center;
  margin-bottom: ${(props) => (props.isDesktop ? '0px' : '62px')};
  max-width: 500px;
  @media (min-width: 937px) {
    margin-left: auto;
    margin-right: auto;
  }
  @media (min-width: 1200px) {
    margin-left: 100px;
  }
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
`;

const TextContainer = styled.div`
  color: ${M.COL.TEXT.BLACK};
  position: relative;
  top: 20px;
  max-width: 394px;
  @media (max-width: 500px) {
    max-width: 326px;
  }
`;

const TextContainerOuter = styled.div`
  display: flex;
  justify-content: ${(props) => (props.isDesktop ? 'center' : 'flex-start')};
  width: ${(props) => (props.isDesktop ? '50%' : '100%')};
  margin-left: ${(props) => (props.isDesktop ? '' : '17px')};
  margin-right: ${(props) => (props.isDesktop ? '' : '17px')};
`;

const TextContainerOuter2 = styled(TextContainerOuter)`
  position: relative;
  right: ${(props) => (props.isDesktop ? '53px' : '')};
`;

const CTAButtonTextBold = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: bold;
  font-size: ${(props) => (props.isDesktop === true ? '26px' : '18px')};
  line-height: 38px;
`;

const CTAButtonTextLight = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: light;
  font-size: ${(props) => (props.isDesktop === true ? '26px' : '18px')};
  line-height: 38px;
`;

const ChromeIcon = styled.img`
  width: 13px;
  margin-right: 6px;
`;

const FiveStars = styled(({ className, style, size = 'regular' }) => {
  return (
    <div className={`${className} ${size}`} style={style}>
      {_.times(5, (i) => {
        return <img key={i} src={MEDIA.RATING_STAR} alt="" />;
      })}
    </div>
  );
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    display: inline-block;
  }

  &.small img {
    width: 14px;
  }

  &.regular img {
    width: 23px;
  }
`;

const CtaButton = styled(({ className, style, children, isDesktop }) => {
  return (
    <div className={className}>
      <Link className="button" style={style} to={R.SIGNUP}>
        <Button
          isDesktop={isDesktop}
          text={children}
          msstyle={
            isDesktop === true
              ? 'cta_alternative_desktop'
              : 'cta_alternative_mobile'
          }
        />
      </Link>
      <SubSubtitle>
        <ChromeIcon src={MEDIA.CHROME_ICON} />
        Designed for Chrome, Edge & Brave browsers
      </SubSubtitle>
      <SubSubtitle>
        <FiveStars size="small" /> 5 star average Chrome store rating
      </SubSubtitle>
    </div>
  );
})`
  .button {
    display: block;
    text-decoration: none;
    color: inherit;
    margin-bottom: 16px;

    button {
      ${({ isDesktop }) =>
        isDesktop
          ? css`
              padding: 0 40px;
            `
          : ''}
    }
  }

  ${FiveStars} {
    margin-right: 6px;
  }
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const Section1Subtitle = styled(TEXTSTYLE.BODY2)`
  font-size: 20px;
  line-height: 30px;
  max-width: 100%;
  text-align: left;
`;

const SubSubtitle = styled(TEXTSTYLE.BODY5)`
  color: ${M.COL.TEXT.MID};
  display: flex;
`;

const SecondRowTitle = styled.div`
  color: ${M.COL.TEXT.BLACK};
  display: flex;
  justify-content: flex-start;
  text-align: left;
  margin-left: 18px;
  align-self: flex-start;
`;

const FourthRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${M.COL.BG.LIGHT};
`;

const VideoContainer = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  ::-webkit-scrollbar {
    display: none;
  }
  font-size: 0px;
  overflow: hidden;
  width: ${(props) => (props.isDesktop ? '50%' : '100%')};
  margin-top: 20px;
`;

const VideoContainer2 = styled(VideoContainer)`
  margin-top: ${(props) => (props.isDesktop ? '55px' : '20px')};
`;

const Video = styled.video`
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
`;

const ItemText = styled.div`
  font-size: 20px;
`;

const BottomText = styled(ItemText)`
  max-width: 420px;
  text-align: left;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: ${(props) => (props.isDesktop === true ? '1116px' : '420px')};
`;

const GreyBG = styled.div`
  background-color: ${M.COL.BG.LIGHT};
  display: flex;
  justify-content: center;
`;

const FourthRowText = styled(TEXTSTYLE.BODY2)`
  max-width: 375px;
`;

const Star = styled.img`
  width: 23px;
`;

const StarContainer = styled.div`
  margin-left: 16px;
  display: inline;
`;

const InnerContainer = styled.section`
  margin: 0 auto;
  width: 100%;
  max-width: 992px;

  @media (max-width: 992px) {
    margin: 0 34px;
    width: calc(100% - 68px);
  }

  @media (max-width: 500px) {
    margin: 0 16px;
    width: calc(100% - 32px);
  }
`;

const Instructions = styled(
  ({ className, style, isDesktop, handleAddChromeExtension }) => {
    return (
      <InnerContainer className={className} style={style}>
        <div className="innerContainer">
          <Stack
            justifyContent="center"
            alignItems="center"
            direction="row"
            wrap="wrap-reverse"
          >
            <VideoContainer isDesktop={isDesktop}>
              <Video
                src={MEDIA.LANDING_VIDEO_1_FASTER}
                loop
                autoPlay
                muted
                playsInline
              />
            </VideoContainer>
            <Seperator height="44px" />
            <TextContainerOuter2
              className="TextContainerOuter2"
              isDesktop={isDesktop}
            >
              <TextContainer className="TextContainer">
                <BottomText className="BottomText">
                  <Typography
                    className="heading"
                    variant="editorial.sb34"
                    component="h2"
                  >
                    Add products from
                    <br />
                    any store
                  </Typography>
                  <ol>
                    <li>
                      <Link
                        to={EXTERNAL.CHROMESTORE}
                        target="_blank"
                        onClick={handleAddChromeExtension}
                      >
                        Add to your browser
                      </Link>{' '}
                      on your desktop
                    </li>
                    <li>Browse and shop as normal</li>
                    <li>Save items with one click</li>
                  </ol>
                  <Typography
                    className="subheading"
                    variant="primary.l20"
                    component="p"
                  >
                    <Link to={R.RETAILERS}>List of most popular stores</Link>
                  </Typography>
                  <Seperator height={'18px'} />
                </BottomText>
              </TextContainer>
            </TextContainerOuter2>
          </Stack>
          <Seperator height="48px" />
        </div>
      </InnerContainer>
    );
  },
)`
  @media (max-width: 922px) {
    .innerContainer {
      margin: 0 auto;
      max-width: 420px;
    }
  }

  a {
    line-height: inherit;
    color: ${M.COL.TEXT.BLACK};
  }

  ol {
    font-family: ${M.FONT};
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    padding: 0 0 0 22px;

    a {
      font-weight: 400;
    }
  }
`;

const PriceDropAlertsSection = styled(({ className, style, isDesktop }) => {
  return (
    <div className={className} style={style}>
      <Stack
        className="PriceDropAlertsSection-innerContainer"
        direction="column"
        alignItems="center"
      >
        <div className="PriceDropAlertsSection-topContainer">
          <Typography
            className="PriceDropAlertsSection-heading"
            variant="editorial.sb34"
            component="h2"
          >
            Get{' '}
            <span className="PriceDropAlertsSection-priceDropCopy">
              price drop
            </span>{' '}
            alerts
          </Typography>
          <Typography
            className="PriceDropAlertsSection-paragraph"
            variant="primary.l20"
            component="p"
          >
            We'll track price drops on items you save to your wishlists.
            <br />
            We track prices on{' '}
            <Link className="button" style={style} to={R.RETAILERS_AZ}>
              2,000+ stores
            </Link>
            .
          </Typography>
          <CtaButton isDesktop={isDesktop}>
            <CTAButtonTextBold key="bold-button-text" isDesktop={isDesktop}>
              Get started&nbsp;
            </CTAButtonTextBold>
            <CTAButtonTextLight isDesktop={isDesktop} key="light-button-text">
              - it&apos;s free
            </CTAButtonTextLight>
          </CtaButton>
        </div>
        <div className="PriceDropAlertsSection-bottomContainer">
          <picture>
            <source
              media="(max-width: 922px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/landing/price-notifications-mobile.png`}
            />
            <img
              className="PriceDropAlertsSection-image"
              src={`${process.env.REACT_APP_STATIC_URL}/landing/price-notifications.png`}
              alt="Price drop alert notifications"
            />
          </picture>
        </div>
      </Stack>
    </div>
  );
})`
  padding: 52px 16px 84px;

  .PriceDropAlertsSection-innerContainer {
    margin: 0 auto;
    max-width: 992px;
  }

  .PriceDropAlertsSection-topContainer {
    max-width: 360px;
    margin-bottom: 32px;
  }

  .PriceDropAlertsSection-image {
    width: 100%;
    max-width: 490px;
  }

  .PriceDropAlertsSection-priceDropCopy {
    color: var(--foreground-green);
  }

  .PriceDropAlertsSection-paragraph {
    margin-bottom: 24px;
  }

  @media (min-width: 922px) {
    padding: 96px 16px 84px;

    .PriceDropAlertsSection-innerContainer {
      flex-direction: row-reverse;
    }

    .PriceDropAlertsSection-topContainer {
      margin-left: 62px;
      width: 50%;
    }

    .PriceDropAlertsSection-bottomContainer {
      margin-right: 62px;
      width: 50%;
    }
  }
`;

const UnstyledSEOContentSection = ({ className, style }) => {
  return (
    <section className={className} style={style}>
      <div className="SEOContentSection-content">
        <Typography variant="editorial.sb34" component="h2">
          The ultimate Chrome extension wishlist maker
        </Typography>
        <Typography variant="primary.l20" component="p">
          Having a wishlist extension makes keeping track of all the different
          products you're shopping for easier than ever before. Instead of using
          separate wish lists on each retailer, when you use our Chrome add-on,
          everything will be saved to your own personal wishlist website. Which
          you can access from any device.
        </Typography>
        <Typography variant="primary.b26" component="h3">
          How to use Moonsift's wishlist extension:{' '}
        </Typography>
        <Typography variant="primary.l20" component="ol">
          <li className="SEOContentSection-listItems">
            Add the{' '}
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              browser extension
            </Link>{' '}
            to your browser with the click of a button.
          </li>
          <li className="SEOContentSection-listItems">
            Save products from any retailer simply by tapping the Moonsift icon
            in your toolbar.
          </li>
          <li className="SEOContentSection-listItems">
            Access all your saved items from any device. Moonsift.com acts as
            your very own universal wishlist website.
          </li>
          <li className="SEOContentSection-listItems">
            Share your wishlists using a simple link.
          </li>
        </Typography>
        <Typography variant="primary.l20" component="p">
          <Link to={R.HOW_TO_SAVE}>Learn more</Link> about how to add items from
          any store using our browser extension and iOS mobile wishlist app.
        </Typography>
      </div>
    </section>
  );
};

const SEOContentSection = styled(UnstyledSEOContentSection)`
  padding: 68px 16px;

  .SEOContentSection-listItems {
    margin-bottom: 8px;
    margin-left: 24px;
    padding-left: 6px;
  }

  .SEOContentSection-content {
    max-width: 328px;
    margin: 0 auto;

    > h2 {
      margin-bottom: 12px;
    }

    > h2 + p {
      margin-bottom: 24px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px;

    .SEOContentSection-content {
      max-width: 800px;

      > h2 + p {
        margin-bottom: 24px;
      }
    }
  }
`;

class UnstyledLanding extends Component {
  static contextType = ServicesContext;
  constructor() {
    super();
    this.state = {
      is_loaded: false,
      landing_collection_list: [],
    };
    this.collection_state_assign_func =
      this.collection_state_assign_func.bind(this);
    this.handleAddChromeExtension = this.handleAddChromeExtension.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateTheDataOnce();
  }

  componentDidUpdate() {
    this.updateTheDataOnce();
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_all_listeners(this.state.collections);
  }

  collection_state_assign_func(new_dict) {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          collections: nested_state_assignment(prevState.collections, new_dict),
        };
      });
  }

  updateTheDataOnce() {
    if (this.props.firebaseState && this.props.firebaseState.loaded) {
      this.setState((prevState, props) => {
        // makes this only run once? they are equal after the first time you load the page so updateTheDataOnce doesn't end up populating new collections
        if (
          prevState.auth_user_uid !== props.authUserUID ||
          prevState.current_explore_type !== props.explore_type
        ) {
          // get the collection info JUST ONCE, hence NOT bothering with redux for now:
          get_explore_collections(
            'landing',
            props.firebase,
            props.authUserUID,
          ).then((response) => {
            // split by big and small tiles:
            const like_listeners_dict = get_collection_likes(
              response.collections,
              this.props.firebase,
              this.props.authUserUID,
              this.collection_state_assign_func,
            ); // WILL ALSO CALL AN ASYNC FUNCTION WHEN LIKES DETERMINED!
            var collections_with_listeners = nested_state_assignment(
              response.collections,
              like_listeners_dict,
            );
            const image_getters = get_collection_images(
              response.collections,
              this.props.firebase,
              this.collection_state_assign_func,
            );
            collections_with_listeners = nested_state_assignment(
              collections_with_listeners,
              image_getters,
            );
            this._isMounted &&
              this.setState((prevState) => {
                return {
                  is_loaded: true,
                  landing_collection_list: response.collections_sorted,
                  collections: nested_state_assignment(
                    prevState.collections,
                    collections_with_listeners,
                  ),
                  public_info: response.public_info,
                };
              });
          });
          return {
            auth_user_uid: this.props.authUserUID,
            is_loaded: false,
          };
        }
      });
    }
  }

  CtaButtonText(ISDESKTOP) {
    return [
      <CTAButtonTextBold key="bold-button-text" isDesktop={ISDESKTOP}>
        Get started&nbsp;
      </CTAButtonTextBold>,
      <CTAButtonTextLight isDesktop={ISDESKTOP} key="light-button-text">
        - it&apos;s free
      </CTAButtonTextLight>,
    ];
  }

  renderCarousel() {
    if (this.state.is_loaded) {
      return (
        <CollectionCarousel
          collections_list={this.state.landing_collection_list}
          collections={this.state.collections}
          public_info={this.state.public_info}
          auth_user_uid={this.state.auth_user_uid}
        />
      );
    } else {
      return '';
    }
  }

  renderStars() {
    return (
      <StarContainer>
        {_.times(5, (i) => {
          return <Star key={i} src={MEDIA.RATING_STAR} />;
        })}
      </StarContainer>
    );
  }

  handleAddChromeExtension() {
    const { analytics } = this.context;
    analytics.recordEvent('WebApp:ChromeWebstore:Pressed');
  }

  render() {
    const ISDESKTOP = this.props.windowWidth >= M.MOBILESWITCH;
    return (
      <Container className={clsx('Container', this.props.className)}>
        <TopMenu showTastemaker={true} />
        <Helmet>
          <title>
            Universal Wishlist | Create your Online Wishlist | Moonsift
          </title>
          <meta
            name="description"
            content="Save products from any store in the world. Keep track of everything you're shopping for in one place. Create your free online Wishlist. Track price changes. Share anywhere."
          />
        </Helmet>
        <Seperator height="56px" />
        <FirstRow isDesktop={ISDESKTOP} className="FirstRow">
          <TopImageContainer
            isDesktop={ISDESKTOP}
            className="topImageContainer"
          >
            {this.props.windowWidth > 500 ? (
              <BannerImage />
            ) : (
              <BannerImageSmall />
            )}
          </TopImageContainer>
          <FirstRowColumn isDesktop={ISDESKTOP} className="firstRowColumn">
            <TextContainer isDesktop={ISDESKTOP} className="textContainer">
              <Typography
                className="Landing-headingSectionHeading"
                variant="editorial.r46"
                component="p"
              >
                <span>100 tabs</span> One wishlist for all stores
              </Typography>
              <Seperator height="10px" />
              <Section1Subtitle>
                Make all that browsing count. Discover, save and curate
                shoppable collections with Moonsift's universal wishlist.
              </Section1Subtitle>
              <Seperator height="30px" />
              <CtaButton isDesktop={ISDESKTOP}>
                <CTAButtonTextBold key="bold-button-text" isDesktop={ISDESKTOP}>
                  Get started&nbsp;
                </CTAButtonTextBold>
                <CTAButtonTextLight
                  isDesktop={ISDESKTOP}
                  key="light-button-text"
                >
                  - it&apos;s free
                </CTAButtonTextLight>
              </CtaButton>
            </TextContainer>
          </FirstRowColumn>
        </FirstRow>
        <Seperator height={ISDESKTOP ? '48px' : '55px'} />
        <MaxWidthContainer isDesktop={ISDESKTOP} className="MaxWidthContainer">
          <Instructions
            isDesktop={ISDESKTOP}
            handleAddChromeExtension={this.handleAddChromeExtension}
          />
        </MaxWidthContainer>
        <GreyBG>
          <MaxWidthContainer
            isDesktop={ISDESKTOP}
            className="MaxWidthContainer"
          >
            <FourthRow className="fourthRow" isDesktop={ISDESKTOP}>
              <TextContainerOuter
                className="TextContainerOuter"
                isDesktop={ISDESKTOP}
              >
                <TextContainer className="TextContainer">
                  <Seperator height="32px" />
                  <BottomText className="BottomText">
                    <Typography variant="editorial.sb34" component="h2">
                      Curate and share collections
                    </Typography>
                    <FourthRowText>
                      Moonsift makes it easy to compare, organise and keep track
                      of all your favourite products in one place.
                    </FourthRowText>
                    <Seperator height="30px" />
                    <CtaButton isDesktop={ISDESKTOP}>
                      <CTAButtonTextBold
                        key="bold-button-text"
                        isDesktop={ISDESKTOP}
                      >
                        Get started&nbsp;
                      </CTAButtonTextBold>
                      <CTAButtonTextLight
                        isDesktop={ISDESKTOP}
                        key="light-button-text"
                      >
                        - it&apos;s free
                      </CTAButtonTextLight>
                    </CtaButton>
                  </BottomText>
                  <Seperator height="32px" />
                </TextContainer>
              </TextContainerOuter>
              <VideoContainer2 isDesktop={ISDESKTOP}>
                <Seperator height="20px" />
                <Video
                  src={MEDIA.LANDING_VIDEO_2}
                  loop
                  autoPlay
                  muted
                  playsInline
                />
              </VideoContainer2>
            </FourthRow>
            <Seperator
              height={ISDESKTOP ? '90px' : '50px'}
              style={{ backgroundColor: `${M.COL.BG.LIGHT}` }}
            />
          </MaxWidthContainer>
        </GreyBG>
        <PriceDropAlertsSection isDesktop={ISDESKTOP} />
        <GreyBG>
          <MaxWidthContainer
            isDesktop={ISDESKTOP}
            className="MaxWidthContainer"
          >
            <SecondRow isDesktop={ISDESKTOP} className="secondRow">
              <SecondRowInner className="secondRowInner">
                <Seperator height={ISDESKTOP ? '89px' : '44px'} />
                <SecondRowTitle isDesktop={ISDESKTOP}>
                  <Typography variant="editorial.sb34" component="h2">
                    Read what people are saying{this.renderStars()}
                  </Typography>
                </SecondRowTitle>
                <Seperator height="28px" />
                <ReviewsCarousel type="landing" />
                <Seperator
                  height={'64px'}
                  style={{ backgroundColor: `${M.COL.BG.LIGHT}` }}
                />
                <CtaButton isDesktop={ISDESKTOP}>
                  <CTAButtonTextBold
                    key="bold-button-text"
                    isDesktop={ISDESKTOP}
                  >
                    Get started&nbsp;
                  </CTAButtonTextBold>
                  <CTAButtonTextLight
                    isDesktop={ISDESKTOP}
                    key="light-button-text"
                  >
                    - it&apos;s free
                  </CTAButtonTextLight>
                </CtaButton>
                <Seperator
                  height={'112px'}
                  style={{ backgroundColor: `${M.COL.BG.LIGHT}` }}
                />
              </SecondRowInner>
            </SecondRow>
          </MaxWidthContainer>
        </GreyBG>
        <MaxWidthContainer isDesktop={ISDESKTOP} className="MaxWidthContainer">
          <Seperator height={ISDESKTOP ? '96px' : '55px'} />
          <SecondRow isDesktop={ISDESKTOP} className="secondRow">
            <SecondRowInner className="secondRowInner">
              <SecondRowTitle isDesktop={ISDESKTOP}>
                <Typography variant="editorial.sb34" component="h2">
                  Explore public collections
                </Typography>
              </SecondRowTitle>
              <Seperator height={'28px'} />
              {this.renderCarousel()}
            </SecondRowInner>
          </SecondRow>
          {ISDESKTOP ? <Seperator height="88px" /> : ''}
        </MaxWidthContainer>
        <GreyBG>
          <MaxWidthContainer
            isDesktop={ISDESKTOP}
            className="MaxWidthContainer"
          >
            <Stack className="Landing-useCasesSection">
              <Typography
                className="Landing-useCasesSectionHeading"
                variant="editorial.r26"
                component="h2"
              >
                Create gift lists on a beautiful, easy to use platform, whatever
                the occasion
              </Typography>
              <UseCases hidden={['wishlist']} />
            </Stack>
          </MaxWidthContainer>
        </GreyBG>
        <SEOContentSection />
        <BottomBar />
      </Container>
    );
  }
}

const Landing = styled(UnstyledLanding)`
  .Landing-headingSectionHeading {
    margin-bottom: 0;

    > span {
      display: none;
    }
  }

  .Landing-useCasesSectionHeading {
    max-width: 380px;
    margin: 0 auto 12px;
  }

  .Landing-useCasesSection {
    margin: 100px 0px;
    text-align: center;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .Landing-headingSectionHeading {
      ${({ theme }) => theme.fns.getTypographyStyles('editorial.r68')}

      > span {
        display: inline;
        text-decoration: line-through;
      }
    }
  }
`;

const mapStateToProps = (state) => {
  return {
    firebaseState: state.firebasestate,
    authUserUID: state.auth.id,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFirebase(windowSize(Landing)));
