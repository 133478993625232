/*

This component displays collection tiles in a list (surprise surprise!)

Remember that "liked" collections are now termed "bookmarked"

It is used in the profile view.  It also has the "new collection" tile that should be moved into its own component

TODO:  make functional, use design system, use camelCase, split into container and component, convert to typescript.
Will also need to add the db hooks for the collection list (not authuser as those will already be there) but for other user + liked collections.
Will for all however need to add hooks for the display of images, but maybe this is best done in CollectionTile itself...
Also change "liked" to "bookmarked"
Should make a really nice refactoring project.


Move activeTab selection in here?

*/
import { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Modal } from '@moonsifttech/design-system';
import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { withFirebase } from 'src/mvp22/Firebase';
import CollectionTile from 'src/mvp22/tile-components/CollectionTile';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import MEDIA from 'src/mvp22/media';
import R from 'src/routes';
import get_collection_images from 'src/mvp22/firebase-functions/get_collection_images';
import get_user_profile_public_collections from 'src/mvp22/firebase-functions/get_user_profile_public_collections';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import get_liked_collections from 'src/mvp22/firebase-functions/get_liked_collections';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import remove_all_listeners from 'src/mvp22/core-components/remove_all_listeners';
import { modalSet } from 'src/mvp22/redux-components/actions';
import { CreateCollectionDialog } from 'src/components/common/CreateCollectionDialog';
import { AppGetStartedDialog } from 'src/components/profile/GetStartedDialog';
import { DesktopGetStartedDialog } from 'src/components/profile/GetStartedDialog';
import { MobileWebGetStartedDialog } from 'src/components/profile/GetStartedDialog';
import { GetAppDialog } from 'src/components/profile/GetAppDialog';
import { ReferProDialog } from 'src/components/profile/ReferProDialog';
import { CentralHeading3, CentralText3, BoldLink } from './ProductTileList';
import windowSize from 'src/mvp22/WindowSize';
import { ServicesContext } from 'src/ServicesContext';
import { checkIfIOS } from 'src/utils/checkIfIOS';

const isIOS = checkIfIOS();

const EmptyMessageContainer = styled.div`
  display: block;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  box-sizing: border-box;
  position: relative;
  max-width: 360px;
  height: 345px;
  align-items: center;
  margin: ${(props) =>
    props.isDesktop === true ? '20px 20px 0px 20px' : '0px 20px 0px 20px'};
`;

const PlusIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0002 4.16748V15.8341"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.16675 9.99976H15.8334"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PlusIconContainer = styled.div`
  border: 1px solid #000000;
  box-sizing: border-box;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NewCollectionTileMarginBooster = styled.div`
  padding: 14px;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 21px;
  }
`;

const CollectionTileMarginBooster = styled.div`
  border-radius: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  }
  box-sizing: border-box;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin: 7px;
  }
`;

const EmptyCollectionTile = styled.div`
  height: 220px;
  width: 313px;
  padding: 21px;
`;

const EmptyCollectionImg = styled.img`
  display: block;
  height: 80px;
  width: 80px;
  margin: 0 auto;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

// Used in post install page as well:
export const NewCollectionTile = styled(TEXTSTYLE.LINKA)`
  border: 1px dashed ${M.COL.LINE.MID};
  border-radius: 5px;
  height: 220px;
  width: 313px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${M.COL.BG.WHITE};
  &:hover {
    border: 1px dashed ${M.COL.BG.BLACK};
    svg {
      path {
        stroke-width: 1.5;
      }
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 708 })} {
    height: 58px;
  }
`;

const StyledAppGetStartedDialog = styled(AppGetStartedDialog)`
  box-sizing: border-box !important;
  margin: 14px;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin: 22px;
  }

  * {
    box-sizing: border-box !important;
  }
`;

const StyledDesktopGetStartedDialog = styled(DesktopGetStartedDialog)`
  box-sizing: border-box !important;
  margin: 14px;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin: 22px;
  }

  * {
    box-sizing: border-box !important;
  }
`;

const StyledMobileWebGetStartedDialog = styled(MobileWebGetStartedDialog)`
  box-sizing: border-box !important;
  margin: 14px;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin: 22px;
  }

  * {
    box-sizing: border-box !important;
  }
`;

const StyledGetAppDialog = styled(GetAppDialog)`
  box-sizing: border-box !important;
  margin: 14px;

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin: 22px;
  }

  * {
    box-sizing: border-box !important;
  }
`;

class CollectionTileList extends Component {
  static contextType = ServicesContext;

  constructor(props) {
    super(props);
    this.state = {
      user_uid: null,
      auth_user_uid: null,
      type: null,
      profile_is_own: false,
      isReferProModalOpen: false,
      isCreateCollectionModalOpen: false,
    };
    this.collection_state_assign_func =
      this.collection_state_assign_func.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_all_listeners(this.state);
  }

  // This function runs when props / state changed so we only want to futher update the state
  // when it has been changed to a different view or profile user etc.
  componentDidUpdate() {
    this.setState((prevState, props) => {
      const user_uid_change =
        props.profileUserUID !== prevState.user_uid ? true : false;
      const user_auth_change =
        props.authUserUID !== prevState.auth_user_uid ? true : false;
      const type_change = props.activeTab !== prevState.type ? true : false;
      // temporary fix for slow loading of firestore_user_collection_list that blocked loading of images - will be dealt with in refactor.
      const collections_sorted_empty =
        this.props.firestore_user_collection_list.collections_sorted.length ===
        0;
      const collections_sorted_empty_change =
        prevState.collections_sorted_empty !== collections_sorted_empty;
      // Don't do anything until we know if the user is logged in or not:
      if (
        user_auth_change ||
        user_uid_change ||
        type_change ||
        collections_sorted_empty_change
      ) {
        remove_all_listeners(this.state);
        const state_to_return = {
          auth_user_uid: this.props.authUserUID,
          collections_sorted_empty,
          user_uid: this.props.profileUserUID,
          type: this.props.activeTab,
          is_loaded: false,
          collections: {},
        };
        // We might need to get some more state to set within this state setting function:
        // This is usually only if we don't need to fetch the data.
        var extra_state = {};
        // Populate the state with the appropraite type of data (asynchronously)
        switch (this.props.activeTab) {
          case 'all_user_collections':
            // CASE is viewing own collection and is logged in:
            if (props.profileUserUID === props.authUserUID) {
              extra_state = this.updateOwnCollectionDataOnce();
            }
            // CASE is viewing someone else's collections:
            else if (
              this.props.profileUserUID &&
              this.props.profileUserUID !== ''
            ) {
              extra_state = this.updateTheOtherUserDataOnce(
                this.props.profileUserUID,
                this.props.authUserUID,
              );
            }
            extra_state.profile_is_own =
              props.profileUserUID === props.authUserUID;
            break;
          case 'bookmarked':
            extra_state = this.updateLikedCollectionsOnce(
              this.props.authUserUID,
            );
            extra_state.profile_is_own = true;
            break;
          case 'purchased':
            extra_state = this.updatedPurchasedCollectionOnce(
              this.props.authUserUID,
            );
            extra_state.profile_is_own = true;
            break;
          default:
            console.error('Not a valid active tab', this.props.activeTab);
        }
        // set all the state:
        return Object.assign(state_to_return, extra_state);
      }
    });
  }

  // Only call after an async firebase listener updates please:
  // This adds to the dictionary state the images (or other data e,g, liked collections)
  collection_state_assign_func(new_dict) {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          collections: nested_state_assignment(prevState.collections, new_dict),
        };
      });
  }

  // Pull the current user's collections that are stored in redux due to the ability to add to collections from all over the site (i.e. other collections):
  // Note that this list of collections will, however, update live BUT the images will not:
  // And to save having to fetch them all every time.
  updateOwnCollectionDataOnce() {
    // Convert into something this function will understand:
    const collections_dict = {};
    this.props.firestore_user_collection_list.collections_sorted.forEach(
      (collection_entry) => {
        collections_dict[collection_entry.global_uid] = {
          collection_entry: collection_entry,
        };
      },
    );
    const image_getters = get_collection_images(
      collections_dict,
      this.props.firebase,
      this.collection_state_assign_func,
    );
    return {
      is_loaded: true,
      collections: image_getters,
    };
  }

  // Get the other user's data and if we like any of those collections (which is a pain) and
  // due to the need for listeners:
  // of course the images...:
  updateTheOtherUserDataOnce(profile_user_uid, auth_user_uid) {
    //ASYNC
    // get the collection info JUST ONCE, hence NOT bothering with redux for now:
    get_user_profile_public_collections(
      profile_user_uid,
      this.props.firebase,
      auth_user_uid,
    ).then((response) => {
      const like_listeners_dict = get_collection_likes(
        response.collections,
        this.props.firebase,
        auth_user_uid,
        this.collection_state_assign_func, // WILL ALSO CALL AN ASYNC FUNCTION AS AND WHEN LIKES DETERMINED / UPDATED!
      );
      const collections_with_like_listeners = nested_state_assignment(
        response.collections,
        like_listeners_dict,
      );
      this._isMounted &&
        this.setState((prevState) => {
          const image_getters = get_collection_images(
            collections_with_like_listeners,
            this.props.firebase,
            this.collection_state_assign_func,
          );
          const collections_with_image_getters = nested_state_assignment(
            response.collections,
            image_getters,
          );
          return {
            collections_sorted: response.collections_sorted,
            is_loaded: true,
            collections: nested_state_assignment(
              prevState.collections,
              collections_with_image_getters,
            ),
          };
        });
    });
    return {};
  }

  // Do NOT listen on this list for now...:
  // NOTE: Might need to change how we set is_loaded to make sure we have all the liked collections loaded (that still exist / are accessible)...
  // Should be able to do with a resolver.
  updateLikedCollectionsOnce(auth_user_uid) {
    if (auth_user_uid) {
      //ASYNC
      // get the collection info JUST ONCE, hence NOT bothering with redux for now:
      get_liked_collections(this.props.firebase, auth_user_uid).then(
        (response) => {
          this._isMounted &&
            this.setState((prevState) => {
              //ASYNC
              const image_getters = get_collection_images(
                response.collections,
                this.props.firebase,
                this.collection_state_assign_func,
              );
              const collections_with_image_getters = nested_state_assignment(
                response.collections,
                image_getters,
              );
              return {
                collections_sorted: response.collections_sorted,
                is_loaded: true,
                public_info: response.public_info,
                collections: nested_state_assignment(
                  prevState.collections,
                  collections_with_image_getters,
                ),
              };
            });
        },
      );
    }
    return {};
  }

  updatedPurchasedCollectionOnce(auth_user_uid) {
    if (auth_user_uid) {
      // The deetz of the purchased collection are standardised across the users:
      const collections = {
        [auth_user_uid + '__purchased']: {
          collection_entry: {
            collection_uid: 'purchased',
            uid: 'purchased',
            user_uid: auth_user_uid,
            name: 'Purchased items',
          },
        },
      };
      const collections_sorted = [auth_user_uid + '__purchased'];
      const image_getters = get_collection_images(
        collections,
        this.props.firebase,
        this.collection_state_assign_func,
      );
      return {
        collections_sorted: collections_sorted,
        collections: nested_state_assignment(collections, image_getters),
        is_loaded: true,
      };
    }
    return {};
  }

  onCreateCollectionModalOpen = () => {
    this.setState({ isCreateCollectionModalOpen: true });
  };

  onCreateCollectionModalClose = () => {
    this.setState({ isCreateCollectionModalOpen: false });
  };

  renderAddTile(isDesktop) {
    const { profile_is_own: isOwn, isCreateCollectionModalOpen } = this.state;
    // check if user is looking at their own profile and conditionally render AddNewTileContainer
    if (isOwn) {
      return (
        <>
          <NewCollectionTileMarginBooster>
            <NewCollectionTile
              onClick={this.onCreateCollectionModalOpen}
              href="#"
            >
              <PlusIconContainer>
                <PlusIcon />
              </PlusIconContainer>
              <TEXTSTYLE.BODY4>&nbsp;&nbsp;New Collection</TEXTSTYLE.BODY4>
            </NewCollectionTile>
          </NewCollectionTileMarginBooster>
          <Modal
            type="modal"
            isOpen={isCreateCollectionModalOpen}
            onClose={this.onCreateCollectionModalClose}
          >
            <CreateCollectionDialog
              onClose={this.onCreateCollectionModalClose}
            />
          </Modal>
          {this.renderGetStartedDialog(isDesktop)}
        </>
      );
    } else {
      return null;
    }
  }

  toggleReferProModal = () => {
    this.setState((prevState) => ({
      isReferProModalOpen: !prevState.isReferProModalOpen,
    }));
  };

  getEmptyMessage(ISDESKTOP) {
    if (this.props.activeTab === 'bookmarked') {
      return (
        <EmptyMessageContainer
          isDesktop={ISDESKTOP}
          className="EmptyMessageContainer"
        >
          <Seperator height="20px" />
          <EmptyCollectionImg src={MEDIA.NO_BOOKMARKED_COLLECTIONS} />
          <CentralHeading3>No bookmarked collections</CentralHeading3>
          <CentralText3>
            When you Bookmark other people&apos;s collections, they will be
            saved here.
          </CentralText3>
          <CentralText3>
            <BoldLink to={R.EXPLORE_FEATURED}>Click here</BoldLink> to explore
            public collections
            <br />
          </CentralText3>
        </EmptyMessageContainer>
      );
    }
    if (
      this.props.activeTab === 'all_user_collections' &&
      this.state.profile_is_own !== true
    ) {
      return (
        <EmptyMessageContainer
          isDesktop={ISDESKTOP}
          className="EmptyMessageContainer"
        >
          <EmptyCollectionImg src={MEDIA.NO_PUBLIC_COLLECTIONS} />
          <CentralHeading3>No public collections.</CentralHeading3>
          <CentralText3>
            The collections a user shares privately will not appear on their
            public profile.
          </CentralText3>
          <CentralText3>
            <BoldLink to={R.EXPLORE_FEATURED}>Click here</BoldLink> to explore
            other public collections on Moonsift.
            <br />
          </CentralText3>
        </EmptyMessageContainer>
      );
    }
  }

  renderGetStartedDialog(isDesktop) {
    const { mobile } = this.context;
    const { appGetStartedAllSet, desktopGetStartedAllSet } = this.props;

    if (mobile.isApp && !appGetStartedAllSet) {
      return <StyledAppGetStartedDialog onAllSet={this.toggleReferProModal} />;
    }

    if (!mobile.isApp && isIOS) {
      return <StyledGetAppDialog />;
    }

    if (!mobile.isApp && isDesktop && !desktopGetStartedAllSet) {
      return (
        <StyledDesktopGetStartedDialog onAllSet={this.toggleReferProModal} />
      );
    }

    if (!mobile.isApp && !isDesktop && !desktopGetStartedAllSet) {
      return (
        <StyledMobileWebGetStartedDialog onAllSet={this.toggleReferProModal} />
      );
    }

    return null;
  }

  renderTiles(ISDESKTOP) {
    if (this.state.is_loaded) {
      // Switch to select list in case in future we want to reduce database calls by saving them to different lists and not refreshing...
      var this_render_list = this.state.collections_sorted;
      var this_render_dict = this.state.collections;
      // EXCEPT if we are rendering from redux:
      if (
        this.state.type === 'all_user_collections' &&
        this.state.profile_is_own
      ) {
        // TODO: Move this formatting into redux part to match the format here as this is expensive:
        const temp_collections_dict = {};
        this_render_list = [];
        this.props.firestore_user_collection_list.collections_sorted
          .filter((x) => x.uid !== 'purchased')
          .forEach((x) => {
            temp_collections_dict[x.global_uid] = {
              collection_entry: x,
            };
            this_render_list.push(x.global_uid);
          });
        this_render_dict = nested_state_assignment(
          this.state.collections,
          temp_collections_dict,
        );
      }

      if (this_render_list && this_render_list.length === 0) {
        return this.getEmptyMessage(ISDESKTOP);
      }

      if (this_render_list && this_render_dict) {
        // Hack to make things left-align on desktop in most cases:
        const EXTRA_TILES =
          this_render_list.length > 0 ? (
            <>
              <EmptyCollectionTile key={1} />
              <EmptyCollectionTile key={2} />
            </>
          ) : (
            ''
          );
        return (
          <>
            {this_render_list.map((general_uid) => (
              <CollectionTileMarginBooster key={general_uid}>
                <CollectionTile
                  data={this_render_dict[general_uid]}
                  user_public_info={
                    this.state.type === 'bookmarked'
                      ? this.state.public_info &&
                        this.state.collections &&
                        this.state.collections[general_uid] &&
                        this.state.collections[general_uid].collection_entry
                        ? this.state.public_info[
                            this.state.collections[general_uid].collection_entry
                              .user_uid
                          ]
                        : ''
                      : this.props.public_info
                  }
                  display_name={this.state.type === 'bookmarked'}
                  show_edit={this.state.type === 'all_user_collections'}
                  isLarge={false}
                />
              </CollectionTileMarginBooster>
            ))}
            {/* {this.state.type === 'all_user_collections' &&
              this.state.profile_is_own &&
              this.renderGetStartedDialog(ISDESKTOP)} */}
            {this.state.type === 'all_user_collections' &&
              this.state.profile_is_own &&
              !this.props.isPro && (
                <Modal
                  isOpen={this.state.isReferProModalOpen}
                  onRequestClose={this.toggleReferProModal}
                >
                  <ReferProDialog onClose={this.toggleReferProModal} />
                </Modal>
              )}
            {EXTRA_TILES}
          </>
        );
      }
    }
    return <LoadingDisplay message="Loading Collections..." />;
  }

  render() {
    const ISDESKTOP = this.props.windowWidth >= M.MOBILESWITCH;
    // !!check if user is looking at their own profile and conditionally render AddNewTileContainer
    return (
      <>
        {this.state.type === 'all_user_collections'
          ? this.renderAddTile(ISDESKTOP)
          : ''}
        {this.renderTiles(ISDESKTOP)}
      </>
    );
  }
}

// Pass the state values into the component:
const mapStateToProps = (state, props) => {
  return {
    firestore_user_collection_list: state.firestore_user_collection_list,
    authUserUID: state.auth.id,
    public_info: state?.db.user_public.map[props.profileUserUID],
    appGetStartedAllSet: state.firestore_user_owner.snapshot
      ?.app_get_started_all_set
      ? true
      : false,
    desktopGetStartedAllSet: state.firestore_user_owner.snapshot
      ?.desktop_get_started_all_set
      ? true
      : false,
    isPro: state.firestore_user_owner?.pro ? true : false,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: (data) => dispatch(modalSet(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFirebase(windowSize(CollectionTileList)));
