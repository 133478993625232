/*
Basic function to get the next free index.
(Used for subsection indexing)

*/
const getNextFreeIndex = (list_of_indices: number[]): number => {
  let i = 0;
  while (i < 100000) {
    if (!list_of_indices.includes(i)) {
      return i;
    }
    i++;
  }
  return -1;
};
export default getNextFreeIndex;
