import React from 'react';
import { RateItemProps } from './RateItem.types';
import { Stack, Typography, CtaButton } from '@moonsifttech/design-system';

import styled from 'styled-components';
import { BagHeart } from 'src/components/reviews/BagHeart';
import { EXTERNAL } from 'src/routes';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
const UnstyledRateItem: React.FC<RateItemProps> = ({
  onRateUsClick,
  className,
  style,
  type,
}) => {
  return (
    <Stack
      className={className}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <BagHeart />
      <Typography
        className="RateItem-questionHeading"
        variant="primary.b26"
        component="h3"
      >
        Could you please do us a tiny favour?
      </Typography>
      <Typography
        className="RateItem-mainText"
        variant="primary.l20"
        component="p"
      >
        {type === 'extension'
          ? `Support Moonsift by leaving us a review in the Chrome Web Store.`
          : `Would you be be willing to support us by leaving a review in the App Store?`}
      </Typography>

      <CtaButton
        variant={type === 'extension' ? 'primary-white' : 'primary-black'}
        onClick={onRateUsClick}
        fullWidth
        to={type === 'extension' ? '' : EXTERNAL.APP_STORE_APP}
        target="_blank"
        component={type === 'extension' ? undefined : UnstyledLink}
      >
        {type === 'extension' ? `Rate us on Chrome` : `Rate us`}
      </CtaButton>
    </Stack>
  );
};

export const RateItem = styled(UnstyledRateItem)`
  text-align: left;

  .RateItem-questionHeading {
    width: 100%;
  }

  .RateItem-mainText {
    margin-bottom: 50px;
    width: 100%;
  }
`;
