import { VFC, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import R from 'src/routes';
import { RootState } from 'src/index';
import {
  CreatorDashboardContainerProps,
  CreatorDashboardProps,
} from './CreatorDashboard.types';
import { CreatorDashboard } from './CreatorDashboard.component';
import { ServicesContext } from 'src/ServicesContext';

type RequestApprovalResponse =
  | {
      success: true;
    }
  | { success: false; error: string };

const CreatorDashboardContainer: VFC<CreatorDashboardContainerProps> = (
  props,
) => {
  const history = useHistory();
  const [requestApprovalWorking, setRequestApprovalWorking] = useState(false);
  const [requestApprovalError, setRequestApprovalError] = useState('');

  const { cloud } = useContext(ServicesContext);

  // Retrieve creator status
  const creatorStatus = useSelector<
    RootState,
    CreatorDashboardProps['creatorStatus']
  >(({ db }) => db.user_verified_settings.data?.creator?.status);

  const handleBack = useCallback<CreatorDashboardProps['onBack']>(() => {
    history.push(R.HOME);
  }, [history]);

  const handleRequestApproval = useCallback<
    CreatorDashboardProps['handleRequestApproval']
  >(async () => {
    setRequestApprovalWorking(true);
    setRequestApprovalError('');
    try {
      const result = (await cloud.fastAPI2('requestAcceptance', {}))
        .data as RequestApprovalResponse;
      if (result.success === false) {
        setRequestApprovalError(result.error);
      }
    } catch (err) {
      console.error(err);
      setRequestApprovalError('Unable to reach Moonsift.');
    } finally {
      setRequestApprovalWorking(false);
    }
  }, [cloud]);

  return (
    <CreatorDashboard
      creatorStatus={creatorStatus}
      onBack={handleBack}
      handleRequestApproval={handleRequestApproval}
      requestApprovalError={requestApprovalError}
      requestApprovalWorking={requestApprovalWorking}
      {...props}
    />
  );
};

export { CreatorDashboardContainer as CreatorDashboard };
