import React, { useEffect, useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ProductTileList } from 'src/mvp22/tile-components/ProductTileList';
import { ProductTileListContainerProps } from './ProductTileList.types';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { useLocation } from 'react-router-dom';
// Hooks:
import { collectionViewsParser } from 'src/store/parsers/collectionViews';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import {
  ActionNames,
  ModalTypes,
  SetShowReviewRequests,
  Status,
} from 'src/types';
import { useModal } from 'src/hooks/useModal';
import { useLoadedProducts } from './useLoadedProducts';
import { useSearchParams } from 'src/hooks/useSearchParams';
import R from 'src/routes';
import { useHasExtension } from 'src/hooks/useHasExtension';
//import { useTheme } from 'styled-components';

const Container: React.FC<ProductTileListContainerProps> = ({
  collectionUID,
  collectionUserUID,
  organisingHook,
  goToAddItem,
}) => {
  const extensionInstalled = useHasExtension();
  // Non-DB Redux Selectors:
  const modalType = useSelector<RootState, ModalTypes | null>(
    (state) => state.modal.type,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const creatorStatus = useSelector<RootState, Status | undefined>(
    (state) => state.db.user_verified_settings.data?.creator.status,
  );
  const isCreator = creatorStatus === 'accepted' || creatorStatus === 'applied';
  //TODO: change these on refactoring:
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  // Mobile or not:
  // const theme = useTheme();
  // const mobileBreakpoint = theme.breakpoints.md;
  // const isMobile = useSelector<RootState, boolean>(
  //   ({ ui }) => ui.windowWidth < mobileBreakpoint,
  // );
  // OTHER HOOKS:
  const history = useHistory();
  const location = useLocation();
  const [setModal, closeModal] = useModal();
  // DB Hooks + Selectors:
  // Data from database, namely the product documents in the collection list of products:
  const { db: dB, mobile } = useContext(ServicesContext);
  const numberReceived = useSelector<RootState, false | number>(
    (state) =>
      state.db.single_collection_item_list.set &&
      state.db.single_collection_item_list.data.length,
  );
  const nonReceived = numberReceived === 0;
  // Also get the user views doc (for the viewing user, stores when they last viewed a product pop-up):
  useDBRetrieveDoc<'single_collection_views'>(
    dB,
    dB.collectionViewsDoc,
    'single_collection_views',
    collectionViewsParser,
    undefined,
    [authUserUID, collectionUserUID, collectionUID],
    'listen',
  );
  const dispatch = useDispatch();
  const canShowReviewRequestButtonExtension = useSelector(
    (state: RootState) =>
      state.firestore_user_owner.snapshot
        ?.user_clicked_to_chrome_web_store_time === undefined,
  );
  const canShowReviewRequestButtonApp = useSelector(
    (state: RootState) =>
      state.firestore_user_owner.snapshot?.user_clicked_to_app_store_time ===
      undefined,
  );
  const userViews = useSelector(
    (state: RootState) => state.db.single_collection_views.data,
  );
  const productMap = useSelector((state: RootState) => state.productData ?? {});

  // Constants:
  const renderOrder = organisingHook.renderOrder;
  const isMine = authUserUID === collectionUserUID;
  const searchParams = useSearchParams();
  const productSearchParam = searchParams.product;
  // store if initally (on mount) the product window was closed or not:
  const [hasBeenClosed] = useState(productSearchParam ? false : true);

  // Hook to pre-load the product data and determine if it has all loaded to prevent random loading.
  // TODO: Move the loading of product data into the producttile component.
  const { loadedProducts, renderPseudoCollectionItems } = useLoadedProducts(
    collectionUID,
    collectionUserUID,
  );

  /*

  Set the product modal URL as either open or closed based on arguments:

  display: true if opening, false if closing
  thisItemID: current / desired item id to base off
  increment: increment from current / desired item in the ordering

  */
  const setProductModalURL = useCallback(
    (display: boolean, thisItemID: string | null, increment: number = 0) => {
      // Determine the ID of the item to show based on the order:
      var newNextItemIDToShow = '';
      if (display === true && thisItemID) {
        // get order which may depend on if in organising mode or not:
        // (although you shouldn't be able to open it in organising mode!)
        const thisOrder = renderOrder().itemOrder;
        var oldIndex = thisOrder.indexOf(thisItemID);
        var newIndex = oldIndex + increment;
        // Loop around if at ends of list!:
        if (newIndex < 0) {
          newIndex = thisOrder.length - 1;
        } else if (newIndex >= thisOrder.length) {
          newIndex = 0;
        }
        newNextItemIDToShow = thisOrder[newIndex];
      }
      if (productSearchParam !== newNextItemIDToShow) {
        const localSearchParams = new URLSearchParams();
        if (newNextItemIDToShow) {
          localSearchParams.set('product', newNextItemIDToShow);
        } else {
          localSearchParams.delete('product');
        }
        const newHistory = {
          pathname: location.pathname,
          search: localSearchParams.toString(),
        };
        // Now set the address bar with:
        // replace if changing between products always
        if (increment !== 0) {
          history.replace(newHistory);
          return;
        }
        // push if opening always
        if (display === true) {
          history.push(newHistory);
          return;
        }
        /*
         if closing, then go back -
         unless has not loaded as closed yet (e.g. come from notication / loaded directly),
         in which case we want to just replace to get to the closed state
        */
        if (hasBeenClosed) {
          history.goBack();
          return;
        }
        // TODO: On mobile, as the modal fills the screen so always go back, otherwise replace:
        // Will need to sort modal to display only on collection view first, though!
        history.replace(newHistory);
      }
    },
    [
      history,
      location.pathname,
      productSearchParam,
      renderOrder,
      hasBeenClosed,
    ],
  );

  /*

  Open / Close the product modal based on the URL:

  */
  useEffect(() => {
    const productParamExists = productSearchParam
      && typeof productSearchParam === 'string';

    const productExists = productParamExists
    && productMap[
      getCollectionItemStoreAs(
        collectionUID,
        collectionUserUID,
        productSearchParam,
      )
    ] != null;

    if (
      (!loadedProducts || !productParamExists)
      && (
        modalType === ModalTypes.ProductSettings ||
        modalType === ModalTypes.ProductDoesNotExist
      )
    ) {
      // Close the product modals if nothing is loaded or product doesn't
      closeModal();
      return;
    }

    if (
      productParamExists
      && productExists
      && (modalType == null || modalType === ModalTypes.ProductSettings)
    ) {
      // NOTE: As updates regardless, will in future need to change if product settings changes to more than one type...
      // i.e. if it becomes a group of modals.
      setModal({
        type: ModalTypes.ProductSettings,
        itemUID: productSearchParam,
        collectionUID,
        collectionUserUID,
        showProductModal: setProductModalURL,
      });
    } else if (
      productParamExists
      && loadedProducts
      && !productExists
    ) {
      // if there is a requrested product and we have loaded but it isn't in the product map,
      // then show that it does not exist:
      // (usually it's been deleted but the user clicked a notifcation about it)
      setModal({
        type: ModalTypes.ProductDoesNotExist,
        closeProductModal: () => {
          setProductModalURL(false, null, 0);
          closeModal();
        }
      });
    }
  }, [
    closeModal,
    collectionUID,
    collectionUserUID,
    loadedProducts,
    modalType,
    productMap,
    productSearchParam,
    setModal,
    setProductModalURL,
  ]);

  const goToAddManually = useCallback(
    () => history.push(R.ADD_PRODUCT),
    [history],
  );

  const openReviewModal = useCallback(
    () =>
      dispatch<SetShowReviewRequests>({
        type: ActionNames.UI_SET_SHOW_REVIEW_REQUESTS,
        payload: undefined,
      }),
    [dispatch],
  );

  const showReviewButton =
    isMine &&
    numberReceived !== false &&
    numberReceived >= 5 &&
    ((canShowReviewRequestButtonExtension && extensionInstalled) ||
      (canShowReviewRequestButtonApp && mobile.isApp));

  return (
    <>
      {renderPseudoCollectionItems()}
      <ProductTileList
        {...{
          authUserUID,
          isPro,
          collectionUID,
          collectionUserUID,
          isMine,
          loadedProducts,
          nonReceived,
          renderOrder,
          userViews,
          goToAddManually,
          // Opening modals:
          showProductModal: setProductModalURL,
          // Organising:
          isOrganising: organisingHook.isOrganising,
          // Organising - ordering:
          moveItem: organisingHook.moveItem,
          // Organising - subsections:
          addSubsection: organisingHook.addSubsection,
          moveSubsection: organisingHook.moveSubsection,
          deleteSubsection: organisingHook.deleteSubsection,
          updateSubsectionName: organisingHook.updateSubsectionName,
          addOrRemoveItemToSubsection:
            organisingHook.addOrRemoveItemToSubsection,
          focusSubsectionTextbox: organisingHook.focusSubsectionTextbox,
          // Organising - covers
          choosingCoverImage: organisingHook.choosingCoverImage, // choosing cover?
          setChoosingCoverImage: organisingHook.setChoosingCoverImage, // open choosing cover etc.
          setOrganisingCoverImageURL: organisingHook.setOrganisingCoverImageURL,
          deleteCoverByIndex: organisingHook.deleteCoverByIndex,
          selectCover: organisingHook.selectCover,
          isCreator,
          openReviewModal,
          showReviewButton,
          goToAddItem,
        }}
      />
    </>
  );
};

export { Container as ProductTileList };
