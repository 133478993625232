import React, { useCallback, useContext, useRef, useState } from 'react';
// import { object, string } from 'yup';
import {
  AddAnItemManuallyAddImageContainerProps,
  AddAnItemManuallyAddImageProps,
} from './AddAnItemManuallyAddImage.types';
import { AddAnItemManuallyAddImage } from './AddAnItemManuallyAddImage.component';
import { ServicesContext } from 'src/ServicesContext';
import prepareFileForUpload from 'src/mvp22/core-components/prepareFileForUpload';

// const validationSchema = object({
//   pastedLink: string()
//     .required('Please enter a URL (including https://)')
//     .test('startsWithHttps', 'The link must start with "https://"', (value) => {
//       if (!value) return false;
//       return value.startsWith('https://') || value.startsWith('http://');
//     }),
// });

const AddAnItemManuallyAddImageContainer: React.FC<
  AddAnItemManuallyAddImageContainerProps
> = ({ onImageURLAdded, ...otherProps }) => {
  const { analytics, cloud, mobile } = useContext(ServicesContext);
  const isMobile = mobile.isApp;

  const [errorState, setErrorState] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const uploadButtonReference = useRef<HTMLInputElement>(null);
  const pasteURLReference = useRef<HTMLInputElement>(null);

  const handlePasteImageURL = useCallback<
    AddAnItemManuallyAddImageProps['onPasteImageURL']
  >(async () => {
    const imageURL = pasteURLReference.current?.value;
    if (imageURL) {
      onImageURLAdded(imageURL);
    }
  }, [onImageURLAdded]);

  const handleUploadImage = useCallback<
    AddAnItemManuallyAddImageProps['onUploadImage']
  >(
    async (event) => {
      setErrorState('');
      setIsUploading(true);
      try {
        const localImagePath: string = await prepareFileForUpload(event.target);
        const result = (await cloud.call('collections-uploadProductImage')({
          image: localImagePath,
        })) as { data: string };
        // Also add it to all the collections:
        onImageURLAdded(
          `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_PROJECTID}-user-custom/${result.data}`,
        );
        analytics.recordEvent('WebApp:CalledAddAnItemManuallyAddImage');
      } catch {
        setErrorState(
          'Uploading an image failed.  Please ensure images are less than 8MB',
        );
      }
      setIsUploading(false);
    },
    [cloud, analytics, onImageURLAdded],
  );

  return (
    <AddAnItemManuallyAddImage
      onPasteImageURL={handlePasteImageURL}
      onUploadImage={handleUploadImage}
      pasteURLReference={pasteURLReference}
      errorState={errorState}
      {...otherProps}
      uploadButtonReference={uploadButtonReference}
      isUploading={isUploading}
      isMobile={isMobile === true}
    />
  );
};

export { AddAnItemManuallyAddImageContainer as AddAnItemManuallyAddImage };
