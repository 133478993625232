/*

Gets the site domain name from a URL.

This function's regular expression should match the one in cloud-db and chrome-extension.

Move to utils

*/
export default function get_site_uid(url_in){
  try{
    const host_in = (new URL("http://"+url_in)).host;
    const domain_reg_ex = /((\.co\.uk)|(\.co\.nz)|(\.com\.au)|(\.co\.jp)|(\.co\.hk)|(\.cn)|(\.fr)|(\.eu)|(\.de)|(\.us)|(\.au)|(\.uk)|(\.com)|(\.io)|(\.net)|(\.org)|(\.ca)|(\.jp)|(\.ru)|(\.ch)|(\.it)|(\.hk)|(\.sg)|(\.se)|(\.no)|(\.es)|(\.info)|(\.fashion)|(\.studio))$/;
    const match_res = host_in.match(domain_reg_ex);
    if (match_res){
      const this_domain = match_res[0];
      const this_pre_domain = host_in.slice(0,match_res.index);
      return this_pre_domain.split(".").slice(-1)[0]+this_domain;
    }
    else{
      return host_in;
    }
  }
  catch(err){
    return url_in.slice(0,20);
  }
}
