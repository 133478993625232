import { CollectionReference, DocumentReference } from '@firebase/firestore';
import { useCallback, useState, useRef } from 'react';
import { DB } from 'src/services/DB';

/*

This hook is used to add a document to a collection in the database, returns a function and working flag.

*/
export function useDBAddDoc<T>(
  dB: DB,
  collectionToAddTo: CollectionReference, // collection reference
): [(data: T) => Promise<DocumentReference<any>>, boolean] {
  const promiseRef = useRef<Promise<DocumentReference<any>>>();
  const [working, setWorking] = useState(false);
  const dBAddDoc = useCallback(
    (data: T) => {
      if (working) {
        return promiseRef.current!;
      }

      setWorking(true);

      const newPromise = dB
        .addDoc(collectionToAddTo, data)
        .then((res) => {
          setWorking(false);
          return res;
        })
        .catch((err) => {
          setWorking(false);
          throw err;
        });

      promiseRef.current = newPromise;

      return newPromise;
    },
    [working, dB, collectionToAddTo],
  );
  return [dBAddDoc, working];
}
