/*

Displays the list of notifications (used in dropdown and on page of notifications so reuseable!)

TODO: typescript, camelCase, design-system...

*/

import { useEffect, useState } from 'react';
import styled from 'styled-components';

import R from 'src/routes';
import { checkNotificationConditions } from 'src/utils/checkNotificationConditions';
import MEDIA from 'src/mvp22/media';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import NotificationTile from './NotificationTile';
import { withFirebase } from 'src/mvp22/Firebase';
import { check_item_is_earlier_than } from 'src/mvp22/core-components/date_time_functions';

const MaxWidthContainer = styled.div`
  max-width: 532px;
  width: 100%;
  box-sizing: border-box;

  && *,
  && *::before,
  && *::after {
    box-sizing: border-box;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-items: space-between;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0px 16px;
  box-sizing: border-box;
`;

const NotificationsTitle = styled(TEXTSTYLE.HEADING4)`
  color: default;
`;

const SettingsCogContainer = styled(TEXTSTYLE.LINK)`
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
`;

const EmptyMessage = styled(TEXTSTYLE.BODY4)`
  width: 100%;
  text-align: left;
  padding-left: 16px;
`;

const SettingsCogImage = styled.img`
  width: 18px;
  height: 18px;
`;

const NewMessage = styled(TEXTSTYLE.OTHER5)`
  margin-bottom: 8px;
  width: 100%;
  align-items: flex-start;
  display: flex;
  padding: 0px 16px;
  box-sizing: border-box;
`;

const NotificationTileContainer = styled.div`
  color: default;
`;

export default withFirebase((props) => {
  const [time_at_last_view_on_load, setTimeAtLastViewOnLoad] = useState({
    seconds: 0,
  });

  useEffect(() => {
    props.firebase.fast_api()({
      api: 'update_notification_time_at_last_view',
    });
  }, [props.firebase]);

  useEffect(() => {
    if (time_at_last_view_on_load.seconds === 0) {
      setTimeAtLastViewOnLoad(props.time_at_last_view);
    }
  }, [props.time_at_last_view, time_at_last_view_on_load.seconds]);

  const passingNotifications = props.notification_list.filter((entry) => {
    return checkNotificationConditions(entry.client_conditions);
  });

  const newNotifications = passingNotifications.filter((x) => {
    return !check_item_is_earlier_than(x.created_at, time_at_last_view_on_load);
  });
  const hasNewNotifications = newNotifications.length > 0;

  // redone here since we want to calculate these all at once.
  const TIME_NOW = props.firebase.serverTimestamp();
  return (
    <MaxWidthContainer>
      <TitleContainer>
        <NotificationsTitle>Notifications</NotificationsTitle>
        <SettingsCogContainer to={R.EDIT_EMAIL_PREFERENCES}>
          <SettingsCogImage src={MEDIA.COG} />
        </SettingsCogContainer>
      </TitleContainer>
      {hasNewNotifications && <NewMessage>NEW</NewMessage>}
      {passingNotifications.length === 0 && (
        <EmptyMessage>No notifications yet</EmptyMessage>
      )}
      {passingNotifications.map((entry) => {
        return (
          <NotificationTileContainer key={entry.uid}>
            <NotificationTile
              data={entry}
              time_now={TIME_NOW}
              time_at_last_view={time_at_last_view_on_load}
              auth_display_username={props.auth_display_username}
              auth_user_uid={props.auth_user_uid}
            />
          </NotificationTileContainer>
        );
      })}
    </MaxWidthContainer>
  );
});
