/*
Very old component!!

Ability to request password change

TODO: typescript, camelCase, make functional, non-default exports, design system...

*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import Button from 'src/mvp22/form-components/Button';
import { withFirebase } from 'src/mvp22/Firebase';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const MainContainer = styled.div`
  box-sizing:border-box;
  position:relative;
  width:100%;
  max-width:660px;
`;

const BackToMyCollections = styled(TEXTSTYLE.BODY4)`
  text-decoration: underline;
`;

const VerticalSeparator = styled.div`
  height:${props=>props.height};
`;

const AB = styled(TEXTSTYLE.LINKBOLD)`
  color:${M.COL.TEXT.BLACK};
`;

const Block = styled.div`
  display:inline-block;
`;

/**
* Forgot password to Moonsift Component
*/
class Login extends Component {

  constructor(props){
    super(props);
    this.doSubmit = this.doSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      email:"",
      email_feedback:"",
      done:false
    };
  }

  doSubmit(event){
    event.preventDefault();
    this.props.firebase.doPasswordReset(this.state.email).then(
      ()=>this.setState({done:true})
    ).catch(
      error=>{
        if (error.message){
          this.setState(
            {
              email_feedback:error.message
            }
          );
        }
      }
    );
  }

  onChange(event){
    this.setState(
      {email:event.target.value}
    );
  }

  render(){
    const MAINCONTENT = (this.state.done===false)?
      <MainContainer>
        <form onSubmit={this.doSubmit}>
          <VerticalSeparator height="77px"/>
          <LogoAndText heighttype="A"/>
          <VerticalSeparator height="10px"/>
          <TEXTSTYLE.BODY2>
            <Block>Personalise your</Block>&nbsp;<Block><b>shopping space</b></Block>
          </TEXTSTYLE.BODY2>
          <VerticalSeparator height="26px"/>
          <TextInputFeedback
            focusOnMount={true}
            placeholder="Email"
            msstyle="standard"
            onChange={this.onChange}
            name="email"
            width="100%"
            value={this.state.email}
            feedbackText={this.state.email_feedback}
          />
          <VerticalSeparator height="22px"/>
          <Button
            text="Request password"
            msstyle="bright"
            onClick={this.doSubmit}
            width="100%"
          />
          <VerticalSeparator height="22px"/>
          <TEXTSTYLE.BODY3 style={{display: 'none'}}>
            No account?&nbsp;<AB to={R.SIGNUP}>Sign up</AB>
          </TEXTSTYLE.BODY3>
          <VerticalSeparator height="22px"/>
            <TEXTSTYLE.LINK to={R.SIGNIN}>
              <BackToMyCollections>
                Back to login page
              </BackToMyCollections>
            </TEXTSTYLE.LINK>
          <VerticalSeparator height="13px"/>
        </form>
      </MainContainer>
    :
    <MainContainer>
      <form onSubmit={this.doSubmit}>
        <VerticalSeparator height="77px"/>
        <LogoAndText heighttype="A"/>
        <VerticalSeparator height="10px"/>
        <TEXTSTYLE.BODY2>
          <Block>Personalise your</Block>&nbsp;<Block><b>shopping space</b></Block>
        </TEXTSTYLE.BODY2>
        <VerticalSeparator height="26px"/>
        <TEXTSTYLE.BODY3>
          Please check your inbox!
        </TEXTSTYLE.BODY3>
        <VerticalSeparator height="22px"/>
        <TEXTSTYLE.LINK to={R.HOMEPAGE}>
          <Button
            text="Go to homepage"
            msstyle="bright"
            width="100%"
          />
        </TEXTSTYLE.LINK>
        <VerticalSeparator height="13px"/>
      </form>
    </MainContainer>;

    return(
      <FullBlank>
        <Container>
          {AddHelmetTitle("Forgot Password")}
          {MAINCONTENT}
        </Container>
      </FullBlank>
    );
  }
}

export default withFirebase(Login);
