import React from 'react';
import { RateChromeExtension2Props } from './RateChromeExtension2.types';
import { Stack, Typography, CtaButton } from '@moonsifttech/design-system';

import styled from 'styled-components';
import { ChromeReviewWebstoreImage } from 'src/components/reviews/ChromeReviewWebstoreImage';
import { EXTERNAL } from 'src/routes';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
const UnstyledRateChromeExtension2: React.FC<RateChromeExtension2Props> = ({
  onOpenChromeStoreClick,
  className,
  style,
}) => {
  return (
    <Stack
      className={className}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <ChromeReviewWebstoreImage />
      <Typography
        className="RateChromeExtension2-questionHeading"
        variant="primary.b26"
        component="h3"
      >
        Open the Chrome Web Store, then scroll down and click 'Write a review'
      </Typography>
      <Typography
        className="RateChromeExtension2-mainText"
        variant="primary.l20"
        component="p"
      >
        We really appreciate your support.
      </Typography>

      <CtaButton
        variant="primary-black"
        onClick={onOpenChromeStoreClick}
        fullWidth
        component={UnstyledLink}
        to={EXTERNAL.CHROMESTORE}
        target="_blank"
      >
        Open Chrome Web Store
      </CtaButton>
    </Stack>
  );
};

export const RateChromeExtension2 = styled(UnstyledRateChromeExtension2)`
  text-align: left;
  .RateChromeExtension2-mainText {
    margin-bottom: 50px;
    width: 100%;
  }
  .RateChromeExtension2-questionHeading {
    width: 100%;
  }
`;
