import R from 'src/routes';

/**
 * Routes in which the auth redirect won't execute.
 */
export const authRedirectBlocklist = [R.SIGNUP, R.POSTSIGNUP];

/**
 * Search parameter name used for auth redirects.
 */
export const authRedirectSearchParam = 'authRedirect';

/**
 * List of hash parameters that will stick when navigating to a route.
 */
export const hashParamKeeplist: string[] = [];

/**
 * List of search parameters that will stick when navigating to a route.
 */
export const searchParamKeeplist: string[] = [authRedirectSearchParam];

/**
 * Minimum due amount content creator can request a payout for.
 */
export const creatorsPayoutMin = process.env.REACT_APP_CREATORS_PAYOUT_MIN
  ? parseFloat(process.env.REACT_APP_CREATORS_PAYOUT_MIN)
  : 50.0;
