/*

REDUX Query
This has been replaced but is still used in some places.

Replaced with:
useDBRetrieveDoc<'user_public'>(dB, dB.userPubicDoc, 'user_public', userPublicParser,collectionUserUID,[collectionUserUID],"get",false);
const singleCollectionUserPublicDocData = useSelector((state: RootState) => state.db.user_public.map[collectionUserUID]);

but this gets all at once for a list of collections, so we will deal with this by either making a pseudo collection element OR doing this as a hook in collection tiles.

*/
import collection_data_proc from 'src/mvp22/core-components/collection_data_proc';
export default function get_user_profile_public_collections(user_uid,firebase,auth_user_uid){
  return new Promise(
    (resolve)=>{
      // now get all the public collection's information:
      firebase.user_collection_list_public(
        user_uid
      ).get().then(
        (response)=>{
          const collection_global_uid_list = [];
          const collection_entry_dict = {};
          response.forEach(
            snapshot=>{
              if (snapshot.exists()){
                const this_collection_entry = collection_data_proc(auth_user_uid,snapshot);
                collection_entry_dict[this_collection_entry.global_uid]={
                  collection_entry:this_collection_entry
                };
                collection_global_uid_list.push(
                  this_collection_entry.global_uid
                );
              }
            }
          );
          collection_global_uid_list.sort((a,b)=>(collection_entry_dict[a].collection_entry.last_updated>collection_entry_dict[b].collection_entry.last_updated)?-1:1);
          resolve(
            {
              collections_sorted:collection_global_uid_list,
              collections:collection_entry_dict
            }
          );
        }
      );
    }
  );
}
