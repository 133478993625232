/*
Here are the various types of ways of displaying collection tiles for the landing and explore pages.

TODO: Convert to typescript, component + container, camelCase

*/
import React from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {EXTERNAL} from 'src/routes';
import BookmarkButton from 'src/mvp22/form-components/BookmarkButton';
import ProfileImage from "src/mvp22/image-components/ProfileImage";
import {NewCollectionTile} from 'src/mvp22/tile-components/CollectionTileList';
import MEDIA from 'src/mvp22/media';
import Button from 'src/mvp22/form-components/Button';
//import { time_in_past } from 'src/mvp22/core-components/date_time_functions';
import {getCustomUrl} from "src/mvp22/core-components/image_url";

const NewTileDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 201px;
  background-color: ${M.COL.BG.WHITE};
`;

const LargeCollectionDisplayContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  margin-left: 57px;
`;

const LargeCollectionDisplayContainer2 = styled(LargeCollectionDisplayContainer)`
  max-width: 750px;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 0px;
  margin-left: 0px;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 64%;
  margin-left: 70px;
  margin-top: 33px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 44%;
`;

const Right3 = styled.div`
  justify-content: flex-end;
  display: flex;
  width: 58%;
`;

const CollectionInformationSection = styled.div`
  display: flex;
`;

const CollectionInformationSection2 = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const CollectionInfoLeft = styled.div`
color:default;
`;

const CollectionInfoRight = styled.div`
  display: flex;
  flex-direction: column;
  height: 212px;
  justify-content: space-between;
`;

const CollectionInfoBottom = styled.div`
  display: flex;
  flex-direction: column;
`;

const CollectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 441px;
  margin-left: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const CollectionTitle2 = styled.div`
  max-width: 100%;
  margin: 26px 26px 48px 26px;
`;

const CollectionTitle3 = styled.div`
  max-width: 100%;
  margin: 26px 26px 26px 26px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${props => props.display_type === 2 ? '' : '96px'};
`;

const TitleFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  margin-left: 29px;
`;

const TitleFooter4 = styled(TitleFooter)`
  margin-left: 0px;
`;

const TitleFooter2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 26px 26px 26px;
`;

const TitleFooter3 = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 26px 26px 0px 26px;
  flex-direction: column;
  align-items: flex-start;
`;

const SmallCollectionDisplayContainer = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.display_type === 2 ? '26px' : '40px'};
`;

const ImageAndBackgroundContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

const TitleFooterLeft = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const Username = styled(TEXTSTYLE.BODY5)`
  display: flex;
  align-self: flex-start;
  margin-right: 11px;
  white-space:nowrap;
  color: ${M.COL.TEXT.MID};
  a {
    &:hover {
      color: ${M.COL.TEXT.BLACK};
    }
  }
`;

const Username2 = styled(TEXTSTYLE.BODY5)`
  display: flex;
  margin-right: 11px;
  margin-left: 6px;
  white-space:nowrap;
  color: ${M.COL.TEXT.MID};
  a:hover  {
    color: ${M.COL.TEXT.BLACK};
  }
`;

const BookmarkButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BookmarkButtonContainer2 = styled(BookmarkButtonContainer)`
  margin-right: 40px;
`;

const LeftImageRow = styled(TEXTSTYLE.LINK)`
  display: flex;
  position: absolute;
  z-index: 6000;
  justify-content: space-between;
  bottom: 39px;
  max-width: 790px;
  width: 64%;
  max-height:302px;
  padding: 50px;
  margin: '-50px';

  box-sizing: content-box;
`;

const CoverImage = styled.img`
  width: 100%;
  width:563px;
  height: 700px;
  object-fit:cover;
  position: relative;
  left: 42px;
`;

const CoverImage3 = styled.img`
  width: 100%;
  height: 330px;
  object-fit: cover;
  object-position: top;
`;

const CoverImage2 = styled.img`
  width: 70%;
  height: 330px;
  object-fit: cover;
`;

const CoverImage4 = styled.img`
  width: 100%;
  object-fit: cover;
  height: calc(${ props => props.windowWidth}*0.9*1px);
  max-height: 589px;
  max-width: 472px;
`;

const ColouredBackground = styled.div`
  width: 30%;
  background-color: ${props => props.colour};
`;

const ForegroundImage = styled.img`
  display: flex;
  max-width: 228px;
  width: 33%;
  box-sizing: border-box;
  margin-right: 52px;
  border: 5px solid ${M.COL.BG.WHITE};
  object-fit: cover;
  background-color:${M.COL.BG.WHITE};
`;

const ForegroundImageContainer = styled.div`
  width:42%;
  position: relative;
`;

const ForegroundImage3Container = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 width: 30%;
 position: absolute;
 right: 0;
 height: 100%;
`;

const ForegroundImage4 = styled.img`
  position: absolute;
  border: 5px solid ${props => props.blackBorder? M.COL.BG.BLACK : M.COL.BG.WHITE};
  top: ${props => props.top_position + '%'};
  bottom: ${props => props.bottom_position + '%'};
  left: ${props => props.left_position + '%'};
  right: ${props => props.right_position + '%'};
  height: ${props => props.height + '%'};
  width: ${props => props.width + '%'};
  object-fit: cover;
  box-sizing: border-box;
  background-color:${M.COL.BG.WHITE};
`;

const CenteredImagesContainer = styled.div`
  display: flex;
`;

const UsernameLink = styled(TEXTSTYLE.LINK)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100px;
  @media (min-width: 420px){
    max-width: 200px;
  }
`;

const UsernameLink2 = styled(UsernameLink)`
  text-overflow: ellipsis;
  max-width: 95px;
  overflow: hidden;
`;

const UsernameLink3 = styled(UsernameLink)`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 190px;
  @media(max-width: 470px){
    max-width: 85px;
  }
`;

const GreyBackgroundContainer = styled(ImageAndBackgroundContainer)`
  width: 100%;
  height: 330px;
  background-color: ${M.COL.LINE.MID};
  justify-content: center;
  align-items: center;
`;

const ShadowBox = styled.div`
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
`;

const SectionHeading = styled(TEXTSTYLE.JUANA.HEADING4)`
  font-size: 22px;
  font-weight: 100;
  line-height: 32px;
`;

const SectionHeading2 = styled(TEXTSTYLE.JUANA.HEADING4)`
  font-size: 22px;
  font-weight: 100;
  line-height: 32px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Seperator = styled.div`
  background-color:${props=>props.color};
  height:${props=>props.height};
`;

const ProfileLink = styled(TEXTSTYLE.LINK)`
  color: default;
  line-height: 0;
`;

const PlusIconContainer = styled.div`
  border: 1px solid ${M.COL.TEXT.BLACK};
  box-sizing: border-box;
  height:20px;
  width:20px;
  border-radius:10px;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const DatePosted = styled(TEXTSTYLE.BODY5)`
  display: flex;
  text-align: left;
  color: ${M.COL.TEXT.MID};
  margin-left: 11px;
  white-space: nowrap;
`;

const CoverImageLink = styled(TEXTSTYLE.LINK)`
  width: 100%;
  line-height: 0;
`;

const PlusIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.0002 4.16748V15.8341" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.16675 9.99976H15.8334" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

const GenericCollectionDisplay = (props)=>{
  const COLLECTION_URL = "/collection/" + props.public_info.display_username + "/" + props.data.collection_entry.uid;
  const user_uid = props.data.cli.user_uid;
  const collection_uid = props.data.collection_entry.uid;
  const collection_name = props.data.collection_entry.name;
  const collection_entry_user_uid = props.data.collection_entry.user_uid;
  const like_count = props.data.collection_entry.like_count;
  const background_colour = props.background_colour;
  const PROFILE_LINK = "/@"+props.public_info.display_username;
  const cover_image_input = props.data.cli.cover_image_url?props.data.cli.cover_image_url:props.data.collection_entry.cover_image_url;
  const COVER_IMAGE_URL = cover_image_input?getCustomUrl(cover_image_input):undefined;
  const sub_small_collection_image_list = props.data.collection_images? COVER_IMAGE_URL ? props.data.collection_images.slice(0,3) : props.data.collection_images.slice(1,4) : undefined;
  // Date posted text:
  //const datetime_in_seconds = (props.data.cli.live_at)?Math.floor(props.data.cli.live_at.seconds):Math.floor(props.data.collection_entry.created_at.seconds); // The 0 there is the key, which sets the date to the epoch
  //const now_in_seconds = Math.floor(new Date().getTime()/1000);
  // time_in_past(now_in_seconds, datetime_in_seconds)[0]
  switch (props.type){
    case "large":
      return(
        <LargeCollectionDisplayContainer>
          <Left>
            <CollectionInformationSection>
              <CollectionInfoLeft className='collectionInfoLeft'>
              <ProfileLink to={PROFILE_LINK}>
                <ProfileImage
                  profile_image_code={props.public_info ? props.public_info.profile_image_code : ""}
                  user_uid={user_uid}
                  name = {props.public_info ? props.public_info.name : ""}
                  scale={96}
                />
              </ProfileLink>
              </CollectionInfoLeft>
              <CollectionInfoRight>
                <CollectionTitle>
                  <TEXTSTYLE.LINK to={COLLECTION_URL}><TEXTSTYLE.JUANA.HEADING2>{collection_name}</TEXTSTYLE.JUANA.HEADING2></TEXTSTYLE.LINK>
                </CollectionTitle>
                <TitleFooter>
                  <TitleFooterLeft>
                    <Username>by&nbsp;<UsernameLink to={"/@" + props.public_info.display_username}>{props.public_info.name}</UsernameLink></Username>
                    <DatePosted>

                    </DatePosted>
                  </TitleFooterLeft>
                  <BookmarkButtonContainer2>
                    <BookmarkButton
                     like_count={like_count}
                     collection_entry_user_uid={collection_entry_user_uid}
                     collection_entry_uid={collection_uid}
                     auth_user_uid={props.auth_user_uid}
                     collection_liked_is_liked={props.data.collection_liked_is_liked}
                    />
                  </BookmarkButtonContainer2>
                </TitleFooter>
              </CollectionInfoRight>
            </CollectionInformationSection>
          </Left>
          <Right className="right">
            <TEXTSTYLE.LINK to={COLLECTION_URL}>
              <CoverImage src={COVER_IMAGE_URL ? COVER_IMAGE_URL : (props.data.collection_images !== undefined ? props.data.collection_images[0] : null)}/>
            </TEXTSTYLE.LINK>
          </Right>
          <LeftImageRow to={COLLECTION_URL}>
            <ForegroundImage src={sub_small_collection_image_list !== undefined && sub_small_collection_image_list[0] !== undefined ? sub_small_collection_image_list[0] : null}/>
            <ForegroundImage src={sub_small_collection_image_list !== undefined && sub_small_collection_image_list[1] !== undefined ? sub_small_collection_image_list[1] : null}/>
            <ForegroundImage src={sub_small_collection_image_list !== undefined && sub_small_collection_image_list[2] !== undefined ? sub_small_collection_image_list[2] : null}/>
          </LeftImageRow>
        </LargeCollectionDisplayContainer>
      );
    case "large2":
      return(
        <LargeCollectionDisplayContainer2>
          <CenteredImagesContainer>
            <ForegroundImageContainer>
              <ForegroundImage4 height={43} width={63} top_position={0} bottom_position="" left_position={10} right_position="" src={props.data.collection_images !== undefined && sub_small_collection_image_list[0] !== undefined ? sub_small_collection_image_list[0] : null}/>
              <ForegroundImage4 height={43} width={63} top_position={31} bottom_position="" left_position={48} right_position="" src={props.data.collection_images !== undefined && sub_small_collection_image_list[1] !== undefined ? sub_small_collection_image_list[1] : null}/>
              <ForegroundImage4 height={41} width={55} top_position="" bottom_position={0} left_position={0} right_position="" src={props.data.collection_images !== undefined && sub_small_collection_image_list[2] !== undefined ? sub_small_collection_image_list[2] : null}/>
            </ForegroundImageContainer>
            <Right3 className="right3">
              <TEXTSTYLE.LINK to={COLLECTION_URL}>
                <CoverImage4 windowWidth={props.windowWidth} src={COVER_IMAGE_URL ? COVER_IMAGE_URL : (props.data.collection_images !== undefined && props.data.collection_images[0] !== undefined ? props.data.collection_images[0] : null)}/>
              </TEXTSTYLE.LINK>
            </Right3>
          </CenteredImagesContainer>
          <CollectionInfoBottom>
            <CollectionTitle2>
              <TEXTSTYLE.LINK to={COLLECTION_URL}><SectionHeading2>{collection_name}</SectionHeading2></TEXTSTYLE.LINK>
              <Seperator height="20px"/>
              <TitleFooter4>
                <TitleFooterLeft>
                  <ProfileLink to={PROFILE_LINK}>
                    <ProfileImage
                      profile_image_code={props.public_info ? props.public_info.profile_image_code : ""}
                      user_uid={user_uid}
                      name = {props.public_info ? props.public_info.name : ""}
                      scale={27}
                    />
                  </ProfileLink>
                  <Username2>by&nbsp;<UsernameLink3 to={"/@" + props.public_info.display_username}>{props.public_info.name}</UsernameLink3></Username2>
                  <DatePosted>

                  </DatePosted>
                </TitleFooterLeft>
                  <BookmarkButtonContainer>
                    <BookmarkButton
                     like_count={like_count}
                     collection_entry_user_uid={collection_entry_user_uid}
                     collection_entry_uid={collection_uid}
                     auth_user_uid={props.auth_user_uid}
                     collection_liked_is_liked={props.data.collection_liked_is_liked}
                    />
                  </BookmarkButtonContainer>
              </TitleFooter4>
            </CollectionTitle2>
          </CollectionInfoBottom>
        </LargeCollectionDisplayContainer2>
      );
    case "small":
      return(
        <SmallCollectionDisplayContainer display_type={props.display_type}>
          <ImageAndBackgroundContainer>
            <CoverImageLink to={COLLECTION_URL}>
              <CoverImage3 src={COVER_IMAGE_URL ? COVER_IMAGE_URL : (props.data.collection_images !== undefined && props.data.collection_images[0]!== undefined ? props.data.collection_images[0] : null)}/>
            </CoverImageLink>
          </ImageAndBackgroundContainer>
          <CollectionInformationSection2>
            <CollectionTitle3 display_type={props.display_type}>
              <TEXTSTYLE.LINK to={COLLECTION_URL}><SectionHeading>{collection_name}</SectionHeading></TEXTSTYLE.LINK>
            </CollectionTitle3>
            <TitleFooter2>
              <TitleFooterLeft>
                <ProfileLink to={PROFILE_LINK}>
                  <ProfileImage
                    profile_image_code={props.public_info ? props.public_info.profile_image_code : ""}
                    user_uid={user_uid}
                    name = {props.public_info ? props.public_info.name : ""}
                    scale={27}
                  />
                </ProfileLink>
                <Username2>by&nbsp;<UsernameLink2 to={"/@" + props.public_info.display_username}>{props.public_info.name}</UsernameLink2></Username2>
                <DatePosted>

                </DatePosted>
              </TitleFooterLeft>
                <BookmarkButtonContainer>
                  <BookmarkButton
                   like_count={like_count}
                   collection_entry_user_uid={collection_entry_user_uid}
                   collection_entry_uid={collection_uid}
                   auth_user_uid={props.auth_user_uid}
                   collection_liked_is_liked={props.data.collection_liked_is_liked}
                  />
                </BookmarkButtonContainer>
            </TitleFooter2>
          </CollectionInformationSection2>
        </SmallCollectionDisplayContainer>
      );
    case "small2":
      return(
        <SmallCollectionDisplayContainer display_type={props.display_type}>
          <TEXTSTYLE.LINK to={COLLECTION_URL}>
            <ImageAndBackgroundContainer>
              <CoverImage2 src={COVER_IMAGE_URL ? COVER_IMAGE_URL : (props.data.collection_images !== undefined && props.data.collection_images[0] !== undefined ? props.data.collection_images[0] : null)}/>
              <ColouredBackground colour={background_colour}/>
              <ForegroundImage3Container>
                <ForegroundImage4  blackBorder={true} width={105} height={44} top_position={4} right_position={20} src={props.data.collection_images !== undefined && props.data.collection_images[1] !== undefined ? props.data.collection_images[1] : null}/>
                <ForegroundImage4  blackBorder={true} width={105} height={44} bottom_position={4} right_position={20} src={props.data.collection_images !== undefined && props.data.collection_images[2] !== undefined ? props.data.collection_images[2] : null}/>
              </ForegroundImage3Container>
            </ImageAndBackgroundContainer>
          </TEXTSTYLE.LINK>
          <CollectionInformationSection2>
            <CollectionTitle3 display_type={props.display_type}>
              <TEXTSTYLE.LINK to={COLLECTION_URL}><SectionHeading>{collection_name}</SectionHeading></TEXTSTYLE.LINK>
            </CollectionTitle3>
            <TitleFooter2>
              <TitleFooterLeft>
                <ProfileLink to={PROFILE_LINK}>
                  <ProfileImage
                    profile_image_code={props.public_info ? props.public_info.profile_image_code : ""}
                    user_uid={user_uid}
                    name = {props.public_info ? props.public_info.name : ""}
                    scale={27}
                  />
                </ProfileLink>
                <Username2>by&nbsp;<UsernameLink2 to={"/@" + props.public_info.display_username}>{props.public_info.name}</UsernameLink2></Username2>
                <DatePosted>

                </DatePosted>
              </TitleFooterLeft>
              <BookmarkButtonContainer>
                <BookmarkButton
                 like_count={like_count}
                 collection_entry_user_uid={collection_entry_user_uid}
                 collection_entry_uid={collection_uid}
                 auth_user_uid={props.auth_user_uid}
                 collection_liked_is_liked={props.data.collection_liked_is_liked}
                />
              </BookmarkButtonContainer>
            </TitleFooter2>
          </CollectionInformationSection2>
        </SmallCollectionDisplayContainer>
      );
    default:
      return null;
  }
};

const LargeCollectionDisplay = (props) => {
  return(<GenericCollectionDisplay {...props} type="large"/>);
};

const LargeCollectionDisplay2 = (props) => {
  return(<GenericCollectionDisplay {...props} type="large2"/>);
};

const SmallCollectionDisplay1 = (props) => {
  return(<GenericCollectionDisplay {...props} type="small"/>);
};

const SmallCollectionDisplay2 = (props) => {
  return(<GenericCollectionDisplay {...props} type="small2"/>);
};

const FindOutMoreButton = styled(Button)`
  &:active {
    border: 1px solid rgb(48, 48, 48);
  }
`;

const CuratedByYou = (props) => {
  return(
    <SmallCollectionDisplayContainer display_type={props.display_type}>
      <GreyBackgroundContainer>
        <ShadowBox>
          <NewCollectionTile href={EXTERNAL.SUBMIT_COLLECTION}>
            <PlusIconContainer><PlusIcon src={MEDIA.BLACK_PLUS}/></PlusIconContainer>
            <TEXTSTYLE.BODY4>&nbsp;&nbsp;Contribute to Explore</TEXTSTYLE.BODY4>
          </NewCollectionTile>
        </ShadowBox>
      </GreyBackgroundContainer>
      <NewTileDescriptionContainer>
        <TitleFooter3>
          <SectionHeading>Curated by you</SectionHeading>
          <Seperator height="6px"/>
          <TEXTSTYLE.OTHER3>Help the Moonsift community discover new styles and ideas by curating a collection to feature here</TEXTSTYLE.OTHER3>
          <Seperator height="19px"/>
          <TEXTSTYLE.LINKA href={EXTERNAL.SUBMIT_COLLECTION}>
            <FindOutMoreButton
              isDesktop={false}
              text="Find out more"
              msstyle="prompt_button_dark_wider"
              width="100%"
            />
          </TEXTSTYLE.LINKA>
        </TitleFooter3>
      </NewTileDescriptionContainer>
    </SmallCollectionDisplayContainer>
  );
};

export {
  LargeCollectionDisplay,
  LargeCollectionDisplay2,
  SmallCollectionDisplay1,
  SmallCollectionDisplay2,
  CuratedByYou
};
