import {
  DB,
  Query,
  QuerySnapshot,
  DocumentData,
  DBError,
} from 'src/services/DB';
import { put, takeEvery, take } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import {
  baseDBSetQuery,
  baseDBSetQueryListener,
} from 'src/store/actions/baseDB';
import {
  ActionNames,
  ParserFunction,
  BaseDBKindsUnion,
  BaseDBActionTypes,
  BaseDBFetchingDict,
} from 'src/types';
import {
  addToFetchingDict,
  checkFetchingDict,
  deleteFromFetchingDict,
} from 'src/utils/fetchingDict';

const fetchingDict: BaseDBFetchingDict = {};
/*

The saga for getting collections / queries (arrays of documents) from the database and listening for changes.

*/
function* listenItems<K extends BaseDBKindsUnion>(
  kind: K['name'],
  dB: DB,
  dBQuery: Query,
  parser: ParserFunction<K['item']>,
  storeAs: string | undefined,
) {
  // Code for treating the above listener output:
  // closing channel will terminate entire function and just call finally
  if (!checkFetchingDict(kind, storeAs, fetchingDict)) {
    const queryListChannel = eventChannel<
      QuerySnapshot<DocumentData> | 'USE_DB_ERROR'
    >((emit) =>
      dB.listenQuery(dBQuery, emit, (err: DBError) => {
        console.log('useDB error for kind', kind, err);
        emit('USE_DB_ERROR');
      }),
    );
    // Save the channel into redux (like a listener):
    yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_QUERY]>(
      baseDBSetQueryListener<K>(queryListChannel, kind, storeAs),
    );
    try {
      addToFetchingDict(kind, storeAs, fetchingDict);
      while (true) {
        // take from the channel...
        const snapshot: QuerySnapshot<DocumentData> | 'USE_DB_ERROR' =
          yield take(queryListChannel);
        // process the snapshot:...
        const itemList: K['item'][] = [];
        if (snapshot !== 'USE_DB_ERROR') {
          snapshot.forEach((doc) => {
            const parsedData = parser(doc);
            if (parsedData) {
              itemList.push(parsedData);
            }
          });
        }
        // Put data away:
        yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_QUERY]>(
          baseDBSetQuery<K>(itemList, kind, storeAs),
        );
      }
    } finally {
      // any code to perform after END called or error received?
      queryListChannel.close(); // can be called multiple times if already closed.
      deleteFromFetchingDict(kind, storeAs, fetchingDict);
    }
    // Anything below here is never called (unless there is an error in yield...?)
  }
}

// worker saga:
function* createListener<K extends BaseDBKindsUnion>(
  action: BaseDBActionTypes<K>['BASEDB_LISTEN_QUERY'],
) {
  yield listenItems<K>(
    action.kind,
    action.payload.dB,
    action.payload.dBQuery,
    action.payload.parser,
    action.storeAs,
  );
}

// Watching saga:
export function* baseQueryListen() {
  yield takeEvery(ActionNames.BASEDB_LISTEN_QUERY, createListener);
}
