import React from 'react';
import { CollectionNotificationsProps } from './CollectionNotifications.types';
import {
  Link,
  Modal,
  Stack,
  StandardButton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@moonsifttech/design-system';
import styled from 'styled-components';
import { ModalContent } from 'src/components/core/ModalContent';
import clsx from 'clsx';
import R from 'src/routes';

const UnstyledCollectionNotifications: React.FC<
  CollectionNotificationsProps
> = ({
  isOpen,
  onClose,
  className,
  isMine,
  isMobile,
  isSaving,
  notificationsEnabled,
  onNotificationChange,
  saveEnabled,
  onSave,
  style,
}) => {
  const getContent = () => (
    <ModalContent className={className} style={style} onClose={onClose}>
      <form onSubmit={onSave}>
        <Typography
          className="EditModalContentContent-confirmationHeading"
          variant="primary.b26"
          component="h1"
        >
          Collection Notification Settings
        </Typography>
        <Stack direction="column" justifyContent="flex-start">
          <Stack
            direction="row"
            justifyContent="space-between"
            className="CollectionNotifications-toggleRow"
          >
            <Typography
              variant="primary.r16"
              align="center"
              component="p"
              style={{ margin: '6px 0px 0px' }}
            >
              Price Notifications
            </Typography>
            <ToggleButtonGroup
              value={notificationsEnabled}
              onChange={onNotificationChange}
            >
              <ToggleButton
                className={clsx(
                  'CollectionNotifications-toggle',
                  true === notificationsEnabled
                    ? null
                    : 'CollectionNotifications-toggleOff',
                )}
                value={true}
              >
                On
              </ToggleButton>
              <ToggleButton
                className={clsx(
                  'CollectionNotifications-toggle',
                  false === notificationsEnabled
                    ? null
                    : 'CollectionNotifications-toggleOff',
                )}
                value={false}
              >
                Off
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <StandardButton
            variant="primary-black"
            loading={isSaving}
            type="submit"
            disabled={isSaving === false && saveEnabled === false}
          >
            Save
          </StandardButton>
          <Typography
            variant="primary.r16"
            align="left"
            component="p"
            style={{ margin: '24px 0px 0px' }}
          >
            Note that{' '}
            <Link to={R.EDIT_EMAIL_PREFERENCES}>Global Notifications</Link> must
            be on to receive these notifications
          </Typography>
        </Stack>
      </form>
    </ModalContent>
  );

  if (!isMine) {
    return null;
  }

  if (isMobile) {
    return (
      <Modal
        mode="drawer"
        anchor="bottom"
        isOpen={isOpen}
        onRequestClose={onClose}
      >
        {getContent()}
      </Modal>
    );
  }

  return (
    <Modal mode="modal" isOpen={isOpen} onRequestClose={onClose}>
      {getContent()}
    </Modal>
  );
};

export const CollectionNotifications = styled(UnstyledCollectionNotifications)`
  background-color: var(--common-white);
  text-align: left;
  .CollectionNotifications-toggle {
    width: 50px;
    padding: 0px;
    min-width: 0px;
  }
  .CollectionNotifications-toggleOff {
    color: var(--grey-3);
  }
  .CollectionNotifications-toggleRow {
    margin-bottom: 26px;
    margin-top: 2px;
  }
`;
