import { VFC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Stack,
  Typography,
  StandardButton,
  Modal,
  CheckCircleIcon,
} from '@moonsifttech/design-system';

import { PayoutDialog } from 'src/components/creator/PayoutDialog';
import { EarningsSummaryCardProps } from './EarningsSummaryCard.types';

const UnstyledEarningsSummaryCard: VFC<EarningsSummaryCardProps> = ({
  className,
  style,
  open,
  due,
  paid,
  total,
  canRequestPayout,
  creatorPayoutRequested,
  isPayoutRequestOpen,
  onPayoutRequestToggle,
}) => {
  return (
    <Paper className={className} style={style} variant="outlined">
      <Stack>
        <Typography
          className="EarningsSummaryCard-heading"
          variant="primary.r20"
          component="h3"
          color="grey.2"
          align="center"
        >
          Total
        </Typography>
        <Typography
          className="EarningsSummaryCard-total"
          variant="primary.b26"
          component="p"
          align="center"
        >
          {total}
        </Typography>
        <hr />
        <Stack direction="row" justifyContent="space-evenly">
          <Stack>
            <Typography
              className="EarningsSummaryCard-subheading"
              variant="primary.r16"
              component="h4"
              color="grey.2"
              align="center"
            >
              Open
            </Typography>
            <Typography variant="primary.b18" component="p" align="center">
              {open}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              className="EarningsSummaryCard-subheading"
              variant="primary.r16"
              component="h4"
              color="grey.2"
              align="center"
            >
              Due
            </Typography>
            <Typography variant="primary.b18" component="p" align="center">
              {due}
            </Typography>
          </Stack>
          <Stack>
            <Typography
              className="EarningsSummaryCard-subheading"
              variant="primary.r16"
              component="h4"
              color="grey.2"
              align="center"
            >
              Paid
            </Typography>
            <Typography variant="primary.b18" component="p" align="center">
              {paid}
            </Typography>
          </Stack>
        </Stack>
        <hr />
        <StandardButton
          className="EarningsSummaryCard-requestButton"
          variant="primary-black"
          disabled={creatorPayoutRequested || !canRequestPayout}
          fullWidth
          onClick={onPayoutRequestToggle}
          startIcon={creatorPayoutRequested ? <CheckCircleIcon /> : undefined}
        >
          {creatorPayoutRequested ? 'Payout scheduled' : 'Request payout'}
        </StandardButton>
        <Typography
          className="EarningsSummaryCard-footerNote"
          variant="primary.r12"
          component="p"
          color="grey.3"
          align="center"
        >
          You can request a payout if 'Due' earnings is above £50. <br />
          Payment is sent at the start of the upcoming month.
        </Typography>
      </Stack>
      <Modal
        isOpen={isPayoutRequestOpen}
        onRequestClose={onPayoutRequestToggle}
      >
        <PayoutDialog onClose={onPayoutRequestToggle} />
      </Modal>
    </Paper>
  );
};

export const EarningsSummaryCard = styled(UnstyledEarningsSummaryCard)`
  padding: 20px 16px 8px;

  ${({ theme }) => theme.fns.getShapeStyles('soft')}

  .EarningsSummaryCard-heading {
    margin-bottom: 4px;
  }

  .EarningsSummaryCard-subheading {
    margin-bottom: 6px;
  }

  hr {
    width: 100%;
    margin-bottom: 16px;
    border-top: 1px solid var(--grey-5);
    border-bottom: none;
  }

  .EarningsSummaryCard-requestButton {
    margin-bottom: 8px;
  }

  .EarningsSummaryCard-footerNote {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 20px 32px 24px;
  }
`;
