/*

Will become redundant!  Used at present by the collection listeners when using a nested state assignment...

*/
export default function remove_all_listeners(input_data_dict){
  if (input_data_dict){
    for (let this_entry_uid in input_data_dict){
      const data_entry = input_data_dict[this_entry_uid];
      // Clear any old listeners:
      for (let this_key in data_entry){
        if (
          this_key.includes("listener")
        ){
          data_entry[this_key]();
        }
      }
    }
  }
}
