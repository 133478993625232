import { VFC, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';
import { CreatorApplicationFinalContainerProps } from './CreatorApplicationFinal.types';
import { CreatorApplicationFinal } from './CreatorApplicationFinal.component';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { useHasExtension } from 'src/hooks/useHasExtension';
import { ServicesContext } from 'src/ServicesContext';

const CreatorApplicationFinalContainer: VFC<
  CreatorApplicationFinalContainerProps
> = (props) => {
  const history = useHistory();
  const { mobile } = useContext(ServicesContext);

  const signedUpInThisSession = useSelector(
    (state: RootState) => state.ui.signedUpInThisSession,
  );

  const extensionInstalled = useHasExtension();

  const handleButtonClick = useCallback(() => {
    // If user just signed up, go to the post sign up page after the user has
    // gone through the stylist application.
    // Go to the dashboard page otherwise.
    if (signedUpInThisSession) {
      if (extensionInstalled || mobile.isApp) {
        history.push(R.CREATOR_DASHBOARD);
      } else {
        history.push(R.POSTSIGNUP);
      }
    } else {
      history.push(R.CREATOR_DASHBOARD);
    }
  }, [extensionInstalled, history, mobile.isApp, signedUpInThisSession]);
  return (
    <CreatorApplicationFinal onButtonClick={handleButtonClick} {...props} />
  );
};

export { CreatorApplicationFinalContainer as CreatorApplicationFinal };
