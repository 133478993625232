import { useContext } from 'react';
import { ServicesContext } from 'src/ServicesContext';
import { useSetFooterPopup } from 'src/hooks/useSetFooterPopup';
import { useWorking } from 'src/hooks/useWorking';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';

export const useDeleteProduct = (
  collectionUserUID: string,
  collectionUID: string,
  itemUID: string,
) => {
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  const [setDeleteWorking, unsetDeleteWorking, deleteWorkingValue] = useWorking(
    productDataStoreAs + '__delete',
  );

  const { cloud } = useContext(ServicesContext);
  const setFooterPopup = useSetFooterPopup();

  const deleteProduct = (event: MouseEvent) => {
    if (deleteWorkingValue === undefined) {
      setDeleteWorking(true);
      // ASYNC (but doesn't seem to cause a problem as no reference to this component after async.)
      cloud
        .fastAPI({
          api: 'delete_product',
          // assumes is own collection!
          collection_uid: collectionUID,
          product_uid: itemUID,
        })
        .then((result: any) => {
          unsetDeleteWorking();
          if (result.data.success === true) {
            setFooterPopup({
              type: 'item_deletion',
              data: {
                collectionUID,
                collectionUserUID,
                itemUID,
              },
              uid: productDataStoreAs + Date.now(),
            });
          }
        })
        .catch(unsetDeleteWorking);
    }
    event.preventDefault();
    return false;
  };

  return {
    deleteProduct,
    deleteWorkingValue,
  };
};
