import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { object, string } from 'yup';
import { RootState } from 'src/index';
import {
  AddAnItemPasteALinkContainerProps,
  AddAnItemPasteALinkProps,
} from './AddAnItemPasteALink.types';
import { AddAnItemPasteALink } from './AddAnItemPasteALink.component';
import { ServicesContext } from 'src/ServicesContext';

const validationSchema = object({
  pastedLink: string()
    .required('Please enter a link (including https://)')
    .test('startsWithHello', 'The link must start with "https://"', (value) => {
      if (!value) return false;
      return value.startsWith('https://') || value.startsWith('http://');
    }),
});

const AddAnItemPasteALinkContainer: React.FC<
  AddAnItemPasteALinkContainerProps
> = ({ onClose, ...otherProps }) => {
  const { analytics, cloud } = useContext(ServicesContext);

  const collectionUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.id ?? '',
  );

  const onAddLink = useCallback<AddAnItemPasteALinkProps['onAddLink']>(
    async (values, actions) => {
      await cloud.fastAPI2('startGetFromURL', {
        url: values.pastedLink,
        collections: [collectionUID],
      });
      onClose();
      analytics.recordEvent('WebApp:CalledAddAnItemPasteALink');
      actions.setSubmitting(false);
    },
    [analytics, collectionUID, onClose, cloud],
  );

  return (
    <AddAnItemPasteALink
      onAddLink={onAddLink}
      validationSchema={validationSchema}
      {...otherProps}
    />
  );
};

export { AddAnItemPasteALinkContainer as AddAnItemPasteALink };
