import { VFC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, array, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import { professions } from 'src/types/models/stylistApplication.model';
import { updateStylistApplicationRequest } from 'src/store/actions/stylistApplication';
import {
  StylistApplicationOneContainerProps,
  StylistApplicationOneProps,
  StylistApplicationOneFormContent,
} from './StylistApplicationOne.types';
import { StylistApplicationOne } from './StylistApplicationOne.component';

const validationSchema = object<StylistApplicationOneFormContent>({
  professions: array(string().oneOf(professions)).min(
    1,
    'Select at least one profession, please.',
  ),
});

const StylistApplicationOneContainer: VFC<
  StylistApplicationOneContainerProps
> = (props) => {
  const history = useHistory();

  const dipatch = useDispatch();
  const initialValues = useSelector<
    RootState,
    StylistApplicationOneFormContent
  >(({ stylistApplication }) => ({
    professions: [...stylistApplication.professions],
  }));

  const handleSubmit = useCallback<StylistApplicationOneProps['onSubmit']>(
    (values, actions) => {
      dipatch(updateStylistApplicationRequest(values));
      actions.setSubmitting(false);
      history.push(R.STYLIST_APPLICATION_TWO);
    },
    [dipatch, history],
  );

  return (
    <StylistApplicationOne
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export { StylistApplicationOneContainer as StylistApplicationOne };
