import { VFC } from 'react';
import styled from 'styled-components';

import { LandingBannerProps } from './LandingBanner.types';

const UnstyledLandingBanner: VFC<LandingBannerProps> = ({
  className,
  style,
  imagePath,
  imageAlt,
}) => {
  return (
    <div className={className} style={style}>
      <picture>
        <source
          media="(max-width: 620px)"
          srcSet={`${process.env.REACT_APP_STATIC_URL}${imagePath}.png`}
        />
        <img
          alt={imageAlt}
          src={`${process.env.REACT_APP_STATIC_URL}${imagePath}.2x.png`}
        />
      </picture>
    </div>
  );
};

export const LandingBanner = styled(UnstyledLandingBanner)`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  img {
    display: block;
  }
`;
