/*

For searching and displaying the results of the retailers page.

Update with design system + typescript

*/
import { useState, useContext } from 'react';
import { ServicesContext } from 'src/ServicesContext';
import styled from 'styled-components';
import debounce from 'lodash.debounce';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import { M } from 'src/mvp22/constants';
import { RetailerListItem } from 'src/mvp22/view-components/Retailers';

const SearchBarContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: ${(props) => (props.isDesktop ? '5px' : '94px')};
  margin-bottom: 49px;
  max-width: 1116px;
  align-items: center;
`;

const SearchBar = styled.input`
  border: 1px solid grey;
  height: 43px;
  width: 100%;
  outline: 0;
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  font-size: 26px;
  font-style: normal;
  font-weight: 300;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  ::placeholder {
    color: ${M.COL.TEXT.BLACK};
    opacity: 0.1;
  }
`;

const Cross = styled.img`
  color: default;
`;

const ExitLink = styled(TEXTSTYLE.LINKA)`
  position: relative;
  right: 20px;
  top: 5px;
`;

const SearchSection = styled.div`
  height: 100%;
`;

const ResultsWrapper = styled.div`
  padding-left: 30px;
  overflow-y: scroll;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ResultsWrapperInner = styled.div`
  text-align: left;
  max-width: 300px;
`;

const LiveSearch = ({
  fuse,
  isDesktop,
  goBack,
  ItemComponent = RetailerListItem,
  formatter = (item) => ({ retailer: item }),
  resultsRef,
  type,
}) => {
  const [results, setResults] = useState([]);

  const { analytics } = useContext(ServicesContext);

  const search = debounce((value) => {
    const newResults = fuse.search(value);
    setResults(newResults);
    if (value) {
      analytics.recordEvent(`WebApp:${type}Search`, { text: value });
    }
  }, 500);

  function handleSearch(event) {
    search(event.currentTarget.value);
  }

  // TODO: check the exit link doesn't work in safari
  return (
    <SearchSection className="searchSection">
      <SearchBarContainer isDesktop={isDesktop} className="searchBarContainer">
        <SearchBar
          autoFocus
          className="searchBar"
          type="text"
          placeholder="Type search here"
          onChange={handleSearch}
        />
        <ExitLink href="#" onClick={goBack}>
          <Cross src={MEDIA.EXIT_CROSS} />
        </ExitLink>
      </SearchBarContainer>
      <ResultsWrapper ref={resultsRef} className="resultsWrapper">
        <ResultsWrapperInner className="resultsWrapperInner">
          {results.map((item, i) => {
            return (
              <ItemComponent
                key={i}
                {...formatter(item)}
                className="featuredListItem"
              />
            );
          })}
        </ResultsWrapperInner>
      </ResultsWrapper>
    </SearchSection>
  );
};

export default LiveSearch;
