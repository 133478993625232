import React from 'react';
import styled from 'styled-components';
import { Stack, CtaButton, Typography } from '@moonsifttech/design-system';

import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { FiveStars } from 'src/components/common/FiveStars';
import { BrowserExtensionButtonProps } from './BrowserExtensionButton.types';

const UnstyledBrowserExtensionButton: React.FC<BrowserExtensionButtonProps> = ({
  className,
  style,
  fullWidth,
  children,
  to,
  target,
  size = 'large',
  onClick,
}) => {
  return (
    <div className={className} style={style}>
      <CtaButton
        className="BrowserExtensionButton-button"
        variant="primary-black"
        size={size}
        fullWidth={fullWidth}
        component={UnstyledLink}
        to={to}
        target={target}
        onClick={onClick}
      >
        {children}
      </CtaButton>
      <Stack
        className="BrowserExtensionButton-browsersCopyContainer"
        direction="row"
        alignItems="center"
        spacing={6}
      >
        <img
          className="BrowserExtensionButton-browserIcon"
          src={`${process.env.REACT_APP_STATIC_URL}/landing/chrome-icon.svg`}
          alt="Chrome icon"
        />
        <img
          className="BrowserExtensionButton-browserIcon"
          src={`${process.env.REACT_APP_STATIC_URL}/landing/edge-icon.png`}
          alt="Edge icon"
        />
        <img
          className="BrowserExtensionButton-browserIcon"
          src={`${process.env.REACT_APP_STATIC_URL}/landing/brave-icon.png`}
          alt="Brave icon"
        />
        <Typography variant="primary.r14" color="grey.3">
          Designed for Chrome, Edge & Brave browsers
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={6}>
        <FiveStars size="small" />
        <Typography variant="primary.r12" color="grey.3">
          5 star average Chrome Store rating
        </Typography>
      </Stack>
    </div>
  );
};

export const BrowserExtensionButton = styled(UnstyledBrowserExtensionButton)`
  .BrowserExtensionButton-button {
    margin-bottom: 12px;
  }

  .BrowserExtensionButton-browsersCopyContainer {
    margin-bottom: 6px;

    .BrowserExtensionButton-browserIcon {
      width: 13px;
    }
  }
`;
