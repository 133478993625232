/*
Very old component!!

Orly page! (404 error)  Essentially a static page.

TODO: typescript, camelCase, make functional, non-default exports, design system...
*/
import React from 'react';
import { Component } from 'react';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import styled, {keyframes} from 'styled-components';
import {M} from 'src/mvp22/constants';
import R, {EXTERNAL} from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';

const rotate = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  padding-left:30px;
  padding-right:30px;
  font-size:20px;
  height:100vh;
  display:flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  color:${M.COL.TEXT.ERRORDARK}
`;

const MoonsiftLogo = styled.div`
  width:200px;
  animation: ${rotate} 3s linear infinite;
`;

const HomepageLink = styled(TEXTSTYLE.BODY2)`
  color:black;
  margin-top:12px;
`;
/**
* 404 Page Component
*/
class FourOhFour extends Component {
  render(){
    return(<Container>
      {AddHelmetTitle("Page not found (404)")}
      <MoonsiftLogo>
        <LogoAndText heighttype="A"/>
      </MoonsiftLogo>
      <br/>
      <TEXTSTYLE.HEADING4>
        <TEXTSTYLE.LINKA target="_blank" href={EXTERNAL.ORLY}>Page not found</TEXTSTYLE.LINKA>
      </TEXTSTYLE.HEADING4>
      <TEXTSTYLE.BODY3 style={{fontWeight:400}}>
        <p style={{fontWeight:"bold"}}>The page &ldquo;{this.props.location.pathname}&rdquo; could not be found.</p><br/> User profiles require an `@` prefix, perhaps give <TEXTSTYLE.LINK style={{color:'black'}} to={"/@"+this.props.location.pathname.slice(1,)}>/@{this.props.location.pathname.slice(1,)}</TEXTSTYLE.LINK> a go?<br/><br/> Please let us know if you think you should be able to find something at this address.
      </TEXTSTYLE.BODY3>
      <TEXTSTYLE.LINK to={R.HOME}>
        <HomepageLink style={{fontWeight:400}}>
          Go to homepage
          </HomepageLink>
      </TEXTSTYLE.LINK>
      </Container>
    );
  }
}

export default FourOhFour;
