/*

Gives a linear Moonsift Logo + Name at specified height codes.

TODO: typescript, functional, camelcase

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

const Container = styled.div`
  text-align:center;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:row;
`;

const LogoImgA = styled.img`
  height:50px;
`;

const MoonsiftA = styled(TEXTSTYLE.BRANDED1)`
  color:${M.COL.TEXT.BLACK};
  padding-top:7px;
  padding-left:6px;
`;

const LogoImgB = styled.img`
  height:15px;
`;

const MoonsiftB = styled(TEXTSTYLE.BRANDED4)`
  color:${M.COL.TEXT.WHITE};
  padding-top:3px;
  padding-left:5px;
`;

const LogoImgC = styled.img`
  height:16px;
`;


const MoonsiftC = styled(TEXTSTYLE.BRANDED4)`
  color:${M.COL.TEXT.BLACK};
  padding-top:4px;
  padding-left:2px;
`;

const LogoImgD = styled.img`
  height:32px;
`;

const MoonsiftD = styled(TEXTSTYLE.BRANDED5)`
  color:${M.COL.TEXT.BLACK};
  padding-top:4px;
  padding-left:15px;
`;

const LogoImgDefault = styled.img`
  height:50px;
  background-color:${M.COL.UC.BG};
`;

const MoonsiftDefault = styled(TEXTSTYLE.BRANDED1)`
  padding-top:10px;
  background-color:${M.COL.UC.BG};
  color:${M.COL.UC.FG};
`;


/**
* Login to Moonsift Component
*/
class LogoAndText extends Component {
  static propTypes = {
    /** Height TYPE of logo*/
    heighttype: PropTypes.string,
  }

  getInnuds(){
    switch(this.props.heighttype){
      case "A":{
        return([LogoImgA,MoonsiftA,MEDIA.LOGO_DEFAULT]);
      }
      case "B":{
        return([LogoImgB,MoonsiftB,MEDIA.LOGO_DARK]);
      }
      case "C":{
        return([LogoImgC,MoonsiftC,MEDIA.LOGO_DEFAULT]);
      }
      case "D":{
        return([LogoImgD,MoonsiftD,MEDIA.LOGO_DEFAULT]);
      }
      default:{
        return([LogoImgDefault,MoonsiftDefault,MEDIA.LOGO_DEFAULT]);
      }
    }

  }

  render() {
    const { className } = this.props;
    const INNUDS = this.getInnuds();
    const LOGOIMG = INNUDS[0];
    const MOONSIFT = INNUDS[1];
    return(
      <Container className={className}>
        <LOGOIMG src={INNUDS[2]}/>
        <MOONSIFT>MOONSIFT</MOONSIFT>
      </Container>
    );
  }
}

export default LogoAndText;
