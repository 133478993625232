import {
  CollectionRegistryUiState,
  CollectionRegistryUiAction,
  ActionNames,
} from 'src/types';

const COLLECTION_REGISTRY_UI_DEFAULT: CollectionRegistryUiState = {
  isRevealing: false,
};

/**
 * Reducer for the UI local state used in the registry collection page.
 */
export const collectionRegistryUi = (
  state = COLLECTION_REGISTRY_UI_DEFAULT,
  action: CollectionRegistryUiAction,
): CollectionRegistryUiState => {
  switch (action.type) {
    case ActionNames.COLLECTION_REGISTRY_UI_IS_REVEALING_TOGGLE:
      return {
        ...state,
        isRevealing: !state.isRevealing,
      };
    case ActionNames.UNSET_ALL:
      return COLLECTION_REGISTRY_UI_DEFAULT;
    default:
      return state;
  }
};
