/*

Old component!

Replace any occurrences with design system textboxes.

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';

const StandardInput= styled.textarea`
  box-sizing:border-box;
  outline-width: 0;
  font-family:${M.FONT};
  border:none;
  line-height:14px;
  font-size:16px;
  font-weight:normal;
  font-style:normal;
  width:${props => props.width};
  max-width:${props => props.maxWidth};
  padding:10px;
  resize:${props =>props.resize};
  height:${props=>props.height};
`;

export const BrightInput = styled(StandardInput)`
  background-color:${M.COL.BUTTON.WHITE};
  border-color:${props=>(props.isValid===false)?M.COL.TEXT.ERROR:(props.value==="")?M.COL.LINE.MID:M.COL.BUTTON.BLACK};
  border-style:solid;
  border-width:1px;
  border-radius:2px;
  color:${props=>(props.value==="")?M.COL.TEXT.LIGHT:M.COL.TEXT.BLACK};
  &:focus {
   color:${M.COL.TEXT.BLACK};
   border-color:${M.COL.BUTTON.BLACK};
  }
`;


const VoidInput = styled(StandardInput)`
  background-color:${M.COL.UC.BG};
  border-color:${M.COL.UC.FG};
  color:${M.COL.UC.FG};
`;

/**
* Base text input class
*/
class TextBox extends Component {
  static propTypes = {
    /** Width of field OPTIONAL */
    width: PropTypes.string,
    /** Maximum width OPTIONAL */
    maxWidth: PropTypes.string,
    /** Style type for field to use preset Moonsift styles */
    msstyle: PropTypes.string,
    /** current value */
    value: PropTypes.string,
    /** function to run on change */
    onChange:PropTypes.func,
    /** name of the field */
    name:PropTypes.string,
    /** placeholder for textbox */
    placeholder:PropTypes.string,
    /** theme for style */
    type:PropTypes.string,
    /** focus on mounting? */
    focusOnMount:PropTypes.bool,
    /** focus on mounting? */
    tabIndex:PropTypes.number,
    /** is valid or not? */
    isValid:PropTypes.bool,
  }

  constructor(props){
    super(props);
    this.getInnuds = this.getInnuds.bind(this);
    this.focusMe = this.focusMe.bind(this);
    // a reference to this component:
    this.textRef = React.createRef();
  }

  focusMe(){
    if (this.props.focusOnMount===true){
      this.textRef.current.focus();
    }
  }

  componentDidUpdate(){
  }

  componentDidMount(){
    this.focusMe();
  }

  getInnuds(){
    switch(this.props.msstyle){
      case "standard":{
        return(BrightInput);
      }
      default:{
        return(VoidInput);
      }
    }
  }

  render(){
    const Innuds = this.getInnuds();
    return(
      <Innuds
        ref={this.textRef}
        type={this.props.type}
        width={this.props.width}
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder={this.props.placeholder}
        name={this.props.name}
        isValid={this.props.isValid}
        maxWidth={this.props.maxWidth}
        tabIndex={this.props.tabIndex}
        resize={(this.props.resize)?undefined:"none"}
        height={this.props.height}
      />
    );
  }

}

export default TextBox;
