/*

REDUX (not DB)

TODO: move to new ui redux at:
react-app/src/mvp22/redux-components/reducers/generalui.js

*/
import {
  SET_CBCOPY,
  SET_LOGIN_STARTED_TIMER
} from 'src/mvp22/redux-components/actions';

const DEFAULT_GENERALUI = {
  showCopyMsg:false,
  login_started_timer_on:false
};

function generalui(state = DEFAULT_GENERALUI, action) {
  switch (action.type) {
    case SET_CBCOPY:
      return Object.assign({},state,{showCopyMsg:action.data});
    case SET_LOGIN_STARTED_TIMER:
      return Object.assign(
        {},
        state,
        {
          login_started_timer_on:action.data
        }
      );
    default:
      return state;
  }
}

export default generalui;
