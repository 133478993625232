/*

A prompt to enable notifications.

Uses EnableNotifications panel.

TODO: camelCase, design system?

*/
import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { M } from 'src/mvp22/constants';
import EnableNotifications from 'src/mvp22/menu-components/EnableNotifications';
import MEDIA from 'src/mvp22/media';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { Constants } from 'src/constants';
import { checkIfSafari } from 'src/utils/checkIfSafari';

const PopupContainer = styled.div`
  width: 360px;
  background: ${M.COL.BG.WHITE};
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 16px;
  right: 16px;
  z-index: 2000;
  color: ${M.COL.TEXT.BLACK};
  overflow: hidden;
`;

const PopupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`;

const CrossImageContainer = styled(TEXTSTYLE.LINKA)`
  color: default;
`;

const CrossImage = styled.img`
  height: 14px;
  width: 14px;
`;

const PopupImage = styled.img`
  margin: 36px;
`;

const PopupImageContainer = styled.div`
  background-color: ${M.COL.BUTTON.MIDYELLOW};
`;

const PopupTitleText = styled(TEXTSTYLE.HEADING4)`
  padding: 16px;
  padding-top: 24px;
  width: 100%;
  text-align: left;
  box-sizing: border-box;
`;

const PopupDescriptionText = styled(TEXTSTYLE.BODY2)`
  padding: 0px 16px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
`;

const EnableNotificationsContainer = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
`;

/**
 * Notifications Popup Component
 */
const ThisComponent: React.FC = () => {
  const show_get_notifications = useSelector(
    (state: RootState) => state.firestore_notifications.show_get_notifications,
  );
  const { cloud } = useContext(ServicesContext);
  const [notifications_enabled, setNotificationsEnabled] = useState<
    boolean | null
  >(null);
  const [notifications_hide_immediately, setNotificationsHideImmediately] =
    useState(false);

  const close_popup = (event: React.MouseEvent) => {
    event.preventDefault();
    setNotificationsHideImmediately(true);
    cloud.fastAPI({
      api: 'update_notification_get_popup',
      value: false,
    });
    return false;
  };

  useEffect(() => {
    // different - don't show if no notification option:
    setNotificationsEnabled(
      !('Notification' in window) ||
        (Notification && Notification.permission === 'granted'),
    );
  }, []);

  return notifications_enabled === false &&
    show_get_notifications === true &&
    notifications_hide_immediately !== true ? (
    <PopupContainer>
      <PopupTitle>
        <LogoAndText heighttype="C" />
        <CrossImageContainer href="#" onClick={close_popup}>
          <CrossImage src={MEDIA.EXIT_CROSS} />
        </CrossImageContainer>
      </PopupTitle>
      <PopupImageContainer>
        <PopupImage src={MEDIA.PRICE_DROPS_AND_COMMENTS_POPUP} />
      </PopupImageContainer>
      <PopupTitleText>Browser Notifications</PopupTitleText>
      <PopupDescriptionText>
        Enable notifications so you'll know about price drops and comments on
        items you've saved.
      </PopupDescriptionText>
      <EnableNotificationsContainer>
        <EnableNotifications
          setNotificationsEnabled={setNotificationsEnabled}
          show_notification_notification={false}
        />
      </EnableNotificationsContainer>
    </PopupContainer>
  ) : null;
};

// Safari does not support push notifications yet.  And we only want to show on desktop:
const WrappedComponent = () => {
  const isDesktop = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth >= Constants.MOBILESWITCH,
  );
  const isSafari = checkIfSafari();
  return isDesktop && !isSafari ? <ThisComponent /> : null;
};

export default WrappedComponent;
