import { useCallback, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/index';
import { ProWelcomeContainerProps } from './ProWelcome.types';
import { ProWelcome } from './ProWelcome.component';

const ProWelcomeContainer: VFC<ProWelcomeContainerProps> = (props) => {
  const isPro = useSelector<RootState, boolean>((state) => {
    return state.firestore_user_owner.pro ? true : false;
  });

  const [reloading, setReloading] = useState(false);

  const handleReload = useCallback(() => {
    setReloading(true);
    window.location.reload();
  }, []);

  return (
    <ProWelcome
      isPro={isPro}
      workingReload={reloading}
      onReloadClick={handleReload}
      {...props}
    />
  );
};

export { ProWelcomeContainer as ProWelcome };
