/*

This modal allows the product given to it in the props to be added

TODO: finish camelCase, typescript, design system

*/

import React from 'react';
import { useSelector } from 'react-redux';
import {useClickOutside} from 'src/hooks/useClickOutside';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {ProductLeft} from 'src/mvp22/modal-components/ProductLeft';
import MEDIA from 'src/mvp22/media';
import {
  GeneralOverlayWithinContainer,
  RightContainerProduct,
  RightTitleContainer,
  LogoAndTitle,
  Cross,
} from 'src/mvp22/modal-components/ModalComponents.js';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';


// Used in the Add Product component
export const CollectionListContainer = styled.div`
  overflow-y:auto;
  flex-grow: 1;
  overscroll-behavior:none;
  border-left:solid ${M.COL.LINE.MID} 0.5px;
  ::-webkit-scrollbar {
    background: #FFFFFF;
    border-left:1px solid #EFEFEF;
    box-sizing: border-box;
    width:15px;
  }
  ::-webkit-scrollbar-thumb {
    background: #CECFD1;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:rgb(136, 136, 136);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
`;


export const CollectionNameFlex = styled(TEXTSTYLE.OTHER2)`
  flex-grow: 1;
  text-align:left;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  margin-right: 10px;
  text-decoration:none;
  color:${M.COL.TEXT.BLACK};
`;

export const CollectionEntry = styled(TEXTSTYLE.LINKA)`
  flex-shrink: 0;
  width:100%;
  display:flex;
  flex-direction:row;
  overflow-x:hidden;
  align-items:center;
  justify-content:center;
  align-content: center;
  padding:6px 26px;
  box-sizing: border-box;
  cursor:pointer;
  background-color:${props=>props.added?M.COL.BG.MID:M.COL.BG.WHITE};
  &:hover {
    background-color:${M.COL.BG.MID};
  }
  &:focus {
    background-color:${M.COL.BG.MID};
    outline:none;
  }
  position:relative;
  z-index:1;
`;

export const PlusSquare = styled.div`
  background-color: ${M.COL.BG.WHITE};
  background-image:url(${MEDIA.BLACK_PLUS});
  background-position: center;
  border:1px solid ${M.COL.TEXT.BLACK};
  border-radius:21px;
  box-sizing: border-box;
  margin-right: 16px;
  flex-shrink:0;
  height: 42px;
  width: 42px;
  background-size: 20px;
  background-repeat: no-repeat;
  ${CollectionEntry}:hover & {
    background-color: ${M.COL.BG.BLACK};
    background-image:url(${MEDIA.WHITE_PLUS});
  }
`;

const ButtonBase = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 7px 26px 3px 26px;
  font-family: Moonsiftmain, Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor:pointer;
`;

const ButtonAddWorking = styled(ButtonBase)`
  background: #FFFFFF;
  border: 2px solid #FDCA47;
  flex-shrink: 0;
  flex-grow:0;
  color: #888888;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
  width:92px;
  box-sizing:border-box;
  padding-bottom:6px;
`;

export const ButtonAdd = styled(ButtonBase)`
  color: #000000;
  background-color: #FFFFFF;
  border: 1px solid #FDCA47;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  flex-grow:0;
  width:92px;
  box-sizing:border-box;
  padding-bottom:6px;
  &:hover {
    border-width:2px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;

export const ButtonRemove = styled(ButtonBase)`
  color: #FFFFFF;
  background: #000000;
  border: 1px solid black;
  flex-shrink: 0;
  flex-grow:0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  width:92px;
  padding-bottom:6px;
  box-sizing:border-box;
  &:hover {
    border: 2px solid #000000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  }
`;


const ButtonRemoveWorking = styled(ButtonBase)`
  color: #FFFFFF;
  flex-shrink: 0;
  flex-grow:0;
  background-color: #707070;
  border: 2px solid #707070;
  box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
  width:92px;
  padding-bottom:6px;
  box-sizing:border-box;
`;

export const CollectionSquare = styled.div`
  padding: 21px;
  border-radius:21px;
  background-color:rgba(196, 196, 196, 0.5);
  margin-right:16px;
  flex-shrink:0;
  background-image:${props=>(props.circle_url!==undefined)?"url(\""+props.circle_url+"\")":""};
  background-size:cover;
`;

const CloseCross = styled(Cross)`
  box-sizing: initial;
`;

const DefaultComponent = (
  {
    // params:
    collectionUID,
    collectionUserUID,
    collectionItemUID,
    productImage,
    productData,
    // maybe this be scoped better to this product to reduce re-renders?
    // Need to be careful this doesn't get refreshed so often, could move in here!
    // Using redux due to maybe user switching between modal views
    workingDict,
    // functions:
    closeFunction,
    openAddToNewCollection,
    setCollectionMembership
  }
)=>{
  const containerRef = useClickOutside(closeFunction);
  const collection_list_snapshot = useSelector(state=>state.firestore_product_membership.collection_list_snapshot);

  // productData stored Locally in Modal on first load as it may disappear if deleted from collection!!

  return(
    <GeneralOverlayWithinContainer ref={containerRef}>
    <ProductLeft
      productData={productData}
      productImage={productImage}
    />
      <RightContainerProduct>
        <RightTitleContainer>
          <LogoAndTitle>
            <TEXTSTYLE.HEADING5>Add item to collection</TEXTSTYLE.HEADING5>
          </LogoAndTitle>
          <TEXTSTYLE.LINKA href="#" tabindex="13002" onClick={closeFunction}><CloseCross src={MEDIA.EXIT_CROSS_THIN} /></TEXTSTYLE.LINKA>
        </RightTitleContainer>
        <CollectionListContainer>
        <CollectionEntry href="#" tabindex="12999" onClick={openAddToNewCollection}>
          <PlusSquare/>
          <CollectionNameFlex>New collection</CollectionNameFlex>
        </CollectionEntry>
          {
            (collection_list_snapshot !== undefined)?
              collection_list_snapshot.map(
                (data,index)=>{
                  const setting_collection_uid = data[0].uid;
                  const working_code = setting_collection_uid+"__"+collectionUID+"__"+collectionItemUID+"__set_collection_membership";
                  const addtest = workingDict[working_code]===true;
                  const removetest = workingDict[working_code]===false;
                  const AddbuttonA = (addtest)?ButtonAddWorking:ButtonAdd;
                  const RemoveButtonA = (removetest)?ButtonRemoveWorking:ButtonRemove;
                  return(
                    <CollectionEntry
                     href="#"
                     index={index}
                     added={data[1]}
                     key={index}
                     tabindex="13001"
                     onClick={
                      (event)=>
                      {
                        setCollectionMembership(
                          data[0].uid,
                          (data[1])?false:true,
                        );
                        event.preventDefault();
                        return false;
                      }
                     }
                     collection_uid={data[0].uid}
                    >
                      <CollectionSquare
                        circle_url={data[0].circle_url}
                      />
                      <CollectionNameFlex>{data[0].name}</CollectionNameFlex>
                        {
                          (data[1]===false)?
                            <AddbuttonA>
                            Add
                            {
                              (addtest)?
                                <LoadingDots msstyle="standard"/>
                              :
                                ""
                            }
                            </AddbuttonA>
                          :
                            <RemoveButtonA>
                            Remove
                            {
                              (removetest)?
                                <LoadingDots msstyle="dark"/>
                              :
                                ""
                            }
                            </RemoveButtonA>
                        }
                    </CollectionEntry>
                  );
                }
              ):""
          }
        </CollectionListContainer>
      </RightContainerProduct>
    </GeneralOverlayWithinContainer>
  );
};

export default DefaultComponent;
