import styled from 'styled-components';
import { VFC } from 'react';
import { Stack } from '@moonsifttech/design-system';
import { UseCaseCard } from 'src/components/common/UseCaseCard';
import R from 'src/routes';
import { UseCasesProps, UseCaseKeys } from './UseCases.types';

type UseCasesDict = Record<
  UseCaseKeys,
  {
    imagePath: string;
    heading: string;
    content: string;
    link: string;
  }
>;

const useCasesDict: UseCasesDict = {
  baby: {
    imagePath: '/registry-landing/baby',
    heading: 'Baby registry',
    content:
      'Get all the things you need and avoid duplicate gifts at your baby shower.',
    link: R.BABY_REGISTRY_LANDING,
  },
  wedding: {
    imagePath: '/registry-landing/wedding',
    heading: 'Wedding registry',
    content:
      "Don't limit your bridal registry to one store, add items from anywhere.",
    link: R.REGISTRY_LANDING,
  },
  christmas: {
    imagePath: '/registry-landing/christmas',
    heading: 'Christmas list',
    content:
      'Help friends and family pick the Christmas presents you really want.',
    link: R.CHRISTMAS_LANDING,
  },
  birthday: {
    imagePath: '/registry-landing/birthday',
    heading: 'Birthday wishlist',
    content:
      'Make it easy for loved ones to buy for you and receive the gifts you really want.',
    link: R.BIRTHDAY_LANDING,
  },
  wishlist: {
    imagePath: '/registry-landing/personal-wishlist',
    heading: 'Personal wishlist',
    content:
      "Stop ending up with 100's of tabs open. Keep track of everything in one place.",
    link: R.WISHLIST_MAKER,
  },
  stylists: {
    imagePath: '/registry-landing/creators',
    heading: 'Stylists & Creators',
    content:
      'Curate and share inspiring virtual edits. Centralise all your affiliate links.',
    link: R.ALL_CREATORS,
  },
};

const defaultOrder = Object.keys(useCasesDict) as UseCaseKeys[];

const UnstyledUseCases: VFC<UseCasesProps> = ({
  className,
  style,
  hideCTA,
  hidden,
  goToSignUp,
  order,
}) => (
  <Stack
    direction="row"
    justifyContent="center"
    wrap="wrap"
    style={style}
    className={className}
  >
    {(order ?? defaultOrder).map(
      (key) =>
        !(hidden && hidden.includes(key)) && (
          <UseCaseCard
            key={key}
            imagePath={useCasesDict[key].imagePath}
            heading={useCasesDict[key].heading}
            content={useCasesDict[key].content}
            hideCTA={hideCTA}
            ctaLink={
              goToSignUp && goToSignUp.includes(key)
                ? R.SIGNUP
                : useCasesDict[key].link
            }
          />
        ),
    )}
  </Stack>
);
export const UseCases = styled(UnstyledUseCases)`
  ${UseCaseCard} {
    margin: 12px;
    background-color: var(--common-white);
  }
`;
