// Use the address bar to get a collection UID and also user name (could be an
// alias - a "displayUsername"):
export const getCollectionUIDFromLocation = (
  pathname: string,
  authUserUID: string,
) => {
  var collectionUID: string;
  var username: string = authUserUID;
  try {
    collectionUID = pathname.split('/')[3];
    username = pathname.split('/')[2];
  } catch (error) {
    return undefined;
  }
  if (
    collectionUID &&
    collectionUID.length >= 1 &&
    username &&
    username.length >= 1
  ) {
    return { collectionUID, username };
  } else {
    return undefined;
  }
};
