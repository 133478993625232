import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Stack,
  GiftIcon,
  FormControlLabel,
  Checkbox,
} from '@moonsifttech/design-system';

import { RegistryConfigurationProps } from './RegistryConfiguration.types';
import clsx from 'clsx';

const UnstyledRegistryConfiguration: VFC<RegistryConfigurationProps> = ({
  className,
  style,
  isRegistry,
  toggleIsRegistry,
}) => {
  return (
    <Stack
      className={clsx('RegistryConfiguration-minimumHeight', className)}
      style={style}
    >
      <Stack className="RegistryConfiguration-registryContainer">
        <FormControlLabel
          id="form-registry-checkbox"
          className="RegistryConfiguration-isRegistryToggle"
          name="form-registry-checkbox"
          label={
            <Stack direction="row">
              <GiftIcon className="RegistryConfiguration-giftIcon" />
              Make it a Gift List?
            </Stack>
          }
          labelPlacement="end"
          control={<Checkbox className="RegistryConfiguration-checkboxLeft" />}
          checked={isRegistry}
          onChange={toggleIsRegistry}
        />
        <Typography
          className="RegistryConfiguration-registryExplanationDescription"
          variant="primary.l14"
          component="p"
        >
          Allows visitors to mark-off what they buy.
          <br />
          Perfect for Wedding & Baby Registries, Birthdays, Christmas etc.
        </Typography>
      </Stack>
    </Stack>
  );
};

export const RegistryConfiguration = styled(UnstyledRegistryConfiguration)`
  .RegistryConfiguration-publicRegistryToggle {
    margin-top: -8px;
    margin-bottom: 4px;
  }

  .RegistryConfiguration-giftIcon {
    margin-right: 4px;
  }

  .RegistryConfiguration-registryContainer {
    margin-bottom: 16px;
  }
  .RegistryConfiguration-registryExplanationDescription {
    margin-bottom: 8px;
    color: var(-grey--1);
  }

  .RegistryConfiguration-publicRegistryToggle {
    margin-top: 8px;
  }

  .RegistryConfiguration-minimumHeight {
  }
  .RegistryConfiguration-isRegistryToggle {
    margin-left: -8px;
  }
`;
