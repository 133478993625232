import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import { RootDB } from 'src/types';
// TODO: Replace all these with template reducers:
import firebasestate from 'src/mvp22/redux-components/reducers/firebasestate';
import generalui from 'src/mvp22/redux-components/reducers/generalui';
import firestore_user_owner from 'src/mvp22/redux-components/reducers/firestore_user_owner';
import firestore_user_collection_list from 'src/mvp22/redux-components/reducers/firestore_user_collection_list';
import firestore_product_membership from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import firestore_notifications from 'src/mvp22/redux-components/reducers/firestoreNotifications';

import { auth } from './auth';
import { ui } from './ui';
import { userData } from './userData';
import { footerPopup } from './footerPopup';
import { modal } from './modal';
import { working } from './working';
import { productData } from './productData';
import { creatorApplication } from './creatorApplication';
import { stylistApplication } from './stylistApplication';
import { creatorPayoutDetails } from './creatorPayoutDetails';
import { collectionRegistryUi } from './collectionRegistryUi';
import {
  getBaseDBReducer,
  DEFAULT_QUERY_STATE,
  DEFAULT_DOC_STATE,
  DEFAULT_DOC_MAP_STATE,
  DEFAULT_QUERY_MAP_STATE,
} from './baseDB';

// Add DB states here:!
const DEFAULT_STATE: RootDB = {
  single_collection: DEFAULT_DOC_STATE,
  single_collection_item_list: DEFAULT_QUERY_STATE,
  single_collection_bookmark: DEFAULT_DOC_STATE,
  single_collection_views: DEFAULT_DOC_STATE,
  user_public: DEFAULT_DOC_MAP_STATE,
  user_verified_settings: DEFAULT_DOC_STATE,
  single_collection_scrape: DEFAULT_DOC_MAP_STATE,
  single_collection_product: DEFAULT_DOC_MAP_STATE,
  single_collection_product_is_saved: DEFAULT_QUERY_MAP_STATE,
  single_collection_product_is_purchased: DEFAULT_QUERY_MAP_STATE,
  single_collection_product_is_liked_or_disliked: DEFAULT_QUERY_MAP_STATE,
  collection_earnings_list: DEFAULT_QUERY_STATE,
  collection_registry_private: DEFAULT_QUERY_STATE,
  collection_registry_notes: DEFAULT_DOC_STATE,
  collection_list: DEFAULT_DOC_STATE,
  collection_list_map: DEFAULT_DOC_MAP_STATE,
  collection_images_map: DEFAULT_DOC_MAP_STATE,
};

const reducers = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    db: getBaseDBReducer(DEFAULT_STATE),
    productData,
    ui,
    userData,
    footerPopup,
    modal,
    working,
    creatorApplication,
    stylistApplication,
    creatorPayoutDetails,
    collectionRegistryUi,
    // TODO: replace these:
    firebasestate,
    generalui,
    firestore_user_collection_list,
    firestore_user_owner,
    firestore_product_membership,
    firestore_notifications,
  });

export default reducers;
