/*

This page is essentially a static page promoting moonsift to stylsits (formerly tastemakers.)

TODO: typescript, make functional, non-default exports, design system ...
Change names from tastemakers to stylists

*/
import { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled from 'styled-components';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { Typography, Stack, Link } from '@moonsifttech/design-system';

import { M } from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import Button from 'src/mvp22/form-components/Button';
import ReviewsCarousel from 'src/mvp22/tile-components/ReviewsCarousel';
import windowSize from 'src/mvp22/WindowSize';
import { FiveStars } from 'src/components/common/FiveStars';
import { BrowserExtensionButton } from 'src/components/common/BrowserExtensionButton';
import { LandingBanner } from 'src/components/common/LandingBanner';
import R from 'src/routes';

const Container = styled.div`
  background-color: #ffdddd;
  width: 100%;
  overflow: visible;
  min-width: ${M.MINWIDTH};
  display: flex;
  flex-direction: column;
  align-items: center;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  -webkit-font-smoothing: subpixel-antialiased;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  color: ${M.COL.TEXT.BLACK};
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
  width: 100%;
`;

const GreySeperator = styled(Seperator)`
  background-color: ${M.COL.BG.LIGHT};
  width: 100%;
`;

const TopRowContainer = styled.div`
  background-image: url(${MEDIA.TASTEMAKERS_TOP});
  background-size: cover;
  width: 100%;
`;

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: visible;
  justify-content: center;
`;

const GreyRowContainer = styled(RowContainer)`
  background-color: ${M.COL.BG.LIGHT};
`;
const WhiteRowContainer = styled(RowContainer)`
  background-color: ${M.COL.BG.WHITE};
`;
const BlackRowContainer = styled(RowContainer)`
  background-color: ${M.COL.BG.BLACK + '88'};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1022px;
  flex-wrap: wrap;
`;

const TopRow = styled(Row)`
  flex-direction: row-reverse;
  color: ${M.COL.TEXT.WHITE};
  height: ${(props) => (props.isDesktop ? '585px' : 'auto')};
`;

const MiddleRow = styled(Row)`
  flex-direction: row-reverse;
  max-width: ${(props) => (props.isDesktop ? '1200px' : '420px')};
`;

const BottomRow = styled(Row)`
  flex-direction: row;
  max-width: ${(props) => (props.isDesktop ? '1200px' : '420px')};
`;

const ItemBox = styled.div`
  font-size: 20px;
`;

const ImageContainer = styled(ItemBox)`
  width: ${(props) => (props.isDesktop ? '50%' : '100%')};
`;

const TextContainer = styled(ItemBox)`
  width: ${(props) => (props.isDesktop ? '50%' : '100%')};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ItemImage = styled.img`
  font-size: 20px;
`;

const MiddleImage = styled(ItemImage)`
  width: 100%;
  box-sizing: border-box;
  max-width: ${(props) => (props.isDesktop ? '442px' : '384px')};
  @media (min-width: 429px) {
    position: relative;
    right: 10px;
  }
`;

const BottomImage = styled(ItemImage)`
  width: 100%;
  max-width: ${(props) => (props.isDesktop ? '449px' : '384px')};
  margin-bottom: ${(props) => (props.isDesktop ? '' : '49px')};
  @media (min-width: 429px) {
    position: relative;
    right: 10px;
  }
`;

const ItemText = styled.div`
  font-size: 20px;
  margin: 0px 16px;
`;

const TopText = styled(ItemText)`
  margin-top: 68px;
  margin-bottom: 42px;
  text-align: center;
`;

const MiddleText = styled(ItemText)`
  max-width: 440px;
  margin-top: ${(props) => (props.isDesktop ? '68px' : '51px')};
  margin-bottom: ${(props) => (props.isDesktop ? '68px' : '42px')};
  text-align: left;
`;

const BottomText = styled(ItemText)`
  max-width: 440px;
  margin-top: ${(props) => (props.isDesktop ? '89px' : '50px')};
  margin-bottom: ${(props) => (props.isDesktop ? '114px' : '35px')};
  text-align: left;
`;

const MaxWidthContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  max-width: ${(props) => (props.isDesktop === true ? '1116px' : '420px')};
`;

const SecondRowTitle = styled.div`
  color: ${M.COL.TEXT.BLACK};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  margin-left: 11px;
  align-self: flex-start;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const SecondRowInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 990px;
`;

const RequestMoonsiftPro = styled(({ className, style }) => {
  return (
    <div className={className} style={style}>
      <Typography
        className="RequestMoonsiftPro-link"
        variant="editorial.r22"
        component="p"
      >
        <Link to={R.STYLIST_APPLICATION_ONE}>Get started - it's free</Link>
      </Typography>
      <Stack direction="row" alignItems="center">
        <FiveStars className="RequestMoonsiftPro-fiveStars" size="small" />
        <Typography variant="primary.r12" color="grey.3">
          5 Star Chrome Store rating
        </Typography>
      </Stack>
    </div>
  );
})`
  .RequestMoonsiftPro-link {
    margin-bottom: 6px;
  }

  .RequestMoonsiftPro-fiveStars {
    margin-right: 6px;
  }
`;

class UnstyledTastemakers extends Component {
  render() {
    const ISDESKTOP = this.props.windowWidth >= M.MOBILESWITCH;
    return (
      <>
        <Helmet>
          <title>
            Online Tools for Stylists and Personal Shoppers | Moonsift
          </title>
          <meta
            name="description"
            content={[
              'Curate and share shoppable virtual edits from any brand.',
              'Earn affiliate commissions from thousands of stores.',
              'Free.',
            ].join(' ')}
          />
        </Helmet>
        <Container className={this.props.className}>
          <TopMenu selected={R.TASTEMAKERLANDING} />
          <Seperator height="43px" />
          <MainContent>
            <TopRowContainer>
              <BlackRowContainer>
                <TopRow isDesktop={ISDESKTOP}>
                  <TextContainer>
                    <TopText isDesktop={ISDESKTOP}>
                      <Typography variant="editorial.r46">
                        Stylists & Personal Shoppers
                      </Typography>
                      <Seperator height="10px" />
                      <TEXTSTYLE.BODY1>
                        Curate and share your own virtual edits from any online
                        store
                      </TEXTSTYLE.BODY1>
                      <Seperator height="30px" />
                      <TEXTSTYLE.LINK
                        to={
                          this.props.hasApplied
                            ? R.CREATOR_DASHBOARD
                            : R.STYLIST_APPLICATION_ONE
                        }
                      >
                        <Button
                          className="Tastemakers-getStartedButton"
                          text={
                            this.props.hasApplied ? (
                              'Go to your earnings'
                            ) : (
                              <>
                                Get started -
                                <span className="Tastemakers-ctaLightCopy">
                                  &nbsp;it's free
                                </span>
                              </>
                            )
                          }
                          msstyle="cta1st_dark_adjustable"
                          width="100%"
                          maxWidth="1185px"
                        />
                      </TEXTSTYLE.LINK>
                    </TopText>
                  </TextContainer>
                </TopRow>
              </BlackRowContainer>
            </TopRowContainer>

            <LandingBanner
              className="Tastemakers-brandsBanner"
              imagePath="/stylists/brands-banner"
              imageAlt="Earn from affiliate brands"
            />

            <WhiteRowContainer className="whiteRowContainer">
              <MiddleRow isDesktop={ISDESKTOP} className="middleRow">
                <Seperator height={ISDESKTOP ? '89px' : ''} />
                <TextContainer
                  isDesktop={ISDESKTOP}
                  className={clsx('textContainer', ISDESKTOP && 'left')}
                >
                  <MiddleText isDesktop={ISDESKTOP} className="MiddleText">
                    <Typography variant="editorial.sb34">
                      Curate and share inspiring virtual edits
                    </Typography>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      Moonsift makes it simple to curate your own shoppable
                      online edits from any store.
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      The clean layout provides the perfect canvas to highlight
                      your choices, which clients can then explore on any
                      device.
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      <RequestMoonsiftPro />
                    </TEXTSTYLE.BODY2>
                  </MiddleText>
                </TextContainer>
                <ImageContainer
                  isDesktop={ISDESKTOP}
                  className={clsx('ImageContainer', ISDESKTOP && 'center')}
                >
                  <MiddleImage
                    className="MiddleImage"
                    isDesktop={ISDESKTOP}
                    src={MEDIA.STYLISTS_IMG_1}
                    alt="Virtual Edit"
                  />
                </ImageContainer>
              </MiddleRow>
            </WhiteRowContainer>

            <Seperator height={ISDESKTOP ? '71px' : '46px'} />

            <GreyRowContainer className="greyRowContainer">
              <BottomRow className="bottomRow" isDesktop={ISDESKTOP}>
                <GreySeperator height="68px" />
                <TextContainer isDesktop={ISDESKTOP} className="TextContainer">
                  <BottomText isDesktop={ISDESKTOP}>
                    <Typography variant="editorial.sb34">
                      Earn commission from all your favourite brands
                    </Typography>
                    <GreySeperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      Join Moonsift and instantly access commission from over
                      35,000 brands in one place.
                    </TEXTSTYLE.BODY2>
                    <GreySeperator height="10px" />
                    <RequestMoonsiftPro />
                  </BottomText>
                </TextContainer>
                <ImageContainer
                  isDesktop={ISDESKTOP}
                  className="ImageContainer"
                >
                  <picture>
                    <source
                      media="(max-width: 620px)"
                      srcSet={`${process.env.REACT_APP_STATIC_URL}/stylists/brands-square.png`}
                    />
                    <img
                      className="Tastemakers-brandsImage"
                      alt="Earn affiliate commissions"
                      src={`${process.env.REACT_APP_STATIC_URL}/stylists/brands-square.2x.png`}
                    />
                  </picture>
                </ImageContainer>
              </BottomRow>
            </GreyRowContainer>

            <GreySeperator height={ISDESKTOP ? '80px' : '44px'} />

            <WhiteRowContainer className="whiteRowContainer">
              <MiddleRow isDesktop={ISDESKTOP} className="middleRow">
                <Seperator height="68px" />
                <TextContainer
                  isDesktop={ISDESKTOP}
                  className={clsx('textContainer', ISDESKTOP && 'left')}
                >
                  <BottomText isDesktop={ISDESKTOP}>
                    <Typography variant="editorial.sb34">
                      Spend more time helping clients and less on admin
                    </Typography>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      No more copying and pasting links and images or emailing
                      files. Save the pieces you want with a single click and
                      organise your choices digitally.{' '}
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      Stylists, personal shoppers, designers, bloggers and
                      buyers all use Moonsift to make their lives easier and
                      free up more time to add value.{' '}
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <RequestMoonsiftPro />
                  </BottomText>
                </TextContainer>
                <ImageContainer
                  isDesktop={ISDESKTOP}
                  className={clsx('ImageContainer', ISDESKTOP && 'center')}
                >
                  <BottomImage
                    isDesktop={ISDESKTOP}
                    src={MEDIA.STYLISTS_IMG_2}
                    alt="Personal shopping tool"
                  />
                </ImageContainer>
              </MiddleRow>
            </WhiteRowContainer>

            <GreyRowContainer className="greyRowContainer">
              <BottomRow isDesktop={ISDESKTOP} className="bottomRow">
                <GreySeperator height={ISDESKTOP ? '80px' : ''} />
                <TextContainer isDesktop={ISDESKTOP} className="textContainer">
                  <MiddleText isDesktop={ISDESKTOP} className="MiddleText">
                    <Typography variant="editorial.sb34">
                      Annotate your choices and get feedback
                    </Typography>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      Discuss your choices with clients' and other collaborators
                      all in one place.
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <TEXTSTYLE.BODY2>
                      Get direct feedback from clients on the items you have
                      selected. Find out what items they have purchased and how
                      their wardrobe is evolving.
                    </TEXTSTYLE.BODY2>
                    <Seperator height="10px" />
                    <RequestMoonsiftPro />
                  </MiddleText>
                </TextContainer>
                <ImageContainer
                  isDesktop={ISDESKTOP}
                  className="ImageContainer"
                >
                  <MiddleImage
                    className="MiddleImage"
                    isDesktop={ISDESKTOP}
                    src={MEDIA.STYLISTS_IMG_3}
                    alt="Share virtual edits with clients"
                  />
                </ImageContainer>
              </BottomRow>
            </GreyRowContainer>
            <GreySeperator height={ISDESKTOP ? '80px' : '44px'} />

            <MaxWidthContainer
              isDesktop={ISDESKTOP}
              className="MaxWidthContainer"
            >
              <SecondRow isDesktop={ISDESKTOP} className="secondRow">
                <SecondRowInner className="secondRowInner">
                  <Seperator height={ISDESKTOP ? '89px' : '44px'} />
                  <SecondRowTitle isDesktop={ISDESKTOP}>
                    <Typography variant="editorial.sb34">
                      Read what people are saying{' '}
                      <FiveStars
                        className="Tastemakers-inlineFlex"
                        size="large"
                      />
                    </Typography>
                  </SecondRowTitle>
                  <Seperator height="28px" />
                  <ReviewsCarousel />
                  <Seperator height="74px" />
                </SecondRowInner>
              </SecondRow>
              <Stack
                className="Tastemakers-callToAction"
                justifyContent="center"
              >
                <BrowserExtensionButton
                  className="Tastemakers-ctaButton"
                  size={ISDESKTOP ? 'large' : 'medium'}
                  to={R.STYLIST_APPLICATION_ONE}
                  fullWidth
                >
                  Get started -{' '}
                  <span className="Tastemakers-ctaLightCopy">
                    &nbsp;it's free
                  </span>
                </BrowserExtensionButton>
              </Stack>
              <Seperator height="89px" />
            </MaxWidthContainer>
          </MainContent>
          <BottomBar />
        </Container>
      </>
    );
  }
}

const Tastemakers = styled(UnstyledTastemakers)`
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left {
    display: flex;
    align-items: flex-start;
  }

  .Tastemakers-inlineFlex {
    display: inline-flex;
  }

  .Tastemakers-callToAction {
    align-items: center;
  }

  .Tastemakers-brandsBanner {
    border-bottom: 5px solid var(--common-black);

    img {
      height: 240px;
    }
  }

  .Tastemakers-brandsImage {
    width: 100%;
    max-width: 442px;
  }

  .Tastemakers-getStartedButton {
    background: var(--background-purple);
    border: 0;
    color: var(--common-white);

    &:hover,
    &:active,
    &:active {
      color: var(--common-white);
    }
  }

  .Tastemakers-ctaLightCopy {
    font-weight: 300;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .Tastemakers-ctaButton {
      width: 480px;
    }
  }
`;

function mapStateToProps({ db }) {
  const creatorStatus = db.user_verified_settings.data?.creator?.status;
  const hasApplied = Boolean(creatorStatus) && creatorStatus !== 'denied';

  return {
    hasApplied,
  };
}

export default compose(connect(mapStateToProps), windowSize)(Tastemakers);
