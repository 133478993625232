/*

Will become redundant

*/

// This file explains how the context behaves
// It just adds firebase object to the properties of the component.
import { useContext } from 'react';
import { ServicesContext } from 'src/ServicesContext';



export const withFirebase = Component => {
  return  (props) => {
    const { firebaseMVP22 } = useContext(ServicesContext);
    return <Component {...props} firebase={firebaseMVP22} />;
  };
};
