/*

Dots that wave up and down to show working for circular buttons.

In design system so occurrences can be replaced and this eventually removed.

*/
import styled, {keyframes} from 'styled-components';
import MEDIA from 'src/mvp22/media';

const WorkingKeyframes = keyframes`
  0% {background-image:url(${MEDIA.WORKINGCIRCLE_A});}
  25% {background-image:url(${MEDIA.WORKINGCIRCLE_B});}
  50% {background-image:url(${MEDIA.WORKINGCIRCLE_C});}
  75% {background-image:url(${MEDIA.WORKINGCIRCLE_B});}
  100% {background-image:url(${MEDIA.WORKINGCIRCLE_A});}
`;

const WorkingDots = styled.div`
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${WorkingKeyframes};
`;

export const HeartIconWorking = styled(WorkingDots)`
  height:10px;
  width:10px;
  cursor:pointer;
  background-repeat:no-repeat;
  background-position:center;
  background-size:10px;
`;

export default WorkingDots;
