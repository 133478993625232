import { useCallback, useContext, useState, VFC } from 'react';

import {
  SustainabilityAnswer,
  SustainabilityPollContainerProps,
  SustainabilityPollProps,
} from './SustainabilityPoll.types';
import { SustainabilityPoll } from './SustainabilityPoll.component';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { ServicesContext } from 'src/ServicesContext';

const SustainabilityPollContainer: VFC<SustainabilityPollContainerProps> = (
  props,
) => {
  const { db, analytics } = useContext(ServicesContext);

  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  const [updateUserOwner] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID ?? ''),
  );

  const [justAnswered, setJustAnswered] = useState(false);
  const [justClosed, setJustClosed] = useState(false);

  const sustainabilityAnswer = useSelector<
    RootState,
    SustainabilityAnswer | undefined
  >(
    ({ firestore_user_owner }) =>
      firestore_user_owner.snapshot?.sustainability_answer,
  );
  const showQuestionBox =
    authUserUID &&
    (sustainabilityAnswer === 'UNANSWERED' || justAnswered === true) &&
    justClosed === false;

  const handleLessClick = useCallback(() => {
    setJustAnswered(true);
    updateUserOwner({
      sustainability_answer: 'LESS',
    });
    analytics.recordEvent('WebApp:SustainabilityQuestion:ClickedBuyLess');
  }, [analytics, updateUserOwner]);

  const handleMoreClick = useCallback(() => {
    setJustAnswered(true);
    updateUserOwner({
      sustainability_answer: 'MORE',
    });
    analytics.recordEvent('WebApp:SustainabilityQuestion:ClickedBuyMore');
  }, [analytics, updateUserOwner]);

  const handleAboutTheSameClick = useCallback(() => {
    setJustAnswered(true);
    updateUserOwner({
      sustainability_answer: 'ABOUT_THE_SAME',
    });
    analytics.recordEvent(
      'WebApp:SustainabilityQuestion:ClickedBuyAboutTheSame',
    );
  }, [analytics, updateUserOwner]);

  const handleClose = useCallback<SustainabilityPollProps['onClose']>(() => {
    setJustClosed(true);
    analytics.recordEvent('WebApp:SustainabilityQuestion:ClickedClose');
  }, [analytics]);

  return showQuestionBox ? (
    <SustainabilityPoll
      onLessClick={handleLessClick}
      onMoreClick={handleMoreClick}
      onAboutTheSameClick={handleAboutTheSameClick}
      onClose={handleClose}
      showThanks={justAnswered}
      {...props}
    />
  ) : null;
};

export { SustainabilityPollContainer as SustainabilityPoll };
