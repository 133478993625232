import {
  ActionNames,
  CreatorPayoutDetailsAction,
  CreatorPayoutDetails,
} from 'src/types';

function getInitialState(): CreatorPayoutDetails {
  return {
    email: '',
    fullName: '',
  };
}

export const creatorPayoutDetails = (
  state = getInitialState(),
  action: CreatorPayoutDetailsAction,
): CreatorPayoutDetails => {
  switch (action.type) {
    case ActionNames.UPDATE_CREATOR_PAYOUT_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionNames.RESET_CREATOR_PAYOUT_DETAILS:
      return getInitialState();
    default:
      return state;
  }
};
