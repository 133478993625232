import { VFC, useEffect, useCallback } from 'react';
import Cookies from 'universal-cookie';

import { M } from 'src/mvp22/constants';
import R from 'src/routes';
import { useSearchParams } from 'src/hooks/useSearchParams';
import { AllCreatorsContainerProps } from './AllCreators.types';
import { AllCreators } from './AllCreators.component';

const cookies = new Cookies();

const AllCreatorsContainer: VFC<AllCreatorsContainerProps> = ({
  history,
  ...otherProps
}) => {
  // Update the referral cookie:
  const searchParams = useSearchParams();
  const creatorCode = searchParams.code;
  useEffect(() => {
    if (creatorCode) {
      cookies.set(M.CREATOR_CODE_COOKIE_KEY, creatorCode, {
        maxAge: 60 * 60 * 24 * 90,
        path: M.COOKIEPATH,
      });
    }
  }, [creatorCode]);

  const handleContentCreator = useCallback(() => {
    history.push(R.CREATOR_LANDING);
  }, [history]);

  const handleStylist = useCallback(() => {
    history.push(R.TASTEMAKERLANDING);
  }, [history]);

  return (
    <AllCreators
      onContentCreator={handleContentCreator}
      onStylist={handleStylist}
      {...otherProps}
    />
  );
};

export { AllCreatorsContainer as AllCreators };
