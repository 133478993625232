import { useEffect, useState } from 'react';
import { Constants } from 'src/constants';

export const useHasExtension = () => {
  const [extensionInstalled, setExtensionInstalled] = useState<null | boolean>(
    null,
  );

  useEffect(() => {
    // detect extension via element (remove in future)
    let has_extension_by_element = !!document.getElementById('ms_extension');
    if (has_extension_by_element) {
      setExtensionInstalled(true);
    } else {
      // detect extension via message parsing:
      if (typeof chrome !== 'undefined' && chrome.runtime) {
        let received_response = false;
        const timeoutID = setTimeout(() => {
          if (received_response !== true) {
            received_response = true;
            setExtensionInstalled(false);
          }
        }, 100);
        chrome.runtime.sendMessage(
          Constants.EXTENSION_ID,
          {
            type: 'front-end',
            action: 'ping',
          },
          (response) => {
            received_response = true;
            const lastError = chrome.runtime.lastError;
            if (lastError) {
              setExtensionInstalled(false);
            } else {
              setExtensionInstalled(true);
            }
          },
        );
        return () => {
          clearTimeout(timeoutID);
        };
      } else {
        setExtensionInstalled(false);
      }
    }
  }, []);

  return extensionInstalled;
};
