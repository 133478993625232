/*

Viewing a user's profile (authUser or another user when logged in or not.)

*/
import { VFC, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';
import R from 'src/routes';
import { M } from 'src/mvp22/constants';
import { RootState } from 'src';
import { ProfileContainerProps } from './Profile.types';
import { Profile } from './Profile.component';
import { UserPublic } from 'src/types/models/userPublic.model';
import { Status } from 'src/types/common';
import { useUserUIDLookup } from 'src/hooks/useUserUIDLookup';
import { generateImageSource } from 'src/utils/generateImageSource';
import { useUserPublicDoc } from 'src/hooks/useUserPublicDoc';

const cookies = new Cookies();

const ProfileContainer: VFC<ProfileContainerProps> = ({
  activeTab = 'all_user_collections',
}) => {
  const { setProfileUsernameFromURL, profileUserUID } = useUserUIDLookup();

  // Selectors:
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const userDataLoaded = useSelector<RootState, true | undefined>(
    (state) => state.firebasestate.loaded,
  );
  const profilePublicData = useSelector<
    RootState,
    UserPublic | null | undefined
  >((state) => state?.db.user_public.map[profileUserUID]);
  const windowWidth = useSelector<RootState, number>(
    ({ ui }) => ui.windowWidth,
  );

  // Other hooks:
  const history = useHistory();
  const params = useParams() as { user_map: string | undefined };
  const location = useLocation();

  // Constants:
  const profileIsOwn = profileUserUID === authUserUID;
  const profileDataIsLoaded = profilePublicData !== undefined;
  const profileExists = profilePublicData !== null;
  const profileName = (profilePublicData && profilePublicData.name) ?? '';
  const profileDisplayUsername =
    (profilePublicData && profilePublicData.display_username) ?? '';
  const profileImageCode =
    (profilePublicData && profilePublicData.profile_image_code) ?? '';
  const profileBio = (profilePublicData && profilePublicData.bio) ?? '';
  const profileLink = (profilePublicData && profilePublicData.link) ?? '';
  const profileIsPro =
    profilePublicData && profilePublicData.pro ? true : false;

  // On url changing, check the url for the current viewing state and set the username from the URL:
  useEffect(() => {
    // Get profile username:
    // e.g. @fewtrem or /profile/fewtrem - both are valid:
    const profileUsernameFromParams =
      params.user_map ?? location.pathname.split('/')[2];
    // Default to logged in user if not given:
    const nextProfileUsernameFromURL =
      !profileUsernameFromParams && authUserUID !== null
        ? authUserUID
        : profileUsernameFromParams;
    // Still can be set as '' if not logged in e.g. on /collections
    setProfileUsernameFromURL(nextProfileUsernameFromURL);
  }, [params, location, authUserUID, setProfileUsernameFromURL]);

  // If the username from the URL matches the one we have loaded, make sure it is the display username that we see:
  // TODO: Make sure this only runs on all collections - i.e. sort out the routing for bookmarks and so on.
  useEffect(() => {
    if (profileUserUID && profileDisplayUsername) {
      history.replace(`@${profileDisplayUsername}`);
    }
  }, [profileUserUID, profileDisplayUsername, history]);

  // Finally get the public doc data for the profileUserUID using the DB hooks:
  // Note hook takes care of case profileUserUID is blank...:
  useUserPublicDoc(profileUserUID);

  // Run a few things once we know the profile user UID:
  useEffect(() => {
    if (profileUserUID) {
      // Log profile UID as this is useful for looking up user profiles in the db from links:
      console.log(
        '%cMoonviewing:',
        'background-color:lightskyblue;',
        profileUserUID,
      );
      // Update the referral cookie:
      if (!cookies.get(M.REFERRAL_COOKIE_KEY)) {
        cookies.set(M.REFERRAL_COOKIE_KEY, profileUserUID, {
          maxAge: 60 * 60 * 24 * 30,
          path: M.COOKIEPATH,
        });
      }
    }
  }, [profileUserUID, profileIsOwn]);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const toggleShare = useCallback(() => {
    setIsShareModalOpen((prevIsShareModalOpen) => !prevIsShareModalOpen);
  }, []);

  const handleEdit = useCallback(() => {
    history.push(R.EDIT_PROFILE);
  }, [history]);

  const handleTabChange = useCallback(
    (_event, newValue) => {
      switch (newValue) {
        case 'all_user_collections':
          history.push(`/@${profileDisplayUsername}`);
          break;
        case 'bookmarked':
          history.push(R.BOOKMARKED);
          break;
        case 'purchased':
          history.push(R.PURCHASED);
      }
    },
    [history, profileDisplayUsername],
  );

  const profileImageSource = generateImageSource(
    profileUserUID,
    profileImageCode,
  );

  const isLoading = !userDataLoaded || !profileDataIsLoaded;

  const creatorStatus = useSelector<RootState, Status | undefined>(
    ({ db }) => db.user_verified_settings.data?.creator?.status,
  );

  // Keep local state when the user closes the creator dialog
  const [isCreatorDialogHidden, setIsCreatorDialogHidden] = useState(false);
  const handleCloseCreatorDialog = useCallback(() => {
    setIsCreatorDialogHidden(true);
  }, []);

  const handleGivePro = useCallback(() => {
    history.push(R.PRO_SHARE);
  }, [history]);

  const isOnboardedCreator = useSelector<RootState, boolean>(({ db }) => {
    return db.user_verified_settings.data?.creator?.is_onboarded ?? false;
  });

  return (
    <Profile
      profileExists={profileExists}
      profileUserUID={profileUserUID}
      profileDisplayUsername={profileDisplayUsername}
      profileIsOwn={profileIsOwn}
      profileName={profileName}
      profileImageCode={profileImageCode}
      profileImageSource={profileImageSource}
      profileBio={profileBio}
      profileLink={profileLink}
      profileIsPro={profileIsPro}
      profileCreatorStatus={creatorStatus}
      activeTab={activeTab}
      profileDataIsLoaded={profileDataIsLoaded}
      windowWidth={windowWidth}
      isLoading={isLoading}
      isShareModalOpen={isShareModalOpen}
      isCreatorDialogHidden={isCreatorDialogHidden}
      isOnboardedCreator={isOnboardedCreator}
      onCloseCreatorDialog={handleCloseCreatorDialog}
      onTabChange={handleTabChange}
      toggleShare={toggleShare}
      onEdit={handleEdit}
      onGivePro={handleGivePro}
    />
  );
};

export { ProfileContainer as Profile };
