import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';

import { EmptyMessagePurchasedCollectionProps } from './EmptyMessagePurchasedCollection.types';

const UnstyledEmptyMessagePurchasedCollection: VFC<
  EmptyMessagePurchasedCollectionProps
> = ({ className, style }) => {
  return (
    <div className={className} style={style}>
      <Stack
        direction="column"
        alignItems="center"
        className="EmptyMessagePurchasedCollection-container"
      >
        <img
          className="EmptyMessagePurchasedCollection-image"
          src={`${process.env.REACT_APP_STATIC_URL}/collection/noPurchasedItems.svg`}
          alt="No purchased items"
        />
        <Typography variant="primary.b34">No purchased items yet</Typography>
        <Typography variant="primary.l16">
          To mark an item you've saved as purchased, click the coathanger icon
          below the item's price.
        </Typography>
      </Stack>
    </div>
  );
};

export const EmptyMessagePurchasedCollection = styled(
  UnstyledEmptyMessagePurchasedCollection,
)`
  .EmptyMessagePurchasedCollection-container {
    max-width: 330px;
    display: block;
    background-color: var(--primary-white);
    box-sizing: border-box;
    position: relative;
  }
  .EmptyMessagePurchasedCollection-image {
    display: block;
    margin: 0 auto;
  }
`;
