import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Typography, Stack } from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { SignUpForm } from 'src/components/auth/SignUpForm';
import { ExtensionSignUpProps } from './ExtensionSignUp.types';

const UnstyledExtensionSignUp: VFC<ExtensionSignUpProps> = ({
  className,
  style,
}) => {
  return (
    <Page
      className={className}
      style={style}
      showStylists={false}
      showGetMoonsift={false}
    >
      <Helmet>
        <title>Join us - Moonsift</title>
      </Helmet>
      <div className="ExtensionSignUp-container">
        <Stack
          className="ExtensionSignUp-column1"
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography
            className="ExtensionSignUp-heading"
            variant="primary.b26"
            component="h2"
          >
            Moonsift added successfully!
          </Typography>
          <Typography
            className="ExtensionSignUp-subheading"
            variant="primary.r16"
            component="p"
          >
            Create your free account to keep track of everything you save
          </Typography>
          <SignUpForm />
        </Stack>
        <Stack
          className="ExtensionSignUp-column2"
          direction="column"
          alignItems="center"
        >
          <Typography
            className="ExtensionSignUp-videoHeading"
            variant="primary.b34"
            component="h2"
          >
            One wishlist for all stores
          </Typography>
          <video
            className="ExtensionSignUp-video"
            src={`${process.env.REACT_APP_STATIC_URL}/landing/Saving_product_video_loop_faster.mov`}
            loop
            autoPlay
            muted
            playsInline
          />
        </Stack>
      </div>
    </Page>
  );
};

export const ExtensionSignUp = styled(UnstyledExtensionSignUp)`
  .ExtensionSignUp-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 1400px;
    margin: 0 auto 4rem;
  }

  .ExtensionSignUp-heading {
    margin-bottom: 8px;
  }

  .ExtensionSignUp-subheading {
    margin-bottom: 24px;
  }

  .ExtensionSignUp-column2 {
    .ExtensionSignUp-videoHeading {
      margin-bottom: 0;
    }

    .ExtensionSignUp-video {
      width: 100%;
      max-width: 586px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'lg' })} {
    .ExtensionSignUp-container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;
      max-width: 1400px;
      margin: 0 auto;
    }

    .ExtensionSignUp-column1 {
      margin-bottom: 2rem;
    }
  }
`;
