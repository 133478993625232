import { FC, useContext, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import R from 'src/routes';
import { Collection } from 'src/types/models/collection.model';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { getCollectionType } from 'src/utils/getCollectionType';
import { getPublicLevel } from 'src/utils/getPublicLevel';
import {
  EditModalContentContainerProps,
  EditModalContentProps,
  HandleEdit,
} from './EditModalContent.types';
import { EditModalContent } from './EditModalContent.component';
import { EditDrawerContent } from './EditDrawerContent.component';
import { ActionNames, AutoTriggerReviewRequests } from 'src/types';

const editModalContentContainerFactory = (
  Component: FC<EditModalContentProps>,
) => {
  const EditModalContentContainer: FC<EditModalContentContainerProps> = ({
    onClose,
    ...otherProps
  }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const { db, cloud } = useContext(ServicesContext);

    const collection = useSelector(
      (state: RootState) => state.db.single_collection.data,
    );
    const collectionUID = collection?.id ?? '';
    const collectionUserUID = collection?.user_uid ?? '';
    const collectionName = collection?.name ?? '';
    const collectionDescription = collection?.description ?? '';
    const publicLevel = collection?.public_level ?? -1;
    const isRegistryInitial = collection?.is_registry ?? false;

    // Handle collection type change:
    const [selectedCollectionType, setSelectedCollectionType] = useState(
      getCollectionType(publicLevel),
    );

    const [isRegistryNext, setIsRegistryNext] =
      useState<boolean>(isRegistryInitial);

    const handleCollectionTypeChange = useCallback<
      EditModalContentProps['onCollectionTypeChange']
    >((_event, collectionType) => {
      if (collectionType) {
        setSelectedCollectionType(collectionType);
      }
    }, []);

    // Handle collection update:
    const [updateCollection, working] = useDBUpdateDoc(
      db,
      db.collectionDoc(collectionUserUID, collectionUID),
    );

    const toggleIsRegistry = useCallback(
      () => setIsRegistryNext((state) => !state),
      [],
    );

    const handleEdit = useCallback<HandleEdit>(
      (event) => {
        event.preventDefault();

        const name = event.currentTarget.collectionName.value;
        const description = event.currentTarget.collectionDescription?.value;

        const publicLevel = getPublicLevel(
          selectedCollectionType!,
          isRegistryNext,
        );

        const update: Partial<Collection> = {
          name,
          last_updated: db.serverTimestamp(),
          public_level: publicLevel,
          is_registry: isRegistryNext,
          registry_public_level: 8,
        };

        if (description !== undefined) {
          update.description = description;
        }

        updateCollection(update)
          .then(() => {
            onClose(event);
            if (collectionName !== name) {
              dispatch<AutoTriggerReviewRequests>({
                type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
                payload: undefined,
              });
            }
          })
          .catch((error) => {
            console.error('Could not update collection', error);
          });
      },
      [
        selectedCollectionType,
        db,
        isRegistryNext,
        updateCollection,
        onClose,
        collectionName,
        dispatch,
      ],
    );

    // Handle collection deletion:
    const [deleting, setDeleting] = useState(false);

    const handleDelete = useCallback<EditModalContentProps['onDelete']>(
      (event) => {
        event.preventDefault();

        setDeleting(true);

        cloud
          .fastAPI({
            api: 'delete_collection',
            collection_uid: collectionUID,
          })
          .then(() => {
            setDeleting(false);
            history.push(R.HOME);
          })
          .catch((error) => {
            setDeleting(false);
            console.error("Couldn't remove collection", error);
          });
      },
      [cloud, collectionUID, history],
    );

    // Confirmation state and toggle:
    const [showConfirmation, setShowConfirmation] = useState(false);

    const toggleConfirmation = useCallback<
      EditModalContentProps['toggleConfirmation']
    >(() => {
      setShowConfirmation((prevShowConfirmation) => !prevShowConfirmation);
    }, []);

    return (
      <Component
        name={collectionName}
        description={collectionDescription}
        working={working}
        showConfirmation={showConfirmation}
        deleting={deleting}
        selectedCollectionType={selectedCollectionType}
        onCollectionTypeChange={handleCollectionTypeChange}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onClose={onClose}
        toggleConfirmation={toggleConfirmation}
        isRegistryNext={isRegistryNext}
        toggleIsRegistry={toggleIsRegistry}
        {...otherProps}
      />
    );
  };

  return EditModalContentContainer;
};

const EditModalContentContainer =
  editModalContentContainerFactory(EditModalContent);

const EditDrawerContentContainer =
  editModalContentContainerFactory(EditDrawerContent);

export {
  EditModalContentContainer as EditModalContent,
  EditDrawerContentContainer as EditDrawerContent,
};
