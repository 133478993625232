import { Firebase } from 'src/services/Firebase';
import { DB } from 'src/services/DB';
import { Cloud } from 'src/services/Cloud';
import { Mobile } from 'src/services/Mobile';
import { Notifications } from 'src/services/Notifications';
import { Analytics } from 'src/services/Analytics';
import { Auth } from 'src/services/Auth';
import { Storage } from 'src/services/Storage';
import FirebaseMVP22 from 'src/mvp22/Firebase';

import { Services } from './setupServices.types';

export const setupServices = (): Services => {
  const app = new Firebase();
  const mobile = new Mobile();
  const cloud = new Cloud(app.app);
  const analytics = new Analytics(cloud, mobile);
  const auth = new Auth(app.app, mobile, cloud, analytics);
  const db = new DB(app.app);
  const notifications = new Notifications(app.app, mobile, cloud);
  const storage = new Storage(app.app);

  return {
    mobile,
    db,
    cloud,
    auth,
    notifications,
    storage,
    analytics,
    //TODO: Remove when all firebase functions replaced with new contexts:
    firebaseMVP22: new FirebaseMVP22(app, db, auth, cloud, notifications),
  };
};
