import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import { PeopleSayingSlide } from 'src/components/common/PeopleSayingSlide';
import { PeopleSayingCarouselProps } from './';

const UntyledPeopleSayingCarousel: VFC<PeopleSayingCarouselProps> = ({
  className,
  slides,
}) => {
  return (
    <Slider
      className={clsx(className, 'slider', 'variable-width')}
      dots={false}
      arrows={false}
      swipe={false}
      infinite
      variableWidth
      autoplay
    >
      {slides.map(({ comment, avatar, name, social }, index) => (
        <PeopleSayingSlide
          key={index}
          comment={comment}
          avatar={avatar}
          name={name}
          social={social}
        />
      ))}
    </Slider>
  );
};

export const PeopleSayingCarousel = styled(UntyledPeopleSayingCarousel)`
  /* Allow seeing the shadow casted by the speech bubbles. */
  .slick-list {
    overflow: visible;
  }

  /* Avoid slider track wrapping to the next row when on small devices. */
  .slick-track {
    > :last-child,
    > :nth-last-child(2) {
      display: none;
    }
  }
`;
