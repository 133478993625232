/*

Replace with REDUX Query into list.

*/
export default function get_notifications(doc,increment,firebase,auth_user_uid){
  return new Promise (
    (resolve)=>{
      const notification_list = [];
      var last_doc = false;
      var first_doc = false;
      if (doc!==false){ // may be null...:
        const middle_query = (doc===null)?firebase.user_notifications_orderDesc(auth_user_uid,increment):firebase.user_notifications_orderDesc_StartAfter(auth_user_uid,doc,increment);
        middle_query
        .get().then(
          snapshot=>{
            snapshot.forEach(
              doc=>{
                const data = doc.data();
                if (first_doc===false){
                  first_doc=doc;
                }
                last_doc = doc;
                // Not entry uid as this was used already...
                data.uid = doc.id;
                notification_list.push(data);
              }
            );
            resolve({
              notification_list,
              last_doc,
              first_doc
            });
          }
        );
      }
      else{
        resolve({
          notification_list,
          last_doc,
          first_doc
        });
      }
    }
  );

}
