import { VFC, useCallback } from 'react';
import { ExtensionUpgradedContainerProps } from './ExtensionUpgraded.types';
import { ExtensionUpgraded } from './ExtensionUpgraded.component';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';

const ExtensionUpgradedContainer: VFC<ExtensionUpgradedContainerProps> = () => {
  const history = useHistory();

  const goToMyCollections = useCallback(() => {
    history.push(R.HOME_OR_LOGIN);
  }, [history]);

  return <ExtensionUpgraded goToMyCollections={goToMyCollections} />;
};

export { ExtensionUpgradedContainer as ExtensionUpgraded };
