/*
  Only accessible to the owner.
  Shown as a confirmation message that the owner has already marked an item as bought if they attempt to use the "Go to item" flow.
*/

import styled from 'styled-components';
import {
  Stack,
  Typography,
  StandardButton,
} from '@moonsifttech/design-system';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { ModalContent } from 'src/components/core/ModalContent';

const UnstyledMarkedAsBoughtSlimOwnerDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ setModal, closeModal ] = useModal();

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        <span>You have marked this as bought</span>
      </Typography>

      <Stack direction='row' justifyContent='flex-end'>
        <StandardButton
          className="MarkedAsBoughtSlimOwnerDialog-unmarkButton"
          variant="secondary"
          onClick={() => setModal({
            type: ModalTypes.Unmark,
            collectionId,
            collectionUserId,
            itemId,
          })}
        >
          Un-mark as bought
        </StandardButton>

        <StandardButton
          variant="primary-white"
          onClick={closeModal}
        >
          Back
        </StandardButton>
      </Stack>
    </ModalContent>
  );
};

export const MarkedAsBoughtSlimOwnerDialog = styled(UnstyledMarkedAsBoughtSlimOwnerDialog)`
  .MarkedAsBoughtSlimOwnerDialog-unmarkButton {
    margin-right: 0.75rem;
  }
`;
