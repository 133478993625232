/*

Drop-down notifications list from the bell icon.

TODO: camelCase, typescript.

*/
import React from 'react';
import { useEffect, useRef } from 'react';
import {M} from 'src/mvp22/constants';
import R from 'src/routes';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import NotificationTileList from 'src/mvp22/tile-components/NotificationTileList';

const NUMBER_OF_NOTIFICATIONS_TO_SHOW = 6;

export const MenuPopup = styled.div`
  min-width:280px;
  max-width:360px;
  overflow-y:auto;
  max-height:80%;
  display:flex;
  flex-direction:column;
  background-color:${M.COL.BG.WHITE};
  position:fixed;
  top:60px;
  right:${props=>(props.hug==="edge")?"0px":props.inner_pos+"px"};
  z-index:10001;
  color:${M.COL.TEXT.BLACK};
  margin-right:22px;
  border-radius:6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  ::-webkit-scrollbar {
    background: #FFFFFF;
    border-left:1px solid #EFEFEF;
    box-sizing: border-box;
    width:15px;
  }
  ::-webkit-scrollbar-thumb {
    background: #CECFD1;
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:rgb(136, 136, 136);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
`;

const NofiticationTileListContainer = styled.div`
  padding:16px 0px;
`;

const ViewMore = styled.div`
  width:100%;
  display:flex;
  align-items:center;
  padding:12px;
  border-top:1px solid ${M.COL.LINE.MID};
  box-sizing:border-box;
  justify-content: center;
`;


/**
* Notifications Menu:
*/
const ThisComponent = (props)=>{
  const domRef = useRef();

  // Destructure Props for brevity:
  const domBellRef = props.domBellRef;
  const notification_toggle = props.notification_toggle;

  // click out to hide but not on the bell as this toggles the menu already!
  useEffect(
    () => {
      const checkclick = event=>{
        if (domRef.current && domBellRef.current){
          // domBellRef so that we don't do this toggle twice!
          if (!domRef.current.contains(event.target) && !domBellRef.current.contains(event.target)){
            notification_toggle();
          }
        }
      };
      document.addEventListener("mousedown",checkclick,false);
      return ()=>document.removeEventListener("mousedown",checkclick,false);
    },
    [domBellRef,notification_toggle]
  );

  const INNER_POS = ((props.window_width-1200)>0)?(props.window_width-1200)/2:0;
  if (props.auth_user_uid!==null){
    return(
      <MenuPopup ref={domRef} hug={props.hug} inner_pos={INNER_POS}>
        <NofiticationTileListContainer>
          <NotificationTileList
            notification_list={props.live_notification_list.slice(0,NUMBER_OF_NOTIFICATIONS_TO_SHOW)}
            time_at_last_view={props.time_at_last_view}
            auth_display_username={props.auth_display_username}
            auth_user_uid={props.auth_user_uid}
          />
        </NofiticationTileListContainer>
        {(props.live_notification_list.length>NUMBER_OF_NOTIFICATIONS_TO_SHOW)?
          <ViewMore style={{boxSizing:"border-box"}}>
            <TEXTSTYLE.LINK to={R.NOTIFICATIONS}>
              <TEXTSTYLE.HEADING5>
                View more notifications
              </TEXTSTYLE.HEADING5>
            </TEXTSTYLE.LINK>
          </ViewMore>
          :""
        }
      </MenuPopup>
    );
  }
  return null;
};

// Pass the state values into the component:
export default connect(
  (state) => ({
    auth_user_uid:state.auth.id,
    live_notification_list:state.firestore_notifications.notification_list,
    time_at_last_view:state.firestore_notifications.time_at_last_view,
    auth_display_username:state.firestore_user_owner.display_username
  }),
  {}
)(
  ThisComponent
);
