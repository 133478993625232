import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Paper, Stack, Typography } from '@moonsifttech/design-system';

import { SignUpForm } from 'src/components/auth/SignUpForm';
import { SignUpProps } from './SignUp.types';

const UnstyledSignUp: VFC<SignUpProps> = ({ className, style }) => {
  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Join us - Moonsift</title>
      </Helmet>
      <Paper className="SignUp-paper">
        <Stack
          className="SignUp-container"
          direction="column"
          alignItems="center"
        >
          <Stack className="SignUp-logo" direction="row">
            <img
              className="SignUp-logoImage"
              src={`${process.env.REACT_APP_STATIC_URL}/logos/logo.svg`}
              alt="Monsift logo"
            />
            <Typography className="SignUp-logoText" variant="branded.b42">
              MOONSIFT
            </Typography>
          </Stack>
          <SignUpForm />
        </Stack>
      </Paper>
    </Stack>
  );
};

export const SignUp = styled(UnstyledSignUp)`
  width: 100%;
  height: 100vh;
  background: var(--grey-1);

  .SignUp-paper {
    padding: 36px 14px;
    width: 480px;
  }

  .SignUp-logo {
    margin-bottom: 36px;
    align-items: center;

    .SignUp-logoImage {
      height: 50px;
    }

    .SignUp-logoText {
      padding: 8px 0 0 4px;
    }
  }
`;
