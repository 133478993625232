/*

This simple feedback button sits on the side of the website.  Hardly anyone seems to use it :(
It is quite old.

TODO: design system, typescript, functional, container/component, camelCase

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import PropTypes from 'prop-types';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import Button from 'src/mvp22/form-components/Button';
import TextBox from 'src/mvp22/form-components/TextBox';
import { withFirebase } from 'src/mvp22/Firebase';
import { connect } from 'react-redux';
import windowSize from 'src/mvp22/WindowSize';
import add_feedback from 'src/mvp22/firebase-functions/add_feedback';
import BGCover from 'src/mvp22/image-components/BGCover';

const FeedbackButtonAndBoxContainer = styled.div`
  width:100%;
  display:flex;
`;

const FeedbackTextContainer = styled(TEXTSTYLE.LINKA)`
  position:fixed;
  right:0px;
  bottom:10%;
  background-color:${M.COL.BUTTON.MIDDARK};
  color:${M.COL.TEXT.WHITE};
  padding:${props=>(props.mobile)?"4px 7px":"5px 7px"};
  border-radius:${props=>(props.mobile)?"5px 0px 0px 5px":"5px 0px 0px 5px"};
  z-index: 6000;
`;

const FeedbackImage = styled.img`
  width:${props=>(props.mobile)?"14px":"20px"};
  margin-top:${props=>(props.mobile)?"2px":"4px"};
`;

const FeedbackBox = styled.div`
  min-width:300px;
  position:fixed;
  right:0px;
  bottom:10%;
  background-color:${M.COL.BG.WHITE};
  padding:${props=>(props.mobile)?"4px 7px":"5px 7px"};
  border-radius:${props=>(props.mobile)?"5px 0px 0px 5px":"5px 0px 0px 5px"};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 6000;
  color:${M.COL.TEXT.BLACK};
`;

const ButtonContainer = styled.div`
  width:100%;
  display:flex;
  justify-content:space-between;
`;

const Separator = styled.div`
  width:100%;
  height: ${props=>props.height};
`;

/**
* Feedback component!
*/
class Feedback extends Component {
  static propTypes = {
    /* display or not?  defaults to on */
    on: PropTypes.bool,
  }

  constructor(props){
    super(props);
    // We can just use local state (not redux)
    // since this component is quite self-contained and separate to the rest and always mounted.
    this.state = {
      open:false,
      type:'pre',
      working:false,
      feedback_text:"",
      email:""
    };
    this.openBox = this.openBox.bind(this);
    this.closeBox = this.closeBox.bind(this);
    this.update_state_textbox = this.update_state_textbox.bind(this);
    this.update_state_email = this.update_state_email.bind(this);
    this.send_message = this.send_message.bind(this);
  }

  update_state_textbox(event){
    this.setState({feedback_text:event.target.value});
  }

  update_state_email(event){
    this.setState({email:event.target.value});
  }

  openBox(event){
    this.setState({open:true,type:'pre'});
    event.preventDefault();
    return false;
  }

  closeBox(event){
    this.setState({open:false});
    event.preventDefault();
    return false;
  }

  send_message(event){
    event.preventDefault();
    this.setState({working:true});
    add_feedback(
      {
        feedback:this.state.feedback_text,
        email:this.state.email
      },
      this.props.firebase,
      this.props.authUserUID
    ).then(
      ()=>
        // send to fb:
        this.setState(
          {
            type:'post',
            feedback_text:"",
            email:"",
            working:false
          }
        )
      ).catch(
        ()=>this.setState({working:false})
    );
    return false;
  }

  box_pre_send(){
    return(
      <FeedbackBox>
        <TEXTSTYLE.HEADING5>Thanks for your thoughts</TEXTSTYLE.HEADING5>
        <Separator height="6px"/>
        <TextBox
          maxLength={4000}
          value={this.state.textbox_value}
          focusOnMount={true}
          placeholder="Type your message here"
          msstyle="standard"
          onChange={this.update_state_textbox}
          name="problem_text"
          feedbackText={this.state.feedback_text}
          key="problem_text"
          width="100%"
          tabIndex={1998}
          height="60px"
        />
        <Separator height="12px"/>
        <TextInputFeedback
          placeholder="Email (optional)"
          msstyle="standard"
          onChange={this.update_state_email}
          name="collection_name"
          value={this.state.email}
          key="collection_name"
          width="100%"
          tabIndex={1999}
        />
        <ButtonContainer>
          <Button
            text="Cancel"
            msstyle="square_popup_grey"
            onClick={this.closeBox}
            tabIndex={2000}
            dots={true}
            width="45%"
          />
          <Button
            text="Send"
            msstyle="square_popup"
            onClick={this.send_message}
            tabIndex={2001}
            dots={true}
            enabled={this.state.feedback_text.length>0}
            working={this.state.working}
            width="45%"
          />
        </ButtonContainer>
      </FeedbackBox>
    );
  }

  box_post_send(){
    return(
      <FeedbackBox>
        <TEXTSTYLE.HEADING5>Thanks!</TEXTSTYLE.HEADING5>
        <TEXTSTYLE.BODY4>Your feedback makes Moonsift possible.</TEXTSTYLE.BODY4>
        <Separator height="6px"/>
        <Button
          text="Close"
          msstyle="square_popup"
          onClick={this.closeBox}
          tabIndex={2000}
        />
      </FeedbackBox>
    );
  }

  inner_box(){
    if(this.state.type==="pre"){
      return(this.box_pre_send());
    }
    else{
      return(this.box_post_send());
    }
  }

  render(){
    const ISMOBILE = (this.props.windowWidth<M.MOBILESWITCH)?true:false;
    const to_return = (this.props.on===false)?
      ""
    :
    (this.state.open)?
      <FeedbackButtonAndBoxContainer>
        <BGCover/>
        {this.inner_box()}
      </FeedbackButtonAndBoxContainer>
    :
    <FeedbackButtonAndBoxContainer>
        <FeedbackTextContainer mobile={ISMOBILE} onClick={this.openBox} href="#">
          <FeedbackImage mobile={ISMOBILE} src={MEDIA.FEEDBACK}/>
        </FeedbackTextContainer>
      </FeedbackButtonAndBoxContainer>;
    return(to_return);
  }

}

const mapStateToProps = (state) => {
  return ({
    authUserUID:state.auth.id
  });
};

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFirebase(
    windowSize(
      Feedback
    )
  )
);
