/*
Notifications page (as opposed to drop down menu - got to via "View more notifcations" (needs 10+ notifications at present!) on dropdown or via url...)

Stores all the notifications it has got here and not in redux - db hooks will change that....
Should be able to reuse the ones from the top bar...
(note: uses the main notifcations doc from redux already for last viewed date)

TODO: typescript, camelCase, non-default exports, redux hooks, db hooks, design system, split container / component ...

*/
import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { M } from 'src/mvp22/constants';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import Button from 'src/mvp22/form-components/Button';
import NotificationTileList from 'src/mvp22/tile-components/NotificationTileList';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import { connect } from 'react-redux';
import { withFirebase } from 'src/mvp22/Firebase';
import { requiresAuth } from 'src/mvp22/redux-components/requiresAuth';
import get_notifications from 'src/mvp22/firebase-functions/get_notifications';
import listen_for_new_notifications from 'src/mvp22/firebase-functions/listen_for_new_notifications';
const NOTIFICATION_INCREMENT = 10;
const Container = styled.div`
  color: default;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  color: ${M.COL.TEXT.BLACK};
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  padding-top: 114px;
`;

const LoadMoreButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
  width: 100%;
  max-width: 500px;
`;

/**
 * Notifications Page Component
 */
const ThisComponent = (props) => {
  const [working, setWorking] = useState(false);
  const [live_notification_list, setLiveNotificationList] = useState([]);
  const [additional_notification_list, setAdditionalNotificationList] =
    useState([]);
  const [last_doc, setLastDoc] = useState(null);
  const [first_doc, setFirstDoc] = useState(null);

  useEffect(() => {
    // Ensure is only working with valid user data:
    if (first_doc !== null && first_doc.auth_user_uid === props.authUserUID) {
      // catch case is false, i.e. no notifications at first.
      return listen_for_new_notifications(
        first_doc.doc,
        props.firebase,
        props.authUserUID,
        (result) => setLiveNotificationList(result.notification_list),
      );
    }
  }, [props.firebase, props.authUserUID, first_doc]);

  useEffect(() => {
    const auth_user_uid = props.authUserUID;
    if (auth_user_uid) {
      // If firebaseState changes then wooty woot let's set some stuff:
      // LISTEN to the top 10 notifications coming through:
      get_notifications(
        null,
        NOTIFICATION_INCREMENT,
        props.firebase,
        props.authUserUID,
      ).then((result) => {
        setAdditionalNotificationList(result.notification_list);
        setLastDoc(result.last_doc);
        setFirstDoc({
          auth_user_uid,
          doc: result.first_doc,
        });
      });
      // return the clean up function:
      return () => {
        setLastDoc(null);
        setFirstDoc(null);
      };
    }
  }, [props.authUserUID, props.firebase]);

  const load_more = () => {
    if (working !== true) {
      // initialise the loading:
      setWorking(true);
      get_notifications(
        last_doc,
        NOTIFICATION_INCREMENT,
        props.firebase,
        props.authUserUID,
      ).then((result) => {
        setAdditionalNotificationList((prevState) => [
          ...prevState,
          ...result.notification_list,
        ]);
        setLastDoc(result.last_doc);
        setWorking(false);
      });
    }
  };

  return (
    <Container className="Container">
      <TopMenu />
      {AddHelmetTitle('Notifications')}
      <MainContent className="MainContent">
        <NotificationTileList
          notification_list={[
            ...live_notification_list,
            ...additional_notification_list,
          ]}
          time_at_last_view={props.time_at_last_view}
          auth_display_username={props.auth_display_username}
          auth_user_uid={props.authUserUID}
        />
        <LoadMoreButtonContainer>
          <Button
            text={'Load more'}
            working_overlay={working}
            msstyle="option_positive"
            onClick={load_more}
            width="123px"
          />
        </LoadMoreButtonContainer>
      </MainContent>
    </Container>
  );
};

// Pass the state values into the component:
export default connect(
  (state) => ({
    authUserUID: state.auth.id,
    time_at_last_view: state.firestore_notifications.time_at_last_view,
    auth_display_username: state.firestore_user_owner.display_username,
  }),
  {},
)(requiresAuth(withFirebase(ThisComponent)));
