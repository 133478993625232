import { initializeApp, FirebaseApp } from 'firebase/app';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
};

/*

The root firebase service used to set up the other services (in root index.tsx)

*/
export class Firebase {
  app: FirebaseApp;

  constructor() {
    this.app = initializeApp(config);
  }
}
