import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, array, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import { updateCreatorApplicationRequest } from 'src/store/actions/creatorApplication';
import {
  CreatorApplicationTwoContainerProps,
  CreatorApplicationTwoProps,
  CreatorApplicationTwoFormContent,
} from './CreatorApplicationTwo.types';
import { CreatorApplicationTwo } from './CreatorApplicationTwo.component';
import { Affiliate } from 'src/types';

const validationSchema = object({
  affiliates: array(
    string().oneOf([
      'LTK',
      'Amazon Associates',
      'MagicLinks',
      'Klarna Affiliate',
      'Collective Voice (ShopStyle)',
      'ShareASale',
      'Other',
      'None yet',
    ]),
  ).min(1, 'Select at least one program or "None yet", please.'),
});

const CreatorApplicationTwoContainer: VFC<
  CreatorApplicationTwoContainerProps
> = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const initialValues = useSelector<
    RootState,
    CreatorApplicationTwoFormContent
  >(({ creatorApplication }) => ({
    affiliates: [...creatorApplication.affiliates],
  }));

  const handleSubmit = useCallback<CreatorApplicationTwoProps['onSubmit']>(
    (values, actions) => {
      dispatch(updateCreatorApplicationRequest(values));
      actions.setSubmitting(false);
      history.push(R.CREATOR_APPLICATION_THREE);
    },
    [dispatch, history],
  );

  const formRef: CreatorApplicationTwoProps['formRef'] = useRef(null);

  const handleBack = useCallback(() => {
    if (formRef.current) {
      const affiliates: Affiliate[] = [];
      formRef.current['affiliates'].forEach(
        (x) => x.checked && affiliates.push(x.value as Affiliate),
      );
      dispatch(updateCreatorApplicationRequest({ affiliates }));
    }
    history.push(R.CREATOR_APPLICATION_ONE);
  }, [dispatch, history]);

  return (
    <CreatorApplicationTwo
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onBack={handleBack}
      formRef={formRef}
      {...props}
    />
  );
};

export { CreatorApplicationTwoContainer as CreatorApplicationTwo };
