import { VFC, useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import {
  sumEarningsEntrySales,
  sumEarningsEntryCommissions,
  CollectionEarnings,
  EarningsEntry,
} from 'src/types/models/creatorEarnings.model';
import { CurrencyFormatter } from 'src/utils/CurrencyFormatter';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { useDBRetrieveQuery } from 'src/hooks/useDBRetrieveQuery';
import { collectionEarningsParser } from 'src/store/parsers/creatorEarnings';
import { ServicesContext } from 'src/ServicesContext';
import { EarningsTable } from './EarningsTable.component';
import {
  EarningsTableContainerProps,
  EarningsTableProps,
} from './EarningsTable.types';
import { RootState } from 'src/index';

const EarningsTableContainer: VFC<EarningsTableContainerProps> = (props) => {
  const isMobile = useIsMobile();

  const { db } = useContext(ServicesContext);

  // Retrieve paginated collection earnings
  const earningsList = useSelector<RootState, CollectionEarnings[]>(
    ({ db }) => {
      return db.collection_earnings_list.data;
    },
  );
  const authUserUID = useSelector<RootState, string>(({ auth }) => {
    return auth.id ?? '';
  });
  const [cursor, setCursor] = useState<
    { type: 'before' | 'after'; fieldValues: [Date, string] } | undefined
  >();
  useDBRetrieveQuery<'collection_earnings_list'>(
    db,
    db.userEarningsQuery,
    'collection_earnings_list',
    collectionEarningsParser,
    undefined,
    cursor ? [authUserUID, cursor] : [authUserUID],
    'get',
  );

  // Pagination
  const [page, setPage] = useState(0);
  const handlePageChange = useCallback<EarningsTableProps['onPageChange']>(
    (_event, newPage) => {
      // Going to prev page
      if (newPage < page) {
        const first = earningsList[0];
        setCursor({
          type: 'before',
          fieldValues: [first.collection_created_at, first.collection_name],
        });
      } else if (newPage > page) {
        const last = earningsList[earningsList.length - 1];
        setCursor({
          type: 'after',
          fieldValues: [last.collection_created_at, last.collection_name],
        });
      }
      setPage(newPage);
    },
    [earningsList, page],
  );

  // Calculate totals
  const creatorEarnings = useSelector<RootState, EarningsEntry | undefined>(
    ({ db }) => {
      return db.user_verified_settings.data?.earnings;
    },
  );
  const totalSalesValue = CurrencyFormatter.format(
    sumEarningsEntrySales(creatorEarnings),
    'GBP',
  );
  const totalCommissionsValue = CurrencyFormatter.format(
    sumEarningsEntryCommissions(creatorEarnings),
    'GBP',
  );

  // Collection earnings count
  const collectionEarningsCount = useSelector<RootState, number>(({ db }) => {
    return db.user_verified_settings.data?.collection_earnings_count ?? 0;
  });

  return (
    <EarningsTable
      isMobile={isMobile}
      collectionEarnings={earningsList}
      totalSalesValue={totalSalesValue}
      totalCommissionsValue={totalCommissionsValue}
      collectionEarningsCount={collectionEarningsCount}
      page={page}
      onPageChange={handlePageChange}
      {...props}
    />
  );
};

export { EarningsTableContainer as EarningsTable };
