import { VFC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { PlansContainerProps } from './Plans.types';
import { Plans } from './Plans.component';

const PlansContainer: VFC<PlansContainerProps> = (props) => {
  const isLoggedIn = useSelector<RootState, boolean>((state) =>
    Boolean(state.auth.id),
  );
  return <Plans isLoggedIn={isLoggedIn} {...props} />;
};

export { PlansContainer as Plans };
