import { VFC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Typography,
} from '@moonsifttech/design-system';

import { GiftingMessageProps } from './GiftingMessage.types';

const UnstyledGiftingMessage: VFC<GiftingMessageProps> = ({
  className,
  style,
  author,
  children,
}) => {
  return (
    <div className={className} style={style}>
      <Typography variant="primary.r14" component="p">
        <b>{author}</b> left you a note:
      </Typography>
      <Paper variant="outlined">{children}</Paper>
    </div>
  );
};

export const GiftingMessage = styled(UnstyledGiftingMessage)`
  p:first-child {
    margin-bottom: 8px;
  }

  .${paperClassNames.variantOutlined} {
    padding: 12px;
    background-color: var(--grey-7);
    border: 1px solid var(--grey-3);

    ${({ theme }) => theme.fns.getShapeStyles('soft')}
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r16')}
  }
`;
