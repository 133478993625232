import { VFC } from 'react';
import { Helmet } from 'react-helmet';

import { AcceptedCreatorDashboard } from 'src/components/creator/AcceptedCreatorDashboard';
import { CreatorDashboardProps } from './CreatorDashboard.types';
import { PendingCreatorDashboard } from 'src/components/creator/PendingCreatorDashboard';

export const CreatorDashboard: VFC<CreatorDashboardProps> = ({
  className,
  style,
  creatorStatus,
  onBack,
  handleRequestApproval,
  requestApprovalWorking,
  requestApprovalError,
}) => {
  const isAcceptedCreator = creatorStatus === 'accepted';
  return (
    <>
      <Helmet>
        <title>My Earnings - Moonsift</title>
      </Helmet>
      {isAcceptedCreator ? (
        <AcceptedCreatorDashboard className={className} style={style} />
      ) : (
        <PendingCreatorDashboard
          className={className}
          style={style}
          onBack={onBack}
          handleRequestApproval={handleRequestApproval}
          requestApprovalWorking={requestApprovalWorking}
          requestApprovalError={requestApprovalError}
        />
      )}
    </>
  );
};
