/*
  Only accessible to the owner. Shown when the owner clicks the "Bought by" button on an item in "revealing" mode.
*/

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  StandardButton,
} from '@moonsifttech/design-system';
import { RootState } from 'src/index';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { useItem } from 'src/hooks/useItem';
import { useMine } from 'src/hooks/useMine';
import { ModalContent } from 'src/components/core/ModalContent';
import { GiftingMessage } from 'src/components/collection/GiftingMessage';

const formatArrayToList = (arr: string[]): string => {
  if (arr.length <= 1) {
    return arr[0];
  }
  return `${arr.slice(0, -1).join(', ')} & ${arr.slice(-1)}`;
};

const formatNames = (
  arr: string[],
  limit: number = 3,
): string => {
  const names = arr.slice(0, limit);
  if (names.length > limit) {
    names.push('others');
  }

  return formatArrayToList(names);
};


const UnstyledBoughtByDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {

  const { isMine } = useMine(collectionUserId);
  const [ setModal, closeModal ] = useModal();
  const { privateRegistry, multiplePurchases } = useItem(itemId);

  const purchaserNames = useSelector((state: RootState) => {
    return state.db.user_public.map;
  });

  const purchases = privateRegistry?.map(entry => {
    const name = purchaserNames[entry.purchaser_user_uid ?? 'NOT_LOGGED_IN']?.name
      ?? entry.purchaser_identity_name
      ?? 'Someone';
    return {
      name,
      message: entry.message?.text,
    };
  }) ?? [];

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        {`${formatNames(purchases.map(({ name }) => name))} kindly bought this for you`}
      </Typography>
      <div className="BoughtByDialog-purchasesWrapper">
        {
          purchases
            .filter(purchase => purchase.message != null)
            .map(purchase => (
              <GiftingMessage
                key={purchase.name}
                author={purchase.name}
                className="BoughtByDialog-giftingMessage"
              >
                <i>{purchase.message}</i>
              </GiftingMessage>
            ))
        }
      </div>
      <Stack direction='row' justifyContent='flex-end'>
        <StandardButton
          className="BoughtByDialog-unmarkButton"
          variant="secondary"
          onClick={() => setModal({
            type: isMine && multiplePurchases
              ? ModalTypes.UnmarkOwner
              : ModalTypes.Unmark,
            collectionId,
            collectionUserId,
            itemId,
          })}
        >
          Un-mark as bought
        </StandardButton>
        <StandardButton
          variant="primary-white"
          onClick={() => closeModal()}
        >
          Back
        </StandardButton>
      </Stack>
    </ModalContent>
  );
};

export const BoughtByDialog = styled(UnstyledBoughtByDialog)`
  .BoughtByDialog-purchasesWrapper {
    margin-bottom: 2rem;
  }

  .BoughtByDialog-giftingMessage {
    margin-bottom: 1rem;
  }

  .BoughtByDialog-unmarkButton {
    margin-right: 0.75rem;
  }
`;
