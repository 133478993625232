import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  VoidComponentProps,
  Stack,
  stackClassNames,
  Typography,
} from '@moonsifttech/design-system';

import OldCollectionsCarousel from 'src/mvp22/view-components/OldCollectionsCarousel';
import { Page } from 'src/components/common/Page';
import { AppStoreLinkButton } from 'src/components/common/AppStoreLinkButton';
import { Link } from 'src/components/core/Link';
import { FiveStars } from 'src/components/common/FiveStars';
import R, { EXTERNAL } from 'src/routes';
import { ShopWindow } from 'src/components/common/ShopWindow';
import { BrowserExtensionButton } from 'src/components/common/BrowserExtensionButton';
import { PeopleSayingCarousel } from 'src/components/common/PeopleSayingCarousel';
import { UseCaseCard } from 'src/components/common/UseCaseCard';
import { ChristmasLandingProps } from './ChristmasLanding.types';
import { UseCases } from 'src/components/landing/UseCases';

const UnstyledCallToAction: VFC<
  VoidComponentProps & {
    hasChromeExtensionLink?: boolean;
    hasBrowserExtensionButtonSizeFixed?: boolean;
  }
> = ({
  className,
  style,
  hasChromeExtensionLink = true,
  hasBrowserExtensionButtonSizeFixed = false,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CallToAction-ctaButtonsInSafari">
        <Stack alignItems="center">
          <AppStoreLinkButton />
        </Stack>
        {hasChromeExtensionLink ? (
          <Typography
            className="CallToAction-chromeExtensionLink"
            variant="primary.r16"
            component="p"
            align="center"
          >
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
        ) : (
          <Typography variant="primary.l16" component="p" align="center">
            Get Moonsift on the App Store - it's free
          </Typography>
        )}
      </div>
      <div className="CallToAction-ctaButtonsMobile">
        <BrowserExtensionButton size="medium" to={R.SIGNUP} fullWidth>
          Get started -{' '}
          <span className="CallToAction-ctaLightCopy">&nbsp;it's free</span>
        </BrowserExtensionButton>
      </div>
      <div className="CallToAction-ctaButtons">
        <BrowserExtensionButton
          size={hasBrowserExtensionButtonSizeFixed ? 'medium' : 'large'}
          fullWidth
          to={R.SIGNUP}
        >
          Get started -{' '}
          <span className="CallToAction-ctaLightCopy">&nbsp;it's free</span>
        </BrowserExtensionButton>
      </div>
    </div>
  );
};

const CallToAction = styled(UnstyledCallToAction)`
  .CallToAction-ctaButtonsInSafari,
  .CallToAction-ctaButtonsMobile,
  .CallToAction-ctaButtons {
    display: none;
  }
  .CallToAction-chromeExtensionLink {
    margin-top: 8px;
  }

  .App-root.App-isIOS & .CallToAction-ctaButtonsInSafari {
    display: block;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'lg' })} {
    .App-root:not(.App-isIOS) & .CallToAction-ctaButtonsMobile {
      display: block;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .App-root:not(.App-isIOS) & .CallToAction-ctaButtons {
      display: block;
    }
  }

  .ctaButtonsInSafari > p {
    margin-bottom: 0;
  }

  .CallToAction-ctaButtonsMobile,
  .CallToAction-ctaButtons {
    margin: 0 auto;
  }

  .CallToAction-ctaButtonsMobile {
    max-width: 328px;
  }

  .CallToAction-ctaButtons {
    max-width: 488px;
  }

  ${BrowserExtensionButton} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .CallToAction-ctaLightCopy {
      font-weight: 300;
    }
  }
`;

const UnstyledHeadingSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <Typography variant="editorial.r46" component="h1" align="center">
        Make your
        <br />
        <span>Christmas</span> list
      </Typography>
      <Typography variant="primary.l16" component="p" align="center">
        Add any item from any store in the world.
        <br />
        100% free to use.
      </Typography>
      <CallToAction />
    </section>
  );
};

const HeadingSection = styled(UnstyledHeadingSection)`
  margin: 0 auto;
  padding: 56px 16px 20px;
  max-width: 360px;

  > h1 {
    margin-bottom: 10px;

    > span {
      color: var(--background-green);
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    max-width: 640px;

    > h1 {
      ${({ theme }) => theme.fns.getTypographyStyles('editorial.r68')}
      margin-bottom: 24px;
    }

    > p {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.l20')}
      margin-bottom: 24px;
    }
  }
`;

const UnstyledShopWindowSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <ShopWindow />
    </section>
  );
};

const ShopWindowSection = styled(UnstyledShopWindowSection)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  ${ShopWindow} {
    width: max-content;
    margin-top: 0;
  }

  @media (min-width: 620px) {
    ${ShopWindow} {
      margin-top: -220px;
    }
  }
`;

const UnstyledBenefitsSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <Stack direction="row" justifyContent="center" wrap="wrap">
        <Stack className="BenefitsSection-benefit" direction="column">
          <div className="BenefitsSection-benefitIconContainer">
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/globe-outline-slim.svg`}
              alt=""
            />
          </div>
          <Typography variant="editorial.r26" align="center" component="h2">
            Add from any store
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Add any item from any store in the world
          </Typography>
        </Stack>

        <Stack className="BenefitsSection-benefit" direction="column">
          <div className="BenefitsSection-benefitIconContainer">
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/wallet-outline-slim.svg`}
              alt=""
            />
          </div>
          <Typography variant="editorial.r26" align="center" component="h2">
            Completely Free
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Make and share as many gift lists as you like
          </Typography>
        </Stack>

        <Stack className="BenefitsSection-benefit" direction="column">
          <div className="BenefitsSection-benefitIconContainer">
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/gift-outline-slim.svg`}
              alt=""
            />
          </div>
          <Typography variant="editorial.r26" align="center" component="h2">
            Get gifts you really want
          </Typography>
          <Typography variant="primary.l20" align="center" component="p">
            Share with a single link and avoid duplicate gifts
          </Typography>
        </Stack>
      </Stack>
    </section>
  );
};

const BenefitsSection = styled(UnstyledBenefitsSection)`
  margin: 0 auto;
  padding: 40px 16px;
  max-width: 1064px;

  h2 {
    margin-bottom: 24px;
  }

  .BenefitsSection-benefit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 304px;
    padding: 28px 12px;

    .BenefitsSection-benefitIconContainer {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 8px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 72px 16px;
  }
`;

const UnstyledBrandsBannerSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <picture>
        <source
          media="(max-width: 768px)"
          srcSet={`${process.env.REACT_APP_STATIC_URL}/registry-landing/registry-brands-banner.png`}
        />
        <img
          className="ChristmasLanding-brandsBanner"
          alt="Brands banner"
          src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/registry-brands-banner.2x.png`}
        />
      </picture>
    </section>
  );
};

const BrandsBannerSection = styled(UnstyledBrandsBannerSection)`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;

  img {
    display: block;
    width: auto;
    height: 281px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    img {
      height: 202px;
    }
  }
`;

const UnstyledCreateAndShareSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className="CreateAndShareSection-mobileContent">
        <Typography variant="editorial.r26" component="h2">
          Create and share your Christmas list
        </Typography>
        <Typography variant="primary.l20" component="p">
          Moonsift lets you create beautiful online wishlists on an easy to use
          platform. Perfect for making Christmas lists to share with family &
          friends, or to keep track of gift ideas for others.
        </Typography>
        <CallToAction
          className="CreateAndShareSection-callToAction"
          hasBrowserExtensionButtonSizeFixed
        />
        <picture>
          <source
            media="(max-width: 768px)"
            srcSet={`${process.env.REACT_APP_STATIC_URL}/christmas-landing/christmas-list-mobile.png`}
          />
          <img
            alt="Christmas list"
            src={`${process.env.REACT_APP_STATIC_URL}/christmas-landing/christmas-list-mobile.2x.png`}
          />
        </picture>
      </div>
      <div className="CreateAndShareSection-content">
        <div>
          <Typography variant="editorial.sb34" component="h2">
            Create and share your Christmas list
          </Typography>
          <Typography variant="primary.l20" component="p">
            Moonsift lets you create beautiful online wishlists on an easy to
            use platform. Perfect for making Christmas lists to share with
            family & friends, or to keep track of gift ideas for others.
          </Typography>
          <CallToAction hasBrowserExtensionButtonSizeFixed />
        </div>
        <div>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/christmas-landing/christmas-list.png`}
            />
            <img
              alt="Christmas list"
              src={`${process.env.REACT_APP_STATIC_URL}/christmas-landing/christmas-list.2x.png`}
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

const CreateAndShareSection = styled(UnstyledCreateAndShareSection)`
  padding: 68px 16px 0;

  picture img {
    display: block;
    width: 100%;
  }

  .CreateAndShareSection-mobileContent {
    margin: 0 auto;
    max-width: 328px;

    > h2 {
      margin-bottom: 12px;
    }

    > p {
      margin-bottom: 20px;
    }

    .CreateAndShareSection-callToAction {
      margin-bottom: 68px;
    }
  }

  .CreateAndShareSection-content {
    display: none;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 124px 16px 112px;

    .CreateAndShareSection-mobileContent {
      display: none;
    }

    .CreateAndShareSection-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 1000px;

      > div:first-child {
        max-width: 360px;

        > h2 {
          margin-bottom: 12px;
        }

        > p {
          margin-bottom: 20px;
        }

        > ${CallToAction} .CallToAction-ctaButtons {
          margin: 0;

          ${BrowserExtensionButton} {
            max-width: 328px;
            align-items: flex-start;
          }
        }
      }

      > div:last-child {
        max-width: 500px;
      }
    }
  }
`;

const UnstyledPeopleAreSayingSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className="PeopleAreSayingSection-content">
        <Typography variant="editorial.r26" align="center" component="h2">
          Read what people are saying
          <Stack direction="row" alignItems="center">
            <FiveStars size="large" />
          </Stack>
        </Typography>

        <PeopleSayingCarousel
          className="PeopleAreSayingSection-carousel"
          type="registry"
        />

        <CallToAction hasChromeExtensionLink={false} />
      </div>
    </section>
  );
};

const PeopleAreSayingSection = styled(UnstyledPeopleAreSayingSection)`
  padding: 68px 16px 68px;

  .PeopleAreSayingSection-content {
    max-width: 880px;
    margin: 0 auto;

    > h2 {
      margin-bottom: 20px;

      > .${stackClassNames.root} {
        margin-left: 12px;
        display: inline-flex;
      }
    }

    .PeopleAreSayingSection-carousel {
      margin-bottom: 68px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px 100px;

    .PeopleAreSayingSection-content > h2 {
      ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
    }
  }
`;

const UnstyledIdeasSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className="IdeasSection-content">
        <Typography variant="editorial.r26" component="h2">
          Christmas gift list ideas
        </Typography>
        <Typography variant="primary.l20" component="p">
          Explore Christmas and Secret Santa ideas curated on Moonsift
        </Typography>
        <OldCollectionsCarousel />
      </div>
    </section>
  );
};

const IdeasSection = styled(UnstyledIdeasSection)`
  padding: 68px 16px calc(68px - 56px);

  .IdeasSection-content {
    max-width: 880px;
    margin: 0 auto;

    > h2 {
      margin-bottom: 12px;
    }

    > h2 + p {
      margin-bottom: calc(24px - 14px);
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px calc(100px - 56px);

    .IdeasSection-content > h2 {
      ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
    }
  }
`;

const UnstyledPerfectChristmasSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className="IdeasSection-content">
        <Typography variant="editorial.r26" component="h2">
          Find the perfect Christmas & Secret Santa gifts
        </Typography>
        <Typography variant="primary.l20" component="p">
          Finding the perfect gift is hard, especially on a secret santa budget.
          Luckily for you, the Moonsift community has already sifted through
          1000's of stores to find the best Christmas gift ideas for him, her
          and the kids, including gift ideas specifically for secret santa.
          Check them out above. Whenever you see something you like, save it to
          Moonsift so you can keep track of everything you're considering in one
          place and compare options side by side before making a decision.
        </Typography>
      </div>
    </section>
  );
};

const PerfectChristmasSection = styled(UnstyledPerfectChristmasSection)`
  padding: 68px 16px;

  .IdeasSection-content {
    max-width: 328px;
    margin: 0 auto;

    > h2 {
      margin-bottom: 12px;
    }

    > h2 + p {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px;

    .IdeasSection-content {
      max-width: 800px;

      > h2 {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
      }
    }
  }
`;

const UnstyledUseCasesSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <Typography variant="editorial.r26" align="center" component="h2">
        Create your gift list on a beautiful, easy to use platform, whatever the
        occasion
      </Typography>
      <Stack direction="row" justifyContent="center" wrap="wrap">
        <UseCases goToSignUp={['christmas', 'birthday']} />
      </Stack>
    </section>
  );
};

const UseCasesSection = styled(UnstyledUseCasesSection)`
  margin: 0 auto;
  padding: 56px 16px 68px;
  max-width: 1088px;

  h2 {
    max-width: 380px;
    margin: 0 auto 12px;
  }

  ${UseCaseCard} {
    margin: 12px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px 88px;
  }
`;

const UnstyledSaveItemsSection: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className="SaveItemsSection-content">
        <Typography variant="editorial.r26" component="h2">
          Save items with our iOS app or browser extension
        </Typography>
        <Typography variant="primary.l20" component="p">
          Moonsift's universal wishlist button lets you add products from any
          store in the world. From major stores like Amazon, Target, Williams
          Sonoma, John Lewis, Myer and Anthropologie to local boutiques and
          independent stores.
        </Typography>
        <CallToAction hasBrowserExtensionButtonSizeFixed />
      </div>
    </section>
  );
};

const SaveItemsSection = styled(UnstyledSaveItemsSection)`
  padding: 68px 16px;

  .SaveItemsSection-content {
    max-width: 328px;
    margin: 0 auto;

    > h2 {
      margin-bottom: 12px;
    }

    > h2 + p {
      margin-bottom: 20px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    padding: 100px 16px;

    .SaveItemsSection-content {
      max-width: 800px;

      > h2 {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
      }

      > h2 + p {
        margin-bottom: 24px;
      }

      ${CallToAction} .CallToAction-ctaButtons {
        margin: 0;

        ${BrowserExtensionButton} {
          max-width: 328px;
          align-items: flex-start;
        }
      }
    }
  }
`;

const UnstyledChristmasLanding: VFC<ChristmasLandingProps> = ({
  className,
  style,
}) => {
  return (
    <>
      <Helmet>
        <title>Moonsift: Christmas List maker - Add items from Any Store</title>
        <meta
          name="description"
          content="Create your Christmas wish list on a beautiful platform and share it anywhere. Add products from any store in the world. Free and easy to use."
        />
      </Helmet>
      <Page className={className} style={style} isLanding showFooter>
        <HeadingSection />
        <ShopWindowSection />
        <BenefitsSection />
        <BrandsBannerSection />
        <CreateAndShareSection />
        <PeopleAreSayingSection className="ChristmasLanding-backgroundGrey" />
        <IdeasSection />
        <PerfectChristmasSection className="ChristmasLanding-backgroundGrey" />
        <UseCasesSection />
        <SaveItemsSection className="ChristmasLanding-backgroundGrey" />
      </Page>
    </>
  );
};

export const ChristmasLanding = styled(UnstyledChristmasLanding)`
  overflow-x: hidden;

  section.ChristmasLanding-backgroundGrey {
    background-color: var(--grey-7);
  }
`;
