import { VFC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, array, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import { updateCreatorApplicationRequest } from 'src/store/actions/creatorApplication';
import {
  CreatorApplicationOneContainerProps,
  CreatorApplicationOneProps,
  CreatorApplicationOneFormContent,
} from './CreatorApplicationOne.types';
import { CreatorApplicationOne } from './CreatorApplicationOne.component';

const validationSchema = object({
  categories: array(
    string().oneOf([
      'Fashion',
      'Home & Interiors',
      'Beauty',
      'Family',
      'Health & Fitness',
      'Lifestyle',
      'Other',
    ]),
  ).min(1, 'Select at least one category, please.'),
});

const CreatorApplicationOneContainer: VFC<
  CreatorApplicationOneContainerProps
> = (props) => {
  const history = useHistory();

  const dipatch = useDispatch();
  const initialValues = useSelector<
    RootState,
    CreatorApplicationOneFormContent
  >(({ creatorApplication }) => ({
    categories: [...creatorApplication.categories],
  }));

  const handleSubmit = useCallback<CreatorApplicationOneProps['onSubmit']>(
    (values, actions) => {
      dipatch(updateCreatorApplicationRequest(values));
      actions.setSubmitting(false);
      history.push(R.CREATOR_APPLICATION_TWO);
    },
    [dipatch, history],
  );

  return (
    <CreatorApplicationOne
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export { CreatorApplicationOneContainer as CreatorApplicationOne };
