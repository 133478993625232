import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import {
  Stack,
  stackClassNames,
  Typography,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { Link } from 'src/components/core/Link';
import { FiveStars } from 'src/components/common/FiveStars';
import R, { EXTERNAL } from 'src/routes';
import { ShopWindow } from 'src/components/common/ShopWindow';
import { AppStoreLinkButton } from 'src/components/common/AppStoreLinkButton';
import { BrowserExtensionButton } from 'src/components/common/BrowserExtensionButton';
import { PeopleSayingCarousel } from 'src/components/common/PeopleSayingCarousel';
import { RegistryLandingProps } from './RegistryLanding.types';
import { UseCases } from 'src/components/landing/UseCases';

const UnstyledRegistryLanding: VFC<RegistryLandingProps> = ({
  className,
  style,
}) => {
  return (
    <>
      <Helmet>
        <title>
          Moonsift: Gift List & Registry - Add any item from Any Store in the
          world
        </title>
        <meta
          name="description"
          content="Perfect for Baby Showers, Weddings, Birthdays, Christmas Lists etc. Create your free online Gift List. Add any item from any store in the world."
        />
      </Helmet>
      <Page className={className} style={style} isLanding showFooter>
        <section className="RegistryLanding-headingSection">
          <Typography variant="editorial.r46" component="h1" align="center">
            Create your{' '}
            <br
              className={clsx(
                'RegistryLanding-displayNone',
                'RegistryLanding-displayBlockLg',
              )}
            />
            Registry or <br className="RegistryLanding-displayNoneLg" />
            Gift list
          </Typography>
          <Typography
            className="RegistryLanding-headingEnd"
            variant="editorial.r26"
            component="p"
            align="center"
          >
            for any occasion
          </Typography>
          <Typography
            className="RegistryLanding-headingStatement"
            variant="primary.l16"
            component="p"
            align="center"
          >
            Add any item from any store in the world.
            <br />
            100% free to use.
          </Typography>
          <div className="RegistryLanding-ctaButtonsInSafari">
            <Stack>
              <AppStoreLinkButton />
            </Stack>
            <Typography
              className="RegistryLanding-chromeExtensionLink"
              variant="primary.r16"
              component="p"
              align="center"
            >
              <Link to={EXTERNAL.CHROMESTORE} target="_blank">
                Get Moonsift for desktop instead
              </Link>
            </Typography>
          </div>
          <div className="RegistryLanding-ctaButtonsMobile">
            <BrowserExtensionButton size="medium" fullWidth to={R.SIGNUP}>
              Get started -{' '}
              <span className="RegistryLanding-ctaLightCopy">
                &nbsp;it's free
              </span>
            </BrowserExtensionButton>
          </div>
          <div className="RegistryLanding-ctaButtons">
            <BrowserExtensionButton size="large" fullWidth to={R.SIGNUP}>
              Get started -{' '}
              <span className="RegistryLanding-ctaLightCopy">
                &nbsp;it's free
              </span>
            </BrowserExtensionButton>
          </div>
        </section>

        <section className="RegistryLanding-shopWindowSection">
          <ShopWindow />
        </section>

        <section className="RegistryLanding-benefitsSection">
          <Stack direction="row" justifyContent="center" wrap="wrap">
            <Stack className="RegistryLanding-benefit" direction="column">
              <div className="RegistryLanding-benefitIconContainer">
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/globe-outline-slim.svg`}
                  alt=""
                />
              </div>
              <Typography variant="editorial.r26" align="center" component="h2">
                Add from any store
              </Typography>
              <Typography variant="primary.l20" align="center" component="p">
                Don't limit your options, add any item from any store
              </Typography>
            </Stack>

            <Stack className="RegistryLanding-benefit" direction="column">
              <div className="RegistryLanding-benefitIconContainer">
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/wallet-outline-slim.svg`}
                  alt=""
                />
              </div>
              <Typography variant="editorial.r26" align="center" component="h2">
                No mark-ups
              </Typography>
              <Typography variant="primary.l20" align="center" component="p">
                Say goodbye to overpriced registry catalogues
              </Typography>
            </Stack>

            <Stack className="RegistryLanding-benefit" direction="column">
              <div className="RegistryLanding-benefitIconContainer">
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/gift-outline-slim.svg`}
                  alt=""
                />
              </div>
              <Typography variant="editorial.r26" align="center" component="h2">
                Get gifts you really want
              </Typography>
              <Typography variant="primary.l20" align="center" component="p">
                Share with a single link and avoid duplicate gifts
              </Typography>
            </Stack>
          </Stack>
        </section>

        <section className="RegistryLanding-brandsBannerSection">
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/registry-landing/registry-brands-banner.png`}
            />
            <img
              className="RegistryLanding-brandsBanner"
              alt="Brands banner"
              src={`${process.env.REACT_APP_STATIC_URL}/registry-landing/registry-brands-banner.2x.png`}
            />
          </picture>
        </section>

        <section className="RegistryLanding-useCasesSection">
          <Typography variant="editorial.r26" align="center" component="h2">
            Create your gift list on a beautiful, easy to use platform, whatever
            the occasion
          </Typography>
          <UseCases hidden={['stylists']} goToSignUp={['wedding']} />
        </section>

        <section className="RegistryLanding-carouselSection">
          <Typography variant="editorial.r26" align="center" component="h2">
            Read what people are saying
            <Stack direction="row" alignItems="center">
              <FiveStars size="large" />
            </Stack>
          </Typography>

          <PeopleSayingCarousel
            className="RegistryLanding-carousel"
            type="registry"
          />

          <div className="RegistryLanding-ctaButtonsInSafari">
            <Typography variant="primary.l16" component="p" align="center">
              Get Moonsift on the App Store - it's free
            </Typography>
            <Stack>
              <AppStoreLinkButton />
            </Stack>
            <Typography
              className="RegistryLanding-chromeExtensionLink"
              variant="primary.r16"
              align="center"
              component="p"
            >
              <Link to={EXTERNAL.CHROMESTORE} target="_blank">
                Get Moonsift for desktop instead
              </Link>
            </Typography>
          </div>
          <div className="RegistryLanding-ctaButtonsMobile">
            <BrowserExtensionButton size="medium" fullWidth to={R.SIGNUP}>
              Get started -{' '}
              <span className="RegistryLanding-ctaLightCopy">
                &nbsp;it's free
              </span>
            </BrowserExtensionButton>
          </div>
          <div className="RegistryLanding-ctaButtons">
            <BrowserExtensionButton size="large" fullWidth to={R.SIGNUP}>
              Get started -{' '}
              <span className="RegistryLanding-ctaLightCopy">
                &nbsp;it's free
              </span>
            </BrowserExtensionButton>
          </div>
        </section>
      </Page>
    </>
  );
};

export const RegistryLanding = styled(UnstyledRegistryLanding)`
  overflow-x: hidden;

  .RegistryLanding-displayNone {
    display: none;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-displayBlockLg {
      display: block;
    }

    .RegistryLanding-displayNoneLg {
      display: none;
    }
  }

  .RegistryLanding-headingSection {
    margin: 0 auto;
    padding: 56px 16px 20px;
    max-width: 360px;

    h1 {
      margin-bottom: 10px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-headingSection {
      max-width: 640px;

      h1 {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.r68')}
        margin-bottom: 24px;
      }

      .RegistryLanding-headingEnd {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
        margin-bottom: 12px;
      }

      .RegistryLanding-headingStatement {
        ${({ theme }) => theme.fns.getTypographyStyles('primary.l20')}
        margin-bottom: 24px;
      }
    }
  }

  .RegistryLanding-ctaButtonsInSafari,
  .RegistryLanding-ctaButtonsMobile,
  .RegistryLanding-ctaButtons {
    display: none;
  }

  .App-root.App-isIOS & .RegistryLanding-ctaButtonsInSafari {
    display: block;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'lg' })} {
    .App-root:not(.App-isIOS) & .RegistryLanding-ctaButtonsMobile {
      display: block;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .App-root:not(.App-isIOS) & .RegistryLanding-ctaButtons {
      display: block;
    }
  }

  .RegistryLanding-ctaButtonsMobile,
  .RegistryLanding-ctaButtons {
    margin: 0 auto;
  }

  .RegistryLanding-ctaButtonsMobile {
    max-width: 328px;
  }

  .RegistryLanding-ctaButtons {
    max-width: 488px;
  }

  ${BrowserExtensionButton} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .RegistryLanding-ctaLightCopy {
      font-weight: 300;
    }
  }

  .RegistryLanding-appStoreButton {
    height: 54px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  .RegistryLanding-chromeExtensionLink {
    margin-bottom: 0;
    margin-top: 8px;
  }

  .RegistryLanding-shopWindowSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;

    ${ShopWindow} {
      width: max-content;
      margin-top: 0;
    }

    @media (min-width: 620px) {
      ${ShopWindow} {
        margin-top: -220px;
      }
    }
  }

  .RegistryLanding-brandsBannerSection {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    img {
      display: block;
      height: 281px;
      width: auto;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-brandsBannerSection img {
      height: 202px;
    }
  }

  .RegistryLanding-benefitsSection {
    margin: 0 auto;
    padding: 40px 16px;
    max-width: 1064px;

    h2 {
      margin-bottom: 24px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-benefitsSection {
      padding: 72px 16px;
    }
  }

  .RegistryLanding-benefit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 304px;
    padding: 28px 12px;

    .RegistryLanding-benefitIconContainer {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 8px;
    }

    p {
      margin-bottom: 8px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  .RegistryLanding-useCasesSection {
    margin: 0 auto;
    padding: 68px 16px 56px;
    max-width: 1088px;

    h2 {
      max-width: 380px;
      margin: 0 auto 12px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-useCasesSection {
      padding: 100px 16px 88px;
    }
  }

  .RegistryLanding-carouselSection {
    margin: 0 auto;
    padding: 0 20px 56px;
    max-width: 840px;

    h2 {
      margin-bottom: 24px;

      > .${stackClassNames.root} {
        margin-left: 12px;
        display: inline-flex;
      }
    }

    .RegistryLanding-carousel {
      margin-bottom: 68px;
    }

    .RegistryLanding-ctaButtonsMobile,
    .RegistryLanding-ctaButtons {
      margin-bottom: 56px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
    .RegistryLanding-carouselSection {
      padding: 32px 54px 108px;

      h2 {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.sb34')}
      }
    }
  }
`;
