import { FC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import { NavControls } from 'src/components/common/NavControls';
import { PageProps } from './Page.types';

const UnstyledPage: FC<PageProps> = ({
  className,
  style,
  hug,
  selected,
  showRight,
  showStylists = true,
  showGetMoonsift = true,
  showCreators,
  showNavControls,
  showFooter,
  isLanding,
  children,
}) => {
  return (
    <div
      className={clsx(
        className,
        showNavControls && 'Page-withNavControls',
        isLanding && 'Page-isLanding',
      )}
      style={style}
    >
      <TopMenu
        className="Page-topMenu"
        hug={hug}
        selected={selected}
        showRight={showRight}
        showStylists={showStylists}
        showGetMoonsift={showGetMoonsift}
        showCreators={showCreators}
      />
      {showNavControls && <NavControls className="Page-navControls" />}
      {children}
      {showFooter && <BottomBar />}
    </div>
  );
};

export const Page = styled(UnstyledPage)`
  .Page-topMenu {
    height: 56px;
    overflow: hidden;
    margin-bottom: 28px;
  }

  &.Page-withNavControls > .Page-topMenu {
    margin-bottom: calc(8px + 40px + 16px);
  }

  &.Page-isLanding > .Page-topMenu {
    margin-bottom: 0;
  }

  .Page-navControls {
    position: fixed;
    top: calc(56px + 8px);
    z-index: 10;
    margin-bottom: 16px;
  }
`;
