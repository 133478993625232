import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Stack,
  DeleteIcon,
  StandardButton,
  TextField,
  textFieldClassNames,
  iconBaseClassNames,
  ToggleButtonGroup,
  toggleButtonGroupClassNames,
  ToggleButton,
  toggleButtonClassNames,
  EarthIcon,
  PersonAddIcon,
  GiftIcon,
  LockClosedOutlinedIcon,
  buttonBaseClassNames,
} from '@moonsifttech/design-system';

import {
  EditModalContentContentProps,
  CollectionTypeOptionKey,
  CollectionTypeOption,
  CollectionTypeOptions,
} from './EditModalContent.types';
import { RegistryConfiguration } from 'src/components/common/RegistryConfiguration';

const collectionTypeOptions: CollectionTypeOptions = {
  public: {
    name: 'Public',
    description: 'Accessible to anyone and visible in your profile.',
    icon: 'earth',
  },
  private: {
    name: 'Private',
    description: 'Only people you share the secret link with can view.',
    icon: 'person-add',
  },
  hidden: {
    name: 'Hidden',
    description: 'No one else can view, even if they have the link.',
    icon: 'lock-closed-outlined',
  },
};

const collectionTypeEntries = Object.entries(collectionTypeOptions) as [
  CollectionTypeOptionKey,
  CollectionTypeOption,
][];

const mapIconNameToIcon: Record<string, React.ReactNode> = {
  'earth': <EarthIcon />,
  'person-add': <PersonAddIcon />,
  'gift': <GiftIcon />,
  'lock-closed-outlined': <LockClosedOutlinedIcon />,
};

const UnstyledEditModalContentContent: React.FC<
  EditModalContentContentProps
> = ({
  className,
  style,
  showConfirmation,
  deleting,
  name,
  description,
  selectedCollectionType,
  onCollectionTypeChange,
  onDelete,
  toggleIsRegistry,
  isRegistryNext,
}) => {
  return (
    <div className={className} style={style}>
      {showConfirmation ? (
        <>
          <Typography
            className="EditModalContentContent-confirmationHeading"
            variant="primary.b26"
            component="h1"
          >
            Are you sure?
          </Typography>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <DeleteIcon className="EditModalContentContent-confirmationIcon" />
            <Typography variant="primary.r16" align="center" component="p">
              The collection “{name}” and all items within it will be deleted.
              You cannot undo this.
            </Typography>
            <StandardButton
              variant="primary-white"
              loading={deleting}
              onClick={onDelete}
            >
              Delete
            </StandardButton>
          </Stack>
        </>
      ) : (
        <>
          <Typography variant="primary.b26" component="h1">
            Edit or delete collection
          </Typography>
          <Typography
            className="EditModalContentContent-formHeading"
            variant="primary.r14"
            color="grey.2"
            component="p"
          >
            Rename your collection
          </Typography>
          <TextField
            name="collectionName"
            defaultValue={name}
            maxLength={100}
            fullWidth
            autoFocus
          />

          <TextField
            name="collectionDescription"
            placeholder="Add more info (optional)"
            defaultValue={description}
            multiline
            maxLength={600}
            rows={5}
            maxRows={5}
            fullWidth
          />
          <ToggleButtonGroup
            value={selectedCollectionType}
            onChange={onCollectionTypeChange}
            fullWidth
          >
            {collectionTypeEntries.map(([key, { name, icon }]) => {
              return (
                <ToggleButton
                  key={key}
                  startIcon={mapIconNameToIcon[icon]}
                  value={key}
                  color="common.white"
                >
                  {name}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>

          {selectedCollectionType && (
            <Typography variant="primary.l14" component="p">
              {collectionTypeOptions[selectedCollectionType].description}
            </Typography>
          )}
          <RegistryConfiguration
            isRegistry={isRegistryNext}
            toggleIsRegistry={toggleIsRegistry}
          />
        </>
      )}
    </div>
  );
};

export const EditModalContentContent = styled(UnstyledEditModalContentContent)`
  .EditModalContentContent-confirmationHeading {
    margin-bottom: 60px;
  }

  .EditModalContentContent-registryPublicToggle {
    margin-top: -8px;
    margin-bottom: 4px;
  }

  .${iconBaseClassNames.root}.EditModalContentContent-confirmationIcon {
    font-size: 100px;
    margin-bottom: 12px;
  }

  .EditModalContentContent-formHeading {
    margin-bottom: 10px;
  }

  .${textFieldClassNames.root} {
    margin-bottom: 16px;
  }

  .${toggleButtonGroupClassNames.root} {
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'md' })} {
    .${toggleButtonGroupClassNames.root} {
      overflow-x: auto;
      overflow-y: hidden;
      padding: 1px;

      .${toggleButtonClassNames.root} {
        padding: 7px;
        width: 80px;
        min-width: 80px;
      }

      .${buttonBaseClassNames.label} {
        flex-direction: column;

        .${buttonBaseClassNames.startIcon} {
          margin-right: 0;
          margin-bottom: 4px;
        }
      }
    }
  }
`;
