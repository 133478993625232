import {
  useEffect,
  useCallback,
  useState,
  ReactEventHandler,
  useContext,
} from 'react';
import { ServicesContext } from 'src/ServicesContext';

export type OnCopyFormEventHandler = ReactEventHandler<
  HTMLElement & {
    url: HTMLInputElement;
  }
>;

export function useCopy() {
  const { analytics } = useContext(ServicesContext);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const handle = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(handle);
    }
  }, [copied]);

  const handleCopy = useCallback<OnCopyFormEventHandler>(
    (event) => {
      event.preventDefault();

      const input = event.currentTarget.url;

      // Select the text field
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      // Copy the text inside the text field
      navigator.clipboard.writeText(input.value);

      analytics.recordEvent('WebApp:LinkCopied', { link: input.value });

      // Set state as copied
      setCopied(true);
    },
    [analytics],
  );

  return {
    copied,
    handleCopy,
  };
}
