/*

Get Image URLs from IDs:

Move to utils

*/

export function get_image_full_url(url_in){
  if (url_in){
    return (url_in.slice(0,10)==="moonimage_")?"https://storage.googleapis.com/"+process.env.REACT_APP_FIREBASE_PROJECTID+"-product-images/"+url_in+".jpg":url_in;
  }else{
    return undefined;
  }
}

export function getCustomUrl(image_filename){
  return "https://storage.googleapis.com/"+process.env.REACT_APP_FIREBASE_PROJECTID+"-user-custom/"+image_filename;
}
