import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { object, array, string } from 'yup';

import { RootState } from 'src/index';
import R from 'src/routes';
import { updateStylistApplicationRequest } from 'src/store/actions/stylistApplication';
import {
  StylistApplicationThreeContainerProps,
  StylistApplicationThreeProps,
  StylistApplicationThreeFormContent,
} from './StylistApplicationThree.types';
import { StylistApplicationThree } from './StylistApplicationThree.component';

const validationSchema = object({
  audience: object({
    countries: array(string().required('Must provide a country.')).required(),
  }).required(),
}).required();

const StylistApplicationThreeContainer: VFC<
  StylistApplicationThreeContainerProps
> = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const initialValues = useSelector<
    RootState,
    StylistApplicationThreeFormContent
  >(({ stylistApplication }) => ({
    audience: {
      countries: [...stylistApplication.audience.countries],
    },
  }));

  const handleSubmit = useCallback<StylistApplicationThreeProps['onSubmit']>(
    async (values, actions) => {
      dispatch(updateStylistApplicationRequest(values));
      actions.setSubmitting(false);
      history.push(R.STYLIST_APPLICATION_FOUR);
    },
    [dispatch, history],
  );

  const formRef: StylistApplicationThreeProps['formRef'] = useRef(null);

  const handleBack = useCallback(() => {
    if (formRef.current) {
      const audience = {
        countries: [formRef.current['audience.countries[0]'].value],
      };

      dispatch(updateStylistApplicationRequest({ audience }));
    }
    history.push(R.STYLIST_APPLICATION_TWO);
  }, [dispatch, history]);

  return (
    <StylistApplicationThree
      initialValues={initialValues}
      validationSchema={validationSchema}
      formRef={formRef}
      onSubmit={handleSubmit}
      onBack={handleBack}
      {...props}
    />
  );
};

export { StylistApplicationThreeContainer as StylistApplicationThree };
