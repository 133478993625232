import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { CtaButton, Typography } from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { ExtensionUpgradedProps } from './ExtensionUpgraded.types';
import { UseCases } from 'src/components/landing/UseCases';

const UnstyledExtensionUpgraded: React.FC<ExtensionUpgradedProps> = ({
  className,
  style,
  goToMyCollections,
}) => {
  return (
    <>
      <Helmet>
        <title>Welcome to the Moonsift Browser Extension</title>
      </Helmet>
      <Page className={className} style={style}>
        <div className="ExtensionUpgraded-content">
          <Typography variant="editorial.sb34" component="h1">
            Your Moonsift extension has been updated.
          </Typography>
          <Typography variant="primary.l20" component="h1">
            We've made a few improvements to the speed and reliability. If you
            run into any issues, please use the feedback button or report a
            problem via the toolbar button.
          </Typography>
          <Typography
            variant="editorial.sb34"
            component="h1"
            className="ExtensionUpgraded-secondPoint"
          >
            Save, curate & share items from any store in the world.
          </Typography>
          <Typography variant="primary.l20" component="h1">
            Now also create registry collections. When you share these with
            family and friends, they will be asked to mark off what they buy so
            you avoid duplicate gifts.
          </Typography>
          <CtaButton
            variant="primary-black"
            fullWidth
            onClick={goToMyCollections}
          >
            Go to your collections
          </CtaButton>
        </div>
        <div className="ExtensionUpgraded-useCases">
          <UseCases hideCTA={true} />
        </div>
      </Page>
    </>
  );
};

export const ExtensionUpgraded = styled(UnstyledExtensionUpgraded)`
  .ExtensionUpgraded-useCases {
    margin: 60px auto;
    width: 100%;
    max-width: 1060px;
  }

  .ExtensionUpgraded-secondPoint {
    margin-top: 100px;
  }
  .ExtensionUpgraded-content {
    margin: 0 auto;
    max-width: 480px;
    text-align: center;

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 608 })} {
      margin: 0 16px;
    }
  }

  .ExtensionUpgraded-descriptionInput {
    margin-bottom: 40px;
  }
`;
