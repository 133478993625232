/*

TODO: Move into design system!

*/
import { createGlobalStyle } from 'styled-components';

import Juana from './font.woff';
import Juana2 from './font.woff2';

export default createGlobalStyle`
    @font-face {
        font-family: 'Juana';
        src: local('Juana'), local('Juana'),
        url(${Juana2}) format('woff2'),
        url(${Juana}) format('woff');
        font-weight: 300;
        font-style: normal;
    }
`;
