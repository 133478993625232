import { useEffect, useContext, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { UserObject } from 'src/services/Auth';
import { ActionNames, AuthAction } from 'src/types';

/*

This hook manages authentication but does not load in any other data about the user from the database:

*/
export const useAuth = () => {
  const dispatch = useDispatch();
  const [nullToken, setNullToken] = useState(false);
  const { auth, mobile, analytics } = useContext(ServicesContext);
  const authUserUIDSet = useSelector<RootState>((state) => state.auth.set);
  const authUserUID = useSelector<RootState>((state) => state.auth.id);

  const onAuthChangeFunction = useCallback(
    (user: UserObject | null) => {
      const authUserUID = user === null ? null : user.uid;
      const authUserEmail = user === null ? null : user.email;
      analytics.identifyUser(authUserUID);
      analytics.recordEvent('WebApp:OnAuthChange', { authUserUID });
      const authUserProviderName =
        user !== null &&
        user.providerData &&
        user.providerData.length > 0 &&
        user.providerData[0].displayName
          ? user.providerData[0].displayName
          : null;
      dispatch<AuthAction>({
        type: ActionNames.AUTH_SET,
        payload: {
          id: authUserUID,
          email: authUserEmail,
          provider_name: authUserProviderName,
        },
      });
    },
    [dispatch, analytics],
  );

  useEffect(() => {
    return auth?.auth.onAuthStateChanged(onAuthChangeFunction);
  }, [auth?.auth, onAuthChangeFunction]);

  // APP
  useEffect(() => {
    if (mobile.signInToken) {
      // App passes in "null" if it no longer has a token.
      if (mobile.signInToken !== 'null') {
        auth?.signInWithCustomToken(mobile.signInToken)
          .catch(() => {
            console.log('Token is not valid, received:', mobile.signInToken);
            setNullToken(true);
          });
      } else {
        // May be signed in to App or not,
        setNullToken(true);
      }
    }
  }, [auth, mobile]);

  // Extension testing method to sign-in:
  useEffect(() => {
    if (auth.signInToken) {
      auth?.signInWithCustomToken(auth.signInToken)
        .catch(() => {
          console.log('Token is not valid, received:', auth.signInToken);
        });
    }
  }, [auth]);

  // A way to detect that we are definitely NOT signed into the app and not going to be signed in any time soon:

  useEffect(() => {
    if (mobile.isApp && nullToken && authUserUIDSet && authUserUID === null) {
      // Pass to the app the signal that we are logged out and to restart auth:
      auth?.logoutApp();
    }
  }, [nullToken, authUserUIDSet, authUserUID, mobile, auth]);

  // EXTENSION
  useEffect(() => {
    if (authUserUIDSet && authUserUID === null) {
      // Pass to the extension the signal that we are logged out and to restart auth:
      auth.logoutExtension();
    }
    // If we have been set as logged in then get the extension to check that we are logged in as the correct user:
    if (authUserUID && authUserUIDSet) {
      auth.tellExtensionWeAreLoggedIn();
    }
  }, [authUserUIDSet, authUserUID, auth]);
};
