import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, stackClassNames } from '@moonsifttech/design-system';

import MEDIA from 'src/mvp22/media';
import { ShopWindowProps } from './ShopWindow.types';

const shopWindowDefaultImages = [
  [MEDIA.LANDING_IMG_CANDLES, MEDIA.LANDING_IMG_CANDLES_SMALL],
  [MEDIA.LANDING_IMG_HANDBAG, MEDIA.LANDING_IMG_HANDBAG_SMALL],
  [MEDIA.LANDING_IMG_EARRINGS, MEDIA.LANDING_IMG_EARRINGS_SMALL],
  [MEDIA.LANDING_IMG_PILLOW, MEDIA.LANDING_IMG_PILLOW_SMALL],
  [MEDIA.LANDING_IMG_CUPS, MEDIA.LANDING_IMG_CUPS_SMALL],
  [MEDIA.LANDING_IMG_JUMPER, MEDIA.LANDING_IMG_JUMPER_SMALL],
  [MEDIA.LANDING_IMG_HEADPHONES, MEDIA.LANDING_IMG_HEADPHONES_SMALL],
  [MEDIA.LANDING_IMG_SMARTWATCH, MEDIA.LANDING_IMG_SMARTWATCH_SMALL],
  [MEDIA.LANDING_IMG_COFFEE_MAKER, MEDIA.LANDING_IMG_COFFEE_MAKER_SMALL],
  ['', MEDIA.LANDING_IMG_SNAKE_CANDLES_SMALL],
];

const UnstyledShopWindow: VFC<ShopWindowProps> = ({
  className,
  style,
  images = shopWindowDefaultImages,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={30}
    >
      <Stack spacing={40}>
        <img alt="" src={images[0][0]} />
        <img alt="" src={images[1][0]} />
      </Stack>
      <Stack className="ShopWindow-alignTopUpToL" spacing={40}>
        <picture>
          <source media="(max-width: 620px)" srcSet={images[2][1]} />
          <img alt="" src={images[2][0]} />
        </picture>
        <img alt="" className="ShopWindow-hideFromL" src={images[1][1]} />
      </Stack>
      <Stack className="ShopWindow-alignBottom" spacing={40}>
        <picture>
          <source media="(max-width: 620px)" srcSet={images[3][1]} />
          <img alt="" src={images[3][0]} />
        </picture>
        <img alt="" className="ShopWindow-hideFromL" src={images[0][1]} />
      </Stack>
      <Stack
        className="ShopWindow-alignBottom ShopWindow-alignCenterUpToL"
        spacing={40}
      >
        <picture>
          <source media="(max-width: 620px)" srcSet={images[4][1]} />
          <img alt="" src={images[4][0]} />
        </picture>
        <img alt="" className="ShopWindow-hideFromL" src={images[9][1]} />
      </Stack>
      <Stack className="ShopWindow-alignBottom" spacing={40}>
        <picture>
          <source media="(max-width: 620px)" srcSet={images[5][1]} />
          <img src={images[5][0]} alt="" />
        </picture>
        <img className="ShopWindow-hideFromL" alt="" src={images[8][1]} />
      </Stack>
      <Stack className="ShopWindow-alignTopUpToL" spacing={40}>
        <picture>
          <source media="(max-width: 620px)" srcSet={images[6][1]} />
          <img src={images[6][0]} alt="" />
        </picture>
        <img className="ShopWindow-hideFromL" src={images[7][1]} alt="" />
      </Stack>
      <Stack spacing={40}>
        <img src={images[7][0]} alt="" />
        <img src={images[8][0]} alt="" />
      </Stack>
    </Stack>
  );
};

export const ShopWindow = styled(UnstyledShopWindow)`
  height: 492px;

  .ShopWindow-alignBottom {
    align-self: flex-end;
  }

  @media (min-width: 620px) {
    img.ShopWindow-hideFromL {
      display: none;
    }
  }

  @media (max-width: 620px) {
    height: 366px;

    > :not(style) + :not(style) {
      margin: 0 0 0 20px;
    }

    .${stackClassNames.root} > :not(style) + :not(style) {
      margin: 20px 0 0 0;
    }

    img {
      width: 108px;
    }

    .ShopWindow-alignTopUpToL {
      align-self: flex-start;
    }

    .ShopWindow-alignCenterUpToL {
      align-self: center;
    }
  }

  @media (max-width: 500px) {
    height: 236px;

    > :not(style) + :not(style) {
      margin: 0 0 0 12px;
    }

    ${stackClassNames.root} >:not(style) +:not(style) {
      margin: 12px 0 0 0;
    }

    img {
      width: 70px;
    }
  }
`;
