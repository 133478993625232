import React, { useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { useAuth } from 'src/hooks/useAuth';
import { useAuthUserData } from 'src/hooks/useAuthUserData';
import { useReactWindowSize } from 'src/hooks/useReactWindowSize';
import { useScrollToTopOnLocationChange } from 'src/hooks/useScrollToTopOnLocationChange';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { userVerifiedSettingsParser } from 'src/store/parsers/userVerifiedSettings';
import { Action, ActionNames, ModalTypes } from 'src/types';
import { AppContainerProps } from './App.types';
import { App } from './App.component';

// Check whether the incoming data has an action form
const isValidAction = (data: any): boolean => {
  try {
    return 'type' in JSON.parse(data);
  } catch {
    return false;
  }
};

const Container: React.FC<AppContainerProps> = () => {
  const { mobile, db } = useContext(ServicesContext);
  const windowWidth = useSelector<RootState, number>(
    (state) => state.ui.windowWidth,
  );
  const modalType = useSelector<RootState, ModalTypes | null>(
    (state) => state.modal.type,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  useReactWindowSize();
  useScrollToTopOnLocationChange();
  useAuth();
  useAuthUserData();
  // do not allow scrolling if modal is open:
  const isScrollable = modalType ? false : true;
  // Remove all redux data if unmounting the app:
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch<Action>({
        type: ActionNames.UNSET_ALL,
        payload: undefined,
      });
    };
  }, [dispatch]);

  // Dispatch actions coming from the native app
  const { isApp } = mobile;
  useEffect(() => {
    if (isApp) {
      // Actions handler
      const onMessage = (event: MessageEvent<any>) => {
        // Only attend Redux actions
        if (!isValidAction(event.data)) return;

        const action = JSON.parse(event.data);
        dispatch(action);
      };

      // Listen to actions coming from the native app
      window.addEventListener('message', onMessage);

      return () => {
        window.removeEventListener('message', onMessage);
      };
    }
  }, [dispatch, isApp]);

  // Listen to the user's verified settings document
  useDBRetrieveDoc<'user_verified_settings'>(
    db,
    db.userVerifiedSettingsDoc,
    'user_verified_settings',
    userVerifiedSettingsParser,
    undefined,
    [authUserUID],
    'listen',
  );

  return (
    <App isScrollable={isScrollable} windowWidth={windowWidth} isApp={isApp} />
  );
};

export { Container as App };
