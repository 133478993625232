/*

If not signed in show this at the bottom of collections.

todo: functional, typescript

*/
import React from 'react';
import styled from 'styled-components';
import { M } from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import { Stack, CtaButton, Typography } from '@moonsifttech/design-system';
import { CollectionBottomPromptProps } from './CollectionBottomPrompt.types';

const Container = styled.div`
  margin-top: 36px;
  background-color: ${M.COL.BG.LIGHT};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 407px;
  padding-bottom: 16px;
  margin-bottom: 0px;
`;

const AboveRetailersContainer = styled.div`
  padding: 20px;
`;

const RetailerLogosContainer = styled.div`
  width: 100%;
  margin-top: 36px;
`;

const RetailerLogos = styled.div`
  height: 70px;
  background-image: url(${MEDIA.RETAILER_LOGOS});
  background-repeat: no-repeat;
  background-position: center;
`;

const StyledCtaButton = styled(CtaButton)`
  margin: 8px 0px;
`;

const MainText = styled(Typography)`
  margin: 8px 0px 16px 0px;
`;

const MainPromptStack = styled(Stack)`
  border-radius: 10px;
  border: 1px solid var(--grey-4);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 42px 20px;
  background-color: var(--common-white);

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 42px 42px;
  }
`;

export const CollectionBottomPrompt: React.FC<CollectionBottomPromptProps> = ({
  onMakeGiftRegistryClick,
  onMakeWishlistClick,
  onImAContentCreatorClick,
}) => {
  return (
    <Container>
      <AboveRetailersContainer>
        <MainPromptStack
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="editorial.r26" align="center">
            Create your own
          </Typography>
          <MainText variant="primary.l16" align="center">
            Add items from any store in the world. Share with a single link.
          </MainText>
          <StyledCtaButton
            variant="primary-black"
            fullWidth
            onClick={onMakeGiftRegistryClick}
          >
            Make a gift registry
          </StyledCtaButton>
          <StyledCtaButton
            variant="primary-black"
            fullWidth
            onClick={onMakeWishlistClick}
          >
            Make a wishlist
          </StyledCtaButton>
          <StyledCtaButton
            variant="primary-white"
            fullWidth
            onClick={onImAContentCreatorClick}
          >
            I'm a Content Creator
          </StyledCtaButton>
        </MainPromptStack>
      </AboveRetailersContainer>
      <RetailerLogosContainer>
        <RetailerLogos />
      </RetailerLogosContainer>
    </Container>
  );
};
