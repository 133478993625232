import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  TextField,
  textFieldClassNames,
  StandardButton,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { ReferProContentProps } from './ReferProContent.types';

const UnstyledReferProContent: VFC<ReferProContentProps> = ({
  className,
  style,
  proCode,
  copied,
  onCopy,
}) => {
  return (
    <div className={className} style={style}>
      <Typography
        className="ReferProContent-subheading"
        variant="primary.b18"
        component="p"
      >
        Help your friends discover the magic of Moonsift too!
      </Typography>
      <Typography
        className="ReferProContent-body"
        variant="primary.r16"
        component="p"
      >
        Invite friends to Moonsift using the link below. If they sign up, you'll
        both unlock <Link to={R.PLANS}>Moonsift Pro</Link>.
      </Typography>
      <Typography
        className="ReferProContent-inputHeading"
        variant="primary.m16"
        component="p"
      >
        Your unique referral link
      </Typography>
      <form className="ReferProContent-form" onSubmit={onCopy}>
        <TextField
          name="url"
          value={`${window.location.origin}${R.PRO_LANDING}/${proCode}`}
        />
        <StandardButton type="submit" variant="primary-black" disabled={copied}>
          {copied ? 'Copied' : 'Copy'}
        </StandardButton>
      </form>
      <Typography variant="primary.r14" color="grey.3">
        Copy and share your link with friends
      </Typography>
    </div>
  );
};

export const ReferProContent = styled(UnstyledReferProContent)`
  .ReferProContent-subheading,
  .ReferProContent-inputHeading {
    margin-bottom: 8px;
  }

  .ReferProContent-body {
    margin-bottom: 24px;
  }

  .ReferProContent-form {
    display: flex;
    margin-bottom: 8px;

    .${textFieldClassNames.root} {
      flex-grow: 1;
      margin-right: 4px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .ReferProContent-heading {
      ${({ theme }) => theme.fns.getTypographyStyles('branded.b42')}
    }

    .ReferProContent-subheading {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.b26')}
    }
  }
`;
