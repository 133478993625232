/*

Explore "magazine" style page to showcase stylists.

TODO: typescript, camelCase, make functional, non-default exports, design system...
Extract list of collections useing a dbHook (get,single doc)
Extract collection from db using a dbHook. (get, doc map)
collection tiles (CollectionDisplays here) should load images in themselves.
*/
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import get_collection_images from 'src/mvp22/firebase-functions/get_collection_images';
import get_explore_collections from 'src/mvp22/firebase-functions/get_explore_collections';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import { withFirebase } from 'src/mvp22/Firebase';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import windowSize from 'src/mvp22/WindowSize';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import remove_all_listeners from 'src/mvp22/core-components/remove_all_listeners';
import {
  LargeCollectionDisplay,
  LargeCollectionDisplay2,
  SmallCollectionDisplay1,
  SmallCollectionDisplay2,
  CuratedByYou,
} from 'src/mvp22/tile-components/CollectionDisplays';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import { modalSet } from 'src/mvp22/redux-components/actions';

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  padding-top: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${M.COL.TEXT.BLACK};
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 144px;
`;

const WithinContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`;

const CollectionTitle = styled.div`
  flex-direction: column;
  max-width: 441px;
  margin-left: 29px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`;

const LatestFromTheCommunity = styled(CollectionTitle)`
  margin-left: 0px;
`;

const Section = styled.section`
  background-color: ${(props) =>
    props.isGrey === true ? M.COL.BG.GREY : M.COL.BG.WHITE};
  display: flex;
  width: 100%;
  max-width: ${(props) => (props.isGrey === true ? '' : '1270px')};
  justify-content: center;
`;

const Section2 = styled(Section)`
  align-items: center;
  text-align: left;
  flex-direction: column;
`;

const Width = styled.div`
  width: 1150px;
  display: flex;
  justify-content: ${(props) =>
    props.display_type === 1 ? 'space-evenly' : 'space-between'};
  justify-content: ${(props) => (props.display_type === 2 ? 'center' : '')};
`;

const Width2 = styled(Width)`
  max-width: 755px;
  justify-content: center;
`;

const Subsection = styled(Width)`
  max-width: 755px;
  justify-content: space-between;
`;

const Spacer = styled.div`
  display: flex;
  width: ${(props) => props.width};
`;

const Width3 = styled(Width)`
  max-width: 360px;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 414px) {
    justify-content: center;
  } ;
`;

const Seperator = styled.div`
  background-color: ${(props) => props.color};
  height: ${(props) => props.height};
`;

class Explore extends Component {
  constructor() {
    super();
    this.state = {
      is_loaded: false,
      merged_collection_list: [],
    };
    this.collection_state_assign_func =
      this.collection_state_assign_func.bind(this);
    this.openNewCollection = this.openNewCollection.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateTheDataOnce();
  }

  componentDidUpdate() {
    this.updateTheDataOnce();
  }

  componentWillUnmount() {
    this._isMounted = false;
    remove_all_listeners(this.state.collections);
  }

  collection_state_assign_func(new_dict) {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          collections: nested_state_assignment(prevState.collections, new_dict),
        };
      });
  }

  updateTheDataOnce() {
    if (this.props.firebaseState && this.props.firebaseState.loaded) {
      this.setState((prevState, props) => {
        // makes this only run once? they are equal after the first time you load the page so updateTheDataOnce doesn't end up populating new collections
        if (
          prevState.auth_user_uid !== props.authUserUID ||
          prevState.current_explore_type !== props.explore_type
        ) {
          // get the collection info JUST ONCE, hence NOT bothering with redux for now:
          get_explore_collections(
            'explore_featured',
            props.firebase,
            props.authUserUID,
          ).then((response) => {
            // split by big and small tiles:
            const like_listeners_dict = get_collection_likes(
              response.collections,
              this.props.firebase,
              this.props.authUserUID,
              this.collection_state_assign_func,
            ); // WILL ALSO CALL AN ASYNC FUNCTION WHEN LIKES DETERMINED!
            // these are async so we have SOME of the information from the collection, i.e. the name and username. then when the pictures finally load, the fill in on the page
            var collections_with_listeners = nested_state_assignment(
              response.collections,
              like_listeners_dict,
            );
            const image_getters = get_collection_images(
              response.collections,
              this.props.firebase,
              this.collection_state_assign_func,
            );
            collections_with_listeners = nested_state_assignment(
              collections_with_listeners,
              image_getters,
            );
            const collections_sorted = response.collections_sorted;
            this._isMounted &&
              this.setState((prevState) => {
                return {
                  collections_sorted,
                  is_loaded: true,
                  collections: nested_state_assignment(
                    prevState.collections,
                    collections_with_listeners,
                  ),
                  public_info: response.public_info,
                };
              });
          });
          return {
            auth_user_uid: this.props.authUserUID,
            current_explore_type: this.props.explore_type,
            is_loaded: false,
          };
        }
      });
    }
  }

  openNewCollection(event) {
    event.preventDefault();
    this.props.setModal({
      type: 'new_collection',
    });
    return false;
  }

  get_inside(DISPLAY_TYPE, WINDOW_WIDTH, COLLECTIONS) {
    if (this.state.is_loaded !== true) {
      return <LoadingDisplay message="Loading Collections..." />;
    } else {
      return this.renderCollections(DISPLAY_TYPE, WINDOW_WIDTH, COLLECTIONS);
    }
  }

  preRenderCollections(DISPLAY_TYPE, PAGE_ORDER_TYPES) {
    let background_colour_counter = 0;
    const firebase = this.props.firebase;
    return (
      this.state.is_loaded &&
      this.state.collections_sorted.map((uid, index) => {
        // if there are too many collections to display, display_type for those extra collections will be set to undefined
        let display_type = PAGE_ORDER_TYPES[DISPLAY_TYPE][index];
        var CollectionDisplayToRender = null;
        switch (display_type) {
          case 'large1':
            CollectionDisplayToRender = LargeCollectionDisplay;
            break;
          case 'large2':
            CollectionDisplayToRender = LargeCollectionDisplay2;
            break;
          case 'small1':
            CollectionDisplayToRender = SmallCollectionDisplay1;
            break;
          case 'small2':
            background_colour_counter++;
            CollectionDisplayToRender = SmallCollectionDisplay2;
            break;
          default:
            CollectionDisplayToRender = null;
        }
        return (
          <CollectionDisplayToRender
            firebase={firebase}
            public_info={
              this.state.public_info[
                this.state.collections[this.state.collections_sorted[index]]
                  .collection_entry.user_uid
              ]
            }
            key={this.state.collections_sorted[index]}
            data={this.state.collections[this.state.collections_sorted[index]]}
            auth_user_uid={this.state.auth_user_uid}
            display_type={DISPLAY_TYPE}
            background_colour={
              background_colour_counter % 3 === 1
                ? M.COL.BG.DARKGREEN
                : background_colour_counter % 3 === 2
                ? M.COL.BG.DARKBLUE
                : M.COL.BG.DARKPURPLE
            }
          />
        );
      })
    );
  }

  renderCollections(DISPLAY_TYPE, WINDOW_WIDTH, COLLECTIONS) {
    if (DISPLAY_TYPE === 0) {
      return (
        <WithinContainer>
          <Seperator height="30px" />
          <Section isGrey={false}>{COLLECTIONS[0]}</Section>
          <Seperator height="55px" />
          <Section2 isGrey={true} className="Section2">
            <Seperator height="68px" />
            <Width>
              <LatestFromTheCommunity>
                <TEXTSTYLE.JUANA.HEADING4>
                  Latest from the community
                </TEXTSTYLE.JUANA.HEADING4>
              </LatestFromTheCommunity>
            </Width>
            <Width className="Width">
              {COLLECTIONS[1]}
              {COLLECTIONS[2]}
              {COLLECTIONS[3]}
            </Width>
            <Seperator height="85px" />
          </Section2>
          <Seperator height="36px" />
          <Section isGrey={false}>{COLLECTIONS[4]}</Section>
          <Seperator height="55px" />
          <Section isGrey={true}>
            <Seperator height="50px" />
          </Section>
          <Section isGrey={true}>
            <Width>
              {COLLECTIONS[5]}
              {COLLECTIONS[6]}
              {COLLECTIONS[7]}
            </Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="28px" />
          </Section>
          <Section isGrey={true}>
            <Width>
              {COLLECTIONS[8]}
              {COLLECTIONS[9]}
              {COLLECTIONS[10]}
            </Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="28px" />
          </Section>
          <Section isGrey={true}>
            <Width>
              {COLLECTIONS[11]}
              {COLLECTIONS[12]}
              {COLLECTIONS[13]}
            </Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="28px" />
          </Section>
          <Section isGrey={true}>
            <Width>
              {COLLECTIONS[14]}
              {COLLECTIONS[15]}
              <CuratedByYou
                auth_user_uid={this.state.auth_user_uid}
                openNewCollection={this.openNewCollection}
              />
            </Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="128px" />
          </Section>
        </WithinContainer>
      );
    } else if (DISPLAY_TYPE === 1) {
      return (
        <WithinContainer>
          <Section isGrey={false}>{COLLECTIONS[0]}</Section>
          <Section2 isGrey={true}>
            <Seperator height="68px" />
            <Subsection>
              <LatestFromTheCommunity>
                <TEXTSTYLE.JUANA.HEADING4>
                  Latest from the community
                </TEXTSTYLE.JUANA.HEADING4>
              </LatestFromTheCommunity>
            </Subsection>
            <Width2>
              {COLLECTIONS[1]}
              <Spacer
                width={COLLECTIONS[1] && COLLECTIONS[2] ? '35px' : '0px'}
              />
              {COLLECTIONS[2]}
            </Width2>
          </Section2>
          <Section isGrey={true}>
            <Seperator height="28px" />
          </Section>
          <Section isGrey={true}>
            <Seperator height="58px" />
          </Section>
          <Seperator height="30px" />
          <Section isGrey={false}>{COLLECTIONS[3]}</Section>
          <Section isGrey={true}>
            <Seperator height="40px" />
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[4]}
              <Spacer
                width={COLLECTIONS[4] && COLLECTIONS[5] ? '35px' : '0px'}
              />
              {COLLECTIONS[5]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[6]}
              <Spacer
                width={COLLECTIONS[6] && COLLECTIONS[7] ? '35px' : '0px'}
              />
              {COLLECTIONS[7]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[8]}
              <Spacer width={COLLECTIONS[8] ? '35px' : '0px'} />
              <CuratedByYou
                auth_user_uid={this.state.auth_user_uid}
                openNewCollection={this.openNewCollection}
              />
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[9]}
              <Spacer
                width={COLLECTIONS[9] && COLLECTIONS[10] ? '35px' : '0px'}
              />
              {COLLECTIONS[10]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[11]}
              <Spacer
                width={COLLECTIONS[11] && COLLECTIONS[12] ? '35px' : '0px'}
              />
              {COLLECTIONS[12]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[13]}
              <Spacer
                width={COLLECTIONS[13] && COLLECTIONS[14] ? '35px' : '0px'}
              />
              {COLLECTIONS[14]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Width2>
              {COLLECTIONS[15]}
              <Spacer
                width={COLLECTIONS[15] && COLLECTIONS[16] ? '35px' : '0px'}
              />
              {COLLECTIONS[16]}
            </Width2>
          </Section>
          <Section isGrey={true}>
            <Seperator height="128px" />
          </Section>
        </WithinContainer>
      );
    } else if (DISPLAY_TYPE === 2) {
      return (
        <WithinContainer>
          <Section isGrey={false}>{COLLECTIONS[0]}</Section>
          <Section2 isGrey={true}>
            <Seperator height="40px" />
            <Width3 display_type={DISPLAY_TYPE}>
              <LatestFromTheCommunity>
                <TEXTSTYLE.JUANA.HEADING4>
                  Latest from the community
                </TEXTSTYLE.JUANA.HEADING4>
              </LatestFromTheCommunity>
            </Width3>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[1]}</Width>
          </Section2>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[2]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[3]}</Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="40px" />
          </Section>
          <Section isGrey={false}>{COLLECTIONS[4]}</Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[5]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[6]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[7]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>
              <CuratedByYou
                display_type={DISPLAY_TYPE}
                auth_user_uid={this.state.auth_user_uid}
                openNewCollection={this.openNewCollection}
              />
            </Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[8]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[9]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[10]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[11]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[12]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[13]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[14]}</Width>
          </Section>
          <Section isGrey={true}>
            <Width display_type={DISPLAY_TYPE}>{COLLECTIONS[15]}</Width>
          </Section>
          <Section isGrey={true}>
            <Seperator height="128px" />
          </Section>
        </WithinContainer>
      );
    } else {
      return '';
    }
  }

  render() {
    const WINDOW_WIDTH = this.props.windowWidth;
    const PAGE_ORDER_TYPES = [
      // page order 1
      [
        'large1',
        'small1',
        'small2',
        'small1',
        'large1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small1',
      ],
      // page order 2
      [
        'large2',
        'small1',
        'small2',
        'large2',
        'small2',
        'small1',
        'small2',
        'small1',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
      ],
      // page order 3
      [
        'large2',
        'small1',
        'small2',
        'small1',
        'large2',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
        'small1',
        'small2',
      ],
    ];
    const DISPLAY_TYPE =
      this.props.windowWidth >= 1200
        ? 0
        : this.props.windowWidth >= 768
        ? 1
        : 2; // sets the page order to use (depends on window size)
    let COLLECTIONS = this.preRenderCollections(DISPLAY_TYPE, PAGE_ORDER_TYPES); // removes any falsy values from collections array

    return (
      <FullBlank className="fullBlank">
        <TopMenu hug="edge" selected="EXPLORE" />
        {AddHelmetTitle('Explore community collections')}
        <Container className="container">
          {this.get_inside(DISPLAY_TYPE, WINDOW_WIDTH, COLLECTIONS)}
          {this.state.is_loaded ? <BottomBar /> : null}
        </Container>
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseState: state.firebasestate,
    authUserUID: state.auth.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: (data) => dispatch(modalSet(data)),
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withFirebase(windowSize(Explore))),
);
