import React, { useContext, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import R from 'src/routes';
import { authRedirectSearchParam } from 'src/config';

export const useBookmarkCollection = () => {
  const { cloud } = useContext(ServicesContext);
  const history = useHistory();
  const { pathname, search, hash } = history.location;

  // Global state
  const authUserUID = useSelector<RootState, string>(
    ({ auth }) => auth.id ?? '',
  );
  const collectionUserUID = useSelector<RootState, string>(
    ({ db }) => db.single_collection.data?.user_uid ?? '',
  );
  const collectionUID = useSelector<RootState, string>(
    ({ db }) => db.single_collection.data?.id ?? '',
  );

  const isBookmarked = useSelector<RootState, boolean | undefined>(({ db }) =>
    db.single_collection_bookmark.data === undefined
      ? undefined
      : Boolean(db.single_collection_bookmark.data),
  );

  // Local state
  const [working, setWorking] = useState(false);

  const handleBookmark = useCallback<
    NonNullable<React.MouseEventHandler<HTMLElement>>
  >(
    (event) => {
      event.preventDefault();

      if (authUserUID && isBookmarked !== undefined) {
        setWorking(true);

        cloud
          .fastAPI({
            api: 'likes_saved_likes_change',
            user_uid: collectionUserUID,
            collection_uid: collectionUID,
            action: isBookmarked ? 'delete' : 'create',
          })
          .then(() => setWorking(false))
          .catch((error) => {
            console.error("Couldn't update the bookmark", error);
            setWorking(false);
          });
      } else {
        // Send the user to the sign in page if it's not logged in
        const redirectSearchParams = new URLSearchParams();
        redirectSearchParams.set(
          authRedirectSearchParam,
          `${pathname}${search}${hash}`,
        );
        history.push({
          pathname: R.SIGNIN,
          search: redirectSearchParams.toString(),
        });
      }
    },
    [
      authUserUID,
      cloud,
      collectionUID,
      collectionUserUID,
      hash,
      history,
      isBookmarked,
      pathname,
      search,
    ],
  );

  return { isBookmarked, working, handleBookmark };
};
