import { VFC } from 'react';
import styled from 'styled-components';
import { Typography, StandardButton } from '@moonsifttech/design-system';

import { CreatorApplicationFinalProps } from './CreatorApplicationFinal.types';

const UnstyledCreatorApplicationFinal: VFC<CreatorApplicationFinalProps> = ({
  className,
  style,
  onButtonClick,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationFinal-content">
        <Typography
          className="CreatorApplicationFinal-firstHeading"
          variant="editorial.sb34"
          component="h1"
        >
          Let’s get you ready to start adding products!
        </Typography>
        <Typography
          className="CreatorApplicationFinal-paragraph"
          variant="primary.l20"
          component="p"
        >
          Bring all your recommendations together in one place. Keep 100% of
          your commissions.
        </Typography>
        <StandardButton onClick={onButtonClick} variant="primary-black">
          Let's do it
        </StandardButton>
      </div>
    </div>
  );
};

export const CreatorApplicationFinal = styled(UnstyledCreatorApplicationFinal)`
  padding: 100px 0 0;

  .CreatorApplicationFinal-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationFinal-firstHeading {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.fns.getColor('background.purple')};
  }

  .CreatorApplicationFinal-secondHeading {
    margin-bottom: 24px;
  }

  .CreatorApplicationFinal-paragraph {
    margin-bottom: 36px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
