import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Stack, StandardButton, DeleteIcon } from '@moonsifttech/design-system';

import { ModalContent } from 'src/components/core/ModalContent';
import { EditModalContentProps } from './EditModalContent.types';
import { EditModalContentContent } from './EditModalContentContent.component';

export const UnstyledEditModalContent: React.FC<EditModalContentProps> = ({
  className,
  style,
  name,
  description,
  working,
  showConfirmation,
  deleting,
  selectedCollectionType,
  isRegistryNext,
  onCollectionTypeChange,
  onEdit,
  onDelete,
  onClose,
  toggleConfirmation,
  toggleIsRegistry,
}) => {
  return (
    <ModalContent
      className={clsx(
        className,
        showConfirmation && 'EditModalContent-confirmation',
      )}
      style={style}
      onClose={onClose}
    >
      <form onSubmit={onEdit}>
        <Stack
          className="EditModalContent-innerContainer"
          direction="column"
          justifyContent="space-between"
        >
          <EditModalContentContent
            showConfirmation={showConfirmation}
            deleting={deleting}
            name={name}
            description={description}
            selectedCollectionType={selectedCollectionType}
            isRegistryNext={isRegistryNext}
            toggleIsRegistry={toggleIsRegistry}
            onCollectionTypeChange={onCollectionTypeChange}
            onDelete={onDelete}
          />
          {showConfirmation ? (
            <Stack direction="row" justifyContent="flex-end">
              <StandardButton
                variant="primary-white"
                onClick={toggleConfirmation}
              >
                Cancel
              </StandardButton>
            </Stack>
          ) : (
            <Stack direction="row" justifyContent="space-between">
              <StandardButton
                variant="primary-white"
                onClick={toggleConfirmation}
                startIcon={<DeleteIcon />}
              >
                Delete
              </StandardButton>
              <StandardButton
                type="submit"
                variant="primary-black"
                loading={working}
              >
                Save
              </StandardButton>
            </Stack>
          )}
        </Stack>
      </form>
    </ModalContent>
  );
};

export const EditModalContent = styled(UnstyledEditModalContent)`
  .ModalContent-innerContainer,
  .EditModalContent-innerContainer,
  form {
    height: 100%;
  }

  form {
    margin-bottom: 0;
  }
`;
