import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  Stack,
  CtaButton,
  Link,
  LoadingDots,
  Alert,
} from '@moonsifttech/design-system';

import { ModalContent } from 'src/components/core/ModalContent';
import { VideoGenerationPanelProps } from './VideoGenerationPanel.types';
import { EXTERNAL } from 'src/routes';

const UnstyledVideoGenerationPanel: React.FC<VideoGenerationPanelProps> = ({
  className,
  style,
  onClose,
  videoHasFailed,
  collectionName,
  mp4Name,
  videoHasBeenGenerated,
}) => {
  return (
    <ModalContent className={className} style={style} onClose={onClose}>
      <Stack direction="column">
        <Typography variant="primary.b34">{collectionName}</Typography>
        <Stack
          direction="column"
          className="VideoGenerationPanel-innerContainer"
          spacing={12}
        >
          {videoHasBeenGenerated === true && (
            <>
              <Typography variant="primary.r20">
                Your video is ready!
              </Typography>
              <Link
                to={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_PROJECTID}-user-videos/${mp4Name}`}
                target="_blank"
              >
                <CtaButton variant={'primary-black'} fullWidth target="_blank">
                  Download
                </CtaButton>
              </Link>
              <Typography variant="primary.b16">
                How to upload to Instagram
              </Typography>
              <Typography variant="primary.r14">
                <Link target="_blank" to={EXTERNAL.VIDEO_EXPLANATION}>
                  Click here
                </Link>{' '}
                for quick steps on how to upload your video to Instagram
              </Typography>
            </>
          )}
          {videoHasFailed === true && (
            <>
              <Alert severity="error">
                Sorry, video generation failed. Please go back and try again and
                contact us if this persists.
              </Alert>
            </>
          )}
          {videoHasBeenGenerated === false && videoHasFailed === false && (
            <>
              <Typography variant="primary.r20">
                CREATING YOUR VIDEO. THIS MAY TAKE UP TO A MINUTE...
              </Typography>
              <LoadingDots variant="dark" />

              <Typography variant="primary.r14">
                Moonsift allows you to generate videos of your collections for
                you to share
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
    </ModalContent>
  );
};

export const VideoGenerationPanel = styled(UnstyledVideoGenerationPanel)`
  p {
    margin-bottom: 10px;
  }
  .VideoGenerationPanel-innerContainer {
    padding-top: 24px;
  }
`;
