/*
  Button controlling the entry into the "Mark as bought" user flow.
*/

import {
  StandardButton,
  CheckCircleIcon,
} from '@moonsifttech/design-system';
import { useItem } from 'src/hooks/useItem';
import { useMine } from 'src/hooks/useMine';
import { useModal, ModalTypes } from 'src/hooks/useModal';

export const MarkAsBoughtButton = ({
  className,
  style,
  collectionId,
  collectionUserId,
  itemId,
  variant,
}: {
  className?: string;
  style?: React.CSSProperties;
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  variant: 'primary-white' | 'secondary';
}) => {

  const {
    boughtByUser,
    exhausted,
  } = useItem(itemId);
  const { isMine } = useMine(collectionUserId);

  const [ setModal ] = useModal();

  if (exhausted && !boughtByUser) {
    return (
      <StandardButton
        className={className}
        style={style}
        disabled
      >
        Already bought
      </StandardButton>
    );
  }

  if (boughtByUser && !isMine) {
    return (
      <StandardButton
        className={className}
        style={style}
        variant="primary-black"
        startIcon={<CheckCircleIcon />}
        onClick={() => setModal({
          type: ModalTypes.MarkedAsBought,
          collectionId,
          collectionUserId,
          itemId,
        })}
      >
        Bought
      </StandardButton>
    );
  }

  return (
    <StandardButton
      className={className}
      style={style}
      variant={variant}
      onClick={() => setModal({
        type: ModalTypes.MarkAsBought,
        collectionId,
        collectionUserId,
        itemId,
      })}
    >
      Mark as bought
    </StandardButton>
  );
};
