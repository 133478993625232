import { VFC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/index';
import { EmptyMessageOwnCollectionContainerProps } from './EmptyMessageOwnCollection.types';
import { EmptyMessageOwnCollection } from './EmptyMessageOwnCollection.component';
import { Status } from 'src/types';

const EmptyMessageOwnCollectionContainer: VFC<
  EmptyMessageOwnCollectionContainerProps
> = ({ ...otherProps }) => {
  const creatorStatus = useSelector<RootState, Status | undefined>(
    (state) => state.db.user_verified_settings.data?.creator.status,
  );
  const isCreator = creatorStatus === 'accepted' || creatorStatus === 'applied';

  return <EmptyMessageOwnCollection {...otherProps} isCreator={isCreator} />;
};

export { EmptyMessageOwnCollectionContainer as EmptyMessageOwnCollection };
