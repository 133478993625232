import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { AppQrProps } from './AppQr.types';

const UnstyledAppQr: VFC<AppQrProps> = ({ className, style }) => {
  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack
          className="AppQr-qrCodeContainer"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={`${process.env.REACT_APP_STATIC_URL}/app-qr/app-qr.svg`}
            alt="Moonsift app QR code"
          />
        </Stack>
        <Typography variant="primary.b26" align="center" component="h1">
          Scan to get the iOS app
        </Typography>
        <Typography variant="primary.l20" align="center" component="p">
          Scan this code with your camera to start saving to Moonsift on your
          iPhone or iPad
        </Typography>
      </Stack>
    </Page>
  );
};

export const AppQr = styled(UnstyledAppQr)`
  .AppQr-qrCodeContainer {
    width: 166px;
    height: 166px;
    border: 1px solid var(--background-purple);
    border-radius: 50%;
    margin-bottom: 36px;

    img {
      width: 100px;
    }
  }

  h1 {
    margin-bottom: 8px;
  }
`;
