import { VFC, useCallback, useContext } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { CollectionCarouselContainerProps } from './CollectionCarousel';
import { CollectionCarousel } from './CollectionCarousel.component';
import { useCarousel } from 'src/hooks/useCarousel/useCarousel';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { ServicesContext } from 'src/ServicesContext';
import { collectionListParser } from 'src/store/parsers/collectionList/collectionListParser';

const CAROUSEL_SCROLL_DISTANCE = 351;

const CollectionCarouselContainer: VFC<CollectionCarouselContainerProps> = (
  props,
) => {
  const { db } = useContext(ServicesContext);
  useDBRetrieveDoc<'collection_list'>(
    db,
    db.collectionsList,
    'collection_list',
    collectionListParser,
    undefined,
    [props.listName],
    'get',
  );

  // Get the list of collections:
  const collectionListContainer = useSelector(
    (root: RootState) => root.db.collection_list.data,
  );

  const collectionList = collectionListContainer
    ? collectionListContainer.list
    : [];

  // Amount to scroll depends on screen width:
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );
  const scrollMultiplier = isMobile ? 1 : 3;

  const {
    containerRef,
    showForwardButton,
    showBackButton,
    componentDidUpdate,
  } = useCarousel();

  const currentContainer = containerRef.current;
  const scrollContainer = useCallback(
    (scrollDirection: -1 | 1) => {
      currentContainer &&
        currentContainer.scrollBy({
          left: scrollDirection * scrollMultiplier * CAROUSEL_SCROLL_DISTANCE,
          behavior: 'smooth',
        });
      componentDidUpdate();
    },
    [currentContainer, scrollMultiplier, componentDidUpdate],
  );

  const scrollContainerLeft = useCallback(
    (event) => {
      scrollContainer(-1);
    },
    [scrollContainer],
  );

  const scrollContainerRight = useCallback(
    (event) => {
      scrollContainer(1);
    },
    [scrollContainer],
  );

  return (
    <CollectionCarousel
      {...{
        ...props,
        collectionList,
        scrollContainerLeft,
        scrollContainerRight,
        containerRef,
        showForwardButton,
        showBackButton,
        handleDataLoad: componentDidUpdate,
      }}
    />
  );
};

export { CollectionCarouselContainer as CollectionCarousel };
