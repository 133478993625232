/*

Modal that enables deleting of collection (Cannot be undone!)

TODO: camelcase, typescript, designsystem

*/
import {useState} from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { withFirebase } from 'src/mvp22/Firebase';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {useClickOutside} from 'src/hooks/useClickOutside';
import MEDIA from 'src/mvp22/media';
import {
  GeneralOverlayWithinContainer,
  LeftAlignedTitle,
  VerticalFlow,
  RightFlow,
  FlexGrowTop,
  CloseCross,
  Separator,
} from 'src/mvp22/modal-components/ModalComponents.js';
import Button from 'src/mvp22/form-components/Button';
import delete_collection from 'src/mvp22/firebase-functions/delete_collection';

const FlexGrowDelete = styled(FlexGrowTop)`
  justify-content:center;
  align-items:center;
  align-content:center;
`;

const BigBin = styled.img`
  height:100px;
`;

const DefaultComponent = (
  {
    firebase,
    history,
    // params:
    collection_name,
    collection_uid,
    // functions:
    closeFunction,
    backFunction
  }
)=>{
  const [done_action,setDoneAction] = useState(null);
  const containerRef = useClickOutside(closeFunction);

  const performAction = (event)=>{
    if (done_action!==false){
      setDoneAction(false);
      delete_collection(
        collection_uid,
        firebase
      ).then(
        ()=>{
          // TODO: maybe need to delete colelction info from redux:
          setDoneAction(true);
          history.push(R.HOME);
          closeFunction();
        }
      ).catch(
        ()=>setDoneAction(true)
      );
    }
    event.preventDefault();
    return false;
  };

  return(
    <GeneralOverlayWithinContainer ref={containerRef}>
      <CloseCross closeFunction={closeFunction}/>
      <VerticalFlow>
      <LeftAlignedTitle className="something">Edit or delete collection</LeftAlignedTitle>
        <Separator height="14px"/>
        <FlexGrowDelete>
        <BigBin src={MEDIA.THIN_BIN}/>
        <Separator height="14px"/>
        <TEXTSTYLE.OTHER3>The collection &ldquo;{collection_name}&rdquo; and all items within it will be deleted. You cannot undo this.</TEXTSTYLE.OTHER3>
        <Separator height="14px"/>
        <Button
          text="Delete"
          msstyle="square_popup"
          width="96px"
          onClick={performAction}
          dots={true}
          working={done_action===false}
          tabIndex={2006}
        />
        </FlexGrowDelete>
        <Separator height="26px"/>
        <RightFlow>
          <Button
            text="Cancel"
            msstyle="square_popup_grey"
            width={"96px"}
            onClick={backFunction}
            tabIndex={2005}
          />
        </RightFlow>
      </VerticalFlow>
    </GeneralOverlayWithinContainer>
  );
};

export default withRouter(withFirebase(DefaultComponent));
