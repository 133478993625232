import React from 'react';
import { BaseComponentProps } from 'src/types';
import styled, { useTheme } from 'styled-components';

const UnstyledBagHeart: React.FC<BaseComponentProps> = ({
  className,
  style,
}) => {
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  return (
    <picture className={className} style={style}>
      <source
        media={`(max-width: ${mobileBreakpoint}px)`}
        srcSet={`${process.env.REACT_APP_STATIC_URL}/reviews/bag_heart.png`}
      />
      <img
        alt="Review request"
        src={`${process.env.REACT_APP_STATIC_URL}/reviews/bag_heart.2x.png`}
      />
    </picture>
  );
};

export const BagHeart = styled(UnstyledBagHeart)`
  img {
    max-width: 250px;
    margin: 0px 0px 10px 0px;
    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
      margin: 40px 0px 20px 0px;
    }
  }
`;
