import { VFC } from 'react';
import clsx from 'clsx';
import styled, { keyframes } from 'styled-components';
import {
  ExtensionIcon,
  CtaButton,
  Typography,
  Stack,
  iconBaseClassNames,
} from '@moonsifttech/design-system';

import { SelfDrawingArrow } from 'src/components/onboarding/SelfDrawingArrow';
import { PostinstallOneProps } from './PostinstallOne.types';
import Loading from 'src/mvp22/view-components/Loading';

const PostinstallOne: VFC<PostinstallOneProps> = ({
  className,
  style,
  pinning,
  onPin,
  userDataLoaded,
}) => {
  return !userDataLoaded ? (
    <Loading />
  ) : (
    <Stack
      direction="row"
      className={clsx('PostinstallOne-container', className)}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        className="PostinstallOne-extensionCue"
        justifyContent="center"
        alignItems="center"
        spacing={8}
      >
        <SelfDrawingArrow />
        <div>
          <Typography variant="primary.r14" component="p">
            <ExtensionIcon className="PostinstallOne-extensionIcon" />
            <b>It's up here</b>.
            <br />
            Click the puzzle piece, then <br />
            the pin next to Moonsift.
          </Typography>
        </div>
      </Stack>
      <Stack className="PostinstallOne-innerContainer" direction="column">
        <Stack
          direction="row"
          className="PostinstallOne-titleContainer"
          justifyContent="center"
          alignItems="center"
        >
          <ExtensionIcon className="PostinstallOne-extensionIcon" />
          <Typography variant="primary.b42" component="h1">
            Pin Moonsift to your toolbar
          </Typography>
        </Stack>
        <div className="PostinstallOne-subtitleContainer">
          <Typography variant="primary.l26" component="h2">
            Once pinned, you can click it to save products on any website
          </Typography>
        </div>
        <div className="PostinstallOne-image">
          <img
            src={`${process.env.REACT_APP_STATIC_URL}/postinstall/onboarding1-alt.gif`}
            alt="Pin Moonsift"
          />
        </div>
        <Stack
          className="PostinstallOne-buttons"
          direction="row"
          justifyContent="center"
        >
          <CtaButton loading={pinning} onClick={onPin}>
            I&apos;ve pinned Moonsift
          </CtaButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

const slideInKeyframes = keyframes`
  0% {
    right: -300px;
    opacity: 0;
  }
  90% {
    right: -300px;
    opacity: 0;
  }
  100% {
    right: 28px;
    opacity: 1;
  }
`;

const StyledPostinstallOne = styled(PostinstallOne)`
  height: 100vh;

  .PostinstallOne-extensionCue {
    position: fixed;
    top: 20px;
    right: 28px;

    animation: ${slideInKeyframes} 5s linear;

    .${iconBaseClassNames.root}.PostinstallOne-extensionIcon {
      font-size: 16px;
      color: #5f6368;
      margin-right: 6px;
    }
  }

  .PostinstallOne-titleContainer {
    margin-bottom: 10px;

    .${iconBaseClassNames.root}.PostinstallOne-extensionIcon {
      font-size: 34px;
      color: #5f6368;
      margin-right: 8px;
    }

    h1 {
      margin: 0;
    }
  }

  .PostinstallOne-subtitleContainer > h2 {
    margin-top: 0;
    margin-bottom: 28px;
    text-align: center;
  }

  .PostinstallOne-image {
    margin-bottom: 50px;

    img {
      max-width: 708px;
    }
  }

  .PostinstallOne-buttons button {
    width: 400px;
  }
`;

export { StyledPostinstallOne as PostinstallOne };
