import React from 'react';
import styled from 'styled-components';
import {
  Stack,
  StackRoot,
  AddCircleIcon,
  Typography,
  Paper,
} from '@moonsifttech/design-system';

import { NewCollectionButtonProps } from './NewCollectionButton.types';
import clsx from 'clsx';

const NewCollectionButton: React.FC<NewCollectionButtonProps> = ({
  className,
  style,
  onClick,
}) => {
  return (
    <Paper
      className={clsx('NewCollectionButton-root', className)}
      style={style}
      variant="outlined"
      onClick={onClick}
    >
      <Stack alignItems="center" justifyContent="center" spacing={8}>
        <AddCircleIcon
          className="NewCollectionButton-addCircleIcon"
          size="large"
        />{' '}
        <Typography variant="primary.m16">New Collection</Typography>
      </Stack>
    </Paper>
  );
};

const StyledNewCollectionButton = styled(NewCollectionButton)`
  &.NewCollectionButton-root {
    border: 2px dashed var(--grey-2);
    width: 300px;
    height: 220px;
    cursor: pointer;
  }

  ${StackRoot} {
    height: 100%;
  }

  .NewCollectionButton-addCircleIcon {
    font-size: 28px;
  }
`;

export { StyledNewCollectionButton as NewCollectionButton };
