import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledCheckbox,
  FormControlLabel,
  formControlLabelClassNames,
  Form,
  ControlledFormGroup,
  Stack,
  Link,
} from '@moonsifttech/design-system';

import { affiliates } from 'src/types/models/creatorApplication.model';
import { CreatorApplicationTwoProps } from './CreatorApplicationTwo.types';
import R from 'src/routes';

const UnstyledCreatorApplicationTwo: VFC<CreatorApplicationTwoProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  onSubmit,
  onBack,
  formRef,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationTwo-content">
        <Typography
          className="CreatorApplicationTwo-subheading"
          variant="editorial.sb34"
          component="h1"
        >
          Which affiliate programs do you already use to monetise your content?
        </Typography>
        <Typography variant="primary.l20" component="p">
          You can choose more than one
        </Typography>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          formRef={formRef}
        >
          <ControlledFormGroup
            className="CreatorApplicationTwo-checkboxes"
            name="affiliates"
          >
            {affiliates.map((affiliate, i) => (
              <FormControlLabel
                key={i}
                label={affiliate}
                labelPlacement="end"
                value={affiliate}
                control={<ControlledCheckbox name="affiliates" />}
              />
            ))}
          </ControlledFormGroup>
          <Stack direction="row" spacing={12}>
            <StandardButton variant="primary-white" onClick={onBack}>
              Back
            </StandardButton>
            <StandardButton type="submit" variant="primary-black">
              Next
            </StandardButton>
          </Stack>
        </Form>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const CreatorApplicationTwo = styled(UnstyledCreatorApplicationTwo)`
  padding: 100px 0 0;

  .CreatorApplicationTwo-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationTwo-heading {
    color: var(--background-purple);
    margin-bottom: 8px;
  }

  .CreatorApplicationTwo-subheading {
    margin-bottom: 8px;
  }

  .CreatorApplicationTwo-checkboxes {
    display: flex;
    margin-bottom: 24px;

    .${formControlLabelClassNames.root} {
      margin-bottom: 4px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
