import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  VoidComponentProps,
  Typography,
  Accordion,
  AccordionSummary,
  accordionSummaryClassNames,
  AccordionDetails,
  accordionDetailsClassNames,
  ChevronDownIcon,
  Stack,
  StandardButton,
  ToggleButtonGroup,
  ToggleButton,
  AppleIcon,
  LaptopIcon,
  CtaButton,
  AndroidIcon,
} from '@moonsifttech/design-system';

import { Link } from 'src/components/core/Link';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { Page } from 'src/components/common/Page';
import R, { EXTERNAL } from 'src/routes';
import { HowToSaveProps } from './HowToSave.types';

const videoSource =
  'https://storage.googleapis.com/moonsift-web-assets/onboarding.mp4';
const videoAlt = 'How to save';

const UnstyledHowToSaveShareIcons: VFC<VoidComponentProps> = ({
  className,
  style,
}) => {
  return (
    <Stack className={className} style={style} direction="row" spacing={18}>
      <img
        src={`${process.env.REACT_APP_STATIC_URL}/howto/share-1.svg`}
        alt="Share icon 1"
      />
      <img
        src={`${process.env.REACT_APP_STATIC_URL}/howto/share-2.svg`}
        alt="Share icon 2"
      />
      <img
        src={`${process.env.REACT_APP_STATIC_URL}/howto/share-3.svg`}
        alt="Share icon 3"
      />
      <img
        src={`${process.env.REACT_APP_STATIC_URL}/howto/share-4.svg`}
        alt="Share icon 4"
      />
      <img
        src={`${process.env.REACT_APP_STATIC_URL}/howto/share-5.svg`}
        alt="Share icon 5"
      />
    </Stack>
  );
};

const HowToSaveShareIcons = styled(UnstyledHowToSaveShareIcons)`
  > img {
    width: 46px;
  }
`;

const UnstyledHowToSave: VFC<HowToSaveProps> = ({
  className,
  style,
  videoRef,
  device,
  videoExpanded,
  isIOS,
  isApp,
  onDeviceChange,
  onOnboard,
  onVideoChange,
  onAppStoreLinkClick,
}) => {
  const fromOthersAccordion = (
    <Accordion>
      <AccordionSummary
        id="from-others-header"
        aria-controls="from-others-content"
        expandIcon={<ChevronDownIcon size="small" />}
      >
        <Typography variant="primary.m16">
          👥 Save from other people's collections
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography
          className="HowToSave-fromOthers"
          variant="primary.l16"
          component="p"
        >
          To save items from other people's Moonsift collections, click the +
          button in the top right corner of the product you wish to save.
        </Typography>
        <img
          src={`${process.env.REACT_APP_STATIC_URL}/howto/save-from-others.svg`}
          alt="Save from others"
        />
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Page className={className} style={style} showNavControls>
      <div className="HowToSave-innerContainer">
        <Typography
          className="HowToSave-heading"
          variant="primary.b42"
          component="h1"
        >
          Help & FAQs
        </Typography>

        <ToggleButtonGroup
          className="HowToSave-deviceToggle"
          value={device}
          fullWidth
          onChange={onDeviceChange}
        >
          <ToggleButton
            color="common.white"
            startIcon={<AppleIcon />}
            value="ios"
          >
            iOS
          </ToggleButton>
          <ToggleButton
            color="common.white"
            startIcon={<LaptopIcon />}
            value="desktop"
          >
            Desktop
          </ToggleButton>
          <ToggleButton
            color="common.white"
            startIcon={<AndroidIcon />}
            value="android"
          >
            Android
          </ToggleButton>
        </ToggleButtonGroup>

        <div
          className={clsx(
            'HowToSave-accordionGroup',
            device !== 'ios' && 'HowToSave-hidden',
          )}
        >
          <Typography variant="primary.b26" component="h2">
            Saving items on iOS
          </Typography>

          {!isApp &&
            (isIOS ? (
              <CtaButton
                className="HowToSave-getIOSApp"
                variant="primary-black"
                size="medium"
                fullWidth
                component={UnstyledLink}
                onClick={onAppStoreLinkClick}
                to={EXTERNAL.APP_STORE_APP}
                target="_blank"
              >
                Get the iOS app
              </CtaButton>
            ) : (
              <CtaButton
                className="HowToSave-getIOSApp"
                variant="primary-black"
                size="medium"
                fullWidth
                component={UnstyledLink}
                to={R.APP_QR_CODE}
              >
                Get the iOS app
              </CtaButton>
            ))}

          <Accordion>
            <AccordionSummary
              id="save-faster-header"
              aria-controls="save-faster-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🚀 Make saving on iOS faster and easier
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <StandardButton
                className="HowToSave-shareMenuButton"
                variant="primary-black"
                fullWidth
                onClick={onOnboard}
              >
                Add Moonsift to Share Menu
              </StandardButton>
              <Typography variant="primary.l16" component="p">
                Add Moonsift to Favourites in your share menu so you can save
                items in two taps.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion expanded={videoExpanded} onChange={onVideoChange}>
            <AccordionSummary
              id="howto-header"
              aria-controls="howto-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                ❓ How to save from apps and websites
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <video ref={videoRef} className="HowToSave-video" loop controls>
                <source src={videoSource} type="video/mp4" />
                {videoAlt}
              </video>
              <Typography
                className="HowToSave-instructions"
                variant="primary.l16"
                component="p"
              >
                Assuming you already have{' '}
                <Link to={EXTERNAL.APP_STORE_APP}>the iOS app</Link> and have
                added Moonsift to your share menu:
              </Typography>
              <Typography
                className="HowToSave-instructions"
                variant="primary.l16"
                component="p"
              >
                1. Go to the product you want to save
              </Typography>
              <Typography
                className="HowToSave-instructions"
                variant="primary.l16"
                component="p"
              >
                2. Tap any share button. Note different apps and browsers have
                different share buttons
              </Typography>
              <HowToSaveShareIcons className="HowToSave-shareIcons" />
              <Typography
                className="HowToSave-instructions"
                variant="primary.l16"
                component="p"
              >
                3. Select Moonsift and save to a collection
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              id="issues-saving-header"
              aria-controls="issues-saving-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                ⛔ Issues saving items from a store
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                If you're seeing incorrect product info in your collection,
                please let us know via the below email address. Ideally include
                a screen shot.
              </Typography>
              <Typography variant="primary.l16" component="p">
                support@moonsift.com
              </Typography>
              <Typography variant="primary.l16" component="p">
                Please note that often the issue is with the retailer's app not
                providing accurate information. Therefore sharing items from
                their website on Safari, rather than their app, is more
                reliable.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              id="which-stores-header"
              aria-controls="which-stores-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🛍️ Which stores can I save from?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                You can save from any store that has a share button. Examples of
                share buttons below.
              </Typography>
              <HowToSaveShareIcons className="HowToSave-shareIcons" />
              <Typography variant="primary.l16" component="p">
                Save items from any retailer's website using your browser's in
                built share button. If you are saving from their app, look for a
                share button on the product page.
              </Typography>
            </AccordionDetails>
          </Accordion>
          {fromOthersAccordion}

          <Accordion>
            <AccordionSummary
              id="save-on-desktop-header"
              aria-controls="save-on-desktop-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                💻 Save items on desktop
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                On desktop you can save items from any website.&nbsp;
                <Link to={EXTERNAL.CHROMESTORE} target="_blank">
                  Simply add Moonsift's universal save button to your browser
                </Link>
                .
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div
          className={clsx(
            'HowToSave-accordionGroup',
            device !== 'desktop' && 'HowToSave-hidden',
          )}
        >
          <Typography variant="primary.b26" component="h2">
            Saving items on Desktop
          </Typography>

          <Accordion>
            <AccordionSummary
              id="save-faster-header"
              aria-controls="save-faster-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                💻 Adding Moonsift to your browser
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                If you haven't done so already, add Moonsift to your browser so
                you can save items from any website.
              </Typography>
              <CtaButton
                className="HowToSave-extensionButton"
                variant="primary-black"
                size="medium"
                component={UnstyledLink}
                to={EXTERNAL.CHROMESTORE}
                target="_blank"
              >
                Add Moonsift to your browser
              </CtaButton>
              <Typography variant="primary.r14" component="p">
                Available on Chrome, Edge & Brave browsers
              </Typography>
              <Typography variant="primary.l16" component="p">
                Once added,{' '}
                <Link to={EXTERNAL.HOW_TO_PIN_MOONSIFT} target="_blank">
                  pin Moonsift
                </Link>{' '}
                to your browser so you can access it from any website.
              </Typography>
              {isApp !== true && (
                <video
                  src={`${process.env.REACT_APP_STATIC_URL}/landing/Saving_product_video_loop.mov`}
                  loop
                  autoPlay
                  muted
                  playsInline
                  className="HowToSave-savingFromWebsites"
                />
              )}
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              id="save-from-any-store"
              aria-controls="save-from-any-store-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🌎 Save items from any website
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="primary.l16"
                color="background.purple"
                component="p"
              >
                <i>If you have the browser extension:</i>
              </Typography>
              <Typography variant="primary.l16" component="p">
                <Link to={EXTERNAL.HOW_TO_PIN_MOONSIFT} target="_blank">
                  Pin Moonsift to your toolbar
                </Link>{' '}
                so you can save items directly from any website.
              </Typography>
              <Typography variant="primary.l16" component="p">
                Here's how: <br />
                1. Visit any online retailer's website, <br />
                2. Go the product you want to save, <br />
                3. Click the Moonsift icon in your toolbar!
              </Typography>
              <picture>
                <source
                  media="(max-width: 620px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/howto/save-from-any-store.png`}
                />
                <img
                  className="HowToSave-pic"
                  alt="Moonsift in your browser's toolbar"
                  src={`${process.env.REACT_APP_STATIC_URL}/howto/save-from-any-store.2x.png`}
                />
              </picture>
              <Typography variant="primary.l16" component="p">
                <Link to={EXTERNAL.HOW_TO_PIN_MOONSIFT} target="_blank">
                  Click here
                </Link>{' '}
                if you can't see the Moonsift icon in your toolbar.
              </Typography>
              <Typography
                variant="primary.l16"
                color="background.purple"
                component="p"
              >
                <i>If you cannot get the browser extension:</i>
              </Typography>
              <Typography variant="primary.l16" component="p">
                If you use a browser like <b>Safari</b> or <b>Firefox</b> which
                don't support Chrome extensions, you can still add items simply
                by pasting a link.
                <br />
                <br />
                1. Visit any online retailer's website,
                <br />
                2. Go to a product and copy the page link,
                <br />
                3. Open one of your Moonsift collections, <br />
                4. Tap 'Add item', then 'Add item from a link',
                <br />
                5. Paste the product link.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              id="save-with-quick-buttons"
              aria-controls="save-with-quick-buttons-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                ⚡️ Quick‑save buttons on 2,000+ stores
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                {' '}
                Once you've added Moonsift, you'll see Quick‑save buttons on{' '}
                <Link to={R.RETAILERS_AZ}>1,000's of stores</Link>.
              </Typography>
              <Typography variant="primary.l16" component="p">
                Hover and save when browsing a page with lots of items.
              </Typography>
              <picture>
                <source
                  media="(max-width: 620px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/howto/save-with-quick-buttons-1.png`}
                />
                <img
                  className="HowToSave-pic"
                  alt="Quick-save buttons on product list pages"
                  src={`${process.env.REACT_APP_STATIC_URL}/howto/save-with-quick-buttons-1.2x.png`}
                />
              </picture>
              <Typography variant="primary.l16" component="p">
                Look for a Save button on the right‑hand side when viewing a
                single item.
              </Typography>
              <picture>
                <source
                  media="(max-width: 620px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/howto/save-with-quick-buttons-2.png`}
                />
                <img
                  className="HowToSave-pic"
                  alt="Quick-save buttons on product list pages"
                  src={`${process.env.REACT_APP_STATIC_URL}/howto/save-with-quick-buttons-2.2x.png`}
                />
              </picture>
            </AccordionDetails>
          </Accordion>

          {fromOthersAccordion}
        </div>

        <div
          className={clsx(
            'HowToSave-accordionGroup',
            device !== 'android' && 'HowToSave-hidden',
          )}
        >
          <Typography variant="primary.b26" component="h2">
            Saving items on Android
          </Typography>

          <Accordion>
            <AccordionSummary
              id="save-faster-header"
              aria-controls="save-faster-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🔗 Add items from any store with a link
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                1. Visit any online retailer's website,
                <br />
                2. Go to a product and copy the page link,
                <br />
                3. Open one of your Moonsift collections, <br />
                4. Tap 'Add item', then 'Add item from a link',
                <br />
                5. Paste the product link.
                <br />
                <br />
                All the product information and images will be generated in
                seconds.
              </Typography>
            </AccordionDetails>
          </Accordion>

          {fromOthersAccordion}
          <Accordion>
            <AccordionSummary
              id="save-faster-header"
              aria-controls="save-faster-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                💻 Save items on desktop
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                On desktop you can save items from any website. Simply{' '}
                <Link to={EXTERNAL.CHROMESTORE} target="_blank">
                  add Moonsift's universal save button to your browser.
                </Link>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="HowToSave-accordionGroup">
          <Typography variant="primary.b26" component="h2">
            Sharing collections
          </Typography>

          <Accordion>
            <AccordionSummary
              id="how-to-share-header"
              aria-controls="how-to-share-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🔗 How to share collections
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                1. Go to the collection you wish to share.
              </Typography>
              <Typography variant="primary.l16" component="p">
                2. Click the 'Share' button below the name of your collection.
              </Typography>
              <Typography variant="primary.l16" component="p">
                3. Copy the link provided and share this with whoever you want
                to view your collection.
              </Typography>
              <Typography variant="primary.l16" component="p">
                Recipients will be able to view your collection on any platform,
                comment on your selections and click through to buy the items.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              id="collection-visibility-header"
              aria-controls="collection-visibility-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">
                🔑 Public vs. Private vs. Hidden
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                Collections are 'Private' by default. To change this, go to the
                collection and tap the edit button below your collection name.
              </Typography>
              <Typography variant="primary.l16" component="p">
                <b>Public:</b> Visible to anyone, including people without a
                Moonsift account. Perfect for sharing on social media.
              </Typography>
              <Typography variant="primary.l16" component="p">
                <b>Private:</b> Only visible to people you share the link with.
                For Gift Lists, no account is required by visitors to mark items
                as purchased. For standard collections visitors will need to
                create a free Moonsift account to access it.
              </Typography>
              <Typography variant="primary.l16" component="p">
                <b>Hidden:</b> Not visible to anyone. People you previously
                shared the link with will lose access.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              id="collection-visibility-header"
              aria-controls="collection-visibility-content"
              expandIcon={<ChevronDownIcon size="small" />}
            >
              <Typography variant="primary.m16">🎁 Gift Lists</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="primary.l16" component="p">
                Previously called "Registry".
              </Typography>
              <Typography variant="primary.l16" component="p">
                Visitors will be asked to mark off what they buy.
              </Typography>
              <Typography variant="primary.l16" component="p">
                You will not be notified when items are bought, keeping it a
                surprise. If you wish, you can reveal bought items by checking
                this option at the top of a Gift List collection.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="HowToSave-links">
          <Typography variant="primary.m16" component="p">
            <Link to={EXTERNAL.MASTERING_MOONSIFT} target="_blank">
              Mastering Moonsift
            </Link>
          </Typography>
          <Typography variant="primary.m16" component="p">
            <Link to={EXTERNAL.PRO_EXPLANATION_FAQ} target="_blank">
              Moonsift PRO
            </Link>
          </Typography>
          <Typography variant="primary.m16" component="p">
            <Link to={EXTERNAL.ACCOUNTS_AND_GENERAL_QUESTIONS} target="_blank">
              Account & other general questions
            </Link>
          </Typography>
        </div>

        <Typography variant="primary.l16" component="p">
          Email us at hello@moonsift.com if you have an issue and can't find the
          answer here.
        </Typography>
      </div>
    </Page>
  );
};

export const HowToSave = styled(UnstyledHowToSave)`
  .HowToSave-innerContainer {
    max-width: 328px;
    margin: 0 auto 40px;
  }

  .HowToSave-heading {
    margin-bottom: 24px;
  }

  .HowToSave-deviceToggle {
    margin-bottom: 24px;
  }

  .HowToSave-savingFromWebsites {
    width: 100%;
  }

  .HowToSave-accordionGroup {
    > h2 {
      margin-bottom: 12px;
    }

    > :last-child {
      margin-bottom: 40px;
    }
  }

  .App-root:not(.App-isApp) .HowToSave-getIOSApp {
    margin-bottom: 24px;
  }

  .${accordionSummaryClassNames.root} {
    outline: 0;
  }

  .${accordionDetailsClassNames.root} {
    > :last-child {
      margin-bottom: 24px;
    }
  }

  .HowToSave-video {
    margin-bottom: 16px;
    width: 100%;
  }

  .HowToSave-instructions {
    margin-bottom: 12px;
  }

  .HowToSave-shareIcons {
    margin-top: -4px;
    margin-bottom: 16px;
  }

  .HowToSave-setupImage {
    margin-bottom: 12px;
  }

  .HowToSave-shareMenuButton {
    margin-bottom: 18px;
  }

  .HowToSave-saveText,
  .HowToSave-fromOthers {
    margin-bottom: 12px;
  }

  .HowToSave-links > p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 40px;
    }
  }

  .HowToSave-extensionButton {
    margin-bottom: 12px;
  }

  .HowToSave-pic {
    width: 100%;
    margin-bottom: 12px;
  }

  .HowToSave-hidden {
    display: none;
  }
`;
