import { all } from 'redux-saga/effects';
import { baseQueryListen } from './baseQueryListen/baseQueryListen.saga';
import { baseQueryGet } from './baseQueryGet/baseQueryGet.saga';
import { baseDocGet } from './baseDocGet/baseDocGet.saga';
import { baseDocListen } from './baseDocListen/baseDocListen.saga';
import { watchNotificationsCall } from 'src/mvp22/saga-components/notifications';

export default function* rootSaga() {
  yield all([
    baseQueryListen(),
    baseDocListen(),
    baseQueryGet(),
    baseDocGet(),
    // TODO: Convert these to baseDB:
    watchNotificationsCall(),
  ]);
}
