import { FC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  Typography,
  Stack,
  StandardButton,
  LinearProgress,
} from '@moonsifttech/design-system';

import { GetStartedCloseButton } from 'src/components/profile/GetStartedCloseButton';
import { GetStartedChecklistItem } from 'src/components/profile/GetStartedChecklistItem';
import { GetStartedChecklistDialogProps } from './GetStartedChecklistDialog.types';

const UnstyledGetStartedChecklistDialog: FC<GetStartedChecklistDialogProps> = ({
  className,
  style,
  checklist,
  onClose,
  onAllSet,
}) => {
  const total = checklist.length;
  const completed = checklist.filter(({ checked }) => checked).length;

  return (
    <Paper className={className} style={style}>
      <GetStartedCloseButton
        className="GetStartedChecklistDialog-closeButton"
        onClick={onClose}
      />
      <Typography
        className="GetStartedChecklistDialog-title"
        variant="primary.b26"
        component="h3"
      >
        Getting Started
      </Typography>
      <Typography
        className="GetStartedChecklistDialog-subtitle"
        variant="primary.r14"
        component="h4"
        color="grey.2"
      >
        Four simple steps to get you up and running
      </Typography>
      <Stack
        className="GetStartedChecklistDialog-progressContainer"
        direction="row"
        spacing={12}
        alignItems="center"
      >
        <Typography variant="primary.b18">
          {completed}/{total}
        </Typography>
        <LinearProgress
          className="GetStartedChecklistDialog-progress"
          value={Math.round(100 * (completed / total))}
        />
      </Stack>
      <Stack
        className="GetStartedChecklistDialog-itemContainer"
        direction="column"
        alignItems="center"
        spacing={10}
      >
        {checklist.map(({ name, title, description, checked, onChange }) => (
          <GetStartedChecklistItem
            key={name}
            className="GetStartedChecklistDialog-item"
            name={name}
            title={title}
            description={description}
            checked={checked}
            onChange={onChange}
          />
        ))}
      </Stack>
      <Stack
        className="GetStartedChecklistDialog-allSetContainer"
        direction="row"
        justifyContent="flex-end"
      >
        {completed === total ? (
          <StandardButton
            variant="primary-white"
            disabled={completed !== total}
            onClick={onAllSet}
          >
            I'm all set!
          </StandardButton>
        ) : (
          <Typography variant="primary.r14" color="grey.2" component="p">
            Check off the steps as you go
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export const GetStartedChecklistDialog = styled(
  UnstyledGetStartedChecklistDialog,
)`
  position: relative;
  width: 476px;
  padding: 28px 24px 44px;

  .GetStartedChecklistDialog-closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .GetStartedChecklistDialog-title {
    margin: 0;
  }
  .GetStartedChecklistDialog-subtitle {
    margin: 0 0 12px;
  }

  .GetStartedChecklistDialog-progressContainer {
    margin-bottom: 16px;
  }

  .GetStartedChecklistDialog-itemContainer {
    margin-bottom: 28px;
  }

  .GetStartedChecklistDialog-progress,
  .GetStartedChecklistDialog-item {
    width: 100%;
  }

  .GetStartedChecklistDialog-infoContainer {
    margin-bottom: 40px;
  }

  .GetStartedChecklistDialog-actions {
    margin-bottom: 34px;
  }
`;
