/*

Based on https://github.com/finnfiddle/react-window-size/

This has been replaced with a redux hook called at app level and so the state can be pulled in as follows:
const windowWidth = useSelector<RootState,number>((state) => state.ui.windowWidth);

TODO: Delete this when removed references to it.

*/

import React, { Component } from 'react';

const func = (ComposedComponent) => {

  class windowSize extends Component {

    constructor() {
      super();
      this.state = {
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      };
    }

    handleResize() {
      // set initial state
        this._isMounted && this.setState({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
    }

    componentDidMount() {
      this._isMounted = true;
      // bind window resize listeners
      this._handleResize = this.handleResize.bind(this);
      window.addEventListener('resize', this._handleResize);
      this._timeout = setTimeout(this._handleResize, 1000);
    }

    componentWillUnmount() {
      // clean up listeners
      this._isMounted = false;
      window.removeEventListener('resize', this._handleResize);
    }

    getWrappedInstance() {
      return this.wrappedInstance;
    }

    render() {
      // pass window dimensions as props to wrapped component
      return (
        <ComposedComponent
          {...this.props}
          ref={c => { this.wrappedInstance = c; }}
          windowWidth={this.state.width}
          windowHeight={this.state.height}
        />
      );
    }

  }

  const composedComponentName = ComposedComponent.displayName
    || ComposedComponent.name
    || 'Component';

  windowSize.displayName = `windowSize(${composedComponentName})`;
  return windowSize;

};

export default func;
