import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { generateImageSource } from 'src/utils/generateImageSource';
import { UserContainerProps, UserData } from './User.types';
import { User } from './User.component';

const UserContainer: React.FC<UserContainerProps> = (props) => {
  const { name, username, imageSource, isPro } = useSelector<
    RootState,
    UserData
  >((state) => {
    // Get collection's user public data
    const userUID = state?.db.single_collection.data?.user_uid ?? '';
    const userData = state?.db.user_public.map[userUID];

    // Extract user name and isPro flag
    const name = userData?.name ?? '';
    const isPro = Boolean(userData?.pro);

    // Generate the image source from the image code and the user ID
    // Note: if the user doesn't have a profile image we will generate an
    // image source that will fail to load
    const profileImageCode = userData?.profile_image_code ?? '';
    const imageSource = generateImageSource(userUID, profileImageCode);

    // Generate the user link from the display username
    const originalUsername = userData?.original_username ?? '';
    const displayUsername = userData?.display_username ?? '';
    const username = displayUsername || originalUsername;

    return { name, username, imageSource, isPro };
  });

  return (
    <User
      name={name}
      username={username}
      imageSource={imageSource}
      isPro={isPro}
      {...props}
    />
  );
};

export { UserContainer as User };
