/*

Replace with REDUX doc by id.

Get's the current user's opinion on this collection.

Can be replaced as in Collection view with a new db listener (check it out!)

At present uses a state assign function to add to the collection data map (dictionary).
Thankfully this will be removed soon!

*/
import send_error from 'src/mvp22/core-components/send_error';
// CALLED within a set state function ideally!
export default function get_collection_likes(old_collection_data_dict,firebase,auth_user_uid,state_assign_func){
  const new_collection_data_dict = {};
  if (auth_user_uid){
    for (let this_entry_uid in old_collection_data_dict){
      const this_res = old_collection_data_dict[this_entry_uid];
      if (
        this_res.collection_entry
       &&
        !this_res.collection_liked_listener
      ){
        const collection_liked_listener = firebase.user_collection_liked_query(
          auth_user_uid,
          this_res.collection_entry.user_uid,
          this_res.collection_entry.uid
        ).onSnapshot(
          (snapshot)=>{
            const is_liked = (snapshot.exists())?true:false;
            // Send standard product uid dict:
            state_assign_func(
              {
                [this_res.collection_entry.global_uid]:{
                  collection_liked_is_liked:is_liked
                }
              }
            );
          }
          ,
          (error)=>send_error(error)
        );
        new_collection_data_dict[this_entry_uid] = {
          collection_liked_listener
        };
      }
    }
  }
  return new_collection_data_dict;
}
