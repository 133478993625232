import {
  ActionNames,
  StylistApplicationAction,
  StylistApplicationState,
} from 'src/types';

function getInitialState(): StylistApplicationState {
  return {
    professions: [],
    channels: [
      {
        name: '',
        url: '',
      },
      {
        name: '',
        url: '',
      },
    ],
    audience: {
      countries: [''],
    },
    feature: '',
    errorMessage: '',
  };
}

export const stylistApplication = (
  state = getInitialState(),
  action: StylistApplicationAction,
): StylistApplicationState => {
  switch (action.type) {
    case ActionNames.UPDATE_STYLIST_APPLICATION_REQUEST:
    case ActionNames.UPDATE_STYLIST_APPLICATION_ERROR_MESSAGE:
      return {
        ...state,
        ...action.payload,
      };
    case ActionNames.RESET_STYLIST_APPLICATION_REQUEST:
      return getInitialState();
    default:
      return state;
  }
};
