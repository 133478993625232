import { VFC } from 'react';
import styled from 'styled-components';

import { ModalContent } from 'src/components/core/ModalContent';
import { CreateCollectionContent } from 'src/components/common/CreateCollectionContent';
import { CreateCollectionDialogProps } from './CreateCollectionDialog.types';

const UnstyledCreateCollectionDialog: VFC<CreateCollectionDialogProps> = ({
  className,
  style,
  onClose,
}) => {
  return (
    <ModalContent className={className} style={style} onClose={onClose}>
      <CreateCollectionContent />
    </ModalContent>
  );
};

export const CreateCollectionDialog = styled(UnstyledCreateCollectionDialog)``;
