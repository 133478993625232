/*

Replace with REDUX doc by id.

These are stored in another document to reduce updates to the main collection doc.

At present uses a state assign function to add to the collection data map (dictionary).
Thankfully this will be removed soon!

*/
import {get_image_full_url} from 'src/mvp22/core-components/image_url';
// Now using pattern that searches current dict for items to update, in case list is refreshed in future:
export default function get_collection_images(old_collection_data_dict,firebase,state_assign_func){
  const promise_array = [];
  const new_collection_data_dict = {};
  const listen_update_dict = {};
  for (let this_entry_uid in old_collection_data_dict){
    const this_res = old_collection_data_dict[this_entry_uid];
    if (
      this_res.collection_entry
     &&
      !this_res.images_requested
    ){
      promise_array.push(
        firebase.user_product_collection_images(this_res.collection_entry.user_uid,this_res.collection_entry.uid)
         .get()
         .then(
           snaphot=>{
             new_collection_data_dict[this_entry_uid] = {
               collection_images : (snaphot.data().image_list)?snaphot.data().image_list.map(x=>get_image_full_url(x)):[]
             };
           }
         )
      ); // array push
      listen_update_dict[this_entry_uid] = {images_requested:true};
    } // for loop
  }
  Promise.allSettled(promise_array).then(
    ()=>{
      state_assign_func(new_collection_data_dict);
    }
  );
  return listen_update_dict;
}
