import { DB, QuerySnapshot, DocumentData, Query } from 'src/services/DB';
import { all, put, takeEvery } from 'redux-saga/effects';
import { baseDBSetQuery } from 'src/store/actions/baseDB';
import {
  ActionNames,
  ParserFunction,
  BaseDBActionTypes,
  BaseDBKindsUnion,
  BaseDBFetchingDict,
} from 'src/types';
import {
  addToFetchingDict,
  checkFetchingDict,
  deleteFromFetchingDict,
} from 'src/utils/fetchingDict';

const fetchingDict: BaseDBFetchingDict = {};

/*

The saga for getting collections / queries (arrays of documents) from the database in a single instance (not listening).

*/
function* getItems<K extends BaseDBKindsUnion>(
  kind: K['name'],
  dB: DB,
  dBQuery: Query,
  parser: ParserFunction<K['item']>,
  storeAs: string | undefined,
) {
  // Code for treating the above listener output:
  // closing channel will terminate entire function and just call finally
  if (!checkFetchingDict(kind, storeAs, fetchingDict)) {
    try {
      addToFetchingDict(kind, storeAs, fetchingDict);
      const prom = dB
        .getQuery(dBQuery)
        .then((result) => result)
        .catch((err) => {
          console.log('useDB error for kind', kind, err);
          return null;
        });
      const snapshot: QuerySnapshot<DocumentData> | null = yield prom;
      // process the snapshot:...
      const itemList: K['item'][] = [];
      if (snapshot !== null) {
        snapshot.forEach((doc) => {
          const parsedData = parser(doc); // cannot actually be null but type allows it so we can write more generic parser.
          if (parsedData) {
            itemList.push(parsedData);
          }
        });
      }
      // Put data away:
      yield put<BaseDBActionTypes<K>[ActionNames.BASEDB_SET_QUERY]>(
        baseDBSetQuery<K>(itemList, kind, storeAs),
      );
    } finally {
      //Nothing
    }
  }
  // Anything below here is never called (unless there is an error in yield...?)
}

// worker saga:
function* doGet<K extends BaseDBKindsUnion>(
  action: BaseDBActionTypes<K>['BASEDB_GET_QUERY'],
) {
  yield getItems<K>(
    action.kind,
    action.payload.dB,
    action.payload.dBQuery,
    action.payload.parser,
    action.storeAs,
  );
}

function clearFetching<K extends BaseDBKindsUnion>(
  action:
    | BaseDBActionTypes<K>['BASEDB_UNLISTEN_SINGLE']
    | BaseDBActionTypes<K>['BASEDB_UNLISTEN_MAP'],
) {
  deleteFromFetchingDict(action.kind, undefined, fetchingDict);
}

// Watching saga:
export function* baseQueryGet() {
  yield all([
    takeEvery(ActionNames.BASEDB_GET_QUERY, doGet),
    takeEvery(ActionNames.BASEDB_UNLISTEN_SINGLE, clearFetching),
    takeEvery(ActionNames.BASEDB_UNLISTEN_MAP, clearFetching),
  ]);
}
