import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledTextField,
  Stack,
  Form,
  Link,
} from '@moonsifttech/design-system';

import { StylistApplicationThreeProps } from './StylistApplicationThree.types';
import R from 'src/routes';

const UnstyledStylistApplicationThree: VFC<StylistApplicationThreeProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  formRef,
  onSubmit,
  onBack,
}) => {
  return (
    <div className={className} style={style}>
      <div className="StylistApplicationThree-content">
        <Typography
          className="StylistApplicationThree-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Where is the majority of your audience based?
        </Typography>
        <Form
          formRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <ControlledTextField
            className="StylistApplicationThree-input"
            id="audience-country"
            name="audience.countries[0]"
            placeholder="e.g. France"
          />
          <Stack direction="row" spacing={12}>
            <StandardButton variant="primary-white" onClick={onBack}>
              Back
            </StandardButton>
            <StandardButton type="submit" variant="primary-black">
              Next
            </StandardButton>
          </Stack>
        </Form>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const StylistApplicationThree = styled(UnstyledStylistApplicationThree)`
  padding: 100px 0 0;

  .StylistApplicationThree-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .StylistApplicationThree-heading {
    margin-bottom: 24px;
  }

  .StylistApplicationThree-input {
    margin-bottom: 28px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
