import { VFC, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { object, string } from 'yup';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import R from 'src/routes';
import { updateCreatorApplicationRequest } from 'src/store/actions/creatorApplication';
import {
  CreatorApplicationFourContainerProps,
  CreatorApplicationFourProps,
  CreatorApplicationFourFormContent,
} from './CreatorApplicationFour.types';
import { CreatorApplicationFour } from './CreatorApplicationFour.component';

const validationSchema = object({
  name: string().required('A channel name must be provided.'),
  url: string().required('A channel url or handle must be provided.'),
}).required('An object is required in the array');

const CreatorApplicationFourContainer: VFC<
  CreatorApplicationFourContainerProps
> = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const initialValues = useSelector<
    RootState,
    CreatorApplicationFourFormContent
  >(({ creatorApplication }) => creatorApplication.channels[0]);

  const formRef: CreatorApplicationFourProps['formRef'] = useRef(null);

  const handleSubmit = useCallback<CreatorApplicationFourProps['onSubmit']>(
    (values, actions) => {
      dispatch(
        updateCreatorApplicationRequest({
          channels: [values],
        }),
      );
      actions.setSubmitting(false);
      history.push(R.CREATOR_APPLICATION_FIVE);
    },
    [dispatch, history],
  );

  const handleBack = useCallback(() => {
    if (formRef.current) {
      const channels = [
        {
          name: formRef.current['audience_channel_name'].value,
          url: formRef.current['audience_channel_url'].value,
        },
      ];
      dispatch(updateCreatorApplicationRequest({ channels }));
    }
    history.push(R.CREATOR_APPLICATION_THREE);
  }, [dispatch, history]);

  return (
    <CreatorApplicationFour
      formRef={formRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      onBack={handleBack}
      {...props}
    />
  );
};

export { CreatorApplicationFourContainer as CreatorApplicationFour };
