/*

Modal to edit collection info or create a new one

Debateable if we should change this, but the main point of this modal is to include
methods for creating a new collection and updating existing fields.

We use it to create new collections and edit their name / description.
We ALSO use this modal for creating a new collection when assigning product membership.
Note that this means it is in 2 ModalGroups!

TODO: camelCase, typescript, design system text boxes.
*/

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withFirebase } from 'src/mvp22/Firebase';
import { useSelector } from 'react-redux';
import { useClickOutside } from 'src/hooks/useClickOutside';
import {
  GeneralOverlayWithinContainer,
  FlexGrowTop,
  LeftAlignedTitle,
  VerticalFlow,
  RightFlow,
  CloseCross,
  Separator,
  PrivateDefaultMessage,
  CharCount,
  RightContainerProduct,
  RightTitleContainer,
  LogoAndTitle,
  LogoInTitle,
  Cross,
  CollectionSettings,
  BothBottomBar,
} from 'src/mvp22/modal-components/ModalComponents.js';
import { M } from 'src/mvp22/constants';
import TextBox from 'src/mvp22/form-components/TextBox';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import { ProductLeft } from 'src/mvp22/modal-components/ProductLeft';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import Button from 'src/mvp22/form-components/Button';
import add_new_collection from 'src/mvp22/firebase-functions/add_new_collection';
import update_collection from 'src/mvp22/firebase-functions/update_collection';

const MAX_COLLECTION_DESCRIPTION_LENGTH = 400;
const MAX_COLLECTION_NAME_LENGTH = 100;

const checkCollectionNameValid = (name) => {
  return name.length > 0 && name.length < MAX_COLLECTION_NAME_LENGTH;
};

const checkCollectionDescriptionValid = (description) => {
  return !(
    description && description.length > MAX_COLLECTION_DESCRIPTION_LENGTH
  );
};

const CollectionDesc = styled.div`
  color: ${M.COL.TEXT.MID};
  text-align: left;
`;

const Subtitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
`;

const AtEnds = styled(RightFlow)`
  justify-content: space-between;
`;

const DeleteContainer = styled(TEXTSTYLE.LINKA)`
  display: flex;
  cursor: pointer;
  align-content: center;
  text-decoration: none;
`;

const LittleBin = styled.img`
  height: 20px;
  opacity: 0.7;
  ${DeleteContainer}:hover & {
    opacity: 1;
  }
`;

const DeleteText = styled(TEXTSTYLE.OTHER2)`
  color: ${M.COL.TEXT.MID};
  ${DeleteContainer}:hover & {
    color: ${M.COL.TEXT.BLACK};
  }
  text-decoration: none;
`;

const DefaultComponent = ({
  firebase,
  // required params:
  modal_type,
  collection_name_input,
  collection_description_input,
  // required functions:
  closeFunction,
  afterAction, // what to do after updating / adding collection.
  // optional params depending on modal type:
  productImage, // new_collection_product ONLY
  productData, // new_collection_product ONLY
  // optional functions depending on modal type:
  openDeleteCollectionModal, // collection_settings ONLY
  backToMembershipProperties, // new_collection_product ONLY
  collectionUID, // collection_settings + new_collection_product
  collectionUserUID, // collection_settings + new_collection_product
  collectionItem, // new_collection_product ONLY
}) => {
  const [local_collection_name, setLocalCollectionName] = useState('');
  const [local_collection_description, setLocalCollectionDescription] =
    useState('');
  const [done_create, setDoneCreate] = useState(null);
  const [feedback_text, setFeedbackText] = useState('');
  const [collection_name_modified, setCollectionNameModified] = useState(false);
  const authUserUID = useSelector((state) => state.auth.id);
  const isPro = useSelector((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  const containerRef = useClickOutside(closeFunction);

  // Apply this function to add / update the collection
  const functionToApply = collectionUID
    ? (update_data) =>
        update_collection(
          collectionUID,
          update_data,
          firebase,
          authUserUID,
          () => {
            setDoneCreate(true);
            afterAction();
          },
        )
    : (update_data) =>
        add_new_collection(update_data, firebase, authUserUID, (response) => {
          setDoneCreate(true);
          afterAction(response.id, productData, collectionItem);
          // reset fields:
          setLocalCollectionName('');
          setLocalCollectionDescription('');
          setFeedbackText('');
        });

  const addOrUpdateCollection = (event) => {
    if (
      checkCollectionNameValid(local_collection_name) &&
      checkCollectionDescriptionValid(local_collection_description)
    ) {
      if (done_create !== false) {
        setDoneCreate(false);
        const update_data = {
          name: local_collection_name,
        };
        if (local_collection_description) {
          update_data.description = local_collection_description;
        }
        functionToApply(update_data).catch(() => {
          setDoneCreate(true);
        });
      }
    }
    event.preventDefault();
    return false;
  };

  // Update local states on update of fields:
  useEffect(() => {
    setLocalCollectionName(collection_name_input ? collection_name_input : '');
  }, [collection_name_input]);
  useEffect(() => {
    setLocalCollectionDescription(
      collection_description_input ? collection_description_input : '',
    );
  }, [collection_description_input]);
  // feedback on local state changes:
  useEffect(() => {
    setFeedbackText(
      checkCollectionNameValid(local_collection_name) ||
        collection_name_modified === false
        ? ''
        : 'Name must be between 1 and 100 characters long',
    );
  }, [local_collection_name, collection_name_modified]);

  const renderNameInput = () =>
    collectionUID !== 'purchased' ? (
      <TextInputFeedback
        focusOnMount={true}
        placeholder="Collection name"
        msstyle="standard"
        onChange={(event) => {
          setCollectionNameModified(true);
          setLocalCollectionName(event.target.value);
        }}
        name="collection_name"
        feedbackText={feedback_text}
        value={local_collection_name}
        key="collection_name"
        width="100%"
        tabIndex={2000}
        onEnter={addOrUpdateCollection}
      />
    ) : null;

  const renderDescriptionInput = () =>
    isPro === true ? (
      <>
        <Separator height="12px" />
        <TextBox
          placeholder="Add a description (optional)"
          msstyle="standard"
          value={local_collection_description}
          onChange={(event) =>
            setLocalCollectionDescription(event.target.value)
          }
          name="collection_description"
          width="100%"
          height="100px"
          tabIndex={2001}
        />
        <CharCount
          charCountExceeded={
            local_collection_description.length >
            MAX_COLLECTION_DESCRIPTION_LENGTH
          }
        >
          {local_collection_description.length}/
          {MAX_COLLECTION_DESCRIPTION_LENGTH}
        </CharCount>
      </>
    ) : null;

  const renderActionButton = (text) => (
    <Button
      text={text}
      msstyle="square_popup"
      enabled={checkCollectionNameValid(local_collection_name)}
      onClick={addOrUpdateCollection}
      width="96px"
      tabIndex={2003}
      dots={true}
      working={done_create === false}
    />
  );

  const renderInternal = () => {
    switch (modal_type) {
      case 'NewCollection':
        return (
          <VerticalFlow>
            <LeftAlignedTitle>Create new collection</LeftAlignedTitle>
            <FlexGrowTop>
              {renderNameInput()}
              <PrivateDefaultMessage />
              {renderDescriptionInput()}
            </FlexGrowTop>
            <RightFlow>{renderActionButton('Create')}</RightFlow>
          </VerticalFlow>
        );
      case 'CollectionSettings':
        return collectionUID !== 'purchased' ? (
          <VerticalFlow>
            <LeftAlignedTitle>
              {collectionUID !== 'purchased'
                ? 'Edit or delete collection'
                : 'Purchased collection properties'}
            </LeftAlignedTitle>
            <Separator height="14px" />
            <FlexGrowTop>
              <Subtitle>Rename your collection</Subtitle>
              <Separator height="8px" />
              {renderNameInput()}
              {renderDescriptionInput()}
            </FlexGrowTop>
            <Separator height="26px" />
            <AtEnds>
              {collectionUID !== 'purchased' ? (
                <DeleteContainer
                  onClick={openDeleteCollectionModal}
                  tabIndex={2001}
                  href="#"
                >
                  <LittleBin src={MEDIA.SMALL_BIN} />
                  <DeleteText>&nbsp;&nbsp;Delete Collection</DeleteText>
                </DeleteContainer>
              ) : (
                ''
              )}
              {renderActionButton('Save')}
            </AtEnds>
          </VerticalFlow>
        ) : null;
      case 'NewCollectionProduct':
        return (
          <>
            <ProductLeft
              productData={productData}
              productImage={productImage}
            />
            <RightContainerProduct>
              <RightTitleContainer>
                <LogoAndTitle>
                  <LogoInTitle src={MEDIA.LOGO_DEFAULT} />
                  <TEXTSTYLE.HEADING5>Create new collection</TEXTSTYLE.HEADING5>
                </LogoAndTitle>
                <TEXTSTYLE.LINKA
                  href="#"
                  tabIndex="13002"
                  onClick={closeFunction}
                >
                  <Cross src={MEDIA.EXIT_CROSS_THIN} />
                </TEXTSTYLE.LINKA>
              </RightTitleContainer>
              <CollectionSettings>
                <CollectionDesc>Name new collection</CollectionDesc>
                <Separator height="12px"></Separator>
                {renderNameInput()}
                <PrivateDefaultMessage />
              </CollectionSettings>
              <BothBottomBar>
                <RightFlow>
                  <Button
                    text="Back"
                    msstyle="square_popup"
                    onClick={backToMembershipProperties}
                    tabIndex={2004}
                    width="96px"
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  {renderActionButton('Create')}
                </RightFlow>
              </BothBottomBar>
            </RightContainerProduct>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <GeneralOverlayWithinContainer ref={containerRef}>
      <CloseCross closeFunction={closeFunction} />
      {renderInternal()}
    </GeneralOverlayWithinContainer>
  );
};

export default withFirebase(DefaultComponent);
