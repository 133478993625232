import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Typography,
  StandardButton,
  Stack,
  LoadingDots,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { NotProvisionedProps } from './NotProvisioned.types';

const UnstyledNotProvisioned: VFC<NotProvisionedProps> = ({
  className,
  style,
  isProvisioning,
  provisioningFailed,
  loadingFailed,
  justLoggedIn,
  handleForceReload,
  handleGoBack,
}) => {
  return (
    <>
      <Page className={className} style={style}>
        <div className="NotProvisioned-content">
          {
            /* Case that for some reason the user data does not load even if the account is provisioned, but the user data fails to load */
            !justLoggedIn && (
              <Stack>
                <Helmet>
                  <title>You appear to be offline - Moonsift</title>
                </Helmet>
                <Typography variant="primary.b26" component="h1">
                  You appear to be offline
                </Typography>
                <StandardButton
                  type="submit"
                  variant="primary-white"
                  loading={isProvisioning}
                  fullWidth
                  onClick={handleForceReload}
                >
                  Reload
                </StandardButton>
                <Typography
                  variant="primary.l16"
                  component="h2"
                  className="NotProvisioned-supplementaryText"
                >
                  Note: You may also get this message if your account is not set
                  up properly. In this case, try logging out and in again. If
                  this persists, please contact us via the feedback button
                  (bottom left) or by emailing hello@moonsift.com
                </Typography>
              </Stack>
            )
          }
          {
            /* Case account is not provisioned yet */
            justLoggedIn && !provisioningFailed && !loadingFailed && (
              <Stack>
                <Helmet>
                  <title>Setting up a new account - Moonsift</title>
                </Helmet>
                <Typography variant="primary.r26" component="h1">
                  Setting up a <b>new</b> Moonsift account
                </Typography>
                <LoadingDots className="NotProvisioned-settingUpLoadingDots" />
              </Stack>
            )
          }
          {
            /* Case account provisioning failed */
            justLoggedIn && !provisioningFailed && loadingFailed && (
              <Stack>
                <Helmet>
                  <title>Setup Complete</title>
                </Helmet>
                <Typography variant="primary.r26" component="h1">
                  Setup complete
                </Typography>
                <StandardButton
                  type="submit"
                  variant="primary-white"
                  loading={isProvisioning}
                  fullWidth
                  onClick={handleForceReload}
                >
                  Continue
                </StandardButton>
              </Stack>
            )
          }
          {
            /* Case account provisioning failed */
            justLoggedIn && provisioningFailed && (
              <Stack>
                <Helmet>
                  <title>Failed to set up account - Moonsift</title>
                </Helmet>
                <Typography variant="primary.b26" component="h1">
                  Failed to set up account - please contact us at
                  hello@moonsift.com
                </Typography>
              </Stack>
            )
          }
        </div>
      </Page>
    </>
  );
};

export const NotProvisioned = styled(UnstyledNotProvisioned)`
  .NotProvisioned-buttonRow {
    width: 100%;
  }

  .NotProvisioned-content {
    margin: 0 auto;
    max-width: 576px;
    min-height: 200px;
    display: flex;
    align-content: center;
    justify-content: center;
    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 608 })} {
      margin: 0 16px;
    }
    text-align: center;
  }

  .NotProvisioned-supplementaryText {
    margin-top: 26px;
  }

  .NotProvisioned-settingUpLoadingDots {
    margin: 20px;
  }
`;
