/** Merchant IDs for popular merchants. */
export const popularAffiliateBrandsIds = [
  38003, 58596, 6537, 791, 69198, 4873, 71336, 5475, 53623, 4756, 49410, 55493,
  62155, 30943, 37020, 11638, 1270, 61829, 65713, 79759, 89032, 6884, 49696,
  47248, 28117, 104, 9548, 21552, 6432, 4886, 42409, 1459, 12917, 48290, 49670,
  2861, 58605, 31064, 68891, 32389, 191, 53804, 49974, 23982, 38472, 60937,
  22082, 39320, 67231, 82590, 11035, 899, 46669, 34731, 9050, 17126, 2908,
  26902, 26000, 9509, 79317, 60838, 36437, 12344, 5660, 54584, 3616, 7384, 4287,
  15229, 4198, 26925, 50362, 11420, 78998, 76526, 79809, 23957, 10758, 60346,
  58209, 4881, 9595, 32666, 25398, 59456, 58160, 46054, 9136, 10699, 4005,
  51547, 69567, 1896, 56389, 68144, 694, 2063, 10071, 39879, 14674, 93173,
  39576, 53789, 13777, 81, 4980, 63370, 49709, 42552, 40188, 310, 11703, 39099,
  4023, 6162, 71047, 65967, 9442, 35426, 7139, 49102, 13265, 38357, 9540, 88809,
  37399, 86410, 2087, 28842, 848, 88769, 68105, 9000, 8927, 68542, 27591, 66486,
  4878, 11531, 7060, 78792, 26003, 8910, 12922, 6360, 4270, 49717, 63434, 7252,
  11940, 2095, 16498, 349, 72174, 97419, 5473, 4439, 775, 3720, 6201, 12711,
  5553, 17683, 51350, 68726, 67749, 230, 84211, 84210, 62920, 27, 58336, 58335,
  723, 20542, 3202, 33003, 33131, 5662, 74682, 139, 71437, 1034, 109, 34982,
  42046, 45309, 10224, 13053, 16326, 5189, 196, 46070, 50363, 5910, 29645,
  56172, 53225, 86224, 4796, 5203, 47104, 43145, 2853, 13522, 54063, 426, 2539,
];
