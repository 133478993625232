import { VFC } from 'react';
import styled from 'styled-components';
import {
  Stack,
  CtaButton,
  ctaButtonClassNames,
  moveButtonClassNames,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { AllCreatorsProps } from './AllCreators.types';

const UnstyledAllCreators: VFC<AllCreatorsProps> = ({
  className,
  style,
  onContentCreator,
  onStylist,
}) => {
  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Stack
        className="AllCreators-buttons"
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={20}
      >
        <CtaButton variant="primary-white" onClick={onContentCreator}>
          I'm a Content Creator
        </CtaButton>
        <CtaButton variant="primary-white" onClick={onStylist}>
          I'm a Stylist or Personal Shopper
        </CtaButton>
      </Stack>
    </Page>
  );
};

export const AllCreators = styled(UnstyledAllCreators)`
  height: 100vh;
  background: var(--common-black);

  .Page-navControls {
    margin-bottom: 90px;

    .${moveButtonClassNames.button} {
      background: var(--common-white);
    }
  }

  .AllCreators-buttons {
    padding: 0 20px;

    .${ctaButtonClassNames.variantPrimaryWhite} {
      width: min(100vw - 20px - 20px, 320px);
    }
  }
`;
