import clsx from 'clsx';
import { VFC } from 'react';

import { EXTERNAL } from 'src/routes';
import styled from 'styled-components';
import { AppStoreLinkButtonProps } from './AppStoreLinkButton.types';
import { Stack, Typography } from '@moonsifttech/design-system';
import { FiveStars } from 'src/components/common/FiveStars';

export const UnStyledAppStoreLinkButton: VFC<AppStoreLinkButtonProps> = ({
  className,
  style,
  onAppDownload,
  creator,
  includeRating,
}) => {
  return (
    <Stack direction="column" className={className} style={style}>
      <a
        className={clsx(className, 'AppStoreLinkButton-anchor')}
        href={
          creator ? EXTERNAL.APP_STORE_APP_CREATORS : EXTERNAL.APP_STORE_APP
        }
        target="_blank"
        rel="noreferrer"
        onClick={onAppDownload}
      >
        <img
          className="AppStoreLinkButton-appStoreButton"
          src={`${process.env.REACT_APP_STATIC_URL}/app-landing/app-store-download.svg`}
          alt="Download on the App Store"
        />
      </a>
      {includeRating && (
        <Stack
          direction="row"
          className="AppStoreLinkButton-appRatingContainer"
          spacing={4}
        >
          <FiveStars />
          <Typography
            variant="primary.r12"
            component="p"
            align="left"
            className="AppStoreLinkButton-appRating"
          >
            4.7
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export const AppStoreLinkButton = styled(UnStyledAppStoreLinkButton)`
  display: flex;
  justify-content: center;
  .AppStoreLinkButton-appStoreButton {
    height: 54px;
    cursor: pointer;
    margin-bottom: 8px;
  }
  .AppStoreLinkButton-appRating {
    color: var(--grey-3);
    margin-bottom: 0px;
    margin-top: 4px;
  }
  AppStoreLinkButton-appRatingContainer {
  }
`;
