import { UserDataAction, ActionNames, UserDataState } from 'src/types';
/*

Just stores auth data directly from auth service (not the database!)

*/
const USER_DATA_DEFAULT: UserDataState = {
  is_signing_in: false,
  block_loading_user_data: false,
  run_setup_again: false,
};

export const userData = (
  state = USER_DATA_DEFAULT,
  action: UserDataAction,
): UserDataState => {
  switch (action.type) {
    case ActionNames.BEGAN_SIGN_IN:
      return {
        ...state,
        is_signing_in: true,
      };
    case ActionNames.BLOCK_LOADING_USER_DATA:
      return {
        ...state,
        block_loading_user_data: true,
      };
    case ActionNames.UNBLOCK_LOADING_USER_DATA:
      return {
        ...state,
        block_loading_user_data: false,
        run_setup_again: false,
      };
    case ActionNames.SET_RUN_SETUP_AGAIN:
      return {
        ...state,
        run_setup_again: true,
      };
    case ActionNames.SUCCESSFULLY_LOADED_PROVISIONED_USER_DATA:
      return {
        ...state,
        is_signing_in: false,
      };
    case ActionNames.UNSET_ALL:
      return USER_DATA_DEFAULT;
    default:
      return state;
  }
};
