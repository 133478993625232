/*

Make into a REDUX QUERY,

Need to be careful on replacing this as the query will also take an initial document in some cases.
Will need a way to ADD to a list without removing current ones
(might we need a new reducer action for this which appends to current list OR we could hack with
incrementing storeAs with a number for each query if stored as a map)?
*/

// GETS REACTIONS + COMMENTS!
export default function get_reactions_and_comments(
  product_uid,
  collection_uid,
  collection_user_uid,
  doc, // initial document!
  increment,
  firebase
){
  return new Promise (
    (resolve)=>{
      const comments_list = [];
      var last_doc = false;
      var first_doc = false;
      if (doc!==false){ // may be null...:
        const middle_query = (doc===null)?
        firebase.user_product_collection_reactions_and_comments_list_order_by(
          collection_user_uid, collection_uid, product_uid,increment
        ):firebase.user_product_collection_reactions_and_comments_list_order_by_start_after(
          collection_user_uid, collection_uid, product_uid,increment,doc
        );
        middle_query.get().then(
          snapshot=>{
            snapshot.forEach(
              doc=>{
                const data = doc.data();
                if (first_doc===false){
                  first_doc=doc;
                }
                last_doc = doc;
                // Not entry uid as this was used already...
                data.uid = doc.id;
                comments_list.push(data);
              }
            );
            resolve({
              comments_list,
              last_doc,
              first_doc
            });
          }
        );
      }
      else{
        resolve({
          comments_list,
          last_doc,
          first_doc
        });
      }
    }
  );
}
