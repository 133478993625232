import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { checkIfIOS } from 'src/utils/checkIfIOS';
import { RouterSwitch } from './RouterSwitch.component';
import { RouterSwitchContainerProps } from './RouterSwitch.types';
const isIOS = checkIfIOS();
const Container: React.FC<RouterSwitchContainerProps> = () => {
  const { mobile } = useContext(ServicesContext);
  const isApp = mobile.isApp;
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserSet = useSelector<RootState, boolean>(
    (state) => state.auth.set,
  );
  const displayUsername = useSelector<RootState, string>((state) =>
    state.firestore_user_owner
      ? state.firestore_user_owner.display_username
      : undefined,
  );
  const userDataLoaded = useSelector<RootState, true | undefined>(
    (state) => state.firebasestate.loaded,
  );
  const provisioned = useSelector<RootState, true | undefined>((state) =>
    state.firestore_user_owner
      ? state.firestore_user_owner.provisioned
      : undefined,
  );
  const keepOnNotProvisioned = useSelector(
    (state: RootState) => state.ui.keepOnProvisioningPage,
  );

  const showNotProvisioned =
    keepOnNotProvisioned ||
    (authUserUID !== null && userDataLoaded === true && provisioned !== true);

  return (
    <RouterSwitch
      {...{
        authUserUID,
        authUserSet,
        displayUsername,
        showNotProvisioned,
        isApp,
        isIOS,
      }}
    />
  );
};

export { Container as RouterSwitch };
