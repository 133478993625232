import React from 'react';
import styled, { keyframes } from 'styled-components';

import { SelfDrawingArrowProps } from './SelfDrawingArrow.types';

const UnstyledSelfDrawingArrow: React.FC<SelfDrawingArrowProps> = ({
  className,
  style,
  ...otherProps
}) => {
  return (
    <svg
      className={className}
      style={style}
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="draw"
        d={`
          M 12.1359 40.507
          C 25.7238 33.5214, 29.6656 24.6076, 29.8948 3.14644
          L 25.0776 8.12851
          L 33.0717 8.48435
          L 29.8948 3.14644
      `}
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...otherProps}
      />
    </svg>
  );
};

const drawKeyframe = keyframes`
  1% {
    stroke-dashoffset: 400;
  }

  100% {
    stroke-dashoffset: 0;
  }
`;

export const SelfDrawingArrow = styled(UnstyledSelfDrawingArrow)`
  .draw {
    stroke-dasharray: 400;
    stroke-dashoffset: 0;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-name: ${drawKeyframe};
    animation-iteration-count: infinite;
    animation-delay: 4.5s;
  }
`;
