import { useCallback, useState, useRef } from 'react';
import { DB, PartialWithFieldValue } from 'src/services/DB';

/**
 * This hook is used to set a document in the database, returns a function and
 * working flag.
 */
export function useDBSetDoc<T>(
  dB: DB,
  dBLocation: string, // document reference
): [(data: PartialWithFieldValue<T>) => Promise<void>, boolean] {
  const promiseRef = useRef<Promise<void>>();
  const [working, setWorking] = useState(false);
  const dBSetDoc = useCallback(
    async (data: PartialWithFieldValue<T>) => {
      if (working) {
        return promiseRef.current!;
      }

      setWorking(true);

      const newPromise = dB.setDoc(dBLocation, data).then((result) => {
        setWorking(false);
        return result;
      });

      promiseRef.current = newPromise;

      return newPromise;
    },
    [dB, dBLocation, working],
  );
  return [dBSetDoc, working];
}
