/*

Signle product data of items that appears in collections.
It's made from processed / parsed data from various database documents and stored here for convenience.

*/

import { ProductDataAction, ActionNames, ProductDataState } from 'src/types';

const PRODUCT_DATA_DEFAULT: ProductDataState = null;

export const productData = (
  state: ProductDataState = PRODUCT_DATA_DEFAULT,
  action: ProductDataAction,
): ProductDataState => {
  if (action.type === ActionNames.PRODUCT_DATA_ADD) {
    return {
      ...state,
      [action.payload.storeAs]: action.payload.productData,
    };
  } else if (action.type === ActionNames.PRODUCT_DATA_REMOVE) {
    const stateOut = {
      ...state,
    };
    delete stateOut[action.payload];
    return stateOut;
  } else if (action.type === ActionNames.UNSET_ALL) {
    return PRODUCT_DATA_DEFAULT;
  }
  return state;
};
