import Cookies from 'universal-cookie';
import { Constants } from 'src/constants';
import { Cloud } from 'src/services/Cloud';
import { Mobile } from 'src/services/Mobile';
import { getRandomUID } from 'src/utils/getRandomUID';
import { load as loadEnzuzo } from 'src/vendor/enzuzo';

type Mixpanel = {
  track: (eventName: string, params?: Record<string, unknown>) => void;
  identify: (userUID: string) => void;
  reset: () => void;
  people: { set: (key: string, value: unknown) => void };
};
type Fbq = (
  action: string,
  eventName: string,
  params?: Record<string, unknown>,
) => void;
type GTagArgs = {
  set: [Record<string, unknown>, undefined];
  event: [string, Record<string, unknown>];
  consent: [string, Record<string, unknown>];
};
type Gtag = <T extends keyof GTagArgs>(
  action: T,
  eventName: GTagArgs[T][0],
  options?: GTagArgs[T][1],
) => void;

declare global {
  interface Window {
    mixpanel: Mixpanel;
    fbq: Fbq;
    gtag: Gtag;
  }
}

// Get the landing page on load from cookie if visited before, otherwise set cookie:
const cookies = new Cookies();
const LANDING_PAGE_READ_FROM_COOKIES = cookies.get(
  Constants.LANDINGPAGE_COOKIE_KEY,
);
if (!LANDING_PAGE_READ_FROM_COOKIES) {
  cookies.set(Constants.LANDINGPAGE_COOKIE_KEY, window.location.href, {
    path: Constants.COOKIEPATH,
  });
}
const LANDING_PAGE = LANDING_PAGE_READ_FROM_COOKIES ?? window.location.href;

const CLIENT_IDENTIFIER_READ_FROM_COOKIES = cookies.get(
  Constants.CLIENT_IDENTIFIER_COOKIE_KEY,
);
const CLIENT_IDENTIFIER = CLIENT_IDENTIFIER_READ_FROM_COOKIES ?? getRandomUID();
if (!CLIENT_IDENTIFIER_READ_FROM_COOKIES) {
  cookies.set(Constants.CLIENT_IDENTIFIER_COOKIE_KEY, CLIENT_IDENTIFIER, {
    path: Constants.COOKIEPATH,
  });
}

// Analytics service
export class Analytics {
  cloud: Cloud;
  mobile: Mobile;
  clientIdentifier: string;

  public constructor(cloud: Cloud, mobile: Mobile) {
    this.clientIdentifier = CLIENT_IDENTIFIER;
    this.cloud = cloud;
    this.mobile = mobile;
    this.recordEvent = this.recordEvent.bind(this);
    this.reportPageView = this.reportPageView.bind(this);
  }

  public recordEvent(eventName: string, params?: any) {
    // Moonsift:
    this.recordEventMoonsift(eventName, params);
    // Google Analytics:
    window.gtag('event', eventName, params);

    if (window.fbq != null) {
      // Facebook Pixel:
      window.fbq('track', eventName, params);
    }

    if (window.mixpanel != null) {
      window.mixpanel.track(eventName, params);
    }
  }

  public reportPageView(path: string) {
    if (window.mixpanel != null) {
      window.mixpanel.track(`pageview ${path}`);
    }
    this.recordEventMoonsift('pageview', { path });
  }

  public identifyUser(authUserUID: string | null) {
    if (authUserUID !== null) {
      // Bonkers hack to accept Enzuzo, if banner has been shown but user then logs in. This achieves:
      //  1, makes the banner go away
      //  2, gets Enzuzo to reinstate the cookies it blocked
      //  3, we won't show the banner again next time, since they logged in and "accepted"
      const acceptButton = document.getElementById('ez-cookie-notification__accept');
      if (acceptButton) {
        acceptButton?.click();
      }

      // Enables all tracking permissions for a logged in user in case this is a journey where Enzuzo banner never happened
      // NOTE: Calling below with all permissions granted,
      // will also load in Mixpanel and Meta Pixel (load scripts in index.html)
      window.gtag('consent', 'update', {
          'ad_storage': 'granted',
          'ad_user_data': 'granted',
          'ad_personalization': 'granted',
          'analytics_storage': 'granted',
          'personalization_storage': 'granted',
          'functionality_storage': 'granted',
          'security_storage': 'granted',
      });
      window.gtag('set', {
        user_id: authUserUID,
        debug_mode: true,
      });

      if (window.mixpanel != null) {
        window.mixpanel.identify(authUserUID);
      }
    } else {
      // If the user is unauthenticated, we need to load the cookie banner
      loadEnzuzo();
    }
  }

  public recordEventMoonsift(name: string, params?: Record<string, any>) {
    this.cloud
      .fastAPI2('recordEvent', {
        params,
        name,
        page_url: window.location.href,
        referrer: document.referrer,
        is_app: this.mobile.isApp,
        landing_page: LANDING_PAGE,
        client_timestamp: new Date(),
        client_identifier: CLIENT_IDENTIFIER,
      })
      .catch((err) => console.log('Could not log event:', err));
  }

  public logout() {
    if (window.mixpanel != null) {
      window.mixpanel.reset();
    }
  }
}
