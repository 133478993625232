/*

Horizontal implementation of the carousel for viewing collections

TODO: Convert to typescript, functional, component + container, camelCase, reduce number of functions too...

*/
import React from 'react';
import BasicCarousel from './BasicCarousel';
import CollectionTile from 'src/mvp22/tile-components/CollectionTile';
import styled from 'styled-components';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {M} from 'src/mvp22/constants';
import windowSize from 'src/mvp22/WindowSize';
import {withFirebase} from 'src/mvp22/Firebase';

const CAROUSEL_SCROLL_DISTANCE = 351;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const CarouselContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position:relative;
  max-width: 990px;
  flex-direction: column;
`;

const CarouselArrow = styled(TEXTSTYLE.LINKA)`
  display: ${props => props.visible ? 'block' : 'none'};
  position: absolute;
  color: #111;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  z-index: 10;
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: .5;
  }
  &--left {
    left: 32px;
  }
  &--right {
    right: 32px;
  }
  top: 116px;
`;

const CarouselArrowLeft = styled(CarouselArrow)`
  left: 0px;
`;

const CarouselArrowRight = styled(CarouselArrow)`
  right: 0px;
`;

const CarouselSlideContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-type: x mandatory;
  margin-left: ${props => props.isDesktop === true ? '0px' : '20px'};
  margin-right: ${props => props.isDesktop === true ? '20px' : '10px'};
`;

const CarouselSlideInner = styled.div`
  display: flex;
  width: 100%;
`;

const CarouselArrowRightImg = styled.img`
`;

const CarouselArrowLeftImg = styled.img`
  transform: scaleX(-1);
`;

const CarouselSlide = styled.div`
  scroll-snap-align: start;
`;

class CollectionCarousel extends BasicCarousel {

  constructor(){
    super();

    this.scrollContainerLeft = this.scrollContainerLeft.bind(this);
    this.scrollContainerRight = this.scrollContainerRight.bind(this);
    this.container = null;
  }

  componentDidMount(){
    super.componentDidMount();
    this.setScrollMultiplier();
  }

  // Amount to scroll depends on screen width:
  setScrollMultiplier(){
    if(this.props.windowWidth>=M.MOBILESWITCH){
      this.setState({
        scroll_multiplier: 3
      });
    } else {
      this.setState({
        scroll_multiplier: 1
      });
    }
  }

  scrollContainer(event,multiplier) {
    event.preventDefault();
    this.container.scrollBy(
      { left: multiplier*CAROUSEL_SCROLL_DISTANCE, behavior: 'smooth' }
    );
    this.componentDidUpdate();
    return false;
  }

  scrollContainerLeft(event) {
    this.scrollContainer(event,-1*this.state.scroll_multiplier);
  }

  scrollContainerRight(event) {
    this.scrollContainer(event,1*this.state.scroll_multiplier);
  }

  render() {
    if (this.props.collections_list && this.props.collections){
      const ISDESKTOP = this.props.windowWidth>=M.MOBILESWITCH;
      return (
        <CarouselContainer className='CarouselContainer'>
        <CarouselArrowRight href="#" onClick={this.scrollContainerRight} className='CarouselArrowRight' visible={this.state.hasOverflow && this.state.canScrollForward}>
          <CarouselArrowRightImg src={MEDIA.CAROUSEL_ARROW_RIGHT}/>
        </CarouselArrowRight>
        <CarouselArrowLeft href="#" onClick={this.scrollContainerLeft} className='CarouselArrowLeft' visible={this.state.hasOverflow && this.state.canScrollBack}>
          <CarouselArrowLeftImg src={MEDIA.CAROUSEL_ARROW_RIGHT}/>
        </CarouselArrowLeft>
            <CarouselSlideContainer isDesktop={ISDESKTOP} ref={node =>{this.container = node;}} className='carouselSlideContainer'>
               <CarouselSlideInner className='carouselSlideInner'>
                  {
                    (this.props.collections_list.length>0)?
                      this.props.collections_list.map((general_uid,index) => {
                        return(
                          <CarouselSlide key={index}>
                            <CollectionTile
                             className='FeaturedTile'
                             data={this.props.collections[general_uid]}
                             user_public_info={
                               (this.props.public_info && this.props.collections &&
                                this.props.collections[general_uid] && this.props.collections[general_uid].collection_entry)?
                                this.props.public_info[this.props.collections[general_uid].collection_entry.user_uid]:""
                              }
                             isLarge={false}
                             isCarouselTile={true}
                             tileIndex={index}
                             display_name={true}
                            />
                            <Seperator height='42px'/>
                          </CarouselSlide>
                      );
                    })
                  :""}
                </CarouselSlideInner>
              </CarouselSlideContainer>
        </CarouselContainer>
      );
    }else{
      return "";
    }
  }
}

export default withFirebase(windowSize(CollectionCarousel));
