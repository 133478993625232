import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledTextField,
  textFieldClassNames,
  Stack,
  FormHelperText,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';

import { ModalContent } from 'src/components/core/ModalContent';
import { AddCashFundDialogProps } from './AddCashFundDialog.types';

const UnstyledAddCashFundDialog = ({
  className,
  style,
  errorMessage,
  loading,
  initialFormValues,
  formValidationSchema,
  onSubmit,
  onClose,
  onReset,
  isMine,
  isRevealing,
}: AddCashFundDialogProps) => {
  return (
    <ModalContent className={className} style={style} onClose={onClose}>
      <Typography variant="primary.b26" component="h1">
        {isMine && !isRevealing ? 'Add a cash fund' : initialFormValues.name}
      </Typography>
      {
        isMine && !isRevealing && (
          <Typography variant="primary.r16" component="p">
            People's transfers will be made directly to the account you provide, so no fees. Moonsift does not hold any funds.
          </Typography>
        )
      }
      {
        isMine && !isRevealing ? (
          <Formik
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={onSubmit}
            onReset={onReset}
          >
            {
              ({ dirty, isSubmitting, isValid }) => {
                const canReset = initialFormValues.name !== ''
                  && isMine
                  && !isRevealing
                  && !isSubmitting;

                return (
                  <Form>
                    <label htmlFor="name">
                      <Typography variant="primary.r14" component="p" className="AddCashFundDialog-label">
                        Name your fund
                      </Typography>
                    </label>
                    <ControlledTextField
                      name="name"
                      rows={4}
                      placeholder={'e.g. Newlywed or newborn fund'}
                      fullWidth
                    />

                    <label htmlFor="notes">
                      <Typography variant="primary.r14" component="p" className="AddCashFundDialog-label">
                        Note to family & friends (optional)
                      </Typography>
                    </label>
                    <ControlledTextField
                      name="notes"
                      multiline
                      rows={4}
                      placeholder={'e.g. more on how the funds will be used'}
                      maxLength={250}
                      fullWidth
                    />

                    <label htmlFor="notes">
                      <Typography variant="primary.r14" component="p" className="AddCashFundDialog-label">
                        Where should people send funds?
                      </Typography>
                    </label>
                    <ControlledTextField
                      name="details"
                      multiline
                      rows={4}
                      placeholder={'e.g. your bank details or other payment info'}
                      maxLength={250}
                      fullWidth
                    />

                    <Stack
                      direction="row"
                      justifyContent={canReset ? 'space-between' : 'flex-end'}
                      spacing={12}
                    >
                      {
                        canReset && (
                          <StandardButton
                            type="reset"
                            variant="primary-white"
                            loading={loading}
                            disabled={initialFormValues.name === ''}
                          >
                            Remove cash fund
                          </StandardButton>
                        )
                      }
                      <StandardButton
                        type="submit"
                        variant="primary-white"
                        loading={loading}
                        disabled={!dirty || !isValid}
                      >
                        Confirm
                      </StandardButton>
                    </Stack>
                    {errorMessage && (
                      <Stack direction="row" justifyContent="flex-end">
                        <FormHelperText
                          className="AddCashFundDialog-errorMessage"
                          error
                        >
                          {errorMessage}
                        </FormHelperText>
                      </Stack>
                    )}
                  </Form>
                );
              }
            }
          </Formik>
        ) : (
          <>
            <Typography variant="primary.l20" component="p" className="AddCashFundDialog-guestNotes">
              {initialFormValues.notes}
            </Typography>
            <Typography variant="primary.r16" component="p" className="AddCashFundDialog-guestExplainer">
              Please use the instructions below if you'd like to make a contribution:
            </Typography>
            <div className="AddCashFundDialog-guestDetailsWrapper">
              <Typography variant="primary.r14" component="p" className="AddCashFundDialog-guestDetails">
                {initialFormValues.details}
              </Typography>
            </div>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={12}
            >
              <StandardButton
                variant="primary-white"
                onClick={onClose}
              >
                Close
              </StandardButton>
            </Stack>
          </>
        )
      }
    </ModalContent>
  );
};

export const AddCashFundDialog = styled(UnstyledAddCashFundDialog)`
  .${textFieldClassNames.root} {
    margin-bottom: 32px;
  }

  .AddCashFundDialog-errorMessage {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .${textFieldClassNames.root} {
      margin-bottom: 46px;
    }
  }

  .AddCashFundDialog-label {
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.fns.getColor('grey.3')};
  }

  .AddCashFundDialog-guestNotes {
    font-style: italic;
    white-space: pre-wrap;
    margin-bottom: 2rem;
  }

  .AddCashFundDialog-guestExplainer {
    margin-bottom: 12px;
  }

  .AddCashFundDialog-guestDetailsWrapper {
    padding: 12px;
    margin-bottom: 40px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.fns.getColor('grey.7')};
  }

  .AddCashFundDialog-guestDetails {
    white-space: pre-wrap;
  }
  
`;
