import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Stack, Typography } from '@moonsifttech/design-system';

import { CreatorLandingCtaButton } from 'src/components/creatorLanding/CreatorLandingCtaButton';
import { CreatorLandingSectionProps } from './CreatorLandingSection.types';

const UnstyledCreatorLandingSection: VFC<CreatorLandingSectionProps> = ({
  className,
  style,
  heading,
  subheading,
  imagePath,
  imageAlt,
  subsectionHeading,
  subsectionParagraph,
  isDark = false,
  eventName,
}) => {
  return (
    <section
      className={clsx(className, isDark && 'CreatorLandingSection-isDark')}
      style={style}
    >
      <Stack className="CreatorLandingSection-container" alignItems="center">
        <Typography variant="editorial.r68" component="h2" align="center">
          {heading}
        </Typography>
        <hr />
        <Typography
          className="CreatorLandingSection-subheading"
          variant="editorial.sb34"
          component="p"
          align="center"
        >
          {subheading}
        </Typography>
        <hr />
        <div className="CreatorLandingSection-innerContainer">
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/creators-landing/${imagePath}.png`}
            />
            <img
              alt={imageAlt}
              src={`${process.env.REACT_APP_STATIC_URL}/creators-landing/${imagePath}.2x.png`}
            />
          </picture>
          <div className="CreatorLandingSection-subsection">
            <Typography variant="primary.b34" component="h3">
              {subsectionHeading}
            </Typography>
            <Typography variant="primary.r16" component="p">
              {subsectionParagraph}
            </Typography>
            <CreatorLandingCtaButton eventName={eventName} />
          </div>
        </div>
      </Stack>
    </section>
  );
};

export const CreatorLandingSection = styled(UnstyledCreatorLandingSection)`
  padding: 72px 20px 76px;

  .CreatorLandingSection-container {
    width: min(100vw - 20px - 20px, 700px);
    margin: 0 auto;
  }

  h2 {
    margin-bottom: 24px;
  }

  hr {
    display: none;
  }

  .CreatorLandingSection-subheading {
    margin-bottom: 44px;
  }

  .CreatorLandingSection-innerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .CreatorLandingSection-subsection {
    max-width: 320px;
  }

  img {
    display: block;
    width: min(100vw - 20px - 20px, 320px);
    margin-bottom: 40px;
  }

  h3 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 20px;
  }

  &.CreatorLandingSection-isDark {
    background: var(--common-black);

    h2,
    p,
    h3 {
      color: var(--common-white);
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 96px 20px 116px;

    hr {
      display: block;
      width: 100%;
      margin: 0 0 20px;
      border-top: 1px solid var(--common-black);
      border-bottom: none;

      &:nth-of-type(2) {
        margin-bottom: 48px;
      }
    }

    h2 {
      margin-bottom: 32px;
    }

    .CreatorLandingSection-subheading {
      margin-bottom: 24px;
    }

    .CreatorLandingSection-innerContainer {
      width: 100%;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    img {
      margin-bottom: 0;
    }

    &.CreatorLandingSection-isDark {
      hr {
        border-top: 1px solid var(--common-white);
      }
    }
  }
`;
