import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledCheckbox,
  FormControlLabel,
  formControlLabelClassNames,
  Form,
  ControlledFormGroup,
  Stack,
  Link,
} from '@moonsifttech/design-system';

import { professions } from 'src/types/models/stylistApplication.model';
import { StylistApplicationOneProps } from './StylistApplicationOne.types';
import R from 'src/routes';

const UnstyledStylistApplicationOne: VFC<StylistApplicationOneProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  return (
    <div className={className} style={style}>
      <div className="StylistApplicationOne-content">
        <Typography
          className="StylistApplicationOne-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Which of the following best represents you?
        </Typography>
        <Typography variant="primary.l20" component="p">
          You can choose more than one
        </Typography>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <ControlledFormGroup
            className="StylistApplicationOne-checkboxes"
            name="professions"
          >
            {professions.map((profession, i) => (
              <FormControlLabel
                key={i}
                label={profession}
                labelPlacement="end"
                value={profession}
                control={<ControlledCheckbox name="professions" />}
              />
            ))}
          </ControlledFormGroup>
          <StandardButton type="submit" variant="primary-black">
            Next
          </StandardButton>
        </Form>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const StylistApplicationOne = styled(UnstyledStylistApplicationOne)`
  padding: 100px 0 0;

  .StylistApplicationOne-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .StylistApplicationOne-heading {
    margin-bottom: 8px;
  }

  .StylistApplicationOne-checkboxes {
    display: flex;
    margin-bottom: 24px;

    .${formControlLabelClassNames.root} {
      margin-bottom: 4px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
