import {
  BaseDBFetchingDict,
  BaseDBKindNames,
} from 'src/types/models/basedb.model';
/*

Utility functions for adding, removing and checking storeAs keys from maps in the sagas that prevent data being retrieved more than once until it is cleared.

*/
export function addToFetchingDict(
  kind: BaseDBKindNames,
  storeAs: string | undefined,
  fetchingDict: BaseDBFetchingDict,
): void {
  if (storeAs !== undefined) {
    const entry = fetchingDict[kind];
    if (typeof entry === 'object') {
      entry[storeAs] = true;
    } else {
      fetchingDict[kind] = {
        [storeAs]: true,
      };
    }
  } else {
    fetchingDict[kind] = true;
  }
}

export function deleteFromFetchingDict(
  kind: BaseDBKindNames,
  storeAs: string | undefined,
  fetchingDict: BaseDBFetchingDict,
): void {
  if (storeAs !== undefined) {
    const entry = fetchingDict[kind];
    const new_entry = typeof entry === 'object' ? entry : {};
    delete new_entry[storeAs];
  } else {
    delete fetchingDict[kind];
  }
}

export function checkFetchingDict(
  kind: BaseDBKindNames,
  storeAs: string | undefined,
  fetchingDict: BaseDBFetchingDict,
): boolean {
  if (storeAs !== undefined) {
    const entry = fetchingDict[kind];
    return typeof entry === 'object' && entry[storeAs];
  } else {
    return fetchingDict[kind] === true;
  }
}
