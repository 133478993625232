/*

Adds titles to pages

TODO: Move to core components

*/
import React from 'react';
import {Helmet} from "react-helmet";
export function AddHelmetTitle(title){
  return (
    <Helmet>
      <title>{title} - Moonsift</title>
    </Helmet>
  );
}
