import { FC, useContext } from 'react';
import { Link as RoutingLink } from 'react-router-dom';
import { Link as UiLink } from '@moonsifttech/design-system';

import { LinkProps } from './Link.types';
import { ServicesContext } from 'src/ServicesContext';

// This Link component just uses React Router's own Link as base when the
// provided `to` points to an internal page and uses the default HTML anchor
// element otherwise.
// This will avoid having to import both our design system Link and React
// Router's every time we need a Link.
export const Link: FC<LinkProps> = ({ to, ...otherProps }) => {
  const isExternal = to.includes('//');
  const { analytics } = useContext(ServicesContext);
  if (isExternal) {
    const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
      analytics.recordEvent('WebApp:ExternalLink:Pressed', { to });
      if (otherProps.onClick) {
        otherProps.onClick(event);
      }
    };
    return <UiLink to={to} onClick={onClick} {...otherProps} />;
  }

  return <UiLink to={to} component={RoutingLink} {...otherProps} />;
};
