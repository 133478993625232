import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';

import R, { EXTERNAL } from 'src/routes';
import { Page } from 'src/components/common/Page';
import { Link } from 'src/components/core/Link';
import { EarningsSummaryCard } from 'src/components/creator/EarningsSummaryCard';
import { EarningsTable } from 'src/components/creator/EarningsTable';
import { InviteCreators } from 'src/components/creator/InviteCreators';
import { AcceptedCreatorDashboardProps } from './AcceptedCreatorDashboard.types';
import { HowToVideo } from 'src/components/creator/HowToVideo';

const UnstyledAcceptedCreatorDashboard: VFC<AcceptedCreatorDashboardProps> = ({
  className,
  style,
}) => {
  return (
    <Page
      className={className}
      style={style}
      hug="edge"
      selected={R.HOME}
      showNavControls
    >
      <Stack className="AcceptedCreatorDashboard-container">
        <Stack className="AcceptedCreatorDashboard-headingContainer">
          <Typography
            className="AcceptedCreatorDashboard-heading"
            variant="editorial.sb34"
            align="center"
            component="h1"
          >
            My Earnings
          </Typography>
          <Typography
            className="AcceptedCreatorDashboard-paragraph"
            variant="primary.r14"
            align="center"
            component="p"
          >
            <Link to={R.AFFILIATE_BRANDS}>Browse brands paying commission</Link>
          </Typography>
          <Typography
            className="AcceptedCreatorDashboard-paragraph"
            variant="primary.r14"
            align="center"
            component="p"
          >
            <Link target="_blank" to={EXTERNAL.CREATOR_HOW_IT_WORKS}>
              How it all works
            </Link>
          </Typography>
          <Typography
            className="AcceptedCreatorDashboard-paragraph"
            variant="primary.r14"
            align="center"
            component="p"
          >
            <Link to={R.CREATOR_FAQ}>Support & FAQs</Link>
          </Typography>
        </Stack>
        <EarningsSummaryCard className="AcceptedCreatorDashboard-earningsSummary" />
      </Stack>
      <Stack className="AcceptedCreatorDashboard-wideContainer">
        <EarningsTable className="AcceptedCreatorDashboard-earningsTable" />
      </Stack>
      <Stack className="AcceptedCreatorDashboard-container AcceptedCreatorDashboard-bottomContainer">
        <Stack direction="row" justifyContent="center">
          <hr />
        </Stack>
        <HowToVideo includeExplanation={true} />
        <Stack direction="row" justifyContent="center">
          <hr />
        </Stack>
        <InviteCreators />
      </Stack>
    </Page>
  );
};

export const AcceptedCreatorDashboard = styled(
  UnstyledAcceptedCreatorDashboard,
)`
  min-height: 100vh;
  background: var(--common-white);

  .AcceptedCreatorDashboard-container {
    margin: 0 auto;
    padding: 0 16px;
    max-width: 448px;
  }

  .AcceptedCreatorDashboard-bottomContainer {
    margin-bottom: 40px;
  }

  .AcceptedCreatorDashboard-wideContainer {
    margin: 0 auto;
    padding: 0 16px;
    width: 100%;
  }

  .AcceptedCreatorDashboard-headingContainer {
    .AcceptedCreatorDashboard-heading,
    .AcceptedCreatorDashboard-paragraph {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 40px;
      }
    }
  }

  .AcceptedCreatorDashboard-earningsSummary {
    margin-bottom: 60px;
  }

  .AcceptedCreatorDashboard-earningsTable {
    width: fit-content;
    max-width: 100%;
    margin: 0 auto 60px;

    .MuiTableContainer-root {
      max-height: 600px;
    }
  }

  hr {
    width: 100%;
    max-width: 560px;
    margin: 20px 0 40px;
    border-top: 1px solid var(--grey-4);
    border-bottom: none;
  }
`;
