import { ActionNames, ActionType } from '..';
import { CollectionItem } from './collectionItem.model';
/*

Non-db local redux storage of modal open and data for it to be open.

Note how some are in groups which can be switched between so might require data for all modals in the group when opened!

*/
export enum ModalTypes {
  NewCollection = 'NewCollection',
  DeleteCollection = 'DeleteCollection',
  ProductDoesNotExist = 'ProductDoesNotExist',
  CollectionMembership = 'CollectionMembership',
  CollectionSettings = 'CollectionSettings',
  NewCollectionProduct = 'NewCollectionProduct',
  ProductUpdates = 'ProductUpdates',
  ProductSettings = 'ProductSettings',
  SignUpToView = 'SignUpToView',
  GoToItem = 'GoToItem',
  MarkAsBought = 'MarkAsBought',
  BoughtBy = 'BoughtBy',
  Unmark = 'Unmark',
  UnmarkOwner = 'UnmarkOwner',
  AlreadyBought = 'AlreadyBought',
  MarkedAsBought = 'MarkedAsBought',
  MarkedAsBoughtSlim = 'MarkedAsBoughtSlim',
  MarkedAsBoughtSlimOwner = 'MarkedAsBoughtSlimOwner',
  AddMessage = 'AddMessage',
  DeliveryInfo = 'DeliveryInfo',
  SetQuantity = 'SetQuantity',
}

//
// New collection:
type ModalNewCollection = {
  type: ModalTypes.NewCollection;
  dontRedirect?: boolean;
};

//
// Collection Settings Group (can switch between!)
type ModalCollectionPropertiesBase = {
  collectionName: string;
  collectionUID: string;
  collectionDescription: string;
};

type ModalEditCollection = {
  type: ModalTypes.CollectionSettings;
} & ModalCollectionPropertiesBase;

type ModalDeleteCollection = {
  type: ModalTypes.DeleteCollection;
} & ModalCollectionPropertiesBase;

//
// Product-Collection Membership Group (can switch between!)
type ModalProductCollectionMembershipBase = {
  collectionUID: string;
  collectionUserUID: string;
  collectionItem: CollectionItem;
  productImage: string;
};

type ModalNewCollectionProductCollectionMembership = {
  type: ModalTypes.NewCollectionProduct;
} & ModalProductCollectionMembershipBase;

type ModalProductCollectionMembership = {
  type: ModalTypes.CollectionMembership;
} & ModalProductCollectionMembershipBase;

//
// Product does not exist in collection
type ModalProductDoesNotExist = {
  type: ModalTypes.ProductDoesNotExist;
  closeProductModal: () => void;
};

//
// Product price updates
type ModalProductUpdates = {
  type: ModalTypes.ProductUpdates;
  collectionUID: string;
  collectionUserUID: string;
  itemUID: string;
  productImage: string;
};

//
// Product overview
type ModalProductSettings = {
  type: ModalTypes.ProductSettings;
  collectionUID: string;
  collectionUserUID: string;
  itemUID: string;
  showProductModal: (
    display: boolean,
    thisItemID: string | null,
    increment: number,
  ) => void;
};

type CollectionModal<T extends ModalTypes> = {
  type: T;
  collectionId: string;
  collectionUserId: string;
  itemId: string;
}

type GoToItem = CollectionModal<ModalTypes.GoToItem>;
type MarkAsBought = CollectionModal<ModalTypes.MarkAsBought>;
type BoughtBy = CollectionModal<ModalTypes.BoughtBy>;
type Unmark = CollectionModal<ModalTypes.Unmark>;
type UnmarkOwner = CollectionModal<ModalTypes.UnmarkOwner>;
type AlreadyBought = CollectionModal<ModalTypes.AlreadyBought>;
type MarkedAsBought = CollectionModal<ModalTypes.MarkedAsBought>;
type MarkedAsBoughtSlim = CollectionModal<ModalTypes.MarkedAsBoughtSlim>;
type MarkedAsBoughtSlimOwner = CollectionModal<ModalTypes.MarkedAsBoughtSlimOwner>;
type AddMessage = CollectionModal<ModalTypes.AddMessage>;
type SetQuantity = CollectionModal<ModalTypes.SetQuantity>;

type DeliveryInfo = {
  type: ModalTypes.DeliveryInfo;
  collectionId: string;
  collectionUserId: string;
  back?: Function;
};

//
// Sign up prompt ("Jeremy popup")
type ModalSignUpToView = {
  type: ModalTypes.SignUpToView;
};

//
// No modal shown.
type ModalNull = {
  type: null;
};

export type ModalState =
  | ModalNull
  | ModalNewCollection
  | ModalEditCollection
  | ModalDeleteCollection
  | ModalNewCollectionProductCollectionMembership
  | ModalProductCollectionMembership
  | ModalProductDoesNotExist
  | ModalProductUpdates
  | ModalProductSettings
  | ModalSignUpToView
  | GoToItem
  | MarkAsBought
  | BoughtBy
  | Unmark
  | UnmarkOwner
  | AlreadyBought
  | MarkedAsBought
  | MarkedAsBoughtSlim
  | MarkedAsBoughtSlimOwner
  | AddMessage
  | DeliveryInfo
  | SetQuantity

export type ModalAction =
  | ActionType<ActionNames.MODAL_SET, ModalState>
  | ActionType<ActionNames.MODAL_CLOSE, undefined>;
