/*

Will become redundant

Currently it uses pseudo-db references from the new service objects to save having to rewrite the entire lot!!

*/


// This file sets up the firebase object
//import {firebase} from 'firebase/app';
import {Firebase as FirebaseNew} from 'src/services/Firebase';
import {FirebaseApp } from 'firebase/app';
import {DB} from 'src/services/DB';
import {Timestamp,deleteField,doc,updateDoc,setDoc,collection,addDoc,Firestore, DocumentReference, CollectionReference, collectionGroup, deleteDoc, getDoc, getDocs, onSnapshot, QuerySnapshot, DocumentData, DocumentSnapshot, Query, orderBy, query, startAfter, limit, where} from 'firebase/firestore';
import {Cloud} from 'src/services/Cloud';
import {Functions,httpsCallable} from 'firebase/functions';
import {Auth} from 'src/services/Auth';
import { FacebookAuthProvider,GoogleAuthProvider } from 'firebase/auth';
import {Messaging,getToken} from 'firebase/messaging';
import {Notifications} from 'src/services/Notifications';

class PseudoDoc{
  doc:DocumentReference
  constructor(db:Firestore,documentRef:string){
    this.doc = doc(db, documentRef);
  }

  update = (data:any)=>updateDoc(this.doc,data);
  set = (data:any)=>setDoc(this.doc,data);
  delete = ()=>deleteDoc(this.doc);
  get = ()=>getDoc(this.doc);
  onSnapshot = (callback: (x: DocumentSnapshot<DocumentData>) => void)=>onSnapshot(this.doc,callback);
}

class PseudoCollection{
  collection:CollectionReference
  constructor(db:Firestore,collectionRef:string){
    this.collection = collection(db, collectionRef);
  }

  add = (data:any)=>addDoc(this.collection,data);
  get = ()=>getDocs(this.collection);
  onSnapshot = (callback: (x: QuerySnapshot<DocumentData>) => void)=>onSnapshot(this.collection,callback);

}

class PseudoQuery{
  query:Query
  constructor(query:Query){
    this.query = query;
  }
  get = ()=>getDocs(this.query);
  onSnapshot = (callback: (x: QuerySnapshot<DocumentData>) => void)=>onSnapshot(this.query,callback);

}

export class Firebase {
  app : FirebaseApp
  db : Firestore
  auth : Auth
  messaging: Messaging | null
  functions: Functions
  facebook:FacebookAuthProvider
  google:GoogleAuthProvider
  constructor(app:FirebaseNew,db:DB,auth:Auth,cloud:Cloud,notifications:Notifications) {
    this.app = app.app;
    this.auth = auth;
    this.db = db.fs;
    this.messaging = notifications.messaging;
    this.functions = cloud.functions;
    this.facebook = auth.facebook;
    this.google = auth.google;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email:string, password:string) =>
    this.auth.legacyCreateUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email:string, password:string) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email:string) => this.auth.sendPasswordResetEmail(email);

  sign_in_with_token = (token:string)=>{
    this.auth.signInWithCustomToken(token);
  }

  // NOTIFICATIONS
  getToken = () => (this.messaging)?getToken(this.messaging):null;

  deleteField = ()=>deleteField();

  // update_user_subscriptions => users_update_subscriptions
  // user_sign_up => users_sign_up
  // unsubscribe_from_list => users_unsubscribe_from_list
  // like_collection => likes_saved_likes_change
  fast_api = ()=>{
    return httpsCallable(this.functions,'fast_api-call');
  }

  generate_collection_video = ()=>{
    return httpsCallable(this.functions,'generate_collection_video-call');
  }

  // Firestore redux API:
  user_owner = (user_uid:string) => {
    return new PseudoDoc(this.db,`/databases/user/${user_uid}/owner`);
  };

  user_verified_settings = (user_uid:string) => {
    return new PseudoDoc(this.db,`/databases/user/${user_uid}/verified_settings`);
  };

  user_pro_settings = (user_uid:string) => {
    return new PseudoDoc(this.db,`/databases/user/${user_uid}/pro_settings`);
  };

  user_public_info = (user_uid:string) => {
    return new PseudoDoc(this.db,`/databases/user/${user_uid}/public`);
  };

  user_tastemaker_app = (user_uid:string,app_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_tastemaker_app/${user_uid}/${app_uid}`);
  }

  user_scrape_opinions_doc = (user_uid:string,doc_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_scrape_opinions/${user_uid}/${doc_uid}`);
  }

  user_scrape_collection = (user_uid:string,page_url:string) =>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_scrape/${user_uid}`),where('page_url','==',page_url),orderBy('__name__','desc'),limit(1)));
  }

  supported_sites = () =>{
    return new PseudoDoc(this.db,`/databases/site`);
  }

  trending_sites = () =>{
    return new PseudoDoc(this.db,`/databases/site/trending_lists/trending`);
  }

  featured_sites = () =>{
    return new PseudoDoc(this.db,`/databases/site/trending_lists/featured`);
  }

  user_collection = (user_uid:string, collection_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}`);
  }

  user_product_collection = (user_uid:string, collection_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}`);
  }

  user_product_collection_doc = (user_uid:string, collection_uid:string, doc_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list/${doc_uid}`);
  }

  user_product_collection_list = (user_uid:string, collection_uid:string) =>{
    return new PseudoCollection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list`);
  }

  user_product_collection_list_match_page_url = (user_uid:string, collection_uid:string,page_url:string) =>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list`),where("page_url","==",page_url)));
  }

  user_product_collection_reactions_and_comments_list = (user_uid:string, collection_uid:string, product_uid:string) =>{
    return new PseudoCollection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list/${product_uid}/reactions_and_comments`);
  }

  user_product_collection_reactions_and_comments_list_order_by = (user_uid:string, collection_uid:string, product_uid:string,increment:number) =>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list/${product_uid}/reactions_and_comments`),orderBy("created_at", "desc"),limit(increment)));
  }

  user_product_collection_reactions_and_comments_list_order_by_start_after = (user_uid:string, collection_uid:string, product_uid:string,increment:number,start_after:DocumentReference) =>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list/${product_uid}/reactions_and_comments`),orderBy("created_at", "desc"),startAfter(start_after),limit(increment)));
  }

  user_product_collection_reactions_and_comments_list_user_vote = (user_uid:string, collection_uid:string, product_uid:string, auth_user_uid:string) =>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/list/${product_uid}/reactions_and_comments`),where('user_uid','==',auth_user_uid), where('type','in',['upvote','downvote'])));
  }

  user_product_collection_reactions_and_comments_list_listen = (collection_user_uid:string, collection_uid:string, product_uid:string,start_after:DocumentReference)=>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${collection_user_uid}/${collection_uid}/list/${product_uid}/reactions_and_comments`),orderBy("created_at"),startAfter(start_after)));
  }

  user_product_collection_images = (user_uid:string, collection_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/user_product_collection/${user_uid}/${collection_uid}/sup_data/collection_images`);
  }

  user_product_collection_user_views = (collection_user_uid:string,collection_uid:string,auth_user_uid:string)=>{
    return new PseudoDoc(this.db,`/databases/user_product_collection/${collection_user_uid}/${collection_uid}/view_by_user/${auth_user_uid}`);
  }

  user_product_page_url = (auth_user_uid:string,page_url:string)=>{
    return new PseudoQuery(query(collectionGroup(this.db,`list`),where('user_uid','==',auth_user_uid),where('page_url','==',page_url)));
  }

  user_collection_list = (user_uid:string) => {
    return new PseudoCollection(this.db,`/databases/user_product_collection/${user_uid}`);
  }

  user_collection_list_public = (user_uid:string) => {
    return new PseudoQuery(query(collection(this.db,`/databases/user_product_collection/${user_uid}`),where("public_level","==",10)));
  }

  user_collection_liked = (auth_user_uid:string) => {
    return new PseudoQuery(query(collection(this.db,`/databases/user_likes/${auth_user_uid}/likes/liked_collections`),orderBy("created_at","desc")));
  }

  username = (username:string) =>{
    return new PseudoDoc(this.db,`/databases/username/map/${username}`);
  }

  user_collection_liked_query = (auth_user_uid:string,user_uid:string,collection_uid:string) => {
    const doc_id = user_uid+"_____"+collection_uid;
    return new PseudoDoc(this.db,`/databases/user_likes/${auth_user_uid}/likes/liked_collections/${doc_id}`);
  }

  user_notifications = (auth_user_uid:string)=>{
    return new PseudoCollection(this.db,`/databases/user_notifications/notifications/${auth_user_uid}/notlist`);
  }

  user_notifications_limit10 = (auth_user_uid:string)=>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_notifications/notifications/${auth_user_uid}/notlist`),orderBy("created_at","desc"),limit(10)));
  }

  user_notifications_orderDesc = (auth_user_uid:string,increment:number)=>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_notifications/notifications/${auth_user_uid}/notlist`),orderBy("created_at","desc"),limit(increment)));
  }

  user_notifications_orderDesc_StartAfter = (auth_user_uid:string,doc:DocumentReference,increment:number)=>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_notifications/notifications/${auth_user_uid}/notlist`),orderBy("created_at","desc"),startAfter(doc),limit(increment)));
  }

  user_notifications_order_StartAfter = (auth_user_uid:string,doc:DocumentReference)=>{
    return new PseudoQuery(query(collection(this.db,`/databases/user_notifications/notifications/${auth_user_uid}/notlist`),orderBy("created_at"),startAfter(doc)));
  }

  user_notification_doc = (auth_user_uid:string)=>{
    return new PseudoDoc(this.db,`/databases/user_notifications/notifications/${auth_user_uid}`);
  }

  curated_list = (doc:string)=>{
    return new PseudoDoc(this.db,`/databases/curated_lists/cllists/${doc}`);
  }

  problem = () => {
    return new PseudoCollection(this.db,`/databases/problem/problems`);
  }

  feedback = () => {
    return new PseudoCollection(this.db,`/databases/feedback/feedbacks`);
  }

  product = (product_uid:string) =>{
    return new PseudoDoc(this.db,`/databases/product/products/${product_uid}`);
  }

  serverTimestamp = ()=>{
    return Timestamp.now();
  }

}

export default Firebase;
