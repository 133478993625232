/*

Simple modal to illustrate product has not been found e.g. if deleted from a collection yet notifcation persists.

TODO: typescript, design system.


*/
import React from 'react';
import {useSelector} from 'react-redux';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {useClickOutside} from 'src/hooks/useClickOutside';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import {Separator} from './ModalComponents.js';
import {ButtonBox} from 'src/mvp22/menu-components/SignUpPrompt';
import Button from 'src/mvp22/form-components/Button';

export const GeneralOverlayWithinContainer = styled.div`
  display:flex;
  position:relative;
  width: ${props => props.isDesktop ? '90%' : '100%'};
  height: ${props => props.isDesktop ? '90%' : '100%'};
  max-width: ${props => props.isDesktop ? '716px' : '100%'};
  max-height: ${props => props.isDesktop ? '376px' : '100%'};
  box-sizing:border-box;
  background-color:${M.COL.BG.WHITE};
  border-radius: ${props => props.isDesktop ? '10px' : ''};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: ${M.COL.TEXT.BLACK};
  overflow:auto;
`;

const InnerPopup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
  padding-right: 5%;
  margin-top: 80px;
  max-width:100%;
  box-sizing:border-box;
`;

const Text = styled(TEXTSTYLE.BODY2)`
  text-align: left;
  max-width:100%;
`;

const Heading = styled(TEXTSTYLE.HEADING4)`
  text-align: left;
`;

const ButtonWrapLinkA = styled(TEXTSTYLE.LINKA)`
  width: 100%;
  cursor: pointer;
`;

const ProductNotFoundPopup = (
  {
    closeFunction
  }
) =>{
  const containerRef = useClickOutside(closeFunction);
  const windowWidth = useSelector((state) => state.ui.windowWidth);
  const ISDESKTOP = windowWidth>=M.MOBILESWITCH;
  return(
    <GeneralOverlayWithinContainer isDesktop={ISDESKTOP} className='GeneralOverlayWithinContainer' ref={containerRef}>
      <InnerPopup>
        <Heading>
          This product is no longer in this collection.
        </Heading>
        <Separator height="32px"/>
        <Text>
          It has probably been deleted, although it may still be present in other collections - e.g. if you have moved it since this notification was generated.  Please let us know if you think this is unexpected.
        </Text>
        <ButtonBox>
          <ButtonWrapLinkA onClick={closeFunction}>
            <Button
              text="OK"
              msstyle="prompt_button_light_large"
              width="100%"
              isDesktop={ISDESKTOP}
            />
          </ButtonWrapLinkA>
        </ButtonBox>
      </InnerPopup>
    </GeneralOverlayWithinContainer>
  );
};

export default ProductNotFoundPopup;
