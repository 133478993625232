import { VFC, SyntheticEvent, useState, useCallback } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Prompt } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Typography,
  Stack,
  ShareIcon,
  OrganiseIcon,
  StandardButton,
  CtaButton,
  AddIcon,
  LorryIcon,
  PiggyBankIcon,
  Modal,
  FormControlLabel,
  Checkbox,
  EditIcon,
} from '@moonsifttech/design-system';

import { CollectionNotFound } from 'src/views/CollectionNotFound';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import { Page } from 'src/components/common/Page';
import { ProductTileList } from 'src/components/collection/ProductTileList';
import { ViewCount } from 'src/components/collection/ViewCount';
import { User } from 'src/components/collection/User';
import { SquareButton } from 'src/components/collection/SquareButton';
import { MoreCollectionOptionsButton } from 'src/components/collection/MoreCollectionOptionsButton';
import { EditModal } from 'src/components/collection/EditModal';
import { EditProductDetails } from 'src/components/collection/EditProductDetailsDialog';
import { BookmarkButton } from 'src/components/collection/BookmarkButton';
import { CollectionProps, CollectionModalName } from './Collection.types';
import { CollectionBottomPrompt } from 'src/components/collection/CollectionBottomPrompt';
import { CollectionNotifications } from 'src/components/collection/CollectionNotifications';
import { AddAnItem } from 'src/components/collection/AddAnItem';
import { AddItemsButton } from 'src/components/collection/AddItemsButton';
import { CollectionSharePanel } from 'src/components/collection/CollectionSharePanel';
import { VideoGenerationPanel } from 'src/components/collection/VideoGenerationPanel';
import { AddCashFundDialog } from 'src/components/collection/AddCashFundDialog';
import { useModal, ModalTypes } from 'src/hooks/useModal';

const UnstyledCollection: VFC<CollectionProps> = ({
  className,
  collectionName,
  collectionDescription,
  collectionPublicLevelName,
  collectionViews,
  collectionUID,
  collectionUserUID,
  deliveryInfo,
  cashFund,
  notFound,
  isLoading,
  isLoggedIn,
  isMine,
  isRegistry,
  isEditingProduct,
  isRevealing,
  onEditProductClose,
  onRevealToggle,
  organisingHook,
  // Reuse of the old share button
  isPro,
  isAddItemMenuOpen,
  setIsAddItemMenuOpen,
  nonReceived,
}) => {
  const isOrganising = organisingHook.isOrganising;
  const isSavingOrganising = organisingHook.isSavingOrdering;
  const [modals, manageModals] = useState<Record<CollectionModalName, boolean>>(
    {
      // Note: this will stay static until we remove the old SharePopup
      // and attend old the TODOs in this file
      share: false,
      edit: false,
      notifications: false,
      addAnItem: false,
      generateVideo: false,
      addDeliveryInfo: false,
      addCashFund: false,
    },
  );
  // NOTE: The below is using the modal mechanism driven by Redux
  // Eventually we will want to move all modals onto one way of working and THIS is probably it
  // Historically this file used locally controlled modals coming from react-modal
  // To serve a new flow, I needed to move the DeliveryInfoDialog to use the Redux based approach
  // Not to be confused with the completely separate mechanism used below
  const [ setModal ] = useModal();

  // NOTE: The below is using the locally controlled modals coming from react-modal
  // Eventually we will want to move all modals onto one way of working
  // Not to be confused with the completely separate mechanism used above
  const toggleModal = useCallback(
    (modalName: CollectionModalName) =>
      (event?: SyntheticEvent): void => {
        if (event) event.preventDefault();
        manageModals((prevModals) => {
          return {
            ...prevModals,
            [modalName]: !prevModals[modalName],
          };
        });
      },
    [],
  );

  const onToggleAddItemMenu = useCallback(() => {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    if (isAndroid) {
      toggleModal('addAnItem')();
      return;
    }
    setIsAddItemMenuOpen((state) => !state);
  }, [setIsAddItemMenuOpen, toggleModal]);

  if (notFound) {
    return <CollectionNotFound />;
  }

  const isGuest = !isMine || isRevealing;

  return (
    <Page
      className={clsx(className, isPro && 'Collection-isPro')}
      hug="edge"
      showNavControls
    >
      <Stack
        className="Collection-innerContainer"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          className="Collection-collectionName"
          variant="primary.b34"
          align="center"
          component="h1"
        >
          {collectionName}
          <MoreCollectionOptionsButton
            onShare={toggleModal('share')}
            onEdit={toggleModal('edit')}
            onNotifications={toggleModal('notifications')}
          />
        </Typography>
        {collectionDescription && (
          <Typography
            className="Collection-collectionDescription"
            variant="primary.l16"
            align="center"
            component="p"
          >
            {collectionDescription}
          </Typography>
        )}
        <Stack
          className="Collection-metaInfo"
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={16}
        >
          {collectionViews > 1 && <ViewCount count={collectionViews} />}
          <User />
        </Stack>
        <Stack
          className="Collection-controls"
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          {isOrganising ? (
            <>
              <StandardButton
                className="Collection-organisingSaveButton"
                variant="primary-black"
                onClick={organisingHook.handleOrganising}
                loading={isSavingOrganising}
              >
                Save changes
              </StandardButton>
              <StandardButton
                className="Collection-floatingOrganisingSaveButton"
                variant="primary-black"
                onClick={organisingHook.handleOrganising}
                loading={isSavingOrganising}
              >
                Save changes
              </StandardButton>
              {isPro && (
                <CtaButton
                  className="Collection-organisingSectionButton"
                  size="small"
                  variant="primary-white"
                  startIcon={<AddIcon />}
                  onClick={organisingHook.addSubsection}
                >
                  Section
                </CtaButton>
              )}
            </>
          ) : (
            <>
              {isMine && (
                <SquareButton icon={<EditIcon />} onClick={toggleModal('edit')}>
                  {collectionPublicLevelName}
                </SquareButton>
              )}
              {isMine && (
                <AddItemsButton
                  isAddItemMenuOpen={isAddItemMenuOpen}
                  openAddItemModal={toggleModal('addAnItem')}
                  setIsAddItemMenuOpen={setIsAddItemMenuOpen}
                />
              )}
              {isMine && !nonReceived && (!isRegistry || !isRevealing) && (
                <SquareButton
                  loading={organisingHook.isSavingOrdering}
                  icon={<OrganiseIcon />}
                  onClick={organisingHook.handleOrganising}
                >
                  Organise
                </SquareButton>
              )}
              {!isMine && <BookmarkButton />}
              <SquareButton icon={<ShareIcon />} onClick={toggleModal('share')}>
                Share
              </SquareButton>
            </>
          )}
        </Stack>

        <Stack className="Collection-additionalControlsWrapper">
          {
            isRegistry && (!isGuest || (cashFund && cashFund.name !== '')) && (
              <CtaButton
                className="Collection-addCollectionInfoButton"
                size="small"
                variant="secondary"
                startIcon={<PiggyBankIcon />}
                onClick={toggleModal('addCashFund')}
              >
                {
                  isGuest
                    ? 'Contribute to cash fund'
                    : cashFund && cashFund.name !== ''
                      ? 'Edit cash fund'
                      : 'Add a cash fund'
                }
              </CtaButton>
            )
          }

          {
            isRegistry && (!isGuest || (deliveryInfo && deliveryInfo !== '')) && (
              <CtaButton
                className="Collection-addCollectionInfoButton"
                size="small"
                variant="secondary"
                startIcon={<LorryIcon />}
                onClick={() => {
                  setModal({
                    type: ModalTypes.DeliveryInfo,
                    collectionId: collectionUID,
                    collectionUserId: collectionUserUID,
                  });
                }}
              >
                {
                  isGuest
                    ? 'View delivery info'
                    : deliveryInfo && deliveryInfo !== ''
                      ? 'Edit delivery info'
                      : 'Add delivery info'
                }
              </CtaButton>
            )
          }

          {isMine && isRegistry && !isOrganising && (
            <FormControlLabel
              id="form-control-checkbox"
              className="Collection-revealToggle"
              name="form-control-checkbox"
              label="Preview as a guest & reveal bought items"
              labelPlacement="start"
              control={<Checkbox />}
              checked={isRevealing}
              onChange={onRevealToggle}
            />
          )}
        </Stack>

        {isLoading ? (
          <>
            <Helmet>
              <title>Loading Collection... - Moonsift</title>
            </Helmet>
            <LoadingDisplay message="Loading Collection..." />
          </>
        ) : (
          <>
            <Helmet>
              <title>{collectionName} - Moonsift</title>
              <meta property="og:title" content={collectionName} />
            </Helmet>
            <ProductTileList
              collectionUID={collectionUID}
              collectionUserUID={collectionUserUID}
              organisingHook={organisingHook}
              goToAddItem={onToggleAddItemMenu}
            />
          </>
        )}
      </Stack>
      <Modal
        mode="modal"
        isOpen={modals.share}
      >
        <CollectionSharePanel
          onClose={toggleModal('share')}
          openEditCollection={toggleModal('edit')}
          onOpenGenerateVideo={toggleModal('generateVideo')}
        />
      </Modal>
      <Modal
        mode="modal"
        isOpen={modals.generateVideo}
      >
        <VideoGenerationPanel onClose={toggleModal('generateVideo')} />
      </Modal>
      <Modal
        mode="modal"
        isOpen={isEditingProduct}
      >
        <EditProductDetails onClose={onEditProductClose} />
      </Modal>

      <Modal
        mode="modal"
        isOpen={modals.addCashFund}
      >
        {/* TODO: The Delivery Info Dialog has been refactored to use the Redux based approach so it plays better with other flows.
        Since they are closely related in the UI and functionality, AddCashFundDialog should also be refactored and brought in-line */}
        <AddCashFundDialog
          collectionUID={collectionUID}
          collectionUserUID={collectionUserUID}
          onClose={toggleModal('addCashFund')}
          isMine={isMine}
          isRevealing={isRevealing}
        />
      </Modal>

      <EditModal isOpen={modals.edit} onClose={toggleModal('edit')} />
      <CollectionNotifications
        isOpen={modals.notifications}
        onClose={toggleModal('notifications')}
      />
      <AddAnItem isOpen={modals.addAnItem} onClose={toggleModal('addAnItem')} />
      {/**
       * This Prompt will be shown in the form of a browser alert when trying
       * to leave the collection detail page when organising by navigating via
       * react-router. It won't work when manually changing the URL in your browser.
       * See non-react-router solution in the container.
       */}
      <Prompt
        when={isOrganising}
        message="Any changes you've made to the order of your collection have not been saved. Leave without saving?"
      />
      {
        // Prompt if user is not logged in:
        !isLoggedIn && !isLoading && <CollectionBottomPrompt />
      }
    </Page>
  );
};

export const Collection = styled(UnstyledCollection)`
  margin-bottom: 40px;

  .Collection-innerContainer {
    margin: 0 16px;
  }

  .Collection-collectionName {
    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      ${({ theme }) => theme.fns.getTypographyStyles('primary.b42')}
    }
  }

  .Collection-collectionName,
  .Collection-collectionDescription {
    max-width: 536px;
    margin-bottom: 12px;
    white-space: pre-line;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 16px;
    }
  }

  .Collection-metaInfo {
    margin-bottom: 28px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 32px;
    }
  }

  .Collection-controls {
    margin-bottom: 32px;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 40px;
    }
  }

  &:not(.Collection-isPro) .Collection-organisingSaveButton {
    width: 252px;
  }

  .Collection-organisingSectionButton {
    margin-left: 16px;
  }

  .Collection-floatingOrganisingSaveButton {
    padding: 7px 10px;
    position: fixed;
    right: 26px;
    bottom: 26px;
    z-index: 100;

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'md' })} {
      display: none;
    }
  }

  .Collection-additionalControlsWrapper {
    margin-bottom: 40px;
    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
      margin-bottom: 68px;
    }
  }

  .Collection-addCollectionInfoButton {
    width: 320px;
    max-width: 320px;
    border: 1px solid ${({ theme }) => theme.fns.getColor('grey.4')} !important;
    color: black !important;

    &:hover {
      box-shadow: 0px 3px 6px 0px #00000040;
      border: 1px solid ${({ theme }) => theme.fns.getColor('common.black')} !important;
    }

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`;
