import { VFC, useCallback, useContext } from 'react';

import { ServicesContext } from 'src/ServicesContext';
import { AppStoreLinkButtonContainerProps } from './AppStoreLinkButton.types';
import { AppStoreLinkButton } from './AppStoreLinkButton.component';

const AppStoreLinkButtonContainer: VFC<AppStoreLinkButtonContainerProps> = ({
  onClick,
  creator,
  includeRating,
}) => {
  // Open Moonsift on the App Store
  const { analytics } = useContext(ServicesContext);
  const handleAppDownload = useCallback(
    (event) => {
      if (onClick) {
        onClick(event);
      }
      analytics.recordEvent('WebApp:AppStoreButton:Pressed');
    },
    [analytics, onClick],
  );

  return (
    <AppStoreLinkButton
      creator={creator === true}
      onAppDownload={handleAppDownload}
      includeRating={includeRating === true}
    />
  );
};

export { AppStoreLinkButtonContainer as AppStoreLinkButton };
