import { VFC } from 'react';
import { Stack, Typography } from '@moonsifttech/design-system';
import { EXTERNAL } from 'src/routes';
import { Link } from 'src/components/core/Link';
import { HowToVideoProps } from './HowToVideo.types';
import styled from 'styled-components';

const UnstyledHowToVideo: VFC<HowToVideoProps> = ({
  includeExplanation,
  className,
  style,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      direction="column"
      justifyContent="center"
    >
      <Typography
        className="HowToVideo-paragraph"
        variant="editorial.r22"
        align="center"
        component="p"
      >
        Take a tour of the platform
      </Typography>
      {includeExplanation && (
        <Typography
          className="HowToVideo-paragraph"
          variant="primary.r16"
          align="center"
          component="p"
        >
          See how to add products, organise collections, share your storefront,
          track earnings and add your own external affiliate links.
        </Typography>
      )}
      <Stack
        className="HowToVideo-videoContainer"
        direction="row"
        justifyContent="center"
      >
        <iframe
          width="327"
          height="184"
          src="https://www.youtube.com/embed/Tu7fioCaCGw"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Stack>
      <Typography
        className="HowToVideo-belowVideo"
        variant="primary.l16"
        align="center"
        component="p"
      >
        <Link target="_blank" to={EXTERNAL.CREATOR_HOW_IT_WORKS}>
          Learn more about how it all works
        </Link>
      </Typography>
    </Stack>
  );
};

export const HowToVideo = styled(UnstyledHowToVideo)`
  .HowToVideo-paragraph {
    margin-bottom: 20px;
  }
  .HowToVideo-belowVideo {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
