/*

This page is used to allow users to search the list of supported retailers

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...

*/
import { Fragment, Component, useCallback, useState } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Typography,
  typographyClassNames,
  Stack,
  SearchIcon,
} from '@moonsifttech/design-system';
import Fuse from 'fuse.js';
import clm from 'country-locale-map';

import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import { withFirebase } from 'src/mvp22/Firebase';
import LiveSearch from 'src/mvp22/form-components/LiveSearch';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import windowSize from 'src/mvp22/WindowSize';
import R from 'src/routes';
import { ServicesContext } from 'src/ServicesContext';
import { Link } from 'src/components/core/Link';
import { popularAffiliateBrandsIds } from './popularAffiliateBrands';

const Container = styled.div`
  background-color: ${M.COL.BG.WHITE};
  color: ${M.COL.TEXT.BLACK};
  display: flex;
  height: 100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
`;

const MainContainer = styled.div`
  height: 100%;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
`;

const HeaderAndTabsOuter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  height: 280px;
  position: fixed;
  z-index: 10;
  background-color: white;
`;

const HeaderAndTabsContainer = styled.div`
  display: flex;
  margin-top: 56px;
  text-align: center;
  width: 100%;
  flex-direction: column;
  max-width: 1116px;
  position: fixed;
  background-color: ${M.COL.BG.WHITE};
  z-index: 10;
  height: ${(props) => (props.isOnSearch ? '100%' : '')};
`;

const AffiliateBrandLinksContainer = styled.div`
  padding-bottom: 95px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  align-items: flex-start;
  margin-top: 369px;
`;

export const ExternalLink = styled(TEXTSTYLE.EXTERNALLINK)`
  display: block;
  &:hover {
    color: ${M.COL.TEXT.BLACK};
  }
`;

const Tab = styled(TEXTSTYLE.LINK)`
  letter-spacing: 0.1em;
  cursor: pointer;
  &:hover {
    img {
      opacity: 1;
    }
    div {
      color: ${M.COL.TEXT.BLACK};
    }
  }
  color: ${(props) => (props.active ? M.COL.TEXT.BLACK : M.COL.TEXT.LIGHT)};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;

const AzTab = styled(Tab)`
  width: 26px;
  border-bottom: ${(props) =>
    props.active
      ? `4px solid ${M.COL.TEXT.YELLOW}`
      : `4px solid ${M.COL.TEXT.WHITE}`};

  &.AffiliateBrands-popularTab {
    width: 72px;
    margin-right: 10px;
  }
`;

const CustomInfoAlert = styled.div`
  border-radius: 8px;
  background-color: var(--grey-6);
  padding: 20px;
`;

const AlphabeticalAffiliateBrandsBlock = styled.div`
  display: block;
  background-color: ${(props) => (props.odd ? M.COL.BG.WHITE : M.COL.BG.LIGHT)};
  @media (min-width: 1132px) {
    justify-content: center;
  }
  width: 100%;

  ${CustomInfoAlert} {
    margin: 0 16px 32px;
    max-width: 784px;
  }
`;

const AlphabeticalAffiliateBrandsBlockInner = styled.div`
  max-width: 1116px;
  display: block;
  margin-bottom: 57px;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;

  ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 'sm' })} {
    margin-top: 40px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (min-width: 716px) {
    flex-direction: row;
  }
`;

const AffiliateBrandList = styled.div`
  width: 100%;
  text-align: left;
  margin-left: 16px;
  column-count: 1;
  column-gap: 60px;

  @media (min-width: 716px) {
    margin-left: 0;
    column-count: 2;
  }

  @media (min-width: 1015px) {
    margin-left: 0;
    column-count: 3;
  }

  .AffiliateBrands-isPopular & {
    margin-left: 16px;
  }
`;

export const AffiliateBrandListItemWrapper = styled.li`
  width: 280px;
  margin-bottom: 20px;
  display: inline-block;
`;

export const AffiliateBrandName = styled(TEXTSTYLE.BODY2)`
  font-weight: 400;

  &:hover {
    text-decoration: underline;
  }
`;

const LetterContainer = styled(TEXTSTYLE.HEADING2)`
  text-align: left;
  width: 50px;
  margin-left: 20px;
  margin-right: 100px;
  margin-bottom: 12px;

  .AffiliateBrandList & {
    margin: 40px 0 20px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
`;

export const AffiliateBrandListItem = styled(
  ({ className, name, domain, countries, commissionLevel, commissionRate }) => {
    const link = `https://${domain}`;
    const countriesString = countries
      .map(clm.getCountryNameByAlpha2)
      .join(', ');
    const showExpandButton = countriesString.length > 126;

    const [expanded, setExpanded] = useState(false);
    const toggleCountries = useCallback(() => {
      setExpanded((prevExpanded) => !prevExpanded);
    }, []);
    return (
      <AffiliateBrandListItemWrapper
        className={clsx(className, 'featuredListItem')}
      >
        <ExternalLink href={link} target="_blank">
          <AffiliateBrandName>{`${name} `}</AffiliateBrandName>
        </ExternalLink>
        {commissionRate && (
          <Typography
            className="AffiliateBrandListItem-commissionRate"
            variant="primary.m12"
            component="p"
          >
            Up to {commissionRate}%
          </Typography>
        )}
        <Typography
          className={clsx(
            'AffiliateBrandListItem-countries',
            expanded && 'AffiliateBrandListItem-expanded',
          )}
          variant="primary.l14"
          component="p"
        >
          {countriesString}
          {showExpandButton && (
            <Typography
              className="AffiliateBrandListItem-expandButton"
              variant="primary.r12"
              onClick={toggleCountries}
            >
              {expanded ? 'see less...' : 'see more...'}
            </Typography>
          )}
        </Typography>
      </AffiliateBrandListItemWrapper>
    );
  },
)`
  .AffiliateBrandListItem-countries {
    position: relative;
    margin-bottom: 0;
    overflow: hidden;

    &:not(.AffiliateBrandListItem-expanded) {
      max-height: 62px;
    }

    &.AffiliateBrandListItem-expanded {
      padding-bottom: 20px;
    }
  }

  .AffiliateBrandListItem-expandButton {
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 1)
    );
    width: 150px;
    text-align: right;
    padding: 0 2px 2px 0;
    cursor: pointer;
    font-size: 13px;
  }

  .AffiliateBrandListItem-commissionRate {
    color: var(--background-purple);
    margin: 0px;
    margin-bottom: 4px;
  }
`;

/**
 * AffiliateBrands Moonsift Component
 */
class AffiliateBrands extends Component {
  static contextType = ServicesContext;

  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      brandsByGroup: {},
      isLoading: true,
      scrolled: false,
    };
  }

  componentDidMount() {
    this.setAffiliateBrands();
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = (event) => {
    const { scrolled } = this.state;
    const { currentTarget } = event;
    const newScrolled = (currentTarget.scrollY ?? currentTarget.scrollTop) > 10;

    if (scrolled !== newScrolled) {
      this.setState({ scrolled: newScrolled });
    }
  };

  renderHeading() {
    const { scrolled } = this.state;
    return (
      <div
        className={clsx(
          'AffiliateBrands-headingContainer',
          scrolled && 'AffiliateBrands-scrolled',
        )}
      >
        <Typography
          className="AffiliateBrands-heading"
          variant="primary.b42"
          component="h1"
          align="left"
        >
          Affiliate Brands
        </Typography>
        <Typography
          className="AffiliateBrands-subtitle"
          variant="primary.r16"
          component="p"
          align="left"
          color="grey.1"
        >
          Add items from these sites and commission will be tracked
          automatically.{' '}
          <Link className="AffiliateBrands-subtitleLink" to={R.CREATOR_FAQ}>
            Learn more.
          </Link>
        </Typography>
      </div>
    );
  }

  search = () => {
    const { history } = this.props;
    history.push(`${R.AFFILIATE_BRANDS}/search`);
  };

  searchResultsNode = null;

  setScrollHandlerFromRef = (node) => {
    if (node === null) {
      this.searchResultsNode.removeEventListener('scroll', this.onScroll);
      this.searchResultsNode = null;
    } else {
      node.addEventListener('scroll', this.onScroll);
      this.searchResultsNode = node;
    }
  };

  renderAzFeaturedAndSearchToggle(isDesktop) {
    const { match } = this.props;
    const { isLoading, fuse } = this.state;
    const { group } = match.params;

    if (group === 'search' && !isLoading) {
      return (
        <LiveSearch
          resultsRef={this.setScrollHandlerFromRef}
          goBack={this.goBack}
          fuse={fuse}
          isDesktop={isDesktop}
          ItemComponent={AffiliateBrandListItem}
          formatter={(item) => {
            return {
              name: item.n,
              domain: item.d,
              countries: item.c,
              commissionLevel: item.cl,
              commissionRate: item.r,
            };
          }}
          type="Affiliates"
        />
      );
    }

    const { brandsByGroup } = this.state;
    const currentGroupKey =
      group === 'symbols' ? '#' : this.capitalizeFirstLetter(group);

    // Move '#' to last position
    const groupKeys = Object.keys(brandsByGroup);

    if (groupKeys.length > 1) {
      const first = groupKeys.shift();
      groupKeys.push(first);
    }

    return (
      <div className="AffiliateBrands-nav">
        <Stack
          className="AffiliateBrands-searchButton"
          direction="row"
          spacing={4}
          alignItems="center"
          onClick={this.search}
        >
          <Typography variant="primary.m16" color="common.black">
            SEARCH
          </Typography>
          <SearchIcon size="small" color="common.black" />
        </Stack>
        <Stack
          className="AffiliateBrands-letterButtons"
          direction="row"
          alignItems="center"
          wrap="wrap"
        >
          {groupKeys.map((groupKey) => {
            return (
              <AzTab
                className={clsx(
                  groupKey === 'Popular' && 'AffiliateBrands-popularTab',
                )}
                key={groupKey}
                active={groupKey === currentGroupKey ? 1 : 0}
                to={`${R.AFFILIATE_BRANDS}/${
                  groupKey === '#' ? 'symbols' : groupKey.toLowerCase()
                }`}
              >
                <Typography variant="primary.b18">{groupKey}</Typography>
              </AzTab>
            );
          })}
        </Stack>
      </div>
    );
  }

  async setAffiliateBrands() {
    const { storage } = this.context;

    try {
      // Retrieve affiliate brands JSON
      const brands = await storage.downloadJsonFile('affiliate-brands.json');
      const brandsByGroup = brands.reduce((acc, brand) => {
        // Add the brand to its letter group.
        const firstLetter = brand.n[0].toUpperCase();
        const groupKey = /[A-Z]/.test(firstLetter) ? firstLetter : '#';
        const group = acc[groupKey] ?? [];
        group.push(brand);
        acc[groupKey] = group;

        // Add the brand to the popular group if its ID is in the list of
        // popular IDs.
        if (popularAffiliateBrandsIds.includes(brand.i)) {
          const popularGroup = acc.Popular ?? {};
          const popularLetterGroup = popularGroup[groupKey] ?? [];
          popularLetterGroup.push(brand);
          popularGroup[groupKey] = popularLetterGroup;
          acc.Popular = popularGroup;
        }

        return acc;
      }, {});

      const fuse = new Fuse(brands, { keys: ['n'], threshold: 0.5 });

      this.setState({
        brands,
        brandsByGroup,
        fuse,
        isLoading: false,
      });
    } catch (error) {
      console.error('Failed to retrieve merchants data.', error);
    }
  }

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  };

  capitalizeFirstLetter(word) {
    return `${word.charAt(0).toUpperCase()}${word.slice(1)}`;
  }

  listAffiliateBrands() {
    const { group } = this.props.match.params;
    const { brandsByGroup } = this.state;
    const groupKey =
      group === 'symbols' ? '#' : this.capitalizeFirstLetter(group);
    const brandGroup = brandsByGroup[groupKey];
    const isPopular = groupKey === 'Popular';

    return (
      <AlphabeticalAffiliateBrandsBlock
        className="AlphabeticalAffiliateBrandsBlock"
        odd
      >
        <AlphabeticalAffiliateBrandsBlockInner className="AlphabeticalAffiliateBrandsBlockInner">
          {isPopular && (
            <CustomInfoAlert>
              <Typography variant="primary.r16">
                Below is just a small selection of popular affiliate brands. If
                you don't see the brand or country you're looking for, find
                thousands more using the{' '}
                <Link to={`${R.AFFILIATE_BRANDS}/search`}>
                  <b>Search</b>
                </Link>
                .
              </Typography>
            </CustomInfoAlert>
          )}
          <ListWrapper className="ListWrapper">
            {!isPopular && <LetterContainer>{groupKey}</LetterContainer>}
            <AffiliateBrandList className="AffiliateBrandList">
              {isPopular
                ? Object.entries(brandGroup ?? {}).map(
                    ([subGroupKey, brandSubGroup]) => {
                      return (
                        <Fragment key={subGroupKey}>
                          <LetterContainer>{subGroupKey}</LetterContainer>
                          {brandSubGroup.map((brand, i) => {
                            return (
                              <AffiliateBrandListItem
                                key={`${subGroupKey}-${i}`}
                                className="featuredListItem"
                                name={brand.n}
                                domain={brand.d}
                                countries={brand.c}
                                commissionLevel={brand.cl}
                                commissionRate={brand.r}
                              />
                            );
                          })}
                        </Fragment>
                      );
                    },
                  )
                : (brandGroup ?? []).map((brand, i) => {
                    return (
                      <AffiliateBrandListItem
                        key={i}
                        className="featuredListItem"
                        name={brand.n}
                        domain={brand.d}
                        countries={brand.c}
                        commissionLevel={brand.cl}
                        commissionRate={brand.r}
                      />
                    );
                  })}
            </AffiliateBrandList>
          </ListWrapper>
        </AlphabeticalAffiliateBrandsBlockInner>
      </AlphabeticalAffiliateBrandsBlock>
    );
  }

  renderLoadingOnPage() {
    return (
      <LoadingContainer>
        {AddHelmetTitle('Loading')}
        <LoadingDisplay message="Loading Affiliate Brands..." />
      </LoadingContainer>
    );
  }

  render() {
    const { group } = this.props.match.params;
    const isSearch = group === 'search';
    const isPopular = group === 'popular';
    const isDesktop = this.props.windowWidth >= M.MOBILESWITCH;
    return (
      <FullBlank
        className={clsx(
          this.props.className,
          isPopular && 'AffiliateBrands-isPopular',
        )}
      >
        <TopMenu />
        {AddHelmetTitle('Supported Affiliate Brands')}
        <Container>
          <MainContainer className="mainContainer">
            <HeaderAndTabsOuter>
              <HeaderAndTabsContainer
                className="HeaderAndTabsContainer"
                isOnSearch={isSearch}
              >
                {this.renderHeading()}
                {this.renderAzFeaturedAndSearchToggle(isDesktop)}
              </HeaderAndTabsContainer>
            </HeaderAndTabsOuter>
            {this.state.isLoading ? (
              this.renderLoadingOnPage()
            ) : (
              <AffiliateBrandLinksContainer className="AffiliateBrandLinksContainer">
                {!isSearch && this.listAffiliateBrands()}
              </AffiliateBrandLinksContainer>
            )}
          </MainContainer>
        </Container>
      </FullBlank>
    );
  }
}

const StyledAffiliateBrands = styled(AffiliateBrands)`
  .AffiliateBrands-headingContainer {
    margin-left: 16px;
    margin-top: 45px;
    transform-origin: top left;
    transition: margin-top 0.4s linear, margin-bottom 0.4s linear;

    .AffiliateBrands-subtitle {
      margin-top: -8px;
      margin-bottom: 44px;
      transition: height 0.4s linear, margin 0.4s linear;
      overflow: hidden;
    }

    .AffiliateBrands-subtitleLink {
      color: var(--grey-1);
    }

    &.AffiliateBrands-scrolled {
      margin-top: 16px;

      .AffiliateBrands-heading {
        ${({ theme }) => theme.fns.getTypographyStyles('primary.b26')}
      }

      .AffiliateBrands-subtitle {
        height: 0;
        margin: 0;
      }
    }
  }

  .AffiliateBrands-nav {
    margin: 0 16px;

    .AffiliateBrands-searchButton {
      width: fit-content;
      margin-bottom: 24px;
      cursor: pointer;

      > .${typographyClassNames.root} {
        letter-spacing: 0.1em;
      }
    }
  }

  .searchBarContainer {
    margin-top: 0;
  }
`;

export default withRouter(withFirebase(windowSize(StyledAffiliateBrands)));
