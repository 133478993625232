import { VFC, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { creatorsPayoutMin } from 'src/config';
import {
  EarningsEntry,
  fillEarningsEntry,
  sumEarningsEntryCommissions,
} from 'src/types/models/creatorEarnings.model';
import { CurrencyFormatter } from 'src/utils/CurrencyFormatter';
import { EarningsSummaryCardContainerProps } from './EarningsSummaryCard.types';
import { EarningsSummaryCard } from './EarningsSummaryCard.component';

const EarningsSummaryCardContainer: VFC<EarningsSummaryCardContainerProps> = (
  props,
) => {
  const creatorEarnings = useSelector<RootState, Required<EarningsEntry>>(
    ({ db }) => {
      const earnings = db.user_verified_settings.data?.earnings;
      return fillEarningsEntry(earnings);
    },
  );

  const open = creatorEarnings.pending.commission;
  const due = creatorEarnings.due.commission;
  const paid = creatorEarnings.given.commission;

  // Format individual commission amounts
  const openAmount = CurrencyFormatter.format(open.amount, open.currency);
  const dueAmount = CurrencyFormatter.format(due.amount, due.currency);
  const paidAmount = CurrencyFormatter.format(paid.amount, paid.currency);

  // Calculate and format commissions' total amount
  const totalAmount = CurrencyFormatter.format(
    sumEarningsEntryCommissions(creatorEarnings),
    open.currency,
  );

  // NOTE This `creatorsPayoutMin` is in GBP.
  const canRequestPayout = due.amount >= creatorsPayoutMin;

  // Indicates whether the payout request has already been made.
  const creatorPayoutRequested = useSelector<RootState, boolean>(({ db }) => {
    return db.user_verified_settings.data?.creator_payout_requested ?? false;
  });

  const [isPayoutRequestOpen, setIsPayoutRequestOpen] = useState(false);
  const handlePayoutRequestToggle = useCallback(() => {
    setIsPayoutRequestOpen((prevIsPayoutRequestOpen) => {
      return !prevIsPayoutRequestOpen;
    });
  }, []);

  return (
    <EarningsSummaryCard
      open={openAmount}
      due={dueAmount}
      paid={paidAmount}
      total={totalAmount}
      creatorPayoutRequested={creatorPayoutRequested}
      canRequestPayout={canRequestPayout}
      isPayoutRequestOpen={isPayoutRequestOpen}
      onPayoutRequestToggle={handlePayoutRequestToggle}
      {...props}
    />
  );
};

export { EarningsSummaryCardContainer as EarningsSummaryCard };
