import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Stack, Typography } from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { CollectionNotFoundProps } from './CollectionNotFound.types';

const UnstyledCollectionNotFound: React.FC<CollectionNotFoundProps> = ({
  className,
  style,
}) => {
  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Helmet>
        <title>Collection not found</title>
      </Helmet>
      <Stack
        className="CollectionNotFound-innerContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          className="CollectionNotFound-notFoundImage"
          src={`${process.env.REACT_APP_STATIC_URL}/home/empty.svg`}
          alt="Collection not found"
        />
        <Typography variant="primary.b26" align="center" component="p">
          Collection private or not found
        </Typography>
        <Typography variant="primary.r16" align="center" component="p">
          Click{' '}
          <Link className="CollectionNotFound-notFoundLink" to={R.HOME}>
            here
          </Link>{' '}
          to go to your collections.
        </Typography>
      </Stack>
    </Page>
  );
};

export const CollectionNotFound = styled(UnstyledCollectionNotFound)`
  .CollectionNotFound-innerContainer {
    height: calc(100vh - 166px);
    margin: 0 16px;
  }

  .CollectionNotFound-notFoundImage {
    display: block;
    height: 80px;
    width: 80px;
    margin-bottom: 64px;
  }

  .CollectionNotFound-notFoundLink {
    text-decoration: none;
    border-bottom: 3px solid var(--secondary-main);
  }
`;
