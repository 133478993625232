import { ParserInput } from 'src/services/DB';
// Just parses the document by extracting the data and adding the id.
export function dataAndID<T>(doc: ParserInput) {
  if (doc.exists()) {
    const data = doc.data();
    data.id = doc.id;
    return data as T;
  }
  return null;
}
