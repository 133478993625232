import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  EditIcon,
  ShareIcon,
  DashboardIcon,
} from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContent } from 'src/components/core/MenuContent';
import {
  MoreProfileOptionsMenuRef,
  MoreProfileOptionsMenuProps,
} from './MoreProfileOptionsMenu.types';

const UnstyledMoreProfileOptionsMenu = React.forwardRef<
  MoreProfileOptionsMenuRef,
  MoreProfileOptionsMenuProps
>(
  (
    { className, style, isOwn, isCreator, onShare, onEdit, onGoToDashboard },
    ref,
  ) => {
    return (
      <MenuContent ref={ref} className={className} style={style}>
        <List>
          {isOwn && (
            <ListItem onClick={onEdit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit Profile</ListItemText>
            </ListItem>
          )}
          {isCreator && isOwn && (
            <ListItem onClick={onGoToDashboard}>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText>Earnings Dashboard</ListItemText>
            </ListItem>
          )}
          <ListItem onClick={onShare}>
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText>Share Profile</ListItemText>
          </ListItem>
        </List>
      </MenuContent>
    );
  },
);

export const MoreProfileOptionsMenu = styled(UnstyledMoreProfileOptionsMenu)`
  .${modalMenuClassNames.isDesktop} & {
    width: 180px;
  }
`;
