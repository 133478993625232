import React from 'react';
import { AddAnItemManuallyAddImageProps } from './AddAnItemManuallyAddImage.types';
import {
  ControlledTextField,
  Stack,
  StandardButton,
  Typography,
} from '@moonsifttech/design-system';
import styled from 'styled-components';

const UnstyledAddAnItemManuallyAddImage: React.FC<
  AddAnItemManuallyAddImageProps
> = ({
  className,
  style,
  pasteURLReference,
  uploadButtonReference,
  onUploadImage,
  onPasteImageURL,
  isUploading,
  errorState,
  isMobile,
}) => {
  return (
    <Stack className={className} style={style}>
      <Stack
        direction="row"
        justifyContent="space-between"
        className="AddAnItemManuallyAddImage-pastedImageContainer"
        spacing={4}
      >
        <ControlledTextField
          className="AddAnItemManuallyAddImage-input"
          name="url"
          placeholder="Paste an image URL here"
          autoComplete="off"
          fullWidth
          inputRef={pasteURLReference}
        />
        <StandardButton variant="primary-black" onClick={onPasteImageURL}>
          Add
        </StandardButton>
      </Stack>
      <input
        type="file"
        name="upload_picture"
        ref={uploadButtonReference}
        onChange={onUploadImage}
        accept="image/*"
        className="AddAnItemManuallyAddImage-inputUpload"
      />
      <Stack direction="row" justifyContent="space-between">
        <StandardButton
          variant="primary-black"
          loading={isUploading}
          onClick={() => uploadButtonReference?.current?.click()}
          className={'AddAnItemManyally-standardButton'}
          fullWidth
        >
          Alternatively, {isMobile ? `tap` : `click`} here to upload an image
        </StandardButton>
      </Stack>
      <Typography
        variant="primary.l16"
        className="AddAnItemManuallyAddImage-errorText"
      >
        {errorState}
      </Typography>
    </Stack>
  );
};

export const AddAnItemManuallyAddImage = styled(
  UnstyledAddAnItemManuallyAddImage,
)`
  .AddAnItemManuallyAddImage-input {
    flex-grow: 1;
  }
  .AddAnItemManuallyAddImage-info {
    margin-bottom: 0px;
  }
  .AddAnItemManuallyAddImage-inputUpload {
    visibility: hidden;
    margin: 0px;
    padding: 0px;
    width: 0px;
    height: 0px;
  }
  .AddAnItemManuallyAddImage-errorText {
    color: var(--error-main);
  }
  .AddAnItemManuallyAddImage-pastedImageContainer {
    margin-bottom: 8px;
  }
`;
