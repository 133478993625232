import { ActionNames } from 'src/types/actions';
import {
  SetUISignedUpInSession,
  UnsetUISignedUpInSession,
} from 'src/types/models/ui.model';

export function setUiSignedUpInSession(): SetUISignedUpInSession {
  return {
    type: ActionNames.UI_SIGNED_UP_IN_SESSION_SET,
    payload: undefined,
  };
}

export function unsetUiSignedUpInSession(): UnsetUISignedUpInSession {
  return {
    type: ActionNames.UI_SIGNED_UP_IN_SESSION_UNSET,
    payload: undefined,
  };
}
