import {
  Action,
  RootDB,
  ActionNames,
  BaseDocMapState,
  BaseQueryMapState,
  BaseDocState,
  BaseQueryState,
} from 'src/types';

/*

Reducer that stores a map of documents with key document location and value the parsed document data.

*/
export const DEFAULT_QUERY_MAP_STATE: BaseQueryMapState<any> = {
  dBKind: 'query',
  stateKind: 'map',
  map: {},
  listeners: {},
};
export const DEFAULT_DOC_MAP_STATE: BaseDocMapState<any> = {
  dBKind: 'doc',
  stateKind: 'map',
  map: {},
  listeners: {},
};
export const DEFAULT_QUERY_STATE: BaseQueryState<any> = {
  dBKind: 'query',
  stateKind: 'single',
  data: [],
  set: false,
  listener: null,
};
export const DEFAULT_DOC_STATE: BaseDocState<any> = {
  dBKind: 'doc',
  stateKind: 'single',
  data: undefined,
  set: false,
  listener: null,
};

export function getBaseDBReducer(defaultState: RootDB) {
  return function (state: RootDB = defaultState, action: Action): RootDB {
    if (
      action.type === ActionNames.BASEDB_SET_DOC ||
      action.type === ActionNames.BASEDB_SET_QUERY
    ) {
      // define this for type checking:
      const setKindState = state[action.kind];
      if (action.storeAs !== undefined && setKindState.stateKind === 'map') {
        return {
          ...state,
          [action.kind]: {
            ...setKindState,
            map: {
              ...setKindState.map,
              [action.storeAs]: action.payload,
            },
          },
        };
      } else if (setKindState.stateKind === 'single') {
        return {
          ...state,
          [action.kind]: {
            ...setKindState,
            set: true,
            data: action.payload,
          },
        };
      }
    } else if (
      action.type === ActionNames.BASEDB_SET_LISTENER_DOC ||
      action.type === ActionNames.BASEDB_SET_LISTENER_QUERY
    ) {
      // define this for type checking:
      const setListenerKindState = state[action.kind];
      if (
        action.storeAs !== undefined &&
        setListenerKindState.stateKind === 'map'
      ) {
        return {
          ...state,
          [action.kind]: {
            ...setListenerKindState,
            listeners: {
              ...setListenerKindState.listeners,
              [action.storeAs]: action.payload,
            },
          },
        };
      } else if (setListenerKindState.stateKind === 'single') {
        return {
          ...state,
          [action.kind]: {
            ...setListenerKindState,
            listener: action.payload,
          },
        };
      }
    } else if (action.type === ActionNames.BASEDB_UNLISTEN_SINGLE) {
      const unlistenSingleKindState = state[action.kind];
      if (
        action.storeAs !== undefined &&
        unlistenSingleKindState.stateKind === 'map'
      ) {
        // remove listener:
        if (unlistenSingleKindState.listeners[action.storeAs]) {
          unlistenSingleKindState.listeners[action.storeAs].close();
        }
        const newListeners = {
          ...unlistenSingleKindState.listeners,
        };
        delete newListeners[action.storeAs];
        // remove data:
        const newMap = {
          ...unlistenSingleKindState.map,
        };
        delete newMap[action.storeAs];
        return {
          ...state,
          [action.kind]: {
            ...unlistenSingleKindState,
            listeners: newListeners,
            map: newMap,
          },
        };
      } else if (unlistenSingleKindState.stateKind === 'single') {
        if (unlistenSingleKindState.listener !== null) {
          unlistenSingleKindState.listener.close();
        }
        return {
          ...state,
          [action.kind]:
            unlistenSingleKindState.dBKind === 'doc'
              ? DEFAULT_DOC_STATE
              : DEFAULT_QUERY_STATE,
        };
      }
    } else if (action.type === ActionNames.BASEDB_UNLISTEN_MAP) {
      const unlistenMapKindState = state[action.kind];
      if (unlistenMapKindState.stateKind === 'map') {
        Object.keys(unlistenMapKindState.listeners).forEach((key: string) => {
          if (unlistenMapKindState.listeners[key] !== null) {
            unlistenMapKindState.listeners[key].close();
          }
        });
        return {
          ...state,
          [action.kind]: defaultState[action.kind],
        };
      }
    }
    return state;
  };
}
