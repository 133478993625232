import React from 'react';
import {
  AddItemsButtonProps,
  AddItemsButtonContainerProps,
} from './AddItemsButton.types';
import { AddItemsButton } from './AddItemsButton.component';
import { RootState } from 'src';
import { useSelector } from 'react-redux';

const AddItemsButtonContainer: React.FC<AddItemsButtonContainerProps> = (
  props,
) => {
  // Handlers
  const handleClick = React.useCallback<AddItemsButtonProps['onClick']>(
    (event) => {
      const isAndroid = /(android)/i.test(navigator.userAgent);
      if (isAndroid) {
        props.openAddItemModal(event);
        return;
      }
      props.setIsAddItemMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
    },
    [props],
  );

  const handleClose = React.useCallback<AddItemsButtonProps['onClose']>(() => {
    props.setIsAddItemMenuOpen(false);
  }, [props]);

  const collectionIsEmpty = useSelector<RootState, boolean>(
    (state) =>
      state.db.single_collection_item_list.set &&
      state.db.single_collection_item_list.data.length === 0,
  );

  return (
    <AddItemsButton
      isMenuOpen={props.isAddItemMenuOpen}
      onClick={handleClick}
      onClose={handleClose}
      collectionIsEmpty={collectionIsEmpty}
      {...props}
    />
  );
};

export { AddItemsButtonContainer as AddItemsButton };
