import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Typography,
  ProTickTwoToneIcon,
  proTickTwoToneIconClassNames,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { ReferProContent } from 'src/components/common/ReferProContent';
import { ReferProProps } from './ReferPro.types';

const UnstyledReferPro: VFC<ReferProProps> = ({ className, style }) => {
  return (
    <>
      <Helmet>
        <title>Refer Pro - Moonsift</title>
      </Helmet>
      <Page className={className} style={style} showNavControls>
        <div className="ReferPro-container">
          <Typography
            className="ReferPro-heading"
            variant="branded.b34"
            component="h1"
          >
            Moonsift Pro <ProTickTwoToneIcon />
          </Typography>
          <ReferProContent />
        </div>
      </Page>
    </>
  );
};

export const ReferPro = styled(UnstyledReferPro)`
  .ReferPro-container {
    max-width: 448px;
    padding: 0 24px;
    margin: 0 auto;
  }

  .ReferPro-heading {
    text-transform: uppercase;

    .${proTickTwoToneIconClassNames.root} {
      --secondary-color: var(--common-white);
      vertical-align: text-top;
    }
  }
`;
