/*

Shows both the app and chrome extension review requests.

Also can trigger the native review request.

*/
import React, { useCallback, useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/index';
import {
  ReviewRequestContainerProps,
  ReviewRequestTypes,
  ScreenNames,
} from './ReviewRequest.types';
import { ReviewRequest } from './ReviewRequest.component';
import { ActionNames, HideAutoReviewRequests } from 'src/types';
import { useTheme } from 'styled-components';
import { ServicesContext } from 'src/ServicesContext';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { useHasExtension } from 'src/hooks/useHasExtension';
import { Constants } from 'src/constants';
import { useNativeReviewRequest } from 'src/hooks/useNativeReivewRequest';

const ReviewRequestContainer: React.FC<ReviewRequestContainerProps> = (
  props,
) => {
  const { db, mobile, analytics } = useContext(ServicesContext);
  const dispatch = useDispatch();

  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );
  // Persist open until closed even if redux auto-triggers change to close due to callbacks in db:
  const [modalOpen, setModalOpen] = useState(false);

  /*

  WHEN + WHAT TO SHOW

  Trigger conditions:
  - can_trigger_review in user_owner is true
  - Auto request is true (ui.showAutoReviewRequests is true and ui.hideAutoReviewRequests is false) OR triggered from button (ui.showReviewRequests is true)
  FOR APP NATIVE:
    - is app
    - user_owner.user_clicked_to_app_store_time is undefined
    AND
    - user_owner.user_requested_review_app_native is undefined OR > 6 months ago
  FOR APP MOONSIFT:
    - is app
    - user_owner.user_clicked_to_app_store_time is undefined
    - user_owner.user_requested_review_app_native is NOT (undefined OR > 6 months ago)
    AND
    - user_owner.user_requested_review_app_moonsift is > 3 months ago
  FOR CHROME EXTENSION:
      - has chrome extension
      - user_owner.user_clicked_to_chrome_web_store_time is undefined
      AND
      - user_owner.user_requested_review_chrome_webstore is undefined OR > 4 months ago
  */

  // Are the UI conditions in this session met?
  const uiConditionsMet = useSelector<RootState, boolean>(
    ({ ui }) => ui.showAutoReviewRequest === 'SHOW',
  );

  // Potentially show native review request:
  useNativeReviewRequest(uiConditionsMet);

  const isApp = mobile.isApp;
  // For our own review popup (not the native one)
  // Note this relies on us showing the native one first!
  // TODO: we probably should move the logic for this and the native one into the same file.
  const appDBConditionsMetAppMoonsift = useSelector<RootState, boolean>(
    ({ firestore_user_owner }) => {
      // if they were not sure we ask them again more recently than if they said no:
      const delaySinceLastAskNonNative =
        firestore_user_owner.snapshot?.last_moonsift_opinion === 'NOT_ENJOYING'
          ? Constants.THREE_MONTHS_AGO
          : Constants.ONE_MONTH_AGO;
      return (
        firestore_user_owner.snapshot?.can_trigger_review_app === true &&
        firestore_user_owner.snapshot?.user_clicked_to_app_store_time ===
          undefined &&
        // native review is not going to be triggered:
        firestore_user_owner.snapshot?.user_requested_review_app_native >=
          Constants.APP_REVIEW_NATIVE_REQUEST_BLOCK_TIME &&
        firestore_user_owner.snapshot?.user_requested_review_app_native <
          delaySinceLastAskNonNative &&
        (firestore_user_owner.snapshot?.user_requested_review_app_moonsift <
          delaySinceLastAskNonNative ||
          firestore_user_owner.snapshot?.user_requested_review_app_moonsift ===
            undefined)
      );
    },
  );
  const appConditionsMet = appDBConditionsMetAppMoonsift && uiConditionsMet;

  const extensionInstalled = useHasExtension();
  const extensionDBConditionsMet = useSelector<RootState, boolean>(
    ({ firestore_user_owner }) => {
      // if they were not sure we ask them again more recently than if they said no:
      const delaySinceLastAsk =
        firestore_user_owner.snapshot?.last_moonsift_opinion === 'NOT_ENJOYING'
          ? Constants.FOUR_MONTHS_AGO
          : Constants.ONE_MONTH_AGO;
      return (
        firestore_user_owner.snapshot?.can_trigger_review_extension === true &&
        firestore_user_owner.snapshot?.user_clicked_to_chrome_web_store_time ===
          undefined &&
        (firestore_user_owner.snapshot?.user_requested_review_extension <
          delaySinceLastAsk ||
          firestore_user_owner.snapshot?.user_requested_review_extension ===
            undefined)
      );
    },
  );
  const extensionConditionsMet = extensionDBConditionsMet && uiConditionsMet;

  const showType: ReviewRequestTypes = extensionInstalled
    ? 'extension'
    : isApp
    ? 'app-moonsift'
    : null;
  const forceShow = useSelector<RootState, boolean>(
    ({ ui }) => ui.showReviewRequests === true,
  );
  const showModal =
    (extensionInstalled && extensionConditionsMet) ||
    (isApp && appConditionsMet) ||
    forceShow ||
    modalOpen;

  // Persist open if opened already:
  useEffect(() => {
    // Set modal open:
    if (showModal === true) {
      setModalOpen(true);
    }
  }, [showModal]);

  /*

  BUTTONS / NAVIGATION

  */
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const [updateUserOwner] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID ?? ''),
  );
  // we don't want updateUserOwnerOnce to keep changing as we have a useEffect based on it!
  const [updateUserOwnerOnce] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID ?? ''),
    true,
  );
  const [currentScreen, setCurrentScreen] = useState<ScreenNames>('initial');
  const onClose = useCallback(() => {
    setModalOpen(false);
    setCurrentScreen('initial');
    dispatch<HideAutoReviewRequests>({
      type: ActionNames.UI_CLOSE_REVIEW_REQUEST,
      payload: undefined,
    });
    analytics.recordEvent('WebApp:Reviews:CloseReviewRequest', {
      isApp,
    });
  }, [analytics, dispatch, isApp]);
  const onTooEarlyClick = useCallback(() => {
    setCurrentScreen('feedback');
    updateUserOwner({
      last_moonsift_opinion: 'TOO_EARLY',
      last_moonsift_opinion_time: db.serverTimestamp(),
    });
    analytics.recordEvent('WebApp:Reviews:ClickedTooEarly');
  }, [analytics, db, updateUserOwner]);

  const onYesClick = useCallback(() => {
    if (isApp) {
      setCurrentScreen('rate-app');
    } else {
      setCurrentScreen('rate-chrome-extension-1');
    }
    updateUserOwner({
      last_moonsift_opinion: 'ENJOYING',
      last_moonsift_opinion_time: db.serverTimestamp(),
    });
    analytics.recordEvent('WebApp:Reviews:ClickedEnjoyingMoonsift');
  }, [analytics, db, updateUserOwner, isApp]);

  const onNoClick = useCallback(() => {
    setCurrentScreen('feedback');
    updateUserOwner({
      last_moonsift_opinion: 'NOT_ENJOYING',
      last_moonsift_opinion_time: db.serverTimestamp(),
    });
    analytics.recordEvent('WebApp:Reviews:ClickedNotEnjoyingMoonsift');
  }, [analytics, db, updateUserOwner]);

  const onRateUsOnChromeStoreClick = useCallback(() => {
    setCurrentScreen('rate-chrome-extension-2');
    analytics.recordEvent('WebApp:Reviews:ClickedRateUsOnChromeStore');
  }, [analytics]);

  const onOpenChromeStoreClick = useCallback(() => {
    setCurrentScreen('rate-chrome-extension-3');
    analytics.recordEvent('WebApp:Reviews:ClickedOpenChromeStore');
    updateUserOwner({
      user_clicked_to_chrome_web_store_time: db.serverTimestamp(),
    });
  }, [analytics, db, updateUserOwner]);

  const onRateAppClick = useCallback(() => {
    onClose();
    analytics.recordEvent('WebApp:Reviews:ClickedOpenAppStore');
    updateUserOwner({
      user_clicked_to_app_store_time: db.serverTimestamp(),
    });
  }, [analytics, db, onClose, updateUserOwner]);

  /*

  EFFECTS:

  */
  useEffect(() => {
    if (showModal) {
      analytics.recordEvent('WebApp:Reviews:ShowReviewRequestModal', {
        isApp,
      });
      if (showType === 'extension') {
        updateUserOwnerOnce({
          user_requested_review_extension: db.serverTimestamp(),
        });
      } else if (showType === 'app-moonsift') {
        updateUserOwnerOnce({
          user_requested_review_app_moonsift: db.serverTimestamp(),
        });
      }
    }
  }, [analytics, showModal, isApp, db, showType, updateUserOwnerOnce]);

  return showModal ? (
    <ReviewRequest
      isOpen={showModal}
      onClose={onClose}
      minHeight={currentScreen === 'feedback' ? false : 600}
      showModal={showModal}
      currentScreen={currentScreen}
      onNoClick={onNoClick}
      onYesClick={onYesClick}
      onTooEarlyClick={onTooEarlyClick}
      isMobile={isMobile}
      setCurrentScreen={setCurrentScreen}
      onRateUsOnChromeStoreClick={onRateUsOnChromeStoreClick}
      onOpenChromeStoreClick={onOpenChromeStoreClick}
      onRateAppClick={onRateAppClick}
      {...props}
    />
  ) : null;
};

export { ReviewRequestContainer as ReviewRequest };
