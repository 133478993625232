/*

General ability to enable notifications about price drops, comments etc.

Enables notifcations from this panel that can be in the GetNotifications popup or the settings view.

TODO: camelCase, typescript.

*/
import React, { useState, useContext } from 'react';
import styled from 'styled-components';

import MEDIA from 'src/mvp22/media';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';
import { M } from 'src/mvp22/constants';
import { ServicesContext } from 'src/ServicesContext';
import { checkIfSafari } from 'src/utils/checkIfSafari';
import { BaseComponentProps } from 'src/types';

const EnableNotificationsButtonContainer = styled.div`
  flex-grow: 0;
  display: flex;
`;

const EnableNotificationsButton = styled(TEXTSTYLE.LINKA)`
  padding: 0px 12px;
  display: flex;
  align-items: center;
  align-content: center;
  height: 36px;
  background: ${M.COL.BUTTON.WHITE};
  border: 1px solid ${M.COL.BUTTON.BLACK};
  box-sizing: border-box;
  border-radius: 2px;
  justify-content: space-evenly;
  cursor: pointer;
  opacity: 1;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  &:hover {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
`;

const EnableNotificationsText = styled(TEXTSTYLE.OTHER4)`
  display: inline-block;
  width: 200px;
`;

const EnableNotificationsIcon = styled.img`
  display: inline-block;
  height: 16px;
  margin-right: 12px;
`;

const ErrorNotificationsEnable = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.ERROR};
  margin-top: 12px;
  text-align: left;
`;

const NotificationEnablerContainer = styled.div`
  display: flex;
  margin: 12px 0px;
  flex-direction: column;
`;

const NotificationNotification = styled(TEXTSTYLE.BODY4)`
  background: ${M.COL.BUTTON.LIGHTYELLOW};
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 12px;
  text-align: left;
  margin-top: 16px;
`;

interface EnableNotifcationsProps extends BaseComponentProps {
  setNotificationsEnabled: React.Dispatch<React.SetStateAction<boolean | null>>;
  show_notification_notification: boolean;
}

const EnableNotifications: React.FC<EnableNotifcationsProps> = ({
  setNotificationsEnabled,
  show_notification_notification,
}) => {
  const { mobile, notifications } = useContext(ServicesContext);
  const { isApp } = mobile;
  const isSafari = checkIfSafari();
  const [requesting_notifications, setRequestingNotifications] =
    useState(false);
  const [notifications_enabled_message, setNotificationsEnabledMessage] =
    useState('');
  const [enable_clicked_once, setEnableClickedOnce] = useState(false);
  const enableNotifications = async function (event: React.MouseEvent) {
    event.preventDefault();
    if (requesting_notifications === false) {
      setRequestingNotifications(true);
      setEnableClickedOnce(true);

      let new_notifications_enabled_message = '';
      let new_notifications_enabled = false;

      try {
        if (isApp) {
          await notifications!.enableAppNotifications();
          new_notifications_enabled = true;
        } else {
          // If a notification permission isn't already granted, this method
          // asks the user for permission. The returned promise rejects if the
          // user does not allow the app to show notifications.
          const response = await notifications!.enableBrowserNotifications();
          if ('data' in response && response.data === true) {
            new_notifications_enabled = true;
          } else {
            new Error('Fast API failed');
          }
        }
      } catch (error) {
        console.log('Failed to set up notifications', error);
        new_notifications_enabled_message =
          'Failed to enable notifications. ' +
          'Currently you can only enable Chrome notifications on your Desktop or Android devices, and app notifications. ' +
          'Also check that you have not blocked them in your browser or your smartphone settings.';
      }
      setRequestingNotifications(false);
      setNotificationsEnabledMessage(new_notifications_enabled_message);
      setNotificationsEnabled(new_notifications_enabled);
    }
    return false;
  };

  return (
    <NotificationEnablerContainer>
      {!isApp && isSafari ? (
        'Sorry, Notifications cannot be enabled on Safari at this time'
      ) : (
        <>
          <EnableNotificationsButtonContainer>
            <EnableNotificationsButton onClick={enableNotifications}>
              <EnableNotificationsIcon src={MEDIA.BELL} />
              <EnableNotificationsText>
                {requesting_notifications === true ? (
                  <LoadingDots msstyle="dark" />
                ) : (
                  'Enable notifications on this device'
                )}
              </EnableNotificationsText>
            </EnableNotificationsButton>
          </EnableNotificationsButtonContainer>
          {notifications_enabled_message !== '' ? (
            <ErrorNotificationsEnable>
              {notifications_enabled_message}
            </ErrorNotificationsEnable>
          ) : null}
          {show_notification_notification === true ? (
            <NotificationNotification>
              {enable_clicked_once === true ? (
                <>
                  Click <b>Allow</b> in the pop-up to enable notifications (you
                  can turn these off any time).
                </>
              ) : (
                <>
                  Enable notifications so you'll know about price drops and
                  comments on items you've saved.
                </>
              )}
            </NotificationNotification>
          ) : null}
        </>
      )}
    </NotificationEnablerContainer>
  );
};

export default EnableNotifications;
