import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/index';
import {
  AddAnItemContainerProps,
  AddAnItemProps,
  AddAnItemTabType,
} from './AddAnItem.types';
import { AddAnItem } from './AddAnItem.component';
import { useTheme } from 'styled-components';

const AddAnItemContainer: React.FC<AddAnItemContainerProps> = ({
  onClose,
  ...otherProps
}) => {
  const [openTab, setOpenTab] = useState<AddAnItemTabType>('PASTE_A_LINK');

  const handleTabChange = useCallback<AddAnItemProps['onTabChange']>(
    (_event, addType) => {
      setOpenTab(addType);
    },
    [setOpenTab],
  );

  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id ?? null,
  );
  const collectionUserUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.user_uid ?? '',
  );
  const isMine = collectionUserUID === authUserUID;

  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  return (
    <AddAnItem
      openTab={openTab}
      onTabChange={handleTabChange}
      isMine={isMine}
      isMobile={isMobile}
      onClose={onClose}
      {...otherProps}
    />
  );
};

export { AddAnItemContainer as AddAnItem };
