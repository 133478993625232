/*

The image in the collection tile that displays 3 collection items.

TODO: Typescript + camelCase

*/

import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';
/*
height: 220px OR 440px
width: 313px OR 626px
*/
const TileContainer = styled.div`
  display:flex;
  box-sizing: border-box;
  border-radius: ${props=> props.isCarouselTile === true ? '0px' : '5px'};
  overflow:hidden;
  flex-shrink:0;
  background-color: ${props => props.backgroundColor};
`;

const LeftImage = styled.div`
  height:${props=>props.isLarge?"440px":"220px"};
  width:${props=>props.isLarge?"440px":"220px"};
  margin-right:${props=>props.isLarge?"12px":"6px"};
  background-color:${M.COL.BUTTON.LIGHTYELLOW};
  background-size: cover;
  background-image: url("${props=>props.url}");
  background-position: center;
  box-sizing:border-box;
`;

const RightImage = styled.div`
  height:${props=>props.isLarge?"214px":"107px"};
  width:${props=>props.isLarge?"174px":"87px"};
  background-color:${M.COL.LINE.MID};
  background-size: cover;
  background-image: url("${props=>props.url}");
  background-position: center;
  box-sizing:border-box;
`;

const RightImagesContainer = styled.div`
  display:flex;
  flex-direction:column;
`;

const Sep = styled.div`
width:100%;
height:${props=>props.isLarge?"12px":"6px"};
`;

/**
* Collection Preview Class
*/
export default class CollectionPreview extends Component {
  static propTypes = {
    /** is large tile or not **/
    isLarge: PropTypes.bool,
    /** is coloured carousel tile or not **/
    isCarouselTile: PropTypes.bool
  }

  render(){
    const image_srcs = {};
    if (this.props.data!==undefined){
      for (var i=0;i<3;i++){
        if (this.props.data[i]!==undefined){
          image_srcs[i] = this.props.data[i];
        }
      }
    }
    return(
      <TileContainer
       isLarge={this.props.isLarge}
       isCarouselTile={this.props.isCarouselTile}
       backgroundColor={this.props.backgroundColor}
       className="CollectionPreview"
      >
        <LeftImage url={image_srcs[0]} isLarge={this.props.isLarge}/>
        <RightImagesContainer>
          <RightImage url={image_srcs[1]} isLarge={this.props.isLarge}/>
          <Sep isLarge={this.props.isLarge}/>
          <RightImage url={image_srcs[2]} isLarge={this.props.isLarge}/>
        </RightImagesContainer>
      </TileContainer>
    );
  }
}
