/*
Adds some additional fields to collection document snapshots
Is now in a parser so don't worry about it!
*/
export default function collection_data_proc(auth_uid,snapshot){
  const this_data = snapshot.data();
  this_data.uid = snapshot.id;
  this_data.global_uid = this_data.user_uid+"__"+this_data.uid;
  this_data.is_mine = (this_data.user_uid===auth_uid)?true:false;
  this_data.public_level = (this_data.public_level)?this_data.public_level:0;
  this_data.like_count = (this_data.like_count)?this_data.like_count:0;
  return this_data;
}
