/*

The main menu popup from the burger icon

TODO: functional, typescript, camelCase

*/
import React, { Component } from 'react';
import styled from 'styled-components';
import { ServicesContext } from 'src/ServicesContext';
import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  LockClosedOutlinedIcon,
  iconBaseClassNames,
} from '@moonsifttech/design-system';

import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import windowSize from 'src/mvp22/WindowSize';
import R, { EXTERNAL } from 'src/routes';

const InlineBody = styled(TEXTSTYLE.BODY4)`
  display: inline-block;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  color: ${M.COL.TEXT.BLACK};
  &:hover {
    background-color: ${M.COL.BG.MID};
  }

  .${iconBaseClassNames.root} {
    margin-left: 8px;
  }
`;

export const MenuPopup = styled.div`
  min-width: 280px;
  overflow-y: auto;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  position: fixed;
  top: 60px;
  right: ${(props) => (props.hug === 'edge' ? '0px' : props.inner_pos + 'px')};
  z-index: 10001;
  color: ${M.COL.TEXT.BLACK};
  margin-right: 22px;
  border-radius: 6px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`;

const SeparatorLine = styled.div`
  height: 1px;
  background-color: ${M.COL.LINE.MID};
  width: 100%;
`;

const TopOfTheMenuContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  a {
    line-height: 0;
  }
`;

const BackArrow = styled.img`
  height: 16px;
  padding-right: 8px;
  cursor: pointer;
`;

const NotificationIndicatorInMenu = styled(TEXTSTYLE.OTHER6)`
  font-weight: bold;
  border-radius: 20px;
  background-color: ${M.COL.BUTTON.LIGHTYELLOW};
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  padding: 0px 8px;
  margin-left: 10px;
`;

/**
 * Burger Menu
 */
class BurgerMenu extends Component {
  static contextType = ServicesContext;
  constructor() {
    super();
    this.checkclick = this.checkclick.bind(this);
    this.toggle_preferences_menu = this.toggle_preferences_menu.bind(this);
    this.onAddToChromeClick = this.onAddToChromeClick.bind(this);
    this.domRef = React.createRef();
    this.state = {
      preferences_block: false,
      open: false,
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.checkclick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.checkclick, false);
  }

  // click out to hide but not on the burger icon as this toggles already!
  checkclick(event) {
    if (this.domRef.current && this.props.domBurgerRef.current) {
      if (
        !this.domRef.current.contains(event.target) &&
        !this.props.domBurgerRef.current.contains(event.target)
      ) {
        // Set back to main menu
        this.props.burger_toggle();
        this.setState({
          preferences_block: false,
        });
      }
    }
  }

  toggle_preferences_menu(event) {
    event.preventDefault();
    this.setState((prevState) => {
      return {
        preferences_block: !prevState.preferences_block,
      };
    });
    return false;
  }

  componentDidUpdate() {
    // Ensure that it always reopens to the main menu not submenus:.
    this.setState((prevState, props) => {
      if (prevState.open !== props.open) {
        return {
          preferences_block: false,
          open: props.open,
        };
      }
    });
  }

  onMenuItemClick = () => {
    // Set back to main menu
    this.props.burger_toggle();
    this.setState({
      preferences_block: false,
    });
  };

  onAddToChromeClick = () => {
    const { analytics } = this.context;
    analytics.recordEvent('WebApp:ChromeWebstore:Pressed');
    this.onMenuItemClick();
  };

  // TODO: refactor to avoid defining everything each time we render...
  render() {
    const { creatorStatus } = this.props;
    const isAppliedCreator = creatorStatus === 'applied';
    const isAcceptedCreator = creatorStatus === 'accepted';
    const isCreator = isAppliedCreator || isAcceptedCreator;

    const LOGOUT_BLOCK = (
      <TEXTSTYLE.LINK key="logout" to={R.LOGOUT} onClick={this.onMenuItemClick}>
        <InlineBody selected={false}>Log out</InlineBody>
      </TEXTSTYLE.LINK>
    );
    const SIGNUP_BLOCK = (
      <TEXTSTYLE.LINK key="signup" to={R.SIGNUP} onClick={this.onMenuItemClick}>
        <InlineBody selected={this.props.selected === R.SIGNUP}>
          Sign up
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const HOW_TO_SAVE_BLOCK = (
      <TEXTSTYLE.LINK
        key="howto"
        to={R.HOW_TO_SAVE}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.HOW_TO_SAVE}>
          How to Save Items
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const EXPLORE_BLOCK = (
      <TEXTSTYLE.LINK
        key="explore"
        to={R.EXPLORE_FEATURED}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === 'EXPLORE'}>
          Explore Collections
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const NOTIFICATIONS_BLOCK =
      this.props.windowWidth <= M.MOBILESWITCH ? (
        <TEXTSTYLE.LINK
          key="notifications"
          to={R.NOTIFICATIONS}
          onClick={this.onMenuItemClick}
        >
          <InlineBody selected={this.props.selected === 'NOTIFICATIONS'}>
            Notifications
            {this.props.notification_indicator_text ? (
              <NotificationIndicatorInMenu>
                {this.props.notification_indicator_text}
              </NotificationIndicatorInMenu>
            ) : (
              ''
            )}
          </InlineBody>
        </TEXTSTYLE.LINK>
      ) : (
        ''
      );
    const TASTEMAKER_BLOCK = (
      <TEXTSTYLE.LINK
        key="tastemakers"
        to={R.ALL_CREATORS}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.ALL_CREATORS}>
          Moonsift for Creators & Stylists
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const ABOUT_BLOCK = (
      <TEXTSTYLE.LINK
        key="about"
        to={R.ABOUT_US}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.ABOUT_US}>
          About Moonsift
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const AI_BLOCK = (
      <TEXTSTYLE.LINKA key="about" href={EXTERNAL.AI_LANDING}>
        <InlineBody>AI Shopping Copilot</InlineBody>
      </TEXTSTYLE.LINKA>
    );
    const CREATOR_BLOCK = (
      <TEXTSTYLE.LINK
        key="creator"
        to={R.CREATOR_DASHBOARD}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.CREATOR_DASHBOARD}>
          My Earnings{' '}
          {isAppliedCreator && <LockClosedOutlinedIcon size="small" />}
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const WEBSTORE_BLOCK = (
      <TEXTSTYLE.LINKA
        key="webstore"
        href={EXTERNAL.CHROMESTORE}
        target="_blank"
        onClick={this.onAddToChromeClick}
      >
        <InlineBody selected={false}>Add Moonsift to Chrome</InlineBody>
      </TEXTSTYLE.LINKA>
    );
    const PRO_SHARE_BLOCK =
      this.props.is_pro === true ? (
        <TEXTSTYLE.LINK
          key="mypropasses"
          to={R.PRO_SHARE}
          onClick={this.onMenuItemClick}
        >
          <InlineBody selected={this.props.selected === R.PRO_SHARE}>
            My Pro Passes
          </InlineBody>
        </TEXTSTYLE.LINK>
      ) : (
        ''
      );
    const RETAILERS_BLOCK = (
      <TEXTSTYLE.LINK
        key="retailers"
        to={isAcceptedCreator ? R.AFFILIATE_BRANDS : R.RETAILERS}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.RETAILERS}>
          List of Stores
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const TERMS_BLOCK = (
      <TEXTSTYLE.LINK key="terms" to={R.TERMS} onClick={this.onMenuItemClick}>
        <InlineBody selected={this.props.selected === R.TERMS}>
          Terms & Privacy
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const PREFERENCES_BLOCK = (
      <TEXTSTYLE.LINKA
        key="preferences"
        href="#"
        onClick={this.toggle_preferences_menu}
      >
        <InlineBody selected={this.props.selected === R.TERMS}>
          Settings & Preferences
        </InlineBody>
      </TEXTSTYLE.LINKA>
    );
    const PREFERENCES_HEADER = (
      <TopOfTheMenuContainer key="pref_header">
        <TEXTSTYLE.LINKA
          key="preferences"
          href="#"
          onClick={this.toggle_preferences_menu}
        >
          <BackArrow src={MEDIA.BACK_ARROW} />
        </TEXTSTYLE.LINKA>
        <TEXTSTYLE.HEADING5>Settings & Preferences</TEXTSTYLE.HEADING5>
      </TopOfTheMenuContainer>
    );
    const PREFERENCES_PROFILE_BLOCK = (
      <TEXTSTYLE.LINK
        key="preferences_profile"
        to={R.EDIT_PROFILE}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.EDIT_PROFILE}>
          Edit Profile
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const PREFERENCES_EMAIL_BLOCK = (
      <TEXTSTYLE.LINK
        key="preferences_email"
        to={R.EDIT_EMAIL_PREFERENCES}
        onClick={this.onMenuItemClick}
      >
        <InlineBody selected={this.props.selected === R.EDIT_EMAIL_PREFERENCES}>
          Emails & Notifications
        </InlineBody>
      </TEXTSTYLE.LINK>
    );
    const PREFERENCES_ACCOUNT_BLOCK = (
      <TEXTSTYLE.LINK
        key="preferences_account"
        to={R.EDIT_ACCOUNT_PREFERENCES}
        onClick={this.onMenuItemClick}
      >
        <InlineBody
          selected={this.props.selected === R.EDIT_ACCOUNT_PREFERENCES}
        >
          Account Settings
        </InlineBody>
      </TEXTSTYLE.LINK>
    );

    const INNER_POS =
      this.props.windowWidth - 1200 > 0
        ? (this.props.windowWidth - 1200) / 2
        : 0;
    //        <BGCover zIndex="10000" onClick={this.burger_toggle}/>
    return this.props.open === true ? (
      <MenuPopup ref={this.domRef} hug={this.props.hug} inner_pos={INNER_POS}>
        {this.state.preferences_block === true
          ? [
              PREFERENCES_HEADER,
              PREFERENCES_PROFILE_BLOCK,
              PREFERENCES_EMAIL_BLOCK,
              PREFERENCES_ACCOUNT_BLOCK,
            ]
          : this.props.loggedin === true
          ? [
              HOW_TO_SAVE_BLOCK,
              EXPLORE_BLOCK,
              isCreator ? CREATOR_BLOCK : null,
              NOTIFICATIONS_BLOCK,
              RETAILERS_BLOCK,
              <SeparatorLine key={'sep1'} />,
              WEBSTORE_BLOCK,
              PRO_SHARE_BLOCK,
              ABOUT_BLOCK,
              TASTEMAKER_BLOCK,
              <SeparatorLine key={'sep2'} />,
              PREFERENCES_BLOCK,
              TERMS_BLOCK,
              LOGOUT_BLOCK,
            ]
          : [
              ABOUT_BLOCK,
              AI_BLOCK,
              HOW_TO_SAVE_BLOCK,
              EXPLORE_BLOCK,
              TASTEMAKER_BLOCK,
              RETAILERS_BLOCK,
              SIGNUP_BLOCK,
            ]}
      </MenuPopup>
    ) : (
      ''
    );
  }
}

const mapStateToProps = ({ db }) => ({
  creatorStatus: db.user_verified_settings.data?.creator?.status,
});

export default compose(connect(mapStateToProps), windowSize)(BurgerMenu);
