import { VFC } from 'react';
import styled from 'styled-components';
import { CtaButton, Typography } from '@moonsifttech/design-system';
import { Redirect } from 'react-router-dom';

import R from 'src/routes';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { Page } from 'src/components/common/Page';
import { ProWelcomeComponentProps } from './ProWelcome.types';
import { Helmet } from 'react-helmet';

const UnstyledProWelcome: VFC<ProWelcomeComponentProps> = ({
  className,
  style,
  isPro,
  workingReload,
  onReloadClick,
}) => {
  if (isPro) {
    console.log('PROMAN');
    return <Redirect to={R.PRO_SHARE} />;
  }

  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Helmet>
        <title>Welcome To Pro - Moonsift</title>
      </Helmet>
      <div className="ProTrial-content">
        <Typography variant="editorial.sb34" component="h1">
          Upgrade to Pro successful
        </Typography>
        <Typography variant="primary.l16" component="p">
          You need to reload Moonsift to access Pro features.
        </Typography>
        <CtaButton
          variant="primary-black"
          component={UnstyledLink}
          fullWidth
          loading={workingReload}
          onClick={onReloadClick}
        >
          Reload Moonsift
        </CtaButton>
      </div>
    </Page>
  );
};

export const ProWelcome = styled(UnstyledProWelcome)`
  .ProTrial-content {
    max-width: 432px;
    padding: 0 16px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px;
  }
`;
