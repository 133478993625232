/*
Master Modal component that renders all the other modals depending on modal redux state.
*/
import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import R from 'src/routes';
import { ProductModal } from './ProductModal';
import CollectionMembershipModal from './CollectionMembershipModal';
import CollectionPropertiesModal from './CollectionPropertiesModal';
import { ProductUpdatesModal } from './ProductUpdatesModal';
import { SignUpToViewModal } from './SignUpToViewModal';
import ProductNotFoundModal from './ProductNotFoundModal';
import DeleteCollectionModal from './DeleteCollectionModal';
import { useSelector, useDispatch } from 'react-redux';
import { useWorkingDynamic } from 'src/hooks/useWorkingDynamic';
import { actionCreator } from 'src/mvp22/redux-components/actions';
import { FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP } from 'src/mvp22/redux-components/reducers/firestore_product_membership';
import update_product_opinion from 'src/mvp22/firebase-functions/update_product_opinion';
import { ModalState, ModalTypes, ProductData } from 'src/types';
import { useModal } from 'src/hooks/useModal';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { GoToItemDialog } from 'src/components/collection/GoToItemDialog';
import { MarkAsBoughtDialog } from 'src/components/collection/MarkAsBoughtDialog';
import { BoughtByDialog } from 'src/components/collection/BoughtByDialog';
import { UnmarkAsBoughtDialog } from 'src/components/collection/UnmarkAsBoughtDialog';
import { UnmarkAsBoughtOwnerDialog } from 'src/components/collection/UnmarkAsBoughtOwnerDialog';
import { AlreadyBoughtDialog } from 'src/components/collection/AlreadyBoughtDialog';
import { MarkedAsBoughtDialog } from 'src/components/collection/MarkedAsBoughtDialog';
import { MarkedAsBoughtSlimDialog } from 'src/components/collection/MarkedAsBoughtSlimDialog';
import { MarkedAsBoughtSlimOwnerDialog } from 'src/components/collection/MarkedAsBoughtSlimOwnerDialog';
import { AddMessageDialog } from 'src/components/collection/AddMessageDialog';
import { DeliveryInfoDialog } from 'src/components/collection/DeliveryInfoDialog';
import { SetQuantityDialog } from 'src/components/collection/SetQuantityDialog';

const GeneralOverlayBG = styled.div`
  z-index: 3000;
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  background-color: #000000cc;
  justify-content: center;
  align-self: center;
  align-items: center;
`;

const GeneralOverlayBGNext = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  justify-content: center;
  align-self: center;
  align-items: center;
  display: flex;
`;
/*

Still to do for refactoring:

- typescript
- camelCase
- get values of product data's collection info from live db redux states rather than set ones (so they will close on deletion)
- in general if values are in redux there is no point in passing them down BUT we do that for productData as we store it locally.

*/

export const Modal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { firebaseMVP22: firebase } = useContext(ServicesContext);
  const [setModal, closeModal] = useModal();
  const modal = useSelector<RootState, ModalState>((state) => state.modal);
  const [setWorking, unsetWorking, workingDict] = useWorkingDynamic();
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  // TODO: update these:
  const removeProductMembershipListener = () =>
    dispatch(actionCreator(FIRESTORE_REMOVE_PRODUCT_MEMBERSHIP));
  const rawMembershipInfo = useSelector<RootState>(
    (state) => state.firestore_product_membership.raw_membership_info,
  );
  const displayUsername = useSelector<RootState>((state) =>
    state.firestore_user_owner
      ? state.firestore_user_owner.display_username
      : undefined,
  );

  // Set membership things locally because removing it from the current collection could mess things up!
  // Do it here because can jump between sub-modals:
  const [productDataLocal, setProductDataLocal] = useState<
    ProductData | undefined
  >(undefined);
  const [modalLocal, setModalLocal] = useState<ModalTypes | null>(null);
  const productDataStoreAs =
    modal.type === ModalTypes.NewCollectionProduct ||
    modal.type === ModalTypes.CollectionMembership
      ? getCollectionItemStoreAs(
          modal.collectionUID,
          modal.collectionUserUID,
          modal.collectionItem.id,
        )
      : '';
  const productData = useSelector<RootState, ProductData | undefined>((state) =>
    state.productData ? state.productData[productDataStoreAs] : undefined,
  );
  useEffect(() => {
    // Only run when loading the product-membership modal from nothing:
    // Here due to jumping between New collection + product membership
    // Means that if product is deleted / removed from current collection it will still be possible to use that data to save it elsewhere / view the image on the left.
    if (
      (modalLocal === null || modal.type === null) &&
      modal.type !== modalLocal
    ) {
      setModalLocal(modal.type);
      setProductDataLocal(productData);
    }
  }, [modal.type, modalLocal, productData]);

  // For jumping around within modal groups and keeping other data in redux:
  const goToModalCollectionSettingsGroup = (
    event: undefined | React.MouseEvent,
    newModalType: string,
  ) => {
    if (
      (modal.type === ModalTypes.CollectionSettings ||
        modal.type === ModalTypes.DeleteCollection) &&
      (newModalType === ModalTypes.CollectionSettings ||
        newModalType === ModalTypes.DeleteCollection)
    ) {
      setModal({
        ...modal,
        type: newModalType,
      });
    }
    if (event) {
      event.preventDefault();
      return false;
    }
  };
  const goToModalCollectionMembership = (
    event: undefined | React.MouseEvent,
    newModalType: string,
  ) => {
    if (
      (modal.type === ModalTypes.NewCollectionProduct ||
        modal.type === ModalTypes.CollectionMembership) &&
      (newModalType === ModalTypes.NewCollectionProduct ||
        newModalType === ModalTypes.CollectionMembership)
    ) {
      setModal({
        ...modal,
        type: newModalType,
      });
    }
    if (event) {
      event.preventDefault();
      return false;
    }
  };

  // This is here as used by multiple modals (collection membership + create new collection product for setting initial membership)
  // TODO: consider moving some of these functions into modals?
  const setCollectionMembership = (
    settingCollectionUID: string,
    opinion: boolean,
  ) => {
    if (
      (modal.type === ModalTypes.CollectionMembership ||
        modal.type === ModalTypes.NewCollectionProduct) &&
      authUserUID !== null
    ) {
      const currentCollectionUID = modal.collectionUID;
      const itemUID = modal.collectionItem.id;
      const working_code =
        settingCollectionUID +
        '__' +
        currentCollectionUID +
        '__' +
        itemUID +
        '__set_collection_membership';
      if (workingDict[working_code] === undefined) {
        setWorking(working_code, opinion);
        update_product_opinion(
          opinion,
          settingCollectionUID,
          modal.collectionItem,
          productDataLocal ? productDataLocal.price : '', // price when product loaded in - required to be saved in case removed from current collection!
          rawMembershipInfo,
          firebase,
          authUserUID,
        )
          .then(() => unsetWorking(working_code))
          .catch(() => unsetWorking(working_code));
      }
    }
  };
  const afterMakingProductNewCollection = (
    collectionUID: string,
    productData: ProductData,
    collectionItem: CollectionItem,
  ) => {
    goToModalCollectionMembership(undefined, ModalTypes.CollectionMembership);
    if (collectionUID) {
      setCollectionMembership(collectionUID, true);
    }
  };
  // after new collection not in product membership group:
  const afterMakingNewCollection = (
    collectionUID: string,
    productData: ProductData,
    collectionItem: CollectionItem,
  ) => {
    if (modal.type === ModalTypes.NewCollection) {
      // for typescript
      const dont_redirect = modal.dontRedirect;
      if (dont_redirect !== true) {
        history.push(
          R.COLLECTION + '/' + displayUsername + '/' + collectionUID,
        );
      }
      closeModal();
    }
  };
  const afterUpdatingCollection = () => {
    closeModal();
  };

  // RENDER:
  const renderContent = (modal: ModalState) => {
    switch(modal.type) {
      case null: {
        return null;
      }
      case ModalTypes.NewCollection: {
        return (
          <CollectionPropertiesModal
            modal_type="NewCollection"
            closeFunction={closeModal}
            afterAction={afterMakingNewCollection}
          />
        );
      }
      case ModalTypes.CollectionSettings: {
        return (
          <CollectionPropertiesModal
            modal_type="CollectionSettings"
            collectionUID={modal.collectionUID}
            collection_name_input={modal.collectionName}
            collection_description_input={modal.collectionDescription}
            closeFunction={closeModal}
            afterAction={afterUpdatingCollection}
            openDeleteCollectionModal={(event: React.MouseEvent) =>
              goToModalCollectionSettingsGroup(
                event,
                ModalTypes.DeleteCollection,
              )
            }
          />
        );
      }
      case ModalTypes.DeleteCollection: {
        return (
          <DeleteCollectionModal
            collection_name={modal.collectionName}
            collection_uid={modal.collectionUID}
            closeFunction={closeModal}
            backFunction={(event: React.MouseEvent) =>
              goToModalCollectionSettingsGroup(
                event,
                ModalTypes.CollectionSettings,
              )
            }
          />
        );
      }
      case ModalTypes.CollectionMembership: {
        return (
          <CollectionMembershipModal
            collectionUID={modal.collectionUID}
            collectionUserUID={modal.collectionUserUID}
            collectionItemUID={modal.collectionItem.id}
            productImage={modal.productImage}
            productData={productDataLocal}
            workingDict={workingDict}
            closeFunction={(event?: React.MouseEvent) => {
              if (event) {
                event.preventDefault();
              }
              closeModal();
              removeProductMembershipListener();
            }}
            openAddToNewCollection={(event: React.MouseEvent) =>
              goToModalCollectionMembership(
                event,
                ModalTypes.NewCollectionProduct,
              )
            }
            setCollectionMembership={setCollectionMembership}
          />
        );
      }
      case ModalTypes.NewCollectionProduct: {
        return (
          <CollectionPropertiesModal
            modal_type="NewCollectionProduct"
            collection_name_input={''}
            collection_description_input={''}
            closeFunction={closeModal}
            collectionUID={null} // DO NOT PASS IN collectionUID as this will edit current collection not create a new one!  Ask if this doesn't make sense, it is weird.
            // FROM PRODUCT MEMBERSHIP modal (also used here)
            collectionUserUID={modal.collectionUserUID}
            collectionItem={modal.collectionItem}
            productData={productDataLocal}
            productImage={modal.productImage}
            backToMembershipProperties={(event: React.MouseEvent) =>
              goToModalCollectionMembership(
                event,
                ModalTypes.CollectionMembership,
              )
            }
            afterAction={afterMakingProductNewCollection}
          />
        );
      }
      case ModalTypes.ProductUpdates: {
        return (
          <ProductUpdatesModal
            collectionUID={modal.collectionUID}
            collectionUserUID={modal.collectionUserUID}
            itemUID={modal.itemUID}
            productImage={modal.productImage}
            closeFunction={closeModal}
          />
        );
      }
      case ModalTypes.ProductSettings: {
        return (
          <ProductModal
            collectionUID={modal.collectionUID}
            collectionUserUID={modal.collectionUserUID}
            itemUID={modal.itemUID}
            showProductModal={modal.showProductModal}
          />
        );
      }
      case ModalTypes.ProductDoesNotExist: {
        return (
          <ProductNotFoundModal closeFunction={modal.closeProductModal} />
        );
      }
      case ModalTypes.SignUpToView: {
        return (
          <SignUpToViewModal
            className=""
            closeFunction={closeModal}
          />
        );
      }
      case ModalTypes.GoToItem: {
        return (
          <GoToItemDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.MarkAsBought: {
        return (
          <MarkAsBoughtDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.BoughtBy: {
        return (
          <BoughtByDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.Unmark: {
        return (
          <UnmarkAsBoughtDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.UnmarkOwner: {
        return (
          <UnmarkAsBoughtOwnerDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.AlreadyBought: {
        return (
          <AlreadyBoughtDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.MarkedAsBought: {
        return (
          <MarkedAsBoughtDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.MarkedAsBoughtSlim: {
        return (
          <MarkedAsBoughtSlimDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.MarkedAsBoughtSlimOwner: {
        return (
          <MarkedAsBoughtSlimOwnerDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.AddMessage: {
        return (
          <AddMessageDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      case ModalTypes.DeliveryInfo: {
        return (
          <DeliveryInfoDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            back={modal.back}
          />
        );
      }
      case ModalTypes.SetQuantity: {
        return (
          <SetQuantityDialog
            collectionId={modal.collectionId}
            collectionUserId={modal.collectionUserId}
            itemId={modal.itemId}
          />
        );
      }
      default: {
        const _exhaust: never = modal;
        return _exhaust;
      }
    }
  };

  return modal.type !== null ? (
    <GeneralOverlayBG>
      <GeneralOverlayBGNext>{renderContent(modal)}</GeneralOverlayBGNext>
    </GeneralOverlayBG>
  ) : null;
};
