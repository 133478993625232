/*

Displays reactions and comments in the product modal, also has the ability to write a new comment if logged in.


TODO:camelCase, typescript, container/component split
Make getting list of comments and listening for more a db hook.  As loading in more adds to list need to be careful about how this is done...
Could give a storeAs with an index?  A new hook would be required almost certainly this way.
Otherwise could use another reducer / saga or add option to add to list if possible.
Remember to clear the list in this case on unmount!!
Could also be an option in the type set-up to switch on in reducer...


Would make a good all-round starter refactor project.

*/

import React, { useEffect, useState, useContext, useRef } from 'react';
import Overlay from 'react-overlays/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import MEDIA from 'src/mvp22/media';
import Button from 'src/mvp22/form-components/Button';
import CommentTile from './CommentTile';
import { BrightInput } from 'src/mvp22/form-components/TextBox';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import ProfileImage from 'src/mvp22/image-components/ProfileImage';
import WorkingDots from 'src/mvp22/image-components/WorkingDots';
import condense_number from 'src/mvp22/core-components/condense_number';
import { ServicesContext } from 'src/ServicesContext';
import { userPublicParser } from 'src/store/parsers/userPublic';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import get_reactions_and_comments from 'src/mvp22/firebase-functions/get_reactions_and_comments';
import listen_for_new_reactions_and_comments from 'src/mvp22/firebase-functions/listen_for_new_reactions_and_comments';
import { useWorking } from 'src/hooks/useWorking';
import { ActionNames } from 'src/types';

const Tooltip = styled.div`
  width: ${(props) => (props.is_purchased ? '230px' : '160px')};
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index: 1000;
  position: absolute;
`;

const TooltipText = styled(TEXTSTYLE.BODY5)`
  padding: 10px;
`;

const CommentsContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  overflow-y: auto;
`;

const CommentsComboBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const FlexHoriz = styled.div`
  display: flex;
  width: 100%;
`;

const FlexVert = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TextBoxContainer = styled.div`
  flex-grow: 1;
`;

const TextBox = styled(BrightInput)`
  width: 100%;
  flex-shrink: 1;
  height: ${(props) => props.height + 'px'};
  overflow: hidden;
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
`;

const ButtonSpacer = styled.span`
  display: inline-block;
  width: 16px;
`;

const ProfileImageContainer = styled.div`
  align-self: flex-start;
  margin-top: 4px;
  margin-right: 16px;
`;

const CommentTileListContainer = styled.div`
  color: default;
`;

const ReactionButtonCSS = css`
  width: 30px;
  height: 30px;
  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border-radius: 15px;
  margin: 0px 4px;
`;

const ReactionButton = styled(TEXTSTYLE.LINKA)`
  ${ReactionButtonCSS}
`;

const ReactionButtonWorking = styled(WorkingDots)`
  ${ReactionButtonCSS}
  background-repeat:no-repeat;
  background-position: center;
`;

const ReactionButtonImage = styled.img`
  height: 20px;
  margin: 0px 4px;
`;

const ReactionImageContainer = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
`;

const LoadMoreContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const ReportContainer = styled.div`
  display: flex;
  align-items: center;
  padding-right: 16px;
  flex-direction: column;
`;

const ReportText = styled(TEXTSTYLE.OTHER4)`
  margin-top: 2px;
`;

const SummaryBox = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
`;

const DEFAULT_COMMENT_BOX_HEIGHT = 36;
const MAX_COMMENT_LENGTH = 1000;
const COMMENT_INCREMENT = 8;

export const CommentTileList = ({
  itemUID,
  collectionUserUID,
  collectionUID,
  isLoggedIn,
}) => {
  const dispatch = useDispatch();
  // Loading Comments:
  const [loading_more, setLoadingMore] = useState(false);
  const [live_comments_list, setLiveCommentsList] = useState([]);
  const [additional_comments_list, setAdditionalCommentsList] = useState([]);
  const [last_doc, setLastDoc] = useState(null);
  const [first_doc, setFirstDoc] = useState(null);
  const [show_load_more, setShowLoadMore] = useState(false);
  // Input:
  const [comment_input_text, setCommentInputText] = useState('');
  const [comment_input_text_height, setCommentInputTextHeight] = useState(
    DEFAULT_COMMENT_BOX_HEIGHT,
  ); //20+16
  const [comment_input_text_focus, setCommentInputTextFocus] = useState(false);
  const [comment_text_valid, setCommentTextValid] = useState(false);
  // Posting:
  const [working_post_comment, setWorkingPostComment] = useState(false);
  const [working_delete_comment, setWorkingDeleteComment] = useState({});
  const authUserUID = useSelector((state) => state.auth.id);
  // Tooltip if not loggedin:
  const triggerRef = useRef(null);
  //TODO: change these when firebase moved in and auth redone:
  const {
    firebaseMVP22: firebase,
    db: dB,
    analytics,
  } = useContext(ServicesContext);
  //const isPro = useSelector(state=>state.firestore_user_owner.pro?true:false);
  const loaded = useSelector((state) => state.firebasestate.loaded);
  //const isMine = authUserUID===collectionUserUID;
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  const [tooltipIsVisible, setVisible] = useState(false);
  // Working:
  const [setUpvoteWorking, unsetUpvoteWorking, upvoteWorkingValue] = useWorking(
    productDataStoreAs + '__upvote',
  );
  const [setDownvoteWorking, unsetDownvoteWorking, downvoteWorkingValue] =
    useWorking(productDataStoreAs + '__downvote');
  const reactionQueryResult = useSelector(
    (state) =>
      state.db.single_collection_product_is_liked_or_disliked.map[
        productDataStoreAs
      ],
  );
  const downupvoted =
    reactionQueryResult && reactionQueryResult.length === 1
      ? reactionQueryResult[0].type
      : undefined;
  // Doing it this way means you don't get a blank circle on first comment!

  // Effects:
  // Ensure we have fetched the auth user public profile:
  // User Public Profiles not removed on unmount!
  //todo: ADD TYPESCRIPT "user_public" type!
  const authUserUIDNotNull = authUserUID ?? '';
  useDBRetrieveDoc(
    dB,
    dB.userPubicDoc,
    'user_public',
    userPublicParser,
    authUserUIDNotNull,
    [authUserUIDNotNull],
    'get',
    false,
  );
  const authUserPublicProfile = useSelector(
    (state) => state.db.user_public.map[authUserUIDNotNull],
  );
  const profileImageCode = authUserPublicProfile
    ? authUserPublicProfile.profile_image_code
    : undefined;
  const profileName = authUserPublicProfile ? authUserPublicProfile.name : '';
  // extract item from list:
  const singleCollectionItemListData = useSelector(
    (state) => state.db.single_collection_item_list.data,
  );
  const collectionItemMatchingList = singleCollectionItemListData.filter(
    (x) => x.id === itemUID,
  );
  const collectionItem =
    collectionItemMatchingList.length === 1
      ? collectionItemMatchingList[0]
      : undefined;

  // Action Functions:
  const resetCommentBox = (event) => {
    if (event) {
      event.preventDefault();
    }
    setCommentInputTextFocus(false);
    setCommentInputText('');
    setCommentInputTextHeight(DEFAULT_COMMENT_BOX_HEIGHT);
    return false;
  };

  const reactProduct = (event, current_type, newType) => {
    event.preventDefault();
    const working =
      newType === 'upvote'
        ? upvoteWorkingValue
        : newType === 'downvote'
        ? downvoteWorkingValue
        : false;
    const setWorking =
      newType === 'upvote'
        ? setUpvoteWorking
        : newType === 'downvote'
        ? setDownvoteWorking
        : false;
    const unsetWorking =
      newType === 'upvote'
        ? unsetUpvoteWorking
        : newType === 'downvote'
        ? unsetDownvoteWorking
        : false;
    if (
      working === undefined &&
      setWorking !== false &&
      unsetWorking !== false
    ) {
      setWorking(true);
      const API_TO_CALL =
        current_type !== newType ? 'set_reaction' : 'unset_reaction';
      firebase
        .fast_api()({
          api: API_TO_CALL,
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          product_uid: itemUID,
          reaction: newType,
        })
        .then((result) => {
          if (result.data === true && current_type === newType) {
            // remove it from the comments list that was got not listened:
            const uid_to_remove = authUserUIDNotNull + '--' + current_type;
            setLiveCommentsList((prevState) =>
              prevState.filter((x) => x.uid !== uid_to_remove),
            );
            setAdditionalCommentsList((prevState) =>
              prevState.filter((x) => x.uid !== uid_to_remove),
            );
          }
          if (
            result.data !== false && // data can be null if success(!)
            API_TO_CALL === 'set_reaction' &&
            newType === 'upvote'
          ) {
            dispatch({
              type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
              payload: undefined,
            });
          }
          unsetWorking();
        })
        .catch(() => {
          unsetWorking();
        });
      analytics.recordEvent(`WebApp:Rection:${newType}`);
    }
    return false;
  };

  const postComment = (event) => {
    event.preventDefault();
    if (working_post_comment !== true && comment_text_valid === true) {
      setWorkingPostComment(true);
      firebase
        .fast_api()({
          api: 'add_comment',
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          product_uid: itemUID,
          comment: comment_input_text,
        })
        .then((result) => {
          setWorkingPostComment(false);
          if (result.data === true) {
            resetCommentBox();
            dispatch({
              type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
              payload: undefined,
            });
          }
        })
        .catch(() => {
          setWorkingPostComment(false);
        });
      analytics.recordEvent('WebApp:PostComment');
    }
    return false;
  };

  const unsetWorkingDeleteComment = (comment_uid) =>
    setWorkingDeleteComment((prevState) => {
      const to_return = Object.assign({}, prevState);
      delete to_return[comment_uid];
      return to_return;
    });

  const deleteComment = (event, comment_uid) => {
    event.preventDefault();
    if (working_delete_comment[comment_uid] !== true) {
      setWorkingDeleteComment((prevState) =>
        Object.assign({}, prevState, {
          [comment_uid]: true,
        }),
      );
      firebase
        .fast_api()({
          api: 'delete_comment',
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          product_uid: itemUID,
          comment_uid,
        })
        .then((result) => {
          if (result.data === true) {
            setLiveCommentsList((prevState) =>
              prevState.filter((x) => x.uid !== comment_uid),
            );
            // remove it from the comments list that was got not listened:
            setAdditionalCommentsList((prevState) =>
              prevState.filter((x) => x.uid !== comment_uid),
            );
            unsetWorkingDeleteComment(comment_uid);
          }
        })
        .catch(() => {
          unsetWorkingDeleteComment(comment_uid);
        });
    }
    return false;
  };

  const loadMore = (event) => {
    event.preventDefault();
    if (loading_more !== true) {
      // initialise the loading:
      setLoadingMore(true);
      get_reactions_and_comments(
        itemUID,
        collectionUID,
        collectionUserUID,
        last_doc,
        COMMENT_INCREMENT,
        firebase,
      ).then((result) => {
        // if no more expected then hide "load more".
        if (result.comments_list.length === COMMENT_INCREMENT) {
          setShowLoadMore(true);
        } else {
          setShowLoadMore(false);
        }
        setAdditionalCommentsList((prevState) => [
          ...prevState,
          ...result.comments_list,
        ]);
        setLastDoc(result.last_doc);
        setLoadingMore(false);
      });
    }
    return false;
  };

  const commentInputTextChange = (event) => {
    event.preventDefault();
    if (event.target.value.length < MAX_COMMENT_LENGTH) {
      setCommentInputText(event.target.value);
      setCommentInputTextHeight(event.target.scrollHeight + 2);
    }
    if (
      event.target.value.length > 0 &&
      event.target.value.length <= MAX_COMMENT_LENGTH
    ) {
      setCommentTextValid(true);
    } else {
      setCommentTextValid(false);
    }
    return false;
  };

  // LISTENER:
  useEffect(() => {
    // Ensure is only working with valid user data:
    // (when we have run the first comments query)
    if (first_doc !== null) {
      // catch case is false, i.e. no comments at first.
      return listen_for_new_reactions_and_comments(
        itemUID,
        collectionUID,
        collectionUserUID,
        first_doc,
        firebase,
        (result) => setLiveCommentsList(result.comments_list),
      );
    }
  }, [firebase, first_doc, itemUID, collectionUID, collectionUserUID]);
  // LOADING IN BULK:
  useEffect(() => {
    // If firebaseState changes then wooty woot let's set some stuff:
    // LISTEN to the top 10 notifications coming through:
    get_reactions_and_comments(
      itemUID,
      collectionUID,
      collectionUserUID,
      null,
      COMMENT_INCREMENT,
      firebase,
    ).then((result) => {
      if (result.comments_list.length === COMMENT_INCREMENT) {
        setShowLoadMore(true);
      } else {
        setShowLoadMore(false);
      }
      setAdditionalCommentsList(result.comments_list);
      setLastDoc(result.last_doc);
      setFirstDoc(result.first_doc);
    });
    // return the clean up function:
    return () => {
      setLastDoc(null);
      setFirstDoc(null);
    };
  }, [firebase, itemUID, collectionUID, collectionUserUID]);

  // redone here since we want to calculate these all at once.
  const TIME_NOW = firebase.serverTimestamp();
  const NOTIFICATION_ARRAY = [
    [
      MEDIA.REPORT_UPVOTES,
      collectionItem ? collectionItem.upvote_count : 0,
      'upvote',
      upvoteWorkingValue,
    ],
    [
      MEDIA.REPORT_DOWNVOTES,
      collectionItem ? collectionItem.downvote_count : 0,
      'downvote',
      downvoteWorkingValue,
    ],
    [
      MEDIA.REPORT_COMMENTS,
      collectionItem ? collectionItem.comment_count : 0,
      null,
    ],
  ];

  const isRegistry = useSelector(
    ({ db }) => db.single_collection.data?.is_registry ?? false,
  );

  // TODO: re-liking will product an error about multiple indices... does not affect functionality it seems.
  return (
    <CommentsContainer>
      {!isRegistry && (
        <SummaryBox
          ref={triggerRef}
          onClick={(event) => {
            if (!isLoggedIn) {
              event.preventDefault();
              setVisible(true);
              return false;
            }
          }}
        >
          {NOTIFICATION_ARRAY.map((arr, index) => {
            return (
              <ReportContainer key={index}>
                {arr[2] ? (
                  arr[3] !== undefined ? (
                    <ReactionButtonWorking />
                  ) : (
                    <ReactionButton
                      key={arr[2]}
                      href="#"
                      onClick={(event) =>
                        reactProduct(event, downupvoted, arr[2])
                      }
                    >
                      <ReactionButtonImage
                        src={
                          downupvoted === arr[2]
                            ? MEDIA[`BUTTON_${arr[2].toUpperCase()}_SELECTED`]
                            : MEDIA[`BUTTON_${arr[2].toUpperCase()}`]
                        }
                      />
                    </ReactionButton>
                  )
                ) : (
                  <ReactionImageContainer>
                    <ReactionButtonImage src={arr[0]} />
                  </ReactionImageContainer>
                )}
                <ReportText>{condense_number(arr[1])}</ReportText>
              </ReportContainer>
            );
          })}
        </SummaryBox>
      )}
      {authUserUID !== null && loaded === true ? (
        <CommentsComboBox>
          <ProfileImageContainer>
            <ProfileImage
              profile_image_code={profileImageCode}
              name={profileName}
              user_uid={authUserUID}
              scale={27}
            />
          </ProfileImageContainer>
          <FlexVert>
            <FlexHoriz>
              <TextBoxContainer>
                <TextBox
                  maxLength={MAX_COMMENT_LENGTH}
                  resize="none"
                  placeholder="Add a comment"
                  msstyle="standard"
                  value={comment_input_text}
                  onChange={commentInputTextChange}
                  onFocus={() => setCommentInputTextFocus(true)}
                  name="comment_textbox"
                  height={comment_input_text_height}
                  tabIndex={2}
                />
              </TextBoxContainer>
            </FlexHoriz>
            {comment_input_text_focus ? (
              <CancelAndConfirmButtonsContainer>
                <Button
                  onClick={resetCommentBox}
                  type="button"
                  text="Cancel"
                  msstyle="option_negative"
                  width="96px"
                  tabIndex={4}
                />
                <ButtonSpacer />
                <Button
                  type="button"
                  text="Comment"
                  msstyle="option_positive"
                  onClick={postComment}
                  width="114px"
                  working_overlay={working_post_comment}
                  tabIndex={3}
                  enabled={comment_text_valid}
                />
              </CancelAndConfirmButtonsContainer>
            ) : null}
          </FlexVert>
        </CommentsComboBox>
      ) : null}
      {!isLoggedIn ? (
        <Overlay
          rootClose
          show={tooltipIsVisible}
          offset={[0, 10]}
          onHide={() => setVisible(false)} // required!
          container={triggerRef}
          target={triggerRef}
          placement={'auto'}
        >
          {(overlay_props) => (
            <Tooltip
              {...overlay_props.props}
              placement={overlay_props.placement}
            >
              <TooltipText>You need to log in to do this</TooltipText>
            </Tooltip>
          )}
        </Overlay>
      ) : null}
      <CommentTileListContainer>
        {[...live_comments_list, ...additional_comments_list].map((entry) => (
          <CommentTile
            key={entry.uid}
            data={entry}
            time_now={TIME_NOW}
            collectionUserUID={collectionUserUID}
            deleteComment={deleteComment}
            workingDelete={working_delete_comment[entry.uid]}
          />
        ))}
      </CommentTileListContainer>
      {show_load_more ? (
        <LoadMoreContainer>
          <Button
            type="button"
            text="Load more"
            msstyle="option_positive"
            onClick={loadMore}
            width="114px"
            working_overlay={loading_more}
            tabIndex={5}
          />
        </LoadMoreContainer>
      ) : null}
    </CommentsContainer>
  );
};
