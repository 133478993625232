import { AppThunk, ActionNames } from 'src/types/actions';
import {
  CreatorPayoutDetails,
  UpdateCreatorPayoutDetailsAction,
  ResetCreatorPayoutDetailsAction,
} from 'src/types/models/creatorPayoutDetails.model';

export function updateCreatorPayoutDetails(
  payload: Partial<CreatorPayoutDetails>,
): UpdateCreatorPayoutDetailsAction {
  return {
    type: ActionNames.UPDATE_CREATOR_PAYOUT_DETAILS,
    payload,
  };
}

export function resetCreatorPayoutDetails(): ResetCreatorPayoutDetailsAction {
  return {
    type: ActionNames.RESET_CREATOR_PAYOUT_DETAILS,
    payload: undefined,
  };
}

export const requestCreatorPayout = (): AppThunk => {
  return async (dispatch, getState, { services }) => {
    const { cloud } = services;
    const { creatorPayoutDetails } = getState();

    await cloud.call('contentCreator-requestPayout')(creatorPayoutDetails);

    dispatch(resetCreatorPayoutDetails());
  };
};
