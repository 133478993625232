import { VFC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';
import { CreatorApplicationFiveContainerProps } from './CreatorApplicationFive.types';
import { CreatorApplicationFive } from './CreatorApplicationFive.component';

const CreatorApplicationFiveContainer: VFC<
  CreatorApplicationFiveContainerProps
> = (props) => {
  const history = useHistory();

  const handleSubmit = useCallback(async () => {
    history.push(R.CREATOR_APPLICATION_SIX);
  }, [history]);

  const handleBack = useCallback(() => {
    history.push(R.CREATOR_APPLICATION_FOUR);
  }, [history]);

  const hasAffiliates = true;

  return (
    <CreatorApplicationFive
      onSubmit={handleSubmit}
      onBack={handleBack}
      hasAffiliates={hasAffiliates}
      {...props}
    />
  );
};

export { CreatorApplicationFiveContainer as CreatorApplicationFive };
