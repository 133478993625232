/*

Logic for moving items around in lists:

It assigns a number to each position, manipulates the number and then uses these new values to sort the list.

Move to utils

*/
const moveItems = (organising_order,uid,direction)=>{
  const organising_order_aoa = organising_order.map((x,i)=>[i,x]);
  switch(direction){
    case "back":
      organising_order_aoa.forEach(
        x=>{
          if (x[1]===uid){
            x[0]-=1.5;
          }
        }
      );
      break;
    case "forward":
      organising_order_aoa.forEach(
        x=>{
          if (x[1]===uid){
            x[0]+=1.5;
          }
        }
      );
      break;
    case "start":
      organising_order_aoa.forEach(
        x=>{
          if (x[1]===uid){
            x[0]=-1;
          }
        }
      );
      break;
    case "end":
      organising_order_aoa.forEach(
        x=>{
          if (x[1]===uid){
            x[0]=organising_order_aoa.length;
          }
        }
      );
      break;
    default:
  }
  organising_order_aoa.sort((a,b)=>a[0]>b[0]?1:-1);
  return organising_order_aoa.map(x=>x[1]);
};

export default moveItems;
