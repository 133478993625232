import { useCallback, useState, useRef } from 'react';
import { DB } from 'src/services/DB';

/*

This hook is used to update a document in the database, returns a function and working flag.


*/
export function useDBUpdateDoc<T>(
  dB: DB,
  dBLocation: string, // document reference
  runOnceOnly?: boolean, // run this once only on mount;
): [(data: T) => Promise<void>, boolean] {
  const promiseRef = useRef<Promise<void>>();
  const [working, setWorking] = useState(false);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const dBUpdateDoc = useCallback(
    async (data: T) => {
      if (working) {
        return promiseRef.current!;
      }
      if (runOnceOnly && hasRunOnce) {
        const emptyPromise: Promise<void> = new Promise(() => {
          return;
        });
        return emptyPromise;
      }

      setWorking(true);
      setHasRunOnce(true);
      const newPromise = dB
        .updateDoc(dBLocation, data)
        .then((result) => {
          setWorking(false);
          return result;
        })
        .catch((err) => {
          setWorking(false);
          throw err;
        });

      promiseRef.current = newPromise;

      return newPromise;
    },
    [dB, dBLocation, hasRunOnce, runOnceOnly, working],
  );
  return [dBUpdateDoc, working];
}
