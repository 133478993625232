import { getDateTimeString } from './getDateTimeString';

/*

Return a random ID based on the datetime and a random number.

*/
export const getRandomUID = function () {
  return (
    getDateTimeString() +
    '_' +
    String(Math.round(Math.random() * 10000000)).padStart(8, '0')
  );
};
