import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import clsx from 'clsx';
import {
  Stack,
  StandardButton,
  DeleteIcon,
  Paper,
  MoveButton,
  moveButtonClassNames,
  modalClassNames,
  iconBaseClassNames,
} from '@moonsifttech/design-system';

import { EditModalContentProps } from './EditModalContent.types';
import { EditModalContentContent } from './EditModalContentContent.component';

// Makes drawers as tall as the screen but leaving 20px on the top
const EditDrawerContentGlobalStyle = createGlobalStyle`
  .${modalClassNames.content} {
    height: calc(100% - 20px);
  }
`;

export const UnstyledEditDrawerContent: React.FC<EditModalContentProps> = ({
  className,
  style,
  name,
  description,
  working,
  showConfirmation,
  deleting,
  selectedCollectionType,
  isRegistryNext,
  onCollectionTypeChange,
  onEdit,
  onDelete,
  onClose,
  toggleConfirmation,
  toggleIsRegistry,
}) => {
  return (
    <>
      <EditDrawerContentGlobalStyle />
      <Paper
        className={clsx(
          className,
          showConfirmation && 'EditDrawerContent-confirmation',
        )}
        style={style}
      >
        <form onSubmit={onEdit}>
          <Stack
            className="EditDrawerContent-controls"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <MoveButton
              className="EditDrawerContent-backButton"
              variant="outlined"
              direction="left"
              onClick={showConfirmation ? toggleConfirmation : onClose}
            />
            {!showConfirmation && (
              <Stack direction="row" spacing={12}>
                <StandardButton
                  variant="primary-white"
                  onClick={toggleConfirmation}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </StandardButton>
                <StandardButton
                  type="submit"
                  variant="primary-black"
                  loading={working}
                >
                  Save
                </StandardButton>
              </Stack>
            )}
          </Stack>
          <div className="EditDrawerContent-innerContainer">
            <div className="EditDrawerContent-transparency" />
            <EditModalContentContent
              showConfirmation={showConfirmation}
              deleting={deleting}
              name={name}
              description={description}
              selectedCollectionType={selectedCollectionType}
              isRegistryNext={isRegistryNext}
              onCollectionTypeChange={onCollectionTypeChange}
              onDelete={onDelete}
              toggleIsRegistry={toggleIsRegistry}
            />
          </div>
        </form>
      </Paper>
    </>
  );
};

export const EditDrawerContent = styled(UnstyledEditDrawerContent)`
  padding: 24px 16px 0;
  height: 100%;

  form {
    height: 100%;
  }

  .EditDrawerContent-backButton.${moveButtonClassNames.variantOutlined} {
    padding: 0;

    .${moveButtonClassNames.button} {
      width: 40px;
      height: 40px;

      .${iconBaseClassNames.root} {
        font-size: 20px;
      }
    }
  }

  .EditDrawerContent-confirmationHeading {
    margin-bottom: 60px;
  }

  .${iconBaseClassNames.root}.EditDrawerContent-confirmationIcon {
    font-size: 100px;
    margin-bottom: 12px;
  }

  .EditDrawerContent-innerContainer {
    position: relative;
    height: calc(100% - 40px);
    overflow-y: auto;

    .EditDrawerContent-transparency {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      height: 32px;
      z-index: 10;
      background-image: linear-gradient(
        to top,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1),
        rgba(255, 255, 255, 1)
      );
    }
  }
`;
