import React from 'react';
import {
  AddItemsMenuProps,
  AddItemsMenuContainerProps,
} from './AddItemsMenu.types';
import { AddItemsMenu } from './AddItemsMenu.component';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { Status } from 'src/types';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';

const isAndroid = /(android)/i.test(navigator.userAgent);
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isSafariDesktop = !isIOS && isSafari;
const isFirefoxDesktop = !isIOS && isFirefox;

const AddItemsMenuContainer = React.forwardRef<
  HTMLDivElement,
  AddItemsMenuContainerProps
>((props: AddItemsMenuContainerProps, ref) => {
  const history = useHistory();

  const creatorStatus = useSelector<RootState, Status | undefined>(
    (state) => state.db.user_verified_settings.data?.creator.status,
  );

  const isCreator = creatorStatus === 'accepted' || creatorStatus === 'applied';

  const handleGotToRetailers = React.useCallback<
    AddItemsMenuProps['goToRetailers']
  >(() => {
    history.push(isCreator ? R.AFFILIATE_BRANDS : R.RETAILERS);
  }, [isCreator, history]);

  const handleAddItemDirectly = React.useCallback<
    AddItemsMenuProps['openAddItemDirectly']
  >(() => {
    history.push(R.HOW_TO_SAVE);
  }, [history]);

  return (
    <AddItemsMenu
      goToRetailers={handleGotToRetailers}
      openAddItemDirectly={handleAddItemDirectly}
      isCreator={isCreator}
      isUnsupportedDevice={isAndroid || isSafariDesktop || isFirefoxDesktop}
      {...props}
      ref={ref}
    />
  );
});

export { AddItemsMenuContainer as AddItemsMenu };
