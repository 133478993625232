import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Typography, Stack } from '@moonsifttech/design-system';
import { Page } from 'src/components/common/Page';
import { CreatorLandingCtaButton } from 'src/components/creatorLanding/CreatorLandingCtaButton';
import { CreatorLandingSection } from 'src/components/creatorLanding/CreatorLandingSection';
import { LandingBanner } from 'src/components/common/LandingBanner';
import { CreatorLandingStripe } from 'src/components/creatorLanding/CreatorLandingStripe';
import { CreatorLandingProps } from './CreatorLanding.types';
import { PeopleSayingCarousel } from 'src/components/common/PeopleSayingCarousel';
import { CreatorLandingExampleCollections } from 'src/components/creatorLanding/CreatorLandingExampleCollections';

const UnstyledCreatorLanding: VFC<CreatorLandingProps> = ({
  className,
  style,
}) => {
  return (
    <>
      <Helmet>
        <title>The Affiliate Platform for Content Creators - Moonsift</title>
        <meta
          name="description"
          content={[
            'The easiest way for Content Creators and Influencers to earn money from thousands of leading brands.',
            'Free to use.',
            'Fashion, Beauty, Homeware...',
          ].join(' ')}
        />
      </Helmet>
      <Page
        className={className}
        style={style}
        showCreators
        isLanding
        showFooter
      >
        <section className="CreatorLanding-headingSection">
          <Typography
            variant="editorial.r68"
            component="h1"
            align="center"
            color="common.white"
          >
            Curate. <br />
            Share. <br />
            Earn.
          </Typography>
          <Typography
            className="CreatorLanding-mobile"
            variant="primary.l20"
            component="p"
            align="center"
            color="common.white"
          >
            Create your storefront. Add products from any brand in the world.
            Get paid.
          </Typography>
          <Typography
            className="CreatorLanding-desktop"
            variant="primary.l26"
            component="p"
            align="center"
            color="common.white"
          >
            Create your storefront. Add products from any brand in the world.
            Get paid.
          </Typography>
          <CreatorLandingCtaButton eventName="top" />
        </section>

        <CreatorLandingStripe color="purple" direction="dark-to-light" />

        <LandingBanner
          className="CreatorLanding-brandsBanner"
          imagePath="/creators-landing/brands-banner-1"
          imageAlt="Earn from affiliate brands like Nike"
        />

        <CreatorLandingStripe color="green" direction="dark-to-light" />

        <LandingBanner
          className="CreatorLanding-picturesBanner"
          imagePath="/creators-landing/picture-sequence"
          imageAlt="Content creators and influencers on YouTube, Instagram and TikTok"
        />

        <CreatorLandingStripe color="green" direction="light-to-dark" />

        <LandingBanner
          className="CreatorLanding-brandsBanner"
          imagePath="/creators-landing/brands-banner-2"
          imageAlt="Earn affiliate commissions from brands like Sephora"
        />

        <CreatorLandingStripe color="purple" direction="light-to-dark" />

        <CreatorLandingSection
          heading="Curate"
          subheading="Curate shoppable collections"
          imagePath="curate"
          imageAlt="Fashion influencer affiliate marketing"
          subsectionHeading="Add items from any store in the world."
          subsectionParagraph={`
          Curate beautiful collections that combine products from any store in the world.
          `}
          eventName="curate"
        />

        <CreatorLandingStripe color="green" />

        <CreatorLandingSection
          className="CreatorLanding-shareSection"
          heading="Share"
          subheading="Turn inspiration into income"
          imagePath="share"
          imageAlt="Share on YouTube, Instagram, TikTok, Twitch, Snap, Pinterest, Facebook, Twitter"
          subsectionHeading="Followers shop everything from a single link."
          subsectionParagraph={`
            Stop pasting so many links! Make it easier than ever before for followers to browse and shop all your recommendations in one place.
          `}
          isDark
          eventName="share"
        />

        <CreatorLandingStripe color="green" />

        <CreatorLandingSection
          heading="Earn"
          subheading="Instantly access over 35,000 leading brands"
          imagePath="earn"
          imageAlt="Fashion, Clothes, Apparel, Homeware, Beauty, Make up, Family, Fitness, Consumer electronics"
          subsectionHeading="Earn from the brands you love in every category."
          subsectionParagraph={`
          No need to apply to brands one by one. Apply once and gain access to the world's largest network of affiliate brands.
          `}
          eventName="earn"
        />

        <CreatorLandingStripe color="green" />

        <CreatorLandingSection
          heading="One link"
          subheading="All your affiliates together in one link"
          imagePath="one-link"
          imageAlt="Links for RewardStyle, Amazon, LTK, Klarna, MagicLinks, Revolve all in one place"
          subsectionHeading="Combine all your affiliates in one link."
          subsectionParagraph={`
            Got your own affiliate links?
            Combine all your existing affiliates in one easy to browse link.
            We don't take a cut, you keep 100%.
          `}
          isDark
          eventName="one-link"
        />
        <CreatorLandingExampleCollections className="CreatorLanding-exampleCollections" />

        <PeopleSayingCarousel
          className="CreatorLanding-peopleSayingCarousel"
          type="creator"
        />
        <Stack className="CreatorLanding-finalCTA" alignItems="center">
          <CreatorLandingCtaButton eventName="bottom" />
        </Stack>
        <CreatorLandingStripe color="purple" direction="dark-to-light" />
        <LandingBanner
          className="CreatorLanding-bottomBrandsBanner"
          imagePath="/creators-landing/brands-banner-3"
          imageAlt="Earn affiliate commissions from brands like ASOS"
        />
        <CreatorLandingStripe color="purple" direction="light-to-dark" />
        <Stack className="CreatorLanding-finalMessage" alignItems="center">
          <Typography variant="editorial.sb34" component="p" align="center">
            The new way to monetise
            <br /> your passion.
          </Typography>
        </Stack>
      </Page>
    </>
  );
};

export const CreatorLanding = styled(UnstyledCreatorLanding)`
  .CreatorLanding-desktop {
    display: none;
  }

  .CreatorLanding-headingSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--grey-3);
    padding: 26px 16px 68px;
    background-image: url(${`${process.env.REACT_APP_STATIC_URL}/creators-landing/creators-heading-section-background.mobile.png`});
    background-size: cover;

    h1 {
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 32px;
    }
  }

  .CreatorLanding-brandsBanner img {
    height: 128px;
  }

  .CreatorLanding-picturesBanner img {
    height: 260px;
  }

  .CreatorLanding-bottomBrandsBanner img {
    height: 248px;
  }

  .CreatorLanding-shareSection h3 {
    margin-bottom: 24px;
  }

  .CreatorLanding-finalMessage {
    padding: 52px 20px 60px;
    background: var(--common-black);
    color: var(--common-white);

    p {
      margin-bottom: 0;
    }

    br {
      display: none;
    }
  }

  .CreatorLanding-finalCTA {
    padding: 12px 20px 60px;
    background: var(--grey-7);
  }

  .CreatorLanding-peopleSayingCarousel {
    padding: 52px 20px 60px;
    overflow: hidden;
    background-color: var(--grey-7);
  }

  .CreatorLanding-exampleCollections {
    padding: 52px 20px 60px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .CreatorLanding-mobile {
      display: none;
    }

    .CreatorLanding-desktop {
      display: block;
    }

    .CreatorLanding-headingSection {
      padding: 152px 16px 176px;
      background-image: url(${`${process.env.REACT_APP_STATIC_URL}/creators-landing/creators-heading-section-background.desktop.png`});

      br {
        display: none;
      }
    }

    .CreatorLanding-picturesBanner img {
      height: 372px;
    }

    .CreatorLanding-finalMessage {
      padding: 64px 20px 24px;

      p {
        ${({ theme }) => theme.fns.getTypographyStyles('editorial.r46')}
      }

      br {
        display: block;
      }
    }
  }
`;
