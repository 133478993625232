import { VFC } from 'react';
import styled from 'styled-components';
import { Stack, Typography } from '@moonsifttech/design-system';

import { EmptyMessageOtherUserCollectionProps } from './EmptyMessageOtherUserCollection.types';

const UnstyledEmptyMessageOtherUserCollection: VFC<
  EmptyMessageOtherUserCollectionProps
> = ({ className, style }) => {
  return (
    <div className={className} style={style}>
      <Stack
        direction="column"
        alignItems="center"
        className="EmptyMessageOtherUserCollection-container"
      >
        <Typography variant="primary.b34">Collection empty</Typography>
      </Stack>
    </div>
  );
};

export const EmptyMessageOtherUserCollection = styled(
  UnstyledEmptyMessageOtherUserCollection,
)`
  .EmptyMessageOtherUserCollection-container {
    max-width: 330px;
    display: block;
    background-color: var(--primary-white);
    box-sizing: border-box;
    position: relative;
  }
  .EmptyMessageOtherUserCollection-image {
    display: block;
    margin: 0 auto;
  }
`;
