/*

Will become redundant

*/

// This file links the object to the context in this folder - just an index really.
import { withFirebase } from './context.js';
import Firebase from './firebase';

export default Firebase;

export {  withFirebase };
