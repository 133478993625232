
/*

Popup from the "+" button in organise that displays the list of subsections in a collection.

TODO: typescript, camelCase

*/
import React, {useRef,useState} from 'react';
import {M} from 'src/mvp22/constants';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';
import Overlay from 'react-overlays/Overlay';

const SubMenu = styled.div`
  border-radius:10px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index:1000;
  position:absolute;
  overflow:hidden;
`;

const SubMenuInner = styled.div`
  display:flex;
  flex-direction:column;
`;

const SubMenuOption = styled(TEXTSTYLE.LINKA)`
  padding:12px;
  display:flex;
  flex-direction:row;
  justify-items:space-between;
  align-items:center;
  cursor:pointer;
  &:hover{
    background-color:${M.COL.BG.MID};
  }
`;

const SubMenuTextNotSelected = styled(TEXTSTYLE.BODY4)`
  flex-grow:1;
`;

const SubMenuTextSelected = styled(TEXTSTYLE.OTHER2)`
  flex-grow:1;
`;

const SubMenuTick = styled.img`
  width:18px;
  margin-left:12px;
`;

const WithinPos = styled.div`
color:default;
`;

const CentralTab = styled(TEXTSTYLE.LINKA)`
  display:flex;
  flex-direction: column;
  align-items: flex-start;
`;

const AddImage = styled.img`
  width:18px;
  margin-right:12px;
`;

const AddBlock = styled(TEXTSTYLE.BODY4)`
  display:flex;
  justify-content:flex-start;
`;


const SubsectionsToAddTo = props=>{
  const containerRef = useRef(null);
  const triggerRef = useRef(null);
  const [show_popup, set_popup] = useState(false);

  const addOrRemoveItemToSubsection = (subsection_uid,event)=>{
    props.addOrRemoveItemToSubsection(props.product_uid,subsection_uid,event);
    set_popup(false);
  };

  const addToNewSubsection = (event)=>{
    props.addSubsection(event,props.product_uid);
    set_popup(false);
  };

  const subsection_list = props.subsection_list;
  const current_subsection_uid = props.current_subsection_uid;
  return(
    <WithinPos ref={containerRef} className="WithinPos">
    <CentralTab
      href="#"
      ref={triggerRef}
      onClick={
        (event)=>{
          event.preventDefault();
          set_popup(true);
          return false;
        }
      }
    >
    {props.children}
    </CentralTab>
      <Overlay
        show={show_popup}
        rootClose
        offset={[0,0]}
        placement={"top"}
        onHide={() => set_popup(false)} // required!
        container={containerRef}
        target={triggerRef}
      >
      {
        (overlay_props)=>
          <SubMenu {...overlay_props.props} placement={overlay_props.placement}>
            <SubMenuInner>
              {
                subsection_list.map(
                  (option,index)=>
                  {
                    const IS_SELECTED = option.uid===current_subsection_uid;
                    const SUB_MENU_TEXT = (IS_SELECTED)?SubMenuTextSelected:SubMenuTextNotSelected;
                    return (
                      <SubMenuOption href="#" key={index} onClick={(event)=>addOrRemoveItemToSubsection(option.uid,event)}>
                        <SUB_MENU_TEXT>{option.name}</SUB_MENU_TEXT>
                        <SubMenuTick src={(IS_SELECTED)?MEDIA.BOX_TICKED:MEDIA.BOX_UNTICKED}/>
                      </SubMenuOption>
                    );
                  }
                )
              }
              <SubMenuOption href="#" key={"add"} onClick={addToNewSubsection}>
                <AddBlock>
                <AddImage src={MEDIA.NEW_PLUS}/>New Section
                </AddBlock>
              </SubMenuOption>
            </SubMenuInner>
          </SubMenu>
      }
      </Overlay>
    </WithinPos>
  );
};

export default SubsectionsToAddTo;
