/*

Vertical implementation of the carousel for viewing a product's images.

TODO: Convert to typescript, functional, component + container, camelCase, reduce number of functions too...

*/
import React from 'react';
import BasicCarousel from './BasicCarousel';
import styled from 'styled-components';
import MEDIA from 'src/mvp22/media';
import LoadCheck from 'src/mvp22/image-components/LoadCheck';
// images in carousel are fixed size and it scrolls a row at a time
const CAROUSEL_SCROLL_DISTANCE = 449;

const CarouselContainer = styled.div`
  display: flex;
  max-width: 60px;
  justify-content: center;
  position:relative;
  height: 408px;
  margin-right: 22px;
  margin-top: 0px;
`;

const CarouselArrow = styled.div`
  position: absolute;
  display: ${props => props.visible ? 'block' : 'none'};
  color: #111;
  cursor: pointer;
  opacity: .75;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  z-index: 10;
  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: 1;
  }

  &--left {
    left: 32px;
  }

  &--right {
    right: 32px;
  }
`;

const CarouselArrowUp = styled(CarouselArrow)`
color:default;
`;
const CarouselArrowDown = styled(CarouselArrow)`
  bottom: -29px;
`;

const CarouselSlideContainer = styled.div`
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  ::-webkit-scrollbar {
    display: none;
  }
  scroll-snap-type: x mandatory;
`;

const CarouselSlideInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const CarouselArrowDownImg = styled.img`
  transform: scaleY(-1);
`;

const CarouselArrowUpImg = styled.img`
color:default;
`;

const CarouselImage = styled.img`
  width: 60px;
  margin-bottom: 22px;
  border: ${props => props.is_selected ? '1px solid black' : '0px solid transparent'};
  box-sizing: border-box;
  cursor:pointer;
`;

class VerticalCarousel extends BasicCarousel {

  constructor(){
    super();
    this.scrollContainerUp = this.scrollContainerUp.bind(this);
    this.scrollContainerDown = this.scrollContainerDown.bind(this);
  }

  scrollContainerUp(event) {
    event.preventDefault();
    this.container.scrollBy({ top: (-1*CAROUSEL_SCROLL_DISTANCE), behavior: 'smooth' });
    return false;
  }

  scrollContainerDown(event) {
    event.preventDefault();
    this.container.scrollBy({ top: CAROUSEL_SCROLL_DISTANCE, behavior: 'smooth' });
    return false;
  }

  render() {
    return (
      <CarouselContainer className='CarouselContainer'>
        <CarouselArrowUp href="#" onClick={this.scrollContainerUp} visible={this.state.hasOverflow && this.state.canScrollForward} className='CarouselArrowUp'>
          <CarouselArrowUpImg src={MEDIA.CAROUSEL_ARROW_UP}/>
        </CarouselArrowUp>
        <CarouselArrowDown href="#" onClick={this.scrollContainerDown} visible={this.state.hasOverflow && this.state.canScrollBack} className='CarouselArrowDown'>
          <CarouselArrowDownImg src={MEDIA.CAROUSEL_ARROW_UP}/>
        </CarouselArrowDown>
          <CarouselSlideContainer ref={node =>{this.container = node;}} className='carouselSlideContainer'>
            <CarouselSlideInner className='carouselSlideInner'>
             {
               this.props.image_list.map(
                 (image, index) => {
                  return(
                    <LoadCheck src={image} key={index}>
                      <CarouselImage
                        active_image_index={this.props.active_image_index}
                        onClick={() => this.props.setActiveImage(index)}
                        is_selected={this.props.active_image_index === index}
                        className='carouselImage'
                      />
                    </LoadCheck>
                  );
                }
              )
            }
          </CarouselSlideInner>
        </CarouselSlideContainer>
      </CarouselContainer>
    );
  }
}

export default VerticalCarousel;
