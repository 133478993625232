/*

useFastAPI

*/
export default function update_like(user_uid,collection_uid,bool_to_set,firebase){
  return new Promise(
    resolve=>{
      firebase.fast_api()(
        {
          api:"likes_saved_likes_change",
          collection_uid,
          user_uid,
          action:(bool_to_set)?"create":"delete"
        }
      ).then(
        resolve
      ).catch(
        err=>
          {
            console.error("ERROR",err);
          }
      );
    }
  );
}
