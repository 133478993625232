import { VFC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import throttle from 'lodash.throttle';

import {
  NavControlsContainerProps,
  NavControlsProps,
} from './NavControls.types';
import { NavControls } from './NavControls.component';

function limitValue(value: number): number {
  if (value < 0) {
    return 0;
  }

  if (value > window.screen.height) {
    return window.screen.height;
  }

  return value;
}

const NavControlsContainer: VFC<NavControlsContainerProps> = (props) => {
  // onBack handler
  const history = useHistory();
  const handleBack = useCallback<NavControlsProps['onBack']>(() => {
    history.goBack();
  }, [history]);

  // Hide/show back button on scroll down/up
  const [hidden, setHidden] = useState(false);
  useEffect(() => {
    let previousPageYOffset = limitValue(window.pageYOffset);

    const onScroll = throttle(() => {
      const currentPageYOffset = limitValue(window.pageYOffset);

      if (hidden && previousPageYOffset > currentPageYOffset) {
        setHidden(false);
      } else if (!hidden && previousPageYOffset < currentPageYOffset) {
        setHidden(true);
      }
      previousPageYOffset = currentPageYOffset;
    }, 100);

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
      onScroll.cancel();
    };
  }, [hidden]);

  return <NavControls hidden={hidden} onBack={handleBack} {...props} />;
};

export { NavControlsContainer as NavControls };
