export const generateImageSource = (
  userUID: string,
  imageCode: string,
): string => {
  const urlParts = [
    'https://storage.googleapis.com',
    `${process.env.REACT_APP_FIREBASE_PROJECTID}-profile-images`,
    `userprofile_${userUID}_${imageCode}.jpg`,
  ];

  return urlParts.join('/');
};
