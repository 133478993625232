import React from 'react';
import { BookmarkButtonContainerProps } from './BookmarkButton.types';
import { BookmarkButton } from './BookmarkButton.component';
import { useBookmarkCollection } from 'src/hooks/useBookmarkCollection';
const BookmarkButtonContainer: React.FC<BookmarkButtonContainerProps> = (
  props,
) => {
  const { isBookmarked, working, handleBookmark } = useBookmarkCollection();

  return (
    <BookmarkButton
      isBookmarked={isBookmarked === true}
      working={working}
      onClick={handleBookmark}
      {...props}
    />
  );
};

export { BookmarkButtonContainer as BookmarkButton };
