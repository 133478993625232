import React, { useState, useEffect, useCallback, useContext } from 'react';
import styled from 'styled-components';
import {
  Typography,
  TextField,
  TextFieldRoot,
  Stack,
  StandardButton,
  FormHelperTextRoot,
} from '@moonsifttech/design-system';

import { ModalContent } from 'src/components/core/ModalContent';
import { ProfileSharePanelProps } from './ProfileSharePanel.types';
import { ServicesContext } from 'src/ServicesContext';
import { ActionNames, AutoTriggerReviewRequests } from 'src/types';
import { useDispatch } from 'react-redux';

const UnstyledProfileSharePanel: React.FC<ProfileSharePanelProps> = ({
  className,
  style,
  onClose,
  displayUsername,
}) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const { analytics } = useContext(ServicesContext);

  useEffect(() => {
    if (copied) {
      const handle = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(handle);
    }
  }, [copied]);

  const handleCopy = useCallback<
    React.FormEventHandler<
      HTMLFormElement & {
        url: HTMLInputElement;
      }
    >
  >(
    (event) => {
      event.preventDefault();

      const input = event.currentTarget.url;

      // Select the text field
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      // Copy the text inside the text field
      navigator.clipboard.writeText(input.value);

      analytics.recordEvent('WebApp:LinkCopied', { link: input.value });

      // Set state as copied
      setCopied(true);
    },
    [analytics],
  );

  const handleClose = React.useCallback(() => {
    onClose && onClose();
    dispatch<AutoTriggerReviewRequests>({
      type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
      payload: undefined,
    });
  }, [dispatch, onClose]);

  return (
    <ModalContent className={className} style={style} onClose={handleClose}>
      <Typography variant="primary.b26" component="h1">
        Share this profile
      </Typography>
      <Typography variant="primary.r14" color="grey.2" component="p">
        Copy and share with friends
      </Typography>
      <form onSubmit={handleCopy}>
        <TextField
          name="url"
          defaultValue={window.location.origin + '/@' + displayUsername}
          fullWidth
          helperText={copied ? 'Copied!' : undefined}
        />
        <Stack direction="row" justifyContent="flex-end">
          <StandardButton type="submit" variant="primary-black">
            Copy
          </StandardButton>
        </Stack>
      </form>
    </ModalContent>
  );
};

export const ProfileSharePanel = styled(UnstyledProfileSharePanel)`
  p {
    margin-bottom: 10px;
  }

  ${TextFieldRoot} {
    margin-bottom: 16px;
  }

  ${FormHelperTextRoot} {
    font-weight: 700;
  }
`;
