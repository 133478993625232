import { ParserInput } from 'src/services/DB';
import { CollectionRegistryPrivate } from 'src/types/models/collectionRegistryPrivate.model';

export function collectionRegistryPrivateParser(
  doc: ParserInput,
): CollectionRegistryPrivate | null {
  return {
    id: doc.id,
    ...doc.data(),
  } as CollectionRegistryPrivate;
}
