/*
  Shown as an error message when someone tries to buy an item, but it has already been exhausted.
  This should only really happen in case of a race condition, as the option to mark the item would be hidden
  if the item was already bought.
*/

import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  LaunchIcon,
} from '@moonsifttech/design-system';
import { useGoToItem } from 'src/hooks/useGoToItem';
import { useModal } from 'src/hooks/useModal';
import { ModalContent } from 'src/components/core/ModalContent';
import { GoToBuyItemCard } from 'src/components/collection/GoToBuyItemCard';
import { UnstyledLink } from 'src/components/core/UnstyledLink';

const UnstyledAlreadyBoughtDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ _, closeModal ] = useModal();

  const { url, goToItem } = useGoToItem({
    collectionId,
    collectionUserId,
    itemId,
  });

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1" color="error">
        Sorry, someone else just bought this item
      </Typography>

      <Typography variant="primary.r16" component="p">
        Please return to the gift list to find an alternative.
      </Typography>

      <GoToBuyItemCard
        className="AlreadyBoughtDialog-productCard"
        collectionUID={collectionId}
        collectionUserUID={collectionUserId}
        collectionItemUID={itemId}
        showLink={false}
      />

      <StandardButton
        className="AlreadyBoughtDialog-button"
        variant="primary-black"
        fullWidth
        onClick={closeModal}
      >
        Go back to gift list
      </StandardButton>

      <StandardButton
        className="AlreadyBoughtDialog-button"
        variant="primary-white"
        fullWidth
        endIcon={<LaunchIcon />}
        component={UnstyledLink}
        to={url}
        target="_blank"
        onClick={goToItem}
      >
        View on retailer anyway
      </StandardButton>
    </ModalContent>
  );
};

export const AlreadyBoughtDialog = styled(UnstyledAlreadyBoughtDialog)`
  .AlreadyBoughtDialog-productCard {
    margin-bottom: 2.5rem;
  }

  .AlreadyBoughtDialog-button {
    margin-bottom: 0.75rem;
  }
`;
