import { VFC, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Alert, Paper, Stack, Typography } from '@moonsifttech/design-system';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import debounce from 'lodash.debounce';

import { CollectionEarningsTableRow } from 'src/components/creator/CollectionEarningsTableRow';
import { EarningsTablePagination } from 'src/components/creator/EarningsTablePagination';
import { getCells, mobileOrder, desktopOrder } from './getCells';
import { EarningsTableProps } from './EarningsTable.types';

function calculateTableMessageContainerWidth(
  tableWidth?: number,
): number | undefined {
  // IF table width is not yet available, just return undefined.
  if (!tableWidth) {
    return undefined;
  }

  // ELSE subtract 40px from table padding.
  return tableWidth - 40;
}

const UnstyledEarningsTable: VFC<EarningsTableProps> = ({
  className,
  style,
  isMobile,
  collectionEarnings,
  totalSalesValue,
  totalCommissionsValue,
  collectionEarningsCount,
  page,
  onPageChange,
}) => {
  const order = isMobile ? mobileOrder : desktopOrder;
  const cells = getCells({
    totalSalesValue,
    totalCommissionsValue,
  });
  const orderedCells = order.map((columnName) => cells[columnName]);

  const hasEarnings = collectionEarnings.length > 0;
  const hasPagination = collectionEarningsCount > 20;

  // Update empty message width when resizing.
  const ref = useRef<HTMLDivElement>(null);
  const [tableWidth, setTableWidth] = useState<number | undefined>();
  useEffect(() => {
    if (!hasEarnings) {
      const handleResize = debounce(() => {
        if (ref.current) {
          const tableWidth = ref.current.offsetWidth;
          setTableWidth(tableWidth);
        }
      }, 250);

      // Call it one time to set width on first render.
      handleResize();

      // Set up a listener to update width on resize.
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [hasEarnings]);

  return (
    <div
      ref={ref}
      className={clsx(
        className,
        hasPagination && 'EarningsTable-hasPagination',
      )}
    >
      <Alert className="EarningsTable-alert" severity="info">
        It may take 1-2 weeks for a brand to report Sales and Commissions
      </Alert>
      <TableContainer style={style} component={Paper} variant="outlined">
        <Table aria-label="Earnings by collection" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Collection name</TableCell>
              {orderedCells.map((group) => group.head)}
            </TableRow>
          </TableHead>
          <TableBody>
            {hasEarnings ? (
              collectionEarnings.map((row) => (
                <CollectionEarningsTableRow
                  key={row.id}
                  isMobile={isMobile}
                  collectionUID={row.collection_uid}
                  collectionName={row.collection_name}
                  clicks={row.clicks}
                  sales={row.sales}
                  totalEarnings={row.collection}
                  items={row.items}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div
                    style={{
                      width: calculateTableMessageContainerWidth(tableWidth),
                    }}
                  >
                    <Stack className="EarningsTable-emptyMessage">
                      <Typography variant="primary.r16" align="center">
                        When a Collection starts receiving 'Clicks' it will
                        appear in this table.
                      </Typography>
                    </Stack>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {hasEarnings && (
            <TableFooter>
              <TableRow>
                <TableCell component="th" scope="row">
                  Total
                </TableCell>
                {orderedCells.map((group) => group.footer)}
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
      {hasPagination && (
        <Stack direction="row" justifyContent="flex-end">
          <EarningsTablePagination
            count={collectionEarningsCount}
            rowsPerPageOptions={[1, 20]}
            rowsPerPage={20}
            page={page}
            onPageChange={onPageChange}
          />
        </Stack>
      )}
    </div>
  );
};

export const EarningsTable = styled(UnstyledEarningsTable)`
  .MuiTableContainer-root {
    width: 100%;
    ${({ theme }) => theme.fns.getShapeStyles('soft')}
  }

  &.EarningsTable-hasPagination > .MuiTableContainer-root {
    margin-bottom: 12px;
  }

  .EarningsTable-alert {
    margin: 0 auto 12px;
  }

  .MuiTable-root {
    margin-bottom: 0;
  }

  .EarningsTable-emptyMessage {
    margin: 14px auto 38px;
    max-width: 272px;
  }

  .MuiTableCell-root {
    min-width: 130px;
    border-bottom: 0;
    padding: 10px 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  tbody th.MuiTableCell-root {
    min-width: 160px;
    max-width: 160px;
  }

  .MuiTableHead-root .MuiTableCell-root {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.m16')}
  }

  .MuiTableBody-root .MuiTableCell-root {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r14')}
  }

  .MuiTableFooter-root .MuiTableCell-root {
    color: var(--common-black);
    border-top: 1px solid var(--grey-5);
    ${({ theme }) => theme.fns.getTypographyStyles('primary.r16')}
  }

  .MuiTableFooter-root th.MuiTableCell-root {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.m16')};
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    tbody th.MuiTableCell-root {
      min-width: 220px;
      max-width: 220px;
    }
  }
`;
