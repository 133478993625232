import { VFC } from 'react';
import styled from 'styled-components';
import { Typography, StandardButton, Stack } from '@moonsifttech/design-system';

import { CreatorApplicationThreeProps } from './CreatorApplicationThree.types';

const UnstyledCreatorApplicationThree: VFC<CreatorApplicationThreeProps> = ({
  className,
  style,
  onSubmit,
  onBack,
  hasAffiliates,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationThree-content">
        {hasAffiliates ? (
          <>
            {' '}
            <Typography
              className="CreatorApplicationThree-heading"
              variant="editorial.sb34"
              component="h1"
            >
              Combine all your existing affiliate links & let Moonsift fill the
              gaps.
            </Typography>
            <Typography
              className="CreatorApplicationThree-paragraph"
              variant="primary.l20"
              component="p"
            >
              More commission with less hassle. You automatically gain access to
              over 35K affiliate brands on Moonsift. Simply add their products
              to your boards.
            </Typography>
            <Typography
              className="CreatorApplicationThree-paragraph"
              variant="primary.l20"
              component="p"
            >
              Only two more questions...
            </Typography>
            <Stack direction="row" spacing={12}>
              <StandardButton variant="primary-white" onClick={onBack}>
                Back
              </StandardButton>
              <StandardButton
                type="submit"
                onClick={onSubmit}
                variant="primary-black"
              >
                Next
              </StandardButton>
            </Stack>
            <Stack
              direction="column"
              spacing={0}
              className="CreatorApplicationThree-imageContainer"
            >
              <picture>
                <source
                  media="(max-width: 544px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/creator/AffiliatePlatforms.png`}
                />
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/creator/AffiliatePlatforms.2x.png`}
                  alt="Affiliate platform logos"
                  className="CreatorApplicationThree-affiliateLogos"
                />
              </picture>
              <picture>
                <source
                  media="(max-width: 544px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/creator/SkimlinksRetailers.png`}
                />
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/creator/SkimlinksRetailers.2x.png`}
                  alt="Skimlinks affiliate brand logos"
                  className="CreatorApplicationThree-affiliateLogos"
                />
              </picture>
            </Stack>
          </>
        ) : (
          <>
            <Typography
              className="CreatorApplicationThree-heading"
              variant="editorial.sb34"
              component="h1"
            >
              No problem, we've got you covered.
            </Typography>
            <Typography
              className="CreatorApplicationThree-paragraph"
              variant="primary.l20"
              component="p"
            >
              You automatically gain access to over 35k affiliate brands on
              Moonsift. Simply add products from their site for the chance to
              earn when followers buy.
            </Typography>
            <Typography
              className="CreatorApplicationThree-paragraph"
              variant="primary.l20"
              component="p"
            >
              Only two more questions...
            </Typography>
            <Stack direction="row" spacing={12}>
              <StandardButton variant="primary-white" onClick={onBack}>
                Back
              </StandardButton>
              <StandardButton
                type="submit"
                onClick={onSubmit}
                variant="primary-black"
              >
                Next
              </StandardButton>
            </Stack>
            <Stack
              direction="row"
              spacing={12}
              className="CreatorApplicationThree-imageContainer"
            >
              <picture>
                <source
                  media="(max-width: 544px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/creator/SkimlinksRetailers.png`}
                />
                <img
                  src={`${process.env.REACT_APP_STATIC_URL}/creator/SkimlinksRetailers.2x.png`}
                  alt="Skimlinks affiliate brand logos"
                  className="CreatorApplicationThree-affiliateLogos"
                />
              </picture>
            </Stack>
          </>
        )}
      </div>
    </div>
  );
};

export const CreatorApplicationThree = styled(UnstyledCreatorApplicationThree)`
  padding: 100px 0 0;

  .CreatorApplicationThree-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationThree-heading {
    margin-bottom: 24px;
    color: var(--background-purple);
  }

  .CreatorApplicationThree-input {
    margin-bottom: 28px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
  .CreatorApplicationThree-paragraph {
  }
  .CreatorApplicationThree-affiliateLogos {
    width: 100%;
  }
  .CreatorApplicationThree-imageContainer {
    margin-top: 24px;
  }
`;
