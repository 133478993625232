import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { CollectionNotificationsContainerProps } from './CollectionNotifications.types';
import { CollectionNotifications } from './CollectionNotifications.component';
import { useTheme } from 'styled-components';
import { ServicesContext } from 'src/ServicesContext';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';

const CollectionNotificationsContainer: React.FC<
  CollectionNotificationsContainerProps
> = ({ onClose, ...otherProps }) => {
  const { db, analytics } = useContext(ServicesContext);
  const notificationsEnabledDB = useSelector<RootState, boolean>((state) => {
    return state.db.single_collection.data?.notifications_enabled !== false; // undefined is on
  });
  const [nextNotificationsEnabledValue, setNextNotificationsEnabledValue] =
    useState(notificationsEnabledDB);
  useEffect(() => {
    setNextNotificationsEnabledValue(notificationsEnabledDB);
  }, [notificationsEnabledDB]);
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id ?? null,
  );
  const collectionUserUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.user_uid ?? '',
  );
  const isMine = collectionUserUID === authUserUID;
  const collectionUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.id ?? '',
  );

  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  const [updateCollectionDoc, isSaving] = useDBUpdateDoc(
    db,
    db.collectionDoc(collectionUserUID, collectionUID),
  );

  const onSave = useCallback(
    async (event) => {
      if (event) {
        event.preventDefault();
      }
      await updateCollectionDoc({
        notifications_enabled: nextNotificationsEnabledValue,
      });
      onClose();
      analytics.recordEvent('WebApp:SavedCollectionNotificationSettings');
    },
    [analytics, updateCollectionDoc, onClose, nextNotificationsEnabledValue],
  );

  const onNotificationChange = useCallback(() => {
    setNextNotificationsEnabledValue(!nextNotificationsEnabledValue);
  }, [nextNotificationsEnabledValue]);

  return (
    <CollectionNotifications
      onSave={onSave}
      saveEnabled={notificationsEnabledDB !== nextNotificationsEnabledValue}
      notificationsEnabled={nextNotificationsEnabledValue}
      isSaving={isSaving}
      isMine={isMine}
      isMobile={isMobile}
      onNotificationChange={onNotificationChange}
      onClose={onClose}
      {...otherProps}
    />
  );
};

export { CollectionNotificationsContainer as CollectionNotifications };
