import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Stack,
  textFieldClassNames,
  StandardButton,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { InviteCreatorsProps } from './InviteCreators.types';
import { Link } from 'src/components/core/Link';

const UnstyledInviteCreators: VFC<InviteCreatorsProps> = ({
  className,
  style,
  onButtonClick,
}) => {
  return (
    <div className={className} style={style}>
      <Typography
        className="InviteCreators-heading"
        variant="editorial.sb34"
        align="center"
        component="h2"
      >
        Free upgrade to Moonsift Pro
      </Typography>
      <Typography
        className="InviteCreators-subheading"
        variant="editorial.r22"
        align="center"
        component="p"
      >
        When you refer a friend
      </Typography>
      <Typography
        className="InviteCreators-paragraph"
        variant="primary.l16"
        align="center"
        component="p"
      >
        <Link to={R.PLANS}>Moonsift Pro</Link> gives you access to advanced
        features, such as adding sections to collections, personalising your URL
        and more.
      </Typography>
      <Typography
        className="InviteCreators-paragraph"
        variant="primary.l16"
        align="center"
        component="p"
      >
        <Link to={R.PRO_REFER}>Refer a friend</Link> and you will both be
        upgraded to Moonsift Pro for free.
      </Typography>
      <Stack direction="row" justifyContent="center">
        <StandardButton variant="primary-white" onClick={onButtonClick}>
          Get Moonsift Pro for free
        </StandardButton>
      </Stack>
    </div>
  );
};

export const InviteCreators = styled(UnstyledInviteCreators)`
  .InviteCreators-heading {
    margin-bottom: 20px;
  }

  .InviteCreators-subheading {
    margin-top: -8px;
    margin-bottom: 20px;
  }

  .InviteCreators-paragraph {
    margin-bottom: 20px;
  }

  .InviteCreators-form {
    display: flex;
    margin-bottom: 8px;

    .${textFieldClassNames.root} {
      margin-right: 4px;
    }
  }

  .InviteCreators-infoCopy {
    margin-bottom: 40px;
  }
`;
