import React from 'react';
import { AddIcon } from '@moonsifttech/design-system';

import { ModalMenu } from 'src/components/core/ModalMenu';
import { SquareButton } from 'src/components/collection/SquareButton';
import { AddItemsMenu } from 'src/components/collection/AddItemsMenu';
import { AddItemsButtonProps } from './AddItemsButton.types';
import styled from 'styled-components';

export const UnstyledAddItemsButton: React.FC<AddItemsButtonProps> = ({
  className,
  style,
  isMenuOpen,
  onClick,
  onClose,
  openAddItemModal,
  collectionIsEmpty,
}) => {
  return (
    <ModalMenu
      isOpen={isMenuOpen}
      onRequestClose={onClose}
      menu={<AddItemsMenu openAddItemModal={openAddItemModal} />}
    >
      <SquareButton
        className={className}
        shadow={collectionIsEmpty}
        style={style}
        icon={<AddIcon />}
        onClick={onClick}
      >
        Add items
      </SquareButton>
    </ModalMenu>
  );
};

export const AddItemsButton = styled(UnstyledAddItemsButton)``;
