import { VFC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Stack,
  Typography,
  Link,
  LaunchIcon,
} from '@moonsifttech/design-system';

import { GoToBuyItemCardProps } from './GoToBuyItemCard.types';

const UnstyledGoToBuyItemCard: VFC<GoToBuyItemCardProps> = ({
  className,
  style,
  imageSource,
  description,
  showLink,
  collectionItemUrl,
  onGoToItem,
}) => {
  return (
    <Paper className={className} style={style} variant="outlined">
      <Stack direction="row" spacing={16}>
        <img
          className="GoToBuyItemCard-productCardImage"
          src={imageSource}
          alt={description}
        />
        <Stack direction="column" justifyContent="center">
          <Typography
            className="GoToBuyItemCard-productCardDescription"
            variant="primary.r14"
            component="h2"
          >
            {description}
          </Typography>
          {showLink && (
            <Typography variant="primary.b14">
              <Link to={collectionItemUrl} target="_blank" onClick={onGoToItem}>
                <Stack direction="row" alignItems="center" spacing={6}>
                  <span>Go to buy item</span>
                  <LaunchIcon size="small" />
                </Stack>
              </Link>
            </Typography>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export const GoToBuyItemCard = styled(UnstyledGoToBuyItemCard)`
  padding: 12px;

  /* FIXME: We need to update to sort this specificity issue in the design system. */
  &.${paperClassNames.variantOutlined} {
    border: ${({ theme }) => theme.fns.getBorder('secondary')};
  }

  .GoToBuyItemCard-productCardImage {
    max-width: 120px;
  }

  .GoToBuyItemCard-productCardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 8px;
  }
`;
