import { VFC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import {
  Stack,
  Avatar,
  Typography,
  Modal,
  Link,
  Tabs,
  Tab,
  BookmarkIcon,
  HangerIcon,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import CollectionTileList from 'src/mvp22/tile-components/CollectionTileList';
import { Page } from 'src/components/common/Page';
import { MoreProfileOptionsButton } from 'src/components/profile/MoreProfileOptionsButton';
import { ProfileSharePanel } from 'src/components/profile/ProfileSharePanel';
import { CreatorDialog } from 'src/components/profile/CreatorDialog';
import { ProfileProps } from './Profile.types';
import { SustainabilityPoll } from 'src/components/profile/SustainabilityPoll';

const UnstyledProfile: VFC<ProfileProps> = ({
  className,
  style,
  profileUserUID,
  profileName,
  profileBio,
  profileDisplayUsername,
  profileLink,
  profileIsPro,
  profileIsOwn,
  profileImageSource,
  profileCreatorStatus,
  activeTab,
  isLoading,
  isShareModalOpen,
  isCreatorDialogHidden,
  isOnboardedCreator,
  onCloseCreatorDialog,
  onTabChange,
  toggleShare,
  onEdit,
  onGivePro,
}) => {
  // NOTE We have accepted the local state coming from `isCreatorDialogHidden`
  // sticks even when the API call marking the user as an onboarded creator
  // failed.
  const showCreatorDialog =
    profileIsOwn &&
    (profileCreatorStatus === 'applied' ||
      (profileCreatorStatus === 'accepted' &&
        !isCreatorDialogHidden &&
        !isOnboardedCreator));

  const avatarInnerProps = useMemo(
    () => ({
      proTick: {
        onClick: onGivePro,
      },
    }),
    [onGivePro],
  );

  return (
    <Page className={className} style={style} hug="edge" selected={R.HOME}>
      <Helmet>
        <title>
          {profileIsOwn ? 'My Collections' : profileName} - Moonsift
        </title>
      </Helmet>
      {isLoading ? (
        <LoadingDisplay message="Loading Profile..." />
      ) : (
        <>
          {showCreatorDialog && (
            <CreatorDialog
              className="Profile-creatorDialog"
              creatorStatus={profileCreatorStatus}
              onClose={onCloseCreatorDialog}
            />
          )}
          <Stack
            className="Profile-heading"
            direction="column"
            alignItems="center"
          >
            <Avatar
              className="Profile-avatar"
              alt={profileName}
              src={profileImageSource}
              isPro={profileIsPro}
              size={108}
              innerProps={avatarInnerProps}
            >
              {profileName[0]}
            </Avatar>
            <Typography
              className="Profile-name"
              variant="primary.b26"
              component="h1"
            >
              {profileName}
              <MoreProfileOptionsButton
                profileUserUID={profileUserUID}
                onShare={toggleShare}
                onEdit={onEdit}
              />
            </Typography>
            {profileBio && (
              <Typography
                className="Profile-bio"
                variant="primary.r12"
                component="p"
                color="grey.2"
              >
                {profileBio}
              </Typography>
            )}
            <Typography
              className="Profile-username"
              variant="primary.r12"
              component="p"
              color="grey.2"
            >
              {profileIsOwn && profileIsPro ? (
                <Link className="Profile-link" to={R.EDIT_USERNAME}>
                  {`@${profileDisplayUsername}`}
                </Link>
              ) : (
                `@${profileDisplayUsername}`
              )}
              {profileLink && (
                <Link
                  className="Profile-link"
                  to={
                    profileLink.startsWith('http')
                      ? profileLink
                      : `https://${profileLink}`
                  }
                >
                  {profileLink}
                </Link>
              )}
            </Typography>
          </Stack>
          {profileIsOwn && (
            <Stack alignItems="center">
              <SustainabilityPoll className="Profile-sustainabilityPoll" />
            </Stack>
          )}
          {profileIsOwn && (
            <Tabs
              className="Profile-tabs"
              value={activeTab}
              onChange={onTabChange}
            >
              <Tab value="all_user_collections">Saved</Tab>
              <Tab value="bookmarked" startIcon={<BookmarkIcon size="small" />}>
                Bookmarked
              </Tab>
              <Tab value="purchased" startIcon={<HangerIcon size="small" />}>
                Purchased
              </Tab>
            </Tabs>
          )}
          <div className="Profile-collectionListContainer">
            <CollectionTileList
              profileUserUID={profileUserUID}
              activeTab={activeTab}
            />
          </div>
          <Modal
            mode="modal"
            isOpen={isShareModalOpen}
            onRequestClose={toggleShare}
          >
            <ProfileSharePanel
              displayUsername={profileDisplayUsername}
              onClose={toggleShare}
            />
          </Modal>
        </>
      )}
    </Page>
  );
};

export const Profile = styled(UnstyledProfile)`
  margin-bottom: 40px;

  .Profile-creatorDialog {
    margin: 0 auto 24px;
  }

  .Profile-heading {
    margin: 0 16px;
  }

  .Profile-avatar {
    margin-bottom: 16px;
  }

  .Profile-name {
    text-align: center;
    margin-bottom: 8px;
  }

  .Profile-bio {
    margin-bottom: 12px;
    white-space: pre-line;
  }

  .Profile-username {
    margin-bottom: 26px;
  }

  .Profile-name,
  .Profile-bio,
  .Profile-username {
    text-align: center;
    max-width: 536px;
  }

  .Profile-link {
    color: currentColor;
    margin-left: 8px;
    text-decoration: none;
  }

  .Profile-collectionListContainer {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    -webkit-box-pack: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto 32px;

    *,
    *::before,
    *::after {
      box-sizing: initial;
    }
  }

  .Profile-sustainabilityPoll {
    margin-bottom: 12px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .Profile-avatar {
      margin-bottom: 12px;
    }

    .Profile-name {
      font-size: 42px;
      line-height: 50px;
      margin-bottom: 12px;
    }

    .Profile-username {
      margin-bottom: 28px;
    }

    .Profile-tabs {
      margin-bottom: 8px;
    }
  }
`;
