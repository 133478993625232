import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Typography,
  TextField,
  StandardButton,
  Alert,
  Link,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { ExtensionIssuesReportProps } from './ExtensionIssuesReport.types';
import { EXTERNAL } from 'src/routes';

const UnstyledExtensionIssuesReport: React.FC<ExtensionIssuesReportProps> = ({
  className,
  style,
  description,
  working,
  reported,
  onChange,
  onReport,
  restartExtension,
  workingRestartingExtension,
  chromeVersion,
}) => {
  return (
    <>
      <Helmet>
        <title>Report An Extension Issue - Moonsift</title>
      </Helmet>
      <Page className={className} style={style}>
        <div className="ExtensionIssuesReport-content">
          {reported ? (
            <Typography variant="primary.b26" component="h1">
              Thank you - we have received your report and will look into it
            </Typography>
          ) : (
            <form onSubmit={onReport} className="ExtensionIssuesReport-form">
              <Typography
                variant="editorial.sb34"
                component="h2"
                style={{ textAlign: 'center' }}
              >
                Report an issue with the extension
              </Typography>
              {chromeVersion && chromeVersion < 115 && (
                <Alert severity="warning">
                  You are using an old version of Google Chrome ({chromeVersion}
                  ) that we no longer support. Please{' '}
                  <Link to={EXTERNAL.CHROME_UPGRADE} target="_blank">
                    update to the latest version
                  </Link>
                </Alert>
              )}
              <Typography variant="primary.b18" component="h2">
                Please describe the problem,
              </Typography>
              <Typography variant="primary.r14" component="h2">
                Include the site you want to save from. The more information the
                better!
              </Typography>
              <TextField
                className="ExtensionIssuesReport-descriptionInput"
                name="description"
                placeholder="Problem"
                defaultValue="Random"
                value={description}
                onChange={onChange}
                autoFocus
                fullWidth
                multiline
                maxLength={4000}
                rows={4}
              />
              <StandardButton
                type="submit"
                variant="primary-white"
                loading={working}
                disabled={description.length === 0}
                fullWidth
                style={{ marginTop: '-32px' }}
              >
                Tell Moonsift
              </StandardButton>
              <Typography
                variant="primary.r14"
                component="h2"
                style={{ marginTop: '60px' }}
              >
                Alternatively, you can try restarting your Moonsift Extension:
              </Typography>
              <StandardButton
                onClick={restartExtension}
                variant="primary-white"
                fullWidth
                loading={workingRestartingExtension}
              >
                Restart Extension
              </StandardButton>
            </form>
          )}
        </div>
      </Page>
    </>
  );
};

export const ExtensionIssuesReport = styled(UnstyledExtensionIssuesReport)`
  .ExtensionIssuesReport-form {
  }
  .ExtensionIssuesReport-content {
    margin: 0 auto;
    max-width: 576px;

    ${({ theme }) => theme.fns.getMediaQuery({ maxWidth: 608 })} {
      margin: 0 16px;
    }
  }

  .ExtensionIssuesReport-descriptionInput {
    margin-bottom: 40px;
  }
`;
