/*


T Y P E S C R I P T !

This hook has a bunch of organising functions for changing the order in a collection, adding covers and (for pro users) creating subsections.


TODO: UseCallback wrappers required!

I haven't put any useSelectors in here, to keep it kinda separate from the db.

*/
import {
  useState,
  useCallback,
  useContext,
  MouseEvent,
  useEffect,
} from 'react';
import moveItems from 'src/mvp22/core-components/moveItems';
import redraftOrders from 'src/mvp22/core-components/redraftOrders';
import getNextFreeIndex from 'src/utils/getNextFreeIndex';
import updateCollectionOrder from 'src/mvp22/firebase-functions/update_collection_order';
import sortCollection from 'src/utils/sortCollection';
import { ServicesContext } from 'src/ServicesContext';
import {
  ItemToSubsectionMap,
  SubsectionInfoMap,
  CoverImageDict,
} from 'src/types/models/collection.model';
import { UseOrganisingHookReturn } from './useOrganising.types';
import { CollectionOrganisingProps } from 'src/views';
export function useOrganising({
  collectionUID,
  authUserUID,
  singleCollectionOrganisingData,
  singleCollectionItemListIDs,
}: {
  collectionUID: string;
  authUserUID: string | null;
  singleCollectionOrganisingData: CollectionOrganisingProps | undefined;
  singleCollectionItemListIDs: string[];
}): UseOrganisingHookReturn {
  const { analytics, firebaseMVP22: firebase } = useContext(ServicesContext);
  const [isOrganising, setIsOrganising] = useState(false);
  // for the ordering:
  const [originalOrderList, setOriginalOrderList] = useState<string[]>([]);
  // Local copies of collection data to enable oranising to update them without flashing up other values and making it look like the re-organisation did not save!
  // Alternative could perhaps be to blank out products until DB update received.
  // Also sets defaults if non yet!
  const [orderArray, setOrderArray] = useState<string[]>([]);
  const [subsectionOrder, setSubsectionOrder] = useState<number[]>([]);
  const [itemToSubsectionMap, setItemToSubsectionMap] =
    useState<ItemToSubsectionMap>({});
  const [subsectionInfo, setSubsectionInfo] = useState<SubsectionInfoMap>({});
  const [coverImageDict, setCoverImageDict] = useState<CoverImageDict>({});
  const [coverImageURL, setCoverImageURL] = useState<string | null>(null);
  // Now map these into local copies such that we can handle organising etc. nicely (no jumping around when saving ordering mainly)
  useEffect(() => {
    if (singleCollectionOrganisingData) {
      setOrderArray(singleCollectionOrganisingData.order_array ?? []);
      setSubsectionOrder(singleCollectionOrganisingData.subsection_order ?? []);
      setItemToSubsectionMap(
        singleCollectionOrganisingData.item_to_subsection_map ?? {},
      );
      setSubsectionInfo(singleCollectionOrganisingData.subsection_info ?? {});
      setCoverImageDict(singleCollectionOrganisingData.cover_image_dict ?? {});
      setCoverImageURL(singleCollectionOrganisingData.cover_image_url ?? null);
    }
  }, [singleCollectionOrganisingData]);

  // Product Ordering:
  const [isSavingOrdering, setIsSavingOrdering] = useState(false); // working on saving the collection ordering?
  const [organisingOrder, setOrganisingOrder] = useState<string[]>([]);
  // Subsections:
  const [organisingItemToSubsectionMap, setOrganisingItemToSubsectionMap] =
    useState<ItemToSubsectionMap>({});
  const [organisingSubsectionOrder, setOrganisingSubsectionOrder] = useState<
    number[]
  >([]);
  const [organisingSubsectionInfo, setOrganisingSubsectionInfo] =
    useState<SubsectionInfoMap>({});
  const [focusSubsectionTextbox, setFocusSubsectionTextbox] = useState(-1);
  // Cover images:
  const [organisingCoverImageURL, setOrganisingCoverImageURL] = useState<
    string | null
  >(null);
  const [organisingCoverImageDict, setOrganisingCoverImageDict] =
    useState<CoverImageDict>({});
  const [choosingCoverImage, setChoosingCoverImage] = useState(false);

  // effect on updates from db of order of items in collection:
  useEffect(() => {
    // I.e. the order by last added....
    setOriginalOrderList(singleCollectionItemListIDs);
    // if organising at this point, we still want to add / remove items to the order so add them here:
    setOrganisingOrder((prevState) =>
      isOrganising
        ? sortCollection(singleCollectionItemListIDs, prevState)
        : prevState,
    );
  }, [isOrganising, setOrganisingOrder, singleCollectionItemListIDs]);

  /*
  Note that, in order to be backwards compatible, orderArray is stored irrespective of subsections!
  It is the responsibility of the function here to obey those rules when moving things around:
  e.g. move to start of subsection not



  Switching between ordering and viewing:


  */
  const handleOrganising = useCallback(() => {
    // IS now being set as not organising....
    if (authUserUID !== null) {
      if (isOrganising === false) {
        analytics.recordEvent('WebApp:OrganisingButton:Pressed');
        // Set as organising:
        setOrganisingOrder(
          // ensure deleted products are removed etc.:
          redraftOrders(
            subsectionOrder,
            sortCollection(originalOrderList, orderArray),
            itemToSubsectionMap,
          ),
        );
        setOrganisingItemToSubsectionMap(itemToSubsectionMap);
        setOrganisingSubsectionInfo(subsectionInfo);
        setOrganisingSubsectionOrder(subsectionOrder);
        setOrganisingCoverImageDict(coverImageDict);
        setOrganisingCoverImageURL(coverImageURL);
        setIsOrganising(true);
        setChoosingCoverImage(false);
      } else if (isOrganising === true) {
        // Working flag:
        setIsSavingOrdering(true);
        // clean up the subsection stuff in case it has any deleted products in it:
        // Doing it here means that they can be deleted and undid back into the subsections....
        const newItemToSubsectionMap = { ...organisingItemToSubsectionMap };
        Object.keys(newItemToSubsectionMap).forEach((itemUID) => {
          if (!originalOrderList.includes(itemUID)) {
            delete newItemToSubsectionMap[itemUID];
          }
        });
        setIsOrganising(false);
        setChoosingCoverImage(false);
        setFocusSubsectionTextbox(-1);
        // save the order:
        updateCollectionOrder(
          collectionUID,
          organisingOrder,
          organisingSubsectionOrder,
          organisingSubsectionInfo,
          newItemToSubsectionMap,
          organisingCoverImageDict,
          organisingCoverImageURL,
          firebase,
          authUserUID,
        ).then((result) => {
          if (result) {
            // Remove from the organising info:
            setOrganisingOrder([]);
            setOrganisingItemToSubsectionMap({});
            setOrganisingSubsectionInfo({});
            setOrganisingSubsectionOrder([]);
            setOrganisingCoverImageDict({});
            setOrganisingCoverImageURL('');
            // Transfer over to live version to ensure no flash of old version:
            setOrderArray(organisingOrder);
            setItemToSubsectionMap(newItemToSubsectionMap);
            setSubsectionInfo(organisingSubsectionInfo);
            setSubsectionOrder(organisingSubsectionOrder);
            setCoverImageDict(organisingCoverImageDict);
            setCoverImageURL(organisingCoverImageURL);
            // ensure is in non ordering state when save done (prevent re-clicks...):
            setIsOrganising(false);
          }
          setIsSavingOrdering(false);
        });
      }
    }
  }, [
    analytics,
    authUserUID,
    collectionUID,
    coverImageDict,
    coverImageURL,
    firebase,
    isOrganising,
    itemToSubsectionMap,
    orderArray,
    organisingCoverImageDict,
    organisingCoverImageURL,
    organisingItemToSubsectionMap,
    organisingOrder,
    organisingSubsectionInfo,
    organisingSubsectionOrder,
    originalOrderList,
    subsectionInfo,
    subsectionOrder,
  ]);

  /*

  Product ordering:

  */
  const moveItem = (uid: string, direction: string, event: MouseEvent) => {
    event.preventDefault();
    const newItemOrder = moveItems(organisingOrder, uid, direction);
    setOrganisingOrder(
      redraftOrders(
        organisingSubsectionOrder,
        newItemOrder,
        organisingItemToSubsectionMap,
      ),
    );
    return false;
  };

  /*


  Subsections:


  */
  const addSubsection = (event: MouseEvent, itemToAddUID?: string) => {
    event.preventDefault();
    // get the next free index:
    const newSubsectionUID = getNextFreeIndex(organisingSubsectionOrder);
    const newOrganisingSubsectionOrder = [
      newSubsectionUID,
      ...organisingSubsectionOrder,
    ];
    const newSubsectionInfo = { ...organisingSubsectionInfo };
    newSubsectionInfo[newSubsectionUID] = {
      name: '',
    };
    // Add the current product to it:
    const newOrganisingItemToSubsectionMap = Object.assign(
      {},
      organisingItemToSubsectionMap,
    );
    if (itemToAddUID !== undefined) {
      newOrganisingItemToSubsectionMap[itemToAddUID] = newSubsectionUID;
    }
    // Update all the local states:
    setOrganisingOrder(
      redraftOrders(
        newOrganisingSubsectionOrder,
        organisingOrder,
        newOrganisingItemToSubsectionMap,
      ),
    );
    setOrganisingSubsectionOrder(newOrganisingSubsectionOrder);
    setOrganisingItemToSubsectionMap(newOrganisingItemToSubsectionMap);
    setOrganisingSubsectionInfo(newSubsectionInfo);
    setFocusSubsectionTextbox(newSubsectionUID);
    return false;
  };

  const updateSubsectionName = (
    subsectionUID: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    const newName = event.target.value;
    // get the next free index:
    const newOrganisingSubsectionInfo = { ...organisingSubsectionInfo };
    newOrganisingSubsectionInfo[subsectionUID] = {
      ...newOrganisingSubsectionInfo[subsectionUID],
    };
    newOrganisingSubsectionInfo[subsectionUID].name = newName;
    setOrganisingOrder(
      redraftOrders(
        organisingSubsectionOrder,
        organisingOrder,
        organisingItemToSubsectionMap,
      ),
    );
    setOrganisingSubsectionInfo(newOrganisingSubsectionInfo);
    return false;
  };

  const deleteSubsection = (subsectionUID: number, event: MouseEvent) => {
    event.preventDefault();
    // remove items in this subsection from it and make them undefined - they will drop to the start of the unordered items, why not?
    const newOrganisingItemToSubsectionMap = {
      ...organisingItemToSubsectionMap,
    };
    Object.keys(newOrganisingItemToSubsectionMap).forEach((itemUID) => {
      if (newOrganisingItemToSubsectionMap[itemUID] === subsectionUID) {
        delete newOrganisingItemToSubsectionMap[itemUID];
      }
    });
    // actually remove the subsection from the order of subsections:
    const newOrganisingSubsectionOrder = organisingSubsectionOrder.filter(
      (thisUID) => thisUID !== subsectionUID,
    );
    // And remove the info:
    const newOrganisingSubsectionInfo = { ...organisingSubsectionInfo };
    delete newOrganisingSubsectionInfo[subsectionUID];
    setOrganisingOrder(
      redraftOrders(
        newOrganisingSubsectionOrder,
        organisingOrder,
        newOrganisingItemToSubsectionMap,
      ),
    );
    setOrganisingSubsectionOrder(newOrganisingSubsectionOrder);
    setOrganisingItemToSubsectionMap(newOrganisingItemToSubsectionMap);
    setOrganisingSubsectionInfo(newOrganisingSubsectionInfo);
    return false;
  };

  const moveSubsection = (
    subsectionUID: number,
    direction: string,
    event: MouseEvent,
  ) => {
    event.preventDefault();
    const newItemOrder = moveItems(
      organisingSubsectionOrder,
      subsectionUID,
      direction,
    );
    setOrganisingOrder(
      redraftOrders(
        newItemOrder,
        organisingOrder,
        organisingItemToSubsectionMap,
      ),
    );
    setOrganisingSubsectionOrder(newItemOrder);
    return false;
  };

  // false subsectionUID removes it!
  const addOrRemoveItemToSubsection = (
    productEntryUID: string,
    subsectionUID: number,
    event: MouseEvent,
  ) => {
    event.preventDefault();
    const newOrganisingItemToSubsectionMap = Object.assign(
      {},
      organisingItemToSubsectionMap,
    );
    if (newOrganisingItemToSubsectionMap[productEntryUID] !== subsectionUID) {
      newOrganisingItemToSubsectionMap[productEntryUID] = subsectionUID;
    } else {
      delete newOrganisingItemToSubsectionMap[productEntryUID];
    }
    setOrganisingOrder(
      // ensure deleted products are removed etc.:
      redraftOrders(
        organisingSubsectionOrder,
        organisingOrder,
        newOrganisingItemToSubsectionMap,
      ),
    );
    setOrganisingItemToSubsectionMap(newOrganisingItemToSubsectionMap);
    return false;
  };

  /*

  Collection Covers:

  */
  const deleteCoverByIndex = (itemIndex: number) => {
    const newOrganisingCoverImageDict = { ...organisingCoverImageDict };
    delete newOrganisingCoverImageDict[itemIndex];
    setOrganisingCoverImageDict(newOrganisingCoverImageDict);
  };

  const selectCover = (event: MouseEvent, newItemEntryUID: string) => {
    event.preventDefault();
    // rebuild the dict:
    const newCoverImageDict: CoverImageDict = {};
    var unusedIndex = 3;
    var wasPresent = false;
    for (let i = 3; i > 0; i--) {
      const itemUID = organisingCoverImageDict[i];
      if (newItemEntryUID === itemUID) {
        wasPresent = true;
      } else if (originalOrderList.includes(itemUID)) {
        newCoverImageDict[i] = itemUID;
      } else {
        unusedIndex = i;
      }
    }
    if (wasPresent === false) {
      newCoverImageDict[unusedIndex] = newItemEntryUID;
    }
    setOrganisingCoverImageDict(newCoverImageDict);
    return false;
  };

  /*

  Rendering order:

  */
  const renderOrder = useCallback(() => {
    return isOrganising
      ? {
          itemOrder: organisingOrder,
          subsectionOrder: organisingSubsectionOrder,
          itemToSubsectionMap: organisingItemToSubsectionMap,
          subsectionInfoMap: organisingSubsectionInfo,
          coverImageDict: organisingCoverImageDict,
          coverImageURL: organisingCoverImageURL,
        }
      : {
          itemOrder: sortCollection(originalOrderList, orderArray),
          subsectionOrder: subsectionOrder,
          itemToSubsectionMap: itemToSubsectionMap,
          subsectionInfoMap: subsectionInfo,
          coverImageDict: coverImageDict,
          coverImageURL: coverImageURL,
        };
  }, [
    coverImageDict,
    coverImageURL,
    isOrganising,
    itemToSubsectionMap,
    orderArray,
    organisingCoverImageDict,
    organisingCoverImageURL,
    organisingItemToSubsectionMap,
    organisingOrder,
    organisingSubsectionInfo,
    organisingSubsectionOrder,
    originalOrderList,
    subsectionInfo,
    subsectionOrder,
  ]);

  return {
    // General organising:
    isOrganising,
    isSavingOrdering,
    handleOrganising,
    renderOrder,
    setOrganisingOrder,
    moveItem,
    // Subsections:
    addSubsection,
    moveSubsection,
    deleteSubsection,
    updateSubsectionName,
    addOrRemoveItemToSubsection,
    focusSubsectionTextbox,
    // Covers:
    choosingCoverImage,
    setChoosingCoverImage,
    setOrganisingCoverImageURL,
    deleteCoverByIndex,
    selectCover,
  };
}
