export const generateProductImageSource = (imageCodeOrUrl: string): string => {
  if (imageCodeOrUrl.startsWith('moonimage_')) {
    const urlParts = [
      'https://storage.googleapis.com',
      `${process.env.REACT_APP_FIREBASE_PROJECTID}-product-images`,
      `${imageCodeOrUrl}.jpg`,
    ];

    return urlParts.join('/');
  }

  // NOTE We could have been provided with something other than a Moonsift image
  // code or an image URL at this point but, for the sake of simplicity, we
  // consider this an image URL and just return it.
  return imageCodeOrUrl;
};
