import { VFC, useState, useCallback, ChangeEventHandler } from 'react';
import styled from 'styled-components';
import {
  Typography,
  TextField,
  textFieldClassNames,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  StandardButton,
  EarthIcon,
  PersonAddIcon,
  GiftIcon,
} from '@moonsifttech/design-system';

import {
  CreateCollectionContentProps,
  CollectionTypeOptionKey,
  CollectionTypeOption,
  CollectionTypeOptions,
} from './CreateCollectionContent.types';
import { RegistryConfiguration } from 'src/components/common/RegistryConfiguration';

const collectionTypeOptions: CollectionTypeOptions = {
  public: {
    name: 'Public',
    description:
      'Accessible to anyone without needing an account and visible in your profile',
    icon: 'earth',
  },
  private: {
    name: 'Private',
    description: 'Only people you share the secret link with can view',
    icon: 'person-add',
  },
};

const collectionTypeEntries = Object.entries(collectionTypeOptions) as [
  CollectionTypeOptionKey,
  CollectionTypeOption,
][];

const mapIconNameToIcon: Record<string, React.ReactNode> = {
  'earth': <EarthIcon />,
  'person-add': <PersonAddIcon />,
  'gift': <GiftIcon />,
};

const UnstyledCreateCollectionContent: VFC<CreateCollectionContentProps> = ({
  className,
  style,
  isFirst,
  working,
  selectedCollectionType,
  isRegistry,
  toggleIsRegistry,
  onCollectionCreation,
  onCollectionTypeChange,
}) => {
  const [collectionName, setCollectionName] = useState('');
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      setCollectionName(event.target.value);
    },
    [],
  );

  return (
    <div className={className} style={style}>
      <Typography variant="primary.b26" component="h1">
        {isFirst ? 'Create your first collection' : 'Create new collection'}
      </Typography>
      <form onSubmit={onCollectionCreation}>
        <TextField
          name="name"
          placeholder="Name your collection"
          fullWidth
          defaultValue=""
          maxLength={100}
          onChange={handleChange}
        />
        {/* <TextField
          name="description"
          placeholder="Add more info (optional)"
          fullWidth
          multiline
          defaultValue=""
          minRows={4}
          maxLength={400}
        /> */}
        <ToggleButtonGroup
          className="CreateCollectionContent-toggleButton"
          value={selectedCollectionType}
          onChange={onCollectionTypeChange}
          fullWidth
        >
          {collectionTypeEntries.map(([key, { name, icon }]) => {
            return (
              <ToggleButton
                key={key}
                startIcon={mapIconNameToIcon[icon]}
                value={key}
                color="common.white"
              >
                {name}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>

        <Typography
          className="CreateCollectionContent-typeDescription"
          variant="primary.l14"
          component="p"
        >
          {collectionTypeOptions[selectedCollectionType]?.description}
        </Typography>
        <RegistryConfiguration
          isRegistry={isRegistry}
          toggleIsRegistry={toggleIsRegistry}
        />
        <Stack direction="row" justifyContent="flex-end">
          <StandardButton
            type="submit"
            variant="primary-white"
            disabled={!collectionName}
            loading={working}
          >
            Create
          </StandardButton>
        </Stack>
      </form>
    </div>
  );
};

export const CreateCollectionContent = styled(UnstyledCreateCollectionContent)`
  .${textFieldClassNames.root} {
    margin-bottom: 16px;
  }

  .CreateCollectionContent-toggleButton {
    margin-bottom: 12px;
  }

  .CreateCollectionContent-typeDescription {
    margin-bottom: 36px;
  }

  .CreateCollectionContent-publicRegistryToggle {
    margin-top: -8px;
    margin-bottom: 4px;
  }

  .CreateCollectionContent-giftIcon {
    margin-right: 4px;
  }

  .CreateCollectionContent-registryContainer {
    background-color: var(--grey-6);
    border-radius: 2px;
    padding: 2px;
    margin-bottom: 16px;
  }
  .CreateCollectionContent-registryExplanationDescription {
    padding: 0px 8px;
    margin-bottom: 8px;
  }

  .CreateCollectionContent-publicRegistryToggle {
    margin-top: 8px;
  }

  .CreateCollectionContent-minimumHeight {
    min-height: 180px;
  }
`;
