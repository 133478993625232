import TableCell from '@mui/material/TableCell';

import { EarningsTableProps } from './EarningsTable.types';

export const mobileOrder = [
  'commissionValue',
  'salesValue',
  'sales',
  'clicks',
  'brand',
];

export const desktopOrder = [
  'brand',
  'clicks',
  'sales',
  'salesValue',
  'commissionValue',
];

export const getCells = ({
  totalSalesValue,
  totalCommissionsValue,
}: Pick<EarningsTableProps, 'totalSalesValue' | 'totalCommissionsValue'>): {
  [key: string]: {
    head: JSX.Element;
    footer: JSX.Element;
  };
} => ({
  brand: {
    head: <TableCell key="brand">Brands</TableCell>,
    footer: <TableCell key="brand" />,
  },
  clicks: {
    head: (
      <TableCell key="clicks" align="right">
        Clicks
      </TableCell>
    ),
    footer: <TableCell key="clicks" align="right" />,
  },
  sales: {
    head: (
      <TableCell key="sales" align="right">
        Sales
      </TableCell>
    ),
    footer: <TableCell key="sales" align="right" />,
  },
  salesValue: {
    head: (
      <TableCell key="salesValue" align="right">
        Sales value
      </TableCell>
    ),
    footer: (
      <TableCell key="salesValue" align="right">
        {totalSalesValue}
      </TableCell>
    ),
  },
  commissionValue: {
    head: (
      <TableCell key="commissionValue" align="right">
        Commission
      </TableCell>
    ),
    footer: (
      <TableCell key="commissionValue" align="right">
        {totalCommissionsValue}
      </TableCell>
    ),
  },
});
