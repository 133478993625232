
// GENERAL UI (To move to ui reducer!)
export const SET_CBCOPY = 'SET_CBCOPY';
export const SET_LOGIN_STARTED_TIMER = "SET_LOGIN_STARTED_TIMER";

// Removed when all old redux stuff dealt with:
export const FIRESTORE_REDUX_REMOVE_ALL = "FIRESTORE_REDUX_REMOVE_ALL";
// SAGAS... (will be removed soon into db sagas:)
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const REMOVE_NOTIFICATIONS = "REMOVE_NOTIFICATIONS";
export const LISTEN_FOR_NOTIFICATIONS = "LISTEN_FOR_NOTIFICATIONS";


// Going in this refactor:
export const FIREBASE_SET_AUTH = 'FIREBASE_SET_AUTH';



// Removed when all old redux stuff dealt with:
export function actionCreator(type,data={}){
  return{ type:type,data};
}
export const modalSet = (payload)=>{
  return {
    type:'MODAL_SET',
    payload
  };
};
