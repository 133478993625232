import React, { useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  FeedbackRequestContainerProps,
  OnClickSendFeedbackHandler,
  OnFeedbackChangeHandler,
} from './FeedbackRequest.types';
import { useDBAddDoc } from 'src/hooks/useDBAddDoc';
import { FeedbackDocument } from 'src/types/models/feedback.model';
import { FeedbackRequest } from './FeedbackRequest.component';
import { ServicesContext } from 'src/ServicesContext';
import { RootState } from 'src';

const FeedbackRequestContainer: React.FC<FeedbackRequestContainerProps> = ({
  setCurrentScreen,
}) => {
  const { db, analytics } = useContext(ServicesContext);
  const [feedbackValid, setFeedbackValid] = useState(false);
  const [dbAddFeedbackDoc, isSendingFeedback] = useDBAddDoc<FeedbackDocument>(
    db,
    db.feedbackCollection(),
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserEmail = useSelector<RootState, string | null>(
    (state) => state.auth.email,
  );
  const onClickSendFeedback = useCallback<OnClickSendFeedbackHandler>(
    async (event) => {
      event.preventDefault();
      // Send Feedback to back end:
      await dbAddFeedbackDoc({
        feedback: event.currentTarget.feedback.value,
        user_uid: authUserUID ?? '',
        created_at: db.serverTimestamp(),
        email: authUserEmail ?? '',
      });
      // Will error if failed to send feedback and won't move on.
      setCurrentScreen('feedback-thankyou');
      // Record analytics event:
      analytics.recordEvent('WebApp:Reviews:SentReviewRequestFeedback');
    },
    [
      dbAddFeedbackDoc,
      authUserUID,
      db,
      authUserEmail,
      setCurrentScreen,
      analytics,
    ],
  );
  const onFeedbackChange = useCallback<OnFeedbackChangeHandler>(
    async (event) => {
      event.preventDefault();
      const feedback = event.currentTarget.value;
      if (feedback.length > 0) {
        setFeedbackValid(true);
        return;
      }
      setFeedbackValid(false);
    },
    [],
  );
  return (
    <FeedbackRequest
      onClickSendFeedback={onClickSendFeedback}
      isSendingFeedback={isSendingFeedback}
      onFeedbackChange={onFeedbackChange}
      feedbackValid={feedbackValid}
    />
  );
};

export { FeedbackRequestContainer as FeedbackRequest };
