import { VFC, useEffect, useCallback, useState, useContext } from 'react';

import { PostSignUpContainerProps } from './PostSignUp.types';
import { PostSignUp } from './PostSignUp.component';
import { useDispatch, useSelector } from 'react-redux';
import { unsetUiSignedUpInSession } from 'src/store/actions/ui';
import { ServicesContext } from 'src/ServicesContext';
import { RootState } from 'src';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';

const PostSignUpContainer: VFC<PostSignUpContainerProps> = (props) => {
  // Send analytics
  const dispatch = useDispatch();
  const history = useHistory();
  const { analytics } = useContext(ServicesContext);
  useEffect(() => {
    // Keep for Adwords for now:
    analytics.recordEvent('Viewed Postsignup Page');
    dispatch(unsetUiSignedUpInSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Open a new window with the user
  const [hasAddedExtension, setHasAddedExtension] = useState(false);
  const handleAddChromeExtension = useCallback(() => {
    setHasAddedExtension(true);
    analytics.recordEvent('WebApp:GetChromeExtensionPostSignup:Pressed');
  }, [analytics]);

  const isCreator = useSelector(
    (state: RootState) =>
      state.db.user_verified_settings.data?.creator.status === 'applied',
  );

  const handleNextClick = useCallback(() => {
    history.push(R.COLLECTIONS_CREATE);
    analytics.recordEvent('WebApp:PostSignup:Skip:Pressed');
  }, [analytics, history]);

  return (
    <PostSignUp
      hasAddedExtension={hasAddedExtension}
      onAddChromeExtension={handleAddChromeExtension}
      onGoToNext={handleNextClick}
      isCreator={isCreator}
      {...props}
    />
  );
};

export { PostSignUpContainer as PostSignUp };
