import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import {
  MoreProfileOptionsMenuRef,
  MoreProfileOptionsMenuContainerProps,
} from './MoreProfileOptionsMenu.types';
import { MoreProfileOptionsMenu } from './MoreProfileOptionsMenu.component';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';

const MoreProfileOptionsMenuContainer = React.forwardRef<
  MoreProfileOptionsMenuRef,
  MoreProfileOptionsMenuContainerProps
>(({ profileUserUID, ...otherProps }, ref) => {
  const history = useHistory();
  const handleGoToDashboard = useCallback(() => {
    history.push(R.CREATOR_DASHBOARD);
  }, [history]);

  // Global state
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  const creatorStatus = useSelector(
    (state: RootState) => state.db.user_verified_settings.data?.creator.status,
  );

  // Flag indicating whether the profile's user is the current user
  const isOwn = authUserUID === profileUserUID;

  const isCreator = creatorStatus === 'accepted';

  return (
    <MoreProfileOptionsMenu
      ref={ref}
      isOwn={isOwn}
      isCreator={isCreator}
      onGoToDashboard={handleGoToDashboard}
      {...otherProps}
    />
  );
});

export { MoreProfileOptionsMenuContainer as MoreProfileOptionsMenu };
