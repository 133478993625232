/*

useDBUpdate,
no validation required in here as will be done in fields OR fb rules (no actual preventative validation is done here)

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function update_collection(collection_uid,data,firebase,auth_user_uid,callback=null){
  return new Promise(
    (resolve,reject)=>{
      if (collection_uid!==undefined){
        if (data.name.length > 0 && data.name.length<=100 && !(data.description && data.description.length>400)){
          // double check a query with firestore frist:
          //const last_updated = firebase.serverTimestamp();
          // Not updating collection order by not updating last_updated....
          data.description = data.description?data.description:firebase.deleteField();
          const new_data = Object.assign(
            {},
            data,
            {
              //last_updated,
              user_uid:auth_user_uid,
            }
          );
          firebase.user_collection(
            auth_user_uid,
            collection_uid
          ).update(
            new_data
          ).then(
            response=>{
              if (callback){
                callback(response);
              }
              resolve();
            }
          ).catch(
              (err)=>{
                send_error(err);
                reject();
              }
          );
        }
        else{
          send_error("Name must be between 1 and 100 characters");
          reject();
        }
      }
      else{
        send_error("Require a collection uid to be specified in data");
        reject();
      }
    }
  );
}
