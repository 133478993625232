import { FirebaseApp } from '@firebase/app';
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  getDoc,
  addDoc,
  updateDoc,
  setDoc,
  deleteDoc,
  doc,
  getDocs,
  collection,
  CollectionReference,
  where,
  query,
  Query as FirebaseQuery,
  onSnapshot,
  DocumentData,
  QuerySnapshot,
  Timestamp,
  limit,
  collectionGroup,
  orderBy,
  FirestoreError,
  QueryConstraint,
  startAfter,
  endBefore,
  getDocFromServer,
} from 'firebase/firestore';

export type Query = FirebaseQuery | CollectionReference;

/*

The main database, NoSQL.

*/
export class DB {
  fs: Firestore;

  constructor(app: FirebaseApp) {
    this.fs = getFirestore(app);
    // Development Settings:
    if (process.env.REACT_APP_USE_LOCAL_FIRESTORE === 'TRUE') {
      connectFirestoreEmulator(this.fs, 'localhost', 8383);
    }
  }

  // Add functions here to get db docs + collection refs + queries:
  // Collections + Queries return query objects:
  collectionDoc = (collectionUserUID: string, collectionUID: string) =>
    `/databases/user_product_collection/${collectionUserUID}/${collectionUID}`;

  collectionImages = (collectionUserUID: string, collectionUID: string) =>
    `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/sup_data/collection_images`;

  collectionItem = (collectionUserId: string, collectionId: string, itemId: string) =>
    `/databases/user_product_collection/${collectionUserId}/${collectionId}/list/${itemId}`;

  collectionBookmarkDoc = (
    authUserUID: string,
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    const docID = collectionUserUID + '_____' + collectionUID;
    return `/databases/user_likes/${authUserUID}/likes/liked_collections/${docID}`;
  };
  collectionViewsDoc = (
    authUserUID: string,
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    return `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/view_by_user/${authUserUID}`;
  };
  collectionItemsQuery = (collectionUserUID: string, collectionUID: string) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/list`,
      ),
      orderBy('created_at', 'desc'),
    );
  collectionRegistryPrivate = (
    authUserUID: string,
    collectionUserUID: string,
    collectionUID: string,
    isMine: boolean,
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where('collection_user_uid', '==', collectionUserUID),
      where('collection_uid', '==', collectionUID),
    ];

    if (!isMine) {
      queryConstraints.push(where('purchaser_user_uid', '==', authUserUID));
    }

    return query(
      collectionGroup(this.fs, 'registry_private'),
      ...queryConstraints,
    );
  };

  collectionRegistryPrivateLoggedOut = (
    identifier: string,
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    const queryConstraints: QueryConstraint[] = [
      where('collection_user_uid', '==', collectionUserUID),
      where('collection_uid', '==', collectionUID),
      where('purchaser_identity_identifier', '==', identifier),
    ];

    return query(
      collectionGroup(this.fs, 'registry_private'),
      ...queryConstraints,
    );
  };

  collectionRegistryNotesDocument = (
    collectionUserUID: string,
    collectionUID: string,
  ) => {
    return [
      '/databases/user_product_collection',
      collectionUserUID,
      collectionUID,
      'registry_notes/public',
    ].join('/');
  };

  collectionsList = (listID: string) =>
    `/databases/curated_lists/cllists/${listID}`;

  userPubicDoc = (userUID: string) => `/databases/user/${userUID}/public`;
  userOwnerDoc = (userUID: string) => `/databases/user/${userUID}/owner`;
  userVerifiedSettingsDoc = (userUID: string) =>
    `/databases/user/${userUID}/verified_settings`;
  userScrape = (userUID: string, docUID: string) =>
    `/databases/user_scrape_opinions/${userUID}/${docUID}`;
  itemIsSaved = (authUserUID: string, pageURL: string) =>
    query(
      collectionGroup(this.fs, `list`),
      where('user_uid', '==', authUserUID),
      where('page_url', '==', pageURL),
      limit(1),
    );
  itemIsPurchased = (authUserUID: string, pageURL: string) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${authUserUID}/purchased/list`,
      ),
      where('page_url', '==', pageURL),
      limit(1),
    );
  itemIsLikedOrDisliked = (
    collectionUserUID: string,
    collectionUID: string,
    authUserUID: string,
    itemUID: string,
  ) =>
    query(
      collection(
        this.fs,
        `/databases/user_product_collection/${collectionUserUID}/${collectionUID}/list/${itemUID}/reactions_and_comments`,
      ),
      where('user_uid', '==', authUserUID),
      where('type', 'in', ['upvote', 'downvote']),
    );
  product = (productUID: string) => `/databases/product/products/${productUID}`;
  userCollectionList = (userUID: string) =>
    collection(this.fs, `/databases/user_product_collection/${userUID}`);
  problemList = () => collection(this.fs, '/databases/problem/problems');
  username = (username: string) => `/databases/username/map/${username}`;
  feedbackCollection = () =>
    collection(this.fs, '/databases/feedback/feedbacks');
  // Add functions here to perform actions on collections and docs:
  // TODO: Consider whether we still need this after removing old firebase service from mvp22;
  getDoc = (docRef: string, server: true | undefined = undefined) =>
    server
      ? getDocFromServer(doc(this.fs, docRef))
      : getDoc(doc(this.fs, docRef));
  getQuery = (queryIn: Query) => getDocs(queryIn);
  listenDoc = (
    docRef: string,
    callback: (x: DocumentSnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(doc(this.fs, docRef), callback, onError);
  listenQuery = (
    queryIn: Query,
    callback: (x: QuerySnapshot<DocumentData>) => void,
    onError: (error: FirestoreError) => void,
  ) => onSnapshot(queryIn, callback, onError);
  deleteDoc = (docRef: string) => deleteDoc(doc(this.fs, docRef));
  addDoc = (collectionRef: CollectionReference, data: any) =>
    addDoc(collectionRef, data);
  updateDoc = (docRef: string, data: any) =>
    updateDoc(doc(this.fs, docRef), data);
  setDoc = (docRef: string, data: any) =>
    setDoc(doc(this.fs, docRef), data, { merge: true });
  serverTimestamp = () => {
    return Timestamp.now();
  };
  userEarningsQuery = (
    userUID: string,
    cursor: { type: 'before' | 'after'; fieldValues: [Date, string] },
  ) => {
    const queryConstraints: QueryConstraint[] = [
      orderBy('collection_created_at', 'desc'),
      orderBy('collection_name', 'asc'),
      limit(20),
    ];

    if (cursor) {
      const { type, fieldValues } = cursor;
      const constraintFn = type === 'after' ? startAfter : endBefore;
      queryConstraints.splice(2, 0, constraintFn(...fieldValues));
    }

    return query(
      collection(
        this.fs,
        `/databases/user_earnings/${userUID}/earnings/earnings_collection`,
      ),
      ...queryConstraints,
    );
  };
}

// Types required elsewhere will be imported from here NOT from firebase-firestore so firebase is not
// an explicitly hard-coded dependency!
export type {
  DocumentData,
  QuerySnapshot,
  QueryDocumentSnapshot,
  DocumentSnapshot,
  FirestoreError as DBError,
  PartialWithFieldValue,
} from 'firebase/firestore';
export type ParserInput = DocumentSnapshot | QueryDocumentSnapshot;
export { Timestamp } from 'firebase/firestore';
