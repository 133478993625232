/*

Simple UI information, e.g. window width or if certain modes are toggled on / off.

*/
import { UIAction, ActionNames, UIState } from 'src/types';

const UI_DEFAULT: UIState = {
  windowWidth: document.body.clientWidth,
  windowHeight: document.body.clientHeight,
  signedUpInThisSession: false,
  keepOnProvisioningPage: false,
  showAutoReviewRequest: 'NOT_SHOWN_YET', // can only progress through state > 'SHOW' > 'SHOWN_AND_HIDDEN_ONCE' unless reset by logout
  showReviewRequests: false, // manually controlled
};

export const ui = (state = UI_DEFAULT, action: UIAction): UIState => {
  switch (action.type) {
    case ActionNames.UI_WINDOW_SIZE_SET:
      return {
        ...state,
        windowWidth: document.body.clientWidth,
        windowHeight: document.body.clientHeight,
      };
    case ActionNames.UI_SIGNED_UP_IN_SESSION_SET:
      return {
        ...state,
        signedUpInThisSession: true,
      };
    case ActionNames.UI_SIGNED_UP_IN_SESSION_UNSET:
      return {
        ...state,
        signedUpInThisSession: false,
      };
    case ActionNames.START_KEEP_ON_PROVISIONING_PAGE:
      return {
        ...state,
        keepOnProvisioningPage: true,
      };
    case ActionNames.END_KEEP_ON_PROVISIONING_PAGE:
      return {
        ...state,
        keepOnProvisioningPage: false,
      };
    case ActionNames.UI_CLOSE_REVIEW_REQUEST:
      return {
        ...state,
        showAutoReviewRequest: 'SHOWN_AND_HIDDEN_ONCE',
        showReviewRequests: false,
      };
    case ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST:
      if (state.showAutoReviewRequest === 'NOT_SHOWN_YET') {
        return {
          ...state,
          showAutoReviewRequest: 'SHOW',
        };
      }
      return state;
    case ActionNames.UI_SET_SHOW_REVIEW_REQUESTS:
      return {
        ...state,
        showReviewRequests: true,
      };
    case ActionNames.UNSET_ALL:
      return UI_DEFAULT;
    default:
      return state;
  }
};
