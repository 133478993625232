import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { Paper, Stack, Typography } from '@moonsifttech/design-system';

import { SignInForm } from 'src/components/auth/SignInForm';
import { SignInProps } from './SignIn.types';

const UnstyledSignIn: VFC<SignInProps> = ({ className, style }) => {
  return (
    <Stack
      className={className}
      style={style}
      justifyContent="center"
      alignItems="center"
    >
      <Helmet>
        <title>Login - Moonsift</title>
      </Helmet>
      <Paper className="SignIn-paper">
        <Stack
          className="SignIn-container"
          direction="column"
          alignItems="center"
        >
          <Stack className="SignIn-logo" direction="row">
            <img
              className="SignIn-logoImage"
              src={`${process.env.REACT_APP_STATIC_URL}/logos/logo.svg`}
              alt="Monsift logo"
            />
            <Typography className="SignIn-logoText" variant="branded.b42">
              MOONSIFT
            </Typography>
          </Stack>
          <SignInForm />
        </Stack>
      </Paper>
    </Stack>
  );
};

export const SignIn = styled(UnstyledSignIn)`
  width: 100%;
  height: 100vh;
  background: var(--grey-1);

  .SignIn-paper {
    padding: 36px 14px;
    width: 480px;
  }

  .SignIn-logo {
    margin-bottom: 36px;
    align-items: center;

    .SignIn-logoImage {
      height: 50px;
    }

    .SignIn-logoText {
      padding: 8px 0 0 4px;
    }
  }
`;
