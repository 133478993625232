import { useEffect, useContext, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { firestore_user_set_up_redux } from 'src/mvp22/redux-components/reducers/firestore_user_set_up';

/*

This hook loads in any other data about the user from the database:

TIP: Ask David about it since how we store the data should be refactored.

*/
export const useAuthUserData = () => {
  const dispatch = useDispatch();
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserEmail = useSelector<RootState, string | null>(
    (state) => state.auth.email,
  );
  const authUserSet = useSelector<RootState, boolean>(
    (state) => state.auth.set,
  );

  const {
    cloud,
    notifications,
    firebaseMVP22: firebase,
  } = useContext(ServicesContext);

  // TODO: Remove this in next refactor:
  const firestore_user_set_up = useCallback(
    (firebase: any) => {
      dispatch(firestore_user_set_up_redux(firebase));
    },
    [dispatch],
  );

  useEffect(() => {
    // TODO: Replace this in next refactor:
    if (authUserSet) {
      firestore_user_set_up(firebase);
    }
  }, [
    firestore_user_set_up,
    firebase,
    authUserEmail,
    authUserUID,
    authUserSet,
  ]);

  useEffect(() => {
    // Listen to notifications.messaging for changes as we need this to be enabled to run:
    if (
      notifications.messaging &&
      'Notification' in window &&
      Notification.permission === 'granted'
    ) {
      const notificationTokenPromise = notifications.getToken();
      if (notificationTokenPromise !== null) {
        notificationTokenPromise
          .then(
            (
              token: string, // Now update the notification token:
            ) =>
              cloud.fastAPI({
                api: 'update_notification_token',
                token,
              }),
          )
          .catch((err: any) => console.log(err));
      }
    }
  }, [cloud, notifications, notifications.messaging]);
};
