import React from 'react';
import { AddAnItemProps } from './AddAnItem.types';
import {
  Modal,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@moonsifttech/design-system';
import styled from 'styled-components';
import { ModalContent } from 'src/components/core/ModalContent';
import { AddAnItemPasteALink } from 'src/components/collection/AddAnItemPasteALink';
import { AddAnItemManually } from 'src/components/collection/AddAnItemManually';

const UnstyledAddAnItem: React.FC<AddAnItemProps> = ({
  isOpen,
  onClose,
  className,
  openTab,
  onTabChange,
  isMine,
  isMobile,
  style,
}) => {
  const getContent = () => (
    <ModalContent className={className} style={style} onClose={onClose}>
      <Typography
        className="EditModalContentContent-confirmationHeading"
        variant="primary.b26"
        component="h1"
      >
        Add an item
      </Typography>
      <Stack direction="column" justifyContent="flex-start">
        <Stack
          direction="row"
          justifyContent="space-between"
          className="AddAnItem-toggleRow"
        >
          <ToggleButtonGroup value={openTab} onChange={onTabChange} fullWidth>
            <ToggleButton className="AddAnItem-toggle" value="PASTE_A_LINK">
              Paste URL
            </ToggleButton>
            <ToggleButton className="AddAnItem-toggle" value="ENTER_MANUALLY">
              Enter Manually
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {openTab === 'PASTE_A_LINK' && (
          <AddAnItemPasteALink onClose={onClose} />
        )}
        {openTab === 'ENTER_MANUALLY' && (
          <AddAnItemManually onClose={onClose} />
        )}
      </Stack>
    </ModalContent>
  );

  if (!isMine) {
    return null;
  }

  if (isMobile) {
    return (
      <Modal
        mode="drawer"
        anchor="bottom"
        isOpen={isOpen}
        onRequestClose={onClose}
      >
        {getContent()}
      </Modal>
    );
  }

  return (
    <Modal mode="modal" isOpen={isOpen} onRequestClose={onClose}>
      {getContent()}
    </Modal>
  );
};

export const AddAnItem = styled(UnstyledAddAnItem)`
  background-color: var(--common-white);
  text-align: left;
  max-height: 80vh;
  overflow-y: auto;
  .AddAnItem-toggleContainer {
    width: 100%;
  }
  .AddAnItem-toggle {
    padding: 8px;
    min-width: 0px;
  }

  .AddAnItem-toggleRow {
    margin-top: 2px;
    margin-bottom: 12px;
  }
  .AddAnItem-input {
    margin-bottom: 12px;
  }
  .AddAnItem-info {
    margin-bottom: 12px;
  }
`;
