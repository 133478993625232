import React from 'react';

import { CollectionSharePanelContainerProps } from './CollectionSharePanel.types';
import { ServicesContext } from 'src/ServicesContext';
import { useDispatch, useSelector } from 'react-redux';
import { ActionNames, AutoTriggerReviewRequests } from 'src/types';
import { CollectionSharePanel } from './CollectionSharePanel.component';
import { RootState } from 'src';
import { CollectionType } from 'src/types/models/collection.model';
import { getCollectionType } from 'src/utils/getCollectionType';

const CollectionSharePanelContainer: React.FC<
  CollectionSharePanelContainerProps
> = ({ onClose, openEditCollection, ...props }) => {
  const dispatch = useDispatch();

  const [copied, setCopied] = React.useState(false);
  const [showOverlay, setShowOverlay] = React.useState(false);
  const collectionUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.id ?? '',
  );
  const collectionUserUID = useSelector<RootState, string>(
    (state) => state.db.single_collection.data?.user_uid ?? '',
  );
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  const canBeShared = useSelector<RootState, boolean>(
    (state) =>
      state.db.single_collection.data?.public_level !== undefined &&
      state.db.single_collection.data?.public_level >= 7,
  );
  const isPublicRegistry = useSelector<RootState, boolean>(
    (state) =>
      state.db.single_collection.data?.public_level !== undefined &&
      state.db.single_collection.data?.public_level >= 8,
  );
  const currentVisibilityType = useSelector<
    RootState,
    CollectionType | undefined
  >((state) =>
    state.db.single_collection.data?.public_level !== undefined
      ? getCollectionType(state.db.single_collection.data?.public_level)
      : undefined,
  );

  const isRegistry = useSelector<RootState, boolean>(
    (state) => state.db.single_collection.data?.is_registry ?? false,
  );

  const { analytics } = React.useContext(ServicesContext);

  React.useEffect(() => {
    analytics.recordEvent('WebApp:CollectionSharePanel:Opened', {
      collectionUID: collectionUID,
      collectionUserUID: collectionUserUID,
    });
  }, [analytics, collectionUID, collectionUserUID]);

  React.useEffect(() => {
    if (copied) {
      const handle = setTimeout(() => setCopied(false), 2000);
      return () => clearTimeout(handle);
    }
  }, [copied]);

  const handleCopy = React.useCallback<
    React.FormEventHandler<
      HTMLFormElement & {
        url: HTMLInputElement;
      }
    >
  >(
    (event) => {
      event.preventDefault();

      const input = event.currentTarget.url;

      // Select the text field
      input.select();
      input.setSelectionRange(0, 99999); /* For mobile devices */

      // Copy the text inside the text field
      navigator.clipboard.writeText(input.value);

      // Set state as copied
      setCopied(true);

      analytics.recordEvent('WebApp:LinkCopied', { link: input.value });
    },
    [analytics],
  );

  const handleClose = React.useCallback(() => {
    onClose && onClose();
    dispatch<AutoTriggerReviewRequests>({
      type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
      payload: undefined,
    });
  }, [dispatch, onClose]);

  const handleOpenEditCollection = React.useCallback(() => {
    onClose && onClose();
    openEditCollection();
  }, [onClose, openEditCollection]);

  const triggerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  return (
    <CollectionSharePanel
      onClose={handleClose}
      onCopy={handleCopy}
      openEditCollection={handleOpenEditCollection}
      copied={copied}
      canBeShared={canBeShared}
      currentVisibilityType={currentVisibilityType}
      isRegistry={isRegistry}
      isPublicRegistry={isPublicRegistry}
      isPro={isPro}
      triggerRef={triggerRef}
      containerRef={containerRef}
      setShowOverlay={setShowOverlay}
      showOverlay={showOverlay}
      {...props}
    />
  );
};

export { CollectionSharePanelContainer as CollectionSharePanel };
