import { VFC } from 'react';
import styled from 'styled-components';
import {
  Stack,
  Typography,
  StandardButton,
  FormHelperText,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { Page } from 'src/components/common/Page';
import { PendingCreatorDashboardProps } from './PendingCreatorDashboard.types';
import { HowToVideo } from 'src/components/creator/HowToVideo';

const UnstyledPendingCreatorDashboard: VFC<PendingCreatorDashboardProps> = ({
  className,
  style,
  onBack,
  handleRequestApproval,
  requestApprovalWorking,
  requestApprovalError,
}) => {
  return (
    <Page className={className} style={style} hug="edge" selected={R.HOME}>
      <Stack className="PendingCreatorDashboard-container">
        <Typography
          className="PendingCreatorDashboard-heading"
          variant="editorial.sb34"
          align="center"
          component="h1"
        >
          Unlock your analytics dashboard
        </Typography>
        <Typography
          className="PendingCreatorDashboard-paragraph"
          variant="primary.l16"
          align="center"
          component="p"
        >
          Add any 5 items to a collection to unlock your analytics dashboard.
        </Typography>
        <Typography
          className="PendingCreatorDashboard-paragraph"
          variant="primary.l16"
          align="center"
          component="p"
        >
          This may take up to 10 minutes to process.
        </Typography>
        <Stack
          className="PendingCreatorDashboard-buttonContainer"
          direction="row"
          justifyContent="center"
        >
          <StandardButton
            className="PendingCreatorDashboard-goButton"
            variant="primary-white"
            onClick={handleRequestApproval}
            loading={requestApprovalWorking}
          >
            I've added 5+ items
          </StandardButton>
        </Stack>
        <Stack
          className="PendingCreatorDashboard-buttonContainer"
          direction="row"
          justifyContent="center"
        >
          {requestApprovalError && (
            <FormHelperText error>{requestApprovalError}</FormHelperText>
          )}
        </Stack>
        <Typography
          className="PendingCreatorDashboard-paragraph"
          variant="primary.l16"
          align="center"
          component="p"
        >
          <Link to={R.HOW_TO_SAVE}>How to add items</Link>
        </Typography>
        <Stack direction="row" justifyContent="center">
          <hr />
        </Stack>
        <HowToVideo includeExplanation={false} />
        <Stack direction="row" justifyContent="center">
          <hr />
        </Stack>
        <Stack direction="row" justifyContent="center">
          <img
            className="PendingCreatorDashboard-letterImage"
            src={`${process.env.REACT_APP_STATIC_URL}/creator/letter.svg`}
            alt="PayPal logo"
          />
        </Stack>
        <Typography
          className="PendingCreatorDashboard-paragraph"
          variant="primary.l16"
          align="center"
          component="p"
        >
          Check your inbox and junk folder. We've sent an email with more info.
        </Typography>
        <Stack direction="row" justifyContent="center">
          <hr />
        </Stack>
        <Stack
          className="PendingCreatorDashboard-buttonContainer PendingCreatorDashboard-lastContainer"
          direction="row"
          justifyContent="center"
        >
          <StandardButton variant="primary-white" onClick={onBack}>
            View Collections
          </StandardButton>
        </Stack>
      </Stack>
    </Page>
  );
};

export const PendingCreatorDashboard = styled(UnstyledPendingCreatorDashboard)`
  min-height: 100vh;
  background: var(--grey-7);

  .PendingCreatorDashboard-container {
    margin: 0 auto;
    padding: 0 24px;
    max-width: 448px;
  }

  .PendingCreatorDashboard-heading {
    margin-bottom: 20px;
  }

  .PendingCreatorDashboard-paragraph {
    margin-bottom: 20px;
  }

  hr {
    width: 100%;
    max-width: 328px;
    margin: 20px 0 40px;
    border-top: 1px solid var(--grey-4);
    border-bottom: none;
  }

  .PendingCreatorDashboard-letterImage {
    max-width: 68px;
    margin-bottom: 12px;
  }

  .PendingCreatorDashboard-buttonContainer {
    margin-bottom: 20px;
  }

  .PendingCreatorDashboard-lastContainer {
    margin-bottom: 100px;
  }

  .PendingCreatorDashboard-goButton {
  }
`;
