import getUserLocale from 'get-user-locale';

export class CurrencyFormatter {
  // The `getUserLocale` function normalises locale format inconsistencies
  // between browsers and provides the "en-US" locale as a default in Node
  // environments.
  // TODO Ideally, once we have SSR, we should get the user locale from the
  // HTTP request (Accept-Language header).
  private static locale = getUserLocale();

  private static mapCurrencyToFormatter: Map<
    string,
    Intl.NumberFormat | undefined
  > = new Map();

  /**
   * Removes decimals when the provided amount is over 10,000.
   */
  private static beautifyAmount(amount: number): number {
    if (amount < 10000) {
      return amount;
    }

    return Math.floor(amount);
  }

  public static format(amount: number, currency: string): string {
    // Initialise formatter if it doesn't exist.
    if (!this.mapCurrencyToFormatter.has(currency)) {
      this.mapCurrencyToFormatter.set(
        currency,
        // Currency formatter using the current locale
        new Intl.NumberFormat(this.locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: 2,
        }),
      );
    }

    // Format the provided amount using the current locale or, in other words,
    // the preferred language as set in browser settings.
    const beautifiedAmount = this.beautifyAmount(amount);
    return this.mapCurrencyToFormatter.get(currency)!.format(beautifiedAmount);
  }
}
