/*

The arrows that appear below products when in Organise mode.
TODO: typescript

*/
import React from 'react';
import styled from 'styled-components';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import {M} from 'src/mvp22/constants';
import SubsectionsToAddTo from './SubsectionsToAddTo';
const ArrowsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 28px;
  padding-right: 28px;
  justify-content: space-between;
  margin-bottom: 26px;
  align-items:center;
`;

const Arrow = styled.img`
  cursor:pointer;
  box-sizing: border-box;
  border-radius: 5px;
  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const OrganiseCentreAdd = styled.img`
  height:20px;
  width:20px;
  cursor:pointer;
  box-sizing: border-box;
`;

const ArrowLink = styled(TEXTSTYLE.LINKA)`
color:default;
`;

const OrganiseCentreAddButton = styled.div`
  border: 1px solid ${M.COL.BUTTON.BLACK};
  box-sizing: border-box;
  height:30px;
  width:30px;
  display:flex;
  align-items:center;
  justify-content: center;
  border-radius: 50%;
  &:hover{
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const CentralGap = styled.div`
  width:30px;
  height:30px;
`;

const OrganiseArrows = (props)=>{
  const item_uid = props.uid;
  const moveItem = props.moveItem;
  return(
    <ArrowsContainer>
      <ArrowLink onClick={(event)=>moveItem(item_uid,"start",event)} href="#"><Arrow src={MEDIA.TO_TOP}/></ArrowLink>
      <ArrowLink onClick={(event)=>moveItem(item_uid,"back",event)} href="#"><Arrow src={MEDIA.LEFT}/></ArrowLink>
      {
        props.show_subsection_add?
          <SubsectionsToAddTo
           subsection_list={props.subsection_list}
           current_subsection_uid={props.current_subsection_uid}
           addOrRemoveItemToSubsection={props.addOrRemoveItemToSubsection}
           product_uid={props.uid}
           addSubsection={props.addSubsection}
          >
            <OrganiseCentreAddButton><OrganiseCentreAdd src={MEDIA.BLACK_PLUS}/></OrganiseCentreAddButton>
          </SubsectionsToAddTo>
        :
          <CentralGap/>
      }
      <ArrowLink onClick={(event)=>moveItem(item_uid,"forward",event)} href="#"><Arrow src={MEDIA.RIGHT}/></ArrowLink>
      <ArrowLink onClick={(event)=>moveItem(item_uid,"end",event)} href="#"><Arrow src={MEDIA.TO_BOTTOM}/></ArrowLink>
    </ArrowsContainer>
  );
};

export default OrganiseArrows;
