import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import {
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuContainerProps,
} from './MoreCollectionOptionsMenu.types';
import { MoreCollectionOptionsMenu } from './MoreCollectionOptionsMenu.component';
import { useBookmarkCollection } from 'src/hooks/useBookmarkCollection';

const MoreCollectionOptionsMenuContainer = React.forwardRef<
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuContainerProps
>((props, ref) => {
  // Global state
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const collectionUID = useSelector<RootState, string | undefined>(
    ({ db }) => db.single_collection.data?.id,
  );
  const collectionUserUID = useSelector<RootState, string | undefined>(
    ({ db }) => db.single_collection.data?.user_uid,
  );

  const { isBookmarked, working, handleBookmark } = useBookmarkCollection();

  // Flag indicating whether the collection's user is the current user
  const isMine = authUserUID === collectionUserUID;
  const isPurchased = collectionUID === 'purchased';

  return (
    <MoreCollectionOptionsMenu
      ref={ref}
      isMine={isMine}
      isPurchased={isPurchased}
      onBookmark={handleBookmark}
      bookmarkLoading={working}
      isBookmarked={isBookmarked === true}
      {...props}
    />
  );
});

export { MoreCollectionOptionsMenuContainer as MoreCollectionOptionsMenu };
