/*

Show this moonsift logo flashing when something is loading.

TODO: typescript

*/
import React from 'react';
import { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { M } from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
//import PropTypes from 'prop-types';

const rotate = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  padding: 30px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

const MoonsiftLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const LogoLoading = styled.img`
  animation: ${rotate} 3s linear infinite;
  opacity: 0.5;
  height: 42px;
  margin-bottom: 16px;
`;

const TextLoading = styled(TEXTSTYLE.HEADING5)`
  color: ${M.COL.TEXT.MID};
`;

/**
 * Loading Page Component
 */
class Loading extends Component {
  render() {
    return (
      <Container>
        <MoonsiftLogo>
          <LogoLoading src={MEDIA.LOGO_BW} />
          <TextLoading>{this.props.message}</TextLoading>
        </MoonsiftLogo>
      </Container>
    );
  }
}

export default Loading;
