/*
  Shown when the user clicks "Add a message" on the MarkedAsBought dialog.
*/

import { useState, useContext, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { FormikConfig } from 'formik';
import { object, string } from 'yup';
import {
  Typography,
  StandardButton,
  ControlledTextField,
  textFieldClassNames,
  Stack,
  FormHelperText,
  Form,
} from '@moonsifttech/design-system';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { useItem } from 'src/hooks/useItem';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { ModalContent } from 'src/components/core/ModalContent';

const UnstyledAddMessageDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const { cloud, analytics } = useContext(ServicesContext);
  const [ setModal, closeModal ] = useModal();

  const ownerName = useSelector<RootState, string>(({ db }) => {
    return db.user_public.map[collectionUserId]?.name ?? 'The owner';
  });
  const { privateEntry } = useItem(itemId);
  const currentMessage = privateEntry?.message?.text ?? '';

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const submit = useCallback<FormikConfig<{ message: string; }>['onSubmit']>(
    async ({ message }, actions) => {
      try {
        setLoading(true);
        await cloud.fastAPI2('sendMessageToRegistryOwner', {
          collection_user_uid: collectionUserId,
          collection_uid: collectionId,
          collection_item_uid: itemId,
          message,
          identity: {
            identifier: analytics.clientIdentifier,
          },
        });
        setErrorMessage('');
        closeModal();
      } catch (error) {
        const typedError = error as Error;
        setErrorMessage(typedError.message);
        console.error(error);
      } finally {
        actions.setSubmitting(false);
        setLoading(false);
      }
    },
    [
      cloud,
      collectionUserId,
      collectionId,
      itemId,
      analytics.clientIdentifier,
      closeModal,
    ],
  );

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        Add a message
      </Typography>
      <Typography variant="primary.r16" component="p">
        {ownerName} will see your message when they reveal which items have been
        purchased.
      </Typography>
      <Form
        initialValues={{ message: currentMessage }}
        validationSchema={object({
          message: string().required('A message is required'),
        })}
        onSubmit={submit}
      >
        <ControlledTextField
          name="message"
          multiline
          rows={4}
          placeholder={`Write a message to ${ownerName}... (optional)`}
          maxLength={400}
          fullWidth
        />
        <Stack direction="row" justifyContent="flex-end" spacing={12}>
          <StandardButton
            variant="secondary"
            onClick={() => setModal({
              type: ModalTypes.MarkedAsBought,
              collectionId,
              collectionUserId,
              itemId,
            })}
          >
            Back
          </StandardButton>
          <StandardButton
            type="submit"
            variant="primary-white"
            loading={loading}
          >
            Done
          </StandardButton>
        </Stack>
        {errorMessage && (
          <Stack direction="row" justifyContent="flex-end">
            <FormHelperText
              className="AddMessageDialog-errorMessage"
              error
            >
              {errorMessage}
            </FormHelperText>
          </Stack>
        )}
      </Form>
    </ModalContent>
  );
};

export const AddMessageDialog = styled(UnstyledAddMessageDialog)`
  .${textFieldClassNames.root} {
    margin-bottom: 32px;
  }

  .AddMessageDialog-errorMessage {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .${textFieldClassNames.root} {
      margin-bottom: 46px;
    }
  }
`;
