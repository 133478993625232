/*
Displays fading in and our Moonsift logo to indicate loading.  Used all over the place!

TODO: typescript, make functional, non-default exports

*/
import React from 'react';
import { Component } from 'react';
import styled, {keyframes} from 'styled-components';
import {M} from 'src/mvp22/constants';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';

const rotate = keyframes`
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
`;

const Container = styled.div`
  background-color:${M.COL.BUTTON.WHITE};
  padding-left:30px;
  padding-right:30px;
  font-size:20px;
  height:100vh;
  display:flex;
  align-items: center;
  justify-content: center;
`;

const MoonsiftLogo = styled.div`
  width:200px;
  animation: ${rotate} 3s linear infinite;
`;

/**
* Loading Page Component
*/
class Loading extends Component {
  render(){
    return(<Container>
      <MoonsiftLogo>
        <LogoAndText heighttype="A"/>
      </MoonsiftLogo>
      </Container>
    );
  }
}

export default Loading;
