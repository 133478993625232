import { VFC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { CollectionCarouselTileContainerProps } from './CollectionCarouselTile';
import { CollectionCarouselTile } from './CollectionCarouselTile.component';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { ServicesContext } from 'src/ServicesContext';
import { collectionParser } from 'src/store/parsers/collection';
import { useUserPublicDoc } from 'src/hooks/useUserPublicDoc';
import { collectionImagesParser } from 'src/store/parsers/collectionImages';

const CollectionCarouselTileContainer: VFC<
  CollectionCarouselTileContainerProps
> = ({ index, collectionUserUID, collectionUID, handleDataLoad }) => {
  const storeAs = collectionUserUID + '_' + collectionUID;

  const { db } = useContext(ServicesContext);
  useDBRetrieveDoc<'collection_list_map'>(
    db,
    db.collectionDoc,
    'collection_list_map',
    collectionParser,
    storeAs,
    [collectionUserUID, collectionUID],
    'get',
  );

  useDBRetrieveDoc<'collection_images_map'>(
    db,
    db.collectionImages,
    'collection_images_map',
    collectionImagesParser,
    storeAs,
    [collectionUserUID, collectionUID],
    'get',
  );

  useUserPublicDoc(collectionUserUID);

  const collectionUserPublic = useSelector(
    (state: RootState) => state.db.user_public.map[collectionUserUID],
  );

  const collectionEntry = useSelector(
    (state: RootState) => state.db.collection_list_map.map[storeAs],
  );

  const collectionImages = useSelector(
    (state: RootState) => state.db.collection_images_map.map[storeAs],
  );

  // Reasses size of the entry on load:
  useEffect(handleDataLoad, [
    handleDataLoad,
    collectionUserPublic,
    collectionEntry,
    collectionImages,
  ]);

  if (!collectionUserPublic || !collectionEntry || !collectionImages) {
    return null;
  }

  return (
    <CollectionCarouselTile
      {...{
        collectionUserUID,
        collectionUID,
        collectionEntry,
        collectionUserPublic,
        collectionImages,
        index,
      }}
    />
  );
};

export { CollectionCarouselTileContainer as CollectionCarouselTile };
