import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { useWorking } from 'src/hooks/useWorking';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import update_product_opinion from 'src/mvp22/firebase-functions/update_product_opinion';
import { ActionNames, AutoTriggerReviewRequests, ProductData } from 'src/types';
import { useSetFooterPopup } from 'src/hooks/useSetFooterPopup';

export const useSetPurchased = (
  productDataStoreAs: string,
  productData: ProductData | null | undefined,
  collectionItem: CollectionItem | null | undefined,
) => {
  const dispatch = useDispatch();
  // Local copy of isPurchased so that it updates immediately on completion of the adding to purchased async action:
  const [isPurchased, setIsPurchased] = useState(false); // immediate update state set.
  // CUSTOM HOOKS:
  const [setPurchasedWorking, unsetPurchasedWorking, purchasedWorkingValue] =
    useWorking(productDataStoreAs + '__purchased');
  const setFooterPopup = useSetFooterPopup();
  // SELECTORS:
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const isPurchasedDB = useSelector<RootState, boolean>((state) =>
    state.db.single_collection_product_is_purchased.map[productDataStoreAs]
      ?.length > 0
      ? true
      : false,
  );
  // FUNCTIONS:
  useEffect(() => {
    setIsPurchased(isPurchasedDB);
  }, [isPurchasedDB, setIsPurchased]);

  const { firebaseMVP22: firebase, analytics } = useContext(ServicesContext);
  const addOrRemoveProductToPurchased = (opinion: boolean) => {
    // Ensure input data is used in promise .thens not later defined properties...:
    if (
      purchasedWorkingValue === undefined &&
      productData &&
      authUserUID !== null
    ) {
      setPurchasedWorking(true);
      update_product_opinion(
        opinion,
        'purchased',
        collectionItem,
        productData.price,
        null, // no membership info known so will need to lookup in function
        firebase,
        authUserUID,
      )
        .then(() => {
          unsetPurchasedWorking();
          if (opinion === true) {
            setFooterPopup({
              type: 'added_to_purchased',
              uid: productDataStoreAs + Date.now(),
            });
            dispatch<AutoTriggerReviewRequests>({
              type: ActionNames.UI_AUTO_TRIGGER_REVIEW_REQUEST,
              payload: undefined,
            });
          }
          setIsPurchased(opinion);
        })
        .catch(unsetPurchasedWorking);
      // Record event:
      analytics.recordEvent('WebApp:ProductTilePurchasedButton:Pressed');
    }
  };

  return { isPurchased, addOrRemoveProductToPurchased, purchasedWorkingValue };
};
