import { useContext, useState, useCallback } from 'react';
import { ServicesContext } from 'src/ServicesContext';

export interface UseMarkAsBoughtInput {
  collectionUserUID: string;
  collectionUID: string;
  collectionItemUID: string;
  onSuccess?: () => void;
  onAlreadyBoughtError?: () => void;
}

export interface UseMarkAsBoughtOutput {
  working: boolean;
  errorMessage: string;
  mark: (quantity: number, name?: string) => void;
}

export function useMarkAsBought({
  collectionUserUID,
  collectionUID,
  collectionItemUID,
  onSuccess,
  onAlreadyBoughtError,
}: UseMarkAsBoughtInput): UseMarkAsBoughtOutput {
  const { cloud, analytics } = useContext(ServicesContext);

  // Marks a collection item as bought by the logged-in user.
  const [working, setWorking] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const mark = useCallback<
    UseMarkAsBoughtOutput['mark']
  >(
    async (quantity, name) => {
      try {
        setWorking(true);
        await cloud.fastAPI2('markCollectionItemAsBought', {
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
          collection_item_uid: collectionItemUID,
          ...(quantity && { quantity }),
          ...(name && {
            identity: {
              identifier: analytics.clientIdentifier,
              name,
            },
          }),
        });
        setErrorMessage('');
        onSuccess?.();
      } catch (error) {
        const typedError = error as Error;
        setErrorMessage(typedError.message);
        if (typedError.message.startsWith('Already bought')) {
          onAlreadyBoughtError?.();
        }
      } finally {
        setWorking(false);
      }
    },
    [
      cloud,
      analytics.clientIdentifier,
      collectionUserUID,
      collectionUID,
      collectionItemUID,
      onSuccess,
      onAlreadyBoughtError,
    ],
  );

  return {
    working,
    errorMessage,
    mark,
  };
}
