/*
  Button controlling the entry into the "Go to item" (on retailer) user flow.
*/

import { StandardButton } from '@moonsifttech/design-system';
import { useItem } from 'src/hooks/useItem';
import { useMine } from 'src/hooks/useMine';
import { useModal, ModalTypes } from 'src/hooks/useModal';

const getCopy = ({
  domain,
  boughtByUser,
  exhausted,
  inProductModal,
}: {
  domain?: string;
  boughtByUser: boolean;
  exhausted: boolean;
  inProductModal: boolean;
}) => {
  if (domain && inProductModal) {
    if (boughtByUser) {
      return `View again on ${domain}`;
    }
    if (exhausted) {
      return `View on ${domain}`;
    }
    return `Buy on ${domain}`;
  } else {
    return 'Go to item';
  }
};

export const GoToItemButton = ({
  className,
  style,
  collectionId,
  collectionUserId,
  itemId,
  variant,
  domain,
  inProductModal = false,
}: {
  className?: string;
  style?: React.CSSProperties;
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  variant: 'primary-white' | 'secondary';
  domain?: string;
  inProductModal?: boolean;
}) => {

  const {
    boughtByUser,
    exhausted
  } = useItem(itemId);
  const { isMine } = useMine(collectionUserId);
  const [ setModal ] = useModal();

  let modalType: ModalTypes.MarkedAsBoughtSlim | ModalTypes.GoToItem;
  if (
    (!isMine && boughtByUser)
    || (isMine && exhausted)
  ) {
    modalType = ModalTypes.MarkedAsBoughtSlim;
  } else {
    modalType = ModalTypes.GoToItem;
  }

  return (
    <StandardButton
      className={className}
      style={style}
      variant={variant}
      fullWidth={inProductModal}
      onClick={() => setModal({
        type: modalType,
        collectionId,
        collectionUserId,
        itemId,
      })}
    >
      {getCopy({ domain, boughtByUser, exhausted, inProductModal })}
    </StandardButton>
  );
};
