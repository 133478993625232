import React from 'react';
import { Fab, MoreTwoToneIcon } from '@moonsifttech/design-system';

import { ModalMenu } from 'src/components/core/ModalMenu';
import { MoreCollectionOptionsMenu } from 'src/components/collection/MoreCollectionOptionsMenu';
import { MoreCollectionOptionsButtonProps } from './MoreCollectionOptionsButton.types';

export const MoreCollectionOptionsButton: React.FC<
  MoreCollectionOptionsButtonProps
> = ({
  className,
  style,
  isMenuOpen,
  onClick,
  onClose,
  onShare,
  onEdit,
  onNotifications,
}) => {
  return (
    <ModalMenu
      isOpen={isMenuOpen}
      onRequestClose={onClose}
      menu={
        <MoreCollectionOptionsMenu
          onShare={onShare}
          onEdit={onEdit}
          onNotifications={onNotifications}
        />
      }
    >
      <Fab className={className} style={style} size={40} onClick={onClick}>
        <MoreTwoToneIcon secondaryColor="grey.5" size="larger" />
      </Fab>
    </ModalMenu>
  );
};
