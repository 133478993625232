import { CollectionType } from 'src/types/models/collection.model';

const mapOptionKeyToPublicLevel: Record<CollectionType, number> = {
  public: 10,
  private: 7,
  hidden: 0,
};

export const getPublicLevel = (
  optionKey: CollectionType,
  isRegistry: boolean,
) => {
  if (optionKey === 'private') {
    return isRegistry ? 8 : 7;
  }
  return mapOptionKeyToPublicLevel[optionKey];
};
