import { VFC, useState, useCallback } from 'react';

import { PostinstallThreeContainerProps } from './PostinstallThree.types';
import { PostinstallThree } from './PostinstallThree.component';

const PostinstallThreeContainer: VFC<PostinstallThreeContainerProps> = (
  props,
) => {
  const [isCreateCollectionModalOpen, setIsCreateCollectionModalOpen] =
    useState(false);
  const handleCreateCollection = useCallback(() => {
    setIsCreateCollectionModalOpen(true);
  }, []);
  const handleCreateCollectionModalClose = useCallback(() => {
    setIsCreateCollectionModalOpen(false);
  }, []);

  return (
    <PostinstallThree
      isCreateCollectionModalOpen={isCreateCollectionModalOpen}
      onCreateCollection={handleCreateCollection}
      onCreateCollectionModalClose={handleCreateCollectionModalClose}
      {...props}
    />
  );
};

export { PostinstallThreeContainer as PostinstallThree };
