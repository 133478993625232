// This file contains links to the media:
/*

We will from now on just reference the media files directly thus making this file redundant.

Call preURL from a constant though!

*/
const PREURL = process.env.REACT_APP_STATIC_URL;
const MEDIA = {
  SIGNUPLOGO : PREURL+"/logos/SignUpLogo.svg",
  MENUARROW : PREURL+"/menubar/menuarrow.svg",
  DESIGNSYSTEM : PREURL+"/designsystem/",
  LOGO_DEFAULT : PREURL+"/logos/logo.svg",
  LOGO_BW : PREURL+"/logos/logo_bw.svg",
  LOGO_DS : PREURL+"/logos/logo_dropshadow.svg",
  LOGO_BW_DS : PREURL+"/logos/logo_bw_dropshadow.svg",
  LOGO_IS : PREURL+"/logos/logo_internal_shadow.svg",
  LOGO_DARK : PREURL+"/logos/logo_on_dark.svg",
  LOGO_DARK_BW : PREURL+"/logos/logo_on_dark_bw.svg",
  FACEBOOK_DARK : PREURL+"/menubar/facebook_white.svg",
  INSTAGRAM_DARK : PREURL+"/menubar/instagram_white.svg",
  INSTAGRAM : PREURL+"/logos/instagramLogo.svg",
  MEDIUM_DARK : PREURL+"/menubar/medium_white.svg",
  LINKEDIN_DARK : PREURL+"/menubar/linkedin_white.svg",
  TASTEMAKERS_TOP:PREURL+"/landing/tastemakers_top.png",
  TASTEMAKERS_MIDDLE:PREURL+"/landing/tastemakers_middle.png",
  TASTEMAKERS_BOTTOM:PREURL+"/landing/tastemakers_bottomhehe.png",
  TASTEMAKERS_BRANDS:[
    PREURL+"/landing/brand_trouva.png",
    PREURL+"/landing/brand_and_other_stories.png",
    PREURL+"/landing/brand_zara.png",
    PREURL+"/landing/brand_net_a_porter.png",
    PREURL+"/landing/brand_asos.png",
    PREURL+"/landing/brand_urban_outfitters.png",
    PREURL+"/landing/brand_anthropologie.png",
    PREURL+"/landing/brand_cos.png",
  ],
  SMALL_BIN : PREURL+"/menubar/small_bin.svg",
  THIN_BIN : PREURL+"/menubar/thin_bin.svg",
  HAMBURGER: PREURL+"/menubar/hamburger.svg",
  HAMBURGER_YELLOW: PREURL+"/menubar/hamburger_yellow.svg",
  EXIT_CROSS: PREURL+"/menubar/exit_cross.svg",
  EXIT_CROSS_WHITE: PREURL+"/menubar/exit_cross_white.svg",
  EXIT_CROSS_YELLOW: PREURL+"/menubar/exit_cross_yellow.svg",
  OPEN_RETAILER : PREURL+"/menubar/open_retailer.svg",
  POST_INSTALL_LEFT : PREURL+"/postinstall/left_intro.png",
  EXIT_CROSS_THIN : PREURL+"/menubar/exit_cross_thin.svg",
  FORWARD_ARROW : PREURL+"/menubar/forward_arrow.svg",
  BACK_ARROW : PREURL+"/menubar/back_arrow.svg",
  NEW_PLUS: PREURL+"/producttile/new_plus.svg",
  BLACK_PLUS : PREURL+"/menubar/the_best_thing_about_switzerland_the_flag_is_a_big.svg",
  BLACK_PLUS_CIRCLE: PREURL+"/menubar/sideways_plus_circle.svg",
  BLACK_TICK_CIRCLE: PREURL+"/menubar/sideways_tick_circle.svg",
  WHITE_PLUS : PREURL+"/menubar/the_best_thing_about_switzerland_the_flag_is_a_big_white.svg",
  SIDEWAYS_BURGER : PREURL+"/menubar/sideways_burger.svg",
  SIDEWAYS_BURGER_BLACK : PREURL+"/menubar/sideways_burger_black.svg",
  EDIT : PREURL+"/menubar/edit.svg",
  WORKINGCIRCLE_A: PREURL+"/menubar/circle_dots_0.svg",
  WORKINGCIRCLE_B: PREURL+"/menubar/circle_dots_1.svg",
  WORKINGCIRCLE_C: PREURL+"/menubar/circle_dots_2.svg",
  FEEDBACK: PREURL+"/menubar/feedback.svg",
  EXTENSIONBARPOINTER: PREURL+"/postinstall/pointsAtChromeExtension.svg",
  EMPTY : PREURL+"/home/empty.svg",
  ABOUT_US_COMMUNITYPICTURES : PREURL+"/about/Community-pictures.png",
  ABOUT_US_CONSUMERDATA : PREURL+"/about/Consumer-data.png",
  ABOUT_US_PRODUCTVISIONIMAGES : PREURL+"/about/Product-vision-images.png",
  ALEX_DAVID_SIGNATURE : PREURL+"/about/Alex-David-signature.svg",
  LANDING_IMG_1: PREURL+"/landing/Landing_img_1b.png",
  LANDING_IMG_1_SMALL: PREURL+"/landing/Landing_img_1_small.png",
  LANDING_IMG_3: PREURL+"/landing/Landing_img_3.svg",
  LANDING_VIDEO_1: PREURL+"/landing/Saving_product_video_loop.mov",
  LANDING_VIDEO_1_FASTER: PREURL+"/landing/Saving_product_video_loop_faster.mov",
  LANDING_VIDEO_2: PREURL+"/landing/Collections_video_loop.mov",
  LANDING_IMG_1_AUG_2020: PREURL+"/landing/landingImageAugust2020.png",
  LANDING_IMG_1_AUG_2020_SMALL: PREURL+"/landing/landingImageAugust2020_small.png",
  LANDING_IMG_1_MAR_2022: PREURL+"/landing/landingImageMarch2022.png",
  LANDING_IMG_CANDLES: PREURL + "/landing/candles.png",
  LANDING_IMG_HANDBAG: PREURL + "/landing/handbag.png",
  LANDING_IMG_EARRINGS: PREURL + "/landing/earrings.png",
  LANDING_IMG_PILLOW: PREURL + "/landing/pillow.png",
  LANDING_IMG_CUPS: PREURL + "/landing/cups.png",
  LANDING_IMG_JUMPER: PREURL + "/landing/jumper.png",
  LANDING_IMG_HEADPHONES: PREURL + "/landing/headphones.png",
  LANDING_IMG_SMARTWATCH: PREURL + "/landing/smartwatch.png",
  LANDING_IMG_COFFEE_MAKER: PREURL + "/landing/coffee-maker.png",
  LANDING_IMG_SNAKE_CANDLES_SMALL: PREURL + "/landing/snake-candles-small.png",
  LANDING_IMG_CANDLES_SMALL: PREURL + "/landing/candles-small.png",
  LANDING_IMG_HANDBAG_SMALL: PREURL + "/landing/handbag-small.png",
  LANDING_IMG_EARRINGS_SMALL: PREURL + "/landing/earrings-small.png",
  LANDING_IMG_PILLOW_SMALL: PREURL + "/landing/pillow-small.png",
  LANDING_IMG_CUPS_SMALL: PREURL + "/landing/cups-small.png",
  LANDING_IMG_JUMPER_SMALL: PREURL + "/landing/jumper-small.png",
  LANDING_IMG_HEADPHONES_SMALL: PREURL + "/landing/headphones-small.png",
  LANDING_IMG_SMARTWATCH_SMALL: PREURL + "/landing/smartwatch-small.png",
  LANDING_IMG_COFFEE_MAKER_SMALL: PREURL + "/landing/coffee-maker-small.png",
  MOONSIFT_ON_COMPUTER_IMG: PREURL+"/landing/moonsiftOnDesktopComputer.png",
  CHROME_ICON: PREURL+"/landing/chrome-icon.svg",
  GIFT_OUTLINE: PREURL + "/landing/gift-outline.svg",
  GLOBE_OUTLINE: PREURL + "/landing/globe-outline.svg",
  PEOPLE_OUTLINE: PREURL + "/landing/people-outline.svg",
  WALLET_OUTLINE: PREURL + "/landing/wallet-outline.svg",
  WHITEHEART: PREURL+"/explore/whiteHeart.svg",
  BLACKHEART: PREURL+"/explore/blackHeart.svg",
  WHITE_BOOKMARK: PREURL+"/explore/whiteBookmark.svg",
  BLACK_BOOKMARK: PREURL+"/explore/blackBookmark.svg",
  BLACK_SHARE_ICON: PREURL+"/collection/blackShareIcon.svg",
  WHITE_SHARE_ICON: PREURL+"/collection/whiteShareIcon.svg",
  LOCKICON: PREURL+"/explore/lockIcon.svg",
  LOCKICON_BLACK: PREURL+"/explore/lockIconBlack.svg",
  TICKBOXEMPTY: PREURL+"/explore/empty_box.svg",
  TICKBOXFULL: PREURL+"/explore/ticked_box.svg",
  RECENCY_GREEN: PREURL+"/menubar/recency_green.svg",
  RECENCY_ORANGE: PREURL+"/menubar/recency_orange.svg",
  RECENCY_RED: PREURL+"/menubar/recency_red.svg",
  RECENCY_GREY: PREURL+"/menubar/recency_grey.svg",
  PRICE_DOWN: PREURL+"/menubar/down_green.svg",
  PRICE_UP: PREURL+"/menubar/up_grey.svg",
  ORGANISEICON: PREURL+'/collection/organiseIcon.svg',
  ORGANISEICON_INVERTED: PREURL+'/collection/organiseIconInverted.svg',
  TO_TOP: PREURL+'/collection/toTop.svg',
  TO_BOTTOM: PREURL+'/collection/toBottom.svg',
  LEFT: PREURL+'/collection/left.svg',
  RIGHT: PREURL+'/collection/right.svg',
  CAROUSEL_ARROW_RIGHT: PREURL+"/landing/carouselArrowRight.svg",
  UNDO_ICON: PREURL+"/popups/undoIcon.svg",
  COAT_HANGER: PREURL+"/home/coatHanger.svg",
  COAT_HANGER_PLUS: PREURL+"/producttile/coatHangerPlus.svg",
  COAT_HANGER_TICK: PREURL+"/producttile/coatHangerTick.svg",
  TICK: PREURL+"/popups/tickIcon.svg",
  UP_ARROW: PREURL+"/producttile/upArrow.svg",
  DOWN_ARROW: PREURL+"/producttile/downArrow.svg",
  BOX_TICKED: PREURL+"/producttile/box_ticked.svg",
  BOX_UNTICKED: PREURL+"/producttile/box_empty.svg",
  RETAILER_LOGOS: PREURL+"/collection/retailerLogos.svg",
  CAROUSEL_ARROW_UP: PREURL+'/popups/carouselArrowUp.svg',
  RIGHT_ARROW_SMALL: PREURL+'/collection/rightArrowSmall.svg',
  GENERIC_ARROW: PREURL+'/popups/genericArrow.svg',
  GENERIC_ARROW_SMALL: PREURL+'/popups/genericArrowSmall.svg',
  CROPPED_CAROUSEL_ARROW: PREURL+'/popups/croppedCarouselArrow.svg',
  PRODUCT_IMAGE_NOT_FOUND: PREURL+'/producttile/missing_image.png',
  BACKEND_SCRAPING_IN_PROGRESS: PREURL+'/producttile/bgscrape_processing.gif',
  SHARE_LEVEL_0: PREURL+'/collection/share_lock.svg',
  SHARE_LEVEL_7: PREURL+'/collection/share_private_shareable.svg',
  SHARE_LEVEL_10: PREURL+'/collection/share_world.svg',
  SHARE_TICK: PREURL+'/collection/share_tick.svg',
  SHARE_DOWN_ARROW: PREURL+'/collection/share_down_arrow.svg',
  STYLISTS_IMG_1: PREURL+'/stylists/stylists1.png',
  STYLISTS_IMG_2: PREURL+'/stylists/stylists2.png',
  STYLISTS_IMG_3: PREURL+'/stylists/stylists3.png',
  PRO_BADGE: PREURL+'/pro/proBadge.svg',
  GREY_MAIL: PREURL+'/pro/greyMail.svg',
  SEARCH_ICON: PREURL+'/retailers/searchIcon.svg',
  FACEBOOK_SIGNUP_LOGO: PREURL+'/logos/facebookSignupLogo.svg',
  GOOGLE_SIGNUP_LOGO: PREURL+'/logos/googleSignupLogo.svg',
  COG: PREURL+'/menubar/cog.svg',
  BELL: PREURL+'/menubar/bell.svg',
  NOTIFICATION_TAG_COMMENT: PREURL+'/notifications/tag_comment.svg',
  NOTIFICATION_TAG_PRICE_DROP: PREURL+'/notifications/tag_price_drop.svg',
  NOTIFICATION_TAG_SETTINGS: PREURL+'/notifications/tag_settings.svg',
  NOTIFICATION_TAG_UPVOTE: PREURL+'/notifications/tag_upvote.svg',
  NOTIFICATION_TAG_DOWNVOTE: PREURL+'/notifications/tag_downvote.svg',
  PRICE_DROPS_AND_COMMENTS_POPUP: PREURL+'/popups/price_drops_and_comments.svg',
  NO_BOOKMARKED_COLLECTIONS: PREURL+'/collection/noBookmarkedCollections.svg',
  NO_PUBLIC_COLLECTIONS: PREURL+'/collection/noPublicCollections.svg',
  NO_PURCHASED_ITEMS: PREURL+'/collection/noPurchasedItems.svg',
  COLLECTION_EMPTY: PREURL+'/collection/collectionEmpty.svg',
  VIDEO_ICON: PREURL+'/popups/videoIcon.svg',
  EYE_ICON: PREURL+'/collection/eyeIcon.svg',
  INFO_ICON: PREURL+'/popups/infoIcon.svg',
  RETAILERS:[
    {
      name: 'Asos',
      image: PREURL+"/landing/brand_asos.png",
      link: "https://www.asos.com/",
      style: { width: '64%' }
    },
    {
      name: 'Selfridges',
      image: PREURL + "/landing/brand_selfridges.svg",
      link: "https://www.selfridges.com/",
      style: { width: '76%' }
    },
    {
      name: 'And Other Stories',
      image: PREURL + "/landing/brand_and_other_stories.png",
      link: "https://www.stories.com/",
      style: { width: '85%' }
    },
    {
      name: 'Trouva',
      image: PREURL + "/landing/brand_trouva.png",
      link: "https://www.trouva.com/",
      style: { width: '72%' }
    },
    {
      name: 'Zalando',
      image: PREURL + "/landing/brand_zalando.png",
      link: "https://www.zalando.co.uk/",
      style: { width: '82%' }
    },
    {
      name: 'The Outnet',
      image: PREURL + "/landing/brand_the_outnet.svg",
      link: "https://www.theoutnet.com",
      style: { width: '86%' }
    }
  ],
  BUTTON_DOWNVOTE: PREURL+'/comments_and_reactions/button_downvote.svg',
  BUTTON_DOWNVOTE_SELECTED: PREURL+'/comments_and_reactions/button_downvote_selected.svg',
  BUTTON_UPVOTE: PREURL+'/comments_and_reactions/button_upvote.svg',
  BUTTON_UPVOTE_SELECTED: PREURL+'/comments_and_reactions/button_upvote_selected.svg',
  REPORT_COMMENTS: PREURL+'/comments_and_reactions/report_comments.svg',
  REPORT_DOWNVOTES: PREURL+'/comments_and_reactions/report_downvotes.svg',
  REPORT_UPVOTES: PREURL+'/comments_and_reactions/report_upvotes.svg',
  REVIEW_SPEECH_BUBBLE: PREURL+'/stylists/reviewSpeechBubble.svg',
  RATING_STAR: PREURL+'/stylists/ratingStar.svg',
  WAVING_EMOJI: PREURL+'/popups/wavingEmoji.png',
  FIVE_STARS: PREURL+'/popups/fiveStars.svg',
  REVIEWERS: {
    LYNN: PREURL+'/stylists/reviewers/lynn.png',
    KAREN: PREURL+'/stylists/reviewers/karen.png',
    CLAIRE: PREURL+'/stylists/reviewers/claire.png',
    DANIELLE: PREURL+'/stylists/reviewers/danielle.png',
    NICOLE: PREURL+'/stylists/reviewers/nicole.png',
    SIAN: PREURL+'/stylists/reviewers/sian.png',
    ABBEY: PREURL+'/stylists/reviewers/abbey.png',
    LINDSAY: PREURL+'/stylists/reviewers/lindsay.png',
    HILLARY: PREURL+'/stylists/non-stylists/hillary.png',
    MARIA: PREURL+'/stylists/non-stylists/maria.png',
    SHORE: PREURL+'/stylists/non-stylists/shore.png',
    TIA: PREURL+'/stylists/non-stylists/tia.png',
    SHOPE: PREURL+'/stylists/non-stylists/shope.png'
  }
};
export default MEDIA;
