import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
//import { Query } from 'src/services/DB';
import { collectionItemParser } from 'src/store/parsers/collectionItem';
import { scrapeParser } from 'src/store/parsers/scrape';
import { useDBRetrieveQuery } from 'src/hooks/useDBRetrieveQuery';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { reactionParser } from 'src/store/parsers/reaction';
import { ActionNames, ProductData, ProductDataAction } from 'src/types';
import { get_image_full_url } from 'src/mvp22/core-components/image_url';
import { order_image_list } from 'src/mvp22/core-components/order_image_list';
import { price_in_past } from 'src/mvp22/core-components/date_time_functions';
import { get_best_price_rep } from 'src/mvp22/core-components/price_functions';
import { productParser } from 'src/store/parsers/product';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { PseudoProductElementContainerProps } from '.';

// TEMP fix until safari supports webp....(coming in 14)
function webpToJPG(addr_in: string) {
  if (!addr_in) {
    return addr_in;
  }
  return addr_in
    .replace('fmt=webp', 'fmt=jpeg')
    .replace('value%5Bwebp%5D&', 'value%5Bjpeg%5D&');
}

export const PseudoProductElement: React.FC<
  PseudoProductElementContainerProps
> = ({ collectionUID, collectionUserUID, collectionItem }) => {
  const scrapeUserUID = collectionItem.scrape_user_uid ?? collectionUserUID; // should not be needed due to parser!
  const scrapeUID = collectionItem.scrape_uid;
  const pageURL = collectionItem.page_url;
  const itemUID = collectionItem.id;
  const itemStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    itemUID,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserUIDNotNull = authUserUID ?? '';

  const { db: dB } = useContext(ServicesContext);
  // Fetch the scrapes and other associated queries for the products:
  useDBRetrieveDoc<'single_collection_scrape'>(
    dB,
    dB.userScrape,
    'single_collection_scrape',
    scrapeParser,
    itemStoreAs,
    [scrapeUserUID, scrapeUID, collectionItem.bg_processing ? 'TRUE' : 'FALSE'],
    'get',
  );
  const scrapeData = useSelector(
    (state: RootState) => state.db.single_collection_scrape.map[itemStoreAs],
  );
  // use the below to store the latest product data in redux for access by a range of items later!
  useDBRetrieveQuery<'single_collection_product_is_saved'>(
    dB,
    dB.itemIsSaved,
    'single_collection_product_is_saved',
    collectionItemParser,
    itemStoreAs,
    [authUserUIDNotNull, pageURL],
    'listen',
  );
  useDBRetrieveQuery<'single_collection_product_is_purchased'>(
    dB,
    dB.itemIsPurchased,
    'single_collection_product_is_purchased',
    collectionItemParser,
    itemStoreAs,
    [authUserUIDNotNull, pageURL],
    'listen',
  );
  useDBRetrieveQuery<'single_collection_product_is_liked_or_disliked'>(
    dB,
    dB.itemIsLikedOrDisliked,
    'single_collection_product_is_liked_or_disliked',
    reactionParser,
    itemStoreAs,
    [collectionUserUID, collectionUID, authUserUIDNotNull, itemUID],
    'listen',
  );
  // Fetch the global data for the products:
  const productUID = collectionItem ? collectionItem.product_uid : null;
  useDBRetrieveDoc<'single_collection_product'>(
    dB,
    dB.product,
    'single_collection_product',
    productParser,
    itemStoreAs,
    [productUID],
    'get',
  );
  // use the above to store the latest product data in redux for access by a range of items later!
  const productData = useSelector(
    (state: RootState) => state.db.single_collection_product.map[itemStoreAs],
  );
  // We will store the products in redux so we can access it more easily later around the app with fewer renders hopefully...?
  // (alternative could have been to use a context...)
  const dispatch = useDispatch();
  useEffect(() => {
    // fetch the collection info if the collection uid or the collection user uid changes and if the auth user data is loaded:
    if (scrapeData === null) {
      // could do something here to report as not found...
    } else if (scrapeData !== undefined) {
      // In this first part imagine we don't have any live product data and we just use the scrapes:
      const image = get_image_full_url(
        collectionItem.product_image ??
          webpToJPG(scrapeData.best_image.sources[0]),
      );
      // require check on sources being present due to some malformed data at some point...:
      const extraImageList =
        scrapeData.image_list.length > 1
          ? scrapeData.image_list
              .filter((x) => x.sources.length > 0)
              .map((x) => get_image_full_url(webpToJPG(x.sources[0])))
          : [];
      const firstImageArray = image !== undefined ? [image] : [];
      const imageList = order_image_list(
        firstImageArray,
        extraImageList,
        collectionItem.original_image,
      ) as string[];
      const lastUpdated = scrapeData.created_at
        ? (price_in_past(
            dB.serverTimestamp().seconds,
            scrapeData.created_at.seconds,
          ) as [x: string, y: string])
        : undefined;
      // use price from saved time if present (case user saves product from another collection and this product has a price that is updated from the scrape price).
      const savedPrice = collectionItem.saved_price ?? scrapeData.best_price;
      const updatePrice =
        scrapeData.manual_addition !== true ||
        scrapeData.can_price_update === true;
      var price = savedPrice;
      var description = scrapeData.best_description;
      var url = scrapeData.best_link;
      var commissionType = scrapeData.commission_type;
      var pageURL = collectionItem.page_url;
      const isBGProcessing = collectionItem.bg_processing;
      const deletedImages = scrapeData.deleted_images ?? [];
      const quantity = collectionItem.quantity;
      const productDataOut: ProductData = {
        price,
        //currency // maybe add this in future?
        savedPrice, // price when saved to collection.
        description,
        url,
        image,
        imageList,
        lastUpdated,
        pageURL,
        isBGProcessing,
        deletedImages,
        commissionType,
        quantity: quantity ?? 1,
      };
      // Add in latest product data:
      if (productData !== null && productData !== undefined) {
        // Calculate the updated price as this is the only info the user really cares about
        if (updatePrice) {
          const priceInfo = get_best_price_rep(price, productData);
          productDataOut.priceChange = priceInfo.price_change;
          productDataOut.originalPrice = productDataOut.price;
          productDataOut.price = priceInfo.updated_price;
          productDataOut.lastUpdated = price_in_past(
            dB.serverTimestamp().seconds,
            productData.best_last_updated.seconds,
          ) as [x: string, y: string];
        }
        // We may also be able to add some more images:
        if (productData.images && deletedImages.length === 0) {
          let curLargestSet: string[] = [];
          (['main', 'tile'] as const).forEach((imageTypeKey) => {
            const imageSet = productData.images[imageTypeKey]?.set;
            if (imageSet && imageSet.length > curLargestSet.length) {
              curLargestSet = imageSet;
            }
          });
          const extraProductImageList =
            curLargestSet.length > 1
              ? curLargestSet.map((x) => get_image_full_url(webpToJPG(x)))
              : [];
          const firstImageArray = image !== undefined ? [image] : [];
          const productImageList = order_image_list(
            firstImageArray,
            extraProductImageList,
            collectionItem.original_image,
          );
          // take the list that has the most images!
          if (!(imageList && imageList.length >= productImageList.length)) {
            productDataOut.imageList = productImageList;
          }
        }
      }
      // Set the data:to redux store:
      dispatch<ProductDataAction>({
        type: ActionNames.PRODUCT_DATA_ADD,
        payload: {
          storeAs: itemStoreAs,
          productData: productDataOut,
        },
      });
    }
    return () => {
      // Remove the old data to keep the store tidy:
      dispatch<ProductDataAction>({
        type: ActionNames.PRODUCT_DATA_REMOVE,
        payload: itemStoreAs,
      });
    };
  }, [
    dB,
    scrapeData,
    productData,
    dispatch,
    collectionItem.product_image,
    collectionItem.original_image,
    collectionItem.saved_price,
    collectionItem.bg_processing,
    collectionItem.quantity,
    itemStoreAs,
    collectionItem.page_url,
  ]);
  // pseudo, so no rendering!
  return <></>;
};
