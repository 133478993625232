export type Message<T, P> = P extends Object
  ? {
      type: T;
      payload: P;
    }
  : {
      type: T;
    };

export enum OutgoingMessageType {
  // App notifications
  EnableNotifications = 'ENABLE_NOTIFICATIONS',
  CheckNotifications = 'CHECK_NOTIFICATIONS',
  // App auth
  Logout = 'LOGOUT',
  // App navigation
  Navigate = 'NAVIGATE',
  // Reviews:
  CheckIsActive = 'CHECK_IS_ACTIVE',
  RequestInAppReview = 'IN_APP_REVIEW',
  //
  Ready = 'READY',
}

export enum IncomingMessageType {
  NotificationsEnabled = 'NOTIFICATIONS_ENABLED',
  NotificationsDisabled = 'NOTIFICATIONS_DISABLED',
  IsActive = 'IS_ACTIVE',
}

export type IsActiveIncomingMessage = Message<
  IncomingMessageType.IsActive,
  undefined
>;

export type NotificationsEnabledIncomingMessage = Message<
  IncomingMessageType.NotificationsEnabled,
  undefined
>;

export type NotificationsDisabledIncomingMessage = Message<
  IncomingMessageType.NotificationsDisabled,
  undefined
>;
