/*
  Component controlling the button group shown under product tiles in listing view and inside the ProductModal.
*/

import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  Stack,
  StandardButton,
  CheckCircleIcon,
  standardButtonClassNames,
} from '@moonsifttech/design-system';
import { useItem } from 'src/hooks/useItem';
import { useMine } from 'src/hooks/useMine';
import { useModal, ModalTypes } from 'src/hooks/useModal';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import get_site_uid from 'src/mvp22/core-components/get_site_uid';
import { RootState } from 'src/index';
import { GoToItemButton } from 'src/components/collection/GoToItemButton';
import { MarkAsBoughtButton } from 'src/components/collection/MarkAsBoughtButton';
import { ProductToProductButton } from 'src/components/collection/ProductToProductButton';
import { ProductData } from 'src/types';

export const UnstyledGiftListButtons = ({
  collectionId,
  collectionUserId,
  itemId,
  inProductModal = false,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  inProductModal?: boolean;
  className: string;
}) => {
  const { isMine } = useMine(collectionUserId);
  const [ setModal ] = useModal();
  const {
    boughtByUser,
    someBought,
    exhausted,
    ownerOnlyPurchase,
  } = useItem(itemId);

  const productKey = getCollectionItemStoreAs(
    collectionId,
    collectionUserId,
    itemId,
  );
  const product = useSelector<RootState, ProductData | undefined>((state) =>
    state.productData?.[productKey] ?? undefined
  );
  const isRevealing = useSelector<RootState, boolean>((state) => {
    return state.collectionRegistryUi.isRevealing;
  });
  const canViewAI = useSelector<RootState, boolean>(
    (state) => state.db.user_verified_settings.data?.can_view_ai === true,
  );

  const domain = get_site_uid(product?.pageURL ?? '');

  return (
    <Stack className={className}>
      {(
        isMine && isRevealing && someBought && (
          <StandardButton
            variant="primary-black"
            startIcon={<CheckCircleIcon />}
            onClick={() => setModal({
              type: ownerOnlyPurchase
                ? ModalTypes.MarkedAsBoughtSlimOwner
                : ModalTypes.BoughtBy,
              collectionId,
              collectionUserId,
              itemId
            })}
          >
            Bought by...
          </StandardButton>
        )
      )}
      {(
        inProductModal
        || (!isMine && (boughtByUser || !exhausted))
        || (isMine && isRevealing && !exhausted))
        && (
          <GoToItemButton
            collectionId={collectionId}
            collectionUserId={collectionUserId}
            itemId={itemId}
            variant={inProductModal ? 'primary-white' : 'secondary'}
            domain={domain}
            inProductModal={inProductModal}
          />
      )}
      {(
        !isMine
        || (isMine && isRevealing && !exhausted))
        && (
          <MarkAsBoughtButton
            collectionId={collectionId}
            collectionUserId={collectionUserId}
            itemId={itemId}
            variant={inProductModal ? 'primary-white' : 'secondary'}
          />
        )
      }
      {(
        isMine && !isRevealing && inProductModal && canViewAI
        && (
          <ProductToProductButton
            variant="primary"
            product={{
              url: product?.url ?? '',
              siteUID: domain,
              price: product?.price ?? '',
              imageURL: product?.imageList[0] ?? '',
              description: product?.description ?? '',
            }}
          />
        )
      )}
    </Stack>
  );
};

export const GiftListButtons = styled(UnstyledGiftListButtons)`
  width: ${(props => props.inProductModal ? '100%' : 'auto')};
  margin-bottom: 32px;  

  & .${standardButtonClassNames.root}:not(:last-child) {
    margin-bottom: 8px;
  }
`;

