import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { CollectionCarouselProps } from './CollectionCarousel';
import MEDIA from 'src/mvp22/media';
import { CollectionCarouselTile } from 'src/components/common/CollectionCarouselTile';

const UnstyledCollectionCarousel: VFC<CollectionCarouselProps> = ({
  className,
  style,
  collectionList,
  scrollContainerRight,
  scrollContainerLeft,
  showForwardButton,
  showBackButton,
  containerRef,
  handleDataLoad,
}) => {
  return (
    <div className={className}>
      <div className="CollectionCarousel-container">
        <button
          onClick={scrollContainerRight}
          className={clsx(
            'CollectionCarousel-arrowButton',
            'CollectionCarousel-rightArrowButton',
            showForwardButton && 'CollectionCarousel-arrowButtonVisible',
          )}
        >
          <img src={MEDIA.CAROUSEL_ARROW_RIGHT} alt="Forwards arrow" />
        </button>
        <button
          onClick={scrollContainerLeft}
          className={clsx(
            'CollectionCarousel-arrowButton',
            'CollectionCarousel-leftArrowButton',
            showBackButton && 'CollectionCarousel-arrowButtonVisible',
          )}
        >
          <img
            className="CollectionCarousel-leftArrowImage"
            src={MEDIA.CAROUSEL_ARROW_RIGHT}
            alt="Backwards arrow"
          />
        </button>
        <div ref={containerRef} className="CollectionCarousel-slideContainer">
          <div className="CollectionCarousel-innerSlideContainer">
            {collectionList.length > 0
              ? collectionList.map((collectionInfo, index) => {
                  return (
                    <CollectionCarouselTile
                      handleDataLoad={handleDataLoad}
                      collectionUID={collectionInfo.collection_uid}
                      collectionUserUID={collectionInfo.user_uid}
                      index={index}
                      key={index}
                    />
                  );
                })
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export const CollectionCarousel = styled(UnstyledCollectionCarousel)`
  .CollectionCarousel-container {
    display: flex;
    width: 100%;
    justify-content: center;
    position: relative;
    max-width: 990px;
    flex-direction: column;
  }

  .CollectionCarousel-arrowButton {
    visibility: hidden;
    display: block;
    position: absolute;
    color: #111;
    cursor: pointer;
    opacity: 0.75;
    transform: translateY(-50%);
    transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
    z-index: 10;
    &:focus {
      outline: 0;
    }
    &:hover {
      opacity: 0.5;
    }
    &--left {
      left: 32px;
    }
    &--right {
      right: 32px;
    }
    top: 116px;
    background: none;
    border: none;
    padding: 0px;
  }

  .CollectionCarousel-leftArrowButton {
    left: 0px;
  }

  .CollectionCarousel-rightArrowButton {
    right: 0px;
  }

  .CollectionCarousel-rightArrowImage {
  }

  .CollectionCarousel-leftArrowImage {
    transform: scaleX(-1);
  }
  .CollectionCarousel-arrowButtonVisible {
    visibility: visible;
  }

  .CollectionCarousel-slideContainer {
    overflow-x: scroll;
    display: flex;
    justify-content: center;
    ::-webkit-scrollbar {
      display: none;
    }
    scroll-snap-type: x mandatory;
    margin-left: '20px';
    margin-right: '10px';
  }

  .CollectionCarousel-innerSlideContainer {
    display: flex;
    width: 100%;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .CollectionCarousel-slideContainer {
      margin-left: '0px';
      margin-right: '20px';
    }
  }
`;
