import { useCallback, useContext, useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import R from 'src/routes';
import { RootState } from 'src/index';
import { ProTrialContainerProps } from './ProTrial.types';
import { ProTrial } from './ProTrial.component';
import { ServicesContext } from 'src/ServicesContext';
import { useHistory } from 'react-router-dom';

const ProTrialContainer: VFC<ProTrialContainerProps> = (props) => {
  const isPro = useSelector<RootState, boolean>((state) => {
    return state.firestore_user_owner.pro ? true : false;
  });

  const [workingStartPro, setWorkingStartPro] = useState(false);
  const history = useHistory();

  const { cloud } = useContext(ServicesContext);

  const handleStartProTrial = useCallback(() => {
    setWorkingStartPro(true);
    cloud
      .fastAPI({
        api: 'users_pro_request',
        pro_code: 'trial30days',
      })
      .then((result) => {
        const data = result.data as { success: boolean } | undefined;
        if (data?.success === true) {
          history.push(R.PRO_WELCOME);
        }
        setWorkingStartPro(false);
      })
      .catch((err) => {
        console.error('Failed to upgrade to Pro:', err);
        setWorkingStartPro(false);
      });
  }, [cloud, history]);

  return (
    <ProTrial
      isPro={isPro}
      workingStartPro={workingStartPro}
      onStartProTrial={handleStartProTrial}
      {...props}
    />
  );
};

export { ProTrialContainer as ProTrial };
