import React, { useContext } from 'react';
import {
  Route as ReactRouterRoute,
  Redirect as ReactRouterRedirect,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import R from 'src/routes';
import { authRedirectSearchParam } from 'src/config';
import { authRedirectBlocklist } from 'src/config';
import { RouteProps } from './Route.types';
import Loading from 'src/mvp22/view-components/Loading';
import { ServicesContext } from 'src/ServicesContext';

// Checks whether the provided pathname corresponds to one of the postinstall
// routes (1, 2 or 3)
function checkIfPostinstall(pathname: string): boolean {
  const cleanPathname = pathname.endsWith('/')
    ? pathname.slice(0, -1)
    : pathname;

  return [R.POSTINSTALL_ONE, R.POSTINSTALL_TWO, R.POSTINSTALL_THREE].includes(
    cleanPathname,
  );
}

/**
 * A React Route library's Route wrapper that will take care of redirecting
 * the logged-out users to the login page whenever trying to navigate to a
 * private route.
 *
 * It will set an auth redirect search param that will be used to redirect
 * the user once the user is logged-in and not in a route blocklisted for
 * auth redirections.
 */
export const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  path,
  component,
  ...otherProps
}) => {
  const { mobile } = useContext(ServicesContext);
  const location = useLocation();
  const { pathname, search, hash } = location;

  const authUserUID = useSelector<RootState, string | null>(
    ({ auth }) => auth.id,
  );
  const authUserSet = useSelector<RootState, boolean>(({ auth }) => auth.set);
  const appUserAwaitingLogIn = !authUserUID && mobile.isApp;

  const searchParams = new URLSearchParams(search);

  /**
   * If the route is private but firebase/redux auth data is still loading before we can determine whether
   * the user is logged-in, show a loading page.
   *
   * This means we avoid a flash of signin whilst we wait for firebase to load a user
   * that is already logged in
   */
  if (isPrivate && (!authUserSet || appUserAwaitingLogIn)) {
    return <Loading />;
  }

  /**
   * If the route is private but the user is not authenticated
   * redirect to the sign in page or the extension sign up page with:
   *
   * - a redirect search param set to the current redirect search param
   *   so that the user is redirected to a specific path once logged in,
   *
   * - or a redirect search param set to the current location so that
   *   the user is redirected to the originally intended page.
   */
  if (isPrivate && !authUserUID) {
    const redirectSearchParams = new URLSearchParams();

    if (searchParams.has(authRedirectSearchParam)) {
      // If an auth redirect exists already, use that one.
      // Note that all the search parameters and hash parameters will be
      // lost as the provided redirect should contain any search or hash
      // parameters needed in the target destination after the redirect.
      redirectSearchParams.set(
        authRedirectSearchParam,
        searchParams.get(authRedirectSearchParam)!,
      );
    } else {
      // Otherwise, just encode the current pathname, search parameters
      // and hash parameters in the auth redirect search parameter.
      redirectSearchParams.set(
        authRedirectSearchParam,
        `${pathname}${search}${hash}`,
      );
    }

    return (
      <ReactRouterRedirect
        to={{
          pathname: checkIfPostinstall(pathname)
            ? R.SIGNUPFROMEXTENSION
            : R.SIGNUP,
          search: checkIfPostinstall(pathname)
            ? ''
            : redirectSearchParams.toString(),
        }}
      />
    );
  }

  /**
   * If an auth redirect query param exists and the user
   * is authenticated, redirect.
   */
  if (
    searchParams.has(authRedirectSearchParam) &&
    authUserUID &&
    !authRedirectBlocklist.includes(pathname)
  ) {
    const redirect = searchParams.get(authRedirectSearchParam)!;

    return <ReactRouterRedirect to={redirect} />;
  }

  /**
   * In any other case we will render as usual.
   */
  return <ReactRouterRoute component={component} {...otherProps} />;
};
