import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';

import { CreatorLandingStripeProps } from './CreatorLandingStripe.types';

const mapColorToClassName = {
  green: 'CreatorLandingStripe-green',
  purple: 'CreatorLandingStripe-purple',
};

const mapDirectionToClassName = {
  'dark-to-light': 'CreatorLandingStripe-darkToLight',
  'light-to-dark': 'CreatorLandingStripe-light-to-dark',
};

const UnstyledCreatorLandingStripe: VFC<CreatorLandingStripeProps> = ({
  className,
  style,
  color,
  direction,
}) => {
  return (
    <div
      className={clsx(
        className,
        mapColorToClassName[color],
        direction && mapDirectionToClassName[direction],
      )}
      style={style}
    />
  );
};

export const CreatorLandingStripe = styled(UnstyledCreatorLandingStripe)`
  height: 5px;

  &.${mapColorToClassName.purple}.${mapDirectionToClassName['dark-to-light']} {
    background: linear-gradient(to left, #fdabe3 0%, #8137ca 100%);
  }

  &.${mapColorToClassName.purple}.${mapDirectionToClassName['light-to-dark']} {
    background: linear-gradient(to right, #fdabe3 0%, #8137ca 100%);
  }

  &.${mapColorToClassName.green} {
    background: #b7fb00;
  }

  &.${mapColorToClassName.green}.${mapDirectionToClassName['dark-to-light']} {
    background: linear-gradient(to right, #38df6b 0%, #ccfd35 100%);
  }

  &.${mapColorToClassName.green}.${mapDirectionToClassName['light-to-dark']} {
    background: linear-gradient(to left, #38df6b 0%, #ccfd35 100%);
  }
`;
