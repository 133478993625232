/*
Product Tile List is like a container around the product tiles in a collection view
It reduces the amount of extreneuous stuff in this component, focuses on loading product data and leaves the
rest to the collection view.  besides it may be reused somewhere else at some point!

For logic see:
react-app/src/components/collection/ProductTileList/ProductTileList.container.tsx

Renders only
TODO:typescript, design-system and move to:
react-app/src/components/collection/ProductTileList/ProductTileList.component.tsx

*/
import React from 'react';
import styled from 'styled-components';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';

// Components:
import CollectionSubSection from 'src/mvp22/tile-components/CollectionSubSection';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import { EmptyMessageOwnCollection } from 'src/components/collection/EmptyMessageOwnCollection';
import { EmptyMessagePurchasedCollection } from 'src/components/collection/EmptyMessagePurchasedCollection';
import { EmptyMessageOtherUserCollection } from 'src/components/collection/EmptyMessageOtherUserCollection';
import { OrganiseMessageBlock } from './OrganiseMessageBlock';
import { CtaButton, HeartIcon } from '@moonsifttech/design-system';

export const CentralHeading3 = styled(TEXTSTYLE.HEADING3)`
  text-align: center;
  max-width: 305px;
  padding: 12px;
  margin: 0 auto;
`;

export const CentralHeading5 = styled(TEXTSTYLE.HEADING5)`
  text-align: center;
  padding: 12px;
`;

export const CentralText3 = styled(TEXTSTYLE.BODY3)`
  text-align: center;
  margin-bottom: 10px;
`;

const TilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 15px;
  width: 100%;
  margin-top: -16px;
  margin-bottom: ${(props) => (props.isLoggedIn ? '155px' : '93px')};
  box-sizing: border-box;
  max-width: 1168px;
`;

export const BoldLink = styled(TEXTSTYLE.LINK)`
  padding: 0 0 2px;
  font-weight: normal;
  border-bottom: 1px solid black;
`;

export const AddManuallyButtonContainer = styled.div`
  display: flex;
  margin-top: 36px;
`;

export const AddManuallyButtonContainerWithItems = styled.div`
  display: flex;
  margin-top: -36px;
  flex-direction: column;
`;

/**
 * ProductTileList
 *
 * This file actually gets and displays a list of product tiles...:
 */
export const ProductTileList = ({
  authUserUID,
  isPro,
  collectionUID,
  collectionUserUID,
  isMine,
  loadedProducts,
  nonReceived,
  renderOrder,
  userViews,
  // Opening modals:
  showProductModal,
  // Organising:
  isOrganising,
  // Organising - ordering:
  moveItem,
  // Organising - subsections:
  addSubsection,
  moveSubsection,
  deleteSubsection,
  updateSubsectionName,
  addOrRemoveItemToSubsection,
  focusSubsectionTextbox,
  // Organising - covers
  choosingCoverImage, // choosing cover?
  setChoosingCoverImage, // open choosing cover etc.
  setOrganisingCoverImageURL,
  deleteCoverByIndex,
  selectCover,
  goToAddManually,
  showReviewButton,
  openReviewModal,
  isCreator,
  goToAddItem,
}) => {
  if (nonReceived) {
    if (isMine !== true) {
      return <EmptyMessageOtherUserCollection />;
    }
    if (collectionUID === 'purchased') {
      return <EmptyMessagePurchasedCollection />;
    }
    return <EmptyMessageOwnCollection goToAddItem={goToAddItem} />;
  } else if (loadedProducts) {
    // reorder here to avoid temporary switch around when saving...
    const thisOrderInfo = renderOrder();
    const SUBSECTION_LIST = thisOrderInfo.subsectionOrder.map(
      (thisSubsectionUID) => {
        return {
          ...thisOrderInfo.subsectionInfoMap[thisSubsectionUID],
          uid: thisSubsectionUID,
        };
      },
    );
    // Make local copies of subsection maps that include none-subsectioned products:
    // This allows us to render non-subsectioned products in a dummy subsection:
    const localSubsectionOrder = [...thisOrderInfo.subsectionOrder, 'NONE'];
    const localItemToSubsectionMap = { ...thisOrderInfo.itemToSubsectionMap };
    thisOrderInfo.itemOrder
      .filter(
        (itemUID) => thisOrderInfo.itemToSubsectionMap[itemUID] === undefined,
      )
      .forEach((itemUID) => (localItemToSubsectionMap[itemUID] = 'NONE'));
    // Note: needs to be the one where the order is currently being edited!
    return (
      <>
        <OrganiseMessageBlock
          thisOrderInfo={thisOrderInfo}
          isOrganising={isOrganising}
          isPro={isPro}
          collectionUID={collectionUID}
          collectionUserUID={collectionUserUID}
          deleteCoverByIndex={deleteCoverByIndex}
          setOrganisingCoverImageURL={setOrganisingCoverImageURL}
          setChoosingCoverImage={setChoosingCoverImage}
          choosingCoverImage={choosingCoverImage}
        />
        <TilesContainer isLoggedIn={authUserUID}>
          {localSubsectionOrder.map((thisSubsectionUID, index) => (
            <CollectionSubSection
              key={thisSubsectionUID}
              index={index}
              subsectionInfo={
                thisOrderInfo.subsectionInfoMap[thisSubsectionUID]
              }
              subsectionList={SUBSECTION_LIST}
              uid={thisSubsectionUID}
              productTiles={thisOrderInfo.itemOrder.filter(
                (this_entry_uid) =>
                  localItemToSubsectionMap[this_entry_uid] ===
                  thisSubsectionUID,
              )}
              moveItem={moveItem}
              addSubsection={addSubsection}
              addOrRemoveItemToSubsection={addOrRemoveItemToSubsection}
              deleteSubsection={deleteSubsection}
              moveSubsection={moveSubsection}
              updateSubsectionName={updateSubsectionName}
              isPro={isPro}
              doFocusSubsectionTextbox={
                focusSubsectionTextbox === thisSubsectionUID
              }
              isOrganising={isOrganising}
              collectionUserUID={collectionUserUID}
              collectionUID={collectionUID}
              showProductModal={showProductModal}
              userViews={userViews}
              choosingCoverImage={choosingCoverImage}
              coverImageDict={thisOrderInfo.coverImageDict}
              selectCover={selectCover}
            />
          ))}
        </TilesContainer>
        <AddManuallyButtonContainerWithItems>
          {showReviewButton && (
            <CtaButton
              size="medium"
              style={{ width: '300px' }}
              variant="primary-white-grey"
              onClick={openReviewModal}
              startIcon={<HeartIcon />}
            >
              Are you enjoying Moonsift?
            </CtaButton>
          )}
        </AddManuallyButtonContainerWithItems>
      </>
    );
  } else {
    return (
      <>
        <LoadingDisplay message="Loading Items..." />
        <div style={{ height: '600px' }} />
      </>
    );
  }
};
