import { VFC } from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Typography,
} from '@moonsifttech/design-system';

import { RegistryNoteProps } from './RegistryNote.types';

const UnstyledRegistryNote: VFC<RegistryNoteProps> = ({
  className,
  style,
  heading,
  text,
}) => {
  return (
    <Paper className={className} style={style} variant="outlined">
      <Typography variant="primary.l14" component="p">
        {heading}
      </Typography>
      <Typography variant="primary.r14">{text}</Typography>
    </Paper>
  );
};

export const RegistryNote = styled(UnstyledRegistryNote)`
  padding: 12px;
  background-color: var(--grey-5);

  ${({ theme }) => theme.fns.getShapeStyles('soft')}

  &.${paperClassNames.variantOutlined} {
    border: 1px solid var(--grey-5);
  }

  p:first-child {
    margin-bottom: 4px;
  }
`;
