import { useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { object, string } from 'yup';
import {
  Typography,
  StandardButton,
  ControlledTextField,
  textFieldClassNames,
  Stack,
} from '@moonsifttech/design-system';
import { ModalContent } from 'src/components/core/ModalContent';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { CollectionDocument } from 'src/types/models/collection.model';
import { useMine } from 'src/hooks/useMine';
import { useModal } from 'src/hooks/useModal';


const formValidationSchema = object({
  message: string().required(),
}).required();

const UnstyledDeliveryInfoDialog = ({
  collectionId,
  collectionUserId,
  back,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  back?: Function;
  className?: string;
}) => {

  const [ _, closeModal ] = useModal();
  const { isMine } = useMine(collectionUserId);
  const isRevealing = useSelector<RootState, boolean>((state) => {
    return state.collectionRegistryUi.isRevealing;
  });

  const userNames = useSelector((state: RootState) => {
    return state.db.user_public.map;
  });
  const name = userNames[collectionUserId]?.name ?? 'them';

  const deliveryInfo = useSelector<RootState, string | undefined>(
    (state) => state.db.single_collection.data?.delivery_info,
  );

  const authUserUID = useSelector<RootState, string>(
    (state) => state.auth.id ?? 'no_user',
  );
  const { db } = useContext(ServicesContext);
  const [ updateCollectionDocument, isSaving ] = useDBUpdateDoc<Partial<CollectionDocument>>(
    db,
    db.collectionDoc(authUserUID, collectionId),
  );

  const handleSubmit = async ({ message }: { message: string }) => {
    await updateCollectionDocument({ delivery_info: message });
    const cb = back ?? closeModal;
    cb();
  };

  const handleReset = async () => {
    await updateCollectionDocument({ delivery_info: '' });
    const cb = back ?? closeModal;
    cb();
  };

  const initialFormValues = { message: deliveryInfo ?? '' };

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        { isMine && !isRevealing ? 'Add delivery info' : 'Delivery information' }
      </Typography>
      {
        isMine && !isRevealing ? (
          <Typography variant="primary.r16" component="p">
            Add the option for people to send gifts directly to your chosen address. Feel free to include any other delivery instructions.
          </Typography>
        ) : (
          <>
            <Typography variant="primary.r16" component="p" style={{ marginBottom: '12px' }}>
              {`If you'd like to send your gift directly to ${name}, take note of the delivery info below to use at the checkout.`}
            </Typography>
            <Typography variant="primary.r16" component="p" style={{ marginBottom: '24px' }}>
              Checkout and delivery are handled by the retailer.
            </Typography>
          </>
        )
      }
      {
        isMine && !isRevealing ? (
          <Formik
            initialValues={initialFormValues}
            validationSchema={formValidationSchema}
            onSubmit={handleSubmit}
            onReset={handleReset}
          >
            {
              ({ dirty, isSubmitting, isValid }) => {
                const canReset = initialFormValues.message !== ''
                  && isMine
                  && !isRevealing
                  && !isSubmitting;

                return (
                  <Form>
                    <ControlledTextField
                      name="message"
                      multiline
                      rows={4}
                      placeholder={'Enter your delivery details (optional)'}
                      maxLength={400}
                      fullWidth
                    />
                    <Stack
                      direction="row"
                      justifyContent={canReset ? 'space-between' : 'flex-end'}
                      spacing={12}
                    >
                      {
                        canReset && (
                          <StandardButton
                            type="reset"
                            variant="primary-white"
                            loading={isSaving}
                            disabled={initialFormValues.message === ''}
                          >
                            Remove delivery info
                          </StandardButton>
                        )
                      }
                      <StandardButton
                        type="submit"
                        variant="primary-white"
                        loading={isSaving}
                        disabled={!dirty || !isValid}
                      >
                        Confirm
                      </StandardButton>
                    </Stack>
                  </Form>
                );
              }
            }
          </Formik>
        ) : (
          <>
            <div className="AddDeliveryInfoDialog-guestInfoPanel">
              <Typography variant="primary.l14" component="p" className="AddDeliveryInfoDialog-guestInfoHeading">
                Delivery info
              </Typography> 
              <Typography variant="primary.r14" component="p" className="AddDeliveryInfoDialog-guestInfo">
                {initialFormValues.message}
              </Typography> 
            </div>
            <Stack
              direction="row"
              justifyContent="flex-end"
              spacing={12}
            >
              <StandardButton
                variant="primary-white"
                onClick={(ev) => (back ?? closeModal)(ev)}
              >
                Back
              </StandardButton>
            </Stack>
          </>
        )
      }
    </ModalContent>
  );
};

export const DeliveryInfoDialog = styled(UnstyledDeliveryInfoDialog)`
  .${textFieldClassNames.root} {
    margin-bottom: 32px;
  }

  .AddDeliveryInfoDialog-errorMessage {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .${textFieldClassNames.root} {
      margin-bottom: 46px;
    }
  }

  .AddDeliveryInfoDialog-guestInfoPanel {
    border: 2px solid ${({ theme }) => theme.fns.getColor('background.purple')};
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 32px;
  }

  .AddDeliveryInfoDialog-guestInfoHeading {
    margin-bottom: 0.5rem;
  }

  .AddDeliveryInfoDialog-guestInfo {
    font-style: italic;
    white-space: pre-wrap;
  }
`;
