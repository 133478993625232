import { Selector, createCachedSelector } from 're-reselect';

import { RootState } from 'src/index';
import { CollectionRegistryPrivate } from 'src/types/models/collectionRegistryPrivate.model';

export const selectCollectionRegistryPrivateList: Selector<
  RootState,
  CollectionRegistryPrivate[]
> = ({ db }) => {
  return db.collection_registry_private.data;
};

export const selectCollectionRegistryPrivate = createCachedSelector(
  [
    selectCollectionRegistryPrivateList,
    (_state: RootState, collectionItemUID: string) => collectionItemUID,
  ],
  (collectionRegistryPrivateList, collectionItemUID) =>
    collectionRegistryPrivateList.filter(({ collection_item_uid }) => {
      return collection_item_uid === collectionItemUID;
    }),
)((_state: RootState, collectionItemUID: string) => collectionItemUID);
