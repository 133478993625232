import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledCheckbox,
  FormControlLabel,
  formControlLabelClassNames,
  Form,
  ControlledFormGroup,
  Link,
  Stack,
} from '@moonsifttech/design-system';

import { categories } from 'src/types/models/creatorApplication.model';
import { CreatorApplicationOneProps } from './CreatorApplicationOne.types';
import R from 'src/routes';

const UnstyledCreatorApplicationOne: VFC<CreatorApplicationOneProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  onSubmit,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationOne-content">
        <Typography
          className="CreatorApplicationOne-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Let's quickly get to know each other.
        </Typography>
        <Typography
          className="CreatorApplicationOne-subheading"
          variant="editorial.sb34"
          component="h2"
        >
          What content do you focus on?
        </Typography>
        <Typography variant="primary.l20" component="p">
          You can choose more than one
        </Typography>
        <Form
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <ControlledFormGroup
            className="CreatorApplicationOne-checkboxes"
            name="categories"
          >
            {categories.map((category, i) => (
              <FormControlLabel
                key={i}
                label={category}
                labelPlacement="end"
                value={category}
                control={<ControlledCheckbox name="categories" />}
              />
            ))}
          </ControlledFormGroup>
          <StandardButton type="submit" variant="primary-black">
            Next
          </StandardButton>
        </Form>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const CreatorApplicationOne = styled(UnstyledCreatorApplicationOne)`
  padding: 100px 0 0;

  .CreatorApplicationOne-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationOne-heading {
    color: var(--background-purple);
    margin-bottom: 8px;
  }

  .CreatorApplicationOne-subheading {
    margin-bottom: 8px;
  }

  .CreatorApplicationOne-checkboxes {
    display: flex;
    margin-bottom: 24px;

    .${formControlLabelClassNames.root} {
      margin-bottom: 4px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
