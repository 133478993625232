import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FooterPopup } from 'src/mvp22/popup-components/FooterPopup';
import { FooterPopupContainerProps } from './FooterPopup.types';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import {
  FooterPopupAction,
  FooterPopupState,
} from 'src/types/models/footerPopup.model';
import { ActionNames } from 'src/types';

const Container: React.FC<FooterPopupContainerProps> = () => {
  const [isUndoing, setIsUndoing] = useState(false);
  const [opacity, setOpacity] = useState(0.9);
  const [stateTimeout, setStateTimeout] = useState(-1);
  const [localFooterUID, setLocalFooterUID] = useState('');
  const { cloud } = useContext(ServicesContext);
  const dispatch = useDispatch();
  const footerPopup = useSelector<RootState, FooterPopupState>(
    (state) => state.footerPopup,
  );
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserDisplayUsername = useSelector<RootState, string>(
    (state) => state.firestore_user_owner.display_username,
  );

  // Actually close the footer
  const closeFooterPopup = useCallback(
    () =>
      dispatch<FooterPopupAction>({
        type: ActionNames.FOOTER_POPUP_SET,
        payload: {
          type: null,
        },
      }),
    [dispatch],
  );

  // If a user mouses-over the footer we want to stop the fade out and let them read it:
  const removeTimeout = useCallback(() => {
    window.clearTimeout(stateTimeout);
    setOpacity(0.9);
  }, [stateTimeout]);

  // We want to start fading the footer on mouseout (as well as initially)
  // We will then close and remove it when it has finished fading out!
  const fadePopup = useCallback(() => {
    setOpacity(0.0);
    setStateTimeout(window.setTimeout(closeFooterPopup, 2000));
  }, [closeFooterPopup]);

  // On mouseout will soon start fading out again...
  const resetTimeout = useCallback(() => {
    window.clearTimeout(stateTimeout);
    setStateTimeout(window.setTimeout(fadePopup, 500)); // integer is returned.
  }, [stateTimeout, fadePopup]);

  const closeFooterPopupWithEvent = useCallback(
    (event) => {
      closeFooterPopup();
      if (event) {
        event.preventDefault();
      }
    },
    [closeFooterPopup],
  );

  // Initial setup:
  // change the UID to start it again!
  useEffect(() => {
    if (footerPopup && footerPopup.type && localFooterUID !== footerPopup.uid) {
      setLocalFooterUID(footerPopup.uid ?? '');
      window.clearTimeout(stateTimeout);
      setStateTimeout(window.setTimeout(fadePopup, 2000)); // integer is returned.
      setOpacity(0.9);
    }
  }, [footerPopup, fadePopup, stateTimeout, localFooterUID]);

  // Specific action for the deleted product footer:
  const undoAction = (event: MouseEvent) => {
    event.preventDefault();
    if (footerPopup.type === 'item_deletion' && isUndoing !== true) {
      setIsUndoing(true);
      cloud
        .fastAPI({
          api: 'delete_product_undo',
          collection_uid: footerPopup.data.collectionUID,
          product_uid: footerPopup.data.itemUID,
        })
        .then(() => {
          setIsUndoing(false);
          closeFooterPopup();
        })
        .catch(() => setIsUndoing(false));
    }
    return false;
  };

  const isMine =
    footerPopup.type === 'item_deletion' &&
    footerPopup.data.collectionUserUID === authUserUID;

  return (
    <FooterPopup
      {...{
        type: footerPopup.type,
        opacity,
        authUserDisplayUsername,
        isMine,
        isUndoing,
        removeTimeout,
        resetTimeout,
        undoAction,
        closeFooterPopup: closeFooterPopupWithEvent,
      }}
    />
  );
};

export { Container as FooterPopup };
