/*

This hook takes a provided profileUsername (usaully from URL) and looks it up in
the database. NOTE: This is async, as it looks stuff up in the db.  Hence if it
is called several times in quick succession with different arguments then we
need to make sure that we are setting the result to match the latest argument.

*/

import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { getProfileUserUID } from 'src/utils/getProfileUserUID';

interface ProfileUserNameAndUID {
  username: string;
  uid: string;
}

export const useUserUIDLookup = () => {
  // Contexts:
  const { db: dB } = useContext(ServicesContext);

  // State:
  // string from URL:
  const [profileUsernameFromURL, setProfileUsernameFromURL] = useState('');
  // result from async call, intermediate state required due to async call and
  // results may not match present requirements:
  const [asyncUserUIDLookupResult, setAsyncUserUIDLookupResult] =
    useState<ProfileUserNameAndUID>({
      username: '',
      uid: '',
    });
  // processed async call result
  const [profileUserUID, setProfileUserUID] = useState('');

  // Selectors:
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const authUserDisplayUsername = useSelector<RootState, string>(
    (state) => state.firestore_user_owner.display_username,
  ); // undefined if not loaded yet, otherwise authUserUID

  // On the usernameFromURL changing, find the profileUserUID: Store result as
  // intermediary to ensure it matches the present requirements:

  // TODO: Consider replacing this with a common db hook and storing in redux...
  // This would reduce the number of calls a bit. This would need to use storeAs
  // ideally to store any async results (and not repeat them)
  useEffect(() => {
    // if we can, save looking up the auth user UID, set it straight:
    if (
      authUserUID &&
      (profileUsernameFromURL === authUserUID ||
        profileUsernameFromURL === authUserDisplayUsername)
    ) {
      setProfileUserUID(authUserUID);
    } else {
      // ASYNC call to getting user UID from username:
      setProfileUserUID('');
      getProfileUserUID(profileUsernameFromURL, dB).then(
        (responseProfileUserUID) =>
          setAsyncUserUIDLookupResult({
            uid: responseProfileUserUID,
            username: profileUsernameFromURL,
          }),
      );
    }
  }, [authUserDisplayUsername, authUserUID, dB, profileUsernameFromURL]);

  // Take the async result and store it only if we are presently looking for that result:
  useEffect(() => {
    if (asyncUserUIDLookupResult.username === profileUsernameFromURL) {
      setProfileUserUID(asyncUserUIDLookupResult.uid);
    }
  }, [asyncUserUIDLookupResult, profileUsernameFromURL]);

  return {
    setProfileUsernameFromURL,
    profileUserUID,
  };
};
