import React from 'react';
import { FeedbackRequestProps } from './FeedbackRequest.types';
import {
  Stack,
  StandardButton,
  Typography,
  TextField,
  standardButtonClassNames,
} from '@moonsifttech/design-system';

import styled from 'styled-components';
const UnstyledFeedbackRequest: React.FC<FeedbackRequestProps> = ({
  onClickSendFeedback,
  isSendingFeedback,
  feedbackValid,
  onFeedbackChange,
  className,
  style,
}) => {
  return (
    <form className={className} style={style} onSubmit={onClickSendFeedback}>
      <Stack
        className="FeedbackRequest-innerContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography
          className="FeedbackRequest-heading"
          variant="primary.b26"
          component="h3"
        >
          How can we improve Moonsift for you?
        </Typography>
        <TextField
          name="feedback"
          placeholder="Please write something here"
          multiline
          maxLength={1000}
          rows={5}
          maxRows={5}
          fullWidth
          onChange={onFeedbackChange}
        />
        <Stack
          direction="row"
          justifyContent="flex-end"
          className="FeedbackRequest-finishButtons"
        >
          <StandardButton
            variant="primary-white"
            type="submit"
            loading={isSendingFeedback}
            disabled={feedbackValid === false}
          >
            Send
          </StandardButton>
        </Stack>
      </Stack>
    </form>
  );
};

export const FeedbackRequest = styled(UnstyledFeedbackRequest)`
  .FeedbackRequest-heading {
    padding-right: 42px;
  }
  .FeedbackRequest-finishButtons {
    margin-top: 64px;
    width: 100%;
  }
  .${standardButtonClassNames.variantPrimaryWhite} {
    margin-left: 12px;
  }

  form {
    height: 100%;
  }

  form {
    margin-bottom: 0;
  }
`;
