import { VFC } from 'react';
import styled from 'styled-components';
import { Typography, Avatar, Stack } from '@moonsifttech/design-system';

import { EXTERNAL } from 'src/routes';
import { FiveStars } from 'src/components/common/FiveStars';
import { PeopleSayingSlideProps } from './PeopleSayingSlide.types';

const UnstyledPeopleSayingSlide: VFC<PeopleSayingSlideProps> = ({
  className,
  style,
  comment,
  avatar,
  name,
  social,
  ...otherProps
}) => {
  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Review',
          'itemReviewed': {
            '@type': 'MobileApplication',
            'name': 'Universal Wishlist by Moonsift',
            'applicationCategory': 'Shopping',
            'applicationSubCategory': 'Productivity',
            'downloadUrl': EXTERNAL.APP_STORE_APP,
            'operatingSystem': 'IOS',
          },
          'reviewBody': comment,
          'reviewRating': {
            '@type': 'Rating',
            'ratingValue': '5',
          },
          'author': {
            '@type': 'Person',
            'name': name,
          },
        })}
      </script>
      <div className={className} style={style} {...otherProps}>
        <div className="PeopleSayingSlide-bubbleWrapper">
          <div className="PeopleSayingSlide-bubble">
            <div>
              <Typography variant="primary.l16">{comment}</Typography>
            </div>
            <div>
              <FiveStars size="small" />
            </div>
          </div>
        </div>
        <Stack className="PeopleSayingSlide-info" direction="row" spacing={12}>
          <Avatar size={62} src={avatar}></Avatar>
          <Stack direction="column" justifyContent="flex-end">
            <Typography
              className="PeopleSayingSlide-name"
              variant="primary.b18"
              component="p"
            >
              {name}
            </Typography>
            <Stack direction="row" spacing={6} alignItems="center">
              <picture>
                <source
                  media="(max-width: 620px)"
                  srcSet={`${process.env.REACT_APP_STATIC_URL}/logos/colorful-${social.type}-logo.png`}
                />
                <img
                  className="PeopleSayingSlide-socialLogo"
                  alt={`${social.type
                    .slice(0, 1)
                    .toUpperCase()}${social.type.slice(1)} logo`}
                  src={`${process.env.REACT_APP_STATIC_URL}/logos/colorful-${social.type}-logo.2x.png`}
                />
              </picture>
              <Typography variant="primary.l16">{social.handle}</Typography>
            </Stack>
          </Stack>
        </Stack>
      </div>
    </>
  );
};

export const PeopleSayingSlide = styled(UnstyledPeopleSayingSlide)`
  width: 300px;

  .PeopleSayingSlide-bubbleWrapper {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));

    .PeopleSayingSlide-bubble {
      background-color: var(--common-white);
      display: grid;
      grid-template-rows: 1fr auto;
      width: 268px;
      height: 180px;
      padding: 12px 12px 52px 20px;
      margin-right: 32px;
      margin-bottom: 12px;
      clip-path: path(
        'M 0 9 Q 0 0 9 0 L 259 0 Q 268 0 268 9 L 268 143 Q 268 152 259 152 L 58 152 Q 54 152 26 180 L 26 152 L 9 152 Q 0 152 0 143 L 0 9'
      );
    }
  }

  .PeopleSayingSlide-info {
    .PeopleSayingSlide-name {
      margin-bottom: 4px;
    }

    .PeopleSayingSlide-socialLogo {
      width: 18px;
    }
  }
`;
