import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';

/**
 * Determines whether we are on mobile.
 */
export const useIsMobile = () => {
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;

  return useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );
};
