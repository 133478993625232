/*

A service that only runs once to see if we are running in the app:

*/
import queryString from 'query-string';
import { createError } from '@moonsifttech/design-system';

import {
  IncomingMessageType,
  IsActiveIncomingMessage,
  Message,
  OutgoingMessageType,
} from './Mobile.types';

export class Mobile {
  public isApp: boolean;
  public signInToken: string | undefined;

  constructor() {
    // default:
    this.isApp = false;
    // Get the sign in token from the URL:
    const signInToken = queryString.parse(window.location.search).sign_in_token;
    if (typeof signInToken === 'string') {
      this.isApp = true;
      this.signInToken = signInToken;
      // Set local storage for the future:
      if (window.localStorage) {
        window.localStorage.setItem('MOONSIFT_IS_MOBILE', 'YES');
      }
    }
    // see if there is a cookie:
    if (
      window.localStorage &&
      window.localStorage.getItem('MOONSIFT_IS_MOBILE') === 'YES'
    ) {
      console.log(
        'FYI - A cookie has set that you are using the mobile app to view the moonsife web-app',
      );
      this.isApp = true;
    }
  }

  // Parses the message, checks whether it has the expected form and returns it
  // or returns `undefined` otherwise.
  private parseMessage = <M extends Message<string, any>>(
    expectedType: M['type'] | M['type'][],
    data: any,
  ): M | undefined => {
    try {
      const json = JSON.parse(data);
      const expectedTypes = new Array<M['type']>().concat(expectedType);

      const hasExpectedType = expectedTypes.find((type) => {
        return json?.type === type;
      });

      if (hasExpectedType) {
        return json as M;
      }

      const error = createError(
        'MobileServiceError',
        'The provided message is not the expected type',
      );
      console.error(error);
    } catch (error) {
      // Probably, `data` could not be JSON-serialised
      console.error(error);
    }
  };

  public postMessage = (type: string, payload?: any): void | never => {
    // Post message only when in the app
    if (!this.isApp) {
      throw createError(
        'MobileServiceError',
        'Tried to post message to the app when not in the app',
      );
    }
    try {
      const message = JSON.stringify({ type, payload });
      window.ReactNativeWebView!.postMessage(message);
    } catch (err) {
      console.error('Could not post message to app', type, payload);
    }
  };

  public requestInAppReview = () => {
    // Check app is active prior to sending message to request review:
    console.log('REQUESTING IN APP NATIVE REVIEW');
    const messagePromise = this.listenToMessage<IsActiveIncomingMessage>(
      IncomingMessageType.IsActive,
      500,
    )
      .then(() => {
        this.postMessage(OutgoingMessageType.RequestInAppReview);
      })
      .catch((err) => console.log('App does not appear to be active', err));
    this.postMessage(OutgoingMessageType.CheckIsActive);
    return messagePromise;
  };

  public listenToMessage = <M extends Message<string, any>>(
    type: M['type'] | M['type'][],
    timeout: number = 1000,
  ): Promise<M> => {
    // Listen to messages only when in the app
    if (!this.isApp) {
      return Promise.reject(
        createError(
          'MobileServiceError',
          'Tried listening to messages from the app when not in the app',
        ),
      );
    }

    return new Promise((resolve, reject) => {
      // Set a timeout to reject the promise if a message with the provided type
      // has not arrived before the timeout
      const timeoutHandle = setTimeout(() => {
        const error = createError(
          'MobileServiceError',
          `Timeout while waiting for message of type ${type}.`,
        );
        window.removeEventListener('message', onMessage);
        reject(error);
      }, timeout);

      // Message listener
      const onMessage = (event: MessageEvent<any>) => {
        // Parse message of the provided type
        const message = this.parseMessage<M>(type, event.data);

        if (message) {
          // Clean async process to avoid memory leaks
          clearTimeout(timeoutHandle);
          window.removeEventListener('message', onMessage);

          // Resolve promise with the received message
          console.info(
            `[MobileServiceError] Message of type ${type} received.`,
          );
          resolve(message);
        }
      };

      // Start listening to the app
      window.addEventListener('message', onMessage);
    });
  };
}
