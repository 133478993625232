import TableCell from '@mui/material/TableCell';

import {
  sumEarningsEntrySales,
  sumEarningsEntryCommissions,
  ItemEarningsEntry,
} from 'src/types/models/creatorEarnings.model';
import { CurrencyFormatter } from 'src/utils/CurrencyFormatter';
import { CollectionEarningsTableRowProps } from './CollectionEarningsTableRow.types';

export {
  mobileOrder,
  desktopOrder,
} from 'src/components/creator/EarningsTable/getCells';

/**
 * Returns a map where keys are column names in the table and values are the cells in the main rows.
 * This map is then used to render these cells given the order of those columns.
 */
export const getMainCells = ({
  clicks = 0,
  sales,
  totalEarnings,
}: Pick<
  CollectionEarningsTableRowProps,
  'clicks' | 'sales' | 'totalEarnings'
>): { [key: string]: JSX.Element } => ({
  brand: <TableCell key="brand"></TableCell>,
  clicks: (
    <TableCell key="clicks" align="right">
      {clicks}
    </TableCell>
  ),
  sales: (
    <TableCell key="sales" align="right">
      {sales}
    </TableCell>
  ),
  salesValue: (
    <TableCell key="salesValue" align="right">
      {CurrencyFormatter.format(sumEarningsEntrySales(totalEarnings), 'GBP')}
    </TableCell>
  ),
  commissionValue: (
    <TableCell key="commissionsValue" align="right">
      {CurrencyFormatter.format(
        sumEarningsEntryCommissions(totalEarnings),
        'GBP',
      )}
    </TableCell>
  ),
});

/**
 * Returns a map where keys are column names in the table and values are the cells in the item rows.
 * This map is then used to render these cells given the order of those columns.
 */
export const getItemCells = ({
  brand,
  clicks = 0,
  sales,
  given,
  due,
  pending,
}: Pick<
  ItemEarningsEntry,
  'brand' | 'clicks' | 'sales' | 'given' | 'due' | 'pending'
>): {
  [key: string]: JSX.Element;
} => ({
  brand: <TableCell key="brand">{brand}</TableCell>,
  clicks: (
    <TableCell key="clicks" align="right">
      {clicks}
    </TableCell>
  ),
  sales: (
    <TableCell key="sales" align="right">
      {sales}
    </TableCell>
  ),
  salesValue: (
    <TableCell key="salesValue" align="right">
      {CurrencyFormatter.format(
        sumEarningsEntrySales({ given, due, pending }),
        'GBP',
      )}
    </TableCell>
  ),
  commissionValue: (
    <TableCell key="commissionsValue" align="right">
      {CurrencyFormatter.format(
        sumEarningsEntryCommissions({ given, due, pending }),
        'GBP',
      )}
    </TableCell>
  ),
});
