/*
  Button controlling the entry into the product-to-product search on moonrose. This is currently a redirect.
  It can be reached from the product modal on the user's own collections.
*/

import styled from 'styled-components';
import clsx from 'clsx';
import {
  Typography,
  AISearchIcon,
} from '@moonsifttech/design-system';
import { buildMoonroseUrl } from 'src/utils/buildMoonroseUrl';

const variantsMap = {
  primary: 'ProductToProductButton-primary',
  secondary: 'ProductToProductButton-secondary',
};

const ButtonLink = styled.a`
  width: 100%;
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme.colors.background?.purple};

  &.${variantsMap.primary} {
    background-color: ${({ theme }) => theme.colors.background?.purple};
    color: ${({ theme }) => theme.colors.common?.white};
  }

  &.${variantsMap.secondary} {
    background-color: white;
    color: ${({ theme }) => theme.colors.background?.purple};
  }

  .ProductToProductButton-ButtonIcon {
    margin-right: 0.75rem;
  }
`;

export type Product = {
  url: string;
  siteUID: string;
  price: string;
  imageURL: string;
  description: string;
}

const UnstyledProductToProductButton = ({
  product,
  variant = 'primary',
  className,
  style,
}: {
  product: Product;
  variant?: 'primary' | 'secondary';
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <ButtonLink
      className={clsx(
        variantsMap[variant],
        className,
      )}
      style={style}
      href={buildMoonroseUrl(product)}
      target="_blank"
    >
      <AISearchIcon className="ProductToProductButton-ButtonIcon" />
      <Typography variant="primary.b16">
        Find similar items ✨
      </Typography>
    </ButtonLink>
  );
};

export const ProductToProductButton = styled(UnstyledProductToProductButton)`
  .ProductToProductButton-TooltipIcon {
    color: ${({ theme }) => theme.colors.background?.purple};
  }
`;
