import { getDateDestructed } from './getDateDestructed';

/*

Return a datetime string for the current date + time.

*/
export const getDateTimeString = (date = new Date()) => {
  const dateO = getDateDestructed(date);
  const millis = date.getUTCMilliseconds().toString().padStart(3, '0');
  return [
    dateO.year,
    dateO.month,
    dateO.day,
    dateO.hours,
    dateO.minutes,
    dateO.seconds,
    millis,
  ].join('_');
};
