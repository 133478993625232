import { VFC } from 'react';
import styled from 'styled-components';
import { CtaButton, ctaButtonClassNames } from '@moonsifttech/design-system';

import { CreatorLandingCtaButtonProps } from './CreatorLandingCtaButton.types';

const UnstyledCreatorLandingCtaButton: VFC<CreatorLandingCtaButtonProps> = ({
  className,
  style,
  hasApplied,
  onApply,
  onGoToMyEarnings,
}) => {
  return (
    <CtaButton
      className={className}
      style={style}
      variant="primary-black"
      onClick={hasApplied ? onGoToMyEarnings : onApply}
    >
      {hasApplied ? 'Go to your earnings' : `Claim your storefront`}
    </CtaButton>
  );
};

export const CreatorLandingCtaButton = styled(UnstyledCreatorLandingCtaButton)`
  &.${ctaButtonClassNames.variantPrimaryBlack}:not(:disabled) {
    width: min(100vw - 20px - 20px, 320px);
    background: var(--background-purple);
    color: var(--common-white);
    border-color: var(--background-purple);

    &:hover,
    &:active {
      background: var(--background-purple);
      border-color: var(--background-purple);
    }
  }
`;
