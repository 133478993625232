import React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { ModalMenuContainerProps } from './ModalMenu.types';
import { ModalMenu, modalMenuClassNames } from './ModalMenu.component';

const ModalMenuContainer: React.FC<ModalMenuContainerProps> = (props) => {
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  return <ModalMenu isMobile={isMobile} {...props} />;
};

export { modalMenuClassNames, ModalMenuContainer as ModalMenu };
