/*

Wraps components that require you to be logged in and displays the login page if the user is not logged in.

TODO: use the useAuth hook and make into it's own hook to supply a wrapper or just keep as context component.

*/
// This file explains how the context behaves
import React from 'react';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'src/mvp22/Firebase';
import Loading from 'src/mvp22/view-components/Loading';
import { SignIn } from 'src/views/SignIn';

class AuthComponent extends Component {

  render(){
    if (this.props.firebasestate.loaded===false){
      return(<Loading/>);
    }
    else{
      if (this.props.authUserUID !== null ){
        return(
          this.props.children
        );
      }
      // If not logged in....
      else{
        if (this.props.display_login===false){
          return(<Loading/>);
        }
        else{
          return(<SignIn />);
        }

      }
    }
  }
}


// Pass the state values into the component:
const mapStateToProps = (state) => {
  return ({
    authUserUID:state.auth.id,
    firebasestate:state.firebasestate
  });
};


const mapDispatchToProps = () => {
  return {
  };
};

const AuthLink = withRouter(connect(mapStateToProps,mapDispatchToProps)(withFirebase(AuthComponent)));

const AuthContext = React.createContext(null);
// <Component {...props}/>
// <AuthLink></AuthLink>
export const requiresAuth = (Component,display_login=true) => props => (
  <AuthContext.Consumer>
    {() => <AuthLink display_login={display_login}><Component {...props}/></AuthLink>}
  </AuthContext.Consumer>
);


export default AuthContext;
