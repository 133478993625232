import React from 'react';
import styled from 'styled-components';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  EditIcon,
  ShareIcon,
  NotificationsIcon,
  LoadingDots,
  BookmarkIcon,
  BookmarkTwoToneIcon,
} from '@moonsifttech/design-system';

import { modalMenuClassNames } from 'src/components/core/ModalMenu';
import { MenuContent } from 'src/components/core/MenuContent';
import {
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuProps,
} from './MoreCollectionOptionsMenu.types';

const UnstyledMoreCollectionOptionsMenu = React.forwardRef<
  MoreCollectionOptionsMenuRef,
  MoreCollectionOptionsMenuProps
>(
  (
    {
      className,
      style,
      isMine,
      isPurchased,
      onShare,
      onEdit,
      onNotifications,
      onBookmark,
      bookmarkLoading,
      isBookmarked,
    },
    ref,
  ) => {
    return (
      <MenuContent ref={ref} className={className} style={style}>
        <List>
          {isMine && !isPurchased && (
            <ListItem onClick={onEdit}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText>Edit Collection</ListItemText>
            </ListItem>
          )}
          {isMine && (
            <ListItem onClick={onNotifications}>
              <ListItemIcon>
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText>Notifications</ListItemText>
            </ListItem>
          )}
          <ListItem onClick={onShare}>
            <ListItemIcon>
              <ShareIcon />
            </ListItemIcon>
            <ListItemText>Share Collection</ListItemText>
          </ListItem>
          <ListItem onClick={onBookmark}>
            <ListItemIcon>
              {bookmarkLoading ? (
                <LoadingDots />
              ) : isBookmarked ? (
                <BookmarkTwoToneIcon />
              ) : (
                <BookmarkIcon />
              )}
            </ListItemIcon>
            <ListItemText>
              {isBookmarked ? `Unb` : `B`}ookmark Collection
            </ListItemText>
          </ListItem>
        </List>
      </MenuContent>
    );
  },
);

export const MoreCollectionOptionsMenu = styled(
  UnstyledMoreCollectionOptionsMenu,
)`
  .${modalMenuClassNames.isDesktop} & {
    width: 220px;
  }
  --secondary-color: ${({ theme }) => theme.fns.getColor('common.black')};
`;
