import React from 'react';
import { InitialRequestProps } from './InitialRequest.types';
import {
  Stack,
  Typography,
  CtaButton,
  ctaButtonClassNames,
} from '@moonsifttech/design-system';

import styled from 'styled-components';
import { BagHeart } from 'src/components/reviews/BagHeart';
const UnstyledInitialRequest: React.FC<InitialRequestProps> = ({
  onYesClick,
  onTooEarlyClick,
  onNoClick,
  isMobile,
  className,
  style,
}) => {
  return (
    <Stack
      className={className}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <BagHeart />
      <Typography
        className="InitialRequest-questionHeading"
        variant="primary.b26"
        component="h3"
      >
        Do you enjoy Moonsift?
      </Typography>
      <CtaButton
        variant="primary-white"
        onClick={onYesClick}
        fullWidth
        size={isMobile ? 'small' : 'medium'}
      >
        Yes
      </CtaButton>
      <CtaButton
        variant="primary-white"
        onClick={onTooEarlyClick}
        type="submit"
        fullWidth
        size={isMobile ? 'small' : 'medium'}
      >
        Too early to say
      </CtaButton>
      <CtaButton
        variant="primary-white"
        onClick={onNoClick}
        fullWidth
        size={isMobile ? 'small' : 'medium'}
      >
        No
      </CtaButton>
    </Stack>
  );
};

export const InitialRequest = styled(UnstyledInitialRequest)`
  .${ctaButtonClassNames.variantPrimaryWhite} {
    margin: 8px 0px;
  }

  .InitialRequest-questionHeading {
    text-align: left;
    width: 100%;

    ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'lg' })} {
      margin-bottom: 24px;
    }
  }
`;
