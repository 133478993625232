/*

REDUX single Doc,
part of reducer for authUserData

*/

import send_error from 'src/mvp22/core-components/send_error';
// AS this returns a lisener need to do the after function like this:
export default function get_user_verified_settings(firebase,auth_user_uid,after_function){
  // Return a listener:
  return firebase.user_verified_settings(auth_user_uid).onSnapshot(
    (snapshot)=>{
      const data_out = (snapshot.exists())?snapshot.data():{subscriptions:[]};
      after_function(data_out);
    },
    ()=>{
      send_error("Failed to get user verified settings");
    }
  );
}
