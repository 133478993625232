import React from 'react';
import { BaseComponentProps } from 'src/types';
import styled, { useTheme } from 'styled-components';

const UnstyledChromeReviewWebstoreImage: React.FC<BaseComponentProps> = ({
  className,
  style,
}) => {
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  return (
    <div className={className} style={style}>
      <picture>
        <source
          media={`(max-width: ${mobileBreakpoint}px)`}
          srcSet={`${process.env.REACT_APP_STATIC_URL}/reviews/chrome_webstore_review_button.png`}
        />
        <img
          alt="Review request"
          src={`${process.env.REACT_APP_STATIC_URL}/reviews/chrome_webstore_review_button.2x.png`}
        />
      </picture>
    </div>
  );
};

export const ChromeReviewWebstoreImage = styled(
  UnstyledChromeReviewWebstoreImage,
)`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 50px;
  margin-left: -20px;
  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    margin-left: -36px;
    margin-right: -36px;
  }
  margin-bottom: 24px;
  img {
    width: 100%;
  }
`;
