import React from 'react';
import { FeedbackThankYouProps } from './FeedbackThankYou.types';
import { Stack, Typography, CtaButton } from '@moonsifttech/design-system';
import { BagHeart } from 'src/components/reviews/BagHeart';
import styled from 'styled-components';
const UnstyledFeedbackThankYou: React.FC<FeedbackThankYouProps> = ({
  onClose,
  className,
  style,
}) => {
  return (
    <Stack
      className={className}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <BagHeart />
      <Typography
        className="FeedbackThankYou-questionHeading"
        variant="primary.b26"
        component="h3"
      >
        Thank you so much for your support
      </Typography>
      <Typography
        className="FeedbackThankYou-mainText"
        variant="primary.l20"
        component="p"
      >
        Your reviews keep our small team motivated to make Moonsift even better.
      </Typography>

      <CtaButton variant="primary-black" onClick={onClose} fullWidth>
        Done
      </CtaButton>
    </Stack>
  );
};

export const FeedbackThankYou = styled(UnstyledFeedbackThankYou)`
  text-align: left;

  .FeedbackThankYou-mainText {
    margin-bottom: 50px;
    width: 100%;
  }
  .FeedbackThankYou-questionHeading {
    width: 100%;
  }
`;
