/*

The footer popup sits at the bottom and gives action feedback, specifically deleting items in a collection and adding them to purchased.

TODO: typescript, design system?

The container is already in the new project, at:
react-app/src/components/popups/FooterPopup/FooterPopup.container.tsx

*/
import React from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import R from 'src/routes';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';

const PageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const PopupContainer = styled.div`
  position: fixed;
  bottom: 20px;
  width: 252px;
  height: 50px;
  border-radius: 28px;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  background-color: black;
  z-index: 100000;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${props=>props.opacity};
  animation-timing-function: ease;
  transition: ${props=>props.opacity<0.5?'2s':'0s'};
`;

const AddedToPurchasedPurchasedPopupContainer = styled(PopupContainer)`
  flex-direction: column;
  width: 325px;
  height: 91px;
`;

const PrimaryMessage = styled(TEXTSTYLE.BODY4)`
  margin-right: 18px;
  color: ${M.COL.TEXT.WHITE};
`;

const PrimaryActionText = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.WHITE};
  font-weight: bold;
`;

const Note = styled(TEXTSTYLE.BODY5)`
  color: ${M.COL.TEXT.WHITE};
  width: 275px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PrimaryActionButton = styled(TEXTSTYLE.LINKA)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 20px;
  width: 65px;
  height: 34px;
  &:hover{
    background-color: ${M.COL.BUTTON.DARKGREY};
  }
  cursor: pointer;
`;

const PrimaryActionIcon = styled.img`
  margin-right: 6px;
`;

const CloseButton = styled.img`
  height:14px;
  width:14px;
  cursor: pointer;
  filter: invert(1)
`;

const Divider = styled.div`
  background-color: ${M.COL.BG.MID};
  width: 100%;
  height: 1px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const Inner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Tick = styled.img`
  margin-right: 18px;
`;

const UnderlinedText = styled(TEXTSTYLE.LINK)`
  border-bottom: 1px solid white
`;

export const FooterPopup = ({
  type,
  opacity,
  authUserDisplayUsername,
  isMine,
  isUndoing,
  removeTimeout,
  resetTimeout,
  undoAction,
  closeFooterPopup
})=>{

  const getContent=()=>{
    switch(type){
      case 'item_deletion':
      return(
        <PageContainer className='PageContainer'>
          <PopupContainer onMouseOver={removeTimeout} onMouseOut={resetTimeout} className='popupContainer' opacity={opacity}>
            <PrimaryMessage>
              Item deleted.
            </PrimaryMessage>
            <PrimaryActionButton onClick={undoAction} href="#">
              <PrimaryActionIcon src={MEDIA.UNDO_ICON}/>
              {(isUndoing!==true)?
                <PrimaryActionText>
                  Undo
                </PrimaryActionText>
              :
                <PrimaryActionText>
                  ...
                </PrimaryActionText>
              }
            </PrimaryActionButton>
            <CloseButton src={MEDIA.EXIT_CROSS_THIN} onClick={closeFooterPopup}/>
          </PopupContainer>
        </PageContainer>
      );
      case 'added_to_purchased':
      return(
          <PageContainer className='PageContainer'>
            <AddedToPurchasedPurchasedPopupContainer onMouseOver={removeTimeout} onMouseOut={resetTimeout}  className='popupContainer' opacity={opacity}>
              <Inner>
                <Tick src={MEDIA.TICK}/>
                  <PrimaryMessage>
                    Added to <UnderlinedText to={R.COLLECTION+"/"+authUserDisplayUsername+"/purchased"}>{`'Purchased'`}</UnderlinedText> collection
                  </PrimaryMessage>
                <CloseButton src={MEDIA.EXIT_CROSS_THIN} onClick={closeFooterPopup}/>
              </Inner>
              {(isMine)?
                <>
                  <Divider/>
                  <Inner>
                    <Note>
                      Feel free to delete the item from this collection
                    </Note>
                  </Inner>
                </>
                :""
              }
            </AddedToPurchasedPurchasedPopupContainer>
          </PageContainer>
        );
      default:
      return("");
    }
  };
  return(
    <div>
      {getContent()}
    </div>
  );
};
