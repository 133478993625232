import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  typographyClassNames,
  Stack,
  ControlledTextField,
  textFieldClassNames,
  CtaButton,
  linkClassNames,
  FormHelperText,
  Form,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Link } from 'src/components/core/Link';
import { SignInFormProps } from './SignInForm.types';

const UnstyledSignInForm: React.FC<SignInFormProps> = ({
  className,
  style,
  signUpPath,
  initialValues,
  validationSchema,
  mainErrors,
  isWorking,
  onSubmit,
  onFacebookAuth,
  onGoogleAuth,
  onAppleAuth,
}) => {
  return (
    <Form
      className={className}
      style={style}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Stack
        className="SignInForm-formContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {mainErrors.email && (
          <FormHelperText style={{ maxWidth: '300px' }} error>
            {mainErrors.email}
          </FormHelperText>
        )}
        <ControlledTextField
          name="email"
          type="text"
          autoComplete="email"
          placeholder="Email"
          fullWidth
        />
        <ControlledTextField
          className="SignInForm-passwordInput"
          name="password"
          type="password"
          autoComplete="current-password"
          placeholder="Password"
          fullWidth
        />
        <Typography className="SignInForm-forgotPassword" variant="primary.r10">
          <Link to={R.FORGOTPASSWORD}>Forgot password?</Link>
        </Typography>
        <CtaButton
          className="SignInForm-continueButton"
          type="submit"
          variant="primary-black"
          fullWidth
          loading={isWorking.email}
          disabled={isWorking.facebook || isWorking.google || isWorking.apple}
        >
          Log In
        </CtaButton>
        <div className="SignInForm-orSeparator">
          <Stack justifyContent="center">
            <hr />
          </Stack>
          <Typography variant="primary.b18" component="span">
            OR
          </Typography>
          <Stack justifyContent="center">
            <hr />
          </Stack>
        </div>
        {mainErrors.providers && (
          <FormHelperText error>{mainErrors.providers}</FormHelperText>
        )}
        <CtaButton
          className="SignInForm-facebookButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/facebookSignupLogo.svg`}
              alt="Facebook logo"
            />
          }
          fullWidth
          loading={isWorking.facebook}
          disabled={isWorking.email || isWorking.google || isWorking.apple}
          onClick={onFacebookAuth}
        >
          Continue with Facebook
        </CtaButton>
        <CtaButton
          className="SignInForm-googleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/googleSignupLogo.svg`}
              alt="Google logo"
            />
          }
          fullWidth
          loading={isWorking.google}
          disabled={isWorking.email || isWorking.facebook || isWorking.apple}
          onClick={onGoogleAuth}
        >
          Continue with Google
        </CtaButton>
        <CtaButton
          className="SignInForm-appleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/apple_logo.svg`}
              alt="Apple logo"
            />
          }
          fullWidth
          loading={isWorking.apple}
          disabled={isWorking.email || isWorking.facebook || isWorking.google}
          onClick={onAppleAuth}
        >
          Continue with Apple
        </CtaButton>
        <Typography
          className="SignInForm-terms"
          variant="primary.r12"
          component="p"
          align="center"
        >
          By continuing, you agree to Moonsift's Terms of Service,
          <br />
          Privacy policy.
        </Typography>
        <Typography
          className="SignInForm-login"
          variant="primary.r12"
          component="p"
        >
          No account? <Link to={signUpPath}>Sign up</Link>
        </Typography>
      </Stack>
    </Form>
  );
};

export const SignInForm = styled(UnstyledSignInForm)`
  .SignInForm-formContainer {
    min-width: 320px;

    > .${textFieldClassNames.root} {
      margin-bottom: 10px;
    }

    > .SignInForm-passwordInput {
      margin-bottom: 0;
    }

    > .SignInForm-forgotPassword {
      margin-bottom: 16px;
      align-self: flex-end;

      .${linkClassNames.root} {
        text-decoration: none;
        color: var(--grey-2);
      }
    }

    > .SignInForm-continueButton {
      margin-bottom: 12px;
    }

    > .SignInForm-orSeparator {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      width: 100%;
      margin-bottom: 12px;

      hr {
        margin: 0;
        border-top: 1px solid black;
        border-bottom: none;
      }

      .${typographyClassNames.root} {
        margin: 0 18px;
      }
    }

    .SignInForm-facebookButton {
      margin-bottom: 6px;
    }

    .SignInForm-googleButton {
      margin-bottom: 6px;
    }

    .SignInForm-appleButton {
      margin-bottom: 20px;
    }

    .SignInForm-terms {
      margin-bottom: 10px;
    }

    .SignInForm-login .${linkClassNames.root} {
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
    }
  }
`;
