import { ActionNames, ActionType } from 'src/types/actions';
import { DBDocumentChannel, DBQueryChannel, ParserFunction } from 'src/types';
import { DB, Query } from 'src/services/DB';
import {
  CollectionListCollectionsKind,
  SingleCollectionKind,
} from './collection.model';
import {
  CollectionItemListKind,
  IsPurchasedKind,
  IsSavedKind,
} from './collectionItem.model';
import { ScrapeKind } from './scrape.model';
import { ProductKind } from './product.model';
import { SingleCollectionBookmarkKind } from './collectionBookmark.model';
import { SingleCollectionViewsKind } from './collectionViews.model';
import { UserPublicKind } from './userPublic.model';
import { UserVerifiedSettingsKind } from './userVerifiedSettings.model';
import { IsLikedOrDislikedKind } from './reaction.model';
import { CollectionEarningsListKind } from './creatorEarnings.model';
import { CollectionRegistryPrivateQueryKind } from './collectionRegistryPrivate.model';
import { CollectionRegistryNotesDocumentKind } from './collectionRegistryNotes.model';
import { CollectionListKind } from './collectionList.model';
import { CollectionImagesKind } from './collectionImages.model';

/*

STATES

The base states and kinds for all local database storage (taking type parameters).

*/
// Ways to store data in redux:
export enum ReducerType {
  DOC_MAP = 'DOC_MAP',
  DOC_SINGLE = 'DOC_SINGLE',
  QUERY_MAP = 'QUERY_MAP',
  QUERY_SINGLE = 'QUERY_SINGLE',
}

export interface BaseDocState<KindType> {
  stateKind: 'single';
  dBKind: 'doc';
  data: KindType | undefined | null;
  set: boolean;
  listener: DBDocumentChannel | null;
}

export interface BaseQueryState<KindType> {
  stateKind: 'single';
  dBKind: 'query';
  data: KindType[];
  set: boolean;
  listener: DBQueryChannel | null;
}

export interface BaseDocMapState<KindType> {
  stateKind: 'map';
  dBKind: 'doc';
  map: {
    [key: string]: KindType | undefined | null;
  };
  listeners: {
    [key: string]: DBDocumentChannel;
  };
}

export interface BaseQueryMapState<KindType> {
  stateKind: 'map';
  dBKind: 'query';
  map: {
    [key: string]: KindType[];
  };
  listeners: {
    [key: string]: DBQueryChannel;
  };
}

type SubStateType<K extends BaseDBKindsUnion> =
  K['reducer'] extends ReducerType.DOC_SINGLE
    ? BaseDocState<K['item']>
    : K['reducer'] extends ReducerType.QUERY_SINGLE
    ? BaseQueryState<K['item']>
    : K['reducer'] extends ReducerType.DOC_MAP
    ? BaseDocMapState<K['item']>
    : K['reducer'] extends ReducerType.QUERY_MAP
    ? BaseQueryMapState<K['item']>
    : null;

// root state structure can be calculated as follows:
export type RootDB = {
  [key in BaseDBKindNames]: SubStateType<BaseDBKindsLookup[key]>;
};
// base states for each kind:
export type DBBaseStatesUnion = RootDB[BaseDBKindNames];

/*

ACTIONS

*/
// Types for sending the action that sets up the saga.
export interface GetOrListenQueryActionPayload<T> {
  dB: DB;
  dBQuery: Query;
  parser: ParserFunction<T>;
}
export interface GetOrListenDocActionPayload<T> {
  dB: DB;
  dBRef: string;
  parser: ParserFunction<T>;
  server?: true;
}
// The action type for DB also includes the kind AND an optional storeAs parameter:
type BaseDBAdditionalAction<K extends BaseDBKindsUnion> = {
  storeAs: K['storeAs'];
  kind: K['name'];
};
// The action type for DB also includes the kind:
type BaseDBKindAdditionalAction<K extends BaseDBKindsUnion> = {
  kind: K['name'];
};

// Conditional on the reducer type:
export type StoreAsRequiredReducers =
  | ReducerType.DOC_MAP
  | ReducerType.QUERY_MAP;
// We can only access a K field in one parameterised type either side ofBaseDBKindsUnion the '&' otherwise we will allow mixing of the K types.
type DBActionType<T, P, K extends BaseDBKindsUnion> = ActionType<T, P> &
  BaseDBAdditionalAction<K>;
// No StoreAs required for general Unmount:
type DBActionUnmount<T, P, K extends BaseDBKindsUnion> = ActionType<T, P> &
  BaseDBKindAdditionalAction<K>;

// Used in the action dispatches to ensure that the types sent out are correct:
// Can avoid needing to write Action Creation Functions and keeping types checked in dispatch.
export interface BaseDBActionTypes<K extends BaseDBKindsUnion> {
  // Query actions:
  [ActionNames.BASEDB_SET_QUERY]: DBActionType<
    ActionNames.BASEDB_SET_QUERY,
    K['item'][],
    K
  >;
  [ActionNames.BASEDB_SET_LISTENER_QUERY]: DBActionType<
    ActionNames.BASEDB_SET_LISTENER_QUERY,
    DBQueryChannel,
    K
  >;
  [ActionNames.BASEDB_LISTEN_QUERY]: DBActionType<
    ActionNames.BASEDB_LISTEN_QUERY,
    GetOrListenQueryActionPayload<K['item']>,
    K
  >;
  [ActionNames.BASEDB_GET_QUERY]: DBActionType<
    ActionNames.BASEDB_GET_QUERY,
    GetOrListenQueryActionPayload<K['item']>,
    K
  >;
  // Document actions:
  [ActionNames.BASEDB_SET_DOC]: DBActionType<
    ActionNames.BASEDB_SET_DOC,
    K['item'] | undefined | null,
    K
  >;
  [ActionNames.BASEDB_SET_LISTENER_DOC]: DBActionType<
    ActionNames.BASEDB_SET_LISTENER_DOC,
    DBDocumentChannel,
    K
  >;
  [ActionNames.BASEDB_LISTEN_DOC]: DBActionType<
    ActionNames.BASEDB_LISTEN_DOC,
    GetOrListenDocActionPayload<K['item']>,
    K
  >;
  [ActionNames.BASEDB_GET_DOC]: DBActionType<
    ActionNames.BASEDB_GET_DOC,
    GetOrListenDocActionPayload<K['item']>,
    K
  >;
  // Both Document and Query actions:
  // Maps and non-maps:
  [ActionNames.BASEDB_UNLISTEN_SINGLE]: DBActionType<
    ActionNames.BASEDB_UNLISTEN_SINGLE,
    undefined,
    K
  >;
  // Only for maps:
  [ActionNames.BASEDB_UNLISTEN_MAP]: DBActionUnmount<
    ActionNames.BASEDB_UNLISTEN_MAP,
    undefined,
    K
  >;
}
// Used in reducer action switch type:
type BaseDBActionG<K extends BaseDBKindsUnion> =
  | BaseDBActionTypes<K>[ActionNames.BASEDB_SET_DOC]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_DOC]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_LISTEN_DOC]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_GET_DOC]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_SET_QUERY]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_QUERY]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_LISTEN_QUERY]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_GET_QUERY]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_UNLISTEN_SINGLE]
  | BaseDBActionTypes<K>[ActionNames.BASEDB_UNLISTEN_MAP];
// Specify:
export type BaseDBAction = BaseDBActionG<BaseDBKindsUnion>;

/*

Enforcing combinations of reducers + states

*/
// Base kind names:
export type BaseDBKindNames = keyof ItemisedKinds;
// All itemised kinds as a type:
type ItemisedKindsUnion = ItemisedKinds[BaseDBKindNames];
// merge a few extra fields in the lookup as below:
type KindType<N extends BaseDBKindNames, K extends ItemisedKindsUnion> = {
  name: N; // add kind name in type
  // Do this here to tie it in to the K type to enable proper typing in action creators:
  storeAs: K['reducer'] extends StoreAsRequiredReducers ? string : undefined;
};
// Convert to a lookup:
export type BaseDBKindsLookup = {
  [key in BaseDBKindNames]: ItemisedKinds[key] &
    KindType<key, ItemisedKinds[key]>;
};
// And the BaseDBKinds Union:
export type BaseDBKindsUnion = BaseDBKindsLookup[BaseDBKindNames];
// Structure of the saga fetching dictionary:
export type BaseDBFetchingDict = {
  [key in BaseDBKindNames]?: boolean | { [storeAs: string]: boolean };
};

/*
We define kinds so that we can define the types assigned to these kinds in the root db state.
This will be of the form:
db.[kind].{state}
where {state} is a type of state dependent on the type of reducer i.e. a map or a list or a single document.

Therefore the Kind must include the item type and the reducer state to use.
*/
export interface Kind {
  item: any;
  reducer: ReducerType;
}

/*

USER INPUT:

*/
interface ItemisedKinds {
  // Add kinds here:
  single_collection: SingleCollectionKind;
  single_collection_bookmark: SingleCollectionBookmarkKind;
  single_collection_views: SingleCollectionViewsKind;
  single_collection_item_list: CollectionItemListKind;
  single_collection_scrape: ScrapeKind;
  single_collection_product: ProductKind;
  single_collection_product_is_purchased: IsPurchasedKind;
  single_collection_product_is_saved: IsSavedKind;
  single_collection_product_is_liked_or_disliked: IsLikedOrDislikedKind;
  user_public: UserPublicKind;
  user_verified_settings: UserVerifiedSettingsKind;
  collection_earnings_list: CollectionEarningsListKind;
  collection_registry_private: CollectionRegistryPrivateQueryKind;
  collection_registry_notes: CollectionRegistryNotesDocumentKind;
  collection_list: CollectionListKind;
  collection_list_map: CollectionListCollectionsKind;
  collection_images_map: CollectionImagesKind;
}
