import {
  createBrowserHistory,
  BrowserHistoryBuildOptions,
  History,
  LocationDescriptor,
} from 'history';

import R from 'src/routes';
import { searchParamKeeplist, hashParamKeeplist } from 'src/config';
import { getLocationDescriptor } from 'src/utils/getLocationDescriptor';
import { keepParams } from 'src/utils/keepParams';

function customCreateBrowserHistory<S = unknown>(
  options?: BrowserHistoryBuildOptions | undefined,
): History<S> {
  const history = createBrowserHistory<S>(options);

  // Keep the initial history location key so that we can know when was the
  // webapp loaded.
  const initialLocationKey = history.location.key;

  const push = (locationDescriptor: LocationDescriptor<S>, state?: S): void => {
    const { location } = history;
    const { search: currentSearch, hash: currentHash } = location;

    const currentSearchParams = new URLSearchParams(currentSearch);
    const currentHashParams = new URLSearchParams(currentHash.slice(1));

    const {
      pathname: linkPathname,
      search: linkSearch,
      hash: linkHash,
      state: linkState,
    } = getLocationDescriptor<S>(location, locationDescriptor);

    const linkSearchParams = new URLSearchParams(linkSearch);
    const linkHashParams = new URLSearchParams(linkHash);

    // Keep those current search parameters that are in `searchParamKeeplist`
    // and are not provided by the next location descriptor
    keepParams(currentSearchParams, linkSearchParams, searchParamKeeplist);

    // And also those current hash parameters that are in `hashParamKeeplist`
    // and are not provided by the next location descriptor
    keepParams(currentHashParams, linkHashParams, hashParamKeeplist);

    history.push({
      pathname: linkPathname,
      search: linkSearchParams.toString(),
      hash: linkHashParams.toString(),
      state: linkState ?? state,
    });
  };

  const goBack = (): void => {
    // TODO This would be the best approach once we move to history@5+:
    // If the current location is at the beginning of the history stack:
    //   1. push a clone of the current location just after it,
    //   2. go back the bottom of the stack,
    //   3. and replace the bottom of the stack with the home page keeping the
    //      original state and those search and hash params that arein the
    //      keeplists.
    // INTERIM SOLUTION:
    // If the current location is at the beginning of the history stack, replace
    // current location with the homepage while keeping the search and hash
    // parameters in the keeplists.
    if (history.location.key === initialLocationKey) {
      const { location } = history;
      const { search, hash, state } = location;

      const currentSearchParams = new URLSearchParams(search);
      const currentHashParams = new URLSearchParams(hash.slice(1));

      // Keep those current search parameters that are in `searchParamKeeplist`
      const finalSearchParams = new URLSearchParams();
      keepParams(currentSearchParams, finalSearchParams, searchParamKeeplist);

      // And also those current hash parameters that are in `hashParamKeeplist`
      const finalHashParams = new URLSearchParams();
      keepParams(currentHashParams, finalHashParams, hashParamKeeplist);

      history.replace({
        pathname: R.HOME,
        search: finalSearchParams.toString(),
        hash: finalHashParams.toString(),
        state,
      });
    } else {
      history.goBack();
    }
  };

  return {
    ...history,
    push,
    goBack,
  };
}

export { customCreateBrowserHistory as createBrowserHistory };
