import { VFC } from 'react';

import { PeopleSayingCarouselContainerProps } from './PeopleSayingCarousel.types';
import { PeopleSayingCarousel } from './PeopleSayingCarousel.component';
import { slides } from './slides';

const PeopleSayingCarouselContainer: VFC<PeopleSayingCarouselContainerProps> = (
  props,
) => {
  const slideType = props.type ?? 'standard';
  return <PeopleSayingCarousel {...props} slides={slides[slideType]} />;
};

export { PeopleSayingCarouselContainer as PeopleSayingCarousel };
