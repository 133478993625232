import { ThunkAction } from 'redux-thunk';
import { CallHistoryMethodAction } from 'connected-react-router';

import { RootState } from 'src/index';
import { ProductDataAction } from '.';
import { AuthAction } from './models/auth.model';
import { BaseDBAction } from './models/basedb.model';
import { FooterPopupAction } from './models/footerPopup.model';
import { ModalAction } from './models/modal.model';
import { WorkingAction } from './models/working.model';
import { CreatorApplicationAction } from './models/creatorApplication.model';
import { StylistApplicationAction } from './models/stylistApplication.model';
import { CreatorPayoutDetailsAction } from './models/creatorPayoutDetails.model';
import { CollectionRegistryUiAction } from './models/collectionRegistryUi.model';
import { Services } from 'src/utils/setupServices';

// All actions are here to avoid conflicts:
export enum ActionNames {
  UNSET_ALL = 'UNSET_ALL',
  AUTH_SET = 'AUTH_SET',
  UI_WINDOW_SIZE_SET = 'UI_WINDOW_SIZE_SET',
  UI_SIGNED_UP_IN_SESSION_SET = 'UI_SIGNED_UP_IN_SESSION_SET',
  UI_SIGNED_UP_IN_SESSION_UNSET = 'UI_SIGNED_UP_IN_SESSION_UNSET',
  UI_CLOSE_REVIEW_REQUEST = 'UI_CLOSE_REVIEW_REQUEST',
  UI_SET_SHOW_REVIEW_REQUESTS = 'UI_SET_SHOW_REVIEW_REQUESTS',
  UI_AUTO_TRIGGER_REVIEW_REQUEST = 'UI_AUTO_TRIGGER_REVIEW_REQUEST',
  START_KEEP_ON_PROVISIONING_PAGE = 'START_KEEP_ON_PROVISIONING_PAGE',
  END_KEEP_ON_PROVISIONING_PAGE = 'END_KEEP_ON_PROVISIONING_PAGE',
  // only used with qualifying "kind" property:
  BASEDB_SET_DOC = 'BASEDB_SET_DOC',
  BASEDB_SET_LISTENER_DOC = 'BASEDB_SET_LISTENER_DOC',
  BASEDB_LISTEN_DOC = 'BASEDB_LISTEN_DOC',
  BASEDB_GET_DOC = 'BASEDB_GET_DOC',
  BASEDB_SET_QUERY = 'BASEDB_SET_QUERY',
  BASEDB_SET_LISTENER_QUERY = 'BASEDB_SET_LISTENER_QUERY',
  BASEDB_LISTEN_QUERY = 'BASEDB_LISTEN_QUERY',
  BASEDB_GET_QUERY = 'BASEDB_GET_QUERY',
  BASEDB_UNLISTEN_MAP = 'BASEDB_UNLISTEN_MAP',
  BASEDB_UNLISTEN_SINGLE = 'BASEDB_UNLISTEN_SINGLE',
  // product data parsed from db:
  PRODUCT_DATA_ADD = 'PRODUCT_DATA_ADD',
  PRODUCT_DATA_REMOVE = 'PRODUCT_DATA_REMOVE',
  // footer popup:
  FOOTER_POPUP_SET = 'FOOTER_POPUP_SET',
  // modal:
  MODAL_SET = 'MODAL_SET',
  MODAL_CLOSE = 'MODAL_CLOSE',
  // working:
  WORKING_SET = 'WORKING_SET',
  WORKING_UNSET = 'WORKING_UNSET',
  // user-set-up:
  SUCCESSFULLY_LOADED_PROVISIONED_USER_DATA = 'SUCCESSFULLY_LOADED_PROVISIONED_USER_DATA',
  BEGAN_SIGN_IN = 'BEGAN_SIGN_IN',
  BLOCK_LOADING_USER_DATA = 'BLOCK_LOADING_USER_DATA',
  UNBLOCK_LOADING_USER_DATA = 'UNBLOCK_LOADING_USER_DATA',
  SET_RUN_SETUP_AGAIN = 'SET_RUN_SETUP_AGAIN',
  // creator application:
  UPDATE_CREATOR_APPLICATION = 'UPDATE_CREATOR_APPLICATION',
  UPDATE_CREATOR_APPLICATION_ERROR_MESSAGE = 'UPDATE_CREATOR_APPLICATION_ERROR_MESSAGE',
  RESET_CREATOR_APPLICATION = 'RESET_CREATOR_APPLICATION',
  // stylist application request:
  UPDATE_STYLIST_APPLICATION_REQUEST = 'UPDATE_STYLIST_APPLICATION_REQUEST',
  UPDATE_STYLIST_APPLICATION_ERROR_MESSAGE = 'UPDATE_STYLIST_APPLICATION_ERROR_MESSAGE',
  RESET_STYLIST_APPLICATION_REQUEST = 'RESET_STYLIST_APPLICATION_REQUEST',
  // creator payout request:
  UPDATE_CREATOR_PAYOUT_DETAILS = 'UPDATE_CREATOR_PAYOUT_DETAILS',
  RESET_CREATOR_PAYOUT_DETAILS = 'RESET_CREATOR_PAYOUT_DETAILS',
  // Collection Registry UI:
  COLLECTION_REGISTRY_UI_IS_REVEALING_TOGGLE = 'COLLECTION_REGISTRY_UI_IS_REVEALING_TOGGLE',
}

// All actions:
export interface ActionType<T, P> {
  type: T;
  payload: P;
}

type UnsetAllAction = ActionType<ActionNames.UNSET_ALL, undefined>;

// So that we can have the overall action type as the input to all redux functions.
export type Action =
  | CallHistoryMethodAction
  | BaseDBAction
  | AuthAction
  | UnsetAllAction
  | ProductDataAction
  | FooterPopupAction
  | ModalAction
  | WorkingAction
  | CreatorApplicationAction
  | StylistApplicationAction
  | CreatorPayoutDetailsAction
  | CollectionRegistryUiAction;

// Serves to type thunk action creators in an easier manner
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  { services: Services },
  Action
>;
