/*
Logout page.

Redirects you after logging out so just renders loading while doing that.

TODO: typescript, camelCase, make functional, non-default exports, redux hooks

*/
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withFirebase } from 'src/mvp22/Firebase';
import {
  actionCreator,
  FIRESTORE_REDUX_REMOVE_ALL,
} from 'src/mvp22/redux-components/actions';
import { ServicesContext } from 'src/ServicesContext';
import Loading from './Loading';

/**
 * Logout Page Component (Special)
 */
class Logout extends Component {
  static contextType = ServicesContext;

  componentDidMount() {
    const { auth, analytics } = this.context;

    this._isMounted = true;
    // SYNC!
    // unset all the listeners FIRST:
    analytics.recordEvent('WebApp:Logout');
    this.props.logout();
    analytics.logout();
    this.props.firebase.doSignOut().then(() => {
      // ASYNC!
      if (window && window.ReactNativeWebView) {
        auth.logoutApp();
      }
      this.props.history.push('/');
    });
  }

  render() {
    return <Loading />;
  }
}

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (event) =>
      dispatch(actionCreator(FIRESTORE_REDUX_REMOVE_ALL, event)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withFirebase(Logout)),
);
