import Bowser from 'bowser';

/**
 * Returns a boolean indicating whether we are on an iOS device.
 */
export function checkIfIOS(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }
  const browserParser = Bowser.getParser(global.window?.navigator.userAgent);
  return browserParser.getOSName(true) === 'ios';
}
