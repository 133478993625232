import type * as React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Paper,
  Typography,
  Stack,
  Checkbox,
  switchBaseClassNames,
  linkClassNames,
  checkboxUncheckedIconClassNames,
  checkboxCheckedIconClassNames,
} from '@moonsifttech/design-system';

import { GetStartedChecklistItemProps } from './GetStartedChecklistItem.types';

const GetStartedChecklistItem: React.FC<GetStartedChecklistItemProps> = ({
  className,
  style,
  name,
  title,
  description,
  checked,
  onChange,
}) => {
  return (
    <Paper
      className={clsx(className, checked && 'GetStartedChecklistItem-checked')}
      style={style}
      variant="outlined"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={8}
      >
        <Stack direction="column">
          <Typography variant="primary.m16">{title}</Typography>
          <Typography variant="primary.r14">{description}</Typography>
        </Stack>
        <Checkbox name={name} checked={checked} onChange={onChange} />
      </Stack>
    </Paper>
  );
};

const StyledGetStartedChecklistItem = styled(GetStartedChecklistItem)`
  padding: 12px 12px 12px 16px;
  border: ${({ theme }) => theme.fns.getBorder('primary')};

  &.GetStartedChecklistItem-checked {
    background: var(--common-black);
    color: var(--common-white);
  }

  .${switchBaseClassNames.checked} {
    color: var(--common-white);
  }

  .${checkboxUncheckedIconClassNames.root},
    .${checkboxCheckedIconClassNames.root} {
    font-size: 32px;
  }

  .${linkClassNames.root} {
    color: currentColor;
  }
`;

export { StyledGetStartedChecklistItem as GetStartedChecklistItem };
