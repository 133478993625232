/*

This page is a place for users to request to become "tastemakers" (now stylists!)

TODO: typescript, camelCase, make functional, non-default exports, redux hooks, design system, split container / component ...
Change names from tastemakers to stylists
*/
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import queryString from 'query-string';
import {AddHelmetTitle} from "src/mvp22/core-components/helmet";
import {M} from 'src/mvp22/constants';
import {withFirebase} from 'src/mvp22/Firebase';
import Balls22 from 'src/mvp22/image-components/Balls22';
import Button from 'src/mvp22/form-components/Button';
import LoadingDisplay from 'src/mvp22/image-components/LoadingDisplay';
import TextBox from 'src/mvp22/form-components/TextBox';
import R from 'src/routes';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import {FullBlank} from 'src/mvp22/menu-components/TopMenu';
import check_overall_validity from "src/mvp22/core-components/check_overall_validity";
import add_tastemaker_app from 'src/mvp22/firebase-functions/add_tastemaker_app';

const Container = styled.div`
  background-color:${M.COL.BG.WHITE};
  color:${M.COL.TEXT.BLACK};
  padding-left:30px;
  padding-right:30px;
  display:flex;
  flex-direction:column;
  min-width:${M.MINWIDTH};
  height:100vh;
  box-sizing:border-box;
  padding-top:${M.HEIGHTS.TOPMENU};
  align-items:center;

  -webkit-font-smoothing: subpixel-antialiased;
`;

const MainContainer = styled.div`
  text-align:left;
  box-sizing:border-box;
  width:100%;
  max-width:660px;
`;

const MainContainerCentral = styled.div`
  text-align:center;
  box-sizing:border-box;
  display:flex;
  flex-direction:column;
  align-items: center;
  width:100%;

`;
const MainContainerPrePost = styled.div`
  text-align:left;
  box-sizing:border-box;
  width:50%;
  min-width:330px;
  max-width:340px;
`;

const TitleText = styled(TEXTSTYLE.OTHER1)`
  margin-top:26px;
`;

const VerticalSeparatorPreField = styled.div`
  height:15px;
`;

const VerticalSeparatorBottom = styled.div`
  height:26px;
`;

const Seperator = styled.div`
  height:${props=>props.height};
`;

const TopBarRuleContainer = styled.div`
  width:100%;
  max-width:660px;
`;

const TopBarContainer = styled.div`
  position:relative;
`;

const TopBar = styled.div`
  height:64px;
  display:flex;
  justify-content:center;
  align-items:center;
  color:${M.COL.TEXT.MID};
  flex-direction:row;
`;

const BackImg = styled.img`
  position:absolute;
  left:0px;
  cursor:pointer;
`;

const OverviewText = styled(TEXTSTYLE.BRANDED2)`
  text-align: center;
  color:default;
`;

const RightTopBar = styled.div`
  position:absolute;
  right:0px;
`;

const HorizontalRule = styled.div`
  height:0.5px;
  background-color:${M.COL.LINE.MID};
`;

const OverallFeedback = styled(TEXTSTYLE.BODY5)`
  height:24px;
  display:flex;
  justify-content:center;
  align-items:center;
  color:${M.COL.TEXT.ERROR};
  text-align:center;
`;

const TickBox = styled.img`
  height:16px;
  margin-right: 10px;
  position: relative;
  top: 3px;
  cursor:pointer;
`;

const PreferenceContainer = styled(TEXTSTYLE.LINKA)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 15px;
  cursor:pointer;
`;

const PreferenceDescContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PreferenceTitle = styled(TEXTSTYLE.BODY4)`
  text-align: left;
`;

const SUBSCRIPTION_ARRAY = [
  "Personal Stylist",
  "Personal Shopper",
  "Fashion Buyer",
  "Visual Merchandiser",
  "Store Owner / Manager",
  "Designer",
  "Fashion Writer / Editor / Blogger",
  "Other"
];

const DEFAULT_TMAPPLY = {
  fields:{
    primarybenefit:["",true,""]
  },
  featureme:false,
  formFeedback:"",
  subscriptions:Array(SUBSCRIPTION_ARRAY.length).fill(false)
};

/**
* TasteMakersApply Moonsift Component
*/
class TasteMakersApply extends Component {
  constructor(props){
    super(props);
    this.getMainContent = this.getMainContent.bind(this);
    this.getCurPage = this.getCurPage.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.goForward = this.goForward.bind(this);
    this.fieldChange = this.fieldChange.bind(this);
    this.toggleFeatureMe = this.toggleFeatureMe.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this.updateErrors = this.updateErrors.bind(this);
    this.state = DEFAULT_TMAPPLY;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({
      post:queryString.parse(this.props.location.search).post
    });
    this.componentDidUpdate();
  }

  componentDidUpdate(){
    if (this.props.firebaseState.loaded){
      this.setState(
        (prevState,props)=>{
          if (props.firebaseState.loaded===true && prevState.auth_user_uid !==props.authUserUID){
            const postString = queryString.parse(this.props.location.search).post;
            if(!props.authUserUID && postString!=="getcex"){
              this.props.history.push({pathname:R.SIGNUP,search:"?post=tastemakers"});
            }
            return(
              {
                auth_user_uid:props.authUserUID,
                auth_user_uid_set:true
              }
            );
          }
        }
      );
    }
  }

  resetForm(){
    this._isMounted && this.setState(DEFAULT_TMAPPLY);
  }

  updateErrors(err){
    this._isMounted && this.setState({formFeedback:err});
  }

  toggleTick(index){
    this.setState(
      prevState=>{
        const subscriptions = [...prevState.subscriptions];
        subscriptions[index] = !subscriptions[index];
        return(
          {
            subscriptions
          }
        );
      }
    );
  }

  toggleFeatureMe(event){
    event.preventDefault();
    this.setState(
      prevState=>{
        return(
          {
            featureme:!prevState.featureme
          }
        );
      }
    );
    return false;
  }

  fieldChange(event){
    const this_event_target_value = [event.target.value,true,""];
    const this_event_target_name = event.target.name;
    this.setState(
        (prevState)=>{
        return({
          fields:{
            ...prevState.fields,
            [this_event_target_name]:this_event_target_value
          }
        });
      }
    );
  }

  // ASYNC
  doSubmit(event){
    event.preventDefault();
    if (check_overall_validity(this.state.fields)===true){
      add_tastemaker_app(
        {
          type:this.state.subscriptions.map(
            (x,i)=>(x===true)?SUBSCRIPTION_ARRAY[i]:false).filter(x=>x!==false).join(", "),
          primarybenefit:this.state.fields.primarybenefit[0],
          feature_me:this.state.featureme
        },
        this.props.firebase,
        this.props.authUserUID
      ).then(
        () => {
          this._isMounted && this.resetForm();
          this._isMounted && this.goForward();
        }
      ).catch(error => {
          this._isMounted && this.setState(
            {
              formFeedback:error.message
            }
          );
        }
      );
    }
    else{
      this.updateErrors("Please check for errors and try again.");
    }
  }

  goForward(event){
    if (event){
      event.preventDefault();
    }
    const newPage = this.getCurPage()+1;
    this.props.history.push({search:"?page="+newPage});
  }

  getCurPage() {
    var curpage = parseInt(queryString.parse(this.props.location.search).page,10);
    if(isNaN(curpage) || curpage<2){
      curpage=2;
    }
    return curpage;
  }

  getMainContent(){
    const CURPAGE=this.getCurPage();
    switch(CURPAGE){
      case 2:{
        return(
          <MainContainer>
            <TitleText>Which of the following best represents you?</TitleText>
            <VerticalSeparatorPreField/>
            {
              SUBSCRIPTION_ARRAY.map(
                (item,index)=>
                <PreferenceContainer href="#" key={index} onClick={
                  (event)=>{
                    event.preventDefault();
                    this.toggleTick(index,event);
                    return false;
                  }
                } >
                  <TickBox src={this.state.subscriptions[index] === true ? MEDIA.TICKBOXFULL : MEDIA.TICKBOXEMPTY}/>
                  <PreferenceDescContainer>
                    <PreferenceTitle>{item}</PreferenceTitle>
                  </PreferenceDescContainer>
                </PreferenceContainer>
              )
            }
            <Button
              text="Next"
              msstyle="bright"
              onClick={this.goForward}
              width="100%"
            />
            <VerticalSeparatorBottom/>
          </MainContainer>
        );
      }
      // Add a key to enable regeneration + focussing...!
      case 3:{
        return(
          <MainContainer>
            <TitleText>What is the primary benefit of using Moonsift for you?</TitleText>
            <VerticalSeparatorPreField/>
            <TextBox
              placeholder="..."
              msstyle="standard"
              onChange={this.fieldChange}
              name="primarybenefit"
              value={this.state.primarybenefit}
              width="100%"
              height="100px"
              focusOnMount={true}
            />
            <VerticalSeparatorPreField/>
            <Button
              text="Next"
              msstyle="bright"
              onClick={this.goForward}
              width="100%"
            />
            <VerticalSeparatorBottom/>
          </MainContainer>
        );
      }
      case 4:{
        return(
          <MainContainer>
            <TitleText>Would you be interested in curating a collection to feature on Moonsift?</TitleText>
            <VerticalSeparatorPreField/>
            <PreferenceContainer onClick={this.toggleFeatureMe} href="#">
              <TickBox src={this.state.featureme === true ? MEDIA.TICKBOXFULL : MEDIA.TICKBOXEMPTY}/>
              <PreferenceDescContainer>
                <PreferenceTitle>Yes, please</PreferenceTitle>
              </PreferenceDescContainer>
            </PreferenceContainer>
            <Button
              text="Sign up"
              msstyle="bright"
              onClick={this.doSubmit}
              enabled={true}
              width="100%"
            />
            <OverallFeedback>
              {this.state.formFeedback}
            </OverallFeedback>
            <VerticalSeparatorBottom/>
          </MainContainer>
        );
      }
      case 5:{
        return(
            <MainContainerCentral>
              <TEXTSTYLE.BRANDED1>
                THANK YOU
              </TEXTSTYLE.BRANDED1>
              <TEXTSTYLE.BRANDED2>
                CURATORS & STYLISTS
              </TEXTSTYLE.BRANDED2>
              <Seperator height="34px"/>
              <MainContainerPrePost>
                <TEXTSTYLE.BODY3>
                  We are working hard to make Moonsift a more useful platform for everyone working in the fashion industry.
                </TEXTSTYLE.BODY3>
                <Seperator height="12px"/>
                <TEXTSTYLE.BODY3>
                  If you said you would be interested in featuring a collection on Moonsift we will get in touch via email.
                </TEXTSTYLE.BODY3>
                <Seperator height="12px"/>
                <TEXTSTYLE.BODY3>
                  If you have additional feedback on how we could improve the experience for you, please feel free to get in touch at hello@moonsift.com
                </TEXTSTYLE.BODY3>
                <Seperator height="34px"/>
                  <TEXTSTYLE.LINK to={(this.state.post==="getcex")?R.POSTSIGNUP:R.HOMEPAGE}>
                    <Button
                     text="Done"
                     msstyle="cta1st_mobile"
                     width="100%"
                    />
                  </TEXTSTYLE.LINK>
                <Seperator height="12px"/>
              </MainContainerPrePost>
            </MainContainerCentral>
        );
      }
      default:{
        return ("");
      }
    }
  }

  getTopBar(){
    const curPage = this.getCurPage();
    if (curPage>1 && curPage <5){
      const STEP = curPage-1;
      return(
        <TopBarRuleContainer>
        <TopBarContainer>
          <TopBar>
            <BackImg
              title="Go back"
              src={MEDIA.MENUARROW}
              onClick={this.props.history.goBack}
            />
            <OverviewText>
              CURATORS AND STYLISTS
            </OverviewText>
            <RightTopBar>
              <Balls22
                title={"Step "+STEP+" of 3"}
                diameter="9px"
                sep="5px"
                count={3}
                value={STEP}
                msstyle="dark"
              />
            </RightTopBar>
          </TopBar>
        </TopBarContainer>
        <HorizontalRule/>
        </TopBarRuleContainer>
      );
    }
    else {
      return "";
    }
  }

  render(){

    return(
      <FullBlank>
        <TopMenu/>
        {AddHelmetTitle("Curators and Stylists")}
        <Container>
        {
          (this.state.auth_user_uid_set===true)?
            <>
            {this.getTopBar()}
            {this.getMainContent()}
            </>
          :
            <LoadingDisplay message = "Loading..." />
        }
        </Container>
      </FullBlank>
    );
  }
}

// Pass the state values into the component:
const mapStateToProps = (state) => {
  return ({
    firebaseState:state.firebasestate,
    authUserUID:state.auth.id
  });
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    withRouter(
      withFirebase(
        TasteMakersApply
      )
    )
  )
);
