import { VFC, useContext, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { OutgoingMessageType } from 'src/services/Mobile';
import { useDBAddDoc } from 'src/hooks/useDBAddDoc';
import {
  CreateCollectionContentContainerProps,
  CreateCollectionContentProps,
  CollectionTypeOptionKey,
} from './CreateCollectionContent.types';
import { CreateCollectionContent } from './CreateCollectionContent.component';
import { CollectionDocument } from 'src/types/models/collection.model';
import { getPublicLevel } from 'src/utils/getPublicLevel';

const CreateCollectionContentContainer: VFC<
  CreateCollectionContentContainerProps
> = (props) => {
  const { db, mobile } = useContext(ServicesContext);

  // Handle collection type change
  const [selectedCollectionType, setSelectedCollectionType] =
    useState<CollectionTypeOptionKey>('private');
  const [isRegistry, setIsRegistry] = useState<boolean>(false);
  const handleCollectionTypeChange = useCallback<
    CreateCollectionContentProps['onCollectionTypeChange']
  >((_event, collectionType) => {
    if (collectionType) {
      setSelectedCollectionType(collectionType);
    }
  }, []);

  // Handle collection creation
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const displayUsername = useSelector<RootState, boolean>(
    (state) => state.firestore_user_owner.display_username,
  );
  const [dbAddDoc, working] = useDBAddDoc<CollectionDocument>(
    db,
    db.userCollectionList(authUserUID ?? ''),
  );
  const history = useHistory();
  const handleCollectionCreation = useCallback<
    CreateCollectionContentProps['onCollectionCreation']
  >(
    (event) => {
      event.preventDefault();

      const name = event.currentTarget.name.value;
      const description = event.currentTarget.description?.value;
      const createdAt = db.serverTimestamp();

      const collectionDocument: CollectionDocument = {
        created_at: createdAt,
        last_updated: createdAt,
        public_level: getPublicLevel(selectedCollectionType, isRegistry),
        like_count: 0,
        user_uid: authUserUID!,
        is_registry: isRegistry,
        registry_public_level: 8,
        name,
      };

      if (description) {
        collectionDocument.description = description;
      }

      dbAddDoc(collectionDocument).then((document) => {
        // After creating a collection, the user is expected to want to go back
        // to My Collections instead of back to creating collections.
        history.replace(`/@${displayUsername}`);
        history.push(`/collection/${displayUsername}/${document.id}`);
      });
    },
    [
      db,
      selectedCollectionType,
      isRegistry,
      authUserUID,
      dbAddDoc,
      history,
      displayUsername,
    ],
  );

  const toggleIsRegistry = useCallback(
    () => setIsRegistry((state) => !state),
    [],
  );

  // When in app, indicate the webapp is loaded to the app
  useEffect(() => {
    if (mobile.isApp) {
      mobile.postMessage(OutgoingMessageType.Ready);
    }
  }, [mobile]);

  return (
    <CreateCollectionContent
      working={working}
      selectedCollectionType={selectedCollectionType}
      onCollectionTypeChange={handleCollectionTypeChange}
      onCollectionCreation={handleCollectionCreation}
      isRegistry={isRegistry}
      toggleIsRegistry={toggleIsRegistry}
      {...props}
    />
  );
};

export { CreateCollectionContentContainer as CreateCollectionContent };
