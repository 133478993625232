import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  accordionSummaryClassNames,
  ChevronDownIcon,
} from '@moonsifttech/design-system';

import R from 'src/routes';
import { Page } from 'src/components/common/Page';
import { Link } from 'src/components/core/Link';
import { CreatorFaqProps } from './CreatorFaq.types';

const UnstyledCreatorFaq: VFC<CreatorFaqProps> = ({ className, style }) => {
  return (
    <Page className={className} style={style} showNavControls>
      <div className="CreatorFaq-innerContainer">
        <Typography
          className="CreatorFaq-heading"
          variant="primary.b34"
          component="h1"
        >
          Support & FAQ
        </Typography>
        <Typography variant="primary.b26" component="h2" color="gray.2">
          Adding external affiliate links to your collections
        </Typography>
        <Accordion>
          <AccordionSummary
            id="how-do-i-add-items-from-affiliates-from-others-header"
            aria-controls="how-do-i-add-items-from-affiliates-from-others-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              How do I add affiliate links from other platforms?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              There are two easy ways to add affiliate links from LTK, Magic
              Link, Amazon Associates or any other affiliate link provider to
              your collections.
            </Typography>
            <Typography variant="primary.l16" component="p">
              Option 1:
              <br />
              1. Go to one of your collections
              <br />
              2. Click 'Add item'
              <br />
              3. Paste your affiliate link and Moonsift will auto-generate all
              the product info
            </Typography>
            <Typography variant="primary.l16" component="p">
              Option 2:
              <br />
              1. Add the item to your collection using the iOS app or Browser
              extension
              <br />
              2. Click on the item and select 'Edit'
              <br />
              3. Replace the default link with the your affiliate link
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            id="where-do-i-track-those-commissions-header"
            aria-controls="where-do-i-track-those-commissions-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              Where do I track those commissions?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              If you are inserting your own affiliate links e.g. from LTK, Magic
              Link, Amazon Associates or elsewhere, you will track the
              commissions on the relevant platform.
            </Typography>
            <Typography variant="primary.l16" component="p">
              When you use Moonsift's affiliate network, you will track that
              here.
            </Typography>
            <Typography variant="primary.l16" component="p">
              No matter who the affiliate is you can track all the 'click' data
              here on Moonsift.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography
          className="CreatorFaq-subheading"
          variant="primary.b26"
          component="h2"
          color="gray.2"
        >
          Using Moonsift's affiliate network
        </Typography>
        <Accordion>
          <AccordionSummary
            id="how-do-i-add-items-from-affiliates-header"
            aria-controls="how-do-i-add-items-from-affiliates-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              How do I add items from affiliates?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              To add items from Moonsift's list of Affiliate brands, simply
              visit their website and save the item to your collection.
            </Typography>
            <Typography variant="primary.l16" component="p">
              The commission is set up and tracked back to your Moonsift account
              automatically.
            </Typography>
            <Typography variant="primary.l16" component="p">
              <Link to={R.HOW_TO_SAVE}>Learn how to save items.</Link>
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="how-commissions-works-header"
            aria-controls="how-commissions-works-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              How do commissions work?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              The commission you earn is a percentage of the total sale value.
              If the shopper buys more than one item from that retailer, you are
              eligible to earn a percentage of their entire basket, not just the
              item they clicked through from 🙌.
            </Typography>
            <Typography variant="primary.l16" component="p">
              Commission rates vary by brand, but typically range from 5-15% of
              the sale value. Brands can also vary their commission rates by
              product line and region.
            </Typography>
            <Typography variant="primary.l16" component="p">
              Moonsift does not take a cut of your commission. We pass on the
              full amount we receive.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="commissions-header"
            aria-controls="commissions-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              Commission rates by region
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              On the <Link to={R.AFFILIATE_BRANDS}>Affiliate brands list</Link>{' '}
              you will see countries listed under some brands. Other brands will
              have separate listings for each country e.g. Adidas UK, Adidas
              Canada etc.
            </Typography>
            <Typography variant="primary.l16" component="p">
              If someone based in one of these countries buys from your
              collection you will be eligible for commission.
            </Typography>
            <Typography variant="primary.l16" component="p">
              You do not personally need to be based in these countries to earn.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="start-earning-header"
            aria-controls="start-earning-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              What do I need to do for items in my collection to be eligible for
              commission?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              Nothing.
              <br />
              When you add a product to Moonsift from one of our{' '}
              <Link to={R.AFFILIATE_BRANDS}>Affiliate brands</Link>, you are
              automatically set up to earn commission when people click through
              and buy.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="payment-header"
            aria-controls="payment-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              How will I receive payment?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              Payments are sent to you via PayPal using your email address. You
              will be asked to confirm which email address Moonsift should send
              payment to after clicking the 'Request payout' button.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="pending-earnings-header"
            aria-controls="pending-earnings-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              How long can earnings remain 'Open'?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              Earnings remain open until we actually receive the payment from
              the brand. As soon as we receive the commission from the brand, we
              move your earnings from 'Open' to 'Due'.
            </Typography>
            <Typography variant="primary.l16" component="p">
              Most brands do not pay commissions for <b>92+</b> days after the
              transaction date. For many, this period can be longer.{' '}
              <b>Up to 8 months</b> in the worst cases.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="due-earnings-header"
            aria-controls="due-earnings-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              When will I be paid my 'Due' earnings?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              Due earnings are available for payout when the amount reaches £50
              or more. When you request a payout, it will be sent via PayPal in
              the first week of the upcoming month.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            id="why-decreased-header"
            aria-controls="why-decreased-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              Why have my 'Open' or 'Due' earnings decreased?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              The most common reason is that one or more items that were bought
              from your collection are returned by the customer. In this case
              the brand will cancel the commission and as a result your 'Open'
              or 'Due' earnings will go down.
            </Typography>
            <Typography variant="primary.l16" component="p">
              Returns are not the only reason commissions are rescinded. Please
              see “Why haven't I been attributed with a sale” below for more
              info.
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion className="CreatorFaq-lastAccordion">
          <AccordionSummary
            id="not-attributed-header"
            aria-controls="not-attributed-content"
            expandIcon={<ChevronDownIcon size="small" />}
          >
            <Typography variant="primary.m16" component="h3">
              Why haven't I been attributed with a sale?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="primary.l16" component="p">
              Please note that it can take up to 7 days for a sale appear as
              'Open' in your My Earnings page. The best day to check is Monday.
            </Typography>
            <Typography variant="primary.l16" component="p">
              There are a several reasons why a retailer may not always credit
              you with a sale:
            </Typography>
            <Typography variant="primary.l16" component="p">
              1. Some retailers do not pay commission on all products, excluding
              specific product categories or brands from their commission
              programme.
            </Typography>
            <Typography variant="primary.l16" component="p">
              2. Another marketing channel (e.g. a Facebook Ad or email
              newsletter) got the last click. In the time between clicking
              through from your Collection and buying the item, the customer may
              have clicked through to the retailer's site via another link. If
              so you may not have been their last click.
            </Typography>
            <Typography variant="primary.l16" component="p">
              3. The user might be based in a country not supported by the
              retailer's commission program. Brands do not pay commission on
              transactions in every region. Check the{' '}
              <Link to={R.AFFILIATE_BRANDS}>Affiliate brands</Link> list for
              more info.
            </Typography>
            <Typography variant="primary.l16" component="p">
              4. The user may be using an ad-blocking software that blocks
              cookies on their browser or cleared their cookies before purchase.
              Most of the time this shouldn't matter though.
            </Typography>
            <Typography variant="primary.l16" component="p">
              5. The retailer cancelled the commission because the item was
              returned or cancelled by the customer. Retailers may also cancel
              commissions if it was a duplicate purchase (e.g. if the shopper
              had previously purchased and returned the item).
            </Typography>
            <Typography variant="primary.l16" component="p">
              6. Some retailers do not pay 'self-referral' i.e. when you are
              referring yourself.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography variant="primary.l16" component="p">
          Go to our <Link to={R.HOW_TO_SAVE}>Help & FAQs</Link> for all other
          questions relating to using Moonsift
        </Typography>
      </div>
    </Page>
  );
};

export const CreatorFaq = styled(UnstyledCreatorFaq)`
  .CreatorFaq-innerContainer {
    max-width: 328px;
    margin: 0 auto 40px;
  }

  .CreatorFaq-heading {
    margin-bottom: 28px;
  }

  .${accordionSummaryClassNames.root} {
    outline: 0;
  }

  .${accordionSummaryClassNames.content} > h3 {
    margin-bottom: 0;
  }

  .CreatorFaq-lastAccordion {
    margin-bottom: 32px;
  }
  .CreatorFaq-subheading {
    margin-top: 60px;
  }
`;
