import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import getCollectionImages from 'src/mvp22/firebase-functions/get_collection_images';
import get_collection_likes from 'src/mvp22/firebase-functions/get_collection_likes';
import getExploreCollections from 'src/mvp22/firebase-functions/get_explore_collections';
import { withFirebase } from 'src/mvp22/Firebase';
import CollectionCarousel from 'src/mvp22/tile-components/CollectionCarousel';
import nested_state_assignment from 'src/mvp22/core-components/nested_state_assignment';
import removeAllListeners from 'src/mvp22/core-components/remove_all_listeners';

/**
 * Old collections carousel extracted from the old Christmas landing exactly as
 * it was. Needs refactoring.
 */
class OldCollectionsCarousel extends Component {
  constructor() {
    super();
    this.state = {
      isLoaded: false,
      landingCollectionList: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateTheDataOnce();
  }

  componentDidUpdate() {
    this.updateTheDataOnce();
  }

  componentWillUnmount() {
    this._isMounted = false;
    removeAllListeners(this.state.collections);
  }

  collectionStateAssignFunc = (newDict) => {
    this._isMounted &&
      this.setState((prevState) => {
        return {
          collections: nested_state_assignment(prevState.collections, newDict),
        };
      });
  };

  updateTheDataOnce() {
    if (this.props.firebaseState && this.props.firebaseState.loaded) {
      this.setState((prevState, props) => {
        // makes this only run once? they are equal after the first time you load the page so updateTheDataOnce doesn't end up populating new collections
        if (
          prevState.auth_user_uid !== props.authUserUID ||
          prevState.current_explore_type !== props.explore_type
        ) {
          // get the collection info JUST ONCE, hence NOT bothering with redux for now:
          getExploreCollections(
            props.type ?? 'christmas',
            props.firebase,
            props.authUserUID,
          ).then((response) => {
            // split by big and small tiles:
            const like_listeners_dict = get_collection_likes(
              response.collections,
              this.props.firebase,
              this.props.authUserUID,
              this.collectionStateAssignFunc,
            ); // WILL ALSO CALL AN ASYNC FUNCTION WHEN LIKES DETERMINED!
            var collections_with_listeners = nested_state_assignment(
              response.collections,
              like_listeners_dict,
            );
            const image_getters = getCollectionImages(
              response.collections,
              this.props.firebase,
              this.collectionStateAssignFunc,
            );
            collections_with_listeners = nested_state_assignment(
              collections_with_listeners,
              image_getters,
            );
            this._isMounted &&
              this.setState((prevState) => {
                return {
                  isLoaded: true,
                  landingCollectionList: response.collections_sorted,
                  collections: nested_state_assignment(
                    prevState.collections,
                    collections_with_listeners,
                  ),
                  public_info: response.public_info,
                };
              });
          });
          return {
            auth_user_uid: this.props.authUserUID,
            isLoaded: false,
          };
        }
      });
    }
  }

  render() {
    const { className, style } = this.props;

    if (this.state.isLoaded) {
      return (
        <CollectionCarousel
          className={className}
          style={style}
          collections_list={this.state.landingCollectionList}
          collections={this.state.collections}
          public_info={this.state.public_info}
          auth_user_uid={this.state.auth_user_uid}
        />
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    firebaseState: state.firebasestate,
    authUserUID: state.auth.id,
  };
};

export default compose(
  withFirebase,
  connect(mapStateToProps),
)(OldCollectionsCarousel);
