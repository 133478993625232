import { VFC } from 'react';
import styled from 'styled-components';
import { CtaButton, Typography } from '@moonsifttech/design-system';
import { Redirect } from 'react-router-dom';

import R from 'src/routes';
import { UnstyledLink } from 'src/components/core/UnstyledLink';
import { Page } from 'src/components/common/Page';
import { ProTrialProps } from './ProTrial.types';
import { Helmet } from 'react-helmet';

const UnstyledProTrial: VFC<ProTrialProps> = ({
  className,
  style,
  isPro,
  workingStartPro,
  onStartProTrial,
}) => {
  if (isPro) {
    return <Redirect to={R.PRO_SHARE} />;
  }

  return (
    <Page className={className} style={style} hug="edge" showNavControls>
      <Helmet>
        <title>Pro Trial - Moonsift</title>
      </Helmet>
      <div className="ProTrial-content">
        <Typography variant="editorial.sb34" component="h1">
          Start your 30 day trial
        </Typography>
        <Typography variant="primary.l16" component="p">
          You will only be asked for payment details after your trial has ended.
          So no need to worry about being charged.
        </Typography>
        <CtaButton
          variant="primary-black"
          component={UnstyledLink}
          fullWidth
          loading={workingStartPro}
          onClick={onStartProTrial}
        >
          Start 30 day trial
        </CtaButton>
      </div>
    </Page>
  );
};

export const ProTrial = styled(UnstyledProTrial)`
  .ProTrial-content {
    max-width: 432px;
    padding: 0 16px;
    margin: 0 auto;
  }

  h1 {
    margin-bottom: 12px;
  }

  p {
    margin-bottom: 24px;
  }
`;
