import { useState } from 'react';
import styled from 'styled-components';
import {
  Stack,
  PlusCircleIcon,
  MinusCircleIcon,
} from '@moonsifttech/design-system';

const UnstyledQuantitySelector = ({
  initialValue = 1,
  onChange = () => {},
  maximum,
  minimum = 0,
  className,
}: {
  initialValue?: number;
  onChange?: (val: number) => void;
  maximum?: number;
  minimum?: number;
  className?: string;
}) => {
  const [quantity, setQuantity] = useState(initialValue);
  const setValue = (val: number) => {
    setQuantity(val);
    onChange(val);
  };

  return (
    <Stack className={className} direction='row'>
      <MinusCircleIcon
        className='QuantitySelector-button QuantitySelector-button--minus'
        size={36}
        onClick={() => {
          if (quantity === minimum) {
            return;
          }
          setValue(quantity - 1);
        }}
      />

      <input
        name="quantity"
        className='QuantitySelector-field'
        value={quantity}
        onChange={(ev) => {
          const value = parseInt(ev.target.value, 10);
          if (!isNaN(value)) {
            setValue(value);
          } else {
            setValue(0);
          }
        }}
      />

      <PlusCircleIcon
        className='QuantitySelector-button QuantitySelector-button--plus'
        size={36}
        onClick={() => {
          if (maximum && quantity + 1 > maximum) {
            return;
          }
          setValue(quantity + 1);
        }}
      />
    </Stack>
  );
};

export const QuantitySelector = styled(UnstyledQuantitySelector)`
  .QuantitySelector-field {
      width: 42px;
      height: 36px;
      text-align: center;
      border: 1px solid black;
      color: black;
      ${({ theme }) => theme?.typography?.primary?.r14};
      border-radius: 3px;

      &:focus {
        outline: none;
      }
  }

  .QuantitySelector-button {
    cursor: pointer;
    &--minus {
      margin-right: 4px;
    }
    &--plus {
      margin-left: 4px;
    }
  }
`;
