import { VFC } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { useGoToItem } from 'src/hooks/useGoToItem';
import { GoToBuyItemCardContainerProps } from './GoToBuyItemCard.types';
import { GoToBuyItemCard } from './GoToBuyItemCard.component';

const GoToBuyItemCardContainer: VFC<GoToBuyItemCardContainerProps> = ({
  collectionUID,
  collectionUserUID,
  collectionItemUID,
  ...otherProps
}) => {
  const productDataStoreAs = getCollectionItemStoreAs(
    collectionUID,
    collectionUserUID,
    collectionItemUID,
  );

  // Get collection item's data.
  const imageSource = useSelector<RootState, string | undefined>(
    ({ productData }) => {
      return productData?.[productDataStoreAs].imageList[0];
    },
  );
  const description = useSelector<RootState, string | undefined>(
    ({ productData }) => {
      return productData?.[productDataStoreAs].description;
    },
  );

  // Get URL for retailer's product page and increments click count for that
  // item.
  const { url, goToItem } = useGoToItem({
    collectionId: collectionUID,
    collectionUserId: collectionUserUID,
    itemId: collectionItemUID,
  });

  return (
    <GoToBuyItemCard
      imageSource={imageSource}
      description={description}
      collectionItemUrl={url}
      onGoToItem={goToItem}
      {...otherProps}
    />
  );
};

export { GoToBuyItemCardContainer as GoToBuyItemCard };
