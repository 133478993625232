/*

Collection tiles in the collection tile lists in profile / liked / purchased screens.
Also carousel tiles.

TODO: typescript, functional, camelCase, design system!
Also consider getting collection images in these tiles as can display blank when loading...!
*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import { M } from 'src/mvp22/constants';
import R from 'src/routes';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import CollectionPreview from 'src/mvp22/image-components/CollectionPreview';
import MEDIA from 'src/mvp22/media';
import { HeartIconWorking } from 'src/mvp22/image-components/WorkingDots';
import update_like from 'src/mvp22/firebase-functions/update_like';
import { withFirebase } from 'src/mvp22/Firebase';
import { getCustomUrl } from 'src/mvp22/core-components/image_url';
import { connect } from 'react-redux';
import { modalSet } from 'src/mvp22/redux-components/actions';

// Using here an after so that we can maniputlate that for each instance separately.
const CollectionPreviewContainer = styled.div`
  position: relative;
  background-color: ${M.COL.BG.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const CollectionPreviewContainerWithAfter = styled(CollectionPreviewContainer)`
  &:after {
    content: '\A';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 5px;
    opacity: 0;
    transition: all 0.1s;
  }
  &:hover:after {
    opacity: 0.1;
  }
`;

const CarouselTileContainer = styled(CollectionPreviewContainer)`
  border: 5px solid ${(props) => props.backgroundColor};
  box-shadow: -10px 10px ${(props) => props.backgroundColor};
`;

const FullLink = styled(TEXTSTYLE.LINK)`
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
`;

const BottomFlex = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  z-index: 50;
  width: ${(props) => (props.isLarge ? '626px' : '313px')};

  a {
    line-height: 0px;
  }
`;

const CollectionName = styled(TEXTSTYLE.OTHER1)`
  box-sizing: border-box;
  overflow: hidden;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
  &:hover {
    text-decoration: ${(props) =>
      props.hover_underline ? 'underline' : 'none'};
  }
  line-height: normal;
  color: ${M.COL.TEXT.BLACK};
`;

const CollectionNameContainer = styled.div`
  flex-shrink: 1;
  flex-basis: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
`;

const CollectionNameLinkHundoPWidth = styled(TEXTSTYLE.LINK)`
  width: 100%;
  text-align: left;
`;

const CollectionUsernameDisplay = styled(TEXTSTYLE.OTHER3)`
  box-sizing: border-box;
  overflow: hidden;
  flex-basis: 100%;
  flex-shrink: 1;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  font-size: 12px;
  color: ${M.COL.TEXT.MID};
  line-height: normal;
`;

const UnderlinedUsernameLink = styled(TEXTSTYLE.LINKUNDERLINE)`
  &:hover {
    color: ${M.COL.TEXT.BLACK};
  }
`;

const OuterTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.isLarge ? '624px' : '313px')};
  width: 100%;
  height: 100%;
`;

const SuperPaddedContainer = styled.div`
  position: relative;
  padding: 14px;
`;

const CollectionPreviewCover = styled.div`
  width: 100%;
  border-radius: 5px;
  height: ${(props) => (props.isLarge ? '440px' : '220px')};
  background-color: ${M.COL.BG.MID};
  background-image: ${(props) =>
    props.image_to_show ? `url(${props.image_to_show})` : 'none'};
  background-size: cover;
  background-position: center;
`;

const LikesContainer = styled(TEXTSTYLE.LINKA)`
  display: flex;
  align-items: center;
  flex-direction: horizontal;
  justify-content: flex-end;
  color: ${M.COL.TEXT.BLACK};
  &:hover {
    img {
      opacity: 1;
    }
  }
  z-index: 400;
  margin-left: 12px;
  flex-shrink: 0;
  cursor: pointer;
`;

const LikeNumber = styled(TEXTSTYLE.BODY5)`
  margin-left: 7px;
`;

const Bookmark = styled.img`
  color: default;
`;

const Lock = styled.img`
  height: 12px;
  display: inline-block;
  margin-right: 6px;
`;

const EditButton = styled(TEXTSTYLE.LINKA)`
  position: relative;
  cursor: pointer;
  z-index: 100;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
`;

const EditImg = styled.img`
  opacity: 0.7;
  height: 14px;
  &:hover {
    opacity: 1;
  }
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

class CollectionTile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      like_count_modifier: 0,
    };
    this.editCollection = this.editCollection.bind(this);
    this.toggle_like = this.toggle_like.bind(this);
    this.backgroundColor = this.backgroundColor.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toggle_like(event) {
    event.preventDefault();
    if (this.props.authUserUID !== null) {
      if (
        this.state.collection_like_working !== true &&
        this.props.data.collection_liked_is_liked !== undefined
      ) {
        this.setState({
          collection_like_working: true,
        });
        // Read the current collection liked state and switch
        const new_state =
          this.props.data.collection_liked_is_liked === true ? false : true;
        update_like(
          this.props.data.collection_entry.user_uid
            ? this.props.data.collection_entry.user_uid
            : this.props.authUserUID, // largely redundant as all should have user_uid now
          this.props.data.collection_entry.uid,
          new_state,
          this.props.firebase,
        ).then(
          // unset working:
          () => {
            this._isMounted &&
              this.setState((prevState) => {
                return {
                  collection_like_working: false,
                  like_count_modifier:
                    prevState.like_count_modifier + (new_state ? 1 : -1),
                };
              });
          },
        );
      }
    }
    // Case is not a user:
    else {
      // TODO: Add an event here!
      this.props.history.push(R.SIGNUP);
    }
    return false;
  }

  editCollection(event) {
    this.props.setModal({
      type: 'collection_settings',
      collectionName: this.props.data.collection_entry.name,
      collectionUID: this.props.data.collection_entry.uid,
      collectionDescription: this.props.data.collection_entry.description ?? '',
    });
    event.preventDefault();
    return false;
  }

  // Sharing level (public level) indicator:
  renderLock() {
    if (this.props.data.collection_entry.user_uid === this.props.authUserUID) {
      if (this.props.data.collection_entry.public_level <= 7) {
        return (
          <Lock
            src={
              MEDIA[
                'SHARE_LEVEL_' + this.props.data.collection_entry.public_level
              ]
            }
          />
        );
      }
    }
  }

  bookmarkOrEdit() {
    if (this.props.data.collection_entry.user_uid === this.props.authUserUID) {
      if (
        this.props.data.collection_entry.uid !== 'purchased' &&
        this.props.show_edit
      ) {
        return (
          <EditButton href="#" onClick={this.editCollection}>
            <EditImg src={MEDIA.EDIT} />
          </EditButton>
        );
      } else {
        return '';
      }
    } else {
      if (this.props.data.collection_liked_is_liked !== undefined) {
        return (
          <LikesContainer
            href="#"
            onClick={this.toggle_like}
            className="likesContainer"
          >
            {this.state.collection_like_working === true ? (
              <HeartIconWorking />
            ) : (
              <Bookmark
                src={
                  this.props.data.collection_liked_is_liked === true
                    ? MEDIA.BLACK_BOOKMARK
                    : MEDIA.WHITE_BOOKMARK
                }
              />
            )}
            <LikeNumber>
              {this.props.data.collection_entry.like_count !== undefined
                ? this.props.data.collection_entry.like_count +
                  this.state.like_count_modifier
                : ''}
            </LikeNumber>
          </LikesContainer>
        );
      } else {
        return '';
      }
    }
  }

  backgroundColor() {
    if (this.props.tileIndex % 3 === 0) {
      return M.COL.BG.DARKBLUE;
    } else if (this.props.tileIndex % 3 === 1) {
      return M.COL.BG.DARKGREEN;
    } else if (this.props.tileIndex % 3 === 2) {
      return M.COL.BG.DARKPURPLE;
    }
  }

  renderTileImage(COLLECTION_LINK) {
    const COLLECTION_PREVIEW_CONTAINER_USED = this.props.hover_bg
      ? CollectionPreviewContainerWithAfter
      : CollectionPreviewContainer;
    const to_return_inside =
      this.props.isCarouselTile === true ? (
        <CarouselTileContainer
          backgroundColor={this.backgroundColor}
          className="CarouselTileContainer"
        >
          <CollectionPreview
            backgroundColor={this.backgroundColor}
            isCarouselTile={true}
            data={this.props.data.collection_images}
          />
        </CarouselTileContainer>
      ) : (
        <COLLECTION_PREVIEW_CONTAINER_USED
          isLarge={this.props.isLarge}
          className="CollectionPreviewContainer"
        >
          {this.props.data.collection_entry &&
          this.props.data.collection_entry.cover_image_url ? (
            <CollectionPreviewCover
              isLarge={this.props.isLarge}
              image_to_show={getCustomUrl(
                this.props.data.collection_entry.cover_image_url,
              )}
            />
          ) : (
            <CollectionPreview
              isLarge={this.props.isLarge}
              data={this.props.data.collection_images}
            />
          )}
        </COLLECTION_PREVIEW_CONTAINER_USED>
      );
    return this.props.hover_bg ? (
      <TEXTSTYLE.LINK to={COLLECTION_LINK}>{to_return_inside}</TEXTSTYLE.LINK>
    ) : (
      to_return_inside
    );
  }

  render() {
    const DISPLAY_USERNAME =
      this.props.data.collection_entry.user_uid === this.props.authUserUID
        ? this.props.display_username
        : this.props.user_public_info?.display_username;
    const COLLECTION_LINK =
      R.COLLECTION +
      '/' +
      DISPLAY_USERNAME +
      '/' +
      this.props.data.collection_entry.uid;
    // This is done here because can't pass props to router dom link other than allowed?
    const COLLECTION_LINK_ELEMENT =
      this.props.hover_bg !== true
        ? CollectionNameLinkHundoPWidth
        : TEXTSTYLE.LINK;
    const to_return_inside = (
      <OuterTileContainer
        isCarouselTile={this.props.isCarouselTile}
        isLarge={this.props.isLarge}
        className="OuterTileContainer"
      >
        {this.renderTileImage(COLLECTION_LINK)}
        {this.props.isLarge === true ? (
          <Seperator height="18px" />
        ) : (
          <Seperator height="15px" />
        )}
        {this.props.isCarouselTile ? <Seperator height="10px" /> : ''}
        <BottomFlex
          isCarouselTile={this.props.isCarouselTile}
          isLarge={this.props.isLarge}
          className="BottomFlex"
        >
          {this.renderLock()}
          <CollectionNameContainer>
            <COLLECTION_LINK_ELEMENT to={COLLECTION_LINK}>
              <CollectionName hover_underline={this.props.hover_bg}>
                {this.props.data.collection_entry.name}
              </CollectionName>
            </COLLECTION_LINK_ELEMENT>
          </CollectionNameContainer>
          {this.bookmarkOrEdit()}
        </BottomFlex>
        {this.props.display_name ? (
          <>
            <Seperator height="10px" />
            <BottomFlex
              isCarouselTile={this.props.isCarouselTile}
              isLarge={this.props.isLarge}
              className="BottomFlex"
            >
              <CollectionUsernameDisplay className="CollectionUsernameDisplay">
                by{' '}
                <UnderlinedUsernameLink
                  to={'/@' + this.props.user_public_info?.display_username}
                >
                  {this.props.user_public_info?.name}
                </UnderlinedUsernameLink>
              </CollectionUsernameDisplay>
            </BottomFlex>
          </>
        ) : (
          ''
        )}
      </OuterTileContainer>
    );
    return this.props.hover_bg ? (
      to_return_inside
    ) : (
      <SuperPaddedContainer>
        {to_return_inside}
        <FullLink to={COLLECTION_LINK} />
      </SuperPaddedContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authUserUID: state.auth.id,
    display_username: state.firestore_user_owner.display_username,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setModal: (data) => dispatch(modalSet(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withFirebase(CollectionTile));
