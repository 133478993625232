/*

The creator's earnings

Stored at:
/databases/user_earnings/<userUID>/earnings/earnings_collection/<collectionUID>


*/

import { compose } from 'redux';

import { ID } from 'src/types/common';
import { ReducerType, Kind } from './basedb.model';

export interface MonetaryAmount {
  currency: string;
  amount: number;
}

export interface CommissionAmount {
  sale_value: MonetaryAmount;
  commission: MonetaryAmount;
}

export interface EarningsEntry {
  given?: CommissionAmount;
  due?: CommissionAmount;
  pending?: CommissionAmount;
}

export interface ItemEarningsEntry extends EarningsEntry {
  link: string;
  sales: number;
  brand: string;
  clicks?: number;
}

export interface CollectionEarningsDocument {
  collection_uid: string;
  user_uid: string;
  collection: EarningsEntry;
  items: ItemEarningsEntry[];
  last_updated: Date;
  collection_name: string;
  collection_created_at: Date;
  views: number;
  sales: number;
  clicks?: number;
}

export type CollectionEarnings = CollectionEarningsDocument & ID;

export interface CollectionEarningsListKind extends Kind {
  item: CollectionEarnings;
  reducer: ReducerType.QUERY_SINGLE;
}

function generateInitialCommissionAmount(): CommissionAmount {
  return {
    sale_value: {
      currency: 'GBP',
      amount: 0,
    },
    commission: {
      currency: 'GBP',
      amount: 0,
    },
  };
}

export function fillEarningsEntry({
  given = generateInitialCommissionAmount(),
  due = generateInitialCommissionAmount(),
  pending = generateInitialCommissionAmount(),
}: EarningsEntry = {}): Required<EarningsEntry> {
  return {
    given,
    due,
    pending,
  };
}

const addList = (amounts: number[]) => {
  return amounts.reduce((acc, a) => acc + a, 0);
};

const multiplyAmountsBy100 = (amounts: MonetaryAmount[]) => {
  return amounts.map((a) => a.amount * 100);
};

const divideAmountBy100 = (amount: number) => amount / 100;

const generateEntryAdder = (
  mapper: (ca: CommissionAmount[]) => MonetaryAmount[],
): ((entry?: EarningsEntry) => number) => {
  return compose(
    divideAmountBy100,
    addList,
    multiplyAmountsBy100,
    mapper,
    Object.values,
    fillEarningsEntry,
  );
};

export const sumEarningsEntrySales = generateEntryAdder(
  (values: CommissionAmount[]) => {
    return values.map((v) => v.sale_value);
  },
);

export const sumEarningsEntryCommissions = generateEntryAdder(
  (values: CommissionAmount[]) => {
    return values.map((v) => v.commission);
  },
);
