/*
  Shown when the user clicks the edit link for requested quantity in the Product Modal.
*/

import { useState, useContext } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Typography,
  Stack,
  StandardButton,
} from '@moonsifttech/design-system';
import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { useModal } from 'src/hooks/useModal';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { ModalContent } from 'src/components/core/ModalContent';
import { GoToBuyItemCard } from 'src/components/collection/GoToBuyItemCard';
import { QuantitySelector } from 'src/components/collection/QuantitySelector';


const UnstyledSetQuantityDialog = ({
  collectionId,
  collectionUserId,
  itemId,
  className,
}: {
  collectionId: string;
  collectionUserId: string;
  itemId: string;
  className?: string;
}) => {
  const [ _, closeModal ] = useModal();

  const items = useSelector(
    (state: RootState) => state.db.single_collection_item_list.data,
  );
  const item = items
    .find((item) => item.id === itemId);
  const requestedQuantity = item?.quantity ?? 1;

  const [ quantity, setQuantity ] = useState(requestedQuantity);

  const { db } = useContext(ServicesContext);

  const [ updateCollectionItem, working ] = useDBUpdateDoc(
    db,
    db.collectionItem(collectionUserId, collectionId, itemId),
  );

  return (
    <ModalContent className={className} onClose={closeModal}>
      <Typography variant="primary.b26" component="h1">
        Quantity requested
      </Typography>
      <Typography variant="primary.r16" component="h1">
        The quantity will only be displayed to visitors if you request more than 1 of the item
      </Typography>

      <div className="SetQuantityDialog-selectorWrapper">
        <QuantitySelector
          initialValue={requestedQuantity}
          onChange={setQuantity}
          minimum={item?.purchased ?? 1}
        />
      </div>

      <GoToBuyItemCard
        className="SetQuantityDialog-productCard"
        collectionUID={collectionId}
        collectionUserUID={collectionUserId}
        collectionItemUID={itemId}
        showLink={false}
      />

      <Stack direction='row' justifyContent='flex-end'>
        <StandardButton
          className="SetQuantityDialog-cancelButton"
          variant="primary-white"
          onClick={closeModal}
        >
          Cancel
        </StandardButton>

        <StandardButton
          variant="primary-black"
          loading={working}
          onClick={() => {
            updateCollectionItem({ quantity });
            closeModal();
          }}
          disabled={
            (requestedQuantity > 1 && quantity === 0)
            || quantity === requestedQuantity
          }
        >
          Confirm
        </StandardButton>
      </Stack>
    </ModalContent>
  );
};

export const SetQuantityDialog = styled(UnstyledSetQuantityDialog)`
  .SetQuantityDialog-selectorWrapper {
    margin-bottom: 1.25rem;
  }

  .SetQuantityDialog-productCard {
    margin-bottom: 2.5rem;
  }

  .SetQuantityDialog-cancelButton {
    margin-right: 0.75rem;
  }
`;
