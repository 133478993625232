/*

Essentially a static page about Moonsift.
TODO: typescript, make functional, non-default export...

*/
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import clsx from 'clsx';
import { Typography } from '@moonsifttech/design-system';

import { M } from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import windowSize from 'src/mvp22/WindowSize';
import { EXTERNAL } from 'src/routes';

const Container = styled.div`
  color: default;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${M.COL.BG.WHITE};
  color: ${M.COL.TEXT.BLACK};
  padding-left: 20px;
  padding-right: 20px;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const RowContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: visible;
  justify-content: center;
`;

const GreyRowBG = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--grey-6);
  color: ${M.COL.TEXT.BLACK};
  padding-left: 20px;
  padding-right: 20px;
`;

const GreyRowContainer = styled(RowContainer)`
  background-color: var(--grey-6);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  padding-bottom: 63px;
  padding-top: 63px;
  @media (min-width: 953px) {
    padding-bottom: 110px;
    padding-top: 110px;
  }
  max-width: ${(props) => (props.isDesktop === true ? '1200px' : '')};
  margin: auto;
`;

const WhiteRowContainer = styled(RowContainer)`
  background-color: ${M.COL.BG.WHITE};
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @media (min-width: 953px) {
    padding-top: 47px;
  }
  max-width: ${(props) => (props.isDesktop === true ? '1200px' : '420px')};
  margin: auto;
`;

const MainHeadingRow = styled(RowContainer)`
  background-color: ${M.COL.BG.WHITE};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: calc(${M.HEIGHTS.TOPMENU} + 60px);
  text-align: left;
`;

//border:1px solid green;
const ImageContainer = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const ImageContainer2 = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const ImageContainer3 = styled.div`
  width: 50%;
  flex-basis: 469px;
  flex-grow: 1;
  overflow: visible;
  box-sizing: border-box;
  align-items: center;
  line-height: 0px;
`;

const TextContainer = styled.div`
  width: ${(props) => (props.isDesktop === true ? '50%' : '')};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SubtitleContainer = styled.div`
  flex: 0 0 50%;
  display: flex;
`;

const RightImage = styled.img`
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;

  @media (min-width: 993px) {
    position: relative;
    top: 90px;
  }
`;

const LeftImage = styled.img`
  font-size: 20px;
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
`;

const BottomImage = styled.img`
  font-size: 20px;
  padding: 0px 16px;
  width: 100%;
  box-sizing: border-box;
  max-width: 450px;
`;

const SignatureImage = styled.img`
  max-width: 100%;
`;

//   border:5px solid green;
const ItemText = styled.div`
  color: default;
`;

const LeftText = styled(ItemText)`
  max-width: 480px;
  text-align: left;
`;

const RightText = styled(ItemText)`
  max-width: 450px;
  text-align: left;
`;

const BodyParagraph = styled(TEXTSTYLE.BODY2)`
  max-width: 420px;
  margin-bottom: 30px;
  text-align: left;
`;

const AlexAndDavidSignatureRow = styled.div`
  display: block;
  height: 50px;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
`;

/**
 * AboutUs Page Component
 */
class UnstyledAboutUs extends Component {
  render() {
    const ISDESKTOP = this.props.windowWidth >= M.MOBILESWITCH;
    const SEPERATOR = <Seperator height="68px" />;

    return (
      <Container className={clsx('Container', this.props.className)}>
        <Helmet>
          <title>About us - Moonsift</title>
        </Helmet>
        <TopMenu />
        <MainContent className="MainContent">
          <WhiteRowContainer
            isDesktop={ISDESKTOP}
            className="WhiteRowContainer"
          >
            <TextContainer isDesktop={ISDESKTOP} className="TextContainer">
              <LeftText className="LeftText">
                <MainHeadingRow className="MainHeadingRow">
                  <Typography
                    className="AboutUs-heading"
                    variant="editorial.r68"
                    component="h1"
                  >
                    About Moonsift
                  </Typography>
                </MainHeadingRow>
                <SubtitleContainer>
                  <Typography
                    className="AboutUs-subheading"
                    variant="editorial.sb34"
                    component="h2"
                  >
                    Re-imagining shopping online
                  </Typography>
                </SubtitleContainer>
                <BodyParagraph>
                  Moonsift has been designed alongside a community of people
                  that love to shop online, but are frustrated by the disjointed
                  experience.
                </BodyParagraph>
                <BodyParagraph>
                  The hours spent scrolling through webpages, so many tabs open
                  you lose track of what's what, separate wishlists with every
                  retailer and unhelpful ads that follow you around trying to
                  sell you things you've already bought!
                </BodyParagraph>
                <BodyParagraph>
                  We are developing tools for people that want to make more
                  considered choices. Giving them the ability to centralise and
                  curate their most wanted items, and discover more of the
                  things they love.
                </BodyParagraph>
                <BodyParagraph>
                  As we work to re-imagine shopping online, we welcome your
                  ideas and feedback.
                </BodyParagraph>
                <AlexAndDavidSignatureRow className="AlexAndDavidSignatureRow">
                  <SignatureImage
                    alt="signature"
                    src={MEDIA.ALEX_DAVID_SIGNATURE}
                  />
                </AlexAndDavidSignatureRow>
              </LeftText>
            </TextContainer>
            <ImageContainer className="ImageContainer">
              <RightImage src={MEDIA.ABOUT_US_COMMUNITYPICTURES} />
            </ImageContainer>
          </WhiteRowContainer>
        </MainContent>
        <Seperator height="63px" />

        <GreyRowBG className="greyRowBG">
          <GreyRowContainer isDesktop={ISDESKTOP} className="GreyRowContainer">
            <ImageContainer2 className="ImageContainer2">
              <LeftImage src={MEDIA.ABOUT_US_PRODUCTVISIONIMAGES} />
            </ImageContainer2>
            <TextContainer isDesktop={ISDESKTOP} className="TextContainer">
              <RightText className="RightText">
                <Typography
                  className="AboutUs-subheading"
                  variant="editorial.sb34"
                  component="h2"
                >
                  Product Vision
                </Typography>
                <BodyParagraph>
                  Moonsift's proprietary technology makes it easier than ever
                  before to save and keep track of all the things you're
                  shopping for in one place. But this is only the beginning.
                </BodyParagraph>
                <BodyParagraph>
                  Phase two will enable you to publish and explore collections
                  curated by the community. Follow people with an eye for
                  aesthetics, collaborate with friends and get help with
                  decision making.
                </BodyParagraph>
                <BodyParagraph>
                  Moonsift ultimate aim is to understand your taste so it can
                  sift through millions of options in the blink of an eye to
                  uncover the things you most want to know about. Helping you
                  discover exciting new brands and products, without having to
                  do all the hard work yourself.
                </BodyParagraph>
              </RightText>
            </TextContainer>
          </GreyRowContainer>
        </GreyRowBG>

        <Seperator height="63px" />
        <MainContent className="MainContent">
          <WhiteRowContainer
            isDesktop={ISDESKTOP}
            className="WhiteRowContainer"
          >
            <TextContainer isDesktop={ISDESKTOP} className="TextContainer">
              <LeftText className="LeftText">
                <SubtitleContainer>
                  <Typography
                    className="AboutUs-subheading"
                    variant="editorial.sb34"
                    component="h2"
                  >
                    We believe your data should work for you
                  </Typography>
                </SubtitleContainer>
                <BodyParagraph>
                  We believe in empowering individuality and we believe in a
                  personalised product discovery experience that is unbiased.
                </BodyParagraph>
                <BodyParagraph>
                  As you browse and shop online today, every interaction you
                  make is being captured by the sites you visit. Powered by
                  artificial intelligence (AI), advertisers often use your data
                  to help benefit their sales agenda - rather than keeping the
                  best interest of the consumer at heart.
                </BodyParagraph>
                <BodyParagraph>
                  Moonsift believes your data should work for you. We want to
                  put the power of AI into the hands of consumers, helping you
                  find more of the things you genuinely want to buy.
                </BodyParagraph>
                <BodyParagraph>
                  We call this AI our{' '}
                  <TEXTSTYLE.EXTERNALLINKUNDERLINE href={EXTERNAL.AI_LANDING}>
                    Shopping Copilot
                  </TEXTSTYLE.EXTERNALLINKUNDERLINE>
                </BodyParagraph>
              </LeftText>
            </TextContainer>
            <ImageContainer3 className="ImageContainer3">
              <BottomImage src={MEDIA.ABOUT_US_CONSUMERDATA} />
            </ImageContainer3>
          </WhiteRowContainer>
        </MainContent>
        {ISDESKTOP ? SEPERATOR : ''}
        <Seperator height="68px" />
        <BottomBar />
      </Container>
    );
  }
}

const AboutUs = styled(UnstyledAboutUs)`
  .AboutUs-heading {
    margin-bottom: 46px;
  }

  .AboutUs-subheading {
    margin-bottom: 24px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .AboutUs-heading {
      margin-bottom: 32px;
    }

    .AboutUs-subheading {
      margin-bottom: 28px;
    }
  }
`;

export default windowSize(AboutUs);
