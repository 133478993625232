import React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { manager as modalManager } from '@moonsifttech/design-system';

import { RootState } from 'src/index';
import {
  MoreCollectionOptionsButtonProps,
  MoreCollectionOptionsButtonContainerProps,
} from './MoreCollectionOptionsButton.types';
import { MoreCollectionOptionsButton } from './MoreCollectionOptionsButton.component';

const MoreCollectionOptionsButtonContainer: React.FC<
  MoreCollectionOptionsButtonContainerProps
> = ({
  onShare: onShareProp,
  onEdit: onEditProp,
  onNotifications: onNotificationsProp,
  ...otherProps
}) => {
  // Determine whether we are on mobile
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  // Local state
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // Handlers
  const handleClick = React.useCallback<
    MoreCollectionOptionsButtonProps['onClick']
  >(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  }, []);

  const handleClose = React.useCallback<
    MoreCollectionOptionsButtonProps['onClose']
  >(() => {
    setIsMenuOpen(false);
  }, []);

  const handleShare = React.useCallback<
    MoreCollectionOptionsButtonProps['onShare']
  >(
    (event) => {
      if (isMobile) {
        // TODO: remove once we've stopped using SharePopup in Collection
        // With this line of code we force the execution of the opening strategy.
        // By providing a non-existent modal we are forcing all the existent modals
        // to close
        modalManager.open('-1');
        // And here we delay the opening of the old modal so that it plays nicely
        // with the new modal animation
        setTimeout(() => onShareProp(event), 250);
      } else {
        // On desktop, as we are using react-overlays instead of our modal, we need
        // to manually close the more options menu when opening the share modal
        setIsMenuOpen(false);
        onShareProp(event);
      }
    },
    [isMobile, onShareProp],
  );

  const handleEdit = React.useCallback<
    MoreCollectionOptionsButtonProps['onEdit']
  >(
    (event) => {
      onEditProp(event);
      // On mobile, we don't need to close the MoreCollectionOptionsMenu on our own,
      // the modal component instance will do that respecting closing times of other
      // modals that were open at that time
      if (!isMobile) {
        setIsMenuOpen(false);
      }
    },
    [isMobile, onEditProp],
  );

  const handleNotifications = React.useCallback<
    MoreCollectionOptionsButtonProps['onNotifications']
  >(
    (event) => {
      onNotificationsProp(event);
      // On mobile, we don't need to close the MoreCollectionOptionsMenu on our own,
      // the modal component instance will do that respecting closing times of other
      // modals that were open at that time
      if (!isMobile) {
        setIsMenuOpen(false);
      }
    },
    [isMobile, onNotificationsProp],
  );

  return (
    <MoreCollectionOptionsButton
      isMenuOpen={isMenuOpen}
      onClick={handleClick}
      onClose={handleClose}
      onShare={handleShare}
      onEdit={handleEdit}
      onNotifications={handleNotifications}
      {...otherProps}
    />
  );
};

export { MoreCollectionOptionsButtonContainer as MoreCollectionOptionsButton };
