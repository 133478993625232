import Bowser from 'bowser';

/**
 * Returns a boolean indicating whether we are on a Safari browser.
 */
export function checkIfSafari(): boolean {
  if (typeof window === 'undefined') {
    return false;
  }

  const browserParser = Bowser.getParser(global.window?.navigator.userAgent);
  return browserParser.getBrowserName() === 'Safari';
}
