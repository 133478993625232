import { VFC } from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledRadio,
  FormControlLabel,
  ControlledRadioGroup,
  checkCircleIconClassNames,
  Stack,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';
import { EnableNotificationsPromptProps } from './EnableNotificationsPrompt.types';

const UnstyledEnableNotificationsPrompt: VFC<
  EnableNotificationsPromptProps
> = ({
  className,
  style,
  onSubmit,
  onClose,
  validationSchema,
  initialValues,
}) => {
  return (
    <div className={className} style={style}>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
        className="FeedbackDialog-form"
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form>
            <Stack>
              <Typography variant="primary.b26" component="h2">
                Choose your notifications
              </Typography>
              <ControlledRadioGroup
                className="EnableNotificationsPrompt-radios"
                name="priceDrop"
              >
                <FormControlLabel
                  label="Price drops over 5% "
                  labelPlacement="end"
                  value="5"
                  control={<ControlledRadio name="priceDrop" />}
                />
                <FormControlLabel
                  label="Price drops over 20% "
                  labelPlacement="end"
                  value="20"
                  control={<ControlledRadio name="priceDrop" />}
                />
                <FormControlLabel
                  label="None"
                  labelPlacement="end"
                  value="false"
                  control={<ControlledRadio name="priceDrop" />}
                />
              </ControlledRadioGroup>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-end"
              className="EnableNotificationsPrompt-buttonsContainer"
            >
              <StandardButton
                variant="primary-white"
                onClick={onClose}
                className="EnableNotificationsPrompt-button"
              >
                Close
              </StandardButton>
              <StandardButton
                variant="primary-black"
                type="submit"
                loading={isSubmitting}
                className="EnableNotificationsPrompt-button"
              >
                Enable
              </StandardButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export const EnableNotificationsPrompt = styled(
  UnstyledEnableNotificationsPrompt,
)`
  h2 {
    display: flex;
    align-items: center;

    > .${checkCircleIconClassNames.root} {
      margin-right: 12px;
    }
  }
  .EnableNotificationsPrompt-button {
    width: 100px;
    margin: 6px;
  }
  .EnableNotificationsPrompt-buttonsContainer {
    margin-top: 40px;
  }
`;
