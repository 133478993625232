/*

Modal display type and any other input data not elsewhere in Redux store.

*/
import { Action, ActionNames } from 'src/types';
import { ModalState } from 'src/types/models/modal.model';

export const DEFAULT_MODAL_STATE: ModalState = {
  type: null,
};

export function modal(
  state: ModalState = DEFAULT_MODAL_STATE,
  action: Action,
): ModalState {
  switch (action.type) {
    case ActionNames.MODAL_SET:
      return action.payload;
    case ActionNames.MODAL_CLOSE:
      return DEFAULT_MODAL_STATE;
    default:
      return state;
  }
}
