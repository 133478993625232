/*

Footer display type and any other input data:

*/
import { Action, ActionNames } from 'src/types';
import { FooterPopupState } from 'src/types/models/footerPopup.model';

export const DEFAULT_FOOTER_POPUP_STATE: FooterPopupState = {
  type: null,
};

export function footerPopup(
  state: FooterPopupState = DEFAULT_FOOTER_POPUP_STATE,
  action: Action,
): FooterPopupState {
  if (action.type === ActionNames.FOOTER_POPUP_SET) {
    return action.payload;
  }
  return state;
}
