/*

Replace with using hook useFastAPI

*/
export default function delete_collection(collection_uid,firebase){
  return new Promise(
    resolve=>{
      firebase.fast_api()(
        {
          api:"delete_collection",
          collection_uid,
        }
      ).then(
        resolve
      ).catch(
        err=>
          {
            console.error("ERROR",err);
          }
      );
    }
  );
}


/*

*/
