import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import { selectCollectionRegistryPrivate } from 'src/store/selectors/collectionRegistryPrivate/collectionRegistryPrivate.selector';
import { CollectionRegistryPrivate } from 'src/types/models/collectionRegistryPrivate.model';
import { CollectionItem } from 'src/types/models/collectionItem.model';

type Output = {
  someBought: boolean;
  boughtByUser: boolean;
  multiplePurchases: boolean;
  ownerOnlyPurchase: boolean;
  exhausted: boolean;
  privateEntry: CollectionRegistryPrivate | undefined;
  privateRegistry: CollectionRegistryPrivate[] | undefined;
};

export const useItem = (itemId: string): Output => {
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  const collectionItems = useSelector<RootState, CollectionItem[]>(
    (state) => state.db.single_collection_item_list.data,
  );
  const collectionItem = collectionItems
    .find((item) => item.id === itemId);

  const isMine = collectionItem?.user_uid === authUserUID;

  // has any quantity been bought from this item
  const someBought = (collectionItem?.purchased ?? 0) >= 1;

  const privateRegistry = useSelector<
    RootState,
    CollectionRegistryPrivate[] | undefined
  >((state) => {
    return selectCollectionRegistryPrivate(state, itemId);
  });
  const { analytics } = useContext(ServicesContext);

  const privateEntry = privateRegistry?.find((entry) => {
    return (
      (entry.purchaser_user_uid ?? 'NOT_LOGGED_IN') === authUserUID ||
      entry.purchaser_identity_identifier === analytics.clientIdentifier
    );
  });
  const boughtByUser = privateEntry != null;

  // has all requested quntity been bought from this item
  const exhausted = (collectionItem?.purchased ?? 0) === (collectionItem?.quantity ?? 1);

  // have multiple purchases been made against this item.
  const multiplePurchases = (privateRegistry?.length ?? 0) > 1;

  // has only the owner made any marks against this item
  const ownerOnlyPurchase = isMine && boughtByUser && !multiplePurchases;

  return {
    someBought,
    boughtByUser,
    multiplePurchases,
    ownerOnlyPurchase,
    exhausted,
    privateEntry,
    privateRegistry,
  };
};
