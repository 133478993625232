/*

Replace with REDUX query list (non-map) to get the collection list
and also get the userpublic data method we already use in various places (e.g. comments)

*/
import collection_data_proc from 'src/mvp22/core-components/collection_data_proc';
import get_user_profile_public from './get_user_profile_public';

export default async function get_liked_collections(firebase,auth_user_uid){
  // Ensure that the scratch (+ site uid array) is loaded:
  // Get the list of liked collections first...
  const liked_list = await firebase.user_collection_liked(auth_user_uid).get(); // QUERY!
  // Get each of the collection entries if they exist!
  const promises_array = [];
  const collection_snapshots = [];
  const public_info = {};
  const user_uid_dict = {};
  liked_list.forEach(
    (doc,index)=>{
      const this_data = doc.data();
      user_uid_dict[this_data.user_uid] = true;
      promises_array.push(
        firebase.user_product_collection(this_data.user_uid,this_data.collection_uid).get().then(
          snapshot=>{
            if (snapshot.exists()){
              collection_snapshots.push([snapshot,index]);
            }
          }
        ).catch(
          ()=>{return(false);}
        )
      );
    }
  );
  for (let this_user_uid in user_uid_dict){
    promises_array.push(
      get_user_profile_public(this_user_uid,firebase).then(
        response=>public_info[this_user_uid]=response
      )
    );
  }
  await Promise.allSettled(promises_array);
  const liked_collections_dict = {};
  const collections_sorted = [];
  // Sort to match original order:
  collection_snapshots.sort((a,b)=>(a[1]<b[1])?-1:1);
  // And convert for output:
  collection_snapshots.forEach(
    snapshot=>{
      const this_collection_entry = collection_data_proc(auth_user_uid,snapshot[0]);
      collections_sorted.push(this_collection_entry.global_uid);
      liked_collections_dict[this_collection_entry.global_uid] = {
        collection_entry: this_collection_entry
      };
    }
  );
  return(
      {
        collections:liked_collections_dict,
        collections_sorted,
        public_info
      }
  );
}
