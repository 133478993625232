import React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { RootState } from 'src/index';
import { EditModalContainerProps } from './EditModal.types';
import { EditModal } from './EditModal.component';

const EditModalContainer: React.FC<EditModalContainerProps> = (props) => {
  // Determine whether we are on mobile
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  return <EditModal isMobile={isMobile} {...props} />;
};

export { EditModalContainer as EditModal };
