/*
Consider moving to part of a hook / reducer to hold data for forms.
Also may not need it.

Checks all inputs in a map of the form
{
  <key>:[value,valid]
}
for validity by looking at the valid field, [1] in the array.
*/
export default function check_overall_validity(inputdict){
  var newOverallValidity = true;
  for (var thisProperty in inputdict){
    if (inputdict[thisProperty][1]===false){
      newOverallValidity = false;
      break;
    }
  }
  return newOverallValidity;
}
