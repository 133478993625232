import React from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Stack,
  Typography,
  StandardButton,
  CloseIcon,
} from '@moonsifttech/design-system';

import { CreatorDialogProps } from './CreatorDialog.types';

const mapCreatorStatusToCopies = {
  applied: {
    body: 'Unlock your earnings dashboard.',
    button: 'Learn more',
  },
  accepted: {
    body: 'Your earnings dashboard is ready!',
    button: 'Check it out',
  },
};

const UnstyledCreatorDialog: React.FC<CreatorDialogProps> = ({
  className,
  style,
  creatorStatus,
  onCreatorDashboard,
  onClose,
  ...otherProps
}) => {
  const isAccepted = creatorStatus === 'accepted';
  const copies = mapCreatorStatusToCopies[creatorStatus];

  return (
    <Paper
      className={className}
      style={style}
      variant={isAccepted ? 'standard' : 'outlined'}
      {...otherProps}
    >
      {isAccepted && (
        <Stack
          justifyContent="center"
          alignItems="center"
          className="CreatorDialog-closeButton"
          onClick={onClose}
        >
          <CloseIcon size="small" />
        </Stack>
      )}
      <Stack alignItems="center" spacing={8}>
        <Typography variant="primary.r14">{copies.body}</Typography>
        <StandardButton
          variant={isAccepted ? 'primary-black' : 'primary-white'}
          onClick={onCreatorDashboard}
        >
          {copies.button}
        </StandardButton>
      </Stack>
    </Paper>
  );
};

export const CreatorDialog = styled(UnstyledCreatorDialog)`
  position: relative;
  padding: 12px 16px 16px;
  max-width: 312px;

  &.${paperClassNames.variantOutlined} {
    border: 0;
    background: var(--grey-6);
  }

  .CreatorDialog-closeButton {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;
