/*

These functions return prettier timings in the past from datetimes.
TODO: Integrate with theme from design system and move to utils.
*/
import {M} from 'src/mvp22/constants';
export function time_in_past(now_in_seconds,datetime_in_seconds){
  const seconds_ago = Math.round(now_in_seconds-datetime_in_seconds);
  var colour_out = M.COL.UPDATECOLS.GREEN; // green;
  if (seconds_ago<10){
      return ["just now",colour_out];
  }
  const minutes_ago = Math.floor(seconds_ago/60.0);
  if (minutes_ago===0){
    return [seconds_ago+" seconds ago",colour_out];
  }
  else if(minutes_ago===1){
    return ["a minute ago",colour_out];
  }
  // else:
  const hours_ago = Math.floor(minutes_ago/60);
  if (hours_ago===0){
    return [minutes_ago+" minutes ago",colour_out];
  }
  else if (hours_ago===1){
    return ["an hour ago",colour_out];
  }
  const days_ago = Math.floor(hours_ago/24);
  if (days_ago<=1){
    return [hours_ago+" hours ago",colour_out];
  }
  // else
  colour_out = M.COL.UPDATECOLS.ORANGE; // yella;
  const weeks_ago = Math.floor(days_ago/7);
  if (weeks_ago===0){
    return [days_ago+" days ago",colour_out];
  }
  else if (weeks_ago===1){
    return ["a week ago",colour_out];
  }
  else if(days_ago<365){
    return [weeks_ago+" weeks ago",colour_out];
  }
  // else
  colour_out = M.COL.UPDATECOLS.RED;  // red;
  return ["over a year ago",colour_out]; //many moons ago
}

export function price_in_past(now_in_seconds,datetime_in_seconds){
  const seconds_ago = Math.round(now_in_seconds-datetime_in_seconds);
  const minutes_ago = Math.floor(seconds_ago/60.0);
  var colour_out = "green"; // green;
  if (seconds_ago<2000){
      return ["just now",colour_out];
  }
  // else:
  const hours_ago = Math.floor(minutes_ago/60);
  if (hours_ago===0){
    return [minutes_ago+" minutes ago",colour_out];
  }
  else if (hours_ago===1){
    return ["an hour ago",colour_out];
  }
  const days_ago = Math.floor(hours_ago/24);
  if (days_ago<=1){
    return [hours_ago+" hours ago",colour_out];
  }
  // else
  const weeks_ago = Math.floor(days_ago/7);
  if (weeks_ago===0){
    return [days_ago+" days ago",colour_out];
  }
  else if (weeks_ago===1){
    colour_out = "orange"; // yella;
    return ["a week ago",colour_out];
  }
  else if(weeks_ago<10){
    colour_out = "orange"; // yella;
    return [weeks_ago+" weeks ago",colour_out];
  }
  // else
  colour_out = "red"; // red;
  const months_ago = Math.round(days_ago/30);
  return [months_ago+" months ago",colour_out];
}

export function notification_in_past(now_dict,datetime_dict){
  const now_in_seconds = (now_dict)?now_dict.seconds:null;
  const datetime_in_seconds = (datetime_dict)?datetime_dict.seconds:null;
  if (typeof now_in_seconds==="number" && typeof datetime_in_seconds==="number"){
    const seconds_ago = Math.round(now_in_seconds-datetime_in_seconds);
    const minutes_ago = Math.floor(seconds_ago/60.0);
    if (minutes_ago===0){
        return "now";
    }
    const hours_ago = Math.floor(minutes_ago/60.0);
    if (hours_ago===0){
      return minutes_ago+"m";
    }
    const days_ago = Math.floor(hours_ago/24.0);
    if (days_ago<=1){
      return hours_ago+"h";
    }
    const weeks_ago = Math.floor(days_ago/7);
    if (weeks_ago===0){
      return days_ago+"d";
    }
    else if(weeks_ago<10){
      return weeks_ago+"w";
    }
    return "ages ago";
  }else{
    return "past";
  }
}

export function check_item_is_earlier_than(item_time_dict,point_in_time_dict){
  const point_in_time_seconds = (point_in_time_dict)?point_in_time_dict.seconds:null;
  const item_time_seconds = (item_time_dict)?item_time_dict.seconds:null;
  return item_time_seconds<point_in_time_seconds;
}
