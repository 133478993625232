/*

Prepare a file for uploading to storage e.g. profile picture.

Move to utils

*/

const prepareFileForUpload = (target)=>{
  return new Promise(
    (resolve,reject)=>{
      try{
        const file_reader = new FileReader();
        file_reader.onloadend = (event)=>{
          if (event.target.result && event.target.result.length<1024*1024*8){
            resolve(
              event.target.result
            );
          }else{
            reject(
              "File must be less than 8MB"
            );
          }
        };
        file_reader.onerror = ()=>{
          reject(
             "Not able to read file."
          );
        };
        file_reader.readAsDataURL(target.files[0]);
      }
      catch(err){
          reject("Failed: "+err.message);
      }
    }
  );
};

export default prepareFileForUpload;
