/*

useDBSetDoc

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function update_collection_product_user_views(
  collection_uid,
  collection_user_uid,
  new_views_dict,
  firebase,
  auth_user_uid
){
  return new Promise(
    (resolve)=>{
      firebase.user_product_collection_user_views(
        collection_user_uid,
        collection_uid,
        auth_user_uid
      ).set(
        new_views_dict
      ).then(
        resolve(true)
      ).catch(
        (error_out)=>{
          send_error(error_out);
          resolve(false);
        }
      );
    }
  );
}
