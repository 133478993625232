/*

Ability to change username (pro only!)

TODO: typescript, camelCase, make functional, non-default exports, design system...

*/
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import { M } from 'src/mvp22/constants';
import TextInputFeedback from 'src/mvp22/form-components/TextInputFeedback';
import Button from 'src/mvp22/form-components/Button';
import R from 'src/routes';
import { withFirebase } from 'src/mvp22/Firebase';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { requiresAuth } from 'src/mvp22/redux-components/requiresAuth';

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  color: ${M.COL.TEXT.BLACK};
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 16px;
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 351px;
  margin-top: 125px;
`;

const FormHeadingContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const InputTitle = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.MID};
  width: 100%;
`;

const InfoText = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.MID};
  width: 100%;
`;

const WarningText = styled(TEXTSTYLE.BODY3)`
  color: ${M.COL.TEXT.ERROR};
`;

const CancelAndConfirmButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const ButtonSpacer = styled.span`
  display: inline-block;
  width: 16px;
`;

const SuccessText = styled(TEXTSTYLE.BODY4)`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-items: center;
`;

const FailText = styled(TEXTSTYLE.BODY4)`
  color: ${M.COL.TEXT.ERROR};
`;

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid_username: true,
      username_text: '',
      changed_once: false,
      submitting: false,
      success: undefined,
    };
    this.handleChange = this.handleChange.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  componentDidMount() {
    // As is protected by requiredAuth, should always be loaded when mount done:
    if (this.props.firestore_user_owner.snapshot) {
      this._isMounted = true;
      this.setState({
        username_text: this.props.firestore_user_owner.alias_username
          ? this.props.firestore_user_owner.alias_username
          : '',
        invalid_username: false,
        changed_once: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goBack(event) {
    event.preventDefault();
    this.props.history.goBack();
    return false;
  }

  // controls the form
  handleChange(event) {
    const target = event.target;
    this.setState({
      username_text: target.value,
      invalid_username:
        event.currentTarget.value.length > 24 ||
        event.currentTarget.value.length < 5
          ? true
          : false,
      changed_once: true,
    });
  }

  doSubmit(event) {
    event.preventDefault();
    this.setState({
      submitting: true,
    });
    this.props.firebase
      .fast_api()({
        api: 'users_set_alias',
        new_alias: this.state.username_text,
      })
      .then((response) => {
        this._isMounted &&
          this.setState({
            submitting: false,
            success: response.data,
          });
      })
      .catch((error) => {
        console.error('Error requesting change in alias', error);
        this._isMounted &&
          this.setState({
            submitting: false,
          });
      });
  }

  refresh() {
    this.props.history.push(R.HOMEPAGE);
    window.location.reload();
  }

  // TODO: do we need to handle the edge case of someone changing their username once and then back to what they originally had
  renderMessage() {
    return (
      <InfoText>
        {this.props.firestore_user_owner.original_username !==
        this.props.firestore_user_owner.display_username ? (
          <WarningText>
            <br />
            Attention! If you change your username, previous links you have
            shared to your collections and profile will no longer work as they
            contain your current username -{' '}
            <b>{this.props.firestore_user_owner.display_username}</b>.
          </WarningText>
        ) : (
          <TEXTSTYLE.BODY3>
            <br />
            Don&#39;t worry, previous links you have shared to your collections
            and profile will still work (even though they contain your old
            username).
          </TEXTSTYLE.BODY3>
        )}
      </InfoText>
    );
  }

  render() {
    return (
      <FullBlank className="fullBlank">
        <TopMenu hug="edge" />
        {AddHelmetTitle('Change Username')}
        <Container className="container">
          <FormContainer>
            <FormHeadingContainer>
              <TEXTSTYLE.HEADING2>Change Username</TEXTSTYLE.HEADING2>
            </FormHeadingContainer>
            {this.props.firestore_user_owner.pro === true ? (
              <>
                <Seperator height="36px" />
                <FormHeadingContainer>
                  <InfoText>
                    Your current username is:{' '}
                    <b>{this.props.firestore_user_owner.display_username}</b>
                  </InfoText>
                  {this.renderMessage()}
                  <Seperator height="24px" />
                  <InputTitle>
                    Enter a new public username below to request a change:
                  </InputTitle>
                  <InputTitle>
                    Tip: You can still change back to your original username{' '}
                    <b>{this.props.firestore_user_owner.original_username}</b>{' '}
                    and ignore the warning above
                  </InputTitle>
                </FormHeadingContainer>
                <Seperator height="6px" />
                <TextInputFeedback
                  focusOnMount={true}
                  placeholder="lowercase, alphanumeric and underscores only"
                  msstyle="standard"
                  onChange={this.handleChange}
                  feedbackText={
                    this.state.invalid_username
                      ? 'Must be between 5 and 24 characters'
                      : ''
                  }
                  value={this.state.username_text}
                  name="name_text"
                  width="100%"
                  tabIndex={1}
                  onEnter={this.doSubmit}
                />
                <Seperator height="36px" />
                {this.state.success === true ? (
                  <SuccessText>
                    Success! Your username has been changed. Please now
                    <Seperator height="8px" />
                    <Button
                      onClick={this.refresh}
                      type="button"
                      text="Refresh Moonsift"
                      msstyle="option_positive"
                      width="200px"
                      tabIndex={4}
                    />
                  </SuccessText>
                ) : (
                  <>
                    {this.state.success === false ? (
                      <>
                        <FailText>
                          Failed to change username. Most likely this username
                          is in use already. Please try another.
                        </FailText>
                        <Seperator height="12px" />
                      </>
                    ) : (
                      ''
                    )}
                    <CancelAndConfirmButtonsContainer>
                      <Button
                        onClick={this.goBack}
                        type="button"
                        text="Back"
                        msstyle="option_negative"
                        width="123px"
                        tabIndex={4}
                      />
                      <ButtonSpacer />
                      <Button
                        type="button"
                        text={
                          this.state.submitting
                            ? 'Requesting'
                            : 'Request change'
                        }
                        msstyle="option_positive"
                        onClick={this.doSubmit}
                        working={this.state.submitting}
                        width="163px"
                        tabIndex={3}
                        enabled={
                          this.state.changed_once &&
                          !this.state.invalid_username
                        }
                      />
                    </CancelAndConfirmButtonsContainer>
                  </>
                )}
              </>
            ) : (
              <>
                <Seperator height="36px" />
                <TEXTSTYLE.BODY3>
                  Sorry, this feature is for{' '}
                  <TEXTSTYLE.LINKBOLD to={R.PRO_LANDING}>
                    Moonsift Pro
                  </TEXTSTYLE.LINKBOLD>{' '}
                  users only.
                </TEXTSTYLE.BODY3>
              </>
            )}
          </FormContainer>
        </Container>
      </FullBlank>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    firestore_user_owner: state.firestore_user_owner,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(requiresAuth(withFirebase(EditProfile))),
);
