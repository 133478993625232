import React from 'react';
import styled from 'styled-components';
import {
  Paper,
  paperClassNames,
  Stack,
  Typography,
  StandardButton,
  CloseIcon,
} from '@moonsifttech/design-system';

import { SustainabilityPollProps } from './SustainabilityPoll.types';

const UnstyledSustainabilityPoll: React.FC<SustainabilityPollProps> = ({
  className,
  style,
  onLessClick,
  onMoreClick,
  onAboutTheSameClick,
  onClose,
  showThanks,
}) => {
  return (
    <Paper className={className} style={style} variant="standard">
      <Stack
        justifyContent="center"
        alignItems="center"
        className="SustainabilityPoll-closeButton"
        onClick={onClose}
      >
        <CloseIcon size="small" />
      </Stack>
      {!showThanks && (
        <Stack justifyContent="center" alignItems="center">
          <Typography
            variant="primary.b18"
            className="SustainabilityPoll-titleText"
          >
            You're among the top 10% of savers on Moonsift!
          </Typography>
          <Typography
            variant="primary.r16"
            className="SustainabilityPoll-paragraph"
          >
            We're curious - has Moonsift influenced your shopping habits?
          </Typography>
          <Typography
            variant="primary.r16"
            className="SustainabilityPoll-paragraph"
          >
            Since you started using Moonsift, are you <b>returning</b> more or
            fewer items?
          </Typography>
          <Stack
            direction="row"
            spacing={10}
            className="SustainabilityPoll-buttonContainer"
          >
            <StandardButton
              className="SustainabilityPoll-button"
              onClick={onLessClick}
            >
              Fewer
            </StandardButton>
            <StandardButton
              className="SustainabilityPoll-button"
              onClick={onAboutTheSameClick}
            >
              About the same
            </StandardButton>
            <StandardButton
              className="SustainabilityPoll-button"
              onClick={onMoreClick}
            >
              More
            </StandardButton>
          </Stack>
        </Stack>
      )}
      {showThanks && (
        <Stack justifyContent="center" alignItems="center">
          <Typography
            variant="primary.r16"
            className="SustainabilityPoll-paragraph"
          >
            <b>Thank you for your response!</b>
            <br /> Look out for the results on our blog.
          </Typography>
        </Stack>
      )}
    </Paper>
  );
};

export const SustainabilityPoll = styled(UnstyledSustainabilityPoll)`
  &.${paperClassNames.variantStandard} {
    border: 0;
    background: var(--grey-6);
  }
  position: relative;
  max-width: 600px;
  padding: 24px;
  margin: 8px;
  .SustainabilityPoll-closeButton {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    color: var(--black);
  }
  .SustainabilityPoll-titleText {
    color: var(--background-purple);
    margin: 8px 0px;
    text-align: center;
  }
  .SustainabilityPoll-paragraph {
    margin: 8px 0px;
    text-align: center;
  }
  .SustainabilityPoll-buttonContainer {
    margin: 8px 0px;
  }
  .SustainabilityPoll-button {
    padding: 8px;
  }
`;
