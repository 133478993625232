import React from 'react';
import styled from 'styled-components';
import { Stack, StarIcon } from '@moonsifttech/design-system';

import { FiveStarsProps } from './FiveStars.types';

const UnstyledFiveStars: React.FC<FiveStarsProps> = ({
  className,
  style,
  size,
}) => {
  return (
    <Stack
      className={className}
      style={style}
      direction="row"
      alignItems="center"
      spacing={1}
    >
      {Array(5)
        .fill(null)
        .map((_, i) => (
          <StarIcon key={i} size={size} color="#FDCA47" />
        ))}
    </Stack>
  );
};

export const FiveStars = styled(UnstyledFiveStars)``;
