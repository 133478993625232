import React from 'react';
import styled from 'styled-components';
import { BookmarkIcon, BookmarkTwoToneIcon } from '@moonsifttech/design-system';

import { SquareButton } from 'src/components/collection/SquareButton';
import { BookmarkButtonProps } from './BookmarkButton.types';

const UnstyledBookmarkButton: React.FC<BookmarkButtonProps> = ({
  className,
  style,
  isBookmarked,
  working,
  onClick,
}) => {
  return (
    <SquareButton
      className={className}
      style={style}
      icon={isBookmarked ? <BookmarkTwoToneIcon /> : <BookmarkIcon />}
      loading={working}
      onClick={onClick}
    >
      {isBookmarked ? 'Bookmarked' : 'Bookmark'}
    </SquareButton>
  );
};

export const BookmarkButton = styled(UnstyledBookmarkButton)`
  --secondary-color: ${({ theme }) => theme.fns.getColor('common.black')};
`;
