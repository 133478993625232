import { Timestamp } from './services/DB';

const THREE_MONTHS_AGO = Timestamp.fromDate(
  new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
);

const ONE_MONTH_AGO = Timestamp.fromDate(
  new Date(Date.now() - 30 * 24 * 60 * 60 * 1000),
);

const FOUR_MONTHS_AGO = Timestamp.fromDate(
  new Date(Date.now() - 120 * 24 * 60 * 60 * 1000),
);

const SIX_MONTHS_AGO = Timestamp.fromDate(
  new Date(Date.now() - 180 * 24 * 60 * 60 * 1000),
);

export const Constants = {
  HOMEPAGEURL: process.env.REACT_APP_HOMEPAGEURL,
  APIADDRESS: process.env.REACT_APP_MOONSIFTAPI_URL,
  COOKIEMESSAGEKEY: 'cookie_message',
  LANDINGPAGE_COOKIE_KEY: 'landing_page',
  CLIENT_IDENTIFIER_COOKIE_KEY: 'client_identifier',
  COOKIEPATH: '/',
  REFERRAL_COOKIE_KEY: 'referral_code',
  PRO_CODE_COOKIE_KEY: 'pro_code',
  MOBILESWITCH: 922, // Pixels below which is mobile.
  EXTENSION_ID: 'hgbbfkafecdbocpcdkoahfkbkoodcaon',
  ONE_MONTH_AGO,
  THREE_MONTHS_AGO,
  FOUR_MONTHS_AGO,
  SIX_MONTHS_AGO,
  APP_REVIEW_NATIVE_REQUEST_BLOCK_TIME: SIX_MONTHS_AGO,
  SEARCH_ADDRESS: process.env.REACT_APP_HOMEPAGEURL + '/search',
};
