import React from 'react';
import { RateChromeExtension3Props } from './RateChromeExtension3.types';
import { Stack, Typography, CtaButton } from '@moonsifttech/design-system';
import { ChromeReviewWebstoreImage } from 'src/components/reviews/ChromeReviewWebstoreImage';
import styled from 'styled-components';
const UnstyledRateChromeExtension3: React.FC<RateChromeExtension3Props> = ({
  onClose,
  className,
  style,
}) => {
  return (
    <Stack
      className={className}
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={style}
    >
      <ChromeReviewWebstoreImage />
      <Typography
        className="RateChromeExtension3-questionHeading"
        variant="primary.b26"
        component="h3"
      >
        Thank you so much for your support
      </Typography>
      <Typography
        className="RateChromeExtension3-mainText"
        variant="primary.l20"
        component="p"
      >
        Your reviews keep our small team motivated to make Moonsift even better.
      </Typography>

      <CtaButton variant="primary-black" onClick={onClose} fullWidth>
        Done
      </CtaButton>
    </Stack>
  );
};

export const RateChromeExtension3 = styled(UnstyledRateChromeExtension3)`
  text-align: left;

  .RateChromeExtension3-mainText {
    margin-bottom: 50px;
    width: 100%;
  }
  .RateChromeExtension3-questionHeading {
    width: 100%;
  }
`;
