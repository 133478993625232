/*

View shown if user logs into account that has not been set up.
Could occur if provisioning fails but more likely if they click an authprovider on the LOGIN page not JOIN page...
This is to remind them that they didn't sign in with this method before!

Can also display if login takes too long and user data is not received, so could be offline.

*/
import { VFC, useContext, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServicesContext } from 'src/ServicesContext';
import R from 'src/routes';
import {
  NotProvisionedProps,
  NotProvisionedContainerProps,
} from './NotProvisioned.types';
import { NotProvisioned } from './NotProvisioned.component';
import { useHistory } from 'react-router-dom';
import { firestore_user_set_up_redux } from 'src/mvp22/redux-components/reducers/firestore_user_set_up';
import { RootState } from 'src';
import {
  ActionNames,
  EndKeepOnProvisioningPage,
  StartKeepOnProvisioningPage,
} from 'src/types';
const PROVISIONING_MINIMUM_DELAY_MS = 2500;

const NotProvisionedContainer: VFC<NotProvisionedContainerProps> = (props) => {
  /* Global state */
  const isSigningIn = useSelector<RootState, boolean>(
    ({ userData }) => userData.is_signing_in,
  );
  /* Local state */
  const [isProvisioning, setIsProvisioning] = useState(false);
  const [provisioningFailed, setProvisioningFailed] = useState(false);
  const [loadingFailed, setLoadingFailed] = useState(false);
  const [firstRun, setFirstRun] = useState(true);
  useEffect(() => {
    setFirstRun(false);
  }, [setFirstRun]);
  const [justLoggedIn] = useState(isSigningIn); // Just store the value on the mounting as will change when provisioned.

  /* Hooks + Contexts */
  const history = useHistory();
  const dispatch = useDispatch();
  const { auth, firebaseMVP22 } = useContext(ServicesContext);

  /* Handlers */
  const handleGoBack = useCallback<NotProvisionedProps['handleGoBack']>(
    (event) => {
      event.preventDefault();
      history.push(R.LOGOUT);
    },
    [history],
  );
  const handleProvisioning = useCallback(() => {
    setIsProvisioning(true);
    auth
      .provisionWithCurrentCredentials()
      .catch((error) => {
        setProvisioningFailed(true);
      })
      .then((user) => {
        if (user) {
          dispatch(firestore_user_set_up_redux(firebaseMVP22));
        }
      })
      .catch((error) => {
        setLoadingFailed(true);
      })
      .finally(() => {
        setIsProvisioning(false);
      });
  }, [auth, dispatch, firebaseMVP22]);

  // On load, if after login then automatically provision the account
  // Make sure this screen displays for a minimum time.
  // This is so the user can read that it is a NEW account being made
  useEffect(() => {
    if (justLoggedIn && firstRun) {
      dispatch<StartKeepOnProvisioningPage>({
        type: ActionNames.START_KEEP_ON_PROVISIONING_PAGE,
        payload: undefined,
      });
      setTimeout(
        () =>
          dispatch<EndKeepOnProvisioningPage>({
            type: ActionNames.END_KEEP_ON_PROVISIONING_PAGE,
            payload: undefined,
          }),
        PROVISIONING_MINIMUM_DELAY_MS,
      );
      handleProvisioning();
      // Do not clear the timeout as we want it to run regardless.
    }
  }, [justLoggedIn, dispatch, handleProvisioning, firstRun]);

  const handleForceReload = useCallback<
    NotProvisionedProps['handleForceReload']
  >((event) => {
    event.preventDefault();
    window.location.reload();
    return false;
  }, []);

  return (
    <NotProvisioned
      isProvisioning={isProvisioning}
      provisioningFailed={provisioningFailed}
      loadingFailed={loadingFailed}
      justLoggedIn={justLoggedIn}
      handleGoBack={handleGoBack}
      handleForceReload={handleForceReload}
    />
  );
};

export { NotProvisionedContainer as NotProvisioned };
