import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

// These components need to be refactored for specific imports:
import GetNotifications from 'src/mvp22/popup-components/GetNotifications';
import RefreshPrompt from 'src/mvp22/menu-components/RefreshPrompt';
import GlobalFonts from 'src/mvp22/fonts/fonts';
import Feedback from 'src/mvp22/popup-components/Feedback';
import { Modal } from 'src/mvp22/modal-components/Modal';
import { RouterSwitch } from 'src/components/core/RouterSwitch';
import { FooterPopup } from 'src/components/popups/FooterPopup';
import { checkIfIOS } from 'src/utils/checkIfIOS';
import { checkIfSafari } from 'src/utils/checkIfSafari';
import { AppProps } from './App.types';
import { ReviewRequest } from 'src/components/reviews/ReviewRequest';
const isIOS = checkIfIOS();
// TODO: check this still works on mobile!
// position: ${props=>props.isScrollable?'absolute':'fixed'};
const GlobalStyle = createGlobalStyle<{ isScrollable: boolean }>`
  body {
    overflow-y: ${(props) => (props.isScrollable ? 'scroll' : 'hidden')};
    width:100%;
    height:100%;
  }
  img {
    margin-bottom:0rem;
  }
  ::-webkit-scrollbar {
    background: var(--common-white);
    border-left: 1px solid var(--common-white);
    box-sizing: border-box;
    width: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--grey-4);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-3);
    border-radius: 8px;
    border: 3px solid transparent;
    background-clip: content-box;
    box-sizing: border-box;
    border-left: 5px solid transparent;
  }
`;

const Component: React.FC<AppProps> = ({
  windowWidth,
  isScrollable,
  isApp,
}) => {
  return (
    <div
      className={clsx(
        'EverythingContainer',
        'App-root',
        isIOS && 'App-isIOS',
        checkIfSafari() && 'App-isSafari',
        isApp && 'App-isApp',
      )}
    >
      <GlobalFonts />
      <GlobalStyle isScrollable={isScrollable} />
      <Helmet>
        <title>Moonsift</title>
        {!isApp && <meta name="apple-itunes-app" content="app-id=1609475671" />}
      </Helmet>
      <ReviewRequest />
      <Feedback />
      <RefreshPrompt />
      <Modal />
      <FooterPopup />
      <div className="MainBGContainer">
        <RouterSwitch />
      </div>
      <GetNotifications />
    </div>
  );
};

export const App = styled(Component)`
  .MainBGContainer {
    color: default;
  }

  .EverythingContainer {
    width: 100%;
    height: 100%;
    font-size: 0px;
    text-align: center;
    top: 0px;
    left: 0px;
    background-color: white;
    color: black;
  }
`;
