import React from 'react';
import styled from 'styled-components';
import { Paper, Stack, CloseIcon } from '@moonsifttech/design-system';
import { useClickOutside } from 'src/hooks/useClickOutside';

const UnstyledModalContent = ({
  className,
  style,
  children,
  onClose,
}: {
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  onClose?: Function;
}) => {
  const ref = useClickOutside<HTMLDivElement>(onClose ? onClose : () => null);

  return (
    <Paper className={className} style={style} ref={ref}>
      <div className="ModalContent-innerContainer">
        <Stack
          className="ModalContent-closeButton"
          justifyContent="center"
          alignItems="center"
          onClick={onClose ? (ev) => onClose(ev) : () => null}
        >
          <CloseIcon />
        </Stack>
        {children}
      </div>
    </Paper>
  );
};

export const ModalContent = styled(UnstyledModalContent)`
  position: relative;
  padding: 54px 20px 32px;
  width: 480px;

  .ModalContent-closeButton {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 42px;
    height: 42px;
    cursor: pointer;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 32px 36px;

    h1 {
      padding-right: 38px;
    }

    .ModalContent-closeButton {
      top: 24px;
      right: 24px;
    }
  }
`;
