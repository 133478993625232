/*

Note we can trigger this review request and it should not show if the user has already rated the item.

*/
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServicesContext } from 'src/ServicesContext';
import { Constants } from 'src/constants';
import { RootState } from 'src/index';
import { useDBUpdateDoc } from './useDBUpdateDoc';
import { ActionNames, HideAutoReviewRequests } from 'src/types';

export const useNativeReviewRequest = (uiConditionsMet: boolean) => {
  const dispatch = useDispatch();
  const { db, mobile, analytics } = useContext(ServicesContext);
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );
  const isApp = mobile.isApp;
  // we don't want updateUserOwnerOnce to keep changing as we have a useEffect based on it!
  const [updateUserOwnerOnce] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID ?? ''),
    true,
  );
  // What about the DB conditions?
  const appDBConditionsMetNative = useSelector<RootState, boolean>(
    ({ firestore_user_owner }) =>
      uiConditionsMet &&
      firestore_user_owner.snapshot?.can_trigger_review_app === true &&
      (firestore_user_owner.snapshot?.user_requested_review_app_native <
        Constants.APP_REVIEW_NATIVE_REQUEST_BLOCK_TIME ||
        firestore_user_owner.snapshot?.user_requested_review_app_native ===
          undefined),
  );

  useEffect(() => {
    if (isApp && appDBConditionsMetNative) {
      mobile.requestInAppReview();
      dispatch<HideAutoReviewRequests>({
        type: ActionNames.UI_CLOSE_REVIEW_REQUEST,
        payload: undefined,
      });
      analytics.recordEvent('WebApp:Reviews:ShowAppNativeReviewRequest');
      updateUserOwnerOnce({
        user_requested_review_app_native: db.serverTimestamp(),
      });
    }
  }, [
    analytics,
    uiConditionsMet,
    mobile,
    appDBConditionsMetNative,
    updateUserOwnerOnce,
    db,
    isApp,
    dispatch,
  ]);
};
