import React from 'react';
import { Fab, MoreTwoToneIcon } from '@moonsifttech/design-system';

import { ModalMenu } from 'src/components/core/ModalMenu';
import { MoreProfileOptionsMenu } from 'src/components/profile/MoreProfileOptionsMenu';
import { MoreProfileOptionsButtonProps } from './MoreProfileOptionsButton.types';

export const MoreProfileOptionsButton: React.FC<
  MoreProfileOptionsButtonProps
> = ({
  className,
  style,
  profileUserUID,
  isMenuOpen,
  onClick,
  onClose,
  onShare,
  onEdit,
}) => {
  return (
    <ModalMenu
      isOpen={isMenuOpen}
      onRequestClose={onClose}
      menu={
        <MoreProfileOptionsMenu
          profileUserUID={profileUserUID}
          onShare={onShare}
          onEdit={onEdit}
        />
      }
    >
      <Fab className={className} style={style} size={40} onClick={onClick}>
        <MoreTwoToneIcon secondaryColor="grey.5" size="larger" />
      </Fab>
    </ModalMenu>
  );
};
