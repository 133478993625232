/*

The bottom of "static" pages is this black bar:

TODO: functional, typescript

*/
import React from 'react';
import { Component } from 'react';
import { M } from 'src/mvp22/constants';
import R, { EXTERNAL } from 'src/routes';
import MEDIA from 'src/mvp22/media';
import styled from 'styled-components';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import LogoAndText from 'src/mvp22/image-components/LogoAndText';

const OuterContainer = styled.div`
  width: 100%;
  background-color: ${M.COL.BG.BLACK};
  justify-content: center;
  display: flex;
`;

const Container = styled.div`
  color: ${M.COL.TEXT.WHITE};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  padding: 42px 16px;
  max-width: ${M.MAXWIDTH};
  box-sizing: border-box;
`;

const Seperator = styled.div`
  width: 100%;
  height: ${(props) => props.height};
`;

const VertSep = styled.div`
  width: 22px;
`;

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialImg = styled.img`
  height: 22px;
`;

const VeryBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const BottomBlock = styled(TEXTSTYLE.BODY5)`
  white-space: nowrap;
`;

const FlexStack = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 60px;
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
/**
 * Main Viewer's Bottom Menu
 */
class BottomBar extends Component {
  render() {
    return (
      <OuterContainer>
        <Container>
          <LogoAndText heighttype="B" />
          <Seperator height="32px" />
          <FlexRow>
            <FlexStack>
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.EXTERNALLINKUNDERLINE href={EXTERNAL.AI_LANDING}>
                  AI Shopping Copilot
                </TEXTSTYLE.EXTERNALLINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.WISHLIST_MAKER}>
                  Universal Wishlist
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.REGISTRY_LANDING}>
                  Universal Gift list or Registry
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
            </FlexStack>
            <FlexStack>
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.BABY_REGISTRY_LANDING}>
                  Universal Baby Registry
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.BIRTHDAY_LANDING}>
                  Universal Birthday Wishlist
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.CHRISTMAS_LANDING}>
                  Universal Christmas List
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
            </FlexStack>
            <FlexStack>
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.CREATOR_LANDING}>
                  Moonsift for Creators
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.LINKUNDERLINE to={R.TASTEMAKERLANDING}>
                  Moonsift for Stylists and Personal Shoppers
                </TEXTSTYLE.LINKUNDERLINE>
              </TEXTSTYLE.BODY4>
              <Seperator height="16px" />
              <TEXTSTYLE.BODY4>
                <TEXTSTYLE.EXTERNALLINKUNDERLINE href={EXTERNAL.GUIDES}>
                  Moonsift guides
                </TEXTSTYLE.EXTERNALLINKUNDERLINE>
              </TEXTSTYLE.BODY4>
            </FlexStack>
          </FlexRow>
          <Seperator height="32px" />
          <RowFlex>
            <TEXTSTYLE.LINKA href={EXTERNAL.INSTAGRAM} target="_blank">
              <SocialImg src={MEDIA.INSTAGRAM_DARK} />
            </TEXTSTYLE.LINKA>
            <VertSep />
            <TEXTSTYLE.LINKA href={EXTERNAL.FACEBOOK} target="_blank">
              <SocialImg src={MEDIA.FACEBOOK_DARK} />
            </TEXTSTYLE.LINKA>
            <VertSep />
            <TEXTSTYLE.LINKA href={EXTERNAL.MEDIUM} target="_blank">
              <SocialImg src={MEDIA.MEDIUM_DARK} />
            </TEXTSTYLE.LINKA>
            <VertSep />
            <TEXTSTYLE.LINKA href={EXTERNAL.LINKEDIN} target="_blank">
              <SocialImg src={MEDIA.LINKEDIN_DARK} />
            </TEXTSTYLE.LINKA>
          </RowFlex>
          <Seperator height="32px" />
          <TEXTSTYLE.OTHER4>
            Made with love in London, Edinburgh and San Francisco.
          </TEXTSTYLE.OTHER4>
          <Seperator height="10px" />
          <VeryBottom>
            <BottomBlock>
              <TEXTSTYLE.LINK to={R.ABOUT_US}>About Moonsift</TEXTSTYLE.LINK>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <TEXTSTYLE.LINK to={R.PRIVACYPOLICY}>
                Privacy Policy
              </TEXTSTYLE.LINK>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <TEXTSTYLE.LINK to={R.TERMS}>Terms</TEXTSTYLE.LINK>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <TEXTSTYLE.LINKA href={EXTERNAL.JOBS}>Hiring</TEXTSTYLE.LINKA>
            </BottomBlock>
            <BottomBlock>
              <TEXTSTYLE.LINKA href={R.SITEMAP}>Sitemap</TEXTSTYLE.LINKA>{' '}
              &nbsp;&nbsp;|&nbsp;&nbsp;© {new Date().getFullYear()} Moonsift
            </BottomBlock>
          </VeryBottom>
        </Container>
      </OuterContainer>
    );
  }
}

export default BottomBar;
