import { ActionNames } from 'src/types/actions';
import {
  DBDocumentChannel,
  DBQueryChannel,
  BaseDBKindsUnion,
  BaseDBActionTypes,
  GetOrListenDocActionPayload,
  GetOrListenQueryActionPayload,
} from 'src/types';

export function baseDBSetQuery<K extends BaseDBKindsUnion>(
  payload: K['item'][],
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_SET_QUERY] {
  return {
    type: ActionNames.BASEDB_SET_QUERY,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBSetDoc<K extends BaseDBKindsUnion>(
  payload: K['item'] | undefined | null,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_SET_DOC] {
  return {
    type: ActionNames.BASEDB_SET_DOC,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBSetDocListener<K extends BaseDBKindsUnion>(
  payload: DBDocumentChannel,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_DOC] {
  return {
    type: ActionNames.BASEDB_SET_LISTENER_DOC,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBSetQueryListener<K extends BaseDBKindsUnion>(
  payload: DBQueryChannel,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_SET_LISTENER_QUERY] {
  return {
    type: ActionNames.BASEDB_SET_LISTENER_QUERY,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBListenDoc<K extends BaseDBKindsUnion>(
  payload: GetOrListenDocActionPayload<K['item']>,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_LISTEN_DOC] {
  return {
    type: ActionNames.BASEDB_LISTEN_DOC,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBListenQuery<K extends BaseDBKindsUnion>(
  payload: GetOrListenQueryActionPayload<K['item']>,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_LISTEN_QUERY] {
  return {
    type: ActionNames.BASEDB_LISTEN_QUERY,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBGetDoc<K extends BaseDBKindsUnion>(
  payload: GetOrListenDocActionPayload<K['item']>,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_GET_DOC] {
  return {
    type: ActionNames.BASEDB_GET_DOC,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBGetQuery<K extends BaseDBKindsUnion>(
  payload: GetOrListenQueryActionPayload<K['item']>,
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_GET_QUERY] {
  return {
    type: ActionNames.BASEDB_GET_QUERY,
    payload,
    kind,
    storeAs,
  };
}

export function baseDBUnlistenSingle<K extends BaseDBKindsUnion>(
  kind: K['name'],
  storeAs: K['storeAs'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_UNLISTEN_SINGLE] {
  return {
    type: ActionNames.BASEDB_UNLISTEN_SINGLE,
    payload: undefined,
    kind,
    storeAs,
  };
}

export function baseDBUnlistenMap<K extends BaseDBKindsUnion>(
  kind: K['name'],
): BaseDBActionTypes<K>[ActionNames.BASEDB_UNLISTEN_MAP] {
  return {
    type: ActionNames.BASEDB_UNLISTEN_MAP,
    payload: undefined,
    kind,
  };
}
