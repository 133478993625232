import { CollectionType } from 'src/types/models/collection.model';

const mapPublicLevelToOptionKey: Record<number, CollectionType> = {
  10: 'public',
  7: 'private',
  8: 'private',
  0: 'hidden',
};

export function getCollectionType(
  publicLevel: number,
): CollectionType | undefined {
  return mapPublicLevelToOptionKey[publicLevel];
}
