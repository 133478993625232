import { useSelector } from 'react-redux';
import { RootState } from 'src';

type Output = {
  isLoggedIn: boolean;
}

export const useLoggedIn = (): Output => {
  const userId = useSelector<RootState, string | null>(
    ({ auth }) => auth.id,
  );


  return {
    isLoggedIn: userId != null,
  };
};
