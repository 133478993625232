import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src';
import { ActionNames, WorkingAction } from 'src/types';
/*

Returns the whole working dict, so inefficient updates expected...!
Note functions that are returned require storeAs to be specified dynamically, not on hook inclusion.
Hence we don't reuse useWorking here.

*/
export const useWorkingDynamic = (): [
  (storeAs: string, value: boolean) => void,
  (storeAs: string) => void,
  { [storeAs: string]: boolean },
] => {
  const dispatch = useDispatch();
  const setWorking = useCallback(
    (storeAs: string, value: boolean) => {
      dispatch<WorkingAction>({
        type: ActionNames.WORKING_SET,
        payload: {
          storeAs,
          value,
        },
      });
    },
    [dispatch],
  );
  const unsetWorking = useCallback(
    (storeAs: string) => {
      dispatch<WorkingAction>({
        type: ActionNames.WORKING_UNSET,
        payload: storeAs,
      });
    },
    [dispatch],
  );
  const working = useSelector<RootState, { [storeAs: string]: boolean }>(
    (state) => state.working,
  );
  return [setWorking, unsetWorking, working];
};
