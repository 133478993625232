/*
Displays a single notification

TODO: typescript, camelCase, design-system...
Consider splitting into individual notification types.
*/
import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { InfoIcon } from '@moonsifttech/design-system';

import R from 'src/routes';
import { M } from 'src/mvp22/constants';
import ProfileImage from 'src/mvp22/image-components/ProfileImage';
import MEDIA from 'src/mvp22/media';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import {
  notification_in_past,
  check_item_is_earlier_than,
} from 'src/mvp22/core-components/date_time_functions';
import { Link } from 'src/components/core/Link';
import { ServicesContext } from 'src/ServicesContext';

const NotificationContainer = styled(Link)`
  width: 100%;
  display: flex;
  text-decoration: none;
  &:hover {
    background-color: ${M.COL.BG.LIGHT};
  }
  padding: 12px 16px;
  box-sizing: border-box;
`;

const LeftImageContainer = styled.div`
  width: 58px;
  align-items: flex-start;
  display: flex;
  flex-shrink: 0;
`;

const LeftImage = styled.img`
  width: 42px;
  height: 42px;
  object-fit: cover;
  object-position: center;
`;

const MiddleInfoContainer = styled.div`
  flex-shrink: 1;
  width: 100%;
  overflow: hidden;
`;

const DescriptiveText = styled(TEXTSTYLE.BODY4)`
  text-align: left;
  overflow: hidden;
  color: ${(props) => (props.seen ? M.COL.TEXT.MID : M.COL.TEXT.BLACK)};
  margin-bottom: 4px;
`;

const BottomMiddle = styled.div`
  display: flex;
`;

const NotificationTag = styled(TEXTSTYLE.OTHER4)`
  background-color: var(--common-black);
  color: ${M.COL.TEXT.WHITE};
  padding: 1px 6px;
  border-radius: 2px;
  align-items: center;
  display: flex;
`;

const TagImage = styled.img`
  height: 10px;
  margin-right: 5px;
`;

const TimeSince = styled(TEXTSTYLE.BODY4)`
  color: ${(props) => (props.seen ? M.COL.TEXT.MID : M.COL.TEXT.BLACK)};
  margin-left: 16px;
`;

const RightDotContainer = styled.div`
  width: 36px;
  align-items: flex-start;
  align-content: flex-end;
  justify-content: flex-end;
  display: flex;
  flex-shrink: 0;
`;

const RightDotImage = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 6px;
  background-color: ${M.COL.BUTTON.YELLOW};
`;

const PriceNotification = (props) => {
  var collection_name = props.data.collection_name
    ? props.data.collection_name
    : 'your collection';
  collection_name =
    collection_name.length > 40
      ? collection_name.slice(0, 38) + '...'
      : collection_name;
  var product_description = props.data.product_description
    ? props.data.product_description
    : 'product';
  product_description =
    product_description.length > 40
      ? product_description.slice(0, 38) + '...'
      : product_description;
  const drop_percentage = props.data.drop_percentage
    ? props.data.drop_percentage + '%'
    : 'Money';
  const display_username =
    props.auth_display_username && props.data.user_uid === props.auth_user_uid
      ? props.auth_display_username
      : props.data.user_uid;
  return (
    <NotificationContainer
      to={
        R.COLLECTION +
        '/' +
        display_username +
        '/' +
        props.data.collection_uid +
        '?product=' +
        props.data.entry_uid
      }
      onClick={props.onNotificationClick}
    >
      <LeftImageContainer>
        <LeftImage src={props.data.product_image} />
      </LeftImageContainer>
      <MiddleInfoContainer>
        <DescriptiveText seen={props.seen}>
          {drop_percentage} off <b>{product_description}</b> saved to{' '}
          {collection_name}
        </DescriptiveText>
        <BottomMiddle>
          <NotificationTag seen={props.seen}>
            <TagImage src={MEDIA.NOTIFICATION_TAG_PRICE_DROP} />
            Price Drop
          </NotificationTag>
          <TimeSince seen={props.seen}>{props.time_since}</TimeSince>
        </BottomMiddle>
      </MiddleInfoContainer>
      <RightDotContainer>
        {props.seen ? '' : <RightDotImage />}
      </RightDotContainer>
    </NotificationContainer>
  );
};

const SettingsNotification = (props) => {
  return (
    <NotificationContainer
      to={R.EDIT_EMAIL_PREFERENCES}
      onClick={props.onNotificationClick}
    >
      <LeftImageContainer>
        <LeftImage src={MEDIA.LOGO_DEFAULT} />
      </LeftImageContainer>
      <MiddleInfoContainer>
        <DescriptiveText seen={props.seen}>
          <b>Enable price drop notifications</b> on the items you save
        </DescriptiveText>
        <BottomMiddle>
          <NotificationTag seen={props.seen}>
            <TagImage src={MEDIA.NOTIFICATION_TAG_SETTINGS} />
            Settings
          </NotificationTag>
          <TimeSince seen={props.seen}>{props.time_since}</TimeSince>
        </BottomMiddle>
      </MiddleInfoContainer>
      <RightDotContainer>
        {props.seen ? '' : <RightDotImage />}
      </RightDotContainer>
    </NotificationContainer>
  );
};

const InfoNotification = styled(
  ({ className, style, data, seen, time_since, onNotificationClick }) => {
    return (
      <NotificationContainer
        className={className}
        style={style}
        to={data.link}
        onClick={onNotificationClick}
      >
        <LeftImageContainer>
          <LeftImage src={MEDIA.LOGO_DEFAULT} />
        </LeftImageContainer>
        <MiddleInfoContainer>
          <DescriptiveText
            seen={seen}
            dangerouslySetInnerHTML={{
              __html: data.text,
            }}
          />
          <BottomMiddle>
            <NotificationTag seen={seen}>
              <InfoIcon className="InfoNotification-infoIcon" size="small" />
              Info
            </NotificationTag>
            <TimeSince seen={seen}>{time_since}</TimeSince>
          </BottomMiddle>
        </MiddleInfoContainer>
        <RightDotContainer>{seen ? '' : <RightDotImage />}</RightDotContainer>
      </NotificationContainer>
    );
  },
)`
  .InfoNotification-infoIcon {
    font-size: 12px;
    margin-right: 5px;
  }
`;

const CommentNotification = (props) => {
  var collection_name = props.data.collection_name
    ? props.data.collection_name
    : 'your collection';
  collection_name =
    collection_name.length > 40
      ? collection_name.slice(0, 38) + '...'
      : collection_name;
  var product_description = props.data.product_description
    ? props.data.product_description
    : 'product';
  product_description =
    product_description.length > 40
      ? product_description.slice(0, 38) + '...'
      : product_description;
  const display_username =
    props.auth_display_username && !props.data.collection_user_uid
      ? props.auth_display_username
      : props.data.auth_user_uid;
  var user_name = props.data.user_name ? props.data.user_name : 'A user';
  user_name =
    user_name.length > 40 ? user_name.slice(0, 38) + '...' : user_name;
  const number_of_reactions = props.data.number_of_reactions
    ? props.data.number_of_reactions
    : 1;
  const OTHERS =
    number_of_reactions > 1
      ? ' and ' + (number_of_reactions - 1) + ' others'
      : '';
  return (
    <NotificationContainer
      to={
        R.COLLECTION +
        '/' +
        display_username +
        '/' +
        props.data.collection_uid +
        '?product=' +
        props.data.product_uid
      }
      onClick={props.onNotificationClick}
    >
      <LeftImageContainer>
        <ProfileImage
          profile_image_code={props.data.profile_image_code}
          name={props.data.user_name}
          user_uid={props.data.user_uid}
          scale={42}
        />
      </LeftImageContainer>
      <MiddleInfoContainer>
        <DescriptiveText seen={props.seen}>
          {props.type === 'comment' ? (
            props.data.product_uid ? (
              <>
                {user_name} has commented on <b>{product_description}</b> in
                your {collection_name} collection
              </>
            ) : (
              <>
                {user_name} has commented in your {collection_name} collection
              </>
            )
          ) : props.type === 'upvote' ? (
            <>
              {user_name}
              {OTHERS} liked <b>{product_description}</b> in your{' '}
              {collection_name} collection
            </>
          ) : (
            <>
              {user_name}
              {OTHERS} downvoted <b>{product_description}</b> in your{' '}
              {collection_name} collection
            </>
          )}
        </DescriptiveText>
        <BottomMiddle>
          {props.type === 'comment' ? (
            <NotificationTag seen={props.seen}>
              <TagImage src={MEDIA.NOTIFICATION_TAG_COMMENT} />
              Comment
            </NotificationTag>
          ) : props.type === 'upvote' ? (
            <NotificationTag seen={props.seen}>
              <TagImage src={MEDIA.NOTIFICATION_TAG_UPVOTE} />
              Liked
            </NotificationTag>
          ) : (
            <NotificationTag seen={props.seen}>
              <TagImage src={MEDIA.NOTIFICATION_TAG_DOWNVOTE} />
              Downvoted
            </NotificationTag>
          )}
          <TimeSince seen={props.seen}>{props.time_since}</TimeSince>
        </BottomMiddle>
      </MiddleInfoContainer>
      <RightDotContainer>
        {props.seen ? '' : <RightDotImage />}
      </RightDotContainer>
    </NotificationContainer>
  );
};

const NotificationTile = (props) => {
  const SEEN = check_item_is_earlier_than(
    props.data.created_at,
    props.time_at_last_view,
  );
  const TIME_SINCE = notification_in_past(
    props.time_now,
    props.data.created_at,
  );
  const { analytics } = useContext(ServicesContext);
  const type = props.data.type;
  const data = props.data;
  const onNotificationClick = useCallback(() => {
    analytics.recordEvent('WebApp:Notification:Click', {
      type,
      data,
    });
  }, [analytics, type, data]);
  switch (props.data.type) {
    case 'price':
      return (
        <PriceNotification
          data={props.data}
          time_since={TIME_SINCE}
          seen={SEEN}
          auth_display_username={props.auth_display_username}
          auth_user_uid={props.auth_user_uid}
          onNotificationClick={onNotificationClick}
        />
      );
    case 'comment':
    case 'upvote':
    case 'downvote':
      return (
        <CommentNotification
          data={props.data}
          time_since={TIME_SINCE}
          seen={SEEN}
          auth_display_username={props.auth_display_username}
          auth_user_uid={props.auth_user_uid}
          type={props.data.type}
          onNotificationClick={onNotificationClick}
        />
      );
    case 'settings':
      return (
        <SettingsNotification
          data={props.data}
          time_since={TIME_SINCE}
          seen={SEEN}
          onNotificationClick={onNotificationClick}
        />
      );
    case 'info':
      return (
        <InfoNotification
          data={props.data}
          time_since={TIME_SINCE}
          seen={SEEN}
          onNotificationClick={onNotificationClick}
        />
      );
    default:
      return '';
  }
};

export default NotificationTile;
