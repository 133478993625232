import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import R from 'src/routes';
import { CollectionBottomPrompt } from './CollectionBottomPrompt.component';
import { CollectionBottomPromptContainerProps } from './CollectionBottomPrompt.types';
import { ServicesContext } from 'src/ServicesContext';

const CollectionBottomPromptContainer: React.FC<
  CollectionBottomPromptContainerProps
> = (props) => {
  const history = useHistory();
  const { analytics } = useContext(ServicesContext);

  const onMakeGiftRegistryClick = useCallback(() => {
    history.push(R.REGISTRY_LANDING);
    window.scrollTo(0, 0);
    analytics.recordEvent(
      'WebApp:CollectionLoggedOutMakeGiftRegistryButton:Pressed',
    );
  }, [history, analytics]);

  const onClickSignUp = useCallback(() => {
    history.push(R.LANDING);
    window.scrollTo(0, 0);
    analytics.recordEvent('WebApp:CollectionLoggedOutMakeWishlist:Pressed');
  }, [history, analytics]);

  const onImAContentCreatorClick = useCallback(() => {
    history.push(R.CREATOR_LANDING);
    window.scrollTo(0, 0);
    analytics.recordEvent('WebApp:CollectionLoggedOutContentCreator:Pressed');
  }, [history, analytics]);

  return (
    <CollectionBottomPrompt
      onMakeGiftRegistryClick={onMakeGiftRegistryClick}
      onMakeWishlistClick={onClickSignUp}
      onImAContentCreatorClick={onImAContentCreatorClick}
      {...props}
    />
  );
};

export { CollectionBottomPromptContainer as CollectionBottomPrompt };
