import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ActionNames, UIAction } from 'src/types';

/*

Puts in redux the window size for determining desktop or mobile:

*/

export const useReactWindowSize = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    const resize_listener = () => {
      dispatch<UIAction>({
        type: ActionNames.UI_WINDOW_SIZE_SET,
        payload: undefined, // Reducer itself reads from window directly, so payload is undefined.
      });
    };
    window.addEventListener('resize', resize_listener);
    // if resizing during loading catch it later...
    setTimeout(resize_listener, 1000);
    return () => window.removeEventListener('resize', resize_listener);
  }, [dispatch]);
};
