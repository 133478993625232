import React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { manager as modalManager } from '@moonsifttech/design-system';

import { RootState } from 'src/index';
import {
  MoreProfileOptionsButtonProps,
  MoreProfileOptionsButtonContainerProps,
} from './MoreProfileOptionsButton.types';
import { MoreProfileOptionsButton } from './MoreProfileOptionsButton.component';

const MoreProfileOptionsButtonContainer: React.FC<
  MoreProfileOptionsButtonContainerProps
> = ({ onShare: onShareProp, onEdit: onEditProp, ...otherProps }) => {
  // Determine whether we are on mobile
  const theme = useTheme();
  const mobileBreakpoint = theme.breakpoints.md;
  const isMobile = useSelector<RootState, boolean>(
    ({ ui }) => ui.windowWidth < mobileBreakpoint,
  );

  // Local state
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  // Handlers
  const handleClick = React.useCallback<
    MoreProfileOptionsButtonProps['onClick']
  >(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  }, []);

  const handleClose = React.useCallback<
    MoreProfileOptionsButtonProps['onClose']
  >(() => {
    setIsMenuOpen(false);
  }, []);

  const handleShare = React.useCallback<
    MoreProfileOptionsButtonProps['onShare']
  >(
    (event) => {
      setIsMenuOpen(false);
      onShareProp(event);
    },
    [onShareProp],
  );

  const handleEdit = React.useCallback<MoreProfileOptionsButtonProps['onEdit']>(
    (event) => {
      // Close the desktop, dropdown instance of the more options menu.
      setIsMenuOpen(false);

      if (isMobile) {
        // With this line of code we force the execution of the opening strategy.
        // By providing a non-existent modal we are forcing all the existent
        // modals to close.
        modalManager.open('-1');
        // And here we delay calling `onEdit` so that it plays nicely with the
        // Modal animation before we navigate Edit Profile page.
        setTimeout(() => onEditProp(event), 250);
      } else {
        // On desktop, as we are using react-overlays instead of our modal, we
        // just need to call `onEdit` as the more options menu has already been
        // closed.
        onEditProp(event);
      }
    },
    [isMobile, onEditProp],
  );

  return (
    <MoreProfileOptionsButton
      isMenuOpen={isMenuOpen}
      onClick={handleClick}
      onClose={handleClose}
      onShare={handleShare}
      onEdit={handleEdit}
      {...otherProps}
    />
  );
};

export { MoreProfileOptionsButtonContainer as MoreProfileOptionsButton };
