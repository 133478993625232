import { VFC, useState, useCallback } from 'react';

import { CollectionEarningsTableRow } from './CollectionEarningsTableRow.component';
import { CollectionEarningsTableRowContainerProps } from './CollectionEarningsTableRow.types';

const CollectionEarningsTableRowContainer: VFC<
  CollectionEarningsTableRowContainerProps
> = (props) => {
  // Toggle list of items
  const [expanded, setExpanded] = useState(false);
  const handleItemsToggle = useCallback(() => {
    setExpanded((prevExpanded) => !prevExpanded);
  }, []);

  return (
    <CollectionEarningsTableRow
      expanded={expanded}
      onItemsToggle={handleItemsToggle}
      {...props}
    />
  );
};

export { CollectionEarningsTableRowContainer as CollectionEarningsTableRow };
