import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  StandardButton,
  ControlledTextField,
  Form,
  Stack,
  Link,
} from '@moonsifttech/design-system';

import { StylistApplicationTwoProps } from './StylistApplicationTwo.types';
import R from 'src/routes';

const UnstyledStylistApplicationTwo: React.FC<StylistApplicationTwoProps> = ({
  className,
  style,
  initialValues,
  validationSchema,
  formRef,
  onSubmit,
  onBack,
}) => {
  return (
    <div className={className} style={style}>
      <div className="StylistApplicationTwo-content">
        <Typography
          className="StylistApplicationTwo-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Where do you connect with your audience?
        </Typography>
        <Typography variant="primary.l20" component="p">
          Link us to at least one of your social media profiles or blogs
        </Typography>
        <Form
          formRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Stack className="StylistApplicationTwo-inputGroup">
            <Typography
              className="StylistApplicationTwo-inputLabel"
              variant="primary.r14"
              color="grey.2"
              component="label"
            >
              Name of primary channel
            </Typography>
            <ControlledTextField
              id="channel-name-1"
              name="channels[0].name"
              placeholder="e.g. YouTube"
            />
            <Typography
              className="StylistApplicationTwo-inputLabel"
              variant="primary.r14"
              color="grey.2"
              component="label"
            >
              Username or profile URL
            </Typography>
            <ControlledTextField
              id="channel-url-1"
              name="channels[0].url"
              placeholder="e.g. https://www.youtube.com/channel/..."
            />
          </Stack>
          <Stack className="StylistApplicationTwo-inputGroup">
            <Typography
              className="StylistApplicationTwo-inputLabel"
              variant="primary.r14"
              color="grey.2"
              component="label"
            >
              Name of secondary channel
            </Typography>
            <ControlledTextField
              id="channel-name-2"
              name="channels[1].name"
              placeholder="e.g. Instagram"
            />
            <Typography
              className="StylistApplicationTwo-inputLabel"
              variant="primary.r14"
              color="grey.2"
              component="label"
            >
              Username or profile URL
            </Typography>
            <ControlledTextField
              id="channel-url-2"
              name="channels[1].url"
              placeholder="e.g. @username"
            />
          </Stack>
          <Stack direction="row" spacing={12}>
            <StandardButton variant="primary-white" onClick={onBack}>
              Back
            </StandardButton>
            <StandardButton type="submit" variant="primary-black">
              Next
            </StandardButton>
          </Stack>
        </Form>
        <Stack alignItems="flex-end">
          <Typography variant="primary.l14" component="p">
            <Link to={R.HOME}>Cancel</Link>
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export const StylistApplicationTwo = styled(UnstyledStylistApplicationTwo)`
  padding: 100px 0 0;

  .StylistApplicationTwo-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .StylistApplicationTwo-heading {
    margin-bottom: 8px;
  }

  .StylistApplicationTwo-inputGroup {
    margin-bottom: 32px;

    > div:first-of-type {
      margin-bottom: 12px;
    }
  }

  .StylistApplicationTwo-inputLabel {
    margin-bottom: 8px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
`;
