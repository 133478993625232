/*

This is a very old component but is used all over the place...!

TODO: Completely replace with the buttons in the design system!

*/
import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';
import windowSize from 'src/mvp22/WindowSize';
import LoadingDots from 'src/mvp22/image-components/LoadingDots';

const ButtonBase = styled.button`
  font-family:${M.FONT};
  outline-width: 0;
  box-sizing:border-box;
  display:flex;
  justify-content:center;
  align-items:center;
  font-style: normal;
  width:${props => props.width};
  max-width:${props => props.maxWidth};
  position:relative;
`;

const CTA = styled(ButtonBase)`
  cursor:pointer;
`;

const CTA1st = styled(CTA)`
  background-color:${M.COL.BUTTON.YELLOW};
  color:${M.COL.TEXT.WHITE};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border: none;
  font-weight: bold;
  padding: 0px 26px;
  &:hover {
   background-color:${M.COL.BUTTON.BLACK};
   border: 1px solid ${M.COL.BUTTON.WHITE};
  }
  &:focus {
   background-color:${M.COL.BUTTON.BLACK};
   border:1px solid ${M.COL.BUTTON.WHITE};
  }
  &:active {
   background-color:${M.COL.BUTTON.BLACK};
   border: 1px solid ${M.COL.BUTTON.WHITE};
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }
`;

const CTA1stDesktop = styled(CTA1st)`
  font-size:26px;
  line-height: 30px;
  height:56px;
  border-radius:28px;
`;

const CTA1stMobile = styled(CTA1st)`
  font-size:18px;
  line-height: 26px;
  height:42px;
  border-radius:28px;
`;

export const CTA2nd = styled(CTA)`
  background-color:${M.COL.BUTTON.LIGHTYELLOW};
  border-radius:28px;
  font-weight:500;
  border: 1px solid ${M.COL.BUTTON.YELLOW};
  color:${M.COL.TEXT.BLACK};
  font-size:16px;
  line-height: 24px;
  height:42px;
  padding: 0px 26px;
  &:hover {
   background-color:${M.COL.BUTTON.MIDYELLOW};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:focus {
   background-color:${M.COL.BUTTON.MIDYELLOW};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active {
   background-color:${M.COL.BUTTON.MIDYELLOW};
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }
`;

const CTA3rd = styled(CTA)`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  border-radius:20px;
  font-weight: bold;
  font-size:14px;
  border: 1px solid ${M.COL.TEXT.BLACK};
  height:36px;
  padding: 0px 26px;
  &:hover {
   background-color:${M.COL.BUTTON.BLACK};
   border-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:focus {
   background-color:${M.COL.BUTTON.BLACK};
   border-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active {
   background-color:${M.COL.BUTTON.BLACK};
   border-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  }
`;
// Behold the ... DARK BUTTONS!

const CTA1stDark = styled(CTA)`
  font-weight: bold;
  padding: 0px 26px;
`;

const CTA1stDarkDesktop = styled(CTA1stDark)`
  background-color:#00000000;
  color:${M.COL.TEXT.WHITE};
  border: 2px solid ${M.COL.BUTTON.WHITE};
  font-size:26px;
  line-height: 30px;
  height:56px;
  border-radius:28px;
  &:hover {
    background-color:${M.COL.BUTTON.WHITE};
    border-color:${M.COL.BUTTON.BLACK};
    color: ${M.COL.TEXT.BLACK};
  }
  &:focus {
    background-color:${M.COL.BUTTON.WHITE};
    border-color: ${M.COL.BUTTON.BLACK};
    color: ${M.COL.TEXT.BLACK};
  }
  &:active {
   background-color:${M.COL.BUTTON.WHITE};
   border: 1px solid ${M.COL.BUTTON.BLACK};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
   color: ${M.COL.TEXT.BLACK};
  }
`;

const CTA1stDarkMobile = styled(CTA1stDark)`
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  border: 2px solid ${M.COL.BUTTON.YELLOW};
  font-size:18px;
  line-height: 26px;
  height:42px;
  border-radius:28px;
  &:hover {
    background-color:${M.COL.BUTTON.BLACK};
    border-color:${M.COL.BUTTON.WHITE};
    color: ${M.COL.TEXT.WHITE};
  }
  &:focus {
    background-color:${M.COL.BUTTON.BLACK};
    border-color:${M.COL.BUTTON.WHITE};
    color: ${M.COL.TEXT.WHITE};
  }
  &:active {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const CTA2ndDark = styled(CTA2nd)`
  background-color:${M.COL.BUTTON.WHITE};
  border:2px solid ${M.COL.BUTTON.WHITE};
  &:hover {
   border: 2px solid ${M.COL.BUTTON.YELLOW};
   background-color:${M.COL.BUTTON.WHITE};
  }
  &:focus {
   border: 2px solid ${M.COL.BUTTON.YELLOW};
   background-color:${M.COL.BUTTON.WHITE};
  }
  &:active {
   border: 3px solid ${M.COL.BUTTON.YELLOW};
   box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.35);
   background-color:${M.COL.BUTTON.WHITE};
  }
`;

const CTA3rdDark = styled(CTA3rd)`
  background-color:${M.COL.BUTTON.WHITE};
  border: 2px solid ${M.COL.BUTTON.WHITE};
  &:hover {
   border: 2px solid ${M.COL.BUTTON.YELLOW};
   background-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.BLACK};
  }
  &:focus {
   border: 2px solid ${M.COL.BUTTON.YELLOW};
   background-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.BLACK};
  }
  &:active {
   border: 3px solid ${M.COL.BUTTON.YELLOW};
   box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
   background-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.BLACK};
  }
`;

const CtaAlternativeDesktop = styled(CTA3rd)`
  background-color:${M.COL.BUTTON.BLACK};
  color:${M.COL.TEXT.WHITE};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 56px;
  width: 100%;
  border-radius: 50px;
  &:hover {
   border-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
   background-color:${M.COL.BUTTON.DARKGREY};
  }
  &:focus {
   border: 2px solid ${M.COL.BUTTON.BLACK};
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.BLACK};
  }
  &:active {
   border: 3px solid ${M.COL.BUTTON.DARKGREY};
   box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.BLACK};
  }
`;

const CtaAlternativeMobile = styled(CTA3rd)`
  background-color:${M.COL.BUTTON.BLACK};
  color:${M.COL.TEXT.WHITE};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  height: 42px;
  width: 100%;
  &:hover {
   border-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
   background-color:${M.COL.BUTTON.DARKGREY};
  }
  &:focus {
   border: 2px solid ${M.COL.BUTTON.BLACK};
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.BLACK};
  }
  &:active {
    border: 3px solid ${M.COL.BUTTON.DARKGREY};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.BLACK};
  }
`;

const JoinPageSignupButton = styled(CtaAlternativeMobile)`
  font-size: 18px;
  font-style: roboto;
  font-weight: 700;
  color: white;
  &:focus {
   border: 2px solid ${M.COL.BUTTON.BLACK};
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
  }
`;

const CtaSocialSignup = styled(CTA3rd)`
color:default;
`;

const CtaAlternativePostSignup = styled(CtaAlternativeDesktop)`
  max-width: ${props => props.needsMaxWidth ? '358px' : ''};
  height: 42px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  &:active {
    color:${M.COL.TEXT.WHITE};
  }
  &:focus {
   color:${M.COL.TEXT.WHITE};
  }
`;

const StandardButton = styled(ButtonBase)`
  border-style:solid;
  border-width:1px;
  border-radius:2px;
  font-weight:bold;
  font-size:14px;
  line-height:20px;
  height:36px;
  padding: 0px 10px;
  display:flex;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

const BrightButton = styled(StandardButton)`
  border-color:${M.COL.BUTTON.YELLOW};
  background-color:${M.COL.BUTTON.LIGHTYELLOW};
  color:${M.COL.TEXT.BLACK};
  &:hover {
   background-color:${M.COL.BUTTON.MIDYELLOW};
  }
  &:focus {
   background-color:${M.COL.BUTTON.MIDYELLOW};
  }
  cursor:pointer;
`;

const BrightButtonDisabled = styled(StandardButton)`
  border-color:${M.COL.BUTTON.MID};
  background-color:${M.COL.BUTTON.MID};
  color:${M.COL.TEXT.MID};
`;

const PositiveOptionButton = styled(StandardButton)`
  border-color:${M.COL.BUTTON.BLACK};
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  &:hover {
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
  }
  &:focus {
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
  }
  &:active{
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    border: 2px solid ${M.COL.BUTTON.WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  cursor:pointer;
`;

const NegativeOptionButton = styled(StandardButton)`
  border-color:${M.COL.BUTTON.MID};
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.MID};
  &:hover {
   background-color:${M.COL.BUTTON.MID};
   color:${M.COL.TEXT.BLACK};
  }
  &:focus {
    background-color:${M.COL.BUTTON.MID};
    color:${M.COL.TEXT.BLACK};
  }
  &:active{
    background-color:${M.COL.BUTTON.MID};
    color:${M.COL.TEXT.BLACK};
    border: 2px solid ${M.COL.BUTTON.WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  cursor:pointer;
`;

const SmallStandardButton = styled(ButtonBase)`
  border-style:solid;
  border-width:0.5px;
  border-radius:2px;
  font-weight:500;
  font-size:12px;
  line-height:18px;
  height:30px;
  padding: 0px 10px;
`;

const SmallOptionButton = styled(SmallStandardButton)`
  border-color:${M.COL.BUTTON.BLACK};
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.TEXT.BLACK};
  &:hover {
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active{
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
  }
  cursor:pointer;
`;

const SmallOptionButtonDark = styled(SmallStandardButton)`

  border-color:${M.COL.BUTTON.BLACK};
  background-color:${M.COL.BUTTON.BLACK};
  height: inherit;
  width: 65px;
  color:${M.COL.TEXT.WHITE};
  &:hover {
   color:${M.COL.TEXT.WHITE};
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  &:active{
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    border: 3px solid ${M.COL.BUTTON.DARKGREY};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);


  }
  cursor:pointer;
`;

const SmallOptionButtonDisabled = styled(SmallStandardButton)`
  border-color:${M.COL.BUTTON.MID};
  background-color:${M.COL.BUTTON.MID};
  color:${M.COL.TEXT.MID};
`;


const VoidButton = styled(ButtonBase)`
  border-color:${M.COL.BUTTON.BLACK};
  background-color:${M.COL.UC.FG};
  color:${M.COL.TEXT.WHITE};
  border-style:solid;
  border-width:2px;
`;


const SquarePopupBase = styled.div`
  width:${props => props.width};
  max-width:${props => props.maxWidth};
  box-sizing: border-box;
  border-radius: 2px;
  background-color: ${M.COL.BG.WHITE};
  padding: 7px 26px;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  cursor:pointer;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
`;

const SquarePopup= styled(SquarePopupBase)`
  border: solid 1px ${M.COL.TEXT.BLACK};
  color: ${M.COL.TEXT.BLACK};
  &:hover {
    background-color:${M.COL.BG.BLACK};
    color: ${M.COL.TEXT.WHITE};
    box-shadow: none;
  }
  &:active {
    background-color:${M.COL.BG.BLACK};
    color: ${M.COL.TEXT.WHITE};
    box-shadow: none;
  }
  &:focus {
    background-color:${M.COL.BG.BLACK};
    color: ${M.COL.TEXT.WHITE};
    box-shadow: none;
  }
`;

const SquarePopupGrey = styled(SquarePopup)`
  border: solid 1px ${M.COL.TEXT.MID};
  color: ${M.COL.TEXT.MID};
`;

const SquarePopupDisabled = styled(SquarePopupBase)`
  border: solid 1px ${M.COL.TEXT.LIGHT};
  color: ${M.COL.TEXT.LIGHT};
`;

const SquarePopupWorking = styled(SquarePopupBase)`
    color: ${M.COL.TEXT.WHITE};
    background-color:${M.COL.BUTTON.MIDDARK};
    box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.25);
`;

const DotsContainer = styled.div`
  position:absolute;
  width:100%;
  bottom:0px;
  margin-bottom: 3px;
`;

const PromptButtonLight = styled(CtaAlternativeMobile)`
  font-size: 18px;
  background-color:${M.COL.BUTTON.WHITE};
  color:${M.COL.BUTTON.BLACK};
  &:hover {
   color:${M.COL.TEXT.BLACK};
   background-color:${M.COL.BUTTON.WHITE};
  }
  &:active {
    border: 2px solid ${M.COL.BUTTON.DARKGREY};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    background-color:${M.COL.BUTTON.WHITE};
    color:${M.COL.TEXT.BLACK};
  }
  &:focus {
   border: 1px solid ${M.COL.BUTTON.BLACK};
   background-color:${M.COL.BUTTON.WHITE};
   color:${M.COL.TEXT.BLACK};
  }
  height: 42px;
  width: 145px;
`;

const PromptButtonLightLarge = styled(PromptButtonLight)`
  height: 56px;
  border-radius: 26px;
  width: 100%;
  cursor:pointer;
  font-size: 26px;
`;

const PromptButtonLightLargeMobile = styled(PromptButtonLightLarge)`
  font-size: 18px;
`;

const PromptButtonDark = styled(CtaAlternativeMobile)`
  font-size: 18px;
  background-color:${M.COL.BUTTON.BLACK};
  &:hover {
   color:${M.COL.TEXT.WHITE};
   background-color:${M.COL.BUTTON.BLACK};
  }
  &:active {
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    border: 2px solid ${M.COL.BUTTON.DARKGREY};
  }
  &:focus {
    color:${M.COL.TEXT.WHITE};
  }
  height: 42px;
  width: 145px;
`;

const PromptButtonDarkWider = styled(CtaAlternativeMobile)`
  width: 156px;
  font-weight: 500;
  font-size: 16px;
  &:focus {
   border: 2px solid ${M.COL.BUTTON.BLACK};
   background-color:${M.COL.BUTTON.BLACK};
   color:${M.COL.TEXT.WHITE};
  }
  &:active {
    border: 3px solid ${M.COL.BUTTON.DARKGREY};
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.35);
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
  }
`;

const DownloadButtonDark = styled(PromptButtonDark)`
  font-size: 18px;
  background-color:${M.COL.BUTTON.BLACK};
  &:hover {
   color:${M.COL.TEXT.WHITE};
   background-color:${M.COL.BUTTON.BLACK};
  }
  &:active {
    background-color:${M.COL.BUTTON.BLACK};
    color:${M.COL.TEXT.WHITE};
    border: 2px solid ${M.COL.BUTTON.DARKGREY};
  }
  &:focus {
    color:${M.COL.TEXT.WHITE};
  }
  height: 42px;
  width: 176px;
`;


const PromptButtonDarkLarge = styled(PromptButtonDark)`
  height: 56px;
  border-radius: 26px;
  width: 100%;
  cursor:pointer;
  font-size: 26px;
`;

const PromptButtonDarkLargeMobile = styled(PromptButtonDarkLarge)`
  font-size: 18px;
`;

const PromptButtonLightMobile = styled(PromptButtonLight)`
  width: 118px;
`;

const PromptButtonDarkMobile = styled(PromptButtonDark)`
  width: 118px;
`;


/**
* Base button class
*/
class Button extends Component {
  static propTypes = {
    /** width of field (text area) OPTIONAL */
    width: PropTypes.string,
    /** Maximum width OPTIONAL */
    maxWidth: PropTypes.string,
    /** Style type for button to use preset Moonsift styles */
    msstyle: PropTypes.string,
    /** Is this button enabled or not? */
    enabled: PropTypes.bool,
    /** Tab index */
    tabIndex:PropTypes.number,
  }

  constructor(props){
    super(props);
    this.getInnuds = this.getInnuds.bind(this);
    this.checkEnter = this.checkEnter.bind(this);
  }

  checkEnter(event){
    if (event.key === 'Enter' && this.props.enabled!==false && this.props.onClick !== undefined) {
      this.props.onClick(event);
      event.preventDefault();
    }
  }

  getInnuds(){
    var dots_to_return;
    switch(this.props.msstyle){
      case "bright":{
        if (this.props.enabled===false){
          return([BrightButtonDisabled]);
        }
        else{
          return([BrightButton]);
        }
      }
      case "option_positive":{
        if (this.props.enabled===false){
          return([BrightButtonDisabled]);
        }
        else{
          if (this.props.working===true){
            return([PositiveOptionButton,<LoadingDots key="loading" msstyle="light"/>]);
          }else{
            return([PositiveOptionButton]);
          }
        }
      }
      case "option_negative":{
        if (this.props.enabled===false){
          return([BrightButtonDisabled]);
        }
        else{
          return([NegativeOptionButton]);
        }
      }
      case "square_popup_grey":
      case "square_popup":{
        if (this.props.dots===true){
          dots_to_return = <LoadingDots msstyle="dark"/>;
        }
        if (this.props.enabled===false){
          return([SquarePopupDisabled,dots_to_return]);
        }
        else{
          if (this.props.working===true){
            return([SquarePopupWorking,dots_to_return]);
          }else{
            return([(this.props.msstyle==="square_popup_grey")?SquarePopupGrey:SquarePopup,dots_to_return]);
          }
        }
      }
      case "cta1st_adjustable":{
        if (this.props.windowWidth>=M.MOBILESWITCH){
          return([CTA1stDesktop]);
        }
        else{
          return([CTA1stMobile]);
        }
      }
      case "cta1st_desktop":{
          return([CTA1stDesktop]);
      }
      case "cta1st_mobile":{
          return([CTA1stMobile]);
      }
      case "cta2nd":{
          return([CTA2nd]);
      }
      case "cta3rd":{
          return([CTA3rd]);
      }
      case "cta1st_dark_adjustable":{
        if (this.props.windowWidth>=M.MOBILESWITCH){
          return([CTA1stDarkDesktop]);
        }
        else{
          return([CTA1stDarkMobile]);
        }
      }
      case "cta1st_dark_desktop":{
          return([CTA1stDarkDesktop]);
      }
      case "cta1st_dark_mobile":{
          return([CTA1stDarkMobile]);
      }
      case "cta2nd_dark":{
          return([CTA2ndDark]);
      }
      case "cta3rd_dark":{
          return([CTA3rdDark]);
      }

      case "cta_alternative_desktop":{
          return([CtaAlternativeDesktop]);
      }
      case "cta_alternative_mobile":{
          return([CtaAlternativeMobile]);
      }
      case "cta_alternative_post_signup":{
          return([CtaAlternativePostSignup]);
      }
      case "cta_social_signup":{
          return([CtaSocialSignup]);
      }

      case "small_option":{
          if (this.props.enabled===false){
            return([SmallOptionButtonDisabled]);
          }
          else{
            return([SmallOptionButton]);
          }
      }
      case "small_option_dark":{
        if (this.props.enabled===false){
          return([SmallOptionButtonDisabled]);
        }
        else{
          return([SmallOptionButtonDark]);
        }
      }

      case "prompt_button_dark":{
        if (this.props.isDesktop===true){
          return([PromptButtonDark]);
        }
        else{
          return([PromptButtonDarkMobile]);
        }
      }
      case "download_button_dark":{
        return([DownloadButtonDark]);
      }
      case "prompt_button_light":{
        if (this.props.isDesktop===true){
          return([PromptButtonLight]);
        }
        else{
          return([PromptButtonLightMobile]);
        }
      }

      case "prompt_button_dark_wider":{
        return([PromptButtonDarkWider]);
      }

      case "prompt_button_dark_large":{
        if (this.props.isDesktop===true){
          return([PromptButtonDarkLarge]);
        }
        else{
          return([PromptButtonDarkLargeMobile]);
        }
      }

      case "prompt_button_light_large":{
        if (this.props.isDesktop===true){
          return([PromptButtonLightLarge]);
        }
        else{
          return([PromptButtonLightLargeMobile]);
        }
      }

      case "join_page_signup_button":{
        return([JoinPageSignupButton]);
      }

      default:{
        return(VoidButton);
      }
    }
  }

  render(){
    const Innuds = this.getInnuds();
    const Container = Innuds[0];
    const DotsContainerOut = (Innuds[1]!==undefined && this.props.working===true)?<DotsContainer>{Innuds[1]}</DotsContainer>:"";
    return(
      <Container
        className={this.props.className}
        style={this.props.style}
        width={this.props.width}
        maxWidth={this.props.maxWidth}
        onClick={(this.props.enabled===false)?()=>null:this.props.onClick}
        onKeyPress={this.checkEnter}
        tabIndex={this.props.tabIndex}
        needsMaxWidth={this.props.needsMaxWidth}
       >
        {(this.props.working_overlay===true)?<LoadingDots/>:this.props.text}
        {DotsContainerOut}
      </Container>
    );
  }

}

export default windowSize(Button);
