import { ParserInput } from 'src/services/DB';
import { dataAndID } from 'src/store/parsers/dataandid';
import { CollectionItem } from 'src/types/models/collectionItem.model';
export function collectionItemParser(doc: ParserInput): CollectionItem | null {
  const data = dataAndID<CollectionItem>(doc);
  if (data) {
    data.scrape_user_uid = data.scrape_user_uid
      ? data.scrape_user_uid
      : data.user_uid;
  }
  return data;
}
