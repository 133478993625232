import {
  VFC,
  useState,
  useCallback,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from 'src/index';
import { ServicesContext } from 'src/ServicesContext';
import { CollectionItem } from 'src/types/models/collectionItem.model';
import {
  EditProductDetailsContainerProps,
  EditProductDetailsProps,
} from './EditProductDetailsDialog.types';
import { EditProductDetails } from './EditProductDetailsDialog.component';
import { getCollectionItemStoreAs } from 'src/utils/getCollectionItemStoreAs';
import { ProductData } from 'src/types/models/productData.model';
import { object, string } from 'yup';

const validationSchema = object({
  url: string()
    .test(
      'includes-substring',
      'Link must start with https:// or http://',
      (value) => {
        if (
          value?.slice(0, 8) === 'https://' ||
          value?.slice(0, 7) === 'http://'
        ) {
          return true;
        }
        return false;
      },
    )
    .required('A link is required'),
  description: string().optional(),
  price: string().required('A price is required'),
});

const EditProductDetailsContainer: VFC<EditProductDetailsContainerProps> = (
  props,
) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get Collection Item
  const collectionItemUID = searchParams.get('product') ?? '';
  const collectionItem = useSelector<RootState, CollectionItem | undefined>(
    ({ db }) =>
      db.single_collection_item_list.data.find(
        ({ id }) => id === collectionItemUID,
      ),
  );

  // Get Product Data
  const collectionUID = useSelector<RootState, string>(
    ({ db }) => db.single_collection.data?.id ?? '',
  );
  const authUserUID = useSelector<RootState, string>(
    ({ auth }) => auth.id ?? '',
  );
  const productDataStorePath = getCollectionItemStoreAs(
    collectionUID,
    authUserUID,
    collectionItemUID,
  );
  const productData = useSelector<RootState, ProductData | undefined>(
    ({ productData }) => productData?.[productDataStorePath],
  );

  const initialValues = {
    url: productData?.url ?? '',
    description: productData?.description ?? '',
    price: productData?.price ?? '',
  };

  // Handle image starring
  const defaultStarredImage = collectionItem?.product_image ?? '';
  const [starredImage, setStarredImage] = useState<string | undefined>();
  const actualStarredImage = starredImage ?? defaultStarredImage;
  const handleStar = useCallback<EditProductDetailsProps['onStar']>((event) => {
    const buttonEl = event.currentTarget;
    const parentEl = buttonEl.parentElement;
    const source = parentEl?.dataset.image ?? '';

    setStarredImage(source);
  }, []);

  // Handle image deletion
  const [deletedImages, setDeletedImages] = useState<string[]>([]);
  useEffect(() => {
    setDeletedImages(productData?.deletedImages ?? []);
  }, [productData?.deletedImages]);
  const handleDelete = useCallback<EditProductDetailsProps['onDelete']>(
    (event) => {
      const buttonEl = event.currentTarget;
      const parentEl = buttonEl.parentElement;
      const source = parentEl?.dataset.image ?? '';

      if (deletedImages.includes(source)) {
        setDeletedImages(deletedImages.filter((image) => image !== source));
      } else {
        setDeletedImages(deletedImages.concat(source));
      }
    },
    [deletedImages],
  );

  // Handle an image loading error for any of the images rendered
  const [hasImageError, setHasImageError] = useState(false);
  const handleError = useCallback<EditProductDetailsProps['onError']>(() => {
    setHasImageError(true);
  }, []);

  // Image list
  const imageList = useMemo(() => {
    const fullImageList = productData?.imageList ?? [];
    const initialDeletedImages = productData?.deletedImages ?? [];
    return fullImageList.filter((url) => !initialDeletedImages.includes(url));
  }, [productData?.deletedImages, productData?.imageList]);

  // Handle save
  const { cloud } = useContext(ServicesContext);

  const handleSave = useCallback<EditProductDetailsProps['onSave']>(
    async (values, actions) => {
      const fullImageList = productData?.imageList ?? [];
      await cloud
        .call('products-editCollectionItemDetails')({
          ...values,
          collection_uid: collectionUID,
          item_uid: collectionItemUID,
          image_urls: [
            actualStarredImage,
            ...fullImageList
              .filter((image) => !deletedImages.includes(image))
              .filter((image) => image !== actualStarredImage),
          ],
          deleted_images: deletedImages,
        })
        .then((result: any) => {
          console.log('Product details updated sucessfully:', result);
        })
        .catch((error) => console.error(error))
        .finally(() => actions.setSubmitting(false));
    },
    [
      actualStarredImage,
      cloud,
      collectionItemUID,
      collectionUID,
      deletedImages,
      productData?.imageList,
    ],
  );

  const isPristine =
    deletedImages.length === 0 &&
    actualStarredImage.includes(defaultStarredImage);

  return (
    <EditProductDetails
      starredImage={actualStarredImage}
      deletedImages={deletedImages}
      imageList={imageList}
      hasImageError={hasImageError}
      isPristine={isPristine}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onStar={handleStar}
      onDelete={handleDelete}
      onError={handleError}
      onSave={handleSave}
      {...props}
    />
  );
};

export { EditProductDetailsContainer as EditProductDetails };
