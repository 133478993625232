import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useCarousel = () => {
  const [canScrollBack, updateCanScrollBack] = useState(false);
  const [canScrollForward, updateCanScrollForward] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const currentContainerRef = containerRef.current;

  const componentDidUpdate = useCallback(() => {
    if (!currentContainerRef) {
      return;
    }
    // NOTE: can be verical or horizontal scrolling:
    const {
      scrollTop,
      scrollHeight,
      clientHeight,
      scrollLeft,
      scrollWidth,
      clientWidth,
    } = currentContainerRef;
    updateCanScrollBack(
      (scrollWidth > clientWidth && scrollLeft > 0) ||
        (scrollHeight > clientHeight &&
          scrollTop !== scrollHeight - clientHeight),
    );
    updateCanScrollForward(
      (scrollWidth > clientWidth && scrollLeft !== scrollWidth - clientWidth) ||
        (scrollHeight > clientHeight && scrollTop > 0),
    );
  }, [currentContainerRef]);

  // Disable warning due to the way debounce works:
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceComponentDidUpdate = useCallback(
    debounce(() => componentDidUpdate(), 200),
    [componentDidUpdate],
  );

  useEffect(() => {
    if (!currentContainerRef) {
      return;
    }
    return currentContainerRef.addEventListener('scroll', () => {
      debounceComponentDidUpdate();
    });
  }, [debounceComponentDidUpdate, currentContainerRef]);

  useEffect(() => {
    componentDidUpdate();
  });
  const showForwardButton = canScrollForward;
  const showBackButton = canScrollBack;

  return {
    containerRef,
    showForwardButton,
    showBackButton,
    componentDidUpdate: debounceComponentDidUpdate,
  };
};
