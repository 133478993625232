import { VFC } from 'react';
import styled from 'styled-components';
import { Typography, StandardButton, Stack } from '@moonsifttech/design-system';

import { CreatorApplicationFiveProps } from './CreatorApplicationFive.types';

const UnstyledCreatorApplicationFive: VFC<CreatorApplicationFiveProps> = ({
  className,
  style,
  onSubmit,
  onBack,
  hasAffiliates,
}) => {
  return (
    <div className={className} style={style}>
      <div className="CreatorApplicationFive-content">
        <Typography
          className="CreatorApplicationFive-heading"
          variant="editorial.sb34"
          component="h1"
        >
          Great, you can share your collections anywhere!
        </Typography>
        <Typography
          className="CreatorApplicationFive-quote"
          variant="editorial.r26"
          component="p"
        >
          “...my YouTube, IG & TikTok followers love Moonsift because they can
          shop everything in my videos from one link...” Moonsift Creator, Mel
        </Typography>
        <Stack
          direction="row"
          spacing={12}
          className="CreatorApplicationFive-quoteAttribution"
        >
          <img
            src={`${process.env.REACT_APP_STATIC_URL}/creator/mel.png`}
            className="CreatorApplicationFive-profile"
            alt="Mel's face"
          />
          <Typography
            className="CreatorApplicationFive-paragraph"
            variant="primary.r14"
            component="p"
          >
            Moonsift Creator, Mel
          </Typography>
        </Stack>
        <Typography
          className="CreatorApplicationFive-paragraph"
          variant="primary.l20"
          component="p"
        >
          Last question...
        </Typography>
        <Stack direction="row" spacing={12}>
          <StandardButton variant="primary-white" onClick={onBack}>
            Back
          </StandardButton>
          <StandardButton
            type="submit"
            onClick={onSubmit}
            variant="primary-black"
          >
            Next
          </StandardButton>
        </Stack>
        <Stack
          direction="row"
          spacing={12}
          className="CreatorApplicationFive-imageContainer"
        >
          <picture>
            <source
              media="(max-width: 544px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/creator/CreatorPlatforms.png`}
            />
            <img
              src={`${process.env.REACT_APP_STATIC_URL}/creator/CreatorPlatforms.2x.png`}
              alt="Creator and social media platform brand logos"
              className="CreatorApplicationFive-affiliateLogos"
            />
          </picture>
        </Stack>
      </div>
    </div>
  );
};

export const CreatorApplicationFive = styled(UnstyledCreatorApplicationFive)`
  padding: 100px 0 0;

  .CreatorApplicationFive-content {
    margin: 0 auto;
    padding: 0 24px;
    max-width: ${({ theme }) => `${theme.breakpoints.sm}px`};
  }

  .CreatorApplicationFive-heading {
    margin-bottom: 24px;
    color: var(--background-purple);
  }

  .CreatorApplicationFive-input {
    margin-bottom: 28px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    padding: 120px 0 0;
  }
  .CreatorApplicationFive-paragraph {
  }
  .CreatorApplicationFive-affiliateLogos {
    width: 100%;
  }
  .CreatorApplicationFive-imageContainer {
    margin-top: 24px;
  }
  .CreatorApplicationFive-profile {
    width: 28px;
  }
  .CreatorApplicationFive-quoteAttribution {
    align-items: center;
    margin-bottom: 24px;
  }
  .CreatorApplicationFive-quote {
    margin-bottom: 4px;
  }
`;
