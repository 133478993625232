import { VFC } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import {
  Stack,
  stackClassNames,
  Typography,
} from '@moonsifttech/design-system';

import { Page } from 'src/components/common/Page';
import { AppStoreLinkButton } from 'src/components/common/AppStoreLinkButton';
import { Link } from 'src/components/core/Link';
import { FiveStars } from 'src/components/common/FiveStars';
import R, { EXTERNAL } from 'src/routes';
import { PeopleSayingCarousel } from 'src/components/common/PeopleSayingCarousel';
import { WishlistAppLandingProps } from './WishlistAppLanding.types';

const UnstyledWishlistAppLanding: VFC<WishlistAppLandingProps> = ({
  className,
  style,
}) => {
  return (
    <>
      <Helmet>
        <title>Universal Wishlist App | Create your wishlist | Moonsift</title>
        <meta
          name="description"
          content="The universal wishlist app that lets you save products from any store. Create wishlists for any occasion. Free and easy to use."
        />
      </Helmet>
      <Page className={className} style={style} isLanding showFooter>
        <section className="WishlistAppLanding-headingSection">
          <Typography variant="editorial.r46" component="h1" align="center">
            One wishlist for all stores
          </Typography>
          <Typography variant="primary.l20" component="p" align="center">
            Save items from any store and keep track of everything in one place.
            <br />
            Get the iOS app - it's free.
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
          <Typography variant="primary.r16" component="p" align="center">
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
        </section>

        <section className="WishlistAppLanding-brandsBannerContainer">
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/brands-banner.png`}
            />
            <img
              className="WishlistAppLanding-brandsBanner"
              alt="Brands banner"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/brands-banner.2x.png`}
            />
          </picture>
        </section>

        <section className="WishlistAppLanding-section">
          <Typography variant="editorial.r26" component="h2">
            The universal wishlist app that lets you save items from anywhere
          </Typography>
          <Typography variant="primary.l20" component="p">
            Stop filling your phone with screenshots and losing track of
            products you want.
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/create-wishlists.png`}
            />
            <img
              className="WishlistAppLanding-picture"
              alt="Create wishlists"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/create-wishlists.2x.png`}
            />
          </picture>
        </section>

        <section className="WishlistAppLanding-section">
          <Typography variant="editorial.r26" component="h2">
            Stay organised and stop impulse buying
          </Typography>
          <Typography variant="primary.l20" component="p">
            Compare all your options in one place and make more considered
            purchase decisions.
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/online-wishlists.png`}
            />
            <img
              className="WishlistAppLanding-picture"
              alt="Online wishlists"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/online-wishlists.2x.png`}
            />
          </picture>
        </section>

        <section className="WishlistAppLanding-section">
          <Typography variant="editorial.r26" component="h2">
            Get price drop alerts
          </Typography>
          <Typography variant="primary.l20" component="p">
            Save an item to Moonsift and we'll notify you if the price drops.
            <br />
            We track prices on <Link to={R.RETAILERS}>2,000+ stores</Link>.
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/price-drop-alerts.png`}
            />
            <img
              className="WishlistAppLanding-priceDropPicture"
              alt="Price drop alerts"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/price-drop-alerts.2x.png`}
            />
          </picture>
          <Typography variant="primary.l16" component="p" align="center">
            Get Moonsift on the App Store - it's free
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
        </section>

        <section className="WishlistAppLanding-section">
          <Typography variant="editorial.r26" component="h2">
            Curate and Share shoppable wishlists
          </Typography>
          <Typography variant="primary.l20" component="p">
            Curate shoppable collections.
            <br />
            Share them privately or publicly.
            <br />
            All with a single link.
          </Typography>
          <picture>
            <source
              media="(max-width: 620px)"
              srcSet={`${process.env.REACT_APP_STATIC_URL}/app-landing/share-wishlists.png`}
            />
            <img
              className="WishlistAppLanding-picture"
              alt="Share wishlists"
              src={`${process.env.REACT_APP_STATIC_URL}/app-landing/share-wishlists.2x.png`}
            />
          </picture>
        </section>

        <section className="WishlistAppLanding-carouselSection">
          <Typography variant="editorial.r26" component="h2">
            Read what people are saying
            <Stack direction="row" alignItems="center">
              <FiveStars size="large" />
            </Stack>
          </Typography>

          <PeopleSayingCarousel className="WishlistAppLanding-carousel" />

          <Typography variant="primary.l16" component="p" align="center">
            Get Moonsift on the App Store - it's free
          </Typography>
          <Stack alignItems="center">
            <AppStoreLinkButton />
          </Stack>
          <Typography variant="primary.r16" component="p" align="center">
            <Link to={EXTERNAL.CHROMESTORE} target="_blank">
              Get Moonsift for desktop instead
            </Link>
          </Typography>
        </section>
      </Page>
    </>
  );
};

export const WishlistAppLanding = styled(UnstyledWishlistAppLanding)`
  overflow-x: hidden;

  .WishlistAppLanding-appStoreButton {
    height: 54px;
    cursor: pointer;
    margin-bottom: 16px;
  }

  .WishlistAppLanding-headingSection {
    margin: 0 auto;
    padding: 56px 20px 24px;
    max-width: 360px;

    h1 {
      margin-bottom: 22px;
    }
  }

  .WishlistAppLanding-brandsBannerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;

    .WishlistAppLanding-brandsBanner {
      height: 202px;
      width: auto;
    }
  }

  .WishlistAppLanding-section {
    margin: 0 auto;
    padding: 48px 30px 16px;
    max-width: 360px;

    h2 {
      margin-bottom: 12px;
    }

    h2 + p {
      margin-bottom: 28px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  picture {
    display: flex;
    justify-content: center;

    img {
      width: 100%;
    }

    img.WishlistAppLanding-priceDropPicture {
      margin-top: -12px;
      margin-bottom: calc(-12px + 44px);
    }
  }

  .WishlistAppLanding-carouselSection {
    margin: 0 auto;
    padding: 48px 20px 64px;
    max-width: 840px;

    h2 {
      margin-bottom: 24px;

      > .${stackClassNames.root} {
        margin-left: 12px;
        display: inline-flex;
      }
    }

    .WishlistAppLanding-carousel {
      margin-bottom: 68px;
    }
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    .WishlistAppLanding-carouselSection {
      padding: 48px 54px 64px;
    }
  }
`;
