/*

REDUX Query

Note can take a start_after doc for loading more notifications!

Can be a separate redux list as will be separate to the other lists and appended.

*/

export default function listen_for_new_notifications(doc,firebase,auth_user_uid,state_assign_func){
  const start_after = (doc!==false)?doc:null;
  return firebase.user_notifications_order_StartAfter(auth_user_uid,start_after)
  .onSnapshot(
    snapshot=>{
      const notification_list = [];
      snapshot.forEach(
        doc=>{
          const data = doc.data();
          // Not entry uid as this was used already...
          data.uid = doc.id;
          notification_list.push(data);
        }
      );
      notification_list.sort(
        (a,b)=>(a.created_at>b.created_at)?-1:1
      );
      state_assign_func({
        notification_list,
      });
    }
  );
}
