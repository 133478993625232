import { VFC } from 'react';
import styled from 'styled-components';
import { CollectionCarouselTileProps } from './CollectionCarouselTile';
import CollectionTile from 'src/mvp22/tile-components/CollectionTile';
import { generateProductImageSource } from 'src/utils/generateProductImageSource';

const UnstyledCollectionCarouselTile: VFC<CollectionCarouselTileProps> = ({
  index,
  collectionEntry,
  collectionUserPublic,
  collectionImages,
}) => {
  return (
    <div className="CollectionCarouselTile-container">
      <CollectionTile
        className="FeaturedTile"
        data={{
          collection_images: collectionImages.image_list.map((src) =>
            generateProductImageSource(src),
          ),
          collection_entry: { ...collectionEntry, uid: collectionEntry.id },
        }}
        user_public_info={collectionUserPublic}
        isLarge={false}
        isCarouselTile={true}
        tileIndex={index}
        display_name={true}
      />
    </div>
  );
};

export const CollectionCarouselTile = styled(UnstyledCollectionCarouselTile)`
  .CollectionCarouselTile-container {
    scroll-snap-align: start;
    min-height: 42px;
  }
`;
