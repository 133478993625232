import { VFC } from 'react';
import { useSelector } from 'react-redux';
import R from 'src/routes';
import { RootState } from 'src/index';
import { useCopy } from 'src/hooks/useCopy';
import { ReferProContentContainerProps } from './ReferProContent.types';
import { ReferProContent } from './ReferProContent.component';
import { useHistory } from 'react-router-dom';

const ReferProContentContainer: VFC<ReferProContentContainerProps> = (
  props,
) => {
  const history = useHistory();
  // Get pro referral code
  const proCode = useSelector<RootState, string>(
    (state) => state.firestore_user_owner?.pro_data?.pro_code ?? '',
  );
  const { copied, handleCopy } = useCopy();

  // redirect if already pro:
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  isPro && history.push(R.PRO_SHARE);

  return (
    <ReferProContent
      proCode={proCode}
      copied={copied}
      onCopy={handleCopy}
      {...props}
    />
  );
};

export { ReferProContentContainer as ReferProContent };
