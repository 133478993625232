import { VFC, SyntheticEvent } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import {
  Typography,
  ControlledTextField,
  Stack,
  StandardButton,
} from '@moonsifttech/design-system';
import { Formik, Form } from 'formik';

import { ModalContent } from 'src/components/core/ModalContent';
import { PayoutDialogProps } from './PayoutDialog.types';

function preventDefault(event: SyntheticEvent): void {
  event.preventDefault();
}

const mapStepToClassName = {
  details: 'PayoutDialog-details',
  request: 'PayoutDialog-request',
  requested: 'PayoutDialog-requested',
};

const UnstyledPayoutDialog: VFC<PayoutDialogProps> = ({
  className,
  style,
  step,
  initialValues,
  validationSchema,
  fullName,
  email,
  onClose,
  onComplete,
  onBack,
  onRequest,
}) => {
  return (
    <ModalContent
      className={clsx(className, mapStepToClassName[step])}
      style={style}
      onClose={onClose}
    >
      {step === 'details' ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onComplete}
        >
          {({ dirty, initialValues, isValidating, isSubmitting }) => {
            const hasInitialValues = Boolean(
              Object.values(initialValues).find((value) => value !== ''),
            );
            return (
              <Form className="PayoutDialog-form">
                <Stack className="PayoutDialog-innerContainer">
                  <div>
                    <Typography
                      className="PayoutDialog-heading"
                      variant="primary.b26"
                      component="h1"
                    >
                      Confirm your payment details
                    </Typography>
                    <Typography
                      className="PayoutDialog-paragraph"
                      variant="primary.r20"
                      component="p"
                    >
                      Payment will be sent to your email address using PayPal
                    </Typography>
                    <Typography
                      className="PayoutDialog-inputHeading"
                      variant="primary.l16"
                      component="h2"
                    >
                      Which email address should payments be sent to?
                    </Typography>
                    <ControlledTextField
                      className="PayoutDialog-input"
                      name="email"
                      placeholder="Email"
                      autoComplete="email"
                      autoFocus
                      fullWidth
                    />
                    <ControlledTextField
                      className="PayoutDialog-input"
                      name="confirmEmail"
                      placeholder="Confirm email"
                      autoComplete="off"
                      fullWidth
                      onDrop={preventDefault}
                      onPaste={preventDefault}
                    />
                    <Typography
                      className="PayoutDialog-inputHeading"
                      variant="primary.l16"
                      component="h2"
                    >
                      What name should be on the invoice?
                    </Typography>
                    <ControlledTextField
                      className="PayoutDialog-input"
                      name="fullName"
                      placeholder="Full name"
                      autoComplete="name"
                      fullWidth
                    />
                  </div>
                  <Stack direction="row" justifyContent="flex-end">
                    <StandardButton
                      type="submit"
                      variant="primary-black"
                      disabled={!dirty && !hasInitialValues}
                      loading={isValidating || isSubmitting}
                    >
                      Confirm
                    </StandardButton>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      ) : step === 'request' ? (
        <Stack
          className="PayoutDialog-innerContainer"
          justifyContent="space-between"
        >
          <div>
            <Typography
              className="PayoutDialog-heading"
              variant="primary.b26"
              component="h1"
            >
              Confirm payout request?
            </Typography>
            <Typography
              className="PayoutDialog-paragraph"
              variant="primary.r20"
              component="p"
            >
              Payment will be sent to the following details in the first week of
              next month:
            </Typography>
            <Typography
              className="PayoutDialog-data"
              variant="primary.b18"
              component="p"
            >
              {fullName}
            </Typography>
            <Typography
              className="PayoutDialog-data"
              variant="primary.b18"
              component="p"
            >
              {email}
            </Typography>
          </div>
          <Stack direction="row" justifyContent="flex-end" spacing={12}>
            <StandardButton variant="primary-white" onClick={onBack}>
              Edit details
            </StandardButton>
            <Formik initialValues={{}} onSubmit={onRequest}>
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <StandardButton
                      variant="primary-black"
                      type="submit"
                      loading={isSubmitting}
                    >
                      Confirm payout
                    </StandardButton>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Stack>
      ) : (
        <Stack
          className="PayoutDialog-innerContainer"
          justifyContent="space-between"
        >
          <div>
            <Typography
              className="PayoutDialog-heading"
              variant="primary.b26"
              component="h1"
            >
              Payout scheduled
            </Typography>
            <Typography
              className="PayoutDialog-paragraph"
              variant="primary.r20"
              component="p"
            >
              Your payment has been scheduled via PayPal for the first week of
              the upcoming month.
            </Typography>
            <img
              className="PayoutDialog-paypalLogo"
              src={`${process.env.REACT_APP_STATIC_URL}/creator/paypal.svg`}
              alt="PayPal logo"
            />
            <Typography
              className="PayoutDialog-paragraph"
              variant="primary.r20"
              component="p"
            >
              Please allow 7 days from the start of the upcoming month to
              receive your payment. If it does not arrive contact
              hello@moonsift.com
            </Typography>
          </div>
          <Stack direction="row" justifyContent="flex-end" spacing={12}>
            <StandardButton variant="primary-black" onClick={onClose}>
              Done
            </StandardButton>
          </Stack>
        </Stack>
      )}
    </ModalContent>
  );
};

export const PayoutDialog = styled(UnstyledPayoutDialog)`
  .ModalContent-innerContainer,
  .PayoutDialog-form,
  .PayoutDialog-innerContainer {
    height: 100%;
  }

  .PayoutDialog-heading {
    margin-bottom: 8px;
  }

  .PayoutDialog-paragraph {
    margin-bottom: 20px;
  }

  &.PayoutDialog-requested .PayoutDialog-paragraph {
    margin-bottom: 40px;
  }

  .PayoutDialog-paypalLogo {
    margin-bottom: 40px;
  }

  .PayoutDialog-inputHeading,
  .PayoutDialog-data {
    margin-bottom: 12px;
  }

  .PayoutDialog-input {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.fns.getMediaQuery({ minWidth: 'md' })} {
    height: 520px;
  }
`;
