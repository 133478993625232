import { VFC, useContext, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RootState } from 'src';
import { ServicesContext } from 'src/ServicesContext';
import R from 'src/routes';
import { useDBUpdateDoc } from 'src/hooks/useDBUpdateDoc';
import { PostinstallOneContainerProps } from './PostinstallOne.types';
import { PostinstallOne } from './PostinstallOne.component';

const PostinstallOneContainer: VFC<PostinstallOneContainerProps> = (props) => {
  const { db, analytics } = useContext(ServicesContext);
  const history = useHistory();

  const authUserUID = useSelector<RootState, string>((state) => state.auth.id!);
  const [dbUpdateDoc, working] = useDBUpdateDoc(
    db,
    db.userOwnerDoc(authUserUID),
  );
  const userDataLoaded = useSelector<RootState, true | undefined>(
    (state) => state.firebasestate.loaded,
  );

  useEffect(() => {
    analytics.recordEvent('WebApp:ViewedPostInstall');
  }, [analytics]);

  const handlePin = useCallback(
    (event) => {
      event.preventDefault();
      dbUpdateDoc({ pinned: true })
        .then(() => {
          // When, and only when, the update comes through, we move to the next page, to avoid
          // complains from the useDBUpdateDoc's inner useState being called after unmounting.
          history.push(R.POSTINSTALL_TWO);
        })
        .catch((error) => console.error(error));
    },
    [dbUpdateDoc, history],
  );

  return (
    <PostinstallOne
      pinning={working}
      onPin={handlePin}
      userDataLoaded={userDataLoaded === true}
      {...props}
    />
  );
};

export { PostinstallOneContainer as PostinstallOne };
