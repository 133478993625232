/*

Sorts a collection based on the order specified in an array of IDs.

Move to utils

*/
// originalOrder - array of IDs...:
export default function sortCollection(
  originalOrder: string[],
  orderArray: string[],
) {
  const notUsed: [a: boolean, b: string][] = originalOrder.map((x) => [
    true,
    x,
  ]);
  const endOfList: string[] = [];
  orderArray.forEach((item_uid) => {
    notUsed
      .filter((x) => x[1] === item_uid)
      .forEach((y) => {
        y[0] = false;
        endOfList.push(y[1]);
      });
  });
  const startOfList = notUsed.filter((x) => x[0]).map((x) => x[1]);
  return [...startOfList, ...endOfList];
}
