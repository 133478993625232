import { VFC, useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { Constants } from 'src/constants';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  CollectionContainerProps,
  CollectionOrganisingProps,
} from './Collection.types';
import { Collection as CollectionComponent } from './Collection.component';
import { RootState } from 'src/index';
// Hooks
import { useUserUIDLookup } from 'src/hooks/useUserUIDLookup';
// Redux:
import { useOrganising } from 'src/hooks/useOrganising';
import { useDBRetrieveDoc } from 'src/hooks/useDBRetrieveDoc';
import { collectionParser } from 'src/store/parsers/collection';
import { ServicesContext } from 'src/ServicesContext';
import { ModalTypes } from 'src/types';
import { useModal } from 'src/hooks/useModal';
import { useDBRetrieveQuery } from 'src/hooks/useDBRetrieveQuery';
import { collectionItemParser } from 'src/store/parsers/collectionItem';
import { collectionRegistryPrivateParser } from 'src/store/parsers/collectionRegistryPrivate';
import { collectionRegistryNotesParser } from 'src/store/parsers/collectionRegistryNotes';
import { toggleCollectionRegistryUiIsRevealing } from 'src/store/actions/collectionRegistryUi';
import { getCollectionUIDFromLocation } from './getCollectionUIDFromLocation';
import { useUserPublicDoc } from 'src/hooks/useUserPublicDoc';
import { collectionBookmarkParser } from 'src/store/parsers/collectionBookmark';
import { visibilityOptions, CashFund } from 'src/types/models/collection.model';
import { getCollectionType } from 'src/utils/getCollectionType';

type firebaseStateType = {
  loaded: boolean;
};

const cookies = new Cookies();

const CollectionContainer: VFC<CollectionContainerProps> = () => {
  const { setProfileUsernameFromURL, profileUserUID: collectionUserUID } =
    useUserUIDLookup();
  // STATE:
  const [collectionInfoValid, setCollectionInfoValid] = useState(false); // determines if we can make a DB query based on address bar return
  const [collectionUID, setCollectionUID] = useState('');
  const [isAddItemMenuOpen, setIsAddItemMenuOpen] = useState(false); // Menu NOT modal!
  // REDUX:
  const [setModal] = useModal();
  const authUserUID = useSelector<RootState, string | null>(
    (state) => state.auth.id,
  );

  // TODO: Replace these lines with new user data redux on refactor:
  const firebaseState: firebaseStateType = useSelector<
    RootState,
    firebaseStateType
  >((state) => state.firebasestate);
  const isPro = useSelector<RootState, boolean>((state) =>
    state.firestore_user_owner.pro ? true : false,
  );
  const authUserUIDLoaded = firebaseState.loaded;
  const {
    firebaseMVP22: firebase,
    db: dB,
    cloud,
    analytics,
  } = useContext(ServicesContext);

  /*

  Database fetches:
  - collection record,
  - collection owner's public record,
  - items in the collection,
  */

  // Fetch the single doc collection stuff: Overload the arguments array that
  // does the useEffect to make sure it only changes when authUserUIDLoaded!
  // (NOTE: Kinda a hack of the hook....!):
  useDBRetrieveDoc<'single_collection'>(
    dB,
    dB.collectionDoc,
    'single_collection',
    collectionParser,
    undefined,
    [collectionUserUID, collectionUID, authUserUIDLoaded ? true : undefined],
    'listen',
  );
  // if invalid info data or no document:
  const collectionNotFound = useSelector<RootState, boolean>((state) => {
    // true when single_collection.data null NOT undefined (when not yet set) since null only when query finished.
    return (
      (collectionInfoValid === false ||
        state.db.single_collection.data === null) &&
      authUserUIDLoaded
    );
  });
  // if we have loaded collection data or not:
  const loadedCollection = useSelector<RootState, boolean>((state) => {
    // Loaded collection doc info? (ignore product data)
    return (
      state.db.single_collection.data !== null &&
      state.db.single_collection.data !== undefined
    );
  });
  // Name of collection:
  const collectionName = useSelector<RootState, string>((state) => {
    return state.db.single_collection.data?.name ?? '';
  });
  // Description of collection:
  const collectionDescription = useSelector<RootState, string | undefined>(
    (state) => {
      return state.db.single_collection.data?.description ?? undefined;
    },
  );
  // Public level of collection:
  const collectionPublicLevel = useSelector<RootState, number | undefined>(
    (state) => {
      return state.db.single_collection.data?.public_level ?? undefined;
    },
  );
  // Whether the collection is a registry:
  const collectionIsRegistry = useSelector<RootState, boolean>((state) => {
    return state.db.single_collection.data?.is_registry ?? false;
  });
  // Number of views of collection:
  const collectionViews = useSelector<RootState, number>((state) => {
    return state.db.single_collection.data?.views ?? 0;
  });
  const deliveryInfo = useSelector<RootState, string | undefined>(
    (state) => state.db.single_collection.data?.delivery_info,
  );
  const cashFund = useSelector<RootState, CashFund | undefined>(
    (state) => state.db.single_collection.data?.cash_fund,
  );
  // Organising info:
  const singleCollectionOrganisingData = useSelector<
    RootState,
    CollectionOrganisingProps | undefined
  >((state) => {
    const data = state.db.single_collection.data;
    return data
      ? {
          order_array: data.order_array,
          subsection_order: data.subsection_order,
          item_to_subsection_map: data.item_to_subsection_map,
          subsection_info: data.subsection_info,
          cover_image_dict: data.cover_image_dict,
          cover_image_url: data.cover_image_url,
        }
      : undefined;
  }, shallowEqual);

  // Note: User Public Profiles not removed on unmount to save calls to db
  // (false last argument)
  useUserPublicDoc(collectionUserUID);
  // Only used to reuse the old SharePopup
  const displayUsername = useSelector(
    (state: RootState) =>
      state.db.user_public.map[collectionUserUID]?.display_username ??
      collectionUserUID,
  );

  // List of items in the collection:
  useDBRetrieveQuery<'single_collection_item_list'>(
    dB,
    dB.collectionItemsQuery,
    'single_collection_item_list',
    collectionItemParser,
    undefined,
    [collectionUserUID, collectionUID],
    'listen',
  );
  // Optimisation using shallowEqual. Without this, it would still
  // rerender each time any data in the queried docs updates, since
  // the mapped to object will change its ref.
  const singleCollectionItemListIDs = useSelector(
    (state: RootState) =>
      state.db.single_collection_item_list.data
        ? state.db.single_collection_item_list.data.map(
            (collectionItem) => collectionItem.id,
          )
        : [],
    shallowEqual,
  );

  // If logged in, Get the associated bookmark information for the user viewing
  // the collection: fetch the collection bookmark info if the collection uid or
  // the collection user uid changes and if the auth user is logged in: Also
  // avoid bookmarking in own collections:
  useDBRetrieveDoc<'single_collection_bookmark'>(
    dB,
    dB.collectionBookmarkDoc,
    'single_collection_bookmark',
    collectionBookmarkParser,
    undefined,
    [authUserUID, collectionUserUID, collectionUID],
    'listen',
  );

  // OTHER HOOKS:
  const location = useLocation();

  // EFFECTS:
  // Get the collectionUID + collectionUserUID from the address bar:
  // Only run if the location pathname changes, will set strings on collectionUID and collectionUserUID.
  useEffect(() => {
    // Gets the collection username (can be a displayUsername) and collection UID from the page URL:
    const collectionInfoFromLocation = getCollectionUIDFromLocation(
      location.pathname,
      authUserUID ?? '',
    );
    if (collectionInfoFromLocation) {
      // Now going to load a new collection...
      setCollectionInfoValid(true);
      setCollectionUID(collectionInfoFromLocation.collectionUID);
      // blank the collectionuserUID as now it's changing async...
      setProfileUsernameFromURL(collectionInfoFromLocation.username);
      // Call the async function (db query) to get the userUID from any displayUsername in the address bar:
    } else {
      setCollectionInfoValid(false);
    }
  }, [authUserUID, location.pathname, setProfileUsernameFromURL]);

  // On loading the data:
  useEffect(() => {
    if (!collectionNotFound && collectionPublicLevel) {
      // If not public and not signed in then prompt to sign in / up:
      if (collectionPublicLevel === 7 && !authUserUID) {
        setModal({
          type: ModalTypes.SignUpToView,
        });
      }
      // record viewing a collection that exists:
      cloud
        .fastAPI({
          api: 'record_collection_view',
          collection_user_uid: collectionUserUID,
          collection_uid: collectionUID,
        })
        .catch((err) =>
          console.log(
            "apparently record collection view didn't work for some reason",
            err,
          ),
        );
      // Set cookie so we know which person first got this new user on to Moonsift by viewing their collection:
      if (!cookies.get(Constants.REFERRAL_COOKIE_KEY)) {
        cookies.set(Constants.REFERRAL_COOKIE_KEY, collectionUserUID, {
          maxAge: 60 * 60 * 24 * 30,
          path: Constants.COOKIEPATH,
        });
      }
    }
  }, [
    authUserUID,
    cloud,
    collectionUID,
    collectionUserUID,
    firebase,
    collectionNotFound,
    collectionPublicLevel,
    setModal,
  ]);

  // ORGANISING:
  // Invoke a hook that stores the functions for organising!
  const organisingHook = useOrganising({
    collectionUID,
    authUserUID, // same as collectionUserUID as can only organise own collection.
    singleCollectionOrganisingData,
    singleCollectionItemListIDs,
  });
  const isOrganising = organisingHook.isOrganising;

  /**
   * This forces a default browser alert informing the user will lose the
   * current changes if they leave the collection detail page when organising by
   * manually updating the browser URL.
   * There is a react-router Prompt that will inform of the same when the user leaves
   * the collection detail page by navigating using react-router links
   */
  useEffect(() => {
    if (isOrganising) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = () => null;
    }
    /**
     * This cleanup function will stop this prompt showing in the case the user has
     * received and ok-ed the prompt. Before having this cleanup function we were
     * leaving `window.onbeforeunload` as `() => true` and that made this prompt
     * reappear every time the user navigated to other URLs manually, even after
     * leaving the collection detail page.
     */
    return () => {
      window.onbeforeunload = () => null;
    };
  }, [isOrganising]);

  // Flag indicating whether the collection's user is the current user
  const isMine = authUserUID === collectionUserUID;

  // Figure out whether the product is being edited
  const searchParams = new URLSearchParams(location.search);
  const isEditingProduct =
    searchParams.has('product') && searchParams.has('edit');

  // Handle closing of edit product details modal
  const history = useHistory();
  const handleEditProductClose = useCallback(() => {
    history.goBack();
  }, [history]);

  // Listen to user's private registry documents that indicate which collection
  // item the user has bought.
  useDBRetrieveQuery<'collection_registry_private'>(
    dB,
    authUserUID
      ? dB.collectionRegistryPrivate
      : dB.collectionRegistryPrivateLoggedOut,
    'collection_registry_private',
    collectionRegistryPrivateParser,
    undefined,
    [
      authUserUID ?? analytics.clientIdentifier,
      collectionUserUID,
      collectionUID,
      isMine,
    ],
    'listen',
  );

  // Listen to public owner notes in collection registries.
  useDBRetrieveDoc<'collection_registry_notes'>(
    dB,
    dB.collectionRegistryNotesDocument,
    'collection_registry_notes',
    collectionRegistryNotesParser,
    undefined,
    [collectionUserUID, collectionUID],
    'listen',
  );

  // Controls checkbox revealing what people have bought.
  const dispatch = useDispatch();
  const isRevealing = useSelector<RootState, boolean>((state) => {
    return state.collectionRegistryUi.isRevealing;
  });
  const handleRevealToggle = useCallback(() => {
    dispatch(toggleCollectionRegistryUiIsRevealing());
  }, [dispatch]);

  const nonReceived = useSelector<RootState, boolean>(
    (state) =>
      state.db.single_collection_item_list.set &&
      state.db.single_collection_item_list.data.length === 0,
  );

  useEffect(() => {
    if (collectionUID && collectionUserUID)
      if (isMine) {
        analytics.recordEvent('WebApp:ViewedOwnCollection', { collectionUID });
      } else {
        analytics.recordEvent('WebApp:ViewedOthersCollection', {
          collectionUID,
          collectionUserUID,
        });
      }
  }, [collectionUID, collectionUserUID, isMine, analytics]);
  const collectionType =
    collectionPublicLevel !== undefined
      ? getCollectionType(collectionPublicLevel)
      : undefined;
  const collectionPublicLevelName = collectionType
    ? visibilityOptions[collectionType].name
    : 'Unknown';

  return (
    <CollectionComponent
      collectionUID={collectionUID}
      collectionUserUID={collectionUserUID}
      collectionName={collectionName}
      collectionDescription={collectionDescription}
      collectionPublicLevel={collectionPublicLevel}
      collectionPublicLevelName={collectionPublicLevelName}
      collectionViews={collectionViews}
      deliveryInfo={deliveryInfo}
      cashFund={cashFund}
      notFound={collectionNotFound}
      isLoading={!loadedCollection}
      isLoggedIn={authUserUID ? true : false}
      isMine={isMine}
      isRegistry={collectionIsRegistry}
      isEditingProduct={isEditingProduct}
      isRevealing={isRevealing}
      onEditProductClose={handleEditProductClose}
      onRevealToggle={handleRevealToggle}
      //      onToggleAddItemMenu={handleToggleAddItemMenu}
      isAddItemMenuOpen={isAddItemMenuOpen}
      // Organising mode controlled from this level:
      organisingHook={organisingHook}
      // Reuse of the old share button
      isPro={isPro}
      displayUsername={displayUsername}
      setIsAddItemMenuOpen={setIsAddItemMenuOpen}
      nonReceived={nonReceived}
    />
  );
};

export { CollectionContainer as Collection };
