import { Audience, Channel, Status } from 'src/types/common';
import { ActionNames, ActionType } from 'src/types/actions';

const professionsMap = {
  'Personal Stylist': true,
  'Personal Shopper': true,
  'Fashion Buyer': true,
  'Visual Merchandiser': true,
  'Store Owner / Manager': true,
  'Designer': true,
  'Fashion Writer / Editor / Blogger': true,
  'Other': true,
};

export type Profession = keyof typeof professionsMap;

export const professions = Object.keys(professionsMap) as Profession[];

export interface StylistApplicationRequest {
  type?: 'content-creator-stylist';
  audience: Audience;
  professions: Profession[];
  channels: Channel[];
  feature: boolean;
  referral_code?: string;
}

export interface StylistApplication extends StylistApplicationRequest {
  status: Status;
}

export interface StylistApplicationState
  extends Omit<
    StylistApplicationRequest,
    'type' | 'referral_code' | 'feature'
  > {
  feature: 'Yes' | 'No' | '';
  errorMessage: string;
}

export type UpdateStylistApplicationRequestAction = ActionType<
  ActionNames.UPDATE_STYLIST_APPLICATION_REQUEST,
  Partial<StylistApplicationState>
>;

export type UpdateStylistApplicationErrorMessageAction = ActionType<
  ActionNames.UPDATE_STYLIST_APPLICATION_ERROR_MESSAGE,
  { errorMessage: string }
>;

export type ResetStylistApplicationRequestAction = ActionType<
  ActionNames.RESET_STYLIST_APPLICATION_REQUEST,
  undefined
>;

export type StylistApplicationAction =
  | UpdateStylistApplicationRequestAction
  | UpdateStylistApplicationErrorMessageAction
  | ResetStylistApplicationRequestAction;
