import React from 'react';
import { Modal } from '@moonsifttech/design-system';

import {
  EditModalContent,
  EditDrawerContent,
} from 'src/components/collection/EditModalContent';
import { EditModalProps } from './EditModal.types';

export const EditModal: React.FC<EditModalProps> = ({
  isMobile,
  isOpen,
  onClose,
}) => {
  if (isMobile) {
    return (
      <Modal
        mode="drawer"
        anchor="bottom"
        isOpen={isOpen}
        onRequestClose={onClose}
      >
        <EditDrawerContent onClose={onClose} />
      </Modal>
    );
  }

  return (
    <Modal mode="modal" isOpen={isOpen} onRequestClose={onClose}>
      <EditModalContent onClose={onClose} />
    </Modal>
  );
};
