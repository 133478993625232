/*

The coathanger component button that has a "tooltip" overlay.  It is used for adding products to the purchased collection and reporting that they are in it.

TODO: Convert to typescript component, camelCase,

*/
import React, { useState, useRef } from 'react';
import Overlay from 'react-overlays/Overlay';
import styled, { css } from 'styled-components';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import MEDIA from 'src/mvp22/media';

//TODO: Would really like the width to be calculated automatically on one line
const Tooltip = styled.div`
  width: ${(props) => (props.isPurchased ? '230px' : '160px')};
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  align-content: center;
  justify-content: center;
  text-align: left;
  z-index: 1000;
  position: absolute;
`;

const TooltipText = styled(TEXTSTYLE.BODY5)`
  padding: 10px;
`;

const CoathangerContainer = styled(TEXTSTYLE.LINKA)`
  right: 7px;
  position: relative;
`;

const LeftIconCSS = css`
  height: 30px;
  width: 30px;
  margin: 6px 0px;
  cursor: pointer;
  background-image: url('${(props) => props.bg_url}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  border-radius: 15px;
  &:hover {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
`;

const LeftIcon = styled.div`
  &:focus {
    outline: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  }
  ${LeftIconCSS}
`;

export function NotesButton({ showProductModal }) {
  const [show_popup, set_hanger_mo_visible] = useState(false);
  const triggerRef = useRef(null);
  const containerRef = useRef(null);
  //approach based on: https://react-bootstrap.github.io/react-overlays/api/Overlay
  return (
    <CoathangerContainer href="#" ref={containerRef} onClick={showProductModal}>
      <LeftIcon
        bg_url={MEDIA.EDIT}
        alt={'Click to add a note about your item'}
        ref={triggerRef}
        onMouseOver={() => set_hanger_mo_visible(true)}
        onMouseOut={() => set_hanger_mo_visible(false)}
      />
      <Overlay
        show={show_popup}
        offset={[0, 10]}
        onHide={() => set_hanger_mo_visible(false)} // required!
        container={containerRef}
        target={triggerRef}
        placement={'bottom-start'}
      >
        {(overlay_props) => (
          <Tooltip {...overlay_props.props} placement={overlay_props.placement}>
            <TooltipText>
              {'Add a note about this item e.g. size / colour'}
            </TooltipText>
          </Tooltip>
        )}
      </Overlay>
    </CoathangerContainer>
  );
}
