import { VFC, useCallback, useContext } from 'react';

import { ServicesContext } from 'src/ServicesContext';
import { GetAppDialogContainerProps } from './GetAppDialog.types';
import { GetAppDialog } from './GetAppDialog.component';

const GetAppDialogContainer: VFC<GetAppDialogContainerProps> = (props) => {
  // Open Moonsift on the App Store
  const { analytics } = useContext(ServicesContext);
  const handleAppStoreLinkClick = useCallback(() => {
    analytics.recordEvent('WebApp:AppStoreButton:Pressed');
  }, [analytics]);

  return (
    <GetAppDialog onAppStoreLinkClick={handleAppStoreLinkClick} {...props} />
  );
};

export { GetAppDialogContainer as GetAppDialog };
