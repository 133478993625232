/*

Old component!

Replace any occurrences with design system textboxes.

*/

import React from 'react';
import { Component } from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import PropTypes from 'prop-types';
import TextInput from 'src/mvp22/form-components/TextInput';

const BaseFeedback= styled.div`
  width: ${props => props.width};
  max-width:${props => props.maxWidth};
  font-family:${M.FONT};
  box-sizing:border-box;
  text-align:left;
  font-weight:200;
  padding-left:10px;
  padding-right:10px;
`;

const StandardFeedback = styled(BaseFeedback)`
  color:${M.COL.TEXT.ERROR};
  font-size:10px;
  line-height:16px;
  height:22px;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
`;

const VoidFeedback = styled(BaseFeedback)`
  background-color:${M.COL.UC.BG};
  color:${M.COL.UC.FG};
`;

const Container = styled.div`
  width: ${props=>props.width};
`;

/**
* Base text input class
*/
class TextInputFeedback extends Component {
  static propTypes = {
    /** width of field (text area) OPTIONAL */
    width: PropTypes.string,
    /** Maximum width OPTIONAL */
    maxWidth: PropTypes.string,
    /** Style type for field to use preset Moonsift styles */
    msstyle: PropTypes.string,
    /** current value */
    value: PropTypes.string,
    /** function to run on change */
    onChange:PropTypes.func,
    /** feedback text */
    feedbackText: PropTypes.string,
    /** name of the field */
    name:PropTypes.string,
    /** placeholder for textbox */
    placeholder:PropTypes.string,
    /** theme for style */
    type:PropTypes.string,
    /** focus on mounting? */
    focusOnMount:PropTypes.bool,
    /** is valid? */
    isValid:PropTypes.bool,
    /** tab index... */
    tabIndex:PropTypes.number
  }

  constructor(props){
    super(props);
    this.getInnuds = this.getInnuds.bind(this);
  }


  getInnuds(){
    switch(this.props.msstyle){
      case "standard":{
        return(StandardFeedback);
      }

      default:{
        return(VoidFeedback);
      }

    }
  }



  render(){
    const FB = this.getInnuds();
    const is_valid = (this.props.isValid===false && this.props.feedbackText!=="")?false:true;
    return(
      <Container width={this.props.width}>
        <TextInput
          focusOnMount={this.props.focusOnMount}
          width={this.props.width}
          value={this.props.value}
          type={this.props.type}
          msstyle={this.props.msstyle}
          onChange={this.props.onChange}
          placeholder={this.props.placeholder}
          name={this.props.name}
          isValid={is_valid}
          maxWidth={this.props.maxWidth}
          tabIndex={this.props.tabIndex}
          onEnter={this.props.onEnter}
        />
        <FB>
          {this.props.feedbackText}
        </FB>
      </Container>
    );
  }

}

export default TextInputFeedback;
