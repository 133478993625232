/*

Essentailly a static page for displaying the privacy policy.
TODO: typescript, make functional, non-default exports, design system

*/
import React from 'react';
import { Component } from 'react';
import { AddHelmetTitle } from 'src/mvp22/core-components/helmet';
import styled from 'styled-components';
import { M } from 'src/mvp22/constants';
import { TEXTSTYLE } from 'src/mvp22/style-components/Text';
import TopMenu from 'src/mvp22/menu-components/TopMenu';
import { FullBlank } from 'src/mvp22/menu-components/TopMenu';
import BottomBar from 'src/mvp22/menu-components/BottomBar';

const Container = styled.div`
  background-color: ${M.COL.BUTTON.WHITE};
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 115px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${M.COL.TEXT.BLACK};
  text-align: left;
  width: 100%;
  box-sizing: border-box;
`;

const WithinContainer = styled.div`
  max-width: 700px;
`;

const Seperator = styled.div`
  height: ${(props) => props.height};
`;

const FullWidthHeading2 = styled(TEXTSTYLE.HEADING2)`
  width: 100%;
`;

const FullWidthOther1 = styled(TEXTSTYLE.OTHER1)`
  width: 100%;
`;

const FullWidthBody3 = styled(TEXTSTYLE.BODY3)`
  width: 100%;
`;

/**
 * Terms + Conditions Moonsift Component
 */
class TermsAndConditions extends Component {
  render() {
    return (
      <FullBlank>
        <TopMenu />
        {AddHelmetTitle('Terms and Conditions')}
        <Container>
          <WithinContainer>
            <FullWidthHeading2>Terms and Conditions</FullWidthHeading2>
            <Seperator height="12px" />
            <FullWidthOther1>Who is Moonsift</FullWidthOther1>
            <FullWidthBody3>
              We are Moonsift Limited, a company registered in England and
              Wales. Our registered office is Kemp House 160 City Road, London,
              England, EC1V 2NX.
              <br />
              <br />
              When you access Moonsift's our website website, apps, APIs and
              widgets (“Services”), these terms apply. By accessing or using
              Moonsift, you agree to be bound by these Terms, our Privacy and
              Cookies Policy. Please read these Terms carefully. If you have any
              questions, we love hearing from you so feel free to get in touch
              if you need anything.
              <br />
              <br />
              Any use or access by anyone under the age of 13 is not allowed. If
              you're based in the EEA, you can only use Moonsift if you are over
              the age at which you can provide consent to data processing under
              the laws of your country or if verifiable parental consent for
              your use of Moonsift has been provided to us. Using Moonsift can
              include downloading software to your computer, phone, tablet or
              other device. You agree that we can automatically update that
              software, and these Terms will apply to any updates.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>Don't be naughty</FullWidthOther1>
            <FullWidthBody3>
              You must not mess with our services (e.g. hack, introduce viruses,
              trojans, worms, logic bombs or other technologically harmful
              material or carry out denial of service attacks etc). You must not
              mess with our tech or functionality or steal our or our customers'
              data. Doing any of these things may be a criminal offence, so we
              take them really seriously. Moonsift will report any such breach
              or activity (and all information about the people carrying it out)
              to the relevant law enforcement authorities. Further, you must not
              you must not use automated systems or software to extract data
              from our Website (commonly known as &pos;scraping&pos;).
              <br />
              <br />
              We have taken strict measures to protect our website, but we
              cannot guarantee that it will always be secure from bugs, viruses
              or trouble-makers. Therefore we recommend you use virus protection
              software when using our site or any others.
              <br />
              <br />
              If you do break any of the above promises: you agree to indemnify,
              defend and hold harmless Moonsift, its directors, officers,
              employees, consultants, agents, and affiliates, from any and all
              third-party claims, liability, damages and/or costs (including,
              but not limited to, legal fees) arising from your use (or misuse)
              of this Website or your breach of the Terms and Conditions.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>
              The Content you add to and create with Moonsift ('User Content')
            </FullWidthOther1>
            <FullWidthBody3>
              We can edit, crop, adapt, enhance or modify Content you bring onto
              Moonsift. We can use the content you generate or bring to Moonsift
              (e.g. your collections) for our own branded marketing so long as
              we either remove any connection to you as an individual or request
              your consent to do so.
              <br />
              <br />
              You understand that other users of our and other social media
              platforms can also share and make use of the Content once added to
              Moonsift and made discoverable or shared outside Moonsift. In
              particular, a user of these platforms can take a screenshot of and
              save an image of the Content to their device, share the Content on
              social media platforms or websites which feature the Content (and
              sharing capabilities). If you do not want to grant the permissions
              set out above then you will need to ensure your collections are
              set to private.
              <br />
              <br />
              If you choose to post content, you give us permission to use it to
              provide and improve Moonsift. Copies of content shared with others
              may remain even after you&pos;ve deleted the content from your
              account or deleted your account. Following termination or
              deactivation of your account, or if you remove any User Content
              from Moonsift, we may keep the content you added for a reasonable
              period of time for backup, archive or audit purposes. Moonsift and
              its users may retain and continue to use, store, display,
              reproduce, save, modify, create derivative works, perform and
              distribute any of the Content you have added that other users have
              stored or shared on Moonsift.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>Feedback you provide</FullWidthOther1>
            <FullWidthBody3>
              We value hearing from our users, and are always interested in
              learning about ways we can make Moonsift better. If you choose to
              submit comments, ideas or feedback, you agree that we are free to
              use them without any restriction or compensation to you. By
              accepting your submission, Moonsift doesn't waive any rights to
              use similar or related feedback previously known to Moonsift, or
              developed by its employees, or obtained from sources other than
              you.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>
              Third-party links, sites and services
            </FullWidthOther1>
            <FullWidthBody3>
              Moonsift provides links to many third-party websites that are not
              owned or controlled by Moonsift (predominantly the sites that
              stock the products displayed on Moonsift). We don't endorse or
              assume any responsibility for any such third-party sites,
              information, materials, products or services. If you access any
              third-party websites, services or content from Moonsift, you do so
              at your own risk and you agree that Moonsift has no liability
              arising from your use of or access to any third-party websites,
              services or content.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>Termination</FullWidthOther1>
            <FullWidthBody3>
              Moonsift may terminate or suspend your right to access or use
              Moonsift's Services for any reason with appropriate notice. We may
              terminate or suspend your access immediately and without notice if
              we have a good reason.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>Disclaimers</FullWidthOther1>
            <FullWidthBody3>
              Our Service and all content on Moonsift is provided on an &pos;as
              is&pos; basis without warranty of any kind, whether express or
              implied.
              <br />
              <br />
              Moonsift specifically disclaims any and all warranties and
              conditions of merchantability, fitness for a particular purpose
              and non-infringement, and any warranties arising out of course of
              dealing or usage of trade.
              <br />
              <br />
              Moonsift takes no responsibility and assumes no liability for any
              User Content that you or any other person or third party posts or
              sends using our Service. You understand and agree that you may be
              exposed to User Content that's inaccurate, objectionable,
              inappropriate for children or otherwise unsuited to your purpose.
              <br />
              <br />
              If you&pos;re a consumer in the EEA, we don't exclude or limit any
              liability for gross negligence, intent, or death or personal
              injury caused by our negligence or willful misconduct.
              <br />
              <br />
              If you see inaccurate or otherwise harmful content or posts on
              Moonsift, please report it to us.
              <br />
              <br />
            </FullWidthBody3>
            <FullWidthOther1>
              Notification procedures and changes to these Terms
            </FullWidthOther1>
            <FullWidthBody3>
              We reserve the right to determine the form and means of providing
              you with notifications, and you agree to receive legal notices
              electronically if that is what we decide.
              <br />
              <br />
              We have the right to amend, remove or vary our services and/or any
              part of the Website (including our Terms and Conditions) at any
              time.
              <br />
              <br />
              These terms and our dealings with you are subject to English law
              and the exclusive jurisdiction of the English courts.
              <br />
              <br />
            </FullWidthBody3>
          </WithinContainer>
        </Container>
        <BottomBar />
      </FullBlank>
    );
  }
}

export default TermsAndConditions;
