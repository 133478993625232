import { useEffect, useRef, Ref } from 'react';

/*

Hook that provides ability to perform a function when clicking outside an object.
Mostly used for closing modals when clicking outside of them.

*/
export const useClickOutside = <T extends HTMLElement>(
  // TODO: see if this callback arg type can be made more specific
  // different usage across the app makes this a bit hard
  callback: Function,
): Ref<T> => {
  const containerRef = useRef<T>(null);
  useEffect(() => {
    const checkClick = (ev: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(ev.target as Node)
      ) {
        if (callback) {
          callback(ev);
        }
      }
    };
    document.addEventListener('mousedown', checkClick);
    return () => document.removeEventListener('mousedown', checkClick);
  }, [callback]);
  return containerRef;
};
