import { VFC } from 'react';
import styled from 'styled-components';

import { Page } from 'src/components/common/Page';
import { CreateCollectionContent } from 'src/components/common/CreateCollectionContent';
import { CreateCollectionProps } from './CreateCollection.types';
import { EnableNotificationsPrompt } from 'src/components/onboarding/EnableNotificationsPrompt';

const UnstyledCreateCollection: VFC<CreateCollectionProps> = ({
  className,
  style,
  isFirst,
  showEnableNotificationsPromptInstead,
  onCloseNotificationsPrompt,
}) => {
  return (
    <Page className={className} style={style} showRight={false}>
      {showEnableNotificationsPromptInstead ? (
        <EnableNotificationsPrompt
          onClose={onCloseNotificationsPrompt}
          className="CreateCollection-content"
        />
      ) : (
        <CreateCollectionContent
          className="CreateCollection-content"
          isFirst={isFirst}
        />
      )}
    </Page>
  );
};

export const CreateCollection = styled(UnstyledCreateCollection)`
  margin-bottom: 40px;

  .CreateCollection-content {
    margin: 0 auto;
    max-width: 328px;
  }
`;
