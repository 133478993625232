import { LinkProps } from 'react-router-dom';
import {
  Location,
  LocationDescriptor,
  LocationDescriptorObject,
} from 'history';

/**
 * Extracts an object location descriptor (in React Router v5 terms)
 * from a string location descriptor.
 *
 * See https://v5.reactrouter.com/web/api/Link/to-string
 */
export const getLocationDescriptorFromString = <S = unknown>(
  to: string,
): LocationDescriptorObject<S> => {
  const [pathname, search, hash] = to.split(/\?|#/);
  return {
    pathname,
    search,
    hash,
  };
};

/**
 * Extracts an object location descriptor (in React Router v5 terms)
 * from a function location descriptor.
 *
 * See https://v5.reactrouter.com/web/api/Link/to-func
 */
export const getLocationDescriptorFromFunction = <S = unknown>(
  location: Location<S>,
  to: (location: Location<S>) => LocationDescriptor<S>,
): LocationDescriptorObject<S> => {
  const locationDescriptor = to(location);

  if (typeof locationDescriptor === 'string') {
    return getLocationDescriptorFromString<S>(locationDescriptor);
  }

  return locationDescriptor;
};

/**
 * Extracts an object location descriptor (in React Router v5 terms)
 * regardless of what kind of location descriptor is provided, a string,
 * a function or an object.
 */
export const getLocationDescriptor = <S = unknown>(
  location: Location<S>,
  to: LinkProps<S>['to'],
): LocationDescriptorObject<S> => {
  if (typeof to === 'function') {
    return getLocationDescriptorFromFunction<S>(location, to);
  }

  if (typeof to === 'string') {
    return getLocationDescriptorFromString<S>(to);
  }

  return to;
};
