import { VFC, useCallback, useState } from 'react';

import { CreateCollectionContainerProps } from './CreateCollection.types';
import { CreateCollection } from './CreateCollection.component';

const CreateCollectionContainer: VFC<CreateCollectionContainerProps> = ({
  location,
  ...otherProps
}) => {
  const searchParams = new URLSearchParams(location.search);
  const isFirst = searchParams.get('first') === 'true';

  // TODO: replace this with it's own screen once the app is updated:
  const [
    showEnableNotificationsPromptInstead,
    setShowEnableNotificationsPromptInstead,
  ] = useState(isFirst);
  const handleCloseNotificationsPrompt = useCallback(() => {
    setShowEnableNotificationsPromptInstead(false);
  }, [setShowEnableNotificationsPromptInstead]);

  return (
    <CreateCollection
      isFirst={isFirst}
      showEnableNotificationsPromptInstead={
        showEnableNotificationsPromptInstead
      }
      onCloseNotificationsPrompt={handleCloseNotificationsPrompt}
      {...otherProps}
    />
  );
};

export { CreateCollectionContainer as CreateCollection };
