import { VFC } from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import { Stack, CheckIcon, Typography } from '@moonsifttech/design-system';

import { PlanFeatureProps } from './PlanFeature.types';

const UnstyledPlanFeature: VFC<PlanFeatureProps> = ({
  className,
  style,
  highlight = false,
  children,
}) => {
  return (
    <Stack
      className={clsx(className, highlight && 'PlanFeature-highlighted')}
      style={style}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      spacing={12}
    >
      <CheckIcon />
      <Typography variant="primary.l16" component="p">
        {children}
      </Typography>
    </Stack>
  );
};

export const PlanFeature = styled(UnstyledPlanFeature)`
  margin-bottom: 12px;

  > svg {
    min-width: 20px;
  }

  > p {
    margin-bottom: 0;
  }

  &.PlanFeature-highlighted > p {
    ${({ theme }) => theme.fns.getTypographyStyles('primary.m16')}
  }
`;
