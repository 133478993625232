import React from 'react';
import styled from 'styled-components';
import {
  Typography,
  typographyClassNames,
  Stack,
  ControlledTextField,
  textFieldClassNames,
  CtaButton,
  linkClassNames,
  FormHelperText,
  Form,
} from '@moonsifttech/design-system';

import { Link } from 'src/components/core/Link';
import { SignUpFormProps } from './SignUpForm.types';

const UnstyledSignUpForm: React.FC<SignUpFormProps> = ({
  className,
  style,
  signInPath,
  initialValues,
  validationSchema,
  mainErrors,
  isWorking,
  onSubmit,
  onGoogleAuth,
  onAppleAuth,
}) => {
  return (
    <Form
      className={className}
      style={style}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Stack
        className="SignUpForm-formContainer"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {mainErrors.email && (
          <FormHelperText error>{mainErrors.email}</FormHelperText>
        )}
        <ControlledTextField
          name="fullName"
          type="text"
          autoComplete="name"
          placeholder="Name"
          fullWidth
        />
        <ControlledTextField
          name="email"
          type="text"
          autoComplete="email"
          placeholder="Email"
          fullWidth
        />
        <ControlledTextField
          className="SignUpForm-passwordInput"
          name="password"
          type="password"
          autoComplete="new-password"
          placeholder="Create a password"
          fullWidth
        />
        <CtaButton
          className="SignUpForm-continueButton"
          type="submit"
          variant="primary-black"
          fullWidth
          loading={isWorking.email}
          disabled={isWorking.google || isWorking.apple}
        >
          Continue
        </CtaButton>
        <div className="SignUpForm-orSeparator">
          <Stack justifyContent="center">
            <hr />
          </Stack>
          <Typography variant="primary.b18" component="span">
            OR
          </Typography>
          <Stack justifyContent="center">
            <hr />
          </Stack>
        </div>
        {mainErrors.providers && (
          <FormHelperText error>{mainErrors.providers}</FormHelperText>
        )}
        <CtaButton
          className="SignUpForm-googleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/googleSignupLogo.svg`}
              alt="Google logo"
            />
          }
          fullWidth
          loading={isWorking.google}
          disabled={isWorking.email || isWorking.apple}
          onClick={onGoogleAuth}
        >
          Join with Google
        </CtaButton>
        <CtaButton
          className="SignUpForm-appleButton"
          variant="primary-white"
          startIcon={
            <img
              width={20}
              height={20}
              src={`${process.env.REACT_APP_STATIC_URL}/logos/apple_logo.svg`}
              alt="Apple logo"
            />
          }
          fullWidth
          loading={isWorking.apple}
          disabled={isWorking.email || isWorking.google}
          onClick={onAppleAuth}
        >
          Join with Apple
        </CtaButton>
        <Typography
          className="SignUpForm-terms"
          variant="primary.r12"
          component="p"
        >
          Create your free account to keep track of everything you save
        </Typography>
        <Typography
          className="SignUpForm-login"
          variant="primary.r12"
          component="p"
        >
          Already a member? <Link to={signInPath}>Log in</Link>
        </Typography>
      </Stack>
    </Form>
  );
};

export const SignUpForm = styled(UnstyledSignUpForm)`
  .SignUpForm-formContainer {
    min-width: 320px;

    > .${textFieldClassNames.root} {
      margin-bottom: 10px;
    }

    > .SignUpForm-passwordInput {
      margin-bottom: 16px;
    }

    > .SignUpForm-continueButton {
      margin-bottom: 12px;
    }

    > .SignUpForm-orSeparator {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      width: 100%;
      margin-bottom: 12px;

      hr {
        margin: 0;
        border-top: 1px solid black;
        border-bottom: none;
      }

      .${typographyClassNames.root} {
        margin: 0 18px;
      }
    }

    .SignUpForm-googleButton {
      margin-bottom: 6px;
    }

    .SignUpForm-appleButton {
      margin-bottom: 20px;
    }

    .SignUpForm-terms {
      margin-bottom: 10px;
    }

    .SignUpForm-login .${linkClassNames.root} {
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
    }
  }
`;
