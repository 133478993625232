/*

A component that tries to load an image and if it doesn't succeed replaces it with a placeholder.

TODO: Functional, typescript, camelCase

*/
// We've tried react-image for the loading detection on product tiles but changing the image via the arrows was just too slow!
import React from 'react';
import { Component } from 'react';
import MEDIA from 'src/mvp22/media';

export default class LoadCheck extends Component {
  constructor() {
    super();
    this.state = {
      current_image_src: '',
    };
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // if the selected image changes,
  // then move to that one so that it looks like we actually have done something!
  componentDidUpdate() {
    this.setState((prevState, props) => {
      if (prevState.raw_image_src !== props.src) {
        const new_image_src = props.src
          ? props.src
          : this.props.isBGProcessing
          ? MEDIA.BACKEND_SCRAPING_IN_PROGRESS
          : MEDIA.PRODUCT_IMAGE_NOT_FOUND;
        return {
          current_image_src: new_image_src,
          raw_image_src: props.src,
        };
      }
    });
  }

  onError(event) {
    const currentSrc = event.target.currentSrc;
    //    console.log("Error loading image part #1",currentSrc,this.state);
    this._isMounted &&
      this.setState((prevState) => {
        console.log('Error loading image part #2', currentSrc, prevState);
        // only update state if actual image is current one set as error:
        // note current src may have extra text over input image source!
        if (currentSrc.includes(prevState.current_image_src)) {
          return {
            current_image_src: this.props.isBGProcessing
              ? MEDIA.BACKEND_SCRAPING_IN_PROGRESS
              : MEDIA.PRODUCT_IMAGE_NOT_FOUND,
          };
        }
      });
  }

  render() {
    return React.cloneElement(this.props.children, {
      src: this.state.current_image_src,
      onError: this.onError,
    });
  }
}
