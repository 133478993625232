/*

Essentially just shared css between modals

TODO: design-system + typescript.

*/
import React from 'react';
import styled from 'styled-components';
import {M} from 'src/mvp22/constants';
import MEDIA from 'src/mvp22/media';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

export const GeneralOverlayWithinContainer = styled.div`
  display:flex;
  position:relative;
  width:90%;
  height:90%;
  max-width:660px;
  max-height:514px;
  box-sizing:border-box;
  background-color:${M.COL.BG.WHITE};
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  overflow-y:auto;
  flex-direction: row;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: ${M.COL.TEXT.BLACK};
`;

export const RightContainerProduct = styled.div`
  padding:0px;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow:1;
  height:100%;
  border-left:solid ${M.COL.LINE.MID} 0.5px;
  width:50%;
  min-width:250px;
`;

export const RightTitleContainer = styled.div`
  border-bottom:solid ${M.COL.LINE.MID} 0.5px;
  padding-top:24px;
  padding-bottom:18px;
  padding-left:26px;
  padding-right:26px;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items:center;
`;

export const LogoAndTitle = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:flex-start;
  align-items: center;
`;

export const LogoInTitle = styled.img`
  height:24px;
  width:24px;
  margin-right:8px;
`;

export const CollectionSettings = styled.div`
  margin:20px 26px;
  justify-content: flex-start;
  align-content: flex-start;
  height: 100%;
`;

export const BothBottomBar = styled.div`
  height:62px;
  padding:16px 26px;
  border-top:solid ${M.COL.LINE.MID} 0.5px;
  display:flex;
  flex-direction:column;
  align-items:center;
  align-content:center;
  justify-content:center;
  box-sizing:border-box;
  flex-shrink: 0;
`;

export const RightFlow = styled.div`
  display:flex;
  width:100%;
  justify-content: flex-end;
  flex-direction:row;
  font-size:10px;
  align-items: center;
`;

export const Cross = styled.img`
  height:16px;
  width:16px;
  cursor:pointer;
  padding:3px;
`;

export const Separator = styled.div`
  width:100%;
  height: ${props=>props.height};
`;

export const TopRight = styled.div`
  position:absolute;
  right:26px;
  top:26px;
`;

export const VerticalFlow = styled.div`
  width:100%;
  flex-direction:column;
  padding:24px;
  display:flex;
  height:100%;
  box-sizing:border-box;
  padding-top: 17px;
`;

export const LeftAlignedTitle = styled(TEXTSTYLE.HEADING4)`
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  max-width: 90%;
  text-align: left;
`;

export const FlexGrowTop = styled.div`
  padding-top:16px;
  flex-direction:column;
  flex-grow:1;
  height:100%;
  width:100%;
  display:flex;
  align-items:flex-start;
`;

const PrivateContainer = styled.div`
  display:flex;
  cursor:pointer;
  align-content:flex-start;
  align-items:flex-start;
  text-align:left;
  text-decoration:none;
`;

const PrivateText = styled(TEXTSTYLE.BODY4)`
  color:${M.COL.TEXT.MID};
  padding:0px 8px;
`;

export const PrivateDefaultMessage = ()=>{
  return(
    <PrivateContainer>
      <PrivateText>
        This collection will be set to 'Private' by default. You can change it
        to 'Public' or 'Registry' in the collection.
      </PrivateText>
    </PrivateContainer>
  );
};

export const CharCount = styled(TEXTSTYLE.BODY5)`
  text-align: right;
  color: ${props => props.charCountExceeded ? M.COL.TEXT.ERROR : M.COL.TEXT.BLACK};
  display: flex;
  align-self: flex-end;
  margin-right: 4px;
`;

export const CloseCross = (props)=>{
  return(
    <TopRight>
      <TEXTSTYLE.LINKA href="#" tabindex="13002" onClick={props.closeFunction}><Cross src={MEDIA.EXIT_CROSS_THIN}/></TEXTSTYLE.LINKA>
    </TopRight>
  );
};
