/*

Replace with using hook useDBSetDoc

*/
export default function add_tastemaker_app(this_data,firebase,auth_user_uid){
  return firebase.user_tastemaker_app(
    auth_user_uid,
    firebase.serverTimestamp()
  ).set(
    this_data,
    {
      merge: true
    }
  );
}
