import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src';
import { ActionNames, WorkingAction } from 'src/types';

// Helper functions that add plugins to the redux "working" map
// this allows tasks that may work across multiple components to complete
// and stay locked until they complete across these multiple components
// also covers case of repopening a component BUT not multiple tabs!
export const useWorking = (
  storeAs: string,
): [(value: boolean) => void, () => void, boolean | undefined] => {
  const dispatch = useDispatch();
  const setWorking = useCallback(
    (value: boolean) => {
      dispatch<WorkingAction>({
        type: ActionNames.WORKING_SET,
        payload: {
          storeAs,
          value,
        },
      });
    },
    [dispatch, storeAs],
  );
  const unsetWorking = useCallback(() => {
    dispatch<WorkingAction>({
      type: ActionNames.WORKING_UNSET,
      payload: storeAs,
    });
  }, [dispatch, storeAs]);
  const workingValue = useSelector<RootState, boolean | undefined>(
    (state) => state.working[storeAs],
  );
  return [setWorking, unsetWorking, workingValue];
};
