/*

The prompt at the bottom of the screen to tell users a new version of moonsift is available and they should refresh.

This is needed due to service workers but we may remove!

DO NOT TOUCH THE LOGIC IN HERE WITHOUT CONSULTING TEAM!

TODO: camelCase, typescript, functional, possibly remove?

*/
import React from 'react';
import { Component } from 'react';
import {M} from 'src/mvp22/constants';
import styled from 'styled-components';
import {TEXTSTYLE} from 'src/mvp22/style-components/Text';

const CookieNoticeContainer = styled.div`
  width:100%;
  position:fixed;
  bottom:0px;
  background-color:${M.COL.BUTTON.YELLOW};
  color:${M.COL.TEXT.BLACK};
  min-width:${M.MINWIDTH};
  display:flex;
  flex-direction:row;
  align-items:center;
  justify-content: center;
  z-index:10000;
`;

const MainTextBlock = styled(TEXTSTYLE.OTHER1)`
  padding: 12px 12px;
`;

const TabContainer = styled(TEXTSTYLE.BODY3)`
  margin-left:4px;
  display:inline-block;
`;

const InlineBody = styled(TEXTSTYLE.LINKA)`
  font-weight:bold;
  text-decoration:underline;
`;

class RefreshPrompt extends Component {

  constructor(){
    super();
    this.notify_of_new_service_worker = this.notify_of_new_service_worker.bind(this);
    this.refresh_now = this.refresh_now.bind(this);
    this.state={
      visible:false,
      updating:false
    };
  }

  componentDidMount(){
    // Add a listener for an event that says this tab has found a new service worker is now ready to be installed:
    window.addEventListener('ms_new_worker',
      ()=>{
        console.log("RP: Moonworker: Detected a new service worker ready via inbound event!");
        this.notify_of_new_service_worker();
      }
    );
    // In case that event was fired prior to mounting:
    var service_worker_count = 0;
    if ('serviceWorker' in navigator){
      navigator.serviceWorker.getRegistrations().then(
        registrations => {
           registrations.forEach(
             (v) => {
               const scope_path = (new URL(v.scope)).pathname;
               if (scope_path==="/"){
                 service_worker_count+=1;
                 if (v.waiting){
                   console.log("RP: Moonworker: Detected a new service worker waiting to be installed on componentDidMount");
                   this.notify_of_new_service_worker();
                 }
               }
             }
          );
          console.log("RP: Moonworker: Found "+service_worker_count+" service worker(s) for this app");
        }
      );
    }
    // Events removed on closure of tab.
  }

  notify_of_new_service_worker(){
    this.setState({visible:true});
  }

  refresh_now(event){
    event.preventDefault();
    this.setState({updating:true});
    // Force install the service worker:
    console.log("RP: Moonworker: Forcing update of service worker (skip waiting)");
    window.postMessage("ms_install_worker","*");
    return false;
  }

  render(){
    return(
      (this.state.visible)?
        <CookieNoticeContainer>
          <MainTextBlock>
          {(this.state.updating===true)?
            <>
              Updating now.  Just a moment...
            </>
          :
            <>
              A more recent version of Moonsift has just become available, please
              <TabContainer>
                <InlineBody onClick={this.refresh_now} href="#">
                  click here to update
                </InlineBody>
              </TabContainer>
            </>
          }
          </MainTextBlock>
        </CookieNoticeContainer>
      :""
    );
  }
}
export default RefreshPrompt;
