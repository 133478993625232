/*

Covers the background in a translucent grey.

Used with BurgerMenu, Feedback and SharePopups.  Must be defined separately with Modals.

TODO: Make a common one (wrapper?) in the design system for use with Menus, Popups and Modals.

*/
import styled from 'styled-components';

const BGCover = styled.div`
  display:block;
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  box-sizing:border-box;
  background-color:#000000CC;
  z-index: ${props=>(props.zIndex!==undefined)?props.zIndex:"5999"};
`;

export default BGCover;
