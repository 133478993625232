import React from 'react';
import styled from 'styled-components';
import { Stack, Typography, ViewIcon } from '@moonsifttech/design-system';

import { ViewCountProps } from './ViewCount.types';

export const ViewCount = styled<React.FC<ViewCountProps>>(
  ({ className, style, count, ...otherProps }) => {
    return (
      <Stack
        className={className}
        style={style}
        direction="row"
        alignItems="center"
        spacing={6}
        {...otherProps}
      >
        <ViewIcon />
        <Typography variant="primary.r12">{count}</Typography>
      </Stack>
    );
  },
)``;
