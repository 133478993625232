import { batmanTheme } from '@moonsifttech/design-system';

export type Conditions = 'isDesktop'[];

/**
 * Checks whether the conditions for a specific notification to be shown are met.
 */
export function checkNotificationConditions(conditions?: Conditions) {
  // When there are no conditions, consider all conditions are met.
  if (!conditions) {
    return true;
  }

  // Iterate over each condition and bail out when a condition is not met.
  for (let i = 0; i < conditions.length; i++) {
    const condition = conditions[i];

    let met = false;
    switch (condition) {
      case 'isDesktop':
        met = batmanTheme.breakpoints.md < global.innerWidth;
        break;
      default:
    }

    if (!met) {
      // If the current condition has not been met, just return and get out of the function.
      return false;
    }
  }

  // All conditions are met.
  return true;
}
