/*

Replace with using hook useDBAddDoc

*/
import send_error from 'src/mvp22/core-components/send_error';
export default function add_feedback(data,firebase,auth_user_uid){
  return new Promise ( (resolve,reject)=>{
      if (data.feedback.length<4000 && data.email.length<300){
        // double check a query with firestore frist:
        const created_at = firebase.serverTimestamp();
        const new_data = Object.assign(
          {},
          data,
          {
          user_uid:auth_user_uid,
          created_at,
          }
        );
        firebase.feedback().add(
          new_data
        ).then(
          resolve
        ).catch(
          error=>{
            send_error(error);
            reject();
          }
        );
      }
      else{
        send_error("Data too long");
        reject();
      }
    }
  );
}
