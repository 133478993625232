/*

Replace with REDUX singleDoc (non-map) for the initial collection list,
Then get the individual collection docs as single documents and store them in a map.
and also get the userpublic data method we already use in various places (e.g. comments)

*/
import collection_data_proc from 'src/mvp22/core-components/collection_data_proc';
import get_user_profile_public from './get_user_profile_public';
export default function get_explore_collections(explore_type,firebase,auth_user_uid){
  return new Promise(
    (resolve)=>{
      // first get the list of explore collections:
      firebase.curated_list(
        explore_type
      ).get().then(
        (doc)=>{
          if (doc.exists()){
            const collection_list = doc.data().list;
            const promise_array = [];
            const dict_of_usernames = {};
            collection_list.forEach(
              cli=>{
                dict_of_usernames[cli.user_uid]={name:"Moonsift user"};
                promise_array.push(
                  firebase.user_product_collection(
                    cli.user_uid,
                    cli.collection_uid
                  ).get().then(
                    snapshot=>{
                      return [cli,snapshot];
                    }
                  ).catch((err)=>console.log("WARNING: unable to fetch collection (probably deleted or been made private)",cli,err))
                );
              }
            );
            // get the public user info e.g. the name:
            for (let this_user_uid in dict_of_usernames){
              promise_array.push(
                get_user_profile_public(
                  this_user_uid,
                  firebase
                ).then(
                  (response)=>
                  dict_of_usernames[this_user_uid]=response
                ).catch(
                  (err)=>console.error(err)
                )
              );
            }
            Promise.all(promise_array).then(
              (response)=>{
                const explore_collections_sorted = [];
                const explore_collections_dict = {};
                response.forEach(
                  snapshot=>{
                    if (snapshot && snapshot[1] && snapshot[1].exists()){
                      const collection_entry = collection_data_proc(auth_user_uid,snapshot[1]);
                      explore_collections_dict[collection_entry.global_uid] = {
                        collection_entry:collection_entry,
                        cli:snapshot[0]
                      };
                      explore_collections_sorted.push(collection_entry.global_uid);
                    }
                  }
                );
                resolve(
                  {
                    collections:explore_collections_dict,
                    collections_sorted:explore_collections_sorted,
                    public_info:dict_of_usernames
                  }
                );
              }
            );
          }
          else{
            resolve({
              collections:{},
              collections_sorted:[],
              public_info:{}
            });
          }
        }
      ).catch(
        err=>{
          console.error("error",err);
          resolve(
            {
              collections:{},
              collections_sorted:[],
              public_info:{}
            }
          );
        }
      );
    }
  );
}
